import { KitTouchable } from '@omni/kit/components';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useState } from 'react';
import { View, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

export default <T,>({
  children,
  id,
  option,
  isSelected,
  onSelect,
  showGradient,
  style,
}: {
  children: React.ReactNode;
  id: string;
  option: T;
  isSelected: boolean;
  onSelect: (id: string, option: T) => void;
  showGradient?: boolean;
  style?: ViewStyle;
}): JSX.Element => {
  const [onShowKitListUnderlay, setOnShowKitListUnderlay] = useState(false);

  return (
    <View style={style}>
      <View
        style={{
          borderWidth: isSelected ? 2 : 1,
          borderColor: isSelected ? Colors.N900 : Colors.N100,
          borderRadius: BorderRadius.l,
          flex: 1,
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      />

      <View pointerEvents={isSelected ? 'none' : 'auto'}>
        <KitTouchable
          onPress={() => {
            !isSelected && onSelect(id, option);
          }}
          style={{ borderRadius: BorderRadius.l }}
          onShowUnderlay={() => setOnShowKitListUnderlay(true)}
          onHideUnderlay={() => setOnShowKitListUnderlay(false)}
        >
          <>
            {children}
            {showGradient && (
              <LinearGradient
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                colors={[
                  'rgba(255, 255, 255, 0);',
                  onShowKitListUnderlay ? Colors.N100 : 'white',
                ]}
                style={{
                  width: 54,
                  height: 62,
                  position: 'absolute',
                  top: 7,
                  right: 7,
                }}
              />
            )}
          </>
        </KitTouchable>
      </View>
    </View>
  );
};
