import React from 'react';

/**
 * @deprecated Use ternary expression instead (`{check ? contents : null}`)
 * This is deprecated since it prevents Typescript from understanding that the
 * contents within should evaluate to truthy.
 */
export default function Show({
  show,
  children,
}: {
  show: any;
  children: JSX.Element | JSX.Element[];
}): JSX.Element | null {
  return show ? <>{children}</> : null;
}
