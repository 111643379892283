import BorderRadius from '../../theming/BorderRadius';
import Spacing from '../../theming/Spacing';

const styles = {
  wrapper: {
    marginBottom: 9,
    marginRight: Spacing.m,
    paddingHorizontal: Spacing.xs,
    paddingVertical: Spacing.xxs,
    borderRadius: BorderRadius.s,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
};

export default styles;
