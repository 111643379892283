import GetAppHeaders from '@omni/kit/Downloader/GetAppHeaders';
import appendUrlParams from '@omni/kit/utilities/appendUrlParams';
import { Platform } from 'react-native';

const debug = require('debug')('omni:kit:AuthService');

/**
 * Dev note: do not trigger any side effects such as logout on a 401 error
 * since this method may be used outside the context of the login workflow
 * and use-case may not need any side effect,
 * e.g. FeedsService::GetCustomProfileHeader
 * Any side effects must be handled by the caller of this method.
 *
 * @param tokenUrl
 * @param source
 * @returns
 */
export const requestAccessToken = async (
  tokenUrl: string,
  sapToken?: string | null, // only for native
  source?: string
): Promise<Response> => {
  const params: string[] = [];

  if (Platform.OS === 'web') {
    params.push('use_refresh_cookie=true');
  }

  /**
   * When a user switches apps in TCA and the user does not yet have a profile in that app, a profile will be created.
   * In this case, we need to track creation source
   * ref: https://subsplash.atlassian.net/browse/ARTEMIS-2465
   */
  if (source) {
    params.push(`source=${source}`);
  }

  const refreshUrl = appendUrlParams(tokenUrl, params);

  debug(`refreshing token with token url: ${refreshUrl}`);

  /**
   * App request headers are required for token refresh in container apps.
   * At a minimum, container apps must provide a sap-root-appkey header here.
   */
  let appHeaders: Record<string, string> = {};
  try {
    appHeaders = await GetAppHeaders();
  } catch {}

  return await fetch(refreshUrl, {
    method: 'POST',
    ...(Platform.OS === 'web'
      ? { credentials: 'include' }
      : { body: `sap_token=${sapToken}` }),
    headers: {
      ...appHeaders,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
