import { KitText } from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import Show from '@omni/kit/components/Show';
import Colors from '@omni/kit/theming/Colors';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { ItemAlignment } from '../../blocks/types';
import { getFlexForAlignment } from '../../blocks/utilities';

interface IImageIndicatorProps {
  image?: string;
  imageHeight: number;
  imageRadius?: number;
  imageWidth: number;
  onLoadEnd?: () => void;
  overlaySubtitle?: string;
  overlayTitle?: string;
  style?: StyleProp<ViewStyle>;
  subtitleTextColor?: string;
  textAlignmentHorizontal?: ItemAlignment;
  textAlignmentVertical?: ItemAlignment;
  titleTextColor?: string;
}

export default function ImageIndicator(
  props: IImageIndicatorProps
): JSX.Element | null {
  const {
    image,
    imageHeight,
    imageRadius,
    imageWidth,
    onLoadEnd,
    overlaySubtitle,
    overlayTitle,
    style = {},
    subtitleTextColor = Colors.N0,
    textAlignmentHorizontal = ItemAlignment.Center,
    textAlignmentVertical = ItemAlignment.Center,
    titleTextColor = Colors.N0,
  } = props;

  const textAlignmentHorizontalFlex = getFlexForAlignment(
    textAlignmentHorizontal
  );
  const textAlignmentVerticalFlex = getFlexForAlignment(textAlignmentVertical);

  if (!image) return null;

  return (
    <View style={style}>
      <KitImage
        source={{
          uri: parseImageUrl(
            image,
            imageWidth,
            imageHeight,
            ImageServiceType.ImageJpeg
          ),
        }}
        style={{
          borderRadius: imageRadius,
          flex: 1,
        }}
        resizeMode='cover'
        onLoadEnd={onLoadEnd}
      />

      <Show show={Boolean(overlayTitle || overlaySubtitle)}>
        <View
          style={{
            backgroundColor: Colors.N1000,
            borderRadius: imageRadius,
            bottom: 0,
            left: 0,
            opacity: 0.2,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />
      </Show>

      <Show show={Boolean(overlayTitle || overlaySubtitle)}>
        <View
          style={{
            alignItems: textAlignmentHorizontalFlex,
            bottom: 0,
            justifyContent: textAlignmentVerticalFlex,
            left: 0,
            padding: '7%',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <Show show={Boolean(overlayTitle)}>
            <KitText
              style={{ lineHeight: 18 }}
              fontSize={14}
              semiBold
              color={titleTextColor}
            >
              {overlayTitle}
            </KitText>
          </Show>
          <Show show={Boolean(overlaySubtitle)}>
            <KitText
              style={{ lineHeight: 18, opacity: 0.7 }}
              fontSize={13}
              color={subtitleTextColor}
            >
              {overlaySubtitle}
            </KitText>
          </Show>
        </View>
      </Show>
    </View>
  );
}
