import ClearCache from './ClearCache';
import CreateCheckIns from './CreateCheckIns';
import CreateCheckOuts from './CreateCheckOuts';
import GetCheckIns from './GetCheckIns';
import GetKiosk from './GetKiosk';
import GetKioskDevice from './GetKioskDevice';
import GetKiosks from './GetKiosks';
import GetSuggestedSessions from './GetSuggestedSessions';
import GetTokenData from './GetTokenData';
import GetUnlockCodes from './GetUnlockCodes';
import VerifySetupCode from './VerifySetupCode';

export default {
  ClearCache,
  CreateCheckIns,
  GetCheckIns,
  GetTokenData,
  GetKiosks,
  GetKiosk,
  GetUnlockCodes,
  GetSuggestedSessions,
  CreateCheckOuts,
  GetKioskDevice,
  VerifySetupCode,
};
