import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../theming/Colors';

interface IKitProgressProps {
  percentage: number;
  progressContainerStyle?: ViewStyle;
  percentageStyle?: ViewStyle;
}

export default function KitProgress(props: IKitProgressProps): JSX.Element {
  return (
    <View style={[styles.progressContainer, props.progressContainerStyle]}>
      <View
        style={[
          styles.progress,
          props.percentageStyle,
          {
            width: `${props.percentage}%`,
          },
        ]}
      />
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  progressContainer: {
    alignSelf: 'center',
    width: '100%',
    height: 4,
    backgroundColor: Colors.N100,
    borderRadius: 2,
    overflow: 'hidden',
  },
  progress: {
    position: 'absolute',
    alignSelf: 'flex-start',
    height: 4,
    bottom: 0,
    backgroundColor: Colors.brand,
  },
});
