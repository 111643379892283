import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import React, { ReactNode } from 'react';

import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';

interface DropdownProps {
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
}

export default function Dropdown({
  children,
  style = {},
}: DropdownProps): JSX.Element {
  return <View style={[styles.dropdown, style]}>{children}</View>;
}

const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: Colors.N0,
    borderRadius: BorderRadius.m,
    overflow: 'hidden',
    paddingVertical: Spacing.s,
    ...Platform.select({
      android: { elevation: 10, overflow: 'hidden' },
      default: {
        shadowColor: '#000000',
        shadowOffset: { height: 3, width: 0 },
        shadowOpacity: 0.06,
        shadowRadius: 6,
      },
    }),
  },
});
