import React from 'react';
import { Platform } from 'react-native';
import ImagePicker, {
  Image as ImagePickerImage,
  Options as ImagePickerOptions,
} from 'react-native-image-crop-picker';

import { IFile, MessageType } from '../../../../shared/redux/types';
import ActionButton from './ActionButton';
import ActionButtonWeb from './ActionButtonWeb';

interface ImageActionButtonProps {
  onMessageSent?: () => void;
  sendFileMessage: (file: IFile) => void;
}

export default function ImageActionButton({
  onMessageSent,
  sendFileMessage,
}: ImageActionButtonProps): JSX.Element {
  const _onPhotoSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imageObject = event.target.files?.[0];

    if (!imageObject) return;

    const img = document.createElement('img');
    const Url = window.URL || window.webkitURL;

    img.src = Url.createObjectURL(imageObject);
    img.onload = () => {
      if (img.width !== 0 && img.height !== 0) {
        const ratio = img.width / img.height;
        const file: IFile = {
          customType: MessageType.Photo,
          data: { ratio: `${ratio}` },
          file: imageObject,
          name: imageObject.name,
          type: imageObject.type,
        };
        sendFileMessage(file);
      }
    };
  };

  const _onPhotoPress = async () => {
    const options: ImagePickerOptions = { mediaType: 'photo' };
    ImagePicker.openPicker(options)
      .then((image) => {
        const imageObject = image as ImagePickerImage;

        if (imageObject.width !== 0 && imageObject.height !== 0) {
          const ratio = imageObject.width / imageObject.height;
          const file: IFile = {
            customType: MessageType.Photo,
            data: { ratio: `${ratio}` },
            name: imageObject.filename || 'file',
            type: imageObject.mime || 'unknown',
            uri: imageObject.path,
          };

          sendFileMessage(file);
        }
      })
      .catch(() => undefined);
    onMessageSent?.();
  };

  return Platform.OS === 'web' ? (
    <ActionButtonWeb icon='image' label='Image' as='label'>
      <input
        accept='image/*'
        onChange={_onPhotoSelection}
        style={{ display: 'none' }}
        type='file'
      />
    </ActionButtonWeb>
  ) : (
    <ActionButton icon='image' label='Image' onPress={_onPhotoPress} />
  );
}
