import { KitButton, KitText } from '@omni/kit/components';
import ActionSheetItem from '@omni/kit/components/KitActionSheetItem';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { Sender } from '@sendbird/chat/message';
import React from 'react';
import { View } from 'react-native';

export default function UserActionSheet({
  isVisible,
  user,
  onClose,
  onReportUser,
  onBlockUser,
  isChannel,
}: {
  isVisible: boolean;
  user: Sender | null;
  onClose: () => void;
  onReportUser: () => void;
  onBlockUser: () => void;
  isChannel?: boolean;
}): JSX.Element | null {
  const { sizeClass } = useSizeClass();

  if (!user) {
    return null;
  }

  const _onReportUser = () => {
    onReportUser();
  };

  const _onBlockUser = () => {
    onBlockUser();
  };

  const userData = user as any;
  const profileUrl = userData._embedded?.['chat-user']?.profile_url;

  return (
    <KitModalV2
      isVisible={isVisible}
      onClose={onClose}
      anchorBottom={sizeClass === SizeClass.Small}
      coverScreen
      modalStyle={{
        padding: Spacing.l,
        alignItems: 'flex-start',
        paddingBottom: Spacing.xl,
      }}
    >
      <View
        style={{
          width: sizeClass === SizeClass.Small ? '100%' : 500,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: Spacing.xl,
        }}
      >
        <KitAvatar
          imageUrl={isChannel ? profileUrl : user.plainProfileUrl}
          nickname={user.nickname}
          size={64}
        />
        <KitText
          black
          extraBold
          fontSize={22}
          style={{ marginLeft: Spacing.l }}
        >
          {user.nickname}
        </KitText>
      </View>

      <ActionSheetItem
        icon='flag'
        iconColor={Colors.N900}
        label='Report'
        onPress={_onReportUser}
      />
      <ActionSheetItem
        icon='block'
        iconColor={Colors.R500}
        label='Block'
        onPress={_onBlockUser}
      />
    </KitModalV2>
  );
}
