import {
  SOURCE_TYPES,
  SourceContextProvider,
} from '@omni/kit/contexts/SourceContext';
import { ApplicationContextProvider } from '@omni/native-shell/contexts/ApplicationContext';
import React from 'react';

import BlockPage from './BlockPage';
import BlockPageWrapper from './BlockPageWrapper';
import { BlockPageProps } from './types';

/**
 * BlockPageApp
 * This is a 'temporary' app component for Block Page that can be
 * used to render a BlockPage in misc packages until we can
 * 1. Move the ios and android shells into a native shell package
 * 2. Port the native app main tabs and navigation to react-native
 * so that we can define a single global ApplicationContext for the entire native shell
 * 3. Define a route in the nativeShell package for block page and pass block page
 * specific root props through the shell
 * 4. Update native handler for block page to dispatch it via the nativeShell
 * 5. Update all packages that render block page to just render a BlockPageWrapper
 * since the global ApplicationContext will already be provided by the shell.
 * ref: https://subsplash.atlassian.net/wiki/spaces/UWA/pages/2507505676/Unified+Shell+App+Paradigm
 */
export default function BlockPageApp(props: BlockPageProps): JSX.Element {
  return (
    <SourceContextProvider source={SOURCE_TYPES.APP}>
      <ApplicationContextProvider {...props}>
        <BlockPageWrapper {...props}>
          <BlockPage {...props} />
        </BlockPageWrapper>
      </ApplicationContextProvider>
    </SourceContextProvider>
  );
}
