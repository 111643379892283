import Colors from '@omni/kit/theming/Colors';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import React from 'react';

import AuthNavigator from './AuthNavigator';

const StackTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Colors.N0,
    primary: Colors.N900,
  },
};

const Auth = ({
  appKey,
  targetAuthProviderId,
}: {
  appKey: string;
  targetAuthProviderId: string;
}): JSX.Element => {
  return (
    <NavigationContainer theme={StackTheme} documentTitle={{ enabled: false }}>
      <AuthNavigator
        appKey={appKey}
        targetAuthProviderId={targetAuthProviderId}
      />
    </NavigationContainer>
  );
};

export default Auth;
