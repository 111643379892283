import KitAvatar from '@omni/kit/components/KitAvatar';
import React from 'react';
import { FlexAlignType, View } from 'react-native';

import { IBlockProps } from './types';

export interface IAvatarBlockProps extends IBlockProps {
  imageUrl?: string;
  nickname: string;
  size: number;
  flexAlign: FlexAlignType;
  marginBottom: number;
}

/** Supported in release 5.21.0+ */
export default function AvatarBlock(props: IAvatarBlockProps): JSX.Element {
  const {
    imageUrl = '',
    nickname = '',
    size = 64,
    flexAlign = 'center',
    marginBottom = 12,
  } = props;

  return (
    <View
      style={{ flex: 1, alignItems: flexAlign, marginBottom: marginBottom }}
    >
      <KitAvatar imageUrl={imageUrl} nickname={nickname} size={size} />
    </View>
  );
}
