import {
  KitButton,
  KitInput,
  KitModal,
  KitSnack,
  KitText,
} from '@omni/kit/components';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Platform, TextInput, View } from 'react-native';

export interface ChangePasswordModalProps {
  clientBrandColor?: string;
  saveNewPassword: (
    oldPass: string,
    newPass: string,
    confirmPass: string
  ) => Promise<void>;
  setVisible: (visible: boolean) => void;
  visible: boolean;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  clientBrandColor,
  visible,
  setVisible,
  saveNewPassword,
}): JSX.Element => {
  const oldPasswordRef = useRef<TextInput>(null);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // ---------------------------------------------------------------------------
  // Computed State
  const isValidPassword = useMemo((): boolean => {
    /** Password must contain at least eight characters */
    const passwordRegex = /^.{8,}$/g;

    return Boolean(
      newPassword.match(passwordRegex) &&
        confirmPassword.match(passwordRegex) &&
        oldPassword !== '' &&
        newPassword === confirmPassword
    );
  }, [newPassword, confirmPassword, oldPassword]);

  const handleSave = async (): Promise<void> => {
    try {
      if (isValidPassword) {
        await saveNewPassword(oldPassword, newPassword, confirmPassword);
        handlePasswordUpdated(true);
      } else {
        KitSnack.show(
          'New passwords must match and contain at least eight characters'
        );
      }
    } catch (e) {
      handlePasswordUpdated(false);
    }
  };

  const handlePasswordUpdated = (success = true): void => {
    setVisible(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');

    if (success) {
      KitSnack.show('Password updated');
    } else {
      KitSnack.show(
        'Unable to save. Please try again.',
        KitSnackDuration.SHORT
      );
    }
  };

  // ---------------------------------------------------------------------------
  // Effects

  useEffect((): void => {
    // Fix for android to auto-focus the input on open
    visible
      ? Platform.OS === 'android'
        ? setTimeout(() => oldPasswordRef.current?.focus(), 150)
        : oldPasswordRef.current?.focus()
      : oldPasswordRef.current?.blur();
  }, [visible]);

  return (
    <KitModal visible={visible} setVisible={setVisible}>
      <View style={{ margin: Spacing.xl }}>
        <KitText h1>Change password</KitText>
        <KitInput
          inputRef={oldPasswordRef}
          label='Old password'
          marginValue='18px 0 18px 0'
          onChangeText={setOldPassword}
          secureTextEntry={true}
          testID='OldPassword'
          value={oldPassword}
        />
        <KitInput
          label='New password'
          onChangeText={setNewPassword}
          secureTextEntry={true}
          testID='NewPassword'
          value={newPassword}
        />
        <KitInput
          label='Re-enter new password'
          marginValue='18px 0 18px 0'
          onChangeText={setConfirmPassword}
          secureTextEntry={true}
          testID='ConfirmPaaswrd'
          value={confirmPassword}
        />
        <KitButton
          color={clientBrandColor}
          disabled={!isValidPassword}
          onPress={handleSave}
          testID='SetPasswordButton'
          title='Set password'
        />
      </View>
    </KitModal>
  );
};

export default ChangePasswordModal;
