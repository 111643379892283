import AccountsService from '../../services/AccountsService';
import { AuthProvider } from './Types';
import { excludeSocialAuthProviders } from './excludeSocialAuthProviders';
import { fetchAuthProviders } from './fetchAuthProviders';

export const fetchTargetAuthProviders = async (
  appKey?: string
): Promise<AuthProvider[] | undefined> => {
  if (!appKey) {
    return undefined;
  }

  let providers: AuthProvider[] = [];
  try {
    const app = await AccountsService.getApp(appKey);
    const orgKey = app?.org_key;

    providers = await fetchAuthProviders({ appKey, orgKey });
  } catch {}

  providers = excludeSocialAuthProviders(providers);

  return providers;
};
