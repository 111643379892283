import { KitButton, KitText } from '@omni/kit/components';

import Colors from '@omni/kit/theming/Colors';
import { ConfirmModalProps } from '@omni/kit/components/KitConfirmModal';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';
import { View } from 'react-native';

export default function ConfirmModal({
  title,
  message,
  cancelButtonText,
  confirmButtonText,
  setConfirmModalVisible,
  onConfirm,
  action,
}: ConfirmModalProps): JSX.Element {
  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor: '#00000040',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: Colors.N0,
          borderRadius: 12,
          justifyContent: 'space-between',
          paddingHorizontal: Spacing.xl,
          paddingTop: Spacing.xl,
        }}
      >
        <View>
          <KitText black bold fontSize={20} style={{ marginBottom: Spacing.l }}>
            {title}
          </KitText>
          {message && (
            <KitText black style={{ marginBottom: Spacing.xl }}>
              {message}
            </KitText>
          )}
        </View>

        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: Spacing.m,
          }}
        >
          <KitButton
            secondary
            title={cancelButtonText}
            marginValue='0 12px'
            onPress={() => setConfirmModalVisible(false)}
          />
          <KitButton
            red
            title={confirmButtonText}
            onPress={() => onConfirm(action)}
          />
        </View>
      </View>
    </View>
  );
}
