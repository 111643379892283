import { Image as ImagePickerImage } from 'react-native-image-crop-picker';

import { PlatformAgnosticFile } from '../../FileTypes';

export const webImageToAgnosticFile = (image: File): PlatformAgnosticFile => {
  const uri = URL.createObjectURL(image);

  const file: PlatformAgnosticFile = {
    contentType: image.type,
    fileName: image.name,
    uri: uri,
    size: image.size,
    _original: image,
  };

  return file;
};

export const nativeImageToAgnosticFile = (
  image: ImagePickerImage
): PlatformAgnosticFile => {
  const file: PlatformAgnosticFile = {
    contentType: image.mime,
    fileName: image.filename || 'unknown',
    uri: image.path,
    size: image.size,
    _original: image,
  };

  return file;
};

export const isLocalFileUrl = (url: string): boolean => {
  return (
    url.startsWith('/') || url.startsWith('file://') || url.startsWith('blob:')
  );
};
