import { KitIcon, KitText, KitTouchable } from '@omni/kit/components';
import KitPopup from '@omni/kit/components/KitPopup.web';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import AddMeetingLocationScreen from '@omni/messaging/shared/scenes/channelDetails/AddMeetingLocationScreen';
import MoreActionSheet from '@omni/messaging/shared/scenes/channelDetails/components/details/MoreActionSheet';
import { createStackNavigator } from '@react-navigation/stack';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React, { useCallback } from 'react';
import { Platform, View } from 'react-native';
import { StyleSheet } from 'react-native';
import { RootSiblingParent } from 'react-native-root-siblings';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { useSelector } from 'react-redux';

import { LeftNavType } from '../../../mobile/components/LeftNavButton';
import { getNavOptions } from '../../../mobile/navigation/AppNavigator';
import {
  ADD_MEETING_LOCATION,
  ADD_MEETING_SCHEDULE,
  CHANNEL_DETAILS_SCREEN,
  EDIT_CHANNEL_SCREEN,
  INVITE_SCREEN,
  MEMBER_LIST_SCREEN,
  VISIBILITY_AND_ENROLLMENT_SCREEN,
} from '../../Constants';
import { channelSelector } from '../../redux/selectors';
import { ChannelType } from '../../redux/types';
import AddMeetingScheduleScreen from './AddMeetingScheduleScreen';
import ChannelDetailsScreen from './ChannelDetailsScreen';
import EditChannelScreen from './EditChannelScreen';
import InviteScreen from './InviteScreen';
import MemberListScreen from './MemberListScreen';
import VisibilityAndEnrollmentScreen from './VisibilityAndEnrollmentScreen';

const debug = require('debug')('tca:chat:screen:ChannelDetailStack');

// @ts-ignore
const Stack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

export const ChannelDetailsStack = ({
  closeModal,
}: {
  closeModal?: () => void;
}) => {
  const channel = useSelector(channelSelector) as GroupChannel;
  const { windowHeight, windowWidth } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  const parseChannelData = useCallback(() => {
    try {
      const data = JSON.parse(channel.data);
      debug('channel data ', data);

      return data;
    } catch {
      debug('Could not parse channel data');
    }
  }, [channel]);

  return (
    <RootSiblingParent>
      <Stack.Navigator
        screenOptions={{
          // @ts-ignore
          headerHideShadow: true,
          headerStyle: {
            elevation: 0,
            shadowColor: 'transparent',
            height: 78,
            borderBottomColor: Colors.N100,
            borderBottomWidth: 1,
          },
          headerTopInsetEnabled: false, // react-native-screens (Android)
        }}
      >
        <Stack.Screen
          name={CHANNEL_DETAILS_SCREEN}
          component={ChannelDetailsScreen}
          options={({ route, navigation }) => {
            return {
              ...getNavOptions(
                channel?.customType === ChannelType.Direct
                  ? 'Conversation details'
                  : '',
                { navigation },
                LeftNavType.Back,
                closeModal
              ),
              headerRight: () =>
                channel?.customType !== ChannelType.Direct && (
                  <View style={{ flexDirection: 'row' }}>
                    {channel?.myRole === 'operator' && (
                      <KitTouchable
                        onPress={() => {
                          navigation.navigate(EDIT_CHANNEL_SCREEN, {
                            channelData: parseChannelData(),
                          });
                        }}
                        style={{
                          padding: 12,
                          marginRight: 0,
                          borderRadius: 22,
                        }}
                      >
                        <KitIcon size={20} name='edit' />
                      </KitTouchable>
                    )}

                    {Platform.OS === 'web' ? (
                      <KitPopup
                        content={
                          <MoreActionSheet
                            // @ts-ignore
                            isManager={route.params?.isManager}
                            // @ts-ignore
                            canLeave={route.params?.canLeave}
                            // @ts-ignore
                            isMuted={route.params?.isMuted}
                            // @ts-ignore
                            onLeaveGroup={() => route.params?.onLeaveGroup()}
                            // @ts-ignore
                            onDeleteGroup={() => route.params?.onDeleteGroup()}
                            // @ts-ignore
                            onMuteGroup={() => route.params?.onMuteGroup()}
                          />
                        }
                        contentStyle={{
                          paddingHorizontal: 0,
                          paddingVertical: Spacing.l,
                        }}
                        position='bottom right'
                        triggerType='click'
                      >
                        <KitTouchable
                          style={{
                            padding: 12,
                            borderRadius: 22,
                            marginRight: Platform.OS === 'web' ? 6 : 0,
                          }}
                        >
                          <KitIcon size={20} name='action-h' />
                        </KitTouchable>
                      </KitPopup>
                    ) : (
                      <KitTouchable
                        // @ts-ignore
                        onPress={() => route.params?.openMoreActionModal()}
                        style={{
                          padding: 12,
                          borderRadius: 22,
                        }}
                      >
                        <KitIcon size={20} name='action-h' />
                      </KitTouchable>
                    )}
                  </View>
                ),
            };
          }}
        />
        <Stack.Screen
          name={EDIT_CHANNEL_SCREEN}
          component={EditChannelScreen}
          options={(props) => ({
            ...getNavOptions('Edit Group', props),
            headerRight: () => (
              <KitTouchable
                onPress={null}
                style={{
                  paddingLeft: 12,
                  paddingRight: Platform.OS === 'ios' ? 12 : 18,
                  paddingVertical: 6,
                  marginRight: -6,
                  borderRadius: 40,
                }}
              >
                <KitText color={Colors.N400} fontSize={17}>
                  Save
                </KitText>
              </KitTouchable>
            ),
          })}
        />
        <Stack.Screen
          name={VISIBILITY_AND_ENROLLMENT_SCREEN}
          component={VisibilityAndEnrollmentScreen}
          // @ts-ignore
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions('Visibility and enrollment', { navigation }),
            headerRight: () => (
              <KitTouchable
                onPress={null}
                style={{
                  paddingLeft: 12,
                  paddingRight: Platform.OS === 'ios' ? 12 : 18,
                  paddingVertical: 6,
                  marginRight: -6,
                  borderRadius: 40,
                }}
              >
                <KitText color={Colors.N400} fontSize={17}>
                  Save
                </KitText>
              </KitTouchable>
            ),
          })}
        />
        <Stack.Screen
          name={ADD_MEETING_LOCATION}
          component={AddMeetingLocationScreen}
          // @ts-ignore
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions('', { navigation }),
            stackPresentation: 'modal',
            headerLeft: () => (
              <KitTouchable onPress={navigation.goBack}>
                <KitIcon name='remove' size={20}></KitIcon>
              </KitTouchable>
            ),
          })}
        />
        <Stack.Screen
          name={ADD_MEETING_SCHEDULE}
          component={AddMeetingScheduleScreen}
          // @ts-ignore
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions('', { navigation }),
            stackPresentation: isLandscape ? 'modal' : 'push',
            headerShown: !isLandscape,
            headerLeft: () => (
              <KitTouchable
                onPress={navigation.goBack}
                style={{
                  padding: 12,
                  borderRadius: 22,
                  marginLeft: Platform.OS === 'ios' ? -10 : 0,
                }}
              >
                <KitIcon name='remove' size={20}></KitIcon>
              </KitTouchable>
            ),
          })}
        />
        <Stack.Screen
          name={MEMBER_LIST_SCREEN}
          component={MemberListScreen}
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions(route.params?.title || 'Members', { navigation }),
            stackPresentation: 'modal', // This will present the screen as a modal
          })}
        />

        <Stack.Screen
          name={INVITE_SCREEN}
          component={InviteScreen}
          // options={(props) => getNavOptions('Add people', props)}
          options={({ navigation, route }) => ({
            ...getNavOptions('Add people', { navigation }),
            stackPresentation: 'modal', // This will present the screen as a modal
          })}
        />
      </Stack.Navigator>
    </RootSiblingParent>
  );
};
