/**
 * Can be overridden by any consuming app through the
 * `setRequestHeaderOverrides` method exported by Downloader.
 */
export let SharedAnalyticsHeaderOverrides: Record<string, string> = {};

export default function setAnalyticsHeaderOverrides(
  headers: Record<string, string>
): void {
  SharedAnalyticsHeaderOverrides = headers;
}
