import { convertToListItem } from '@omni/kit/feeds/ListItemConverter';
import { SearchResultItemProps } from '@omni/kit/feeds/searchListItemTypes';
import { IGroup } from '@omni/kit/services/GroupsService/Types';
import { IMediaItem } from '@omni/kit/services/MediaItemService/Types';
import { ISearchResponse } from '@omni/kit/services/SearchService';
import { IAppSearchHit } from '@omni/kit/services/SearchService/AppSearchHitTypes';
import { extractSearchResults } from '@omni/kit/services/SearchService/extractSearchResults';
import { ITag } from '@omni/kit/services/TagService/Types';

/**
 * Re-shape hits payload, by creating an array with all the response objects
 * including their type. The `type` is used to format each object into
 * a specific format.
 *
 * @example (type - [app, media-item, media-series, tag:speaker]
 * @param results
 */
export function parseSearchResults(
  response: ISearchResponse,
  useBlockPage?: boolean
): SearchResultItemProps[] {
  const results = extractSearchResults(response);

  const listItems: SearchResultItemProps[] = results.map(
    (item: IMediaItem | ITag | IAppSearchHit | IGroup) => {
      return convertToListItem(item, item.type, useBlockPage);
    }
  );

  return listItems;
}
