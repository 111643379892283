import Environment from '@omni/kit/Environment';
import TokensService from '@omni/kit/services/TokensService';

import BaseServiceV2, { buildUrl } from '../BaseServiceV2';
import { BaseGetProps, BaseGetReturn } from '../BaseServiceV2/Core/Get';
import getCustomProfileHeader from './GetCustomProfileHeader';
import getFavorites from './GetFavorites';
import getFeaturedApps from './GetFeaturedApps';
import getSetup from './GetSetup';
import getUserProfile from './GetUserProfile';
import { FeedList } from './types/FeedList';
import {
  FeedMediaDetailRelatedResponse,
  FeedMediaDetailResponse,
} from './types/FeedMediaDetail';
import { FeedMediaPlaylistResponse } from './types/FeedMediaPlaylist';
import { FeedMediaSeries } from './types/FeedMediaSeries';

/** Here for backwards compat */
export * from './GetFeaturedApps';

/** Here for backwards compat */
export { getCustomProfileHeader };
export { getFavorites };
export { getFeaturedApps };
export { getUserProfile };

/**
 * The preferred way to import these functions:
 * `import FeedsService from '@omni/kit/services/FeedsService';`
 */
export default {
  getCustomProfileHeader,
  getFavorites,
  getFeaturedApps,
  getList,
  getMediaDetail,
  getMediaDetailRelated,
  getMediaPlaylist,
  getMediaSeries,
  getTV,
  getSetup,
  getUserProfile,
};

type GetOptionsProps<CustomResourceType> = CustomResourceType &
  Partial<BaseGetProps>;

async function getList({
  listId,
  ...feedProps
}: GetOptionsProps<{ listId: string }>): BaseGetReturn<FeedList> {
  return _BaseGet('list', listId, feedProps);
}

async function getMediaSeries({
  seriesId,
  ...feedProps
}: GetOptionsProps<{ seriesId: string }>): BaseGetReturn<FeedMediaSeries> {
  return _BaseGet('media-series', seriesId, feedProps);
}

async function getMediaDetail({
  itemId,
  ...feedProps
}: GetOptionsProps<{
  itemId: string;
}>): BaseGetReturn<FeedMediaDetailResponse> {
  return _BaseGet('media-detail', itemId, feedProps);
}

async function getMediaDetailRelated({
  itemId,
  ...feedProps
}: GetOptionsProps<{
  itemId: string;
}>): BaseGetReturn<FeedMediaDetailRelatedResponse> {
  return _BaseGet('media-detail', `${itemId}/related`, feedProps);
}

async function getMediaPlaylist({
  itemId,
  ...feedProps
}: GetOptionsProps<{
  itemId: string;
}>): BaseGetReturn<FeedMediaPlaylistResponse> {
  return _BaseGet('media-playlist', itemId, feedProps);
}

async function getTV({
  appKey,
  ...feedProps
}: GetOptionsProps<{ appKey: string }>): BaseGetReturn<
  FeedMediaSeries | FeedList
> {
  return _BaseGet('tv', appKey, { ...feedProps, getFromCache: false, appKey });
}

async function _BaseGet<T>(
  resource: string,
  id: string,
  props: Omit<BaseGetProps, 'url'>
): BaseGetReturn<T> {
  const url = buildUrl({
    host: Environment.feedsService,
    resource,
    id,
    pluralizeResource: false,
  });

  return BaseServiceV2.Get({
    ...props,
    token: props.token ?? (await TokensService.getGuestToken()),
    url,
  });
}
