import { KitFloatingButton } from '@omni/kit/components';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Spacing from '@omni/kit/theming/Spacing';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { OpenChannel } from '@sendbird/chat/openChannel';
import React, { useEffect, useMemo } from 'react';
import { FlatList, Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { onMessageReceived } from '../../../utilities/sendbird/chatFunctions';
import Routes from '../../../web/navigation/Routes';
import {
  canCreateChannelsSelector,
  canCreateDirectMessageSelector,
  channelListSelector,
  channelSelector,
} from '../../redux/selectors';
import { ChannelType } from '../../redux/types';
import JoinRequestsCount from '../joinRequests/components/JoinRequestsCount';
import ChannelListRow from './components/ChannelListRow';
import EmptyConversationList from './components/EmptyConversationList';
import EmptyConversationListLarge from './components/EmptyConversationListLarge';

// TODO - currently only used on web, use this same component for mobile
interface ChannelListProps {
  onChannelSelection: (channel: GroupChannel | OpenChannel) => void;
  onFloatingButtonPressed?: () => void;
}
export default function ChannelList({
  onChannelSelection,
  onFloatingButtonPressed,
}: ChannelListProps) {
  //  const viewIsReady = useSelector(viewIsReadySelector);
  //  const user = useSelector(userSelector);
  const channelList = useSelector(channelListSelector);
  //  const selectedChannel = useSelector(channelSelector);
  const canCreateDirectMessage = useSelector(canCreateDirectMessageSelector);
  const canCreateChannels = useSelector(canCreateChannelsSelector);
  const selectedChannel = useSelector(channelSelector);

  const history = useHistory();
  const params = useParams();

  const { sizeClass } = useSizeClass();

  const nonBlockedChannels = useMemo(() => {
    return channelList?.filter((channel) => {
      if (channel.customType !== ChannelType.Direct) {
        return true;
      }

      const blockedMembers = channel.members.filter(
        (member) => member.isBlockedByMe
      );
      const allMembersBlocked =
        blockedMembers.length === channel.memberCount - 1;

      return !allMembersBlocked;
    });
  }, [channelList]);

  const _navToDiscover = () => {
    // @ts-ignore
    history.push(Routes.groups(params.appKey));
  };

  const totalChannels = channelList?.length || 0;
  useEffect(() => {
    if (totalChannels > 0 && Platform.OS === 'web' && window.parent) {
      const cleanup = onMessageReceived(() => {
        // SECURITY NOTE: Messages send through `postMessage` are publicly accessible.
        // Do not send any sensitive data through! This is used by the web embed.
        window.parent.postMessage(
          { type: 'subsplash_messaging_message_received' },
          '*'
        );
      });

      return () => {
        cleanup();
      };
    }
  }, [totalChannels]);

  const _renderList = ({ item: channel }: { item: GroupChannel }) => {
    const selected = selectedChannel?.url === channel?.url;

    return (
      <ChannelListRow
        channel={channel}
        selected={selected}
        onChannelSelection={onChannelSelection}
      />
    );
  };

  const _keyExtractor = (item: GroupChannel) => item.url;

  const _renderChannelList = () => {
    if (nonBlockedChannels && nonBlockedChannels.length === 0) {
      return sizeClass === SizeClass.Small ? (
        <EmptyConversationList
          onCreatePressed={() => onFloatingButtonPressed?.()}
        />
      ) : (
        <EmptyConversationListLarge navToDiscover={_navToDiscover} />
      );
    } else {
      return (
        <FlatList<GroupChannel>
          contentInset={{ bottom: Spacing.xxl }}
          data={nonBlockedChannels}
          renderItem={_renderList}
          inverted={false}
          keyExtractor={_keyExtractor}
          contentContainerStyle={[
            // Fixes scroll on small screen web
            // @ts-ignore
            Platform.OS === 'web' && sizeClass === SizeClass.Small
              ? { height: '100vh' }
              : {},
          ]}
          showsVerticalScrollIndicator={sizeClass !== SizeClass.Small}
          ListHeaderComponent={<JoinRequestsCount />}
          ListFooterComponent={
            Platform.OS !== 'web' ? <View style={{ height: 100 }} /> : undefined
          }
        />
      );
    }
  };

  return (
    <>
      {_renderChannelList()}
      {(canCreateDirectMessage || canCreateChannels) &&
        sizeClass === SizeClass.Small &&
        onFloatingButtonPressed && (
          <View
            style={{
              position: 'absolute',
              bottom: Spacing.l,
              right: Spacing.l,
            }}
          >
            <KitFloatingButton icon='add' onPress={onFloatingButtonPressed} />
          </View>
        )}
      <View
        style={{
          marginLeft: 8,
          marginBottom: 8,
          position: 'absolute',
          bottom: Spacing.s,
          left: Spacing.l,
        }}
      />
    </>
  );
}
