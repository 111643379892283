import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import {
  isWithinIframe,
  postContentHeight,
} from '@omni/kit/utilities/utilities';
import { useIsFocused } from '@react-navigation/native';
import React, { useContext, useEffect, useState } from 'react';
import { Platform, ScrollView, View, ViewStyle } from 'react-native';

/**
 * Full screen wrapper
 */
interface Props {
  children?: React.ReactNode;
  style?: ViewStyle;
}
export default ({ children, style }: Props): JSX.Element => {
  const { colorForScheme } = useContext(ThemeContext);
  const isFocused = useIsFocused();
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if (isFocused) {
      // This is so the Group Finder embed will resize when
      // navigating back to the Search screen
      postContentHeight(height);
    }
  }, [height, isFocused]);

  return (
    <Wrapper>
      <View
        style={{
          backgroundColor: colorForScheme?.({
            light: Colors.N0,
            dark: Colors.N1000,
          }),
          paddingTop: Spacing.s,
          height: '100%',
          width: '100%',
          ...(Platform.OS === 'web' && {
            height: undefined,
            minHeight: 600,
            maxWidth: 936,
            margin: 'auto',
            paddingTop: Spacing.xl,
          }),

          ...style,
        }}
        onLayout={(e) => {
          // This is to support dynamically sizing the Group Finder embed
          // To test in an iframe, use a codepen, e.g. https://codepen.io/jo_ok/pen/yLWoLLy
          setHeight(e.nativeEvent.layout.height);
          postContentHeight(e.nativeEvent.layout.height);
        }}
      >
        {children}
      </View>
    </Wrapper>
  );
};

const Wrapper = ({ children }: Props): JSX.Element => {
  if (Platform.OS === 'web' && !isWithinIframe()) {
    return <ScrollView>{children}</ScrollView>;
  }

  return <>{children}</>;
};
