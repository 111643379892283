import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import { useEffect, useState } from 'react';

/*
 * Do not use this directly in a component, instead use KioskContext
 * */
export default (): [
  string | undefined,
  (selectedPrintStationId: string | undefined) => Promise<void>
] => {
  const [selectedPrintStationId, setSelectedPrintStationId] = useState<
    string | undefined
  >();

  useEffect(() => {
    const getSelectedPrintStation = async () => {
      const selectedPrintStationId = await getStoredItem(
        'selected-print-station-id'
      );

      if (selectedPrintStationId) {
        setSelectedPrintStationId(selectedPrintStationId);
      } else {
        setSelectedPrintStationId(undefined);
      }
    };
    getSelectedPrintStation();
  }, []);

  const saveSelectedPrintStation = async (printerId: string | undefined) => {
    if (printerId) {
      await setStoredItem('selected-print-station-id', printerId);
    } else {
      await removeStoredItem('selected-print-station-id');
    }

    setSelectedPrintStationId(printerId);
  };

  return [selectedPrintStationId, saveSelectedPrintStation];
};
