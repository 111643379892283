import { AUTH_STACK, AuthNavigator, AuthRedirect } from '@omni/kit/auth';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import useDocumentTitle from '@omni/kit/hooks/useDocumentTitle';
import useFavicon from '@omni/kit/hooks/useFavicon';
import { isWithinIframe } from '@omni/kit/utilities/utilities';
import Header from '@omni/web-header';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import { useChatAuth } from '../../context/ChatAuthContext';
import useAppInfo from '../../hooks/useAppInfo';
import MessagingTabs from '../../mobile/navigation/TopTabNavigator';
import ChatScreen from '../../mobile/scenes/channel/ChatScreen';
import { initState } from '../../shared/redux/actions/SystemActions';
import {
  accessTokenSelector,
  appKeySelector,
} from '../../shared/redux/selectors';
import NotFoundScreen from '../../shared/scenes/NotFoundScreen';
import AcceptInvitationScreen from '../scenes/AcceptInvitationScreen';
import GroupsScreen from '../scenes/discover/GroupsScreen';
import SplitScreen from '../scenes/split/SplitScreen';
import Routes from './Routes';

interface Props {
  appKey: string;
}

export default function LargeWebMessaging({
  appKey,
}: Props): JSX.Element | null {
  const { isAuthenticated, isReady: isAuthReady } = useChatAuth();

  const dispatch = useDispatch();

  const { app } = useAppInfo(appKey);

  useDocumentTitle(app?.title || '', 'Messaging');
  useFavicon(app?.id || '');

  useEffect(() => {
    dispatch(initState({ appKey }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appKey]);

  if (!isAuthReady) return null;

  return (
    <Router>
      <View style={{ flex: 1 }}>
        {isWithinIframe() ? null : <Header />}
        <Switch>
          <Route path={Routes.appKey}>
            <AppKeyRoutes
              isAuthenticated={isAuthenticated}
              isAuthReady={isAuthReady}
            />
          </Route>
          {/* TODO - if your appkey is not valid, go to the not found screen */}
          <Route path={Routes.catchall}>
            <NotFoundScreen />
          </Route>
        </Switch>
      </View>
    </Router>
  );
}

// @ts-ignore
const Stack = createStackNavigator();

// @ts-ignore
function AppKeyRoutes({ isAuthenticated, isAuthReady }) {
  const appKey = useSelector(appKeySelector);
  const { sizeClass } = useSizeClass();
  const isSmall = sizeClass === SizeClass.Small;
  const token = useSelector(accessTokenSelector);
  const { orgKey } = useAppInfo(appKey);

  if (!appKey || appKey.length === 0) return null;

  return (
    <Switch>
      <Redirect exact from={Routes.appKey} to={Routes.channels(appKey)} />

      <Redirect exact from={Routes.discover()} to={Routes.groups(appKey)} />
      <PrivateRoute exact path={[Routes.channels(), Routes.joinRequests()]}>
        {isSmall ? <MessagingTabs screenProps={{ appKey }} /> : <SplitScreen />}
      </PrivateRoute>
      <PrivateRoute exact path={Routes.channelScreen()}>
        {isSmall ? <ChatScreen /> : <SplitScreen />}
      </PrivateRoute>

      <Route path={Routes.groups()}>
        <GroupsScreen appKey={appKey} />
      </Route>

      <Route path={Routes.acceptInvite()}>
        <AcceptInvitationScreen
          isAuthReady={isAuthReady}
          isAuthenticated={isAuthenticated}
        />
      </Route>

      <Route
        path={Routes.login()}
        render={() =>
          isAuthenticated ? (
            <AuthRedirect
              authProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
              token={token}
              redirectUrl={Routes.channels(appKey)}
            />
          ) : (
            <NavigationContainer documentTitle={{ enabled: false }}>
              <Stack.Navigator screenOptions={{ headerShown: false }}>
                <Stack.Screen name={AUTH_STACK}>
                  {(props) => (
                    <AuthNavigator
                      {...props}
                      appKey={appKey}
                      orgKey={orgKey}
                      headerShown={false}
                      targetAuthProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
                    />
                  )}
                </Stack.Screen>
              </Stack.Navigator>
            </NavigationContainer>
          )
        }
      />

      <Route>
        <NotFoundScreen />
      </Route>
    </Switch>
  );
}

// @ts-ignore
function PrivateRoute({ children, ...rest }) {
  const token = useSelector(accessTokenSelector);
  const appKey = useSelector(appKeySelector);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.login(appKey),
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
