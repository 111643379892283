import HealthMonitor from '@omni/kit/HealthMonitor';
import ChatService from '@omni/kit/services/ChatService';
import SendbirdChat, { SessionHandler } from '@sendbird/chat';

const debug = require('debug')('omni:kit:sendbirdUserFunctions');

export const sbCreateSessionHandler = (
  appKey: string,
  source?: string
): void => {
  const sessionHandler = new SessionHandler();

  /**
   * onSessionTokenRequired will be called once every 10 minutes
   * while the chat session is active.
   * Refreshing the token here will keep the Sendbird connection alive
   * until the user closes the chat.
   * ref: https://sendbird.com/docs/chat/sdk/v4/javascript/application/authenticating-a-user/authentication#2-set-a-session-handler
   */
  sessionHandler.onSessionTokenRequired = (resolve, reject) => {
    debug('onSessionTokenRequired', appKey);

    ChatService.createChatSession({
      appKey,
      source,
    })
      .then((session) => {
        const chatTokenData = session._embedded['chat-token'];

        if (chatTokenData) {
          debug('session handler resolving with new session token');

          if (chatTokenData.expires_at) {
            const expiresAtDate = new Date(chatTokenData.expires_at);
            const expiresInMsec = expiresAtDate.getTime() - Date.now();
            const expiresInMin = expiresInMsec / 1000 / 60;
            debug(`chat-token expires in ${expiresInMin} minutes`);

            if (expiresInMin < 0) {
              reject(new Error('chat-token expired'));
            } else {
              resolve(chatTokenData.token);
            }
          } else {
            resolve(chatTokenData.token);
          }
        } else {
          debug('Unable to get session token');
          reject(new Error('Unable to get session token'));
        }
      })
      .catch((err) => {
        debug('Failed to get session token', err);
        reject(err);
      });
  };
  sessionHandler.onSessionClosed = () => {
    debug('Sendbird session closed');
  };
  sessionHandler.onSessionRefreshed = () => {
    debug('Sendbird session refreshed');
  };
  sessionHandler.onSessionError = (err) => {
    debug('Sendbird session error', err);
    err.message = `Sendbird session refresh error: ${err.message}`;
    HealthMonitor.logError(err);
  };
  SendbirdChat.instance?.setSessionHandler(sessionHandler);
};
