const makeFadeInTranslation = (translationType: string, fromValue: number) => {
  return {
    from: {
      opacity: 0,
      [translationType]: fromValue,
    },
    to: {
      opacity: 1,
      [translationType]: 0,
    },
  };
};

const makeFadeOutTranslation = (translationType: string, toValue: number) => {
  return {
    from: {
      opacity: 1,
      [translationType]: 0,
    },
    to: {
      opacity: 0,
      [translationType]: toValue,
    },
  };
};

const openAnimation = makeFadeInTranslation('translateY', 30);
const closeAnimation = makeFadeOutTranslation('translateY', 30);

export default {
  animationIn: openAnimation,
  animationOut: closeAnimation,
  animationInTiming: 225,
  animationOutTiming: 225,
  hideModalContentWhileAnimating: true,
  backdropTransitionInTiming: 0,
  backdropTransitionOutTiming: 0,
  useNativeDriverForBackdrop: true,
};
