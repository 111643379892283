import { IRootProps } from '@omni/kit/contexts/types';

export type TagType = 'speaker' | 'topic';

export enum CONTEXT_TYPES {
  NETWORK = 'network',
  CACHE = 'cache',
}

export interface DynamicMediaProps extends IRootProps {
  listId?: string;
}
