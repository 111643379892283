import KitButton from '@omni/kit/components/KitButton';
import KitIcon from '@omni/kit/components/KitIcon';
import KitText from '@omni/kit/components/KitText';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { SCREEN_HEIGHT } from '@omni/kit/utilities/utilities';
import React, { useContext, useEffect, useState } from 'react';
import { Animated, Easing, View, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { IBlockProps } from './types';

// These props are publicly available
export interface IErrorBlockProps extends IBlockProps {
  callback?: () => void;
  error: number;
}

export default function ErrorBlock(props: IErrorBlockProps): JSX.Element {
  const [opacity] = useState(new Animated.Value(1));

  const { colorForScheme } = useContext(ThemeContext);

  const {
    bottomSpacing = null,
    callback,
    error,
    horizontalSpacing,
    insetStyle = {},
    topSpacing = null,
  } = props;

  const { edgeSpacing, viewPortWidth } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const containerStyle: ViewStyle = {
    maxWidth: 650,
    height: SCREEN_HEIGHT,
    marginHorizontal: edgeSpacing,
    alignSelf: 'center' as ViewStyle['alignSelf'],
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
    ...insetStyle,
  };

  const gradientStyle: ViewStyle = {
    height: 200,
    width: viewPortWidth,
    backgroundColor: '#ffffff00',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 5,
  };

  const iconColor = colorForScheme?.({ light: Colors.N900, dark: Colors.N0 });
  const titleColor = colorForScheme?.({ light: Colors.N900, dark: Colors.N0 });
  const subtitleColor = colorForScheme?.({
    light: Colors.N500,
    dark: Colors.N400,
  });
  const errorCodeColor = colorForScheme?.({
    light: Colors.N500,
    dark: Colors.N800,
  });

  useEffect((): void => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 0.2,
          duration: 2000,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 1,
          duration: 2000,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [opacity]);

  let icon = '';
  let titleText = '';
  let bodyText = '';

  // We have a few custom status codes we use to represent internal errors
  if (error === 504) {
    // Request failed, ususally due to either the device or server being offline
    icon = 'bolt';
    titleText = 'No connection';
    bodyText = 'Ensure that you have an active connection to the internet.';
  } else if (error === 505) {
    // Authorization insufficient
    icon = 'bolt';
    titleText = 'You must be logged in to access this.';
    bodyText =
      'Please ensure that you entered your username and password correctly.';
  } else if (error === 550) {
    // Bad response, we couldn't parse or make sense of the data we got back
    titleText = 'Something went wrong';
    bodyText =
      "We didn't get the response we were expecting to show this content.";
  } else {
    titleText = 'Something went wrong';
    bodyText =
      'We had a problem getting the response we need to show this content.';
  }

  return (
    <View>
      <LinearGradient
        colors={['#00000033', '#00000000']}
        style={gradientStyle}
      />
      <View style={containerStyle}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          {icon !== '' && (
            <Animated.View
              style={{
                alignItems: 'center',
                marginBottom: Spacing.l,
                opacity: opacity,
              }}
            >
              {/* @ts-ignore */}
              <KitIcon name={icon} size={60} color={iconColor} />
            </Animated.View>
          )}
          <View style={{ alignItems: 'center', marginBottom: Spacing.xl }}>
            <KitText
              h1
              color={titleColor}
              style={{ marginBottom: Spacing.l, textAlign: 'center' }}
            >
              {titleText}
            </KitText>
            <KitText
              subtitle
              color={subtitleColor}
              style={{
                marginBottom: Spacing.s,
                marginTop: Spacing.m,
                marginStart: Spacing.xl,
                marginEnd: Spacing.l,
                textAlign: 'center',
              }}
            >
              {bodyText}
            </KitText>
            <KitText
              fontSize={12}
              color={errorCodeColor}
              style={{ marginBottom: Spacing.xl, textAlign: 'center' }}
            >
              Error code: {error}
            </KitText>
          </View>
          {callback && (
            <KitButton secondary title='Try Again' onPress={callback} />
          )}
        </View>
      </View>
    </View>
  );
}
