import React, { useState } from 'react';

import AppLoadingView from '../../../../mobile/components/AppLoadingView';
import IntroScreen from '../../../../mobile/screens/IntroScreen';
import WebModal from '../../app/components/WebModal';

export default function WebLoadingView(): JSX.Element {
  const [introVisible, setIntroVisible] = useState(false);

  const showIntroModal = () => {
    showModal();
  };

  const showModal = () => setIntroVisible(true);
  const closeModal = () => setIntroVisible(false);

  return (
    <>
      <AppLoadingView screenProps={{}} showIntroModal={showIntroModal} />
      <WebModal open={introVisible} onClose={closeModal}>
        {/* @ts-ignore */}
        <IntroScreen navigation={{ goBack: closeModal }} />
      </WebModal>
    </>
  );
}
