import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import Spacing from '@omni/kit/theming/Spacing';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import React from 'react';
import { FlatList, View } from 'react-native';

import CircleButton from '../components/CircleButton';
import { IBlockProps } from './types';

export interface ICircleButtonRowBlockProps extends IBlockProps {
  buttons: IButtonItem[];
  horizontal: boolean;
  /**
   * A button defines an `action` that will occur when a user taps/clicks the button.
   * The `mediaDownload` type is a special case in which the icon and the action is defined internally.
   */
}

export interface IButtonItem {
  action: any;
  icon: string;
  title: string;
  type: 'default' | 'mediaDownload';
  downloadKey?: string;
  playlistUrl?: string;
}

export default function CircleButtonRowBlock(
  props: ICircleButtonRowBlockProps
): JSX.Element {
  const {
    bottomSpacing = null,
    buttons = [],
    horizontal = true,
    horizontalSpacing,
    insetStyle,
    topSpacing = null,
  } = props;

  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  // @ts-ignore
  const _renderButton = ({ item }): JSX.Element => <CircleButton item={item} />;

  const viewStyle = {
    marginTop: Spacing.xxs * 3 + (getSpacing(topSpacing) ?? 0),
    marginBottom: Spacing.xxs * 3 + (getSpacing(bottomSpacing) ?? 0),
    ...insetStyle,
  };

  return (
    <View style={viewStyle}>
      <View style={{ marginHorizontal: edgeSpacing }}>
        <FlatList<IButtonItem>
          testID='circleButtonRowList'
          scrollEnabled={false}
          horizontal={horizontal}
          data={buttons}
          contentContainerStyle={{ flex: 1, justifyContent: 'space-around' }}
          keyExtractor={() => `${Math.random()}`}
          renderItem={_renderButton}
        />
      </View>
    </View>
  );
}
