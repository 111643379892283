import Environment from '../../../Environment';
import { IHousehold } from '../Types';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.peopleService;
const RESOURCE = 'households';

interface IGetHouseholds extends IUri {
  token?: string;
  headers?: { [key: string]: string };
}

interface IHouseholdsResponse {
  count: number;
  total?: number;
  _embedded: {
    households: IHousehold[];
  };
}

interface IGetHouseholdsResponse extends IHttpResponse {
  body?: IHouseholdsResponse;
}

export default async function ({
  token,
  headers,
  ...buildUrlProps
}: IGetHouseholds): Promise<IGetHouseholdsResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
    headers,
  });
}
