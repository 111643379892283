export enum UNLEASH_TOGGLES {
  CHECK_IN_KIOSK_BROTHER_SDK = 'check-in-kiosk.brother-sdk',
  CHECKIN_PRECHECK = 'check-in.pre-check',
  MEDIA_KING_JAMES_TRANSLATION = 'media.king-james-translation',
  MEDIA_MOBILE_NEW_MEDIA_DETAIL_PAGE = 'media.mobile-new-media-detail-page',
  MEDIA_MOBILE_NEW_MEDIA_SERIES_PAGE = 'media.mobile-new-media-series-page',
  MEDIA_WEB_NEW_MEDIA_SERIES_PAGE = 'media.web-new-media-series-page',
  MEDIA_NMT_HIDE_REORDER_ROWS = 'media.nmt-hide-reorder-rows',
  MEDIA_SUPPRESS_PROGRESS_TRACKING = 'media.suppress-progress-tracking',
}
