import { IHousehold, IProfile } from '@omni/kit/services/PeopleService/Types';
import { orderBy } from 'lodash';

export const getChildMemberProfiles = (
  household: IHousehold | undefined
): IProfile[] => {
  const child_members = household?._embedded?.members?.filter(
    (member) => member.household_role === 'child'
  );

  return orderBy(child_members, 'first_name');
};

export const getChildGuestProfiles = (
  household: IHousehold | undefined
): IProfile[] => {
  const childGuestsProfiles = household?._embedded?.['guest-profiles'];

  return orderBy(childGuestsProfiles, 'first_name');
};

export const getAllChildrenProfiles = (
  household: IHousehold | undefined
): IProfile[] => {
  const childMembers = getChildMemberProfiles(household);
  const childGuests = getChildGuestProfiles(household);

  return orderBy([...childMembers, ...childGuests], 'first_name');
};

export const getAdultProfiles = (
  household: IHousehold | undefined
): IProfile[] => {
  const adults = household?._embedded?.members?.filter(
    (member) => member.household_role !== 'child'
  );

  return orderBy(adults, 'first_name');
};

export const getHouseholdMemberProfiles = (
  household: IHousehold | undefined
): IProfile[] => {
  const profiles = household?._embedded?.members;

  return orderBy(profiles, 'first_name');
};

export const checkUnknownHouseholdRoleMembers = (
  members: IProfile[] | undefined
): boolean => {
  return Boolean(
    members?.some((member) => member.household_role === 'unknown')
  );
};
