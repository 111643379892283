import React from 'react';
import {
  Platform,
  TouchableHighlight,
  TouchableNativeFeedback,
  View,
} from 'react-native';

import Colors from '../theming/Colors';

const KitTouchable: any = Platform.select({
  ios: ({
    // @ts-ignore
    children,
    onPress = undefined,
    onPressIn = undefined,
    onPressOut = undefined,
    ripple = false, // TODO: deprecate
    underlayColor = Colors.N100,
    // @ts-ignore
    style,
    // @ts-ignore
    testID,
    onLayout = () => null,
    onShowUnderlay = () => null,
    onHideUnderlay = () => null,
  }) => (
    <TouchableHighlight
      onLayout={onLayout}
      disabled={!onPress}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      underlayColor={underlayColor}
      delayPressIn={0}
      style={style}
      testID={testID}
      onShowUnderlay={onShowUnderlay}
      onHideUnderlay={onHideUnderlay}
    >
      {children}
    </TouchableHighlight>
  ),
  android: ({
    // @ts-ignore
    children,
    noRadius = false,
    borderRadius = 40,
    onPress = undefined,
    onPressIn = undefined,
    onPressOut = undefined,
    ripple = true, // TODO: deprecate
    underlayColor = Colors.N100,
    style = {},
    // @ts-ignore
    testID,
    touchableStyle = {},
    useForeground = true,
    onLayout = () => null,
  }) =>
    ripple ? (
      <View
        onLayout={onLayout}
        style={[
          { overflow: 'hidden' },
          noRadius ? {} : { borderRadius },
          touchableStyle,
        ]}
      >
        <TouchableNativeFeedback
          disabled={!onPress}
          onPress={onPress}
          onPressIn={onPressIn}
          onPressOut={onPressOut}
          background={TouchableNativeFeedback.Ripple(underlayColor, true)}
          delayPressIn={0}
          useForeground={useForeground}
          testID={testID}
        >
          <View style={style}>{children}</View>
        </TouchableNativeFeedback>
      </View>
    ) : (
      <TouchableHighlight
        onLayout={onLayout}
        disabled={!onPress}
        onPress={onPress}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        underlayColor={underlayColor}
        delayPressIn={0}
        style={style}
        testID={testID}
      >
        {children}
      </TouchableHighlight>
    ),
  web: ({
    // @ts-ignore
    children,
    onPress = undefined,
    onPressIn = undefined,
    onPressOut = undefined,
    ripple = false, // TODO: deprecate
    underlayColor = Colors.N100,
    // @ts-ignore
    testID,
    // @ts-ignore
    style,
    onLayout = () => null,
  }) => (
    <TouchableHighlight
      onLayout={onLayout}
      disabled={!onPress}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      underlayColor={underlayColor}
      delayPressIn={0}
      testID={testID}
      style={style}
    >
      {children}
    </TouchableHighlight>
  ),
});

export default KitTouchable;
