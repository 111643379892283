import AccountsService from '../AccountsService';
import { FeatureFlag } from './FeatureFlag';
import unleashClient from './unleashClient';

const debug = require('debug')(
  'tca:packages:kit:services:UnleashService:setupUnleash'
);

let orgKey = '';
let previousAppKey = '';
let isUpdated = false;
let isSetupInProgress = false;

/**
 * Setup unleash by getting all the needed resources including org key.
 */
export default async (appKey: string | undefined): Promise<void> => {
  if (!appKey) {
    return debug('Missing appKey to setup unleash.');
  }

  if (isSetupInProgress) {
    return debug(
      `Attempt to setup unleash with app key ${appKey} but setup is already in progress.`
    );
  }

  debug('Setting up unleash using app key', appKey);

  try {
    isSetupInProgress = true;

    /** Reset org key if app key is different */
    if (previousAppKey !== appKey) {
      debug('App key has been updated. Re-fetch org key.');
      orgKey = '';
    }

    /** Fetch org key */
    if (!orgKey) {
      const app = await AccountsService.getAppWithBranding(appKey);
      orgKey = app?.org_key || '';
      previousAppKey = appKey;
      isUpdated = true;
    }

    if (!isUpdated) return debug('Unleash is already setup');

    debug('Updating unleash context');

    unleashClient.stop();
    await unleashClient.start();
    await unleashClient.updateContext({
      ...(orgKey && { properties: { orgKey } }),
    });
    FeatureFlag.setup();

    isUpdated = false;
  } catch (e) {
    debug('Failed to setup unleash', e);

    return;
  } finally {
    isSetupInProgress = false;
  }
};
