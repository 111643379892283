import type { ShellContextData } from 'packages/kit/Types';
import React, { createContext, useContext } from 'react';

import InitialData from './InitialData';

export const ShellContext = createContext<ShellContextData>(InitialData);

/** This provider should be used in shell apps and not micro apps */
export const ShellContextProvider = ({
  shellData,
  children,
}: {
  shellData: ShellContextData;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <ShellContext.Provider value={shellData}>{children}</ShellContext.Provider>
  );
};

/** @deprecated use ShellContextProvider for shell apps or use [Web/Native]ShellAppProviders for micro apps for the respective platforms */
export const WebShellContextProvider = ShellContextProvider;

export { default as generateShellContextBranding } from '@omni/kit/utilities/generateShellContextBranding';

export const useShellContext = (): ShellContextData => useContext(ShellContext);
