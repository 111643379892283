import { useEffect, useState } from 'react';

import AccountsService from '../services/AccountsService';
import { App } from '../services/Types';

export default function useAppData(appKey?: string): App | undefined {
  const [app, setApp] = useState<App | undefined>();

  useEffect(() => {
    const fetch = async (appKey: string) => {
      try {
        const app = await AccountsService.getApp(appKey, {
          includeAppStoreInfo: true,
          includeBranding: true,
          includeFeatures: true,
          includeContainerAppInfo: true,
          includeWebApp: true,
        });
        setApp(app);
      } catch (e) {
        console.warn(e);
      }
    };

    if (appKey) {
      fetch(appKey);
    }
  }, [appKey]);

  return app;
}
