import unleashClient from './unleashClient';
import { UNLEASH_TOGGLES } from './types';

export class FeatureFlag {
  public static isKingJamesTranslationEnabled = false;
  public static isMediaSuppressProgressTrackingActive = false;

  public static setup(): void {
    this.isMediaSuppressProgressTrackingActive = unleashClient.isEnabled(
      UNLEASH_TOGGLES.MEDIA_SUPPRESS_PROGRESS_TRACKING
    );

    this.isKingJamesTranslationEnabled = unleashClient.isEnabled(
      UNLEASH_TOGGLES.MEDIA_KING_JAMES_TRANSLATION
    );
  }
}
