import { getRetryAfterMsec, shouldRetryRequest } from './requestError';

// a function that will attempt a request and retry up to 3 times
export const attemptRequest = async <T>(
  request: () => Promise<T>
): Promise<T> => {
  let attempts = 0;
  let result: T | undefined;

  while (attempts < 2) {
    try {
      result = await request();
      break;
    } catch (e) {
      attempts++;

      const error = e as Error;

      const shouldRetry = shouldRetryRequest(error);

      if (shouldRetry) {
        const retryAfterMsec = getRetryAfterMsec(error) ?? 1000;
        await new Promise((resolve) => setTimeout(resolve, retryAfterMsec));
      } else {
        throw error;
      }
    }
  }

  // last attempt
  if (!result) {
    result = await request();
  }

  return result;
};
