import KitAvatar from '@omni/kit/components/KitAvatar';
import KitImage from '@omni/kit/components/KitImage';
import KitInfiniteScroll from '@omni/kit/components/KitInfiniteScroll';
import ListItem from '@omni/kit/components/KitListItem';
import { IListItemData } from '@omni/kit/feeds/listItemTypes';
import SearchService, {
  IFacetResponse,
} from '@omni/kit/services/SearchService';
import { parseTagData } from '@omni/kit/services/SearchService/parseTagData';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FullPageLoader from '../../components/FullPageLoader';
import NoResults from '../../components/NoResult/NoResults';
import ScreenContainer from '../../components/ScreenContainer';
import { BrowseScreenRouteProp } from '../../types';
import calculateTotalPages from '../../utils/calculateTotalPages';

const debug = require('debug')('tca:search:Screens:Search.tsx');

export default ({
  route,
}: {
  route: BrowseScreenRouteProp;
  horizontalSpacing?: SpacingType;
}): JSX.Element => {
  const { t } = useTranslation(['search']);
  const navigation = useNavigation();
  const { appFilterValue, tagType, sortByTagCount } = route.params;

  const [results, setResults] = useState<IListItemData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const renderListItem = ({
    item,
    index,
  }: {
    item: IListItemData;
    index: number;
  }) => {
    let imageComponent;

    if (item.showAvatar && item.image) {
      imageComponent = (
        <KitAvatar
          size={Spacing.xxl}
          nickname={item.title}
          imageUrl={item.image}
        />
      );
    } else if (item.image) {
      imageComponent = <KitImage source={{ uri: item.image }} />;
    }

    return (
      <ListItem
        title={item.title}
        subtitle={item.subtitle}
        count={item.count}
        ImageComponent={imageComponent}
        topBorder={item.id === 0}
        key={index}
        style={{ marginHorizontal: Spacing.l }}
        onPress={() => {
          navigation.navigate('Result', {
            appFilterValue: appFilterValue,
            data: item,
          });
        }}
      />
    );
  };

  const onFetchBySearchService = useCallback(
    async (getFromCache = false, sort = 'value') => {
      const data = await SearchService.facets({
        appFilterValue,
        tagType,
        page: currentPage,
        getFromCache,
        sort,
        ...(tagType === 'speaker' && { include: 'tags' }),
      });

      const nextPage = currentPage + 1;
      const totalPages = calculateTotalPages(data?.body?.total || 0);

      setHasNextPage(nextPage <= totalPages);
      setCurrentPage(nextPage);

      return parseTagData(data as IFacetResponse, tagType);
    },
    [appFilterValue, currentPage, tagType]
  );

  /**
   * Fetch tag data based on type. For speaker tags
   * use hits api, otherwise use facets api.
   */
  const onFetchData = useCallback(
    async (getFromCache = false) => {
      return onFetchBySearchService(
        getFromCache,
        sortByTagCount ? 'count' : 'value'
      );
    },
    [appFilterValue, currentPage, tagType, sortByTagCount]
  );

  const getData = useCallback(
    async (context = '') => {
      try {
        const parsedData = await onFetchData(context === 'cache');
        setResults(parsedData);
      } catch (e) {
        debug('Failed to load tags from api', e);
      }
      setIsLoading(false);
    },
    [onFetchData]
  );

  useEffect(() => {
    const initialize = async () => {
      await getData('cache');
      await getData('network');
    };

    initialize();
    // ignore deps: only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <ScreenContainer>
        <FullPageLoader />
      </ScreenContainer>
    );
  }

  if (results?.length) {
    return (
      <ScreenContainer>
        <KitInfiniteScroll<IListItemData>
          onFetchData={onFetchData}
          renderItem={renderListItem}
          initialData={results}
          hasMoreItems={hasNextPage}
          numColumns={1}
        />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer>
      <NoResults
        icon='search'
        title={t('search:noResultsTitle', { context: 'browse' })}
        description={t('search:noResultsSubtitle', { context: 'checkLater' })}
      />
    </ScreenContainer>
  );
};
