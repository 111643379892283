import React from 'react';
import {
  Falsy,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  StyleSheet,
  TextInputChangeEventData,
  TextInputContentSizeChangeEventData,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';

import Colors from '../theming/Colors';
import Spacing from '../theming/Spacing';
import { colorForScheme } from '../theming/Theming';
import KitText from './KitText';

//******************************************************************************
// Types
//******************************************************************************
export interface IKitInputProps {
  allowFontScaling?: boolean;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  autoCorrect?: boolean;
  autoFocus?: boolean;
  blurOnSubmit?: boolean;
  onContentSizeChange?: (
    e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
  ) => void;
  defaultValue?: string;
  editable?: boolean;
  inputBorder?: string;
  inputRef?: any;
  inputStyle?: StyleProp<TextStyle>;
  isValid?: boolean;
  keyboardType?:
    | 'decimal-pad'
    | 'default'
    | 'email-address'
    | 'number-pad'
    | 'numeric'
    | 'phone-pad';
  label?: string;
  labelStyle?: TextStyle;
  leftIcon?: JSX.Element;
  leftIconStyle?: StyleProp<ViewStyle>;
  marginValue?: string;
  multiline?: boolean;
  onChangeText?: (inputText: string) => void;
  onLayout?: (event: any) => void;
  onFocus?: (...args: unknown[]) => void;
  onBlur?: () => void;
  onPressIn?: () => void;
  onSubmitEditing?: (...args: unknown[]) => void;
  outline?: boolean;
  placeholder?: string;
  placeholderTextColor?: string;
  returnKeyType?: 'done' | 'go' | 'next' | 'search' | 'send';
  rightIcon?: Falsy | JSX.Element;
  rightIconStyle?: ViewStyle;
  rightLabel?: string;
  rightLabelStyle?: TextStyle;
  secureTextEntry?: boolean;
  selectTextOnFocus?: boolean;
  selectionColor?: string;
  showSoftInputOnFocus?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  value?: string;
  maxLength?: number;
}

export interface IState {
  focused: boolean;
  inputHeight: number;
}

const MULTILINE_MAX_HEIGHT = 200;

//******************************************************************************
// Component
//******************************************************************************

class KitInput extends React.Component<IKitInputProps, IState> {
  static defaultProps: Partial<IKitInputProps> = {
    autoCapitalize: 'sentences',
    autoCorrect: true,
    autoFocus: false,
    blurOnSubmit: undefined,
    editable: true,
    inputBorder: 'transparent',
    inputStyle: {},
    isValid: true,
    keyboardType: 'default',
    marginValue: '0',
    multiline: false,
    outline: false,
    placeholder: '',
    returnKeyType: undefined,
    rightLabel: undefined,
    rightLabelStyle: {},
    secureTextEntry: false,
    selectTextOnFocus: false,
    style: {},
    testID: '',
    maxLength: undefined,
  };

  //****************************************************************************
  // State
  //****************************************************************************

  state: IState = {
    focused: false,
    inputHeight: 100,
  };

  //****************************************************************************
  // Methods
  //****************************************************************************

  private _onInputFocus = () => {
    this.setState({ focused: true });
    this.props.onFocus?.();
  };

  private _onInputBlur = () => {
    this.setState({ focused: false });
    this.props.onBlur?.();
  };

  private _onContentSizeChange = ({
    nativeEvent,
  }: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
    if (Platform.OS !== 'web') return;

    const { contentSize } = nativeEvent;
    const newHeight = contentSize.height + 12;
    this.setState({
      inputHeight: Math.max(
        100,
        newHeight > MULTILINE_MAX_HEIGHT ? MULTILINE_MAX_HEIGHT : newHeight
      ),
    });
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  render(): JSX.Element {
    const {
      allowFontScaling,
      autoCapitalize,
      autoCorrect,
      autoFocus,
      blurOnSubmit,
      onContentSizeChange,
      defaultValue,
      editable,
      inputBorder,
      inputRef,
      inputStyle,
      isValid,
      keyboardType,
      label,
      labelStyle,
      leftIcon,
      leftIconStyle,
      marginValue,
      multiline,
      onChangeText,
      onLayout,
      onSubmitEditing,
      outline,
      placeholder,
      placeholderTextColor,
      returnKeyType,
      rightIcon,
      rightIconStyle,
      rightLabel,
      rightLabelStyle,
      secureTextEntry,
      selectTextOnFocus,
      selectionColor,
      showSoftInputOnFocus = true,
      style,
      testID,
      value,
      onPressIn,
      maxLength,
    } = this.props;

    return (
      <InputWrapper
        marginValue={marginValue}
        style={[
          { opacity: editable ? 1.0 : !editable && outline ? 1.0 : 0.5 },
          style,
        ]}
      >
        {label && (
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <KitText
              color={Colors.N500}
              fontSize={14}
              semiBold
              style={{ lineHeight: 18, marginBottom: Spacing.s, ...labelStyle }}
              testID={`Label ${label}`}
            >
              {label}
            </KitText>
            {rightLabel?.length && (
              <KitText
                color={Colors.N500}
                fontSize={14}
                style={{
                  lineHeight: 18,
                  marginBottom: Spacing.m,
                  ...rightLabelStyle,
                }}
              >
                {rightLabel}
              </KitText>
            )}
          </View>
        )}
        <Input
          testID={testID || placeholder}
          showSoftInputOnFocus={showSoftInputOnFocus}
          allowFontScaling={allowFontScaling}
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          autoFocus={autoFocus}
          onPressIn={onPressIn}
          blurOnSubmit={blurOnSubmit}
          defaultValue={defaultValue}
          editable={editable}
          keyboardType={keyboardType}
          multiline={multiline}
          onBlur={this._onInputBlur}
          maxLength={maxLength}
          onChange={(
            event: NativeSyntheticEvent<
              TextInputChangeEventData & {
                srcElement?: { scrollHeight: number };
              }
            >
          ) => {
            if (Platform.OS === 'web') {
              if (!multiline) {
                this.setState({
                  inputHeight: event.nativeEvent.srcElement
                    ?.scrollHeight as number,
                });
              }
            }
          }}
          onChangeText={onChangeText}
          onLayout={onLayout}
          onContentSizeChange={
            onContentSizeChange
              ? onContentSizeChange
              : this._onContentSizeChange
          }
          onFocus={this._onInputFocus}
          onSubmitEditing={onSubmitEditing}
          outline={outline}
          placeholder={placeholder}
          placeholderTextColor={colorForScheme({
            default: placeholderTextColor || Colors.N500,
          })}
          ref={inputRef}
          returnKeyType={returnKeyType}
          secureTextEntry={secureTextEntry}
          selectTextOnFocus={selectTextOnFocus}
          selectionColor={selectionColor}
          style={[
            !isValid
              ? { borderColor: colorForScheme({ default: Colors.R500 }) }
              : this.state.focused
              ? { borderColor: colorForScheme({ default: Colors.N900 }) }
              : outline
              ? { borderColor: colorForScheme({ default: Colors.N100 }) }
              : { borderColor: inputBorder },
            leftIcon ? { paddingLeft: 42 } : {},
            Platform.OS === 'web' ? ({ outlineWidth: 0 } as any) : {},
            multiline
              ? {
                  height: this.state.inputHeight,
                  maxHeight: MULTILINE_MAX_HEIGHT,
                  minHeight: 100,
                  paddingBottom: 12,
                  paddingTop: 12,
                  textAlignVertical: 'top',
                }
              : {},
            { width: '100%' },
            inputStyle,
          ]}
          value={value}
        />
        {leftIcon && (
          <View style={[styles.leftIcon, leftIconStyle]}>{leftIcon}</View>
        )}
        {rightIcon && (
          <View
            style={{
              bottom: 12,
              height: 20,
              position: 'absolute',
              right: 12,
              width: 20,
              ...rightIconStyle,
            }}
          >
            {rightIcon}
          </View>
        )}
      </InputWrapper>
    );
  }
}

export default KitInput;

const styles = StyleSheet.create({
  leftIcon: {
    bottom: 0,
    height: '100%',
    justifyContent: 'center',
    left: 14,
    position: 'absolute',
    top: 0,
  },
});

//******************************************************************************
// Styles
//******************************************************************************
const InputWrapper = styled.View`
  ${({ marginValue }: IKitInputProps) =>
    marginValue ? `margin: ${marginValue};` : ''}
`;

const Input = styled.TextInput`
  background-color: ${({ outline }: IKitInputProps) =>
    colorForScheme({ default: outline ? Colors.N0 : Colors.N50 })};
  border-radius: 8;
  border-width: 2;
  color: ${colorForScheme({ default: Colors.N900 })};
  font-size: 17;
  height: 44;
  line-height: 20;
  padding: 0 14px 0 14px;
  ${() => (Platform.OS === 'web' ? 'font-family: inherit;' : '')}
`;
