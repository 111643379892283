import { buildUrl, IFilter, IInclude, IPage, IQuery } from '../BaseServiceV2';

import Environment from '../../Environment';

const host = Environment.tagHost;

export interface IPrepareTagUrl {
  filters?: IFilter[];
  id?: string;
  includes?: IInclude[];
  pages?: IPage[];
  queries?: IQuery[];
}

export function prepareTagUrl({
  filters,
  id,
  includes,
  pages,
  queries,
}: IPrepareTagUrl): string {
  return buildUrl({
    filters,
    host,
    id,
    includes,
    pages,
    resource: 'tag',
    queries,
  });
}
