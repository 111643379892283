import {
  KitFloatingButton,
  KitInput,
  KitSnack,
  KitText,
  KitTouchable,
} from '@omni/kit/components';
import LeftNavButton, { LeftNavType } from '@omni/kit/components/LeftNavButton';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import {
  sendPrayerRequestMessage,
  updatePrayerRequestAnswered,
} from '../../shared/redux/actions/ChatActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';

import { CHAT_SCREEN } from '../../shared/Constants';
import Colors from '@omni/kit/theming/Colors';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { ParamListBase } from '@react-navigation/routers';
import PlatformKeyboardSpacer from '../components/PlatformKeyboardSpacer';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import Spacing from '@omni/kit/theming/Spacing';
import { channelSelector } from '../../shared/redux/selectors';

export interface RouteProps extends RouteProp<ParamListBase, string> {
  params: {
    prayerRequest?: {
      messageId: number;
      message: string;
    };
  };
}

export default function PrayerRequestScreen({
  prayerToBeAnswered = undefined,
  togglePopup = undefined,
}) {
  const [inputValue, setInputValue] = useState('');

  const { sizeClass } = useSizeClass();
  const channel = useSelector(channelSelector);
  const navigation = useNavigation();
  const route = useRoute<RouteProps>();

  const dispatch = useDispatch();

  const prayerRequest = route.params?.prayerRequest || prayerToBeAnswered;
  const isAnswering = prayerRequest ? true : false;

  const _onUpdatePress = useCallback(() => {
    // Update the original prayer message
    dispatch(
      updatePrayerRequestAnswered(
        channel as GroupChannel,
        prayerRequest?.messageId,
        { isAnswered: true }
      )
    );
    if (inputValue.length > 0) {
      // Send a new response message
      dispatch(
        sendPrayerRequestMessage(channel as GroupChannel, inputValue, {
          answeredId: prayerRequest?.messageId,
        })
      );
    }

    KitSnack.show('Prayer request updated');
    if (inputValue.length > 0) {
      navigation.navigate(CHAT_SCREEN, { screen: 'Chat' });
    } else {
      navigation.goBack();
    }
  }, [channel, dispatch, navigation, inputValue, prayerRequest]);

  const _onSendPress = useCallback(() => {
    dispatch(sendPrayerRequestMessage(channel as GroupChannel, inputValue));
    if (togglePopup) {
      // @ts-ignore
      togglePopup();
    } else {
      try {
        navigation.goBack();
      } catch {}
    }
  }, [channel, dispatch, navigation, inputValue]);

  useEffect(() => {
    navigation.setOptions({
      title: isAnswering ? 'Answered prayer' : 'New prayer request',
      headerRight:
        Platform.OS !== 'web'
          ? () => (
              <KitTouchable
                disabled={inputValue.length === 0}
                onPress={isAnswering ? _onUpdatePress : _onSendPress}
                style={{
                  paddingLeft: 12,
                  paddingRight: Platform.OS === 'ios' ? 12 : 18,
                  paddingVertical: 6,
                  marginRight: -6,
                  borderRadius: 40,
                }}
              >
                <KitText
                  color={Colors.N400}
                  brandColor={inputValue.length > 0 || isAnswering}
                  fontSize={17}
                >
                  {isAnswering ? 'Update' : 'Send'}
                </KitText>
              </KitTouchable>
            )
          : null,
    });
  }, [_onSendPress, inputValue.length, navigation]);

  useEffect(() => {
    // @ts-ignore
    if (route.params && route.params.request) {
      // @ts-ignore
      setInputValue(route.params.request);
    }
  }, [route.params]);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {Platform.OS !== 'web' && SizeClass.Small !== sizeClass && isAnswering && (
        <View style={{ flexDirection: 'row', paddingTop: Spacing.m }}>
          <LeftNavButton
            type={LeftNavType.Dismiss}
            title='Answered prayer'
            onPress={togglePopup}
          />
        </View>
      )}

      {isAnswering && (
        <KitText black style={{ marginTop: Spacing.l }}>
          "{prayerRequest?.message}"
        </KitText>
      )}

      <View style={{ marginVertical: Spacing.l }}>
        <KitInput
          label={isAnswering ? 'Add an update (optional)' : 'Prayer request'}
          multiline
          placeholder={
            isAnswering
              ? 'Share how your prayer was answered'
              : 'Describe your request'
          }
          value={inputValue}
          onChangeText={setInputValue}
        />
      </View>
      <PlatformKeyboardSpacer />
      {((!isAnswering && inputValue.length > 0) || isAnswering) && (
        <View
          style={{
            position: 'absolute',
            bottom: Spacing.l,
            right: Spacing.l,
          }}
        >
          <KitFloatingButton
            icon='send'
            onPress={isAnswering ? _onUpdatePress : _onSendPress}
          />
        </View>
      )}
    </ScrollView>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Spacing.l,
    backgroundColor: Colors.N0,
  },
});
