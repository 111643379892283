import GroupsService from '@omni/kit/services/GroupsService';
import { IJoinRequest } from '@omni/kit/services/GroupsService/Types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setJoinRequests } from '../../../redux/actions/ChatActions';
import {
  accessTokenSelector,
  appKeySelector,
  joinRequestsSelector,
} from '../../../redux/selectors';

const debug = require('debug')('groups:join-requests:useJoinRequests');

export default function useJoinRequests(): IJoinRequest[] {
  const appKey = useSelector(appKeySelector);
  const accessToken = useSelector(accessTokenSelector);
  const joinRequests = useSelector(joinRequestsSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchJoinRequests = async () => {
      const response = await GroupsService.GetJoinRequests({
        appKey,
        token: accessToken,
      });

      if (response.body) {
        const jr = response.body._embedded?.['join-requests'];

        if (jr) {
          dispatch(
            setJoinRequests(
              jr.filter(
                (request) =>
                  request._embedded['requesting-user']?._embedded?.profiles &&
                  request._embedded.group?.name
              )
            )
          );
        }
      } else {
        debug('Could not load join requests');
      }
    };
    fetchJoinRequests();
  }, [accessToken, appKey, dispatch]);

  return joinRequests;
}
