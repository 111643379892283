const Spacing: {
  xxs: 2;
  xs: 4;
  s: 8;
  m: 12;
  l: 18;
  xl: 24;
  xxl: 36;
  xxxl: 48;
  xxxxl: 72;
  xxxxxl: 96;
} = {
  xxs: 2,
  xs: 4,
  s: 8,
  m: 12,
  l: 18,
  xl: 24,
  xxl: 36,
  xxxl: 48,
  xxxxl: 72,
  xxxxxl: 96,
};

export default Spacing;
