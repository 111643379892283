import { StyleSheet, View } from 'react-native';

import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import KitIcon from '@omni/kit/components/KitIcon';
import { KitListItem } from '@omni/kit/components';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';
import { useTranslation } from 'react-i18next';

export default function NoUpcomingEvents(): JSX.Element {
  const { t } = useTranslation(['schedule']);

  return (
    <KitListItem
      bottomBorder={false}
      ImageComponent={
        <View style={styles.icon}>
          <KitIcon color={Colors.N300} name={'event'} size={20} />
        </View>
      }
      rightIcon={''}
      style={styles.emptyListItem}
      title={t('schedule:accountMenuItemTitle')}
      titleColor={Colors.N500}
      titleFontSize={16}
      onPressUnderlayColor={Colors.N50}
    />
  );
}

const styles = StyleSheet.create({
  emptyListItem: {
    height: 78,
    paddingVertical: Spacing.l,
    borderColor: Colors.N100,
    borderBottomWidth: 1,
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.N50,
    borderRadius: BorderRadius.m,
    display: 'flex',
    padding: Spacing.s,
  },
});
