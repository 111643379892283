import Environment from '../../../Environment';
import { IEvent } from '../Types';
import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.eventsService;
const RESOURCE = 'event';

interface IGetEvents extends IUri {
  token?: string;
}

interface IEventsResponse {
  count: number;
  _embedded: {
    events: IEvent[];
  };
}

interface IGetEventsResponse extends IHttpResponse {
  body?: IEventsResponse;
}

export default async function ({
  token,
  ...buildUrlProps
}: IGetEvents): Promise<IGetEventsResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
  });
}
