import SchedulePreview from '@omni/accountmenu/components/Schedule';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export default function ScheduleBlock(): JSX.Element {
  const { sizeClass } = useSizeClass();
  const addSpacing = sizeClass !== SizeClass.Small;
  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: undefined,
  });

  return (
    <View
      style={[
        styles.container,
        { paddingHorizontal: addSpacing ? edgeSpacing : 0 },
      ]}
    >
      <SchedulePreview />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: Spacing.xxl,
  },
});
