import { useShellContext } from '@omni/kit/contexts/ShellContext/ShellContext';
import useAuthProviders from '@omni/kit/hooks/useAuthProviders';

import { AuthProvider } from '../../../../auth';

// the web shell does not pass through auth providers,
// so we must fetch them in any screens that require them
// TODO: consider passing target auth providers through shell context
export default function (): {
  isFetchingAuthProviders: boolean;
  providers: AuthProvider[];
  loginEnabledProviders: AuthProvider[];
  targetProviders: AuthProvider[];
} {
  const { app } = useShellContext();
  const appKey = app?.appKey;
  const orgKey = app?.orgKey;
  const {
    isFetchingAuthProviders,
    providers,
    loginEnabledProviders,
    targetProviders,
  } = useAuthProviders({
    appKey,
    orgKey,
  });

  return {
    isFetchingAuthProviders,
    providers,
    loginEnabledProviders,
    targetProviders,
  };
}
