import Environment from '@omni/kit/Environment';
import { BridgeAction, NATIVE_EVENT_KEY } from '@omni/kit/Types';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { EActionHandlers } from '@omni/kit/feeds/ActionHandlerTypes';
import { NativeTopBarStyle } from '@omni/kit/utilities/NativeHelpers';

interface MediaDetailAction extends BridgeAction {
  handler: EActionHandlers;
}

interface GetMediaDetailActionProps {
  id?: string;
  shortCode?: string;
  useBlockPage?: boolean;
}

export const getMediaDetailAction = ({
  id = '',
  shortCode = '',
  useBlockPage = false,
}: GetMediaDetailActionProps): MediaDetailAction => {
  if (useBlockPage) {
    return {
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      handler: EActionHandlers.BlockPage,
      nativeEventKey: `${NATIVE_EVENT_KEY.MEDIA_DETAIL}-${id}`,
      topBarStyle: NativeTopBarStyle.Transparent,
      url: `${Environment.feedsService}/media-detail/${id}`,
    };
  }

  return {
    handler: EActionHandlers.NativeShell,
    moduleCommand: {
      route: `/media/d/${shortCode}`,
    },
  };
};
