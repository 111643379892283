import React from 'react';
import { TextStyle, View, ViewStyle } from 'react-native';

import { KitText } from '../../components';
import BorderRadius from '../../theming/BorderRadius';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';

export type BadgeSize = 's' | 'm' | 'l';
export type BadgeColor =
  | 'default'
  | 'blue'
  | 'red'
  | 'green'
  | 'yellow'
  | 'dark'
  | 'white';

interface IProps {
  color?: BadgeColor;
  size?: BadgeSize;
  style?: ViewStyle;
  text: string;
  textStyle?: {
    textTransform?: TextStyle['textTransform'];
  };
}
export default ({
  color = 'default',
  size = 'm',
  style = {},
  text = '',
  textStyle = {},
}: IProps): JSX.Element => {
  return (
    <View
      style={{
        ...viewStyles(size),
        ...style,
        justifyContent: 'center',
        backgroundColor: backgroundColor(color),
      }}
    >
      <KitText
        style={{
          textTransform: textStyle.textTransform || 'capitalize',
          fontWeight: '500',
          ...textStyles(size),
          color: textColor(color),
        }}
      >
        {text}
      </KitText>
    </View>
  );
};
const viewStyles = (size: BadgeSize) => {
  switch (size) {
    case 's': {
      return {
        paddingHorizontal: Spacing.xs,
        height: 12,
        borderRadius: BorderRadius.s,
      };
    }
    case 'm': {
      return {
        paddingHorizontal: Spacing.xs,
        height: 18,
        borderRadius: BorderRadius.s,
      };
    }
    case 'l': {
      return {
        paddingHorizontal: Spacing.s,
        height: 24,
        borderRadius: BorderRadius.m,
      };
    }
  }
};

const backgroundColor = (color: BadgeColor) => {
  switch (color) {
    case 'default': {
      return Colors.N75;
    }
    case 'blue': {
      return Colors.S100;
    }
    case 'red': {
      return Colors.R100;
    }
    case 'green': {
      return Colors.G50;
    }
    case 'yellow': {
      return Colors.Y100;
    }
    case 'dark': {
      return Colors.N10006;
    }
    case 'white': {
      return Colors.N0;
    }
  }
};

const textColor = (color: BadgeColor) => {
  switch (color) {
    case 'default': {
      return Colors.N700;
    }
    case 'blue': {
      return Colors.brand;
    }
    case 'red': {
      return Colors.R700;
    }
    case 'green': {
      return Colors.G700;
    }
    case 'yellow': {
      return Colors.Y700;
    }
    case 'dark': {
      return Colors.N0;
    }
    case 'white': {
      return Colors.N900;
    }
  }
};

const textStyles = (size: BadgeSize) => {
  switch (size) {
    case 's': {
      return {
        fontSize: 10,
        lineHeight: 12,
      };
    }
    case 'm': {
      return {
        fontSize: 12,
        lineHeight: 18,
      };
    }
    case 'l': {
      return {
        fontSize: 12,
        lineHeight: 14.4,
      };
    }
  }
};
