import { BlockPageApp } from '@omni/blocks';
import Environment from '@omni/kit/Environment';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { NativeTopBarStyle } from '@omni/kit/utilities/NativeHelpers';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  accessTokenSelector,
  appKeySelector,
} from '../../shared/redux/selectors';

interface Props {
  navigation: { goBack: () => void };
  onDismiss?: () => void;
}

export default function AccountScreen({
  navigation,
  onDismiss,
}: Props): JSX.Element {
  const appKey = useSelector(appKeySelector);
  const accessToken = useSelector(accessTokenSelector);

  return (
    <BlockPageApp
      appKey={appKey}
      accessToken={accessToken}
      authProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
      url={`${Environment.feedsService}/profile/${appKey}/user`}
      topBarStyle={NativeTopBarStyle.Hidden}
      theme={'light'}
      onDismiss={onDismiss ?? navigation.goBack}
    />
  );
}
