import React, { useEffect, useState } from 'react';
import { Animated } from 'react-native';

import { KitLoader } from '../../components';

export default ({
  show,
  animationDuration = 500,
  height = 181,
  callback = () => null,
}: {
  show: boolean;
  animationDuration?: number;
  height?: number;
  callback?: () => void;
}): JSX.Element => {
  const [animateHeight] = useState<Animated.Value>(new Animated.Value(0));

  const animate = (show = false) => {
    Animated.timing(animateHeight, {
      duration: show ? 0 : animationDuration,
      toValue: show ? height : 0,
      useNativeDriver: false,
    }).start(callback);
  };

  useEffect(() => {
    animate(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Animated.View
      style={[
        {
          marginTop: 36,
          alignItems: 'center',
          height: animateHeight,
          overflow: 'hidden',
        },
      ]}
    >
      <KitLoader />
    </Animated.View>
  );
};
