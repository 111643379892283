import { useBlockPageContext } from '@omni/blocks/BlockPageContext';
import EditSelectedEventModal from '@omni/blocks/blocks/precheck/modals/EditSelectedEventModal';
import EditSessionModal from '@omni/blocks/blocks/precheck/modals/EditSessionModal';
import PreCheckSecurityCodeSummary from '@omni/blocks/blocks/precheck/modals/PreCheckSecurityCodeSummary';
import ProfileSelectionModal from '@omni/blocks/blocks/precheck/modals/ProfileSelectionModal';
import { generateTintColor } from '@omni/blocks/blocks/utilities';
import useHouseholdMembers from '@omni/check-in/kiosk/hooks/useHouseholdMembers';
import useSessionTypes from '@omni/check-in/kiosk/hooks/useSessionTypes';
import { ICheckIn } from '@omni/check-in/kiosk/services/CheckInService/Types';
import usePreCheck from '@omni/check-in/pre-check/hooks/usePreCheck';
import useStoredSessions, {
  ISessionSelection,
} from '@omni/check-in/pre-check/hooks/useStoredSessions';
import useSuggestedSessions, {
  ISessionSuggestion,
} from '@omni/check-in/pre-check/hooks/useSuggestedSessions';
import { useShellContext } from '@omni/kit';
import { KitLoader, KitSection } from '@omni/kit/components';
import KitAvatarList from '@omni/kit/components/KitAvatarList';
import KitCarousel from '@omni/kit/components/KitCarousel';
import KitIcon, { IconName } from '@omni/kit/components/KitIcon';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import KitText from '@omni/kit/components/KitText';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { IEvent, ISession } from '@omni/kit/services/EventsService/Types';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors, { ColorString } from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableHighlight, View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import { IBlockProps } from '../types';

const debug = require('debug')('tca:blocks:PreCheckBlock');

export interface PreCheckBlockProps extends IBlockProps {
  appKey?: string;
}

export interface IPreCheckQRCode {
  'event.id': string;
  'by-end-user.id': string;
  'household.id': string;
  selections: ISessionSuggestion[] | ISessionSelection[];
}

export default function PreCheckBlock({
  topSpacing,
  bottomSpacing,
}: PreCheckBlockProps): JSX.Element | null {
  const { t } = useTranslation();
  const { sizeClass } = useSizeClass();
  const { app, user } = useShellContext();
  const { isBlockPageRefreshing } = useBlockPageContext();
  const {
    activeEvents,
    household,
    selectedEvent,
    activeCheckIns,
    previousCheckIns,
    loading,
    refreshCheckIns,
    setNewSelectedEvent,
    showCheckOut,
  } = usePreCheck(isBlockPageRefreshing);

  const { adults, allProfiles, childMembers, childGuests } =
    useHouseholdMembers(household);
  const { adultSessions, childSessions } = useSessionTypes(
    selectedEvent?._embedded?.sessions
  );
  // Stored Suggestions
  const [storedSelections, setStoredSelections] = useStoredSessions(
    selectedEvent?.id || ''
  );

  // Modals
  const [profileSelectionModal, setProfileSelectionModal] = useState(false);
  const [editSessionModal, setEditSessionModal] = useState(false);
  const [editEventModal, setEditEventModal] = useState(false);
  // Modals States
  const [editSessionProfile, setEditSessionProfile] = useState<
    IProfile | undefined
  >();

  // Styling
  const isSmall = sizeClass === SizeClass.Small;
  const { edgeSpacing, viewPortWidth } = useScreenContext({
    fixedSpacingType: undefined,
  });
  const backgroundColor = app.branding?.brandColor
    ? generateTintColor(app.branding?.brandColor)
    : Colors.N1000;

  /**
   * Computed data
   */
  // Profiles with eligible sessions in the selected event
  const eligibleProfiles = useMemo(() => {
    return ([] as IProfile[]).concat(
      adults.length && adultSessions.length ? adults : [],
      childMembers.length && childSessions.length ? childMembers : []
    );
  }, [adultSessions.length, adults, childMembers, childSessions.length]);

  // Get Suggested Sessions
  const { suggestions, loading: suggestionsLoading } = useSuggestedSessions(
    selectedEvent?.id,
    eligibleProfiles
  );
  const eventSessions = selectedEvent?._embedded?.sessions || [];
  const sessionIds = new Set(eventSessions.map((session) => session.id));

  // Stored or Suggested Sessions
  const sessionSelections: ISessionSelection[] | ISessionSuggestion[] =
    storedSelections?.length
      ? storedSelections.filter((data) => sessionIds.has(data['session.id']))
      : suggestions;

  const selectedProfiles = useMemo(() => {
    if (
      previousCheckIns?.length &&
      (!storedSelections || !storedSelections.length)
    ) {
      return eligibleProfiles.filter(
        (profile) =>
          // Check if profile has a previous check-in
          previousCheckIns.some(
            (c) => c._embedded?.['profile-snapshot']?.id === profile.id
          ) &&
          // Check if profile matches session selection criteria
          sessionSelections.some(
            (s) => s['end-user.id'] === profile._embedded?.['end-user']?.id
          )
      );
    }

    return eligibleProfiles
      .filter((profile) =>
        sessionSelections.some(
          (s) => s['end-user.id'] === profile._embedded?.['end-user']?.id
        )
      )
      .concat(
        childGuests.length && childSessions.length
          ? childGuests.filter((guest) =>
              storedSelections
                ?.filter((data) => sessionIds.has(data['session.id']))
                .some(
                  (s) => s['end-user.id'] === guest._embedded?.['end-user']?.id
                )
            )
          : []
      );
  }, [
    childGuests,
    childSessions.length,
    eligibleProfiles,
    previousCheckIns,
    sessionSelections,
    storedSelections,
  ]);

  // Whether we have a suggested session for all eligible profiles
  const allProfilesSuggested = useMemo(() => {
    return (
      (selectedProfiles || eligibleProfiles).length > 0 &&
      !(selectedProfiles || eligibleProfiles).some(
        (a) =>
          suggestions.find(
            (s) => s['end-user.id'] === a._embedded?.['end-user']?.id
          ) === undefined &&
          storedSelections?.find(
            (s) => s['end-user.id'] === a._embedded?.['end-user']?.id
          ) === undefined
      )
    );
  }, [eligibleProfiles, selectedProfiles, storedSelections, suggestions]);

  // Whether we need more info to show the QR code
  const preCheckAvailable = useMemo(() => {
    return activeEvents && !showCheckOut && !allProfilesSuggested;
  }, [activeEvents, allProfilesSuggested, showCheckOut]);

  // The QR code content
  const qrCodeData: IPreCheckQRCode | undefined = useMemo(() => {
    if (showCheckOut || !allProfilesSuggested || !selectedEvent?.id) {
      return undefined;
    }

    return {
      'event.id': selectedEvent.id,
      'by-end-user.id': user?.id as string,
      'household.id': household?.id as string,
      selections: sessionSelections,
    };
  }, [
    showCheckOut,
    allProfilesSuggested,
    selectedEvent?.id,
    user?.id,
    household?.id,
    sessionSelections,
  ]);

  // Whether there are multiple active events available
  const multipleEvents = activeEvents.length > 1;

  /**
   * Effects
   */
  useEffect(() => {
    // Re-fetch check-ins every 10 seconds so we can show
    // the check-out code after the QR code is scanned
    if (qrCodeData) {
      const interval = setInterval(() => {
        refreshCheckIns();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [qrCodeData, refreshCheckIns]);

  useEffect(() => {
    // Console logging relevant data for debugging
    debug({
      activeEvents: JSON.stringify(activeEvents?.map((e) => e.title)),
      activeCheckIns: activeCheckIns?.length,
      previousCheckIns: previousCheckIns?.length,
      selectedEvent: selectedEvent?.id,
      suggestions: suggestions.length,
      eligibleProfiles: eligibleProfiles.length,
      selectedProfiles: selectedProfiles?.length,
      preCheckAvailable,
    });
  }, [
    activeCheckIns?.length,
    previousCheckIns?.length,
    selectedEvent?.id,
    suggestions,
    eligibleProfiles.length,
    activeEvents,
    selectedProfiles?.length,
    preCheckAvailable,
  ]);

  /**
   * Callbacks
   */
  const onEditSession = (profile: IProfile) => {
    setProfileSelectionModal(false);
    setEditSessionProfile(profile);

    setEditSessionModal(true);
  };

  const changeSessionId = useCallback(
    async (
      endUserId: string,
      newSessionId: string,
      newSessionTitle: string
    ): Promise<void> => {
      const selection = sessionSelections.find(
        (s) => s['end-user.id'] === endUserId
      );

      if (selection) {
        selection['session.id'] = newSessionId;
        selection['session.title'] = newSessionTitle;
        setStoredSelections([...sessionSelections]);
      } else {
        const newSelection = {
          'end-user.id': endUserId,
          'session.id': newSessionId,
          'session.title': newSessionTitle,
        };
        setStoredSelections([...(sessionSelections || []), newSelection]);
      }
    },
    [setStoredSelections, sessionSelections]
  );

  const onSessionBackPress = () => {
    setEditSessionModal(false);
    setProfileSelectionModal(true);
  };
  const onSaveSession = (session: ISession) => {
    session &&
      selectedEvent?.id &&
      editSessionProfile?._embedded?.['end-user']?.id &&
      changeSessionId(
        editSessionProfile._embedded?.['end-user'].id,
        session.id,
        session.title
      );

    setEditSessionModal(false);
    setProfileSelectionModal(true);
  };

  const onProfileBackPress = () => {
    setProfileSelectionModal(false);
    if (multipleEvents) {
      setEditEventModal(true);
    }
  };
  const onSaveSelectedProfiles = useCallback(
    (profiles: IProfile[]) => {
      setProfileSelectionModal(false);

      const newSelections: ISessionSelection[] = [];
      profiles.forEach((profile) => {
        const selection = sessionSelections.find(
          (s) => s['end-user.id'] === profile._embedded?.['end-user']?.id
        );
        const suggestion = suggestions.find(
          (s) => s['end-user.id'] === profile._embedded?.['end-user']?.id
        );

        if (selection) {
          newSelections.push(selection);
        } else if (suggestion) {
          newSelections.push(suggestion as ISessionSelection);
        }
      });

      setStoredSelections(newSelections);
    },
    [sessionSelections, setStoredSelections, suggestions]
  );

  const onSelectEvent = (event: IEvent) => {
    setEditEventModal(false);
    if (event.id !== selectedEvent?.id) {
      setNewSelectedEvent(event);
    }

    setProfileSelectionModal(true);
  };

  return !isSmall ||
    loading ||
    suggestionsLoading ||
    (!qrCodeData && !showCheckOut && !preCheckAvailable) ? (
    <></>
  ) : (
    <View
      style={[
        {
          paddingHorizontal: !isSmall ? edgeSpacing : 0,
          paddingTop: getSpacing(topSpacing),
          paddingBottom: getSpacing(bottomSpacing),
        },
      ]}
    >
      <KitSection title={t('check-in:titleCheckIn')}>
        {qrCodeData || showCheckOut ? (
          <View
            style={[
              styles.qrContainerBackground,
              {
                backgroundColor,
              },
            ]}
          >
            {showCheckOut ? (
              <CheckOutCodeCards
                backgroundColor={backgroundColor}
                checkIns={activeCheckIns}
                profiles={
                  // Profiles checked into selectedEvent
                  allProfiles.filter((profile) =>
                    activeCheckIns.some(
                      (checkIn) =>
                        checkIn._embedded['profile-snapshot']?.id === profile.id
                    )
                  )
                }
                event={selectedEvent}
              />
            ) : (
              <QrCodeCard
                backgroundColor={backgroundColor}
                qrCodeData={qrCodeData}
                selectedEvent={selectedEvent as IEvent}
                activeProfiles={selectedProfiles || eligibleProfiles}
                onEdit={() =>
                  multipleEvents
                    ? setEditEventModal(true)
                    : setProfileSelectionModal(true)
                }
              />
            )}
          </View>
        ) : (
          <PreCheckRowBlock
            multipleEvents={multipleEvents}
            setEditEventModal={setEditEventModal}
            setProfileSelectionModal={setProfileSelectionModal}
          />
        )}
      </KitSection>
      <KitModalV2
        isVisible={editEventModal || profileSelectionModal || editSessionModal}
        anchorBottom={true}
        onModalHide={() => {
          editEventModal && setEditEventModal(false);
          profileSelectionModal && setProfileSelectionModal(false);
          editSessionModal && setEditSessionModal(false);
        }}
        onClose={() => {
          editEventModal && setEditEventModal(false);
          profileSelectionModal && setProfileSelectionModal(false);
          editSessionModal && setEditSessionModal(false);
        }}
        coverScreen
        enableScroll={false}
        modalStyle={{ padding: 0 }}
        swipeEnabled={false}
      >
        <EditSelectedEventModal
          visible={editEventModal}
          setVisible={setEditEventModal}
          events={activeEvents}
          onSaveEvent={onSelectEvent}
          selectedEvent={selectedEvent}
        />
        {profileSelectionModal ? (
          <ProfileSelectionModal
            visible={profileSelectionModal}
            household={household}
            sessions={selectedEvent?._embedded?.sessions || []}
            onBackPress={onProfileBackPress}
            onEditSession={onEditSession}
            onSaveProfiles={onSaveSelectedProfiles}
            storedSelections={storedSelections?.filter((data) =>
              sessionIds.has(data['session.id'])
            )}
            suggestions={suggestions}
            selectedProfiles={selectedProfiles}
          />
        ) : null}
        <EditSessionModal
          visible={editSessionModal}
          sessions={childSessions}
          profile={editSessionProfile}
          onBackPress={onSessionBackPress}
          onSaveSession={onSaveSession}
          currentSession={
            sessionSelections.find(
              (s) =>
                s['end-user.id'] ===
                editSessionProfile?._embedded?.['end-user']?.id
            ) ||
            suggestions.find(
              (s) =>
                s['end-user.id'] ===
                editSessionProfile?._embedded?.['end-user']?.id
            )
          }
        />
      </KitModalV2>
    </View>
  );
}

export const CheckOutCodeCards = ({
  backgroundColor,
  checkIns,
  event,
  profiles,
}: {
  backgroundColor: ColorString;
  checkIns?: ICheckIn[];
  event?: IEvent;
  profiles?: IProfile[];
}) => {
  const { t } = useTranslation();
  const checkInsByLabelCode = mapCheckInsByLabelCode(checkIns);
  const cardWidth = Dimensions.get('window').width - Spacing.xxl;
  const [activeCode, setActiveCode] = useState<string | undefined>();

  return (
    <>
      <KitCarousel cardWidth={cardWidth}>
        {Object.keys(checkInsByLabelCode)
          .sort((labelCode1, labelCode2) =>
            checkInsByLabelCode[labelCode1][0].created_at >
            checkInsByLabelCode[labelCode2][0].created_at
              ? 1
              : -1
          )
          .map((label_code) => (
            <View
              key={label_code}
              style={{
                width: cardWidth,
                paddingHorizontal: Spacing.xxl,
              }}
            >
              <KitText bold white fontSize={20} center>
                {t('check-in:textSecurityCode')}
              </KitText>
              <KitText white center style={{ marginTop: Spacing.xs }}>
                {t('check-in:textShowDuringCheckOut')}
              </KitText>
              <View
                style={[
                  styles.qrContainerBackgroundWhite,
                  { marginTop: Spacing.l },
                ]}
              >
                <KitText black extraBold fontSize={36}>
                  {checkInsByLabelCode[label_code][0].label_code}
                </KitText>
              </View>
              {event && profiles ? (
                <PreCheckSummary
                  icon='arrow-right'
                  onEdit={() => setActiveCode(label_code)}
                  backgroundColor={backgroundColor}
                  profiles={profiles.filter((profile) =>
                    checkInsByLabelCode[label_code].some(
                      (checkIn) =>
                        checkIn._embedded['profile-snapshot']?.id === profile.id
                    )
                  )}
                  title={`${
                    profiles.filter((profile) =>
                      checkInsByLabelCode[label_code].some(
                        (checkIn) =>
                          checkIn._embedded['profile-snapshot']?.id ===
                          profile.id
                      )
                    ).length
                  } ${t('check-in:textCheckedInLower')} • ${dateFormatRange({
                    startDate: checkInsByLabelCode[label_code][0].created_at,
                    timeOnly: true,
                    timezone: event.timezone,
                  })}`}
                />
              ) : null}
            </View>
          ))}
      </KitCarousel>
      <KitModalV2
        isVisible={Boolean(activeCode)}
        onModalHide={() => setActiveCode(undefined)}
        onClose={() => setActiveCode(undefined)}
        anchorBottom
        coverScreen
        modalStyle={{
          padding: 0,
          height: '100%',
          width: '100%',
          paddingBottom: 0,
        }}
        swipeEnabled={false}
        animationIn='slideInRight'
        animationOut='slideOutRight'
      >
        {activeCode ? (
          <PreCheckSecurityCodeSummary
            profiles={
              profiles?.filter((profile) =>
                checkInsByLabelCode[activeCode].some(
                  (checkIn) =>
                    checkIn._embedded['profile-snapshot']?.id === profile.id
                )
              ) || []
            }
            event={event as IEvent}
            labelCode={activeCode}
            checkIns={checkInsByLabelCode[activeCode]}
            backgroundColor={backgroundColor}
            onClose={() => setActiveCode(undefined)}
          />
        ) : null}
      </KitModalV2>
    </>
  );
};

export const mapCheckInsByLabelCode = (
  checkIns?: ICheckIn[]
): Record<string, ICheckIn[]> => {
  const checkInsByLabelCode: Record<string, ICheckIn[]> = {};
  checkIns
    ?.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
    .forEach((checkIn) => {
      if (!checkIn.label_code) {
        return;
      }

      if (checkInsByLabelCode[checkIn.label_code]) {
        if (checkIn.action_type === 'check-in') {
          checkInsByLabelCode[checkIn.label_code].push(checkIn);
        } else if (checkIn.action_type === 'check-out') {
          checkInsByLabelCode[checkIn.label_code] = checkInsByLabelCode[
            checkIn.label_code
          ].filter(
            (c) =>
              c._embedded['end-user'].id !== checkIn._embedded['end-user'].id ||
              c.created_at > checkIn.created_at
          );
          if (checkInsByLabelCode[checkIn.label_code].length === 0) {
            delete checkInsByLabelCode[checkIn.label_code];
          }
        }
      } else {
        checkInsByLabelCode[checkIn.label_code] = [checkIn];
      }
    });

  return checkInsByLabelCode;
};

export const QrCodeCard = ({
  backgroundColor,
  qrCodeData,
  selectedEvent,
  activeProfiles,
  onEdit,
}: {
  backgroundColor: ColorString;
  qrCodeData?: IPreCheckQRCode;
  selectedEvent: IEvent;
  activeProfiles: IProfile[];
  onEdit?: () => void;
}): JSX.Element => {
  return (
    <View style={{ paddingHorizontal: Spacing.xxl }}>
      <View
        style={[
          styles.qrContainerBackgroundWhite,
          {
            width: Dimensions.get('window').width - 108,
            height: Dimensions.get('window').width - 108,
          },
        ]}
      >
        {!qrCodeData ? (
          <KitLoader size={17} />
        ) : (
          <QRCode
            value={JSON.stringify(qrCodeData)}
            size={
              Dimensions.get('window').width - (Spacing.l * 4 + Spacing.xxl * 2)
            }
            ecl='L'
          />
        )}
      </View>
      <PreCheckSummary
        backgroundColor={backgroundColor}
        icon='edit'
        onEdit={onEdit}
        profiles={activeProfiles}
        title={`${selectedEvent.title} • ${dateFormatRange({
          startDate: selectedEvent.start_at,
          timeOnly: true,
          timezone: selectedEvent.timezone,
        })}`}
      />
    </View>
  );
};

export const PreCheckSummary = ({
  backgroundColor,
  icon,
  profiles,
  title,
  onEdit,
}: {
  backgroundColor: ColorString;
  icon?: IconName;
  profiles: IProfile[];
  title: string;
  onEdit?: () => void;
}): JSX.Element => {
  return (
    <TouchableHighlight
      underlayColor={backgroundColor}
      onPress={onEdit}
      disabled={!onEdit}
    >
      <View style={{ flexDirection: 'row', paddingTop: Spacing.l }}>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <KitText
            style={{ flex: 1, flexWrap: 'wrap' }}
            color={Colors.N0}
            numberOfLines={1}
          >
            {title}
          </KitText>
          <KitAvatarList
            size={28}
            users={profiles}
            style={{
              alignSelf: 'flex-start',
              marginTop: Spacing.xs,
            }}
            borderColor={backgroundColor}
          />
        </View>
        {icon ? (
          <View style={{ justifyContent: 'center' }}>
            <KitIcon name={icon} size={20} color={Colors.N0} />
          </View>
        ) : null}
      </View>
    </TouchableHighlight>
  );
};

export const PreCheckRowBlock = ({
  multipleEvents,
  setEditEventModal,
  setProfileSelectionModal,
}: {
  multipleEvents: boolean;
  setEditEventModal: (value: boolean) => void;
  setProfileSelectionModal: (value: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TouchableHighlight
      underlayColor={Colors.N50}
      onPress={() =>
        multipleEvents
          ? setEditEventModal(true)
          : setProfileSelectionModal(true)
      }
    >
      <View style={styles.rowContainer}>
        <View style={{ justifyContent: 'center' }}>
          <View style={styles.PreCheckIcon}>
            <KitIcon color={Colors.N600} name='qr-code' size={20} />
          </View>
        </View>
        <View style={styles.textContainer}>
          <KitText style={{ flex: 1, flexWrap: 'wrap' }} color={Colors.N1000}>
            {t('check-in:titleCheckInQrCode')}
          </KitText>
          <KitText style={{ flex: 1, flexWrap: 'wrap', fontSize: 14 }}>
            {t('check-in:subTitleCheckInQrCode')}
          </KitText>
        </View>
        <View style={{ justifyContent: 'center' }}>
          <KitIcon name='arrow-right' size={20} color={Colors.N300} />
        </View>
      </View>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    paddingVertical: Spacing.l,
    marginHorizontal: Spacing.l,
    minHeight: 78,
    borderBottomColor: Colors.N100,
    borderBottomWidth: 1,
  },
  qrContainerBackground: {
    flexDirection: 'column',
    borderRadius: BorderRadius.l,
    paddingVertical: Spacing.xxl,
    marginHorizontal: Spacing.l,
    marginTop: Spacing.m,
  },
  qrContainerBackgroundWhite: {
    backgroundColor: Colors.N0,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BorderRadius.m,
    padding: Spacing.l,
  },
  PreCheckIcon: {
    alignItems: 'center',
    display: 'flex',
    padding: Spacing.s,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: Spacing.m,
  },
});
