import { KitText } from '@omni/kit/components';
import KitIcon from '@omni/kit/components/KitIcon';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import formatDuration from '@omni/kit/utilities/formatDuration';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Easing, StyleSheet, View } from 'react-native';

import ButtonTouchable from './ButtonTouchable';

/** Media progress has to be past 5 seconds before allowing to resume */
const BEGINNING_THRESHOLD = 5000;
const ENDING_THRESHOLD = 1000;

interface IPlayAndListenButtons {
  duration?: number;
  onPlay: () => void;
  onResume: () => void;
  progress?: number;
}

export default function PlayAndListenButtons({
  duration = 0,
  onPlay,
  onResume,
  progress = 0,
}: IPlayAndListenButtons): JSX.Element {
  const mediaHasEnded = progress > duration - ENDING_THRESHOLD;
  const [opacity] = useState(new Animated.Value(0));

  useEffect((): void => {
    Animated.timing(opacity, {
      duration: 250,
      easing: Easing.out(Easing.ease),
      toValue: 1,
      useNativeDriver: true,
    }).start();
  }, [opacity]);

  return (
    <Animated.View style={[styles.playButtonWrapper, { opacity }]}>
      {progress > BEGINNING_THRESHOLD && !mediaHasEnded ? (
        <ResumeAndPlay
          onPlay={onPlay}
          onResume={onResume}
          progress={progress}
        />
      ) : (
        <PlayButton onPlay={onPlay} />
      )}
    </Animated.View>
  );
}

const ResumeAndPlay = ({
  onPlay,
  onResume,
  progress = 0,
}: {
  onPlay: () => void;
  onResume: () => void;
  progress?: number;
}) => {
  const { t } = useTranslation(['media']);
  const progressText = progress >= 0 ? formatDuration(progress / 1000) : '';

  return (
    <>
      <ButtonTouchable
        onPress={onResume}
        style={{ borderRadius: 27, marginBottom: Spacing.m }}
      >
        <View style={[styles.resumeAndPlayButton]}>
          <KitIcon color={Colors.N0} name='play-circle' size={14} />
          <KitText semiBold white style={styles.text}>
            {t('resumeFrom', { duration: progressText })}
          </KitText>
        </View>
      </ButtonTouchable>
      <ButtonTouchable onPress={onPlay} style={{ borderRadius: 27 }}>
        <View style={styles.resumeAndPlayButton}>
          <KitIcon color={Colors.N0} name='resume' size={14} />
          <KitText semiBold white style={styles.text}>
            {t('playFromBeginning')}
          </KitText>
        </View>
      </ButtonTouchable>
    </>
  );
};

const PlayButton = ({ onPlay }: { onPlay: () => void }) => {
  return (
    <ButtonTouchable
      onPress={onPlay}
      style={{ borderRadius: BorderRadius.full }}
    >
      <View style={styles.playButton}>
        <KitIcon color={Colors.N0} name='play' size={32} />
      </View>
    </ButtonTouchable>
  );
};

const styles = StyleSheet.create({
  playButtonWrapper: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  playButton: {
    alignItems: 'center',
    backgroundColor: '#000000dd',
    borderRadius: BorderRadius.l * 2,
    height: 64,
    justifyContent: 'center',
    width: 64,
  },
  resumeAndPlayButton: {
    alignItems: 'center',
    backgroundColor: '#000000dd',
    borderRadius: BorderRadius.full,
    color: Colors.N0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: Spacing.xl,
    paddingVertical: Spacing.m,
    minWidth: 229,
  },
  text: {
    marginLeft: 10,
  },
});
