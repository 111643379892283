import moment from 'moment';

import TrackMediaProgressState from './TrackMediaProgressState';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:ResetPatchTimer'
);

export default (clear = false): void => {
  const timer = clear ? null : moment.utc();
  debug('Reset patch progress timer to ', timer?.toString());
  TrackMediaProgressState.saveProgressToNetworkTimer = timer;
};
