import { KitIcon, KitText } from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import { SizeClassV2, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Depth from '@omni/kit/theming/Depth';
import Spacing from '@omni/kit/theming/Spacing';
import formatPhoneNumber from '@omni/kit/utilities/formatPhoneNumber';
import Lottie from 'lottie-react-native';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Animated,
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { useKioskContext } from '../contexts/KioskContext';
import { profileImageUrl } from '../utilities/profile';
import ChildDetailModal from './ChildDetailModal';
import ChildProfileModal from './ChildProfileModal';
import RemoveProfileModal from './RemoveProfileModal';

export default function ProfileCard({
  profile,
  isCheckedIn,
  isChild,
  isGuestProfile,
  isSelected,
  onEditSession,
  onSelect,
  refreshData,
  session,
  showSession = false,
  showContactInfo = false,
  showEditIcon = Boolean(isChild || onEditSession),
  height = 230,
  index,
}: {
  profile: IProfile;
  isCheckedIn?: boolean;
  isChild?: boolean;
  isGuestProfile?: boolean;
  isSelected?: boolean;
  onEditSession?: () => void;
  onSelect?: () => void;
  refreshData?: () => void;
  session?: string;
  showSession?: boolean;
  showContactInfo?: boolean;
  showEditIcon?: boolean;
  height?: number;
  index?: number;
}): JSX.Element {
  const { t } = useTranslation(['profile']);
  const { windowHeight, windowWidth, sizeClassV2 } = useSizeClass();
  const isSmall = sizeClassV2 < SizeClassV2.M;

  const isLandscape = windowHeight < windowWidth;
  const [isChildDetailModalVisible, setIsChildDetailModalVisible] =
    useState(false);
  const [isEditProfileModalVisible, setIsEditProfileModalVisible] =
    useState(false);
  const [isRemoveProfileModalVisible, setIsRemoveProfileModalVisible] =
    useState(false);

  const { setModalInteraction } = useKioskContext();

  const getEmail = useMemo(
    () => profile.email ?? profile._embedded?.['end-user']?.email,
    [profile]
  );

  const getPhone = useMemo(
    () => profile.phone ?? profile._embedded?.['end-user']?.phone,
    [profile]
  );

  const [isActive, setIsActive] = useState(false);
  const active = useMemo(() => new Animated.Value(0), []);
  const selected = useMemo(() => new Animated.Value(0), []);
  const prevSelected = useRef<boolean | undefined>(undefined);
  const animationRef = useRef<Lottie>(null);
  const pressInPageY = useRef<number | undefined>();

  useEffect(() => {
    const animate = prevSelected.current !== undefined;

    if (isSelected || isCheckedIn) {
      Animated.timing(selected, {
        toValue: 1,
        useNativeDriver: true,
        duration: animate ? 100 : 0,
      }).start();
      if (animate) {
        animationRef.current?.reset();
        animationRef.current?.play();
      } else {
        animationRef.current?.play(20, 20);
      }
    } else {
      Animated.timing(selected, {
        toValue: 0,
        useNativeDriver: true,
        duration: animate ? 100 : 0,
      }).start();
    }

    prevSelected.current = isSelected;
  }, [isCheckedIn, isSelected, selected]);

  useEffect(() => {
    if (isActive) {
      Animated.timing(active, {
        toValue: 1,
        useNativeDriver: true,
        duration: 0,
      }).start();
    } else {
      Animated.timing(active, {
        toValue: 0,
        useNativeDriver: true,
        duration: 0,
      }).start();
    }
  }, [isActive, active]);

  const onPressIn = (e: GestureResponderEvent) => {
    pressInPageY.current = e.nativeEvent.pageY;
    setIsActive(true);
  };

  const onPressOut = (e: GestureResponderEvent) => {
    setIsActive(false);
    // Make sure the press was a tap and not a scroll
    if (
      pressInPageY.current &&
      Math.abs(e.nativeEvent.pageY - pressInPageY.current) < 10
    ) {
      onSelect && onSelect();
    }
  };

  return (
    <>
      <View
        style={{
          flexBasis: isSmall ? '50%' : isLandscape ? '25%' : '33.33%',
          paddingHorizontal: isSmall ? 0 : Spacing.m,
        }}
      >
        <Animated.View
          style={[
            styles.container,
            !isActive && !isSelected && Depth.xxl,
            isSmall && {
              marginBottom: 18,
              marginRight: index !== undefined && index % 2 === 0 ? 9 : 0,
              marginLeft: index !== undefined && index % 2 !== 0 ? 9 : 0,
            },
            {
              transform: [
                {
                  scale: active.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0.95],
                  }),
                },
              ],
            },
          ]}
        >
          <Pressable
            onPressIn={onPressIn}
            onPressOut={onPressOut}
            disabled={isCheckedIn}
          >
            <View style={{ height }}>
              {profile?._embedded?.photo?.id ? (
                <KitImage
                  style={{
                    flex: 1,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                  }}
                  source={{
                    uri: profileImageUrl(profile),
                  }}
                  resizeMode='cover'
                />
              ) : (
                <View style={styles.imagePlaceholder}>
                  <KitIcon
                    name={isChild ? 'smile' : 'person'}
                    size={54}
                    color={Colors.N200}
                  />
                </View>
              )}
              <Animated.View
                style={[
                  styles.selectedLayer,
                  {
                    opacity: selected.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1],
                    }),
                  },
                ]}
              >
                <View>
                  <Lottie
                    ref={animationRef}
                    source={require('../images/selected-animation.json')}
                    loop={false}
                    style={{
                      height: isSmall ? 36 : 48,
                      width: isSmall ? 36 : 48,
                    }}
                    speed={2}
                  />
                </View>
                {isCheckedIn && (
                  <KitText
                    fontSize={20}
                    bold
                    white
                    style={{ marginTop: Spacing.s }}
                  >
                    {t('check-in:textCheckedIn')}
                  </KitText>
                )}
              </Animated.View>
            </View>
            <View
              style={[
                { padding: Spacing.l, overflow: 'hidden' },
                (isActive || isSelected) && styles.containerBorder,
              ]}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom:
                    isChild || showSession || showContactInfo
                      ? isSmall
                        ? 8
                        : 11
                      : 0,
                }}
              >
                <KitText
                  fontSize={isSmall ? 14 : 20}
                  bold
                  black
                  numberOfLines={1}
                >
                  {profile.first_name} {profile.last_name}
                </KitText>
              </View>
              {showSession ? (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: isSmall ? 0 : 6,
                    alignItems: 'center',
                  }}
                >
                  <KitIcon
                    name='classroom'
                    size={isSmall ? 12 : 16}
                    color={Colors.N600}
                    style={{ marginRight: Spacing.s }}
                  />
                  <KitText
                    fontSize={isSmall ? 14 : 18}
                    numberOfLines={1}
                    style={{ width: '85%' }}
                  >
                    {session ? session : t('check-in:textNoSession')}
                  </KitText>
                </View>
              ) : null}
              {showContactInfo ? (
                <>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginBottom: isSmall ? 0 : 6,
                      alignItems: 'center',
                    }}
                  >
                    <KitIcon
                      name='phone'
                      size={16}
                      color={Colors.N600}
                      style={{ marginRight: Spacing.s }}
                    />
                    <KitText
                      fontSize={18}
                      numberOfLines={1}
                      style={{ width: '85%' }}
                    >
                      {formatPhoneNumber(getPhone) ??
                        t('profile:textNoPhoneNumber')}
                    </KitText>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <KitIcon
                      name='email'
                      size={16}
                      color={Colors.N600}
                      style={{ marginRight: Spacing.s }}
                    />
                    <KitText
                      fontSize={18}
                      numberOfLines={1}
                      style={{ width: '85%' }}
                    >
                      {getEmail ?? t('profile:textNoEmail')}
                    </KitText>
                  </View>
                </>
              ) : null}
              {isChild ? (
                <>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginBottom: isSmall ? 0 : 6,
                      alignItems: 'center',
                    }}
                  >
                    <KitIcon
                      name='heart'
                      size={isSmall ? 12 : 16}
                      color={Colors.N600}
                      style={{ marginRight: Spacing.s }}
                    />
                    <KitText
                      fontSize={isSmall ? 14 : 18}
                      numberOfLines={1}
                      style={{ width: '85%' }}
                    >
                      {profile.care_notes ?? t('profile:textNoCareNotes')}
                    </KitText>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <KitIcon
                      name='allergies'
                      size={isSmall ? 12 : 16}
                      color={Colors.N600}
                      style={{ marginRight: Spacing.s }}
                    />
                    <KitText
                      fontSize={isSmall ? 14 : 18}
                      numberOfLines={1}
                      style={{ width: '85%' }}
                    >
                      {profile.allergy_notes ?? t('profile:textNoAllergies')}
                    </KitText>
                  </View>
                </>
              ) : null}
              {showEditIcon ? (
                <LinearGradient
                  colors={['#FFFFFF00', Colors.N0]}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                  locations={[0, 0.29]}
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: isSmall ? '45%' : '30%',
                    borderBottomRightRadius: 16,
                    alignItems: 'flex-end',
                  }}
                >
                  <TouchableOpacity
                    style={{ padding: Spacing.l }}
                    onPress={() =>
                      isChild && !isSmall
                        ? setIsChildDetailModalVisible(true)
                        : onEditSession && onEditSession()
                    }
                  >
                    <KitIcon name='edit' size={20} />
                  </TouchableOpacity>
                </LinearGradient>
              ) : null}
            </View>
          </Pressable>
        </Animated.View>
      </View>
      {isChildDetailModalVisible && (
        <ChildDetailModal
          profile={profile}
          isVisible={isChildDetailModalVisible}
          refreshData={refreshData}
          onClose={() => {
            setIsChildDetailModalVisible(false);
            setModalInteraction(false);
          }}
          onEditProfile={() => {
            setIsChildDetailModalVisible(false);
            setIsEditProfileModalVisible(true);
          }}
          onEditSession={
            !isCheckedIn
              ? () => {
                  setIsChildDetailModalVisible(false);
                  onEditSession && onEditSession();
                }
              : undefined
          }
          onRemoveProfile={() => {
            setIsChildDetailModalVisible(false);
            setIsRemoveProfileModalVisible(true);
          }}
          session={session}
          showSession={showSession}
        />
      )}
      {isEditProfileModalVisible && (
        <ChildProfileModal
          title={t('check-in:textEditProfileInfo')}
          profile={profile}
          isVisible={isEditProfileModalVisible}
          refreshData={refreshData}
          isGuestEdit={isGuestProfile}
          onClose={() => {
            setIsEditProfileModalVisible(false);
            setModalInteraction(false);
          }}
        />
      )}
      {isRemoveProfileModalVisible && (
        <RemoveProfileModal
          profile={profile}
          isGuestProfile={isGuestProfile}
          isVisible={isRemoveProfileModalVisible}
          refreshData={refreshData}
          onClose={() => {
            setIsRemoveProfileModalVisible(false);
            setModalInteraction(false);
          }}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: BorderRadius.l,
    marginBottom: Spacing.xl,
    backgroundColor: Colors.N0,
  },
  containerBorder: {
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: Colors.N100,
    borderBottomLeftRadius: BorderRadius.l,
    borderBottomRightRadius: BorderRadius.l,
  },
  containerShadow: {
    shadowColor: 'rgba(0, 0, 0, 0.06)',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 10,
  },
  selectedLayer: {
    flex: 1,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  imagePlaceholder: {
    flex: 1,
    backgroundColor: Colors.N75,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
});
