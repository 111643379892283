import BaseServiceV2, {
  buildUrl,
  IHttpResponse,
  IUri,
} from '../../BaseServiceV2';

import Environment from '../../../Environment';
import { IJoinRequests } from '../Types';

const RESOURCE = 'join-requests';

interface IGetJoinRequests extends Omit<IUri, 'resource'> {
  appKey: string;
  token: string;
}

interface IGetJoinRequestsResponse extends IHttpResponse {
  body?: IJoinRequests;
}

export default ({
  appKey,
  token,
}: IGetJoinRequests): Promise<IGetJoinRequestsResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    filters: [
      ['app_key', appKey],
      ['role', 'manager'],
      ['status', 'pending'],
    ],
    sorts: ['-created_at'],
    resource: RESOURCE,
  });

  return BaseServiceV2.Get({
    url,
    appKey,
    token,
    skipTimestamp: true,
  });
};
