import { KitIcon, KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

const KitDropdown = ({
  title,
  children,
  style,
}: {
  title: string;
  children: JSX.Element;
  style?: ViewStyle;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <View style={[style, styles.container]}>
      <TouchableOpacity style={styles.header} onPress={toggleDropdown}>
        <KitText color={Colors.N900} fontSize={14} lineHeight={20}>
          {title}
        </KitText>
        <KitIcon name='arrow-down' size={20} color={Colors.N300} />
      </TouchableOpacity>
      {isOpen && <View>{children}</View>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default KitDropdown;
