import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

import { NativeTopBarStyle } from './NativeHelpers';

const MAX_CONTENT_OFFSET = 10;
const MIN_CONTENT_OFFSET = -30;

export const updateOnScrollTopBarStyle = (
  event: NativeSyntheticEvent<NativeScrollEvent>,
  topBarStyle?: NativeTopBarStyle,
  onScrollTopBarStyle?: NativeTopBarStyle
): NativeTopBarStyle | undefined => {
  if (topBarStyle && topBarStyle !== NativeTopBarStyle.Hidden) {
    if (
      event.nativeEvent.contentOffset.y < MAX_CONTENT_OFFSET &&
      event.nativeEvent.contentOffset.y > MIN_CONTENT_OFFSET
    ) {
      return topBarStyle;
    } else {
      return onScrollTopBarStyle;
    }
  }
};
