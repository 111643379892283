import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../theming/Colors';
import { ImageServiceType, parseImageUrl } from '../utilities/utilities';
import KitImage from './KitImage';

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  backgroundColor?: string;
  imageUrl?: string;
  size: number;
  style?: ViewStyle;
  testID?: string;
  tintColor?: string;
}

export default function KitAppIcon({
  backgroundColor = Colors.N200,
  imageUrl,
  size,
  style,
  testID = '',
  tintColor,
}: IProps): JSX.Element {
  return (
    <View
      style={[
        styles.container,
        {
          width: size,
          height: size,
          borderRadius: size / 2,
          backgroundColor,
        },
        style,
      ]}
      testID={testID}
    >
      {imageUrl ? (
        <KitImage
          style={{
            // Ensures there is always even space on both sides
            width: size - Math.round(size * 0.2) * 2,
            height: size - Math.round(size * 0.2) * 2,
            tintColor,
          }}
          source={{
            uri: parseImageUrl(imageUrl, size, size, ImageServiceType.ImagePng),
          }}
        />
      ) : null}
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.N200,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
