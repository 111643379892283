import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Dimensions, ScaledSize } from 'react-native';

import GetSizeClass from './GetSizeClass';
import GetSizeClassV2 from './GetSizeClassV2';
import { SizeClass, SizeClassContextType, SizeClassV2 } from './types';

const getWindowSize = (): ScaledSize => {
  return Dimensions.get('window');
};

const getSmallestWindowWidth = (window: ScaledSize): number => {
  return Math.min(window.width, window.height);
};

const initialSizeClass: SizeClassContextType = {
  sizeClassV2: GetSizeClassV2(getWindowSize().width),
  sizeClass: GetSizeClass(getWindowSize().width),
  smallestWindowWidth: getSmallestWindowWidth(getWindowSize()),
  windowWidth: getWindowSize().width,
  windowHeight: getWindowSize().height,
};

export const SizeClassContext =
  createContext<SizeClassContextType>(initialSizeClass);

export const SizeClassContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [sizeClass, setSizeClass] = useState<SizeClass>(
    GetSizeClass(getWindowSize().width)
  );
  const [sizeClassV2, setSizeClassV2] = useState<SizeClassV2>(
    GetSizeClassV2(getWindowSize().width)
  );

  /**
   * Size of the visible Application window
   *
   * In docs on the web, this is the full width of the docs web app (including the side menu panel)
   * A screen component like Block Page may be significantly smaller when used as a web embed.
   *
   * If you need to support web-embed scenarios in a react-native app, please refer to
   * @omni/kit/ScreenContext for how it uses 'onLayout' to determine the appropriate "sub" window size.
   *
   * Do not attempt to use Dimensions.get('screen'). This returns the size of the device display which may be
   * significantly larger than the application window, and layout decisions should be made based on application window size.
   */
  const [windowWidth, setWindowWidth] = useState<number>(getWindowSize().width);

  const [windowHeight, setWindowHeight] = useState<number>(
    getWindowSize().height
  );

  const [smallestWindowWidth, setSmallestWindowWidth] = useState<number>(
    getSmallestWindowWidth(getWindowSize())
  );

  const dimensionHandler = useCallback(
    ({ window }: { window: ScaledSize; screen: ScaledSize }) => {
      const currentClass = GetSizeClass(window.width);

      if (currentClass !== sizeClass) {
        setSizeClass(currentClass);
      }

      const currentSizeClassV2 = GetSizeClassV2(window.width);
      setSizeClassV2(currentSizeClassV2);

      setSmallestWindowWidth(getSmallestWindowWidth(window));
      setWindowWidth(window.width);
      setWindowHeight(window.height);
    },
    [sizeClass]
  );

  useEffect(() => {
    const eventListener = Dimensions.addEventListener(
      'change',
      dimensionHandler
    );

    return () => {
      eventListener.remove();
    };
  }, [dimensionHandler]);

  return (
    <SizeClassContext.Provider
      value={{
        sizeClassV2,
        sizeClass: sizeClass,
        smallestWindowWidth: smallestWindowWidth,
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      }}
    >
      {children}
    </SizeClassContext.Provider>
  );
};

export const useSizeClass = (): SizeClassContextType =>
  useContext<SizeClassContextType>(SizeClassContext);
