export const REQUEST_ERROR = {
  AUTHORIZATION: 'Authorization error',
  BAD_REQUEST: 'Bad request',
  NO_INTERNET: 'Unable to connect to the internet',
  NOT_FOUND: 'Not found',
  TOO_MANY_REQUESTS: 'Too many requests',
  SERVER_ERROR: 'Server error',
};

export const validateResponse = (
  responseStatus?: number,
  responseHeaders?: Record<string, string>
): void => {
  if (!responseStatus || responseStatus === 0) {
    throw new Error(REQUEST_ERROR.NO_INTERNET);
  }

  if (responseStatus === 400) {
    throw new Error(REQUEST_ERROR.BAD_REQUEST);
  }

  if (responseStatus === 401 || responseStatus === 403) {
    throw new Error(REQUEST_ERROR.AUTHORIZATION);
  }

  if (responseStatus === 404) {
    throw new Error(REQUEST_ERROR.NOT_FOUND);
  }

  if (responseStatus === 429) {
    const retryAfterSec = responseHeaders?.['retry-after'];
    throw new Error(`${REQUEST_ERROR.TOO_MANY_REQUESTS}:${retryAfterSec}`);
  }

  if (responseStatus && responseStatus >= 500 && responseStatus < 600) {
    throw new Error(REQUEST_ERROR.SERVER_ERROR);
  }
};

export const getRetryAfterMsec = (error: Error): number | undefined => {
  const isRetryAfterError = error.message.includes(
    REQUEST_ERROR.TOO_MANY_REQUESTS
  );

  if (isRetryAfterError) {
    const segments = error.message.split(':');
    const retryAfterString = segments.length > 1 ? segments[1] : '1';

    return Math.round(parseFloat(retryAfterString) * 1000.0);
  }

  return undefined;
};

export const shouldRetryRequest = (error: Error): boolean => {
  return (
    error.message === REQUEST_ERROR.SERVER_ERROR ||
    error.message === REQUEST_ERROR.NO_INTERNET ||
    error.message.includes(REQUEST_ERROR.TOO_MANY_REQUESTS)
  );
};
