import BrowserBlock, {
  BrowserBlockProps,
} from '@omni/blocks/blocks/browser/BrowserBlock';
import PreCheckBlock from '@omni/blocks/blocks/precheck/PreCheckBlock';
import { KitInput } from '@omni/kit/components';
import { IKitInputProps } from '@omni/kit/components/KitInput';
import { IBlockProps } from '@omni/kit/feeds/blockTypes';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';

import ActionRowBlock, { IActionRowBlockProps } from './ActionRowBlock';
import AvatarBlock, { IAvatarBlockProps } from './AvatarBlock';
import AvatarRowBlock, { IAvatarRowBlockProps } from './AvatarRowBlock';
import ButtonBlock, { IButtonBlockProps } from './ButtonBlock';
import CircleButtonRowBlock, {
  ICircleButtonRowBlockProps,
} from './CircleButtonRowBlock';
import CopyrightLogoBlock, {
  ICopyrightLogoBlockProps,
} from './CopyrightLogoBlock';
import ErrorBlock, { IErrorBlockProps } from './ErrorBlock';
import FrameBlock, { FrameBlockProps } from './FrameBlock';
import GivingBlock, { GivingBlockProps } from './GivingBlock';
import IconBlock, { IIconBlockProps } from './IconBlock';
import IconRowBlock, { IIconRowBlockProps } from './IconRowBlock';
import ImageBlock, { IImageBlockProps } from './ImageBlock';
import ListBlock, { IListBlockProps } from './ListBlock';
import MediaBannerBlock, { IMediaBannerBlockProps } from './MediaBannerBlock';
import ScheduleBlock from './ScheduleBlock';
import SectionBlock, { SectionBlockProps } from './SectionBlock';
import TextBlock, { ITextBlockProps } from './TextBlock';
import AccountBlock from './account';
import { IAccountBlockProps } from './account/AccountBlock';
import ContinuePlayingBlock from './continuePlaying/ContinuePlayingBlock';
import { ContinuePlayingBlockProps } from './continuePlaying/types';
import { BlockData, ComponentType } from './types';

export const renderBlock = (
  block: BlockData,
  componentProps: unknown,
  authProviderId?: string,
  serviceToken?: string,
  appKey?: string,
  accountBlockProps?: {
    addressRequired?: boolean;
    clientBrandColor?: string;
    includeLogoutButton?: boolean;
    isLoading: boolean;
    largeSaveButtonEnabled?: boolean;
    onDismiss?: () => void;
    setIsEdited?: (isEdited: boolean) => void;
    setIsLoading?: (isLoading: boolean) => void;
    setRefreshing?: (refreshing: boolean) => void;
    submitActionRef?: React.MutableRefObject<() => void>;
  }
): JSX.Element | null => {
  switch (block.type) {
    case ComponentType.Account:
      const feedProps = componentProps as IAccountBlockProps;

      return (
        <AccountBlock
          {...feedProps}
          addressRequired={accountBlockProps?.addressRequired}
          appKey={appKey as string}
          clientBrandColor={accountBlockProps?.clientBrandColor}
          includeLogoutButton={accountBlockProps?.includeLogoutButton}
          isLoading={accountBlockProps?.isLoading}
          largeSaveButtonEnabled={accountBlockProps?.largeSaveButtonEnabled}
          onDismiss={accountBlockProps?.onDismiss}
          setIsEdited={accountBlockProps?.setIsEdited}
          setIsLoading={accountBlockProps?.setIsLoading}
          setRefreshing={accountBlockProps?.setRefreshing}
          submitActionRef={accountBlockProps?.submitActionRef}
        />
      );
    case ComponentType.ActionRow:
      return <ActionRowBlock {...(componentProps as IActionRowBlockProps)} />;
    case ComponentType.Avatar:
      return <AvatarBlock {...(componentProps as IAvatarBlockProps)} />;
    case ComponentType.AvatarRow:
      return <AvatarRowBlock {...(componentProps as IAvatarRowBlockProps)} />;
    case ComponentType.Browser:
      return <BrowserBlock {...(componentProps as BrowserBlockProps)} />;
    case ComponentType.Button:
      return <ButtonBlock {...(componentProps as IButtonBlockProps)} />;
    case ComponentType.CircleButtonRow:
      return (
        <CircleButtonRowBlock
          {...(componentProps as ICircleButtonRowBlockProps)}
        />
      );
    case ComponentType.CopyrightLogo:
      return (
        <CopyrightLogoBlock {...(componentProps as ICopyrightLogoBlockProps)} />
      );
    case ComponentType.ContinuePlaying:
      return (
        <ContinuePlayingBlock
          {...(componentProps as ContinuePlayingBlockProps)}
          appKey={appKey}
          setAccountPagePadding={true}
          style={{ marginTop: Spacing.xxl }}
        />
      );
    case ComponentType.Error:
      return <ErrorBlock {...(componentProps as IErrorBlockProps)} />;
    case ComponentType.Frame:
      return <FrameBlock {...(componentProps as FrameBlockProps)} />;
    case ComponentType.Giving:
      return <GivingBlock {...(componentProps as GivingBlockProps)} />;
    case ComponentType.Icon:
      return <IconBlock {...(componentProps as IIconBlockProps)} />;
    case ComponentType.IconRow:
      return <IconRowBlock {...(componentProps as IIconRowBlockProps)} />;
    case ComponentType.Image:
      return <ImageBlock {...(componentProps as IImageBlockProps)} />;
    case ComponentType.Input:
      return <KitInput {...(componentProps as Partial<IKitInputProps>)} />;
    case ComponentType.List:
      return <ListBlock {...(componentProps as IListBlockProps)} />;
    case ComponentType.MediaBanner:
      return (
        <MediaBannerBlock
          // default to block page authProviderId, can be overridden by componentProps
          authProviderId={authProviderId}
          {...(componentProps as IMediaBannerBlockProps)}
          appKey={appKey}
        />
      );
    case ComponentType.Schedule:
      return <ScheduleBlock />;
    case ComponentType.Section:
      return <SectionBlock {...(componentProps as SectionBlockProps)} />;
    case ComponentType.Text:
      return <TextBlock {...(componentProps as ITextBlockProps)} />;
    case ComponentType.PreCheck:
      return (
        <PreCheckBlock appKey={appKey} {...(componentProps as IBlockProps)} />
      );
    default:
      return null;
  }
};
