import GetCalendar from './GetCalendar';
import GetEvent from './GetEvent';
import GetEvents from './GetEvents';
import GetSession from './GetSession';
import GetSessions from './GetSessions';

export default {
  GetEvent,
  GetEvents,
  GetSessions,
  GetSession,
  GetCalendar,
};
