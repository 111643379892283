const BorderRadius: {
  s: 4;
  m: 8;
  l: 16;
  xl: 24;
  full: 1000;
} = {
  s: 4,
  m: 8,
  l: 16,
  xl: 24,
  full: 1000,
};

export default BorderRadius;
