import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import {
  SOURCE_TYPES,
  SourceContextProvider,
} from '@omni/kit/contexts/SourceContext';
import { IRootProps } from '@omni/kit/contexts/types';
import { DOMAIN_GROUPS } from '@omni/kit/services/SearchService/Constants';
import ReactToWebComponent from '@omni/kit/utilities/ReactToWebComponent';
import store from '@omni/messaging/shared/redux/store';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, View } from 'react-native';
import { Provider } from 'react-redux';

import Search from '../../src/search';

interface IGroupsProps {
  pathPrefix?: string;
}

export default function Groups(props: IGroupsProps & IRootProps): JSX.Element {
  const { sizeClass } = useSizeClass();
  const { t } = useTranslation(['groups']);

  /**
   * DISP-3448: Allow a user to directly link to a group detail when
   * the 'WebNavigator' in Messaging.web.tsx is rendered which
   * happens on large screens or when the user directly linked to groups.
   *
   * TODO: refactor web messaging to use a single navigator/router on all screen types
   * so that we do not need to have a special case to enable linking here.
   *
   * Please note that linking should only exist at single point in the component tree,
   * otherwise an unhandled error will be thrown or the app will crash.
   * e.g. do not attempt to enable linking here when user is at root level of
   * web messaging on small screens, or an exception will occur since linking
   * is already defined within the small screen navigation stack in Messaging.web.tsx.
   *
   */
  const isSmall = sizeClass === SizeClass.Small;
  const isWebGroups =
    Platform.OS === 'web' &&
    (window?.location?.href?.includes('/groups') ?? false);
  const enableLinking = !isSmall || isWebGroups;

  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        alignSelf: 'center',
      }}
    >
      <SafeAreaView />
      <Search
        appKey={props.appKey}
        rootAppKey={props.appKey}
        domains={[DOMAIN_GROUPS]}
        moduleCommand={props.moduleCommand}
        pathPrefix={props.pathPrefix ?? ''}
        headerTitle={t('headerTitle')}
        headerShown={props.headerShown ?? false}
        isNativeNavStackAtRoot={props.isNativeNavStackAtRoot}
        enableLinking={enableLinking}
        presentationStyle={props.presentationStyle}
      />
    </View>
  );
}

/**
 * GroupsApp includes the necessary context providers and store for it to
 * function outside the context of Messaging as a dedicated screen,
 * via mobile deep link or as a web component.
 *
 * Web Component usage example:
 * <ss-groups
 *   app-key="CHURCH"
 *   access-token="Bearer eyJh...PMrk"
 *   path-prefix="sunny/"
 * ></ss-groups>
 */
export const GroupsApp = (props: IGroupsProps & IRootProps): JSX.Element => {
  return (
    // using source 'messaging' to track group finder together with messaging for the time being
    <SourceContextProvider source={SOURCE_TYPES.MESSAGING}>
      <Provider store={store}>
        <Groups {...props} />
      </Provider>
    </SourceContextProvider>
  );
};

// Required for reactToWebComponent
GroupsApp.propTypes = {
  accessToken: PropTypes.string,
  appKey: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string,
};

export function registerGroupsWC(tagName = 'ss-groups'): void {
  customElements.define(tagName, ReactToWebComponent(GroupsApp));
}
