import { Animated } from 'react-native';

export const animatePressIn = (scale: Animated.Value): void => {
  Animated.timing(scale, {
    toValue: 0.95,
    useNativeDriver: true,
    duration: 0,
  }).start();
};

export const animatePressOut = (scale: Animated.Value): void => {
  Animated.timing(scale, {
    toValue: 1.0,
    useNativeDriver: true,
    duration: 0,
  }).start();
};

export const createAnimation = (
  value: Animated.Value | Animated.ValueXY,
  toValue: Animated.TimingAnimationConfig['toValue'],
  duration?: Animated.TimingAnimationConfig['duration'],
  easing?: Animated.TimingAnimationConfig['easing']
): Animated.CompositeAnimation => {
  return Animated.timing(value, {
    toValue,
    useNativeDriver: true,
    duration,
    easing,
  });
};
