import ActionSheetItem from '@omni/kit/components/KitActionSheetItem';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';

export default function MoreActionModal({
  isManager,
  isVisible,
  canLeave,
  isMuted,
  onClose,
  onLeaveGroup,
  onDeleteGroup,
  onMuteGroup,
}: {
  isManager: boolean;
  canLeave: boolean;
  isVisible: boolean;
  isMuted: boolean;
  onClose: () => void;
  onLeaveGroup: () => void;
  onDeleteGroup: () => void;
  onMuteGroup: () => void;
}): JSX.Element | null {
  const { t } = useTranslation(['groups']);
  const { windowHeight, windowWidth } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  return (
    <KitModalV2
      isVisible={isVisible}
      onClose={onClose}
      anchorBottom={true}
      coverScreen
      modalStyle={{
        padding: Spacing.l,
        alignItems: 'flex-start',
        paddingBottom: Spacing.xl,
      }}
    >
      {!isLandscape ? (
        <ActionSheetItem
          icon={isMuted ? 'notifications' : 'notifications-off'}
          iconColor={Colors.N900}
          label={isMuted ? t('unmuteNotifications') : t('muteNotifications')}
          onPress={onMuteGroup}
        />
      ) : null}
      {canLeave && (
        <ActionSheetItem
          icon='logout'
          iconColor={Colors.N900}
          label={t('labelLeaveGroup')}
          onPress={onLeaveGroup}
        />
      )}
      {isManager && (
        <ActionSheetItem
          icon='delete'
          iconColor={Colors.R500}
          label={t('labelDeleteGroup')}
          onPress={onDeleteGroup}
        />
      )}
    </KitModalV2>
  );
}
//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({});
