import {
  KitButton,
  KitIcon,
  KitInput,
  KitListItem,
  KitText,
  KitTouchable,
  KitTouchableIcon,
} from '@omni/kit/components';
import KitMapView from '@omni/kit/components/KitMapView';
import PlacesAutocomplete from '@omni/kit/components/PlacesAutocomplete';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { lazy, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';

interface Props {
  navigation: any;
  route: any;
  isOpenLocationModal: boolean;
  setIsOpenLocationModal: (open: boolean) => void;
}
interface SelectedLocation {
  latitude: number;
  longitude: number;
  address: string;
  streetNumber: string | undefined;
  restOfAddress: string | undefined;
}
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
interface GooglePlaceDetails {
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  address_components: AddressComponent[];
  formatted_address: string;
}

export default function AddMeetingLocationScreen({
  route,
  navigation,
  isOpenLocationModal,
  setIsOpenLocationModal,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] =
    useState<SelectedLocation | null>(null);

  const [locationNameValue, setLocationNameValue] = useState('');

  const processAddressComponents = (
    addressComponents: any[],
    latitude: number,
    longitude: number,
    description: string
  ) => {
    const streetNumberComponent = addressComponents.find((component) =>
      component.types.includes('street_number')
    );
    const streetNumber = streetNumberComponent
      ? streetNumberComponent.long_name
      : undefined;

    const restOfAddress = addressComponents
      .filter((component) => !component.types.includes('street_number'))
      .map((component) => component.long_name)
      .join(', ');

    setSelectedLocation({
      latitude: latitude,
      longitude: longitude,
      address: description,
      streetNumber: streetNumber,
      restOfAddress: restOfAddress,
    });
  };

  const handleSelectLocation = (
    data: any,
    details: GooglePlaceDetails | null
  ) => {
    if (Platform.OS !== 'web' && details) {
      processAddressComponents(
        details.address_components,
        details.geometry.location.lat,
        details.geometry.location.lng,
        data.description
      );
    } else {
      const latitude =
        typeof data.geometry.location.lat === 'function'
          ? data.geometry.location.lat()
          : data.geometry.location.lat;
      const longitude =
        typeof data.geometry.location.lat === 'function'
          ? data.geometry.location.lng()
          : data.geometry.location.lng;
      processAddressComponents(
        data.address_components,
        latitude,
        longitude,
        data.description
      );
    }
  };

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () =>
          selectedLocation ? (
            <KitTouchable
              onPress={null}
              style={{
                paddingLeft: 12,
                paddingRight: Platform.OS === 'ios' ? 12 : 18,
                paddingVertical: 6,
                marginRight: -6,
                borderRadius: 40,
              }}
            >
              <KitText color={Colors.brand} fontSize={16}>
                {t('common:buttonTitleSave')}
              </KitText>
            </KitTouchable>
          ) : null,
      });
    }
  }, [navigation, selectedLocation]);

  return (
    <>
      <View style={styles.container}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <KitText color={Colors.N900} style={styles.title}>
            {t('messaging:meetingLocation')}
          </KitText>
          {isOpenLocationModal && (
            <KitTouchableIcon
              name='remove'
              buttonSize={20}
              onPress={() => {
                if (setIsOpenLocationModal) {
                  setIsOpenLocationModal(false);
                }
              }}
              color={Colors.N300}
            />
          )}
        </View>

        {selectedLocation ? (
          <>
            <KitMapView
              latitude={selectedLocation.latitude}
              longitude={selectedLocation.longitude}
              circle={{
                radius: 3218.68,
                strokeColor: Colors.N1000,
                fillColor: 'rgba(0, 0, 0, 0.2)',
                strokeOpacity: 0.8,
                fillOpacity: 0.8,
                strokeWeight: 0.6,
              }}
            />
            <KitListItem
              title={selectedLocation.streetNumber}
              subtitle={selectedLocation.restOfAddress}
              topBorder={false}
              style={{ marginTop: Spacing.xl }}
              ImageComponent={
                <KitIcon
                  name='not-filled-location'
                  size={18}
                  color={Colors.N600}
                  style={{ marginLeft: Spacing.s }}
                />
              }
            />
            <KitInput
              label={t('messaging:labelLocationNameOptional')}
              onChangeText={(e) => setLocationNameValue(e)}
              style={{
                flex: 1,
                marginTop: Spacing.xl,
              }}
              testID='locationName'
              value={locationNameValue}
            />
          </>
        ) : (
          <PlacesAutocomplete
            onPlaceSelected={handleSelectLocation}
            placeholder={t('messaging:placeholderAddress')}
          />
        )}
      </View>
      {selectedLocation && Platform.OS === 'web' && (
        <View style={styles.buttonContainer}>
          <KitButton
            secondary
            icon='minus-circle'
            iconSize={14}
            title={t('messaging:removeLocationButtonLabel')}
            //TODO
            // onPress={handleRemoveLocation}
            style={styles.leftButton}
          />
          <View style={styles.rightButtonsContainer}>
            <KitButton
              secondary
              title={t('messaging:buttonTitleCancel')}
              onPress={() => setIsOpenLocationModal(false)}
              style={styles.rightButton}
            />
            <KitButton
              title={t('common:buttonTitleSave')}
              color={Colors.N900}
              //TODO
              //onPress={handleSave}
              style={styles.rightButton}
            />
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  map: {
    width: '100%',
    height: '30%',
    borderRadius: 9,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: Spacing.xl,
    marginHorizontal: Spacing.xl,
  },
  leftButton: {
    marginRight: Spacing.s,
  },
  rightButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rightButton: {
    marginLeft: Spacing.s,
  },
});
