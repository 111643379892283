import HealthMonitor from '@omni/kit/HealthMonitor';
import { getStoredItem } from '@omni/kit/storage';

import { RefreshToken } from './RefreshToken';

export enum ITokenStatus {
  Expired,
  Missing,
  Valid,
}

export interface ITokenData {
  access_token: string;
  access_token_expires_at: string;
  refresh_token: string;
  token_status: ITokenStatus;
}

/*
 * Do not use this directly in a component, instead use token from KioskContext
 */
const GetToken = async (): Promise<ITokenData> => {
  const cache = await getCachedTokenData();

  const cacheStatus = getTokenStatus(
    cache?.access_token,
    cache?.access_token_expires_at
  );

  switch (cacheStatus) {
    case ITokenStatus.Valid:
      return {
        access_token: cache?.access_token as string,
        access_token_expires_at: cache?.access_token_expires_at as string,
        refresh_token: cache?.refresh_token as string,
        token_status: cacheStatus,
      };
    case ITokenStatus.Expired:
      const refreshedData = await RefreshToken(cache);
      const accessToken = refreshedData?.access_token ?? '';
      const accessTokenExpiresAt = refreshedData?.access_token_expires_at ?? '';
      const refreshToken = refreshedData?.refresh_token ?? '';

      const refreshStatus = getTokenStatus(accessToken, accessTokenExpiresAt);

      return {
        access_token: accessToken,
        access_token_expires_at: accessTokenExpiresAt,
        refresh_token: refreshToken,
        token_status: refreshStatus,
      };
    case ITokenStatus.Missing: {
      throw new Error('Missing access token.');
    }
  }
};

export const getTokenStatus = (
  accessToken?: string,
  expiresAt?: string
): ITokenStatus => {
  if (!accessToken) {
    return ITokenStatus.Missing;
  }

  const now = Date.now();
  const expiresAtMsec = expiresAt ? new Date(expiresAt).getTime() : now;

  // A kiosk token expires in 12 hours, but may become expired by the time
  // a network request is made if the token was nearly expired.
  const thirtyMinutesInMsec = 30 * 60 * 1000;
  const adjustedExpirationTime = expiresAtMsec - thirtyMinutesInMsec;

  return now < adjustedExpirationTime
    ? ITokenStatus.Valid
    : ITokenStatus.Expired;
};

export const getCachedTokenData = async (): Promise<
  | {
      app_key?: string;
      org_key?: string;
      device_id: string;
      access_token: string;
      access_token_expires_at: string;
      refresh_token: string;
    }
  | undefined
> => {
  const device_id = (await getStoredItem('check_in_device_id')) ?? undefined;
  const app_key = (await getStoredItem('check_in_app_key')) ?? undefined;
  const org_key = (await getStoredItem('check_in_org_key')) ?? undefined;
  const access_token = await getStoredItem('check_in_access_token');
  const access_token_expires_at =
    (await getStoredItem('check_in_access_token_expires_at')) ?? undefined;
  const refresh_token =
    (await getStoredItem('check_in_refresh_token')) ?? undefined;

  if (
    !device_id ||
    (!app_key && !org_key) ||
    !access_token ||
    !access_token_expires_at ||
    !refresh_token
  ) {
    return;
  }

  if (app_key) {
    HealthMonitor.setTag('sap-active-appkey', app_key);
  }

  if (org_key) {
    HealthMonitor.setTag('sap-active-orgkey', org_key);
  }

  return {
    app_key,
    org_key,
    device_id,
    access_token,
    access_token_expires_at,
    refresh_token,
  };
};

export default GetToken;
