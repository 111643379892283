import Environment from '../../../Environment';
import { IProfile } from '../Types';
import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.peopleService;
const RESOURCE = 'profile-query';

interface IProfileFilter {
  field: string;
  value: string;
  operator?: '<' | '>' | '=';
}
interface IPostProfileQuery extends IUri {
  body: { filters: IProfileFilter[] };
  token?: string;
}

interface IProfilesResponse {
  count: number;
  _embedded: {
    profiles: IProfile[];
  };
}

interface IPostProfileQueryResponse extends IHttpResponse {
  body?: IProfilesResponse;
}

export default async function ({
  body,
  token,
  ...buildUrlProps
}: IPostProfileQuery): Promise<IPostProfileQueryResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    pluralizeResource: false,
    ...buildUrlProps,
  });

  return BaseServiceV2.Post({
    url,
    token,
    data: body,
  });
}
