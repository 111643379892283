import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FlatList, Platform, ScrollView, StyleSheet, View } from 'react-native';

import BorderRadius from '../../theming/BorderRadius';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { KitInput, KitText, KitTouchable } from '../index';
import { CATEGORIES, STORAGE_KEY } from './contants';
import { formattedEmojiList } from './data';
import {
  CategoriesList,
  CategoriesListProps,
  CategoriesProps,
  CategoryRowProps,
  IEmoji,
  IKitEmojiPickerProps,
  IconProps,
  RowInfo,
  ScrollablePlaceholderProps,
  SearchResultsProps,
} from './types';

const debug = require('debug')('tca:KitEmojiPicker.tsx');

const ICON_PADDING = Spacing.s;
const CAT_HEADER_H = Spacing.xxl;

// We render this once to determine the renderable area in a cross-platform way (minus scrollbars, popup margin etc.)
const ScrollablePlaceholder = (props: ScrollablePlaceholderProps) => (
  <ScrollView>
    <View
      style={{ minHeight: Platform.select({ web: 10000 }) }}
      onLayout={(x) => props.onWidth(x.nativeEvent.layout.width)}
    />
  </ScrollView>
);

export default ({
  onEmojiSelected,
  columns = 7,
  searchPlaceholder = 'Search...',
}: IKitEmojiPickerProps): JSX.Element => {
  const scrollable = useRef<CategoriesList>(null);

  const [history, setHistory] = useState<IEmoji[]>();
  const [containerW, setContainerW] = useState(0);
  const [_query, setQuery] = useState('');

  const query = _query.trim();
  const cellSize = containerW / columns;
  const isReady = containerW && history;

  const [active, setActive] = useState(0);
  const catRowZero = useRef<number[]>([]);

  const scrollToCat = (index: number) =>
    scrollable.current?.scrollToIndex({ index: catRowZero.current[index] });

  const rowToCat = (ri: number) => {
    const i = catRowZero.current.findIndex((cr) => cr > ri);

    return (i >= 0 ? i : catRowZero.current.length) - 1;
  };

  const onActiveChange = useCallback(
    ({ viewableItems: v }: { viewableItems: { index: number }[] }) =>
      v[0] && setActive(rowToCat(v[0].index)),
    []
  );

  const loadRecentEmojis = async () => {
    try {
      const recentEmojiList = await AsyncStorage.getItem(STORAGE_KEY);
      setHistory(JSON.parse(recentEmojiList || '[]'));
    } catch (e) {
      setHistory([]);
      debug(e);
    }
  };

  const addToRecentEmojiList = async (emoji: IEmoji) => {
    try {
      const cachedEmojiList = await AsyncStorage.getItem(STORAGE_KEY);
      const currentEmojiList = JSON.parse(cachedEmojiList as string) || [];

      const recent = currentEmojiList.find(
        (e: IEmoji) => e.unified === emoji.unified
      )
        ? currentEmojiList
        : [emoji, ...currentEmojiList];

      await AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(recent));
      setHistory(recent);
    } catch (e) {
      debug(e);
    }
  };

  const onEmojiSelect = useCallback(async (emoji: IEmoji) => {
    onEmojiSelected?.({ emoji: emoji.emoji });
    await addToRecentEmojiList(emoji);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadRecentEmojis();
  }, []);

  const rows = useMemo(
    () =>
      CATEGORIES.reduce(
        (acc, { name, slug }, i) => {
          catRowZero.current[i] = acc.info.length;
          const catEmojis =
            (slug === 'history' ? history : formattedEmojiList[name]) || [];
          const catRows = Math.ceil(catEmojis.length / columns);

          acc.info.push({
            content: name,
            top: acc.top,
            height: CAT_HEADER_H,
          });
          acc.top += CAT_HEADER_H;

          for (let i = 0; i < catRows; i++) {
            const catOffset = i * columns;
            const content = catEmojis.slice(catOffset, catOffset + columns);

            acc.info.push({ content, top: acc.top, height: cellSize });
            acc.top += cellSize;
          }

          return acc;
        },
        { top: 0, info: [] } as { top: number; info: RowInfo[] }
      ),
    [cellSize, columns, history]
  );

  return (
    <View style={styles.container}>
      <View style={styles.tabs}>
        {CATEGORIES.map((c, i) => (
          <Icon
            cellSize={containerW / CATEGORIES.length}
            key={i}
            style={[styles.tab, active === i && styles.tabActive]}
            onPress={() => scrollToCat(i)}
          >
            {c.symbol}
          </Icon>
        ))}
      </View>
      <View style={styles.searchInput}>
        <KitInput
          testID='Query'
          placeholder={searchPlaceholder}
          onChangeText={setQuery}
          value={_query}
        />
      </View>
      {isReady ? (
        query ? (
          <SearchResults
            cellSize={cellSize}
            query={query}
            onEmojiSelect={onEmojiSelect}
          />
        ) : (
          <Categories
            ref={scrollable}
            cellSize={cellSize}
            onViewableItemsChanged={onActiveChange}
            onEmojiSelect={onEmojiSelect}
            rowInfo={rows.info}
          />
        )
      ) : (
        <ScrollablePlaceholder onWidth={setContainerW} />
      )}
    </View>
  );
};

const flatEmojiList = Object.values(formattedEmojiList).flat(); // Enables fast traversals for SearchResults rendering

const SearchResults = memo((props: SearchResultsProps) => {
  const { onEmojiSelect, cellSize, query } = props;

  const emojis = flatEmojiList.filter((e) =>
    e.shortName.includes(query.toLowerCase())
  );

  return (
    <View style={styles.searchResults}>
      <KitText style={styles.categoryHeader}>Search Results</KitText>
      <ScrollView contentContainerStyle={styles.icons}>
        {emojis.map((e) => (
          <Icon
            key={e.unified}
            cellSize={cellSize}
            onPress={() => onEmojiSelect(e)}
          >
            {e.emoji}
          </Icon>
        ))}
      </ScrollView>
    </View>
  );
});

const Categories = memo(
  forwardRef<FlatList<RowInfo>, CategoriesProps>((props, ref) => {
    const { cellSize, onViewableItemsChanged, rowInfo } = props;

    const getItemLayout: CategoriesListProps['getItemLayout'] = (_, index) => {
      const { top, height } = rowInfo[index];

      return { index, offset: top, length: height };
    };

    const renderItem: CategoriesListProps['renderItem'] = ({ item }) => (
      <CategoryRow
        item={item}
        cellSize={cellSize}
        onEmojiSelect={props.onEmojiSelect}
      />
    );

    const viewabilityConfig = { itemVisiblePercentThreshold: 50 };

    return (
      <FlatList
        ref={ref}
        data={rowInfo}
        keyExtractor={(c) => `${c.top}`}
        initialNumToRender={15}
        maxToRenderPerBatch={15}
        getItemLayout={getItemLayout}
        onViewableItemsChanged={onViewableItemsChanged}
        viewabilityConfig={viewabilityConfig}
        renderItem={renderItem}
      />
    );
  })
);

const Icon: React.FC<IconProps> = (props) => {
  const { cellSize, onPress, style, ...rest } = props;

  return (
    <KitTouchable onPress={onPress} style={style} borderRadius={0}>
      <View style={[styles.icon, { width: cellSize, height: cellSize }]}>
        <KitText
          {...rest}
          style={styles.iconText}
          fontSize={cellSize - 3 * ICON_PADDING}
        />
      </View>
    </KitTouchable>
  );
};

const CategoryRow = memo((props: CategoryRowProps) => {
  const { cellSize, item, onEmojiSelect } = props;

  return typeof item.content === 'string' ? (
    <KitText style={styles.categoryHeader}>{item.content}</KitText>
  ) : (
    <View style={styles.row}>
      {item.content.map((e) => (
        <Icon
          onPress={() => onEmojiSelect(e)}
          cellSize={cellSize}
          key={e.unified}
        >
          {e.emoji}
        </Icon>
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.N0,
    borderRadius: BorderRadius.l,
    paddingBottom: 0,
  },
  tabs: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: Colors.N100,
  },
  tab: {
    flexGrow: 1,
    borderBottomWidth: Spacing.xs,
    borderBottomColor: Colors.N0,
  },
  tabActive: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.N900,
  },
  categoryHeader: {
    display: 'flex',
    fontSize: 14,
    alignItems: 'center',
    paddingHorizontal: ICON_PADDING,
    height: CAT_HEADER_H,
  },
  icons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconText: { lineHeight: undefined },
  searchInput: {
    paddingVertical: Spacing.m,
  },
  searchResults: { flex: 2 },
  row: { display: 'flex', flexDirection: 'row' },
});
