import { FetchMediaPlayByUserAndMediaItem } from '@omni/kit/components/KitTrackMediaProgress';
import { IMediaPlays } from '@omni/kit/services/MediaPersonalizationService/MediaPlayService';

const debug = require('debug')(
  'tca:blocks:MediaBannerBlock:GetLatestMediaPlay'
);

/**
 * Get the latest media play either by
 * - patching media progress to media play resource if the media item is currently playing in the background
 * - fetching media play resource
 *
 * @param userId {string}
 * @param mediaItemId {string}
 * @param appKey {string}
 *
 * @returns IMediaPlays | null
 */
export default async function getLatestMediaPlay({
  userId,
  mediaItemId,
  appKey,
  signal,
}: {
  userId: string;
  mediaItemId: string;
  appKey: string;
  signal?: AbortSignal;
}): Promise<IMediaPlays | null> {
  /** Fetch media play resource */
  try {
    debug('Fetch media play resource');

    return FetchMediaPlayByUserAndMediaItem({
      appKey,
      userId,
      mediaItemId,
      signal,
    });
  } catch (e) {
    debug('failed to retrieve progress', e);

    return null;
  }
}
