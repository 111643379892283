import { IMediaItemData } from '@omni/kit/feeds/mediaItemTypes';

/**
 * Format media item subtitle in the following format:
 * Genesis 1:5-17, 2:1-10, 3-7 • Sept 1, 2022  • Pastor Moses
 */
export const buildMediaItemSubtitle = (
  mediaItem: IMediaItemData,
  osis: string
): IMediaItemData => {
  const scriptureList = mediaItem.scriptures?.split(', ');

  const parsedScriptures = scriptureList
    ?.filter((scripture) => scripture.includes(osis))
    ?.map((scripture) => scripture.split(' ')[1]);

  const scriptures =
    parsedScriptures?.length === 0
      ? null
      : `${osis?.split(' ')[0]} ${parsedScriptures?.join(', ')}`;

  return {
    ...mediaItem,
    subtitle: [scriptures, mediaItem.subtitle]
      .filter((exists) => Boolean(exists))
      .join(' • '),
  };
};

export const buildMediaItemSubtitles = (
  mediaItems: IMediaItemData[],
  osis: string
): IMediaItemData[] => {
  return mediaItems.map((item) => {
    return buildMediaItemSubtitle(item, osis);
  });
};
