import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IProfile } from '../Types';

const HOST = Environment.peopleService;
const RESOURCE = 'profiles';

interface ICreateProfile extends Omit<IUri, 'resource'> {
  body: IProfile;
  token: string;
}

interface ICreateProfileResponse extends IHttpResponse {
  body?: IProfile;
}

export default ({
  body,
  token,
}: ICreateProfile): Promise<ICreateProfileResponse> => {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
  });

  return BaseServiceV2.Post({ url, token, data: body });
};
