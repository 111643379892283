import moment from 'moment';

import { IMediaPlaybackStateInfo } from '../KitPlayer/playbackTypes';
import ResetPatchTimer from './ResetPatchTimer';
import TrackMediaProgressState from './TrackMediaProgressState';
import UpdateMediaPlay from './UpdateMediaPlay';

const debug = require('debug')('tca:packages:TrackMediaProgress:HandleTimer');

/**
 * Patch progress to server if
 * - timer has not started
 * - every 5 minutes
 */
export default async (
  mediaPlaybackStateInfo: IMediaPlaybackStateInfo
): Promise<void> => {
  const {
    appKey = '',
    durationMsec,
    positionMsec = 0,
  } = mediaPlaybackStateInfo;

  /** Get current time */
  const now = moment.utc();

  if (
    !TrackMediaProgressState.saveProgressToNetworkTimer ||
    now.diff(TrackMediaProgressState.saveProgressToNetworkTimer, 'minutes') >= 5
  ) {
    /** Reset timer right away to avoid repeatedly updating media play resource */
    ResetPatchTimer();

    debug('Past 5 minutes timer');

    if (
      TrackMediaProgressState.currentMediaItem &&
      TrackMediaProgressState.mediaPlay &&
      TrackMediaProgressState.userProfile
    ) {
      await UpdateMediaPlay({
        appKey,
        duration: durationMsec,
        mediaItemId: TrackMediaProgressState.currentMediaItem.id,
        mediaPlayId: TrackMediaProgressState.mediaPlay.id,
        position: positionMsec,
        userId: TrackMediaProgressState.userProfile.userID,
      });
    }
  }
};
