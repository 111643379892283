import getRouteRootPath from '@omni/kit/utilities/getRouteRootPath.web';

const Routes = {
  appKey: `${getRouteRootPath()}:appKey`,
  channels: (appKey = ':appKey'): string =>
    `${getRouteRootPath()}${appKey}/channels`,
  groups: (appKey = ':appKey'): string =>
    `${getRouteRootPath()}${appKey}/groups`,
  discover: (appKey = ':appKey'): string =>
    `${getRouteRootPath()}${appKey}/channels/discover`,
  joinRequests: (appKey = ':appKey'): string =>
    `${getRouteRootPath()}${appKey}/channels/join-requests`,
  channelScreen: (appKey = ':appKey', channelId = ':channelId'): string =>
    `${getRouteRootPath()}${appKey}/channels/${channelId}`,
  prayerScreen: (appKey = ':appKey', channelId = ':channelId'): string =>
    `${getRouteRootPath()}${appKey}/channels/${channelId}/prayers`,
  threadScreen: (
    appKey = ':appKey',
    channelId = ':channelId',
    parentMessageId = ':parentMessageId'
  ): string =>
    `${getRouteRootPath()}${appKey}/channels/${channelId}/threads/${parentMessageId}`,
  acceptInvite: (
    appKey = ':appKey',
    invitePayload = ':invitePayload'
  ): string => `${getRouteRootPath()}${appKey}/accept/${invitePayload}`,
  login: (
    appKey = ':appKey',
    accessToken = undefined,
    tokenType = undefined
  ): string =>
    `${getRouteRootPath()}${appKey}/auth${
      accessToken ? `?access_token=${accessToken}` : ''
    }${tokenType ? `&token_type=${tokenType}` : ''}`,
  catchall: '*',
};

export default Routes;
