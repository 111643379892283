import { KitLoader } from '@omni/kit/components';
// @ts-ignore
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { useHistory, useParams } from 'react-router-dom';

import Routes from '../../web/navigation/Routes';

interface AcceptInvitationScreenProps {
  isAuthenticated: boolean;
  isAuthReady: boolean;
}

export default function AcceptInvitationScreen({
  isAuthenticated,
  isAuthReady,
}: AcceptInvitationScreenProps): JSX.Element {
  const history = useHistory();
  const params = useParams();

  /**
   * The invite is automatically accepted when an invite is sent
   * to an existing chat-user or when an invite is sent to a new user
   * and the user signs up with the same email address as the invite.
   *
   * So at this point, we just need to prompt the user to login
   * if they are not already logged in.
   */
  const processInvite = useCallback(() => {
    if (isAuthReady && !isAuthenticated) {
      // @ts-ignore
      history.push(Routes.login(params.appKey));
    } else if (isAuthReady && isAuthenticated) {
      // @ts-ignore
      history.push(Routes.channels(params.appKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthReady, isAuthenticated]);

  useEffect(() => {
    processInvite();
  }, [processInvite]);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <KitLoader />
    </View>
  );
}
