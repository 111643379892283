import { KitText, KitTouchableIcon } from '@omni/kit/components';
import KitIcon from '@omni/kit/components/KitIcon';
import KitLoader from '@omni/kit/components/KitLoader';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { differenceBy } from 'lodash';
import React, { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  PermissionsAndroid,
  Platform,
  Pressable,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import Contacts, { Contact } from 'react-native-contacts';
import { useDispatch, useSelector } from 'react-redux';

// TODO - NavNextButton is only used in InviteScreen. consider either abstracting or specifying it's for this screen
import NavNextButton from '../../../mobile/components/NavNextButton';
import PlatformKeyboardSpacer from '../../../mobile/components/PlatformKeyboardSpacer';
import UserListRow from '../../../mobile/components/UserListRow';
import KitChipInput from '../../../mobile/components/channelDetails/KitChipInput';
import {
  getBlockedUserList,
  inviteMemberList,
} from '../../redux/actions/ChatActions';
import { getUserList } from '../../redux/actions/SystemActions';
import {
  blockedUserListSelector,
  channelSelector,
  chatIsLoadingSelector,
  userListSelector,
} from '../../redux/selectors';
import { IInvite, InviteType } from '../../redux/types';
import { ValidInviteListRow } from './components/invite';

const debug = require('debug')('tca:chat:screen:InviteScreen');

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  navigation: any;
  inviteMemberList: (invites: IInvite[], channel: GroupChannel) => void;
}

interface IPhoneNumber {
  label: string;
  number: string;
}

interface IEmail {
  label: string;
  email: string;
}

interface IContact {
  givenName: string;
  familyName: string;
  phoneNumbers: IPhoneNumber[];
  emailAddresses: IEmail[];
}

//******************************************************************************
// Component
//******************************************************************************

// @ts-ignore
function InviteScreen({
  navigation,
  route,
  openInviteScreenModal,
  setOpenInviteScreenModal,
}: {
  navigation: any;
  route: any;
  openInviteScreenModal?: boolean;
  setOpenInviteScreenModal?: (open: boolean) => void;
}): JSX.Element | null {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState('');
  const [inputType, setInputType] = useState<InviteType>(InviteType.None);
  const [contactsList, setContactsList] = useState<Contact[]>([]);
  const [filteredList, setFilteredList] = useState<IInvite[]>([]);
  const [pendingInvites, setPendingInvites] = useState<IInvite[]>([]);

  const channel = useSelector(channelSelector);
  const userList = useSelector(userListSelector);
  const blockedUserList = useSelector(blockedUserListSelector);
  const isLoading = useSelector(chatIsLoadingSelector);
  const [showAddRightButton, setShowAddRightButton] = useState<boolean>(false);

  const dispatch = useDispatch();

  const inputField = React.useRef<TextInput>();

  //****************************************************************************
  // Methods
  //****************************************************************************

  const _requestContactsPermission = async () => {
    if (Platform.OS === 'ios') {
      _getContacts();
    } else {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
          {
            title: 'Access Contacts',
            message:
              'We need to access your phones contacts in order to invite users.',
            buttonNeutral: 'Ask Me Later',
            buttonNegative: 'Cancel',
            buttonPositive: 'OK',
          }
        );

        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          debug('Contacts permission granted');
          _getContacts();
        } else {
          debug('Contacts permission denied');
        }
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const _getContacts = () => {
    Contacts.getAll().then((contacts: Contact[]) => {
      setContactsList(contacts);
    });
  };

  // @ts-ignore
  const _renderList = ({ item }) => {
    return (
      <UserListRow invite={item} onPress={() => _onListItemPressed(item)} />
    );
  };

  const _addPendingInvite = (item: IInvite) => {
    const updatedInvites: IInvite[] = pendingInvites;
    updatedInvites.push(item);
    setInputText('');
    setInputType(item.type);
    setPendingInvites(updatedInvites);
    setFilteredList([]);

    if (!openInviteScreenModal) {
      navigation.setOptions({
        headerRight: () => <NavNextButton onPress={_onNextButtonPress} />,
      });
    } else {
      setShowAddRightButton(true);
    }

    inputField.current?.focus();
  };

  const _removePendingInvite = (index: number) => {
    const updatedInvites = pendingInvites;
    updatedInvites.splice(index, 1);
    if (updatedInvites.length < 1) {
      navigation.setOptions({ headerRight: () => null });
    }

    setInputText('');
    setInputType(InviteType.None);
    setPendingInvites(updatedInvites);
    setFilteredList([]);

    inputField.current?.focus();
  };

  const _resetPendingInvites = () => {
    const updatedInvites: IInvite[] = [];

    navigation.setOptions({ headerRight: () => null });
    setInputText('');
    setInputType(InviteType.None);
    setPendingInvites(updatedInvites);
    setFilteredList([]);

    inputField.current?.focus();
  };

  const _onListItemPressed = (invite: IInvite) => {
    _addPendingInvite(invite);
    setInputType(InviteType.None);
  };

  const _onNextButtonPress = () => {
    const { channel } = route.params;
    dispatch(inviteMemberList(pendingInvites, channel));
    _resetPendingInvites();
    if (openInviteScreenModal && setOpenInviteScreenModal) {
      setOpenInviteScreenModal(false);
    } else {
      navigation.goBack(null);
    }
  };

  const _onInviteButtonPress = () => {
    const invite: IInvite = {
      type: inputType,
      data: inputText,
      title: inputText,
    };
    _addPendingInvite(invite);
    setInputType(InviteType.None);
  };

  const _renderInviteList = () => {
    if (inputText || pendingInvites.length > 0) {
      return (
        <FlatList<IInvite>
          data={filteredList}
          renderItem={_renderList}
          inverted={false}
          keyExtractor={(item, index) => item.title + Math.random()}
          scrollEnabled={true}
          keyboardShouldPersistTaps='always'
          keyboardDismissMode='on-drag'
          contentContainerStyle={{ paddingBottom: 30 }}
        />
      );
    } else {
      return (
        <View style={styles.emptyContainer}>
          <KitIcon name='person' color={Colors.N500} size={44} />
          <View>
            <KitText fontSize={26} black bold center style={styles.headerText}>
              Invite people to join the conversation and build community
            </KitText>
            <KitText fontSize={16} center>
              Keep in mind new participants will be able to see the entire chat
              history.
            </KitText>
          </View>
        </View>
      );
    }
  };

  const _onInputFieldChanged = (fieldText: string) => {
    if (fieldText !== '') {
      // Filter list each time a new character is typed
      const filteredCombinedList: IInvite[] = [];

      const filteredUserList = differenceBy(userList, blockedUserList, 'userId')
        .filter((item) => {
          const filter = fieldText.toUpperCase();

          return item.nickname.toUpperCase().includes(filter);
        })
        .slice(0, 40); // Limit user suggestions to to keep the list from being too long

      filteredUserList.forEach((user) => {
        if (
          !pendingInvites.find(
            (member) => member.data.userId === user.userId
          ) &&
          !channel?.members.find((member) => member.userId === user.userId)
        ) {
          filteredCombinedList.push({
            type: InviteType.User,
            data: user,
            title: user.nickname,
          });
        }
      });

      const filteredContactList = contactsList
        .filter((item: IContact) => {
          const filter = fieldText.toUpperCase();
          const combinedName = `${item.givenName} ${item.familyName}`;

          return combinedName.toUpperCase().includes(filter);
        })
        .slice(0, 20); // Limit user suggestions to to keep the list from being too long

      filteredContactList.forEach((contact: IContact) => {
        if (contact.phoneNumbers.length > 0) {
          filteredCombinedList.push({
            type: InviteType.Phone,
            data: contact.phoneNumbers[0].number,
            title: `${contact.givenName} ${contact.familyName}`,
          });
        } else if (contact.emailAddresses.length > 0) {
          filteredCombinedList.push({
            type: InviteType.Email,
            data: contact.emailAddresses[0].email,
            title: `${contact.givenName} ${contact.familyName}`,
          });
        }
      });
      setFilteredList(filteredCombinedList);
    } else {
      setFilteredList([]);
    }

    if (_testEmail(fieldText)) {
      debug('Valid email');
      setInputType(InviteType.Email);
    } else if (_testPhone(fieldText)) {
      debug('Valid phone');
      setInputType(InviteType.Phone);
    } else {
      setInputType(InviteType.None);
    }

    setInputText(fieldText);
  };

  const _onBackspace = () => {
    if (inputText.length < 1) {
      _removePendingInvite(pendingInvites.length - 1);
    }
  };

  const _testEmail = (text: string) => {
    const reg = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
    );

    if (reg.test(text.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const _testPhone = (text: string) => {
    const reg =
      /^([\+]?[\d]{1,2}){0,3}[\s]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    if (reg.test(text)) {
      return true;
    } else {
      return false;
    }
  };

  const _onDeleteChipPress = (invite: IInvite) => {
    const index = pendingInvites.indexOf(invite as never);
    _removePendingInvite(index);
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  useEffect(() => {
    debug('componentDidMount()');
    _requestContactsPermission();
    dispatch(getUserList());
    dispatch(getBlockedUserList());
    inputField.current?.focus();
  }, []);

  const spinner = isLoading ? (
    <View style={styles.loadingStyle}>
      <KitLoader />
    </View>
  ) : null;

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colorForScheme({ default: Colors.N0 }),
      }}
    >
      {/* Open this header only if it is Platform.OS==='web' */}
      {openInviteScreenModal && (
        <View style={styles.headerContainer}>
          <View style={styles.leftContainer}>
            <Pressable
              onPress={() => {
                if (setOpenInviteScreenModal) {
                  setOpenInviteScreenModal(false);
                }
              }}
            >
              <KitIcon size={20} name='bar-back-android' color={Colors.N900} />
            </Pressable>
            <KitText color={Colors.N900} style={styles.title}>
              {t('messaging:addPeopleButton')}
            </KitText>
          </View>
          {showAddRightButton && <NavNextButton onPress={_onNextButtonPress} />}
        </View>
      )}
      <View
        style={{
          marginTop: 18,
          marginBottom: 18,
          paddingHorizontal: Platform.OS === 'web' ? 0 : 18,
        }}
      >
        <KitChipInput
          autoCapitalize='none'
          autoCorrect={false}
          editable={true}
          inputRef={inputField as RefObject<TextInput>}
          placeholder='Type a name, phone number, or email'
          chips={pendingInvites}
          value={inputText}
          onChangeText={_onInputFieldChanged}
          onBackspace={_onBackspace}
          onDeleteChipPress={_onDeleteChipPress}
        />
      </View>
      {(inputType === InviteType.Phone || inputType === InviteType.Email) && (
        <ValidInviteListRow
          text={inputText}
          type={inputType}
          onPress={_onInviteButtonPress}
        />
      )}
      {_renderInviteList()}
      <PlatformKeyboardSpacer />
      {spinner}
    </View>
  );
}

// @ts-ignore
InviteScreen.navigationOptions = ({ navigation }) => {
  return {
    title: 'Add people',
    headerRight: navigation.state.params.headerRight,
  };
};

export default InviteScreen;

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  labelText: {
    color: colorForScheme({ default: Colors.N500 }),
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 18,
    marginBottom: Spacing.m,
  },
  loadingStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: Spacing.l,
    left: Spacing.l,
    right: Spacing.l,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: Spacing.xl,
    marginBottom: Spacing.xl,
    marginTop: 0,
  },
  headerText: {
    marginTop: Spacing.m,
    marginBottom: Spacing.m,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    marginTop: 0,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: Spacing.xl,
  },
});
