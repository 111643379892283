import { IRootProps } from '@omni/kit/contexts/types';
import { NativePropContext } from '.';
import React from 'react';

const debug = require('debug')('tca:search:components:NativePropWrapper.tsx');

export default ({
  props,
  children,
}: {
  props: IRootProps;
  children: JSX.Element | JSX.Element[] | undefined;
}): JSX.Element => {
  debug('context updated', props);

  return (
    <NativePropContext.Provider value={props}>
      {children}
    </NativePropContext.Provider>
  );
};
