import Environment from '../../../Environment';
import { ISession } from '../Types';
import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.eventsService;
const RESOURCE = 'sessions';

interface IGetSession extends IUri {
  token?: string;
  filters?: IFilter[];
  id?: string;
}

interface IGetSessionResponse extends IHttpResponse {
  body?: ISession;
}

export default async function ({
  token,
  ...buildUrlProps
}: IGetSession): Promise<IGetSessionResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
  });
}
