import { KitText } from '@omni/kit/components';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import PushNotificationIOS from '@react-native-community/push-notification-ios';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { ParamListBase } from '@react-navigation/routers';
import { StackNavigationProp } from '@react-navigation/stack';
import { ReportCategory } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import {
  FileMessage,
  PreviousMessageListQuery,
  Sender,
  UserMessage,
} from '@sendbird/chat/message';
import moment from 'moment';
import React, {
  LegacyRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  FlatList,
  Keyboard,
  LayoutAnimation,
  NativeEventSubscription,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  AppState as ReactAppState,
  StyleSheet,
  View,
} from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';

import * as Constants from '../../../shared/Constants';
import {
  blockUser,
  createChatHandler,
  endTyping,
  getBlockedUserList,
  getMemberList,
  getPrevMessageList,
  getThreadedMessageList,
  sendTextMessage,
  setChannelName,
  setParentMessageId,
  setSendToChannel,
  startTyping,
  updateMessageList,
  updateTextMessage,
} from '../../../shared/redux/actions/ChatActions';
import { setDeletedChannel } from '../../../shared/redux/actions/SystemActions';
import {
  blockedUserListSelector,
  channelNameSelector,
  channelSelector,
  currentMessagesSelector,
  deletedChannelSelector,
  sendToChannelSelector,
  userSelector,
  userTypingSelector,
} from '../../../shared/redux/selectors';
import { ChannelType, MessageType } from '../../../shared/redux/types';
import Message from '../../../shared/scenes/channel/components/Message';
import {
  formatDirectChannelName,
  getShortChannelUrl,
} from '../../../utilities/chatUtilities';
import {
  sbAdjustMessageList,
  sbCreatePreviousMessageListQuery,
  sbMarkAsRead,
  updateUnread,
} from '../../../utilities/sendbird/chatFunctions';
import {
  sbHasUser,
  sbIsConnected,
} from '../../../utilities/sendbird/userFunctions';
import {
  CustomMessageAttributes,
  CustomMessageData,
  SendbirdMessage,
  SendbirdSenderMessage,
  SendbirdUserMessage,
} from '../../Types';
import FadeInView from '../../components/FadeInView';
import PlatformKeyboardSpacer from '../../components/PlatformKeyboardSpacer';
import DaySeparator from '../../components/chat/DaySeparator';
import MessageActionSheet from '../../components/chat/MessageActionSheet';
import MessageInput from '../../components/chat/MessageInput';
import ReplySeparator from '../../components/chat/ReplySeparator';
import ScrollButtons from '../../components/chat/ScrollButtons';
import OpenInAppModal from '../../screens/OpenInAppModal';
import BlockUserModal from './components/BlockUserModal';
import ConfirmDeleteModalContent from './components/ConfirmDeleteModalContent';
import ConfirmReportMessageContent from './components/ConfirmReportMessageContent';
import ReportUserModal from './components/ReportUserModal';
import UserActionSheet from './components/UserActionSheet';

const debug = require('debug')('tca:chat:screen:ChatScreen');

//******************************************************************************
// Types
//******************************************************************************

export interface Props {
  channelName: string;
  createChatHandler: (channelUrl: string, parentMessageId?: number) => void;
  deletedChannel: string;
  endTyping: (channel: GroupChannel) => void;
  exit: boolean;
  getPrevMessageList: (msgListQuery: PreviousMessageListQuery) => void;
  initChatScreen: () => void;
  messageList: Array<SendbirdMessage>;
  messages: SendbirdMessage[];
  navigation: StackNavigationProp<any, any>;
  onSendButtonPress: (
    channelUrl: string,
    isOpenChannel: boolean,
    textMessage: string
  ) => void;
  sendTextMessage: (channel: GroupChannel, messageText: string) => void;
  startTyping: (channel: GroupChannel) => void;
  title: string;
  updateMessageList: (messageList: GroupChannel[]) => void;
  updateTextMessage: (
    channel: GroupChannel,
    messageId: number,
    messageText: string
  ) => void;
  userTyping: string | null;
}

export interface RouteProps extends RouteProp<ParamListBase, string> {
  params?: {
    isThread?: boolean;
    parentMessageId?: number;
  };
}

interface ChatContextProps {
  isEditing: boolean;
  setIsEditing: (editing: boolean) => void;
  setActiveMessage: (message: SendbirdUserMessage | null) => void;
  selectedMessage: SendbirdUserMessage | null;
  setSelectedMessage: (message: SendbirdUserMessage | null) => void;
  setSelectedReaction: (reaction: string | null) => void;
  setTextMessage: (textMessage: string) => void;
  repliedMessage: SendbirdMessage | undefined;
  answeredPrayer: SendbirdMessage | undefined;
  setIsAnsweringPrayer: (isAnswering: boolean) => void;
  deleteMessage: () => void;
  reportMessage: () => void;
  handleSelectingMessage: () => void;
  handleSelectingReaction: (
    message: SendbirdUserMessage | null,
    reaction: string
  ) => void;
  handleSelectingUser: (user: Sender) => void;
}

export const ChatContext = React.createContext<Partial<ChatContextProps>>({});

//******************************************************************************
// Component
//******************************************************************************

function ChatScreen(): JSX.Element | null {
  // Navigation
  const navigation = useNavigation();
  const route = useRoute<RouteProps>();
  const parentMessageId = route.params?.parentMessageId as number;
  const isThread = parentMessageId !== undefined;

  const [textMessage, setTextMessage] = useState('');
  // "active" means the message is either being edited or replied to
  const [activeMessage, setActiveMessage] =
    useState<SendbirdUserMessage | null>(null);
  const [selectedMessage, setSelectedMessage] =
    useState<SendbirdUserMessage | null>(null);
  const [selectedReaction, setSelectedReaction] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<Sender | null>(null);
  const [userToReport, setUserToReport] = useState<Sender | null>(null);
  const [userToBlock, setUserToBlock] = useState<Sender | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [prevMessageListQuery, setPreviousMessageListQuery] =
    useState<PreviousMessageListQuery | null>(null);
  const [rawMessageData, setMessageData] = useState<SendbirdMessage[]>([]);
  const messageData = useMemo(
    () => (isThread ? rawMessageData.reverse() : rawMessageData),
    [rawMessageData, isThread]
  );
  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
  });
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);
  const [isAnsweringPrayer, setIsAnsweringPrayer] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [throttlingScroll, setThrottlingScroll] = useState(false);
  const [unreadCount, setUnreadCount] = useState<number | undefined>(0);
  const [lastReadMessageId, setLastReadMessageId] = useState<
    string | undefined
  >(undefined);
  const [showReportMessageSheet, setShowReportMessageSheet] = useState(false);

  const user = useSelector(userSelector);
  const blockedUserList = useSelector(blockedUserListSelector);
  const messages = useSelector(currentMessagesSelector);
  const userTyping = useSelector(userTypingSelector);
  const channel = useSelector(channelSelector);
  const channelName = useSelector(channelNameSelector);
  const channelId = getShortChannelUrl(channel?.url);
  const deletedChannel = useSelector(deletedChannelSelector);
  const sendToChannel = useSelector(sendToChannelSelector);

  const dispatch = useDispatch();
  const chatFlatList = useRef<FlatList<SendbirdMessage>>();
  const lastMessage = useRef<(UserMessage & CustomMessageAttributes) | null>(
    null
  );

  const isFocused = useIsFocused();
  const { sizeClass } = useSizeClass();

  //****************************************************************************
  // Methods
  //****************************************************************************

  const _handleAppStateChange = (nextAppState: string) => {
    if (!channel) {
      return;
    }

    if (!sbHasUser()) {
      debug('Ignoring app state change. Missing sb user.');

      return;
    }

    if (nextAppState === 'active') {
      debug('ChatScreen is in the foreground', channel.name);

      // We refresh the chat screen data when the app comes back from the background
      _initChatScreen();
      dispatch(createChatHandler(channel.url, parentMessageId));
      _getMessageList(true);
    } else if (nextAppState === 'background') {
      sbMarkAsRead(channel);
      debug('ChatScreen is in the background');
    }
  };

  const _cleanupDeliveredNotifications = () => {
    if (Platform.OS === 'ios') {
      PushNotificationIOS.removeAllDeliveredNotifications();
    }
  };

  const _initChatScreen = () => {
    if (!channel) {
      return;
    }

    channel.refresh();
  };

  const _renderItem = (rowData: any) => {
    const message: SendbirdMessage = rowData.item;

    const prevMessage = messages[rowData.index + 1];
    let showDay = false;

    if (
      prevMessage &&
      moment(prevMessage.createdAt).format('YYYY-MM-DD') !==
        moment(message.createdAt).format('YYYY-MM-DD') &&
      !isThread
    ) {
      showDay = true;
    }

    const showNewMessageIndicator =
      rowData.item.messageId === lastReadMessageId && !isThread;

    let repliedMessage;
    let answeredPrayer;
    let isThreadReplyInChannel;

    if (message.data) {
      try {
        const data = JSON.parse(message.data);
        repliedMessage = messageData.find(
          (msg) => msg.messageId === data?.replyId
        );
        answeredPrayer = messageData.find(
          (msg) => msg.messageId === data?.answeredId
        );
        isThreadReplyInChannel = data.sentToChannel && data.inChannel;
      } catch {}
    }

    // Don't show threaded replies in main channel AND don't show replies sent to channel in threads
    if (
      (message.parentMessageId && !isThread) ||
      (isThreadReplyInChannel && isThread)
    ) {
      return <View />;
    }

    return (
      <>
        <ChatContext.Provider
          value={{
            isEditing,
            setIsEditing,
            setActiveMessage,
            selectedMessage,
            setSelectedMessage,
            setSelectedReaction,
            setTextMessage,
            repliedMessage,
            answeredPrayer,
            setIsAnsweringPrayer,
            deleteMessage: _deleteSelectedMessage,
            handleSelectingMessage: () => _handleSelectingMessage(rowData.item),
            handleSelectingReaction: _handleSelectingReaction,
            handleSelectingUser: _handleSelectingUser,
            reportMessage: _onReportMessageWeb,
          }}
        >
          <Message
            message={message}
            onReport={_onReportPopup}
            onBlock={_onBlockPopup}
          />
        </ChatContext.Provider>
        {showNewMessageIndicator && !showDay && (
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              marginHorizontal: Spacing.m,
              marginVertical: Spacing.s,
            }}
          >
            <KitText fontSize={12} semiBold color={Colors.R500}>
              New
            </KitText>
            <View
              style={{
                backgroundColor: Colors.R500,
                height: 1.5,
                flex: 1,
                marginLeft: Spacing.m,
              }}
            />
          </View>
        )}
        {showDay && (
          <DaySeparator
            date={message.createdAt}
            newMessage={showNewMessageIndicator}
          />
        )}
        {isThread &&
          message?.threadInfo &&
          message?.threadInfo.replyCount > 0 &&
          messages.length > 1 && (
            <ReplySeparator replyCount={messages.length - 1} />
          )}
      </>
    );
  };

  const _getMessageList = (init = false) => {
    if (!prevMessageListQuery && !init) {
      return;
    }

    if (init && channel) {
      if (isThread) {
        dispatch(getThreadedMessageList(parentMessageId, channel));
      } else {
        const prevMessageQuery = sbCreatePreviousMessageListQuery(channel);

        if (prevMessageQuery) {
          setPreviousMessageListQuery(prevMessageQuery);
          dispatch(getPrevMessageList(prevMessageQuery));
        } else {
          debug('Error creating previous message list query');
          navigation.goBack();
        }
      }
    } else if (isFocused && channel) {
      dispatch(getPrevMessageList(prevMessageListQuery!));
    }
  };

  const _handleSelectingMessage = (message: SendbirdUserMessage | null) => {
    ReactNativeHapticFeedback.trigger('impactLight', {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
    setSelectedMessage(message);
  };

  const _handleSelectingReaction = (
    message: SendbirdUserMessage | null,
    reaction: string
  ) => {
    ReactNativeHapticFeedback.trigger('impactLight', {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
    setSelectedReaction(reaction);
    setSelectedMessage(message);
  };

  const _handleSelectingUser = (user: Sender) => {
    setSelectedUser(user);
  };

  // MESSAGE SEND ACTIONS

  const _onSendButtonPress = (mentions: string[]) => {
    if (textMessage) {
      dispatch(sendTextMessage(channel, textMessage, mentions));
      setTextMessage('');
    }

    scrollToEnd();
  };

  const scrollToEnd = () => {
    setTimeout(() => {
      const listRef = chatFlatList as MutableRefObject<
        FlatList<SendbirdMessage>
      >;

      if (isThread) {
        listRef.current.scrollToEnd({ animated: true });
      } else {
        listRef.current.scrollToOffset({ offset: 0, animated: true });
      }
    }, 0);
  };

  // LONG PRESS ACTIONS
  const _deleteSelectedMessage = () => {
    const isTextMessage = selectedMessage?.customType === MessageType.Text;
    const preview = isTextMessage
      ? `"${(selectedMessage as UserMessage).message}"`
      : '';
    const type =
      selectedMessage?.customType === 'prayer'
        ? 'prayer request'
        : selectedMessage?.customType;
    const title = isTextMessage ? 'Delete message' : `Delete ${type}`;

    if (sizeClass !== SizeClass.Small) {
      setConfirmDeleteModalVisible(true);
    } else {
      Alert.alert(title, preview, [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Delete',
          onPress: _confirmDeleteMessage,
          style: 'destructive',
        },
      ]);
    }
  };

  const _confirmDeleteMessage = () => {
    const msg = selectedMessage || activeMessage;

    if (msg) {
      channel
        ?.deleteMessage(msg!)
        .then(() => {
          setSelectedMessage(null);
        })
        .catch((error) => {
          debug('Could not delete message: ', error);
          setSelectedMessage(null);
          showKitSnack('Could not delete message.', KitSnackDuration.SHORT);
        });
    }

    setConfirmDeleteModalVisible(false);
  };

  const _confirmBlockUser = () => {
    if (userToBlock) {
      if (channel) {
        dispatch(blockUser(channel, userToBlock));
      }
      showKitSnack(`${userToBlock.nickname} blocked`, KitSnackDuration.SHORT);
      setUserToReport(null);
    }
  };

  const _confirmReportMessage = (
    category: ReportCategory,
    description: string
  ) => {
    const msg = selectedMessage || activeMessage;

    if (msg) {
      channel
        ?.reportMessage(msg!, category, description)
        .then(() => {
          setSelectedMessage(null);
          showKitSnack('Message reported', KitSnackDuration.SHORT);
        })
        .catch((error) => {
          debug('Could not report message: ', error);
          setSelectedMessage(null);
          showKitSnack(
            'Could not report message. Try again later',
            KitSnackDuration.SHORT
          );
        });
    }

    setShowReportMessageSheet(false);
  };

  const _confirmReportUser = (
    category: ReportCategory,
    description: string
  ) => {
    if (userToReport) {
      channel
        ?.reportUser(
          // @ts-ignore
          userToReport,
          category,
          description
        )
        .then(() => {
          showKitSnack(
            `${userToReport.nickname} reported`,
            KitSnackDuration.SHORT
          );

          setUserToReport(null);
        })
        .catch((error) => {
          debug('Could not report user: ', error);
          setUserToReport(null);
          showKitSnack(
            'Could not report person. Try again later',
            KitSnackDuration.SHORT
          );
        });
    }
  };

  const _onCancelEditingPress = () => {
    setActiveMessage(null);
    setIsEditing(false);
    setTextMessage('');
    Keyboard.dismiss();
  };

  const _onConfirmEditingPress = () => {
    if (textMessage === '') {
      _deleteSelectedMessage();
    } else {
      if (channel) {
        dispatch(
          updateTextMessage(channel, activeMessage?.messageId, textMessage)
        );
      }
    }

    setActiveMessage(null);
    setIsEditing(false);
    setTextMessage('');
    Keyboard.dismiss();
  };

  const _onToggleSendToChannel = () => {
    dispatch(setSendToChannel(!sendToChannel));
  };

  const _onReplyInThread = () => {
    if (selectedMessage) {
      let data: CustomMessageData | null = null;

      if (selectedMessage.data) {
        try {
          data = JSON.parse(selectedMessage.data);
        } catch (e) {}
      }

      let messageId = selectedMessage.messageId;

      if (data?.sentToChannel && data?.inChannel && data?.replyId) {
        messageId = data?.replyId;
      }

      navigation.navigate(Constants.CHAT_THREAD_SCREEN, {
        parentMessageId: messageId,
        channelId,
      });
    }
  };

  const _onReportMessage = () => {
    if (selectedMessage) {
      setShowReportMessageSheet(true);
    }
  };

  const _onReportMessageWeb = () => {
    setShowReportMessageSheet(true);
  };

  const _onReportUser = () => {
    if (selectedUser) {
      setUserToReport(selectedUser);
      setSelectedUser(null);
    }
  };

  const _onBlockUser = () => {
    if (selectedUser) {
      setUserToBlock(selectedUser);
      setSelectedUser(null);
    }
  };

  const _onReportPopup = (user: any) => {
    setUserToReport(user);
    setSelectedUser(null);
  };

  const _onBlockPopup = (user: any) => {
    setUserToBlock(user);
    setSelectedUser(null);
  };

  const _hideActionSheet = () => {
    setSelectedMessage(null);
    setSelectedReaction(null);
  };

  const _hideUserActionSheet = () => {
    setSelectedUser(null);
  };

  const showKitSnack = (msg: string, dur: number) => {
    setSnackOptions({ visible: true, message: msg, duration: dur });
  };

  const invertedWheelEvent = useCallback(
    (e: { deltaY: number; preventDefault: () => void }) => {
      if (chatFlatList && chatFlatList.current) {
        chatFlatList.current.getScrollableNode().scrollTop -= e.deltaY;
        e.preventDefault();
      }
    },
    []
  );

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (throttlingScroll) {
      return;
    }

    setThrottlingScroll(true);
    const yValue = event.nativeEvent?.contentOffset?.y;
    setTimeout(() => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setScrollOffset(yValue);
      setThrottlingScroll(false);
    }, 300);
  };

  const scrollToIndexFailed = (info: {
    index: number;
    highestMeasuredFrameIndex: number;
    averageItemLength: number;
  }) => {
    const offset = info.averageItemLength * info.index;
    chatFlatList.current?.scrollToOffset({ offset });
    setTimeout(
      () => chatFlatList.current?.scrollToIndex({ index: info.index }),
      100
    ); // You may choose to skip this line if the above typically works well because your average item height is accurate.
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  useEffect(() => {
    debug('componentDidMount()');
    let listener: NativeEventSubscription | undefined;

    if (Platform.OS !== 'web') {
      listener = ReactAppState.addEventListener(
        'change',
        _handleAppStateChange
      );
    }

    dispatch(setChannelName(channel?.name ?? ''));

    _getMessageList(true);

    if (channel) {
      dispatch(getMemberList(channel?.url));
      dispatch(getBlockedUserList()); // see usage in 'formatDirectChannelName'
    }

    dispatch(setParentMessageId(parentMessageId));
    dispatch(setSendToChannel(false));

    return () => {
      if (Platform.OS !== 'web') {
        listener?.remove();
      }

      sbMarkAsRead(channel);
      dispatch(updateMessageList([]));
      dispatch(setParentMessageId(undefined));
    };
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web' && !isThread) {
      const currentRef = chatFlatList.current;

      if (currentRef != null) {
        currentRef
          .getScrollableNode()
          ?.addEventListener('wheel', invertedWheelEvent);
      }

      return () => {
        if (currentRef != null) {
          currentRef
            .getScrollableNode()
            ?.removeEventListener('wheel', invertedWheelEvent);
        }
      };
    }
  }, [chatFlatList, invertedWheelEvent]);

  const listenerRef = useRef<NativeEventSubscription | undefined>(undefined);
  useEffect(() => {
    if (!sbIsConnected()) {
      debug('Skipping effect for app state and unread state. Not connected.');

      return () => {
        listenerRef.current?.remove();
      };
    }

    debug('Effect for app state and unread state', channel?.name);
    if (channel) {
      setUnreadCount(channel?.unreadMessageCount);
      if (Platform.OS !== 'web') {
        // This listener has to be overwritten since it's if a different channel,
        // the function won't realize we have a different channel
        listenerRef.current?.remove();
        listenerRef.current = ReactAppState.addEventListener(
          'change',
          _handleAppStateChange
        );
      }

      _initChatScreen();
      debug('set empty message list');
      dispatch(updateMessageList([]));
      _getMessageList(true);
      sbMarkAsRead(channel);
      dispatch(createChatHandler(channel.url, parentMessageId));
    }

    return () => {
      listenerRef.current?.remove();
    };
  }, [channel]);

  useEffect(() => {
    if (unreadCount !== undefined && messageData.length >= unreadCount) {
      const msg = messageData[unreadCount - 1];

      if (msg !== undefined) {
        setLastReadMessageId(`${msg.messageId}`);
      }
    }
  }, [unreadCount, messageData]);

  useEffect(() => {
    debug('useEffect:channel.myLastRead');
    _cleanupDeliveredNotifications();

    // A user may force close the app before we've had a chance to update the iOS badge number
    // So this is a best effort to keep the badge number updated in case the ChannelListScreen 'background' app state transition gets skipped.
    updateUnread();
  }, [channel?.myLastRead]);

  useEffect(() => {
    const data: SendbirdMessage[] = sbAdjustMessageList(messages);
    const newMessages = data.filter((m) => m.channelUrl === channel?.url);
    setMessageData(newMessages);
    lastMessage.current = newMessages[0] as UserMessage &
      CustomMessageAttributes;
    if (newMessages.length === messageData.length + 1) {
      setUnreadCount(unreadCount ? unreadCount + 1 : 1);
    }

    if (channel) {
      sbMarkAsRead(channel);
    }
  }, [messages]);

  useEffect(() => {
    if (isFocused) {
      debug('onFocus');
      if (channel) {
        sbMarkAsRead(channel);
      }

      _getMessageList(true);
    } else {
      debug('onBlur');
      if (channel) {
        sbMarkAsRead(channel);
      }
    }
  }, [channel, channelName, isFocused, navigation]);

  useEffect(() => {
    if (channel && channel.url) {
      if (textMessage && textMessage.length > 0) {
        dispatch(startTyping(channel));
      } else {
        dispatch(endTyping(channel));
      }
    }
  }, [channel, dispatch, textMessage]);

  useEffect(() => {
    if (deletedChannel) {
      dispatch(setDeletedChannel(null));
      navigation.navigate(Constants.CHANNEL_LIST_SCREEN);
    }
  }, [deletedChannel, navigation]);

  useEffect(() => {
    if (isAnsweringPrayer) {
      setTimeout(() => {
        navigation.navigate(Constants.PRAYER_REQUEST_SCREEN, {
          screen: Constants.PRAYER_REQUEST_SCREEN,
          params: { prayerRequest: activeMessage },
        });
      }, 200);
      setIsAnsweringPrayer(false);
    }
  }, [isAnsweringPrayer, activeMessage, navigation]);

  const messagesFlatList = useMemo(() => {
    const _getMessageListFalse = () => _getMessageList(false);
    const _getMessageKey = (item: SendbirdMessage) =>
      String(
        item.messageId !== 0
          ? item.messageId
          : (item as SendbirdSenderMessage).reqId
      );

    const typingIndicator = !userTyping ? null : (
      <FadeInView>
        <View style={styles.renderTyping}>
          <KitText gray fontSize={12} style={{ fontWeight: '500' }}>
            {userTyping}
          </KitText>
        </View>
      </FadeInView>
    );

    return (
      <FlatList<SendbirdMessage>
        ref={chatFlatList as LegacyRef<any>}
        contentContainerStyle={styles.flatListStyle}
        inverted={!isThread}
        renderItem={_renderItem}
        data={messageData}
        keyExtractor={_getMessageKey}
        onEndReached={_getMessageListFalse}
        onEndReachedThreshold={0.1}
        keyboardDismissMode={isThread ? 'interactive' : 'on-drag'}
        keyboardShouldPersistTaps='always'
        onScroll={handleScroll}
        {...(isThread
          ? { ListFooterComponent: typingIndicator }
          : { ListHeaderComponent: typingIndicator })}
        // disabling virtualization allows for smoother scrolling on the web
        disableVirtualization={Platform.OS === 'web'}
        onScrollToIndexFailed={scrollToIndexFailed}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageData, userTyping]);

  const channelDisplayName =
    channel === null
      ? ''
      : (channel.customType as ChannelType) === ChannelType.Direct
      ? formatDirectChannelName(user, channel.members, blockedUserList)
      : channel.name;

  return channel ? (
    <>
      <View style={styles.containerView}>
        {!isThread && (
          <ScrollButtons
            scrollOffset={scrollOffset}
            scrollToEnd={scrollToEnd}
          />
        )}
        <View style={styles.messageListViewStyle}>{messagesFlatList}</View>
        <MessageInput
          onChangeText={(text) => {
            setTextMessage(text);
            if (isThread) {
              scrollToEnd();
            }
          }}
          onSendPress={_onSendButtonPress}
          onMediaMessageSend={scrollToEnd}
          value={textMessage}
          disabled={!textMessage}
          isEditing={isEditing}
          isThread={isThread}
          channelName={channelDisplayName}
          selectedMessage={selectedMessage}
          onCancelEditingPress={_onCancelEditingPress}
          onConfirmEditingPress={_onConfirmEditingPress}
          onToggleSendToChannel={_onToggleSendToChannel}
          textChanged={
            activeMessage &&
            (activeMessage as UserMessage).message !== textMessage
              ? true
              : false
          }
        />

        <PlatformKeyboardSpacer />
        <MessageActionSheet
          channel={channel!}
          selectedMessage={selectedMessage}
          selectedReaction={selectedReaction}
          setActiveMessage={setActiveMessage}
          deleteMessage={_deleteSelectedMessage}
          setIsEditing={setIsEditing}
          setTextMessage={setTextMessage}
          onReply={_onReplyInThread}
          onReportMessage={_onReportMessage}
          onClose={_hideActionSheet}
          onCopy={() => showKitSnack('Text copied', KitSnackDuration.SHORT)}
          setIsAnsweringPrayer={setIsAnsweringPrayer}
          setSelectedMessage={setSelectedMessage}
          showReplyAction={!isThread}
        />
      </View>
      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />
      <KitModalV2
        isVisible={showReportMessageSheet}
        onClose={() => setShowReportMessageSheet(false)}
        anchorBottom={sizeClass === SizeClass.Small}
        coverScreen
      >
        <ConfirmReportMessageContent
          selectedMessage={activeMessage!}
          setConfirmReportMessageVisible={setShowReportMessageSheet}
          confirmReportMessage={_confirmReportMessage}
        />
      </KitModalV2>
      <Modal
        isVisible={confirmDeleteModalVisible}
        animationIn='fadeIn'
        animationOut='fadeOut'
      >
        <ConfirmDeleteModalContent
          selectedMessage={activeMessage || undefined}
          setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
          confirmDeleteMessage={_confirmDeleteMessage}
        />
      </Modal>
      <UserActionSheet
        isVisible={selectedUser !== null && sizeClass === SizeClass.Small}
        user={selectedUser}
        onClose={_hideUserActionSheet}
        onReportUser={_onReportUser}
        onBlockUser={_onBlockUser}
      />
      <ReportUserModal
        isVisible={userToReport !== null}
        userToReport={userToReport}
        onClose={() => setUserToReport(null)}
        confirmReportUser={_confirmReportUser}
      />
      <BlockUserModal
        isVisible={userToBlock !== null}
        userToBlock={userToBlock}
        onClose={() => setUserToBlock(null)}
        confirmBlockUser={_confirmBlockUser}
      />
      <OpenInAppModal
        screenName={Constants.CHAT_SCREEN}
        channelName={channelDisplayName}
      />
    </>
  ) : null;
}

export default ChatScreen;

const styles = StyleSheet.create({
  containerView: {
    flex: 1,
    backgroundColor: colorForScheme({ default: Colors.N0 }),
    position: 'relative',
    borderTopWidth: 1,
    borderTopColor: Colors.N100,
  },
  messageListViewStyle: {
    flex: 1,
  },
  messageInputViewStyle: {
    marginBottom: 0,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  flatListStyle: {
    paddingTop: Spacing.m,
    paddingBottom: Spacing.l,
    ...Platform.select({
      web: {
        height: 100, // arbitrary height to enable flatlist scrolling
      },
    }),
  },
  renderTyping: {
    marginTop: Spacing.xl,
    marginLeft: Spacing.m,
  },
});
