import Environment from '@omni/kit/Environment';
import BaseServiceV2, {
  IHttpResponse,
  IInclude,
  IUri,
  buildUrl,
} from '@omni/kit/services/BaseServiceV2';

import { KioskConfig } from '../Types';

const HOST = Environment.checkInService;
const RESOURCE = 'kiosk-configs';

interface IGetKiosks extends IUri {
  token?: string;
  includes?: IInclude[];
}

interface IGetAllKiosksResponse extends IHttpResponse {
  body?: {
    _embedded: {
      'kiosk-configs': KioskConfig[];
    };
    count: number;
    _links: {
      self: {
        href: string;
      };
    };
  };
}

export default async function GetKiosks({
  token,
  includes,
  sorts,
  filters,
}: IGetKiosks): Promise<IGetAllKiosksResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    includes,
    sorts,
    filters,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
