import { version } from '@omni/kit';
import AsyncStorage from '@react-native-async-storage/async-storage';

const semver = require('semver');

const debug = require('debug')('tca:chat:utilities:mobileStorage');

//******************************************************************************
// Cached Startup
//******************************************************************************

export const retrieveCachedStartupDataFromDevice = async (): Promise<unknown> =>
  await retrieveAllGlobalDataFromDevice(['@appId', '@userId']);

//******************************************************************************
// App ID
//******************************************************************************

export const saveAppIdToDevice = (value: string): Promise<void> => {
  return saveGlobalDataToDevice('@appId', value);
};

export const retrieveAppIdFromDevice = async (): Promise<string> =>
  (await retrieveGlobalDataFromDevice('@appId')) as string;

export const removeAppIdFromDevice = (): Promise<void> =>
  removeGlobalDataFromDevice('@appId');

//******************************************************************************
// Chat User Data
//******************************************************************************

export const saveUserIdToDevice = (value: string): Promise<void> =>
  saveGlobalDataToDevice('@userId', value);

export const retrieveUserIdFromDevice = async (): Promise<string> =>
  (await retrieveGlobalDataFromDevice('@userId')) as string;

export const removeUserIdFromDevice = (): Promise<void> =>
  removeGlobalDataFromDevice('@userId');

//******************************************************************************
// End User Data
//******************************************************************************

export const saveEndUserIdToDevice = (value: string): Promise<void> =>
  saveGlobalDataToDevice('@endUserId', value);

export const retrieveEndUserIdFromDevice = async (): Promise<
  string | undefined
> => (await retrieveGlobalDataFromDevice('@endUserId')) as string | undefined;

export const removeEndUserIdFromDevice = (): Promise<void> =>
  removeGlobalDataFromDevice('@endUserId');

//******************************************************************************
// @introPresented
//******************************************************************************

export const saveIntroPresentedToDevice = (value: boolean): Promise<void> =>
  saveGlobalDataToDevice('@introPresented', value);

//******************************************************************************
// @appUpdated
//******************************************************************************

export const saveOmniVersion = (): void => {
  saveGlobalDataToDevice('@omniVersion', version);
};

export const isNewOmniVersion = async (): Promise<boolean> => {
  const prevVersion = (await retrieveGlobalDataFromDevice(
    '@omniVersion'
  )) as string;

  if (!prevVersion) return true;

  return ['major', 'minor'].includes(semver.diff(version, prevVersion));
};

//******************************************************************************
// Utility Functions
//******************************************************************************

export const saveGlobalDataToDevice = async (
  key: string,
  value: unknown
): Promise<void> => {
  return await AsyncStorage.setItem(key, JSON.stringify(value));
};

export const retrieveGlobalDataFromDevice = async (
  key: string
): Promise<string | null | undefined> => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);

    if (jsonValue !== null) {
      return JSON.parse(jsonValue); // expecting a string in this case
    }

    debug(`data with key of *${key}* not found on device`);
  } catch (error) {
    throw error;
  }
};

const retrieveAllGlobalDataFromDevice = async (keys: string[]) => {
  try {
    const map = new Map();
    const values = await AsyncStorage.multiGet(keys);
    values.map((result, i, store) => {
      map.set(store[i][0], store[i][1]);
    });

    return map;
  } catch (error) {
    throw error;
  }
};

const removeGlobalDataFromDevice = async (key: string) => {
  try {
    return await AsyncStorage.removeItem(key);
  } catch (error) {
    throw error;
  }
};
