import { KitIcon, KitListItem } from '@omni/kit/components';

import Colors from '@omni/kit/theming/Colors';
import { IInvited } from '../../Types';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';
import { inviteTimeSince } from '../../../utilities/sendbird/channelFunctions';

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  invited: IInvited;
}

export default function InvitedListRow({ invited }: IProps): JSX.Element {
  const { title, timestamp } = invited;
  const date = new Date(timestamp);
  const timeSince = inviteTimeSince(date.valueOf());
  const maxTitleLength = 30;
  const ellipses = '...';
  const truncatedTitleLength = maxTitleLength - ellipses.length;

  return (
    <KitListItem
      ImageComponent={
        <KitIcon
          name='person'
          color={Colors.N0}
          style={{
            padding: Spacing.s,
            borderRadius: Spacing.xxl,
            backgroundColor: Colors.N200,
          }}
        />
      }
      title={
        title.length > maxTitleLength
          ? `${title.slice(0, truncatedTitleLength)}${ellipses}`
          : title
      }
      subtitle={`Invited ${timeSince}`}
    />
  );
}
