import { Optional } from '../Types';
import Spacing from './Spacing';

export const getSpacing = (type: Optional<SpacingType>): number | undefined => {
  if (!type) {
    return undefined;
  }

  switch (type) {
    case 'xxs':
      return Spacing.xxs;
    case 'xs':
      return Spacing.xs;
    case 'small':
      return Spacing.s;
    case 'medium':
      return Spacing.m;
    case 'large':
      return Spacing.l;
    case 'xl':
      return Spacing.xl;
    case 'xxl':
      return Spacing.xxl;
    default:
      return 0;
  }
};

export enum SpacingType {
  None = 'none',
  ExtraExtraSmall = 'xxs',
  ExtraSmall = 'xs',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'xl',
  ExtraExtraLarge = 'xxl',
}
