import HealthMonitor from '@omni/kit/HealthMonitor';
import { getStoredItem, setStoredItem } from '@omni/kit/storage';
import { useEffect, useState } from 'react';

import { DEFAULT_APP_KEY } from './KioskContext';

/*
 * Do not use this directly in a component, instead use KioskContext
 * */
export default (): {
  appKey: string;
  setAppKey: (appKey: string) => Promise<void>;
  appKeyLoading: boolean;
} => {
  const [appKey, setAppKey] = useState<string>(DEFAULT_APP_KEY);
  const [appKeyLoading, setAppKeyLoading] = useState<boolean>(true);

  useEffect(() => {
    const getAppKey = async () => {
      const appKey = await getStoredItem('check_in_app_key');

      if (appKey) {
        setAppKey(appKey);
        HealthMonitor.setTag('sap-active-appkey', appKey);
      }

      setAppKeyLoading(false);
    };
    getAppKey();
  }, []);

  const saveAppKey = async (appKey: string) => {
    await setStoredItem('check_in_app_key', appKey);
    HealthMonitor.setTag('sap-active-appkey', appKey);

    setAppKey(appKey);
  };

  return { appKey, setAppKey: saveAppKey, appKeyLoading };
};
