import {
  Platform,
  StyleSheet,
  TouchableHighlight,
  TouchableNativeFeedback,
  View,
} from 'react-native';
import React, { useContext } from 'react';

import Colors from '@omni/kit/theming/Colors';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';

//****************************************************************************
// Types
//****************************************************************************

//****************************************************************************
// Component
//****************************************************************************

export default function RoundButton({
  buttonSize = 56,
  children,
  disabled = false,
  onPress = () => undefined,
}: {
  buttonSize?: number;
  children: any;
  disabled?: boolean;
  onPress: () => void;
}): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);

  return Platform.OS === 'ios' || Platform.OS === 'web' ? (
    <TouchableHighlight
      delayPressIn={0}
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.buttonContainer,
        { height: buttonSize, width: buttonSize },
      ]}
      underlayColor={colorForScheme?.({
        dark: Colors.N900,
        light: Colors.N200,
      })}
    >
      <View
        style={[
          styles.button,
          {
            height: buttonSize,
            opacity: disabled ? 0.8 : 1.0,
            width: buttonSize,
          },
        ]}
      >
        {children}
      </View>
    </TouchableHighlight>
  ) : (
    <TouchableNativeFeedback
      delayPressIn={0}
      disabled={disabled}
      onPress={onPress}
      useForeground={true}
    >
      <View
        style={[
          styles.button,
          {
            height: buttonSize,
            opacity: disabled ? 0.8 : 1.0,
            width: buttonSize,
          },
        ]}
      >
        {children}
      </View>
    </TouchableNativeFeedback>
  );
}

//****************************************************************************
// Styles
//****************************************************************************

const styles = StyleSheet.create({
  button: {
    ...Platform.select({ android: { overflow: 'hidden' } }),
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: 28,
    justifyContent: 'center',
  },
  buttonContainer: { borderRadius: 28 },
});
