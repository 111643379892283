import * as Constants from '../../../shared/Constants';

import { IFile, MessageType } from '../../../shared/redux/types';
import { Keyboard, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { processImageFile } from '../../../utilities/imageUtilities';
import { useDispatch, useSelector } from 'react-redux';

import ActionButton from './actionButtons/ActionButton';
import Colors from '@omni/kit/theming/Colors';
import FileActionButton from './actionButtons/FileActionButton';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import ImageActionButton from './actionButtons/ImageActionButton';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';
import { channelSelector } from '../../../shared/redux/selectors';
import { sendFileMessage } from '../../../shared/redux/actions/ChatActions';
import { useNavigation } from '@react-navigation/native';

export default function ActionBar({
  onMessageSent = () => undefined,
}: {
  onMessageSent?: () => void;
}): JSX.Element {
  const channel = useSelector(channelSelector);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const onButtonPress =
    (screen: string, data = {}) =>
    () => {
      Keyboard.dismiss();
      navigation.navigate(screen, data);
      onMessageSent();
    };

  const sendStrippedFile = async (file: IFile) => {
    if (file.type.startsWith('image/')) {
      if (file.file !== undefined) {
        const imageProcessed = await processImageFile(file.file);
        // @ts-ignore
        dispatch(sendFileMessage(channel as GroupChannel, imageProcessed));
      }
    } else {
      channel && dispatch(sendFileMessage(channel, file));
    }
  };

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      style={styles.actionBarScrollView}
      contentContainerStyle={styles.actionButtonWrapper}
      horizontal
      keyboardShouldPersistTaps='always'
    >
      <ImageActionButton
        sendFileMessage={sendStrippedFile}
        onMessageSent={onMessageSent}
      />
      <ActionButton
        icon='gif'
        label='GIF'
        onPress={onButtonPress(Constants.GIF_SCREEN, { channel })}
      />
      <ActionButton
        icon='prayer'
        label='Prayer'
        onPress={onButtonPress(Constants.PRAYER_REQUEST_SCREEN)}
      />
      <FileActionButton
        sendFileMessage={sendStrippedFile}
        onMessageSent={onMessageSent}
      />
      <ActionButton
        icon='media'
        label='Media'
        onPress={onButtonPress(Constants.MEDIA_SELECTION_SCREEN)}
      />
      <ActionButton
        icon='poll'
        label='Poll'
        onPress={onButtonPress(Constants.POLL_SCREEN, { channel })}
      />
      <ActionButton
        icon='bible'
        label='Passage'
        onPress={onButtonPress(Constants.BIBLE_LOCATION_SCREEN, { channel })}
      />
      <View style={{ width: 50 }} />
    </ScrollView>
  );
}

const modalSize = {
  height: 500,
  width: 400,
};
const styles = StyleSheet.create({
  actionBarScrollView: {
    borderTopColor: Colors.N100,
    borderTopWidth: 1,
  },
  actionButtonWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      default: {},
      web: { flex: 1, minWidth: 'auto', padding: Spacing.m },
    }),
  },
  gifModal: modalSize,
  mediaModal: modalSize,
  popupWrapper: {
    backgroundColor: Colors.N0,
    borderRadius: 14,
    paddingHorizontal: Spacing.l,
    position: 'relative',
    ...Platform.select({
      android: { elevation: 10, overflow: 'hidden' },
      default: {
        shadowColor: '#000000',
        shadowOffset: { height: 1, width: 0 },
        shadowOpacity: 0.35,
        shadowRadius: 10,
      },
    }),
  },
});
