import { useShellContext } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import { NATIVE_EVENT_KEY } from '@omni/kit/Types';
import KitHorizontalScroll from '@omni/kit/components/KitHorizontalScroll';
import KitIcon from '@omni/kit/components/KitIcon';
import KitListItem from '@omni/kit/components/KitListItem';
import KitText from '@omni/kit/components/KitText';
import KitTouchable from '@omni/kit/components/KitTouchable';
import Show from '@omni/kit/components/Show';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { useScreenVisibility } from '@omni/kit/contexts/ScreenVisibilityContext';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { useFeatureFlag } from '@omni/kit/hooks/useFeatureFlag';
import { AppFeatureName } from '@omni/kit/services/Types';
import { UNLEASH_TOGGLES } from '@omni/kit/services/UnleashService';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { useThemeContext } from '@omni/kit/theming/ThemeContext';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { CONTEXT_TYPES } from '../../../../src/dynamicMedia/types';
import { ContinuePlayingBlockProps, IMediaPlayItem } from './types';
import { fetchMediaPlays } from './utilities';

const debug = require('debug')('tca:blocks:ContinuePlayingBlock');
const SILHOUETTE_IMAGE_SIZE = 76;

export default function ContinuePlayingBlock({
  appKey,
  data,
  presentationStyle = '',
  firstItemPadding = 0,
  setAccountPagePadding = false,
  style = {},
}: ContinuePlayingBlockProps): JSX.Element | null {
  const { app, user, tokens } = useShellContext();
  const isGatedContentEnabled = Boolean(
    app.features.find(
      (f) => f.name === AppFeatureName.GATED_CONTENT_V1 && f.enabled
    )
  );
  const silhouetteLogoImage = app?.branding?.silhouetteLogo;
  const isAuthenticated = Boolean(tokens?.user);

  const { t } = useTranslation(['continuePlaying']);
  const newMediaDetailPage = useFeatureFlag(
    UNLEASH_TOGGLES.MEDIA_MOBILE_NEW_MEDIA_DETAIL_PAGE
  );

  const useBlockPageForMediaItem =
    isGatedContentEnabled || !newMediaDetailPage.isEnabled;

  const { sizeClass } = useSizeClass();
  const isScreenVisible = useScreenVisibility();

  const userInfo = user?.profile;

  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: undefined,
  });

  const [refreshItems, setRefreshItems] = useState<boolean>(false);
  const [items, setItems] = useState<IMediaPlayItem[]>(data || []);

  const isLargeScreen = sizeClass !== SizeClass.Small;

  /**
   * TODO:
   * `firstItemPadding` should be set at the parent level aka the Account menu page (resolver.tsx)
   */
  if (setAccountPagePadding) {
    firstItemPadding = Spacing.l;
    if (isLargeScreen) {
      firstItemPadding += edgeSpacing;
    }
  }

  const renderPlayHistoryIcon = () => {
    return (
      <View style={styles.noPlayHistoryIcon}>
        <KitIcon color={Colors.N300} name={'media'} size={20} />
      </View>
    );
  };

  const renderNoPlayHistory = () => {
    return (
      <View>
        <KitListItem
          bottomBorder={false}
          ImageComponent={renderPlayHistoryIcon()}
          showRightIconOnAndroid={true}
          style={styles.noPlayHistory}
          title={t('continuePlaying:emptyPlayHistoryMessage')}
          titleColor={Colors.N500}
          titleFontSize={16}
        />
      </View>
    );
  };

  const onListItemPressed = (item: IMediaPlayItem): void => {
    dispatch(item.action);
  };

  const silhouetteImage = useCallback(
    () =>
      parseImageUrl(
        silhouetteLogoImage || '',
        SILHOUETTE_IMAGE_SIZE,
        SILHOUETTE_IMAGE_SIZE,
        ImageServiceType.ImagePng
      ),
    [silhouetteLogoImage]
  );

  const initialize = useCallback(async () => {
    debug('initialize', {
      appKey,
      userInfo,
      useBlockPageForMediaItem,
      newMediaDetailPageIsReady: newMediaDetailPage.isReady,
    });
    if (data || !newMediaDetailPage.isReady) return;

    const config = {
      user: { userID: user?.id ?? '', ...userInfo },
      appKey,
      useBlockPage: useBlockPageForMediaItem,
    };

    setItems(
      (await fetchMediaPlays({
        ...config,
        context: CONTEXT_TYPES.CACHE,
      })) || []
    );

    const response = await fetchMediaPlays({
      ...config,
      context: CONTEXT_TYPES.NETWORK,
    });

    setItems(response || []);
  }, [
    appKey,
    data,
    newMediaDetailPage.isReady,
    useBlockPageForMediaItem,
    user?.id,
    userInfo,
  ]);

  useEffect(() => {
    if (data) setItems(data);
  }, [data]);

  useEffect(() => {
    if (isAuthenticated && userInfo) {
      initialize();
    }
  }, [initialize, isAuthenticated, userInfo]);

  useEffect(() => {
    if (refreshItems) {
      setRefreshItems(false);
      initialize();
    }
  }, [initialize, refreshItems]);

  useEffect(() => {
    setRefreshItems(isScreenVisible);
  }, [isScreenVisible]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <View style={style}>
      {items.length > 0 ? (
        <KitHorizontalScroll
          key={'continuePlayingBlock'}
          data={items}
          onItemPressed={onListItemPressed}
          silhouetteLogoImage={silhouetteImage()}
          showViewMore={false}
          title={
            <Title
              items={items}
              presentationStyle={presentationStyle}
              style={styles.titleWrapper}
            />
          }
          style={{ marginTop: 0 }}
          firstItemPadding={firstItemPadding}
        />
      ) : (
        <View style={{ paddingHorizontal: firstItemPadding }}>
          <Title items={items} presentationStyle={presentationStyle} />
          {renderNoPlayHistory()}
        </View>
      )}
    </View>
  );
}

const Title = ({
  items = [],
  presentationStyle = '',
  style,
}: {
  items: IMediaPlayItem[];
  presentationStyle?: string;
  style?: ViewStyle;
}) => {
  const { colorForScheme } = useThemeContext();
  const { t } = useTranslation(['continuePlaying']);

  return (
    <KitTouchable
      style={style}
      borderRadius={0}
      ripple={false}
      onPress={() => {
        if (items.length === 0) return;

        dispatch({
          handler: 'mediaList',
          presentationStyle: 'modal',
          nativeEventKey: NATIVE_EVENT_KEY.CONTINUE_PLAYING,
        });
      }}
      underlayColor={
        colorForScheme?.({
          light: Colors.N0,
          dark: Colors.N1000,
        }) || Colors.N0
      }
    >
      <View style={styles.titleContainer}>
        <KitText
          h2
          bold={true}
          color={
            colorForScheme?.({
              light: Colors.N900,
              dark: Colors.N0,
            }) || Colors.N900
          }
        >
          {t('continuePlaying:headerTitle')}
        </KitText>
        <Show show={items.length > 0}>
          <KitIcon
            color={Colors.N300}
            name={'arrow-right'}
            size={20}
            style={{ marginLeft: Spacing.s }}
          />
        </Show>
      </View>
    </KitTouchable>
  );
};

const styles = StyleSheet.create({
  titleWrapper: {
    marginBottom: Spacing.m,
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  noPlayHistory: {
    borderColor: Colors.N100,
    borderBottomWidth: 1,
    paddingVertical: Spacing.l,
    height: 78,
  },
  noPlayHistoryIcon: {
    alignItems: 'center',
    backgroundColor: Colors.N50,
    borderRadius: BorderRadius.m,
    display: 'flex',
    justifyContent: 'center',
    padding: Spacing.s,
  },
});
