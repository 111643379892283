import { AuthProvider } from './Types';

export const excludeSocialAuthProviders = (
  providers: AuthProvider[]
): AuthProvider[] => {
  return providers.filter(
    (provider) =>
      provider.service_name !== 'apple' &&
      provider.service_name !== 'facebook' &&
      provider.service_name !== 'google'
  );
};
