import { dispatch as actionDispatch } from '@omni/kit/ActionHandler';
import HealthMonitor from '@omni/kit/HealthMonitor';
import KitHtml from '@omni/kit/components/KitHtml';
import KitText from '@omni/kit/components/KitText';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { useScriptureModalContext } from '@omni/kit/contexts/ScriptureModalContext';
import Colors from '@omni/kit/theming/Colors';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { dateFormatter } from '@omni/kit/utilities/utilities';
import 'bible-passage-reference-parser/js/en_bcv_parser';
import React, { useContext } from 'react';
import { View } from 'react-native';

import { IBlockProps } from './types';

let BCV: any;

// @ts-ignore
if (typeof window !== 'undefined' && window.bcv_parser) {
  // @ts-ignore
  BCV = new window.bcv_parser();

  BCV.set_options({
    osis_compaction_strategy: 'b',
    book_alone_strategy: 'first_chapter',
  });
  BCV.include_apocrypha(true);
}

// These props are publicly available
export interface ITextBlockProps extends IBlockProps {
  content: string;

  /** Supported in release 6.2.0+ */
  expandable?: boolean;

  format?: boolean;
  numberOfLines?: number;
  html?: boolean;
  name?: string;

  align?:
    | 'start'
    | 'end'
    | 'center'
    | 'left' /* deprecated in 6.2.0 */
    | 'right' /* deprecated in 6.2.0 */;

  body1?: boolean;
  h1?: boolean;
  subtitle?: boolean;
}

export default function TextBlock(props: ITextBlockProps): JSX.Element {
  const {
    align = 'start',
    bottomSpacing = null,
    content,
    expandable = true,
    horizontalSpacing,
    html = false,
    insetStyle = {},
    numberOfLines = 4,
    topSpacing = null,
    name = '',
  } = props;

  const { colorForScheme } = useContext(ThemeContext);

  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const { showScriptureModal } = useScriptureModalContext();

  const viewStyle = {
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
    ...insetStyle,
  };

  const onSpeakerPress = (speaker: string) => {
    if (!speaker) return;

    actionDispatch({
      handler: 'search',
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      moduleCommand: {
        name: 'navigate_result',
        data: {
          title: speaker.trim(),
          type: 'speaker',
          value: speaker.trim(),
        },
      },
    });
  };

  const formatSpeakers = (speakers: string, result: JSX.Element[]) => {
    if (!speakers) return;

    const speakersArray = speakers?.split(',');
    speakersArray.forEach((speaker: string, index: number) => {
      result.push(
        <KitText
          key={`${index}-speaker`}
          body1={props.body1}
          h1={props.h1}
          subtitle={props.subtitle}
          style={[{ textDecorationLine: 'underline' }]}
          onPress={() => onSpeakerPress(speaker?.trim())}
          color={
            colorForScheme?.({
              dark: Colors.N100,
              light: Colors.N900,
            }) || Colors.N100
          }
        >
          {speaker?.trim()}
        </KitText>
      );

      if (index < speakersArray.length - 1) {
        result.push(
          <KitText key={`${index}-speaker-delimiter`}>{', '}</KitText>
        );
      }
    });
  };

  const formatJSON = (text = ''): JSX.Element[] => {
    if (!props.format) {
      return [
        <KitText {...props} key='0'>
          {text}
        </KitText>,
      ];
    }

    const DELIMITER = ' • ';
    const result: JSX.Element[] = [];

    const splits = text.split(DELIMITER);
    splits.forEach((s: string, index: number) => {
      const format = s.match(/%({.*?})%/);

      if (format && format.length > 1) {
        try {
          const formatObject = JSON.parse(format[1]);
          const formatType = Object.keys(formatObject)[0];
          const formatData = formatObject[formatType];

          switch (formatType) {
            case 'date':
              result.push(
                <KitText key={index} body1={props.body1} h1={props.h1}>
                  {dateFormatter(formatData.value, 'LL')}
                </KitText>
              );
              break;
            case 'scriptures':
              const osisStr = BCV?.parse(formatData.value).osis();

              if (osisStr) {
                result.push(
                  <KitText
                    key={index}
                    body1={props.body1}
                    h1={props.h1}
                    subtitle={props.subtitle}
                    onPress={() => showScriptureModal(osisStr)}
                    style={[{ textDecorationLine: 'underline' }]}
                    color={
                      colorForScheme?.({
                        dark: Colors.N100,
                        light: Colors.N900,
                      }) || Colors.N100
                    }
                  >
                    {formatData.value}
                  </KitText>
                );
              }

              break;
            case 'speaker':
              formatSpeakers(formatData.value, result);
              break;
          }
        } catch (e) {
          if (e instanceof Error) {
            HealthMonitor.logError(e);
          } else {
            HealthMonitor.logError(
              new Error('Failed to format tags for text block')
            );
          }
        }
      } else {
        result.push(
          <KitText key={index} body1={props.body1} h1={props.h1}>
            {s}
          </KitText>
        );
      }

      if (index < splits.length - 1) {
        result.push(<KitText key={`${index}-delimiter`}>{DELIMITER}</KitText>);
      }
    });

    return result;
  };

  return (
    <>
      <View style={viewStyle}>
        <View style={{ marginHorizontal: edgeSpacing }}>
          {html ? (
            <KitHtml content={content} expandable={expandable} />
          ) : (
            <KitText
              {...props}
              style={{
                // in RTL languages, react-native Text component's textAlign
                // 'left' will actually be 'right' and vice-versa
                ...(align === 'center' && { textAlign: 'center' }),
                ...(align === 'start' && { textAlign: 'left' }),
                ...(align === 'end' && { textAlign: 'right' }),
                ...(align === 'left' && { textAlign: 'left' }),
                ...(align === 'right' && { textAlign: 'right' }),
              }}
              ellipsizeMode={'clip'}
              numberOfLines={numberOfLines}
              expandable={expandable}
            >
              {formatJSON(content)}
            </KitText>
          )}
        </View>
      </View>
    </>
  );
}
