import { WindowSizeMin } from '@omni/kit/contexts/SizeClassContext';

export const AUTH_PROVIDERS_LIST_SCREEN = 'auth_providers_list_screen';
export const AUTH_STACK = 'auth_stack';
export const COMMAND_SET_TOKEN = 'set_token';

/** Auth modal constants */
export const MIN_MODAL_HEIGHT = 200;
export const MIN_MODAL_WIDTH = 620;
export const HEADER_FOOTER_HEIGHT = 44;

/**
 * These are the dimensions of the new browser window
 * which opens to show the third party login page
 *
 * The popup's width has to be at least medium in size to
 * not break messaging web because it actually renders
 * the native messaging when window size is small which will
 * prevent the redirect route from being hit
 */
export const [POPUP_WIDTH, POPUP_HEIGHT] = [
  WindowSizeMin.Medium,
  WindowSizeMin.Medium,
];
