import KitSection from '@omni/kit/components/KitSection';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import React from 'react';
import { View } from 'react-native';

import BlockPageNested from '../BlockPageNested';
import { IBlockProps } from './types';

export interface SectionBlockProps extends IBlockProps {
  title?: string;
  blocks?: [];
}

const SectionBlock = ({
  title,
  blocks,
  topSpacing,
  bottomSpacing,
  horizontalSpacing,
}: SectionBlockProps): JSX.Element => {
  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });
  const { sizeClass } = useSizeClass();
  const addSpacing = sizeClass !== SizeClass.Small;

  const viewStyle = {
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
    ...(addSpacing && { marginHorizontal: edgeSpacing }),
  };

  return (
    <View style={viewStyle}>
      <KitSection title={title}>
        <BlockPageNested data={{ blocks: blocks }} />
      </KitSection>
    </View>
  );
};

export default SectionBlock;
