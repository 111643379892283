import { KitText } from '@omni/kit/components';
import { getQueryParams } from '@omni/kit/utilities/utilities';
import React from 'react';
import { Platform, View } from 'react-native';
import { useHistory, useLocation } from 'react-router';

import { AuthService } from './index';

const debug = require('debug');
const appDebug = debug('omni:liveChat:AuthScreen');

interface IProps {
  authProviderId: string;
  redirectUrl: string;
  token?: string;
  reloadPage?: boolean;
}

export default ({
  authProviderId,
  redirectUrl,
  token,
  reloadPage,
}: IProps): JSX.Element => {
  appDebug('AuthRedirect');
  let queryParams;

  try {
    const location = useLocation();
    queryParams = getQueryParams(location);
  } catch {}

  const accessToken = queryParams?.access_token;
  const refreshToken = queryParams?.sap_token;

  token = token || accessToken;

  if (token) {
    appDebug('Token retrieved');
  } else {
    appDebug('No token found');
  }

  AuthService.setAccessToken(
    authProviderId,
    token?.replace('Bearer ', '') || null
  );

  if (refreshToken) {
    AuthService.refreshToken = refreshToken;
  }

  AuthService.notifyAuthorizationChanged();

  const history = useHistory();
  history.replace(redirectUrl);

  if (Platform.OS === 'web' && window.opener) {
    const parentWindow = window.opener;

    if (!parentWindow.reloadAuth) reloadPage = true;

    appDebug('Reload parent window');
    if (parentWindow.reloadAuth && !reloadPage) {
      parentWindow.reloadAuth({ accessToken: token, refreshToken });
    } else {
      parentWindow.location.reload();
    }

    appDebug('Close login popup');
    window.close();
  }

  return (
    <View>
      <KitText>Redirecting..</KitText>
    </View>
  );
};
