import { Platform } from 'react-native';

import TrackMediaProgressState from './TrackMediaProgressState';

/**
 * Cancel polling for ios
 */
export default (): void => {
  if (Platform.OS !== 'ios') return;

  if (TrackMediaProgressState.pid) clearInterval(TrackMediaProgressState.pid);

  TrackMediaProgressState.pid = null;
};
