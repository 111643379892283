import { BibleLocation, BibleSettings } from '../types';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { ContentReducerState } from '../redux/reducers/ContentReducer';

export const saveGlobalDataToDevice = async (
  key: string,
  value: unknown
): Promise<void> => {
  await AsyncStorage.setItem(key, JSON.stringify(value));

  return;
};

export const retrieveGlobalDataFromDevice = async (
  key: string
): Promise<
  BibleLocation | BibleSettings | ContentReducerState | Record<string, unknown>
> => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);

    if (jsonValue !== null) {
      return JSON.parse(jsonValue) as Record<string, unknown>;
    }

    throw { message: `data with key of *${key}* not found on device` };
  } catch (error) {
    throw error;
  }
};

export const removeGlobalDataFromDevice = async (
  key: string
): Promise<void> => {
  try {
    await AsyncStorage.removeItem(key);

    return;
  } catch (error) {
    throw error;
  }
};

const MobileStorage = {
  removeGlobalDataFromDevice,
  retrieveGlobalDataFromDevice,
  saveGlobalDataToDevice,
};

export default MobileStorage;
