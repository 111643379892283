import KitIcon from '@omni/kit/components/KitIcon';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import Colors from '@omni/kit/theming/Colors';
import { SpacingType, getSpacing } from '@omni/kit/theming/SpacingType';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';

import { IBlockProps } from './types';

const debug = require('debug')('tca:blocks:IconBlock');

export interface IIconBlockProps extends IBlockProps {
  align?: 'start' | 'end' | 'center';
  icon: string;
  iconColor: string;
  iconSize: number;
  topPadding: SpacingType;
  bottomPadding: SpacingType;
}

/** Supported in release 6.2.0+ */
export default function IconBlock(props: IIconBlockProps): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);

  const {
    align,
    insetStyle = {},
    icon = '',
    iconColor = colorForScheme?.({
      light: Colors.N1000,
      dark: Colors.N0,
      default: Colors.N0,
    }) ?? Colors.N0,
    iconSize = 26,
    topSpacing = null,
    bottomSpacing = null,
    topPadding = null,
    bottomPadding = null,
    horizontalSpacing,
  } = props;

  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const viewStyle = {
    insetStyle,
    paddingTop: getSpacing(topSpacing),
    paddingBottom: getSpacing(bottomSpacing),
  };

  const containerStyle = {
    paddingTop: getSpacing(topPadding),
    paddingBottom: getSpacing(bottomPadding),
  };

  return (
    <View style={viewStyle}>
      <View
        style={[
          styles.container,
          containerStyle,
          {
            marginHorizontal: edgeSpacing,
            ...(align === 'center' && { alignSelf: 'center' }),
            ...(align === 'start' && { alignSelf: 'flex-start' }),
            ...(align === 'end' && { alignSelf: 'flex-end' }),
          },
        ]}
      >
        {/* @ts-ignore */}
        <KitIcon name={icon} size={iconSize} color={iconColor} />
      </View>
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});
