import React, { createContext, useContext, useEffect, useState } from 'react';
import { NativeEventEmitter, NativeModules } from 'react-native';

import { IRootProps } from '../types';

export const ScreenVisibilityContext = createContext<boolean>(true);

interface ProviderProps extends IRootProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const ScreenVisibilityContextProvider = ({
  children,
  ...props
}: ProviderProps): null | JSX.Element => {
  const [isScreenVisible, setIsScreenVisible] = useState<boolean>(true);

  useEffect(() => {
    const { ReactPlatformBridge } = NativeModules;

    if (!ReactPlatformBridge) return;

    const ReactPlatformBridgeEventEmitter = new NativeEventEmitter(
      ReactPlatformBridge
    );

    const visibilityChangesListener =
      ReactPlatformBridgeEventEmitter.addListener(
        'ScreenVisibilityChanged',
        (data) => {
          if (data.nativeEventKey === props?.nativeEventKey) {
            setIsScreenVisible(data.visible);
          }
        }
      );

    return () => {
      visibilityChangesListener?.remove();
    };
  }, [props?.nativeEventKey]);

  return (
    <ScreenVisibilityContext.Provider value={isScreenVisible}>
      {children}
    </ScreenVisibilityContext.Provider>
  );
};

export const useScreenVisibility = (): boolean => {
  return useContext<boolean>(ScreenVisibilityContext);
};
