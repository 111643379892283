import { Platform, ScrollView, StyleSheet, View } from 'react-native';

import { ChannelMember } from '../../Types';
import Colors from '@omni/kit/theming/Colors';
import MentionListRow from './MentionListRow';
import React from 'react';
import { SCREEN_WIDTH } from '@omni/kit/utilities/utilities';
import Spacing from '@omni/kit/theming/Spacing';

interface MentionListProps {
  list: ChannelMember[];
  mentionMember: (member: ChannelMember) => void;
}

export default function MentionList({
  list = [],
  mentionMember,
}: MentionListProps): JSX.Element | null {
  return list.length ? (
    <View style={[styles.wrapper]}>
      <ScrollView
        style={{ maxHeight: SCREEN_WIDTH > 370 ? 225 : 125 }}
        contentContainerStyle={{ paddingVertical: Spacing.m }}
        keyboardShouldPersistTaps='always'
      >
        {list.map((member) => (
          <MentionListRow
            key={member.id}
            member={member}
            onPress={mentionMember}
          />
        ))}
      </ScrollView>
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  wrapper: {
    alignSelf: 'center',
    backgroundColor: Colors.N0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    bottom: 0,
    position: 'absolute',
    width: '80%',
    ...Platform.select({
      android: {
        elevation: 10,
        overflow: 'hidden',
      },
      default: {
        shadowColor: '#000000',
        shadowOffset: { height: 2, width: 0 },
        shadowOpacity: 0.08,
        shadowRadius: 20,
      },
    }),
    zIndex: 1,
  },
});
