import moment from 'moment';

export interface isEditedProps {
  firstNameValue: string;
  lastNameValue: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberValue: string;
  image: string;
  profilePhotoUrlValue: string;
  dateOfBirth: string;
  dateOfBirthValue: string;
  addressLine1: string;
  addressLine1Value: string;
  addressLine2: string;
  addressLine2Value: string;
  city: string;
  cityValue: string;
  state: string;
  stateValue: number | string;
  country: string;
  countryValue: number | string;
  postalCode: string;
  postalCodeValue: string;
  hasExtraFields: boolean;
}

export const PERCENT_IN_WHOLE = 100;

export interface progressProps {
  phoneNumber: string;
  addressLine1: string;
  dateOfBirth: string;
  city: string;
  postalCode: string;
  country: string;
  state: string;
}

export const isAccountEdited = ({
  addressLine1,
  addressLine1Value,
  addressLine2,
  addressLine2Value,
  city,
  cityValue,
  country,
  countryValue,
  dateOfBirth,
  dateOfBirthValue,
  firstName,
  firstNameValue,
  hasExtraFields,
  image,
  lastName,
  lastNameValue,
  phoneNumber,
  phoneNumberValue,
  postalCode,
  postalCodeValue,
  profilePhotoUrlValue,
  state,
  stateValue,
}: isEditedProps): boolean => {
  if (hasExtraFields) {
    // Do not show the Save button if first name or last name fields are empty. Both of them are required.
    if (!((firstNameValue || '').trim() && (lastNameValue || '').trim())) {
      return false;
    }

    return (
      (Boolean(firstNameValue) &&
        Boolean(lastNameValue) &&
        (firstNameValue.trim() !== firstName ||
          lastNameValue.trim() !== lastName) &&
        firstNameValue.trim() !== '' &&
        lastNameValue.trim() !== '') ||
      phoneNumber !== sanitizePhoneNumber(phoneNumberValue) ||
      image !== profilePhotoUrlValue ||
      moment(dateOfBirth).format('MM/DD/YYYY') !==
        moment(dateOfBirthValue, 'MM/DD/YYYY').format('MM/DD/YYYY') ||
      addressLine1 !== addressLine1Value ||
      addressLine2 !== addressLine2Value ||
      city !== cityValue ||
      state !== stateValue ||
      country !== countryValue ||
      postalCode !== postalCodeValue
    );
  }

  return (
    Boolean(firstNameValue) &&
    Boolean(lastNameValue) &&
    (firstNameValue.trim() !== firstName ||
      lastNameValue.trim() !== lastName) &&
    firstNameValue.trim() !== '' &&
    lastNameValue.trim() !== ''
  );
};

const COMMON_COUNTRIES_WITH_SUBDIVISIONS = ['AU', 'CA', 'UK', 'US'];

export const stateIsPresentIfCountryRequiresIt = (
  country?: string | number,
  state?: string | number
): boolean =>
  !COMMON_COUNTRIES_WITH_SUBDIVISIONS.includes(country as string) ||
  Boolean(state);

export const isValidAddress = (
  country?: string | number,
  addressLine1?: string,
  city?: string,
  state?: string | number,
  postalCode?: string
): boolean => {
  return Boolean(
    ((typeof country === 'string' && country.trim()) ||
      typeof country === 'number') &&
      addressLine1?.trim() &&
      city?.trim() &&
      stateIsPresentIfCountryRequiresIt(country, state) &&
      postalCode?.trim()
  );
};

export const calculateProgressNumber = ({
  addressLine1,
  city,
  country,
  dateOfBirth,
  phoneNumber,
  postalCode,
  state,
}: progressProps): number => {
  /**
   * If chosen country is not on this list, don't require state in order to
   * achieve full progress completion.  This is not an exhaustive list, but it
   * covers most use cases, is extendable, and avoids making unnecessary network
   * calls to determine if a country requires a state/province.
   */
  const progressSteps = [
    addressLine1,
    city,
    country,
    dateOfBirth,
    phoneNumber,
    postalCode,
  ];

  if (COMMON_COUNTRIES_WITH_SUBDIVISIONS.includes(country)) {
    progressSteps.push(state);
  }

  const completedSteps = progressSteps.filter(Boolean);

  return Math.ceil(
    (completedSteps.length / progressSteps.length) * PERCENT_IN_WHOLE
  );
};

/** Validate birthday is before today and after 1900. */
export const checkBirthdayValidation = (dateOfBirthValue: string): boolean => {
  if (!dateOfBirthValue) return true;

  const now = moment().format();
  const currentDate = moment(dateOfBirthValue, 'MM/DD/YYYY').format();
  const lowestDate = moment('01/01/1900', 'MM/DD/YYYY').format();

  if (currentDate < now && currentDate > lowestDate) {
    return moment(dateOfBirthValue, 'MM/DD/YYYY', true).isValid();
  }

  return false;
};

/** Validate phone number is between 10 to 15 digits. */
export const checkPhoneValidation = (phoneNumber: string): boolean => {
  const phoneRegex = /^[1-9][0-9]{9,14}$/;
  const sanitized = sanitizePhoneNumber(phoneNumber);

  if (!sanitized) return true;

  if (sanitized.match(phoneRegex)) return true;

  return false;
};

/**
 * Format phone numbers into a readable/recognizable format. Phone numbers from
 * outside of North America will be incorrectly formatted for end users, this
 * is a known shortcoming of this approach.
 *
 * 2345556789 > (234) 555-6789
 * 12345556789 > +1 (234) 555-6789
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
  const sanitized = sanitizePhoneNumber(phoneNumber);
  const usPhoneLength = 10;

  if (sanitized.length === usPhoneLength) {
    return sanitized.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  if (sanitized.length === usPhoneLength + 1) {
    return sanitized.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
  }

  return sanitized;
};

/** Normalize phone string and remove all non-numeric characters. */
export const sanitizePhoneNumber = (phoneNumber: string): string =>
  phoneNumber.replace(/\D/g, '');
