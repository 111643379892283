import Environment from '../../../Environment';
import { IAddress, IEndUser, IHousehold, IPhoto, IProfile } from '../Types';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.peopleService;
const RESOURCE = 'profiles';

interface IUpdateProfile extends Omit<IUri, 'resource'> {
  id?: string;
  body: Omit<IProfile, '_embedded'> & {
    _embedded?: {
      address?: IAddress;
      household?: IHousehold | null;
      'end-user'?: IEndUser;
      photo?: IPhoto;
    };
  };
  token: string;
}

interface IUpdateProfileResponse extends IHttpResponse {
  body?: IProfile;
}

export default ({
  id,
  body,
  token,
}: IUpdateProfile): Promise<IUpdateProfileResponse> => {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    id,
  });

  return BaseServiceV2.Patch({ url, token, data: body });
};
