import { QUERY_TUPLE, SEARCH_QUERY_STRING } from '.';
import Environment from '../../Environment';
import { buildUrl } from '../BaseServiceV2';
import { DEFAULT_PAGE_SIZE, DOMAIN_GROUPS } from './Constants';

const host = Environment.searchHost;

export interface IPrepareHitsUrl {
  appFilterValue?: string;
  domain?: string;
  filterQuery?: string;
  queryOptions?: string;
  containerAppKey?: string;
  hasChannel?: boolean;
  page: number;
  query?: string;
  scripture?: string;
  size?: number;
  sort?: string;
  tag?: string;
  type?: string[];
  token?: string;
}

export function prepareHitsUrl({
  domain = 'library',
  containerAppKey,
  appFilterValue,
  filterQuery = `{"app_keys":"${appFilterValue}"}`,
  queryOptions,
  page,
  size = DEFAULT_PAGE_SIZE,
  sort,
  tag,
  scripture,
  query,
  hasChannel = true,
  type = [],
}: IPrepareHitsUrl): string {
  const queries: QUERY_TUPLE[] = [
    [SEARCH_QUERY_STRING.D, domain],
    [SEARCH_QUERY_STRING.SIZE, `${size}`],
    [SEARCH_QUERY_STRING.START, `${(page - 1) * size}`],
    [SEARCH_QUERY_STRING.FQ, filterQuery],
  ];

  // ref: https://subsplash.io/go/search/-/blob/main/internal/handlers/app_handler.go#L40
  if (containerAppKey) {
    queries.push([SEARCH_QUERY_STRING.C, containerAppKey]);
  }

  /** Set channel */
  if (hasChannel) {
    queries.push([SEARCH_QUERY_STRING.CHANNELS, 'mobile']);
  }

  /** Set type */
  if (type?.length) {
    queries.push([SEARCH_QUERY_STRING.TYPE, type.join(',')]);
  }

  if (sort) {
    queries.push([SEARCH_QUERY_STRING.SORT, sort]);
  } else if (query) {
    /** Set query */
    queries.push([SEARCH_QUERY_STRING.Q, query]);

    if (domain === 'library') {
      queries.push([
        SEARCH_QUERY_STRING.SORT,
        '_score desc,date desc,created_at desc',
      ]);

      if (query !== 'matchall') {
        queries.push([
          SEARCH_QUERY_STRING.Q_OPTIONS,
          '{ fields: ["tag_title^6","title^5","subtitle^3","speaker^2"] }',
        ]);
      }
    }

    if (domain === DOMAIN_GROUPS && query === 'matchall') {
      queries.push([SEARCH_QUERY_STRING.Q_PARSER, 'structured']);
    }

    if (domain === 'people' && queryOptions) {
      queries.push([SEARCH_QUERY_STRING.Q_OPTIONS, queryOptions]);
    }
  }

  /** Set scripture */
  if (scripture) {
    queries.push(
      [SEARCH_QUERY_STRING.SCRIPTURE, scripture],
      [SEARCH_QUERY_STRING.SORT, 'date desc,created_at desc']
    );
  }

  /** Set tag (speaker, topic) */
  if (tag) {
    queries.push(
      [SEARCH_QUERY_STRING.TAG, tag],
      [SEARCH_QUERY_STRING.SORT, 'date desc,created_at desc']
    );
  }

  return buildUrl({
    host,
    resource: 'hits',
    queries,
  });
}
