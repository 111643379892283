import BaseServiceV2, { IHttpResponse, IUri, buildUrl } from '../BaseServiceV2';

import Environment from '../../Environment';

const HOST = Environment.feedsService;
const RESOURCE = 'featured-apps';

export interface IFeaturedApp {
  app_key?: string;
  title?: string;
  type: string;
}

interface IGetFeaturedApps extends IUri {
  appKey: string;
}

interface IGetFeatureAppsResponse extends IHttpResponse {
  body?: IFeaturedApp[];
}

export default function ({
  appKey,
}: IGetFeaturedApps): Promise<IGetFeatureAppsResponse> {
  const url = buildUrl({ host: HOST, resource: RESOURCE, id: appKey });

  return BaseServiceV2.Get({
    url,
  });
}
