import { AuthService } from '@omni/kit/auth';
import { getUserProfile } from '@omni/kit/services/FeedsService';
import { IUserProfileInfo } from '@omni/kit/services/FeedsService/GetUserProfile';

import { FALLBACK_APPKEY } from './constants';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:FetchUserProfile'
);

/**
 * Fetch end user resource
 * @param appKey {string}
 */
export default async (appKey = FALLBACK_APPKEY): Promise<IUserProfileInfo> => {
  try {
    const userProfileResponse = await getUserProfile({
      appKey,
      token: AuthService.accessTokenWithBearer || '',
    });
    const userProfile = userProfileResponse.body as IUserProfileInfo;

    debug('fetched user profile', userProfile);

    return userProfile;
  } catch (e) {
    const errorMessage = 'failed to fetch user profile';
    debug(errorMessage, e);

    throw new Error(errorMessage);
  }
};
