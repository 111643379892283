import React from 'react';

import BlockPageContent from './BlockPageContent';
import { BlockPageContextProvider } from './BlockPageContext';
import { BlockPageProps } from './types';

const debug = require('debug')('tca:blocks:BlockPage');

export default function BlockPage(props: BlockPageProps): JSX.Element {
  return (
    <BlockPageContextProvider props={props}>
      <BlockPageContent {...props} />
    </BlockPageContextProvider>
  );
}
