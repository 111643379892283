import React, { useState } from 'react';

import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { View } from 'react-native';

interface ActionButtonProps {
  as?: 'div';
  children?: React.ReactNode;
  width?: number;
  height?: number;
  padding?: any;
  borderRadius: number;
  onPress?: () => void;
  style?: any;
  active?: boolean;
}

const ActionButtonWeb = React.forwardRef(
  (
    {
      as = 'div',
      children,
      width,
      height,
      padding,
      borderRadius,
      onPress,
      active,
      style = {},
      ...rest
    }: ActionButtonProps,
    ref: any
  ) => {
    const [isPressed, setIsPressed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    return (
      <View
        //  @ts-ignore
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={() => {
          setIsPressed(true);
        }}
        onMouseUp={() => {
          setIsPressed(false);
        }}
        onMouseOut={() => setIsPressed(false)}
        onClick={onPress}
        ref={ref}
        style={[
          styles.actionButton,
          {
            width: width,
            height: height,
            padding: padding,
            backgroundColor:
              isPressed || active
                ? Colors.N100
                : isHovered
                ? Colors.N50
                : Colors.N0,
            transform: isPressed ? [{ scale: 0.95 }] : [{ scale: 1 }],
            borderRadius: borderRadius,
          },
          style,
        ]}
        {...rest}
      >
        {children}
      </View>
    );
  }
);

const styles = {
  actionButton: {
    display: 'flex',
    position: 'relative',
    borderWidth: 0,
    marginRight: Spacing.s,
    cursor: 'pointer',
  },
};

export default ActionButtonWeb;
