import React, { createContext, useContext, useEffect, useState } from 'react';
import { UnleashClient } from 'unleash-proxy-client';

import AccountsService from '../../services/AccountsService';

const debug = require('debug')('omni:kit:context:FeatureFlagContext');

interface UnleashContextProps {
  client?: UnleashClient;
  isClientReady: boolean;
}

const UnleashContext = createContext<UnleashContextProps>({
  client: undefined,
  isClientReady: false,
});

interface ProviderProps {
  appKey?: string;
  children: React.ReactNode;
  unleashInstance: UnleashClient;
}

/**
 * A basic implementation based on unleash react library.
 * https://github.com/Unleash/proxy-client-react
 */
export const FeatureFlagProvider = ({
  appKey,
  children,
  unleashInstance,
}: ProviderProps): JSX.Element => {
  const [isClientReady, setIsClientReady] = useState<boolean>(false);

  const onReady = () => {
    setIsClientReady(true);
  };

  const onError = (e: any) => {
    debug('Failed onError ', e);
  };

  const setupUnleash = async () => {
    try {
      const app = await AccountsService.getAppWithBranding(appKey as string);
      const orgKey = app?.org_key;

      await unleashInstance.updateContext({
        ...(orgKey && { properties: { orgKey } }),
        ...(appKey && { properties: { appKey } }),
      });

      unleashInstance.on('ready', onReady);
      unleashInstance.on('error', onError);

      await unleashInstance.start();

      setIsClientReady(true);
    } catch (e) {
      debug('Failed setup ', e);
    }
  };

  useEffect(() => {
    if (!appKey) return;

    unleashInstance?.stop();
    setupUnleash();

    return () => {
      unleashInstance.off('ready', onReady);
      unleashInstance.off('error', onError);
    };
  }, [appKey]);

  return (
    <UnleashContext.Provider value={{ client: unleashInstance, isClientReady }}>
      {children}
    </UnleashContext.Provider>
  );
};

export const useUnleash = (): UnleashContextProps => {
  return useContext(UnleashContext);
};
