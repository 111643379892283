import GetAppHeaders from '@omni/kit/Downloader/GetAppHeaders';
import Environment from '@omni/kit/Environment';
import AccountsService from '@omni/kit/services/AccountsService';
import { IQuery, buildUrl } from '@omni/kit/services/BaseServiceV2';
import TokensService from '@omni/kit/services/TokensService/TokensService';
import { Platform } from 'react-native';

import { AuthProvider } from './Types';

interface Props {
  appKey?: string;
  orgKey?: string;
}

const debug = require('debug')('tca:auth:fetchAuthProviders');

export const fetchAuthProviders = async ({
  appKey,
  orgKey,
}: Props): Promise<AuthProvider[]> => {
  if (!appKey && !orgKey) {
    return [];
  }

  if (!orgKey && Platform.OS === 'web') {
    try {
      const app = await AccountsService.getAppWithBranding(appKey ?? '');
      orgKey = app?.org_key;
    } catch (e) {
      debug(e);
    }
  }

  try {
    const queries: IQuery[] = [];

    if (orgKey && Platform.OS === 'web') {
      queries.push(['org_key', orgKey]);
    } else {
      queries.push(['app_key', appKey]);
    }

    // required for backend filtering + sorting in go/feeds
    const appHeaders = await GetAppHeaders(appKey);

    /**
     * We must use 'fetch' here instead of 'FeedsService.get' to avoid
     * recursive dependency on content_domain validation.
     * This is just a one-off special case. Normally requests should use
     * either BaseService or downloadUrl for requests in order to
     * leverage logic around request headers, domain validation and caching.
     */
    const url = buildUrl({
      host: Environment.feedsService,
      resource: 'auth-providers',
      queries,
    });

    const guestToken = await TokensService.getGuestToken();

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        ...appHeaders,
        Authorization: 'Bearer ' + guestToken,
      },
    });

    if (response.ok) {
      try {
        const responseBody = (await response.json()) || [];

        return responseBody as AuthProvider[];
      } catch (error) {
        debug(`Error parsing auth providers: ${error}`);
      }
    }
  } catch (error) {
    debug(`Error fetching auth providers:${error}`);
  }

  return [];
};
