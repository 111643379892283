import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import ActionItem from '../ActionItem';
import Colors from '@omni/kit/theming/Colors';
import { IProps } from './types';
import { KitIcon } from '@omni/kit/components';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import Spacing from '@omni/kit/theming/Spacing';
import Tooltip from '@omni/kit/components/Tooltip';
import { useChatContext } from '../../context/ChatContext';
import { useTranslation } from 'react-i18next';

export default ({ children, message }: IProps): JSX.Element => {
  const { t } = useTranslation(['liveChat']);
  const { isOperator, onDeleteMessage, setSelectedMessage } = useChatContext();
  const [isMessageHover, setIsMessageHover] = useState<boolean>(false);
  const [hoverIcon, setHoverIcon] = useState<string>('');
  const menuRef = useRef<PopupActions | null>(null);

  const onMouseEnter = () => setIsMessageHover(true);
  const onMouseLeave = () => setIsMessageHover(false);

  const handleOnPress = () => {
    setSelectedMessage(message);
    onDeleteMessage?.();
    menuRef.current?.close();
  };

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.close();
    }
  }, [isMessageHover]);

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <View>
        {isOperator && (
          <div style={webStyles(isMessageHover).actionsWrapper}>
            <View style={[styles.actions]}>
              <Popup
                arrow={false}
                on='click'
                position='bottom right'
                ref={menuRef}
                trigger={
                  <button style={webStyles().actionBtn}>
                    <Tooltip
                      label={t('liveChat:messageMoreActionTooltip')}
                      position='top right'
                    >
                      <div
                        style={
                          webStyles(false, hoverIcon === 'action').hoverIcon
                        }
                        onMouseEnter={() => setHoverIcon('action')}
                        onMouseLeave={() => setHoverIcon('')}
                      >
                        <KitIcon
                          name='action-h'
                          size={18}
                          color={Colors.N900}
                        />
                      </div>
                    </Tooltip>
                  </button>
                }
              >
                <div>
                  <View
                    style={[
                      styles.popupWrapper,
                      { paddingVertical: Spacing.m },
                    ]}
                  >
                    <ActionItem
                      iconName={'delete'}
                      iconSize={22}
                      label={t('liveChat:deleteMessageActionName')}
                      onPress={handleOnPress}
                    />
                  </View>
                </div>
              </Popup>
            </View>
          </div>
        )}
        <View
          style={{
            backgroundColor:
              isMessageHover && isOperator ? Colors.N50 : 'transparent',
            zIndex: 2,
          }}
        >
          {children}
        </View>
      </View>
    </div>
  );
};

const styles = StyleSheet.create({
  actions: {
    alignItems: 'center',
    backgroundColor: Colors.N0,
    borderColor: 'rgba(0,0,0,.04)',
    borderRadius: 60,
    borderWidth: 1,
    flexDirection: 'row',
    padding: Spacing.xxs,
    shadowColor: Colors.N1000,
    shadowOffset: { height: 3, width: 1 },
    shadowOpacity: 0.04,
    shadowRadius: 3,
  },
  popupWrapper: {
    backgroundColor: Colors.N0,
    borderRadius: 14,
    paddingHorizontal: Spacing.l,
    shadowColor: Colors.N1000,
    shadowOffset: { height: 3, width: 0 },
    shadowOpacity: 0.06,
    shadowRadius: 6,
  },
});

const webStyles = (isMessageHover = false, isIconHover = false) => ({
  actionBtn: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    cursor: 'pointer',
    padding: 0,
  },
  actionsWrapper: {
    alignSelf: 'flex-end',
    height: Spacing.xl,
    marginBottom: -Spacing.l,
    marginRight: Spacing.l,
    opacity: isMessageHover ? 1 : 0,
    zIndex: 3,
  },
  hoverIcon: {
    alignItems: 'center',
    backgroundColor: isIconHover ? Colors.N50 : 'transparent',
    borderRadius: 60,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    width: 30,
  },
});
