import Environment from '../../../Environment';
import { IHouseholdRelationship } from '../Types';
import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.peopleService;
const RESOURCE = 'household-relationships';

interface IGetHouseholdRelationship extends IUri {
  token?: string;
  filters: IFilter[];
  id?: string;
}

interface IGetHouseholdRelationshipResponse extends IHttpResponse {
  body?: {
    count: number;
    _embedded: { 'household-relationships': IHouseholdRelationship[] };
  };
}

export default async function ({
  token,
  ...buildUrlProps
}: IGetHouseholdRelationship): Promise<IGetHouseholdRelationshipResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
  });
}
