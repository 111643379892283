import Environment from '@omni/kit/Environment';
import BaseServiceV2 from '@omni/kit/services/BaseServiceV2';
import {
  REQUEST_ERROR,
  validateResponse,
} from '@omni/kit/utilities/requestError';

import { ITokenResponse, ITokenResponseBody } from '../Types';

export const RefreshToken = async (props?: {
  device_id?: string;
  app_key?: string;
  org_key?: string;
  refresh_token?: string;
}): Promise<ITokenResponseBody | undefined> => {
  const device_id = props?.device_id;
  const app_key = props?.app_key;
  const org_key = props?.org_key;
  const refresh_token = props?.refresh_token;

  if (!device_id) {
    throw new Error(REQUEST_ERROR.BAD_REQUEST);
  }

  if (!app_key && !org_key) {
    throw new Error(REQUEST_ERROR.BAD_REQUEST);
  }

  if (!refresh_token) {
    throw new Error(REQUEST_ERROR.BAD_REQUEST);
  }

  let result: ITokenResponse | undefined;

  try {
    const url = `${Environment.checkInService}/token`;
    const data = {
      device_id,
      app_key,
      org_key,
      refresh_token,
    };

    result = await BaseServiceV2.Post({ url, data });
  } catch (e) {
    throw new Error(REQUEST_ERROR.NO_INTERNET);
  }

  validateResponse(result?.status, result?.headers);

  return result?.body;
};
