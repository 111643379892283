import { KitModal, KitText } from '@omni/kit/components';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Time, getSnoozeUntilText } from '../../../utilities/dateUtilities';

import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';

type SnoozeDuration = '30m' | '1h' | '2h' | '24h' | 'manual';

interface SnoozeNotificationsActionSheetProps {
  isActionSheetVisible: boolean;
  isSnoozed: boolean;
  setIsActionSheetVisible: (visible: boolean) => void;
  setSnoozePeriod?: (
    isSnoozed: boolean,
    startMs: number,
    endMs: number
  ) => Promise<void>;
  snoozedUntil?: number | null;
}

// @ts-ignore
const SnoozeNotificationsActionSheet: React.FC<
  SnoozeNotificationsActionSheetProps
> = ({
  isActionSheetVisible,
  isSnoozed,
  setIsActionSheetVisible,
  setSnoozePeriod,
  snoozedUntil,
}) => {
  const snoozedUntilStr = useMemo(() => {
    return snoozedUntil ? getSnoozeUntilText(snoozedUntil) : '';
  }, [snoozedUntil]);

  const _handleSnoozeOn = async (duration: SnoozeDuration) => {
    const start = Date.now();
    let end = start;

    switch (duration) {
      case '30m':
        end = start + 30 * Time.Minute;
        break;
      case '1h':
        end = start + Number(Time.Hour);
        break;
      case '2h':
        end = start + 2 * Time.Hour;
        break;
      case '24h':
        end = start + 24 * Time.Hour;
        break;
      case 'manual':
        /**
         * SB does not have a "Manual" option so we set the end date to 1 year
         * after today if this option is selected.
         */
        end = start + 365 * Time.Day;
        break;
    }

    setSnoozePeriod?.(true, start, end);
    setIsActionSheetVisible(false);
  };

  const _handleSnoozeOff = async () => {
    const now = Date.now();
    setSnoozePeriod?.(false, now, now);
    setIsActionSheetVisible(false);
  };

  return (
    <KitModal
      visible={isActionSheetVisible}
      setVisible={setIsActionSheetVisible}
    >
      <View
        style={styles.actionSheetContainer}
        testID='snooze-notifications-action-sheet'
      >
        {isSnoozed ? (
          <>
            <KitText
              fontSize={14}
              gray
              style={styles.actionSheetParagraphText}
              testID='snoozed-until-text'
            >
              Notifications silenced until {snoozedUntilStr}
            </KitText>
            <TouchableOpacity
              style={styles.actionSheetButton}
              onPress={() => _handleSnoozeOff()}
              testID='snooze-off'
            >
              <KitText fontSize={16} black>
                Turn on notifications
              </KitText>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <KitText fontSize={14} gray style={styles.actionSheetParagraphText}>
              Silence notifications
            </KitText>
            <TouchableOpacity
              style={styles.actionSheetButton}
              onPress={() => _handleSnoozeOn('30m')}
              testID='snooze-30m'
            >
              <KitText fontSize={16} black>
                30 minutes
              </KitText>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.actionSheetButton}
              onPress={() => _handleSnoozeOn('1h')}
              testID='snooze-1h'
            >
              <KitText fontSize={16} black>
                1 hour
              </KitText>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.actionSheetButton}
              onPress={() => _handleSnoozeOn('2h')}
              testID='snooze-2h'
            >
              <KitText fontSize={16} black>
                2 hours
              </KitText>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.actionSheetButton}
              onPress={() => _handleSnoozeOn('24h')}
              testID='snooze-tomorrow'
            >
              <KitText fontSize={16} black>
                24 hours
              </KitText>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.actionSheetButton}
              onPress={() => _handleSnoozeOn('manual')}
              testID='snooze-manual'
            >
              <KitText fontSize={16} black>
                Until I turn them back on
              </KitText>
            </TouchableOpacity>
          </>
        )}
      </View>
    </KitModal>
  );
};

const styles = StyleSheet.create({
  actionSheetButton: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    marginVertical: Spacing.l,
  },
  actionSheetContainer: {
    marginHorizontal: Spacing.l,
    paddingBottom: Spacing.xl,
  },
  actionSheetParagraphText: {
    color: Colors.N500,
    fontSize: 14,
    marginTop: Spacing.xl,
    marginVertical: 8,
  },
});

export default SnoozeNotificationsActionSheet;
