import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';

import { minDelay } from '../../utilities/minDelay';
import KitAnimatedLoader from '../KitAnimatedLoader';
import KitList from '../KitList';
import { IInfiniteScrollProps } from './types';

const debug = require('debug')('tca:search:components:InfiniteScroll.tsx');

const LOADING_ANIMATION_DURATION = 500; /** 500 ms */

let minDelayTimeoutId: NodeJS.Timeout | undefined;
let onEndReachedDisabled = false;

/**
 * A specialized KitList designed to automatically fetch more data for the list
 * when user scrolls to the end.
 */
export default <T,>({
  initialData = [],
  renderItem,
  headerComponent,
  footerComponent,
  onFetchData,
  hasMoreItems,
  numColumns = 1,
  onScroll,
  scrollEventThrottle = 0,
  dedupBy,
}: IInfiniteScrollProps<T>): JSX.Element => {
  const [aggregatedData, setAggregatedData] = useState<T[]>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFetch = async () => {
    const startTime = new Date().getTime();
    const fetchedData = (await onFetchData()) || [];

    minDelayTimeoutId = minDelay({
      start: startTime,
      callback: () => {
        setIsLoading(false);
        setAggregatedData((prevState) => {
          const data = [...prevState, ...fetchedData];

          if (dedupBy) return _.uniqBy(data, dedupBy);

          return data;
        });
      },
    });
  };

  const onEndReached = () => {
    /**
     * Prevent the hiding of loading spinner animation from triggering onEndReached repeatedly.
     * Prevent next page if there are no more items
     */
    if (onEndReachedDisabled) return;

    if (!hasMoreItems) return debug('There are no more items.');

    onEndReachedDisabled = true;
    setIsLoading(true);
    debug('onEndReached disabled');

    onFetch();
  };

  const props = {
    data: aggregatedData,
    onScroll,
    renderItem,
    keyExtractor: (_: T, index: number) => `${numColumns}:${index.toString()}`,
    key: `${numColumns}`, // prevent redbox error after changing orientation on tablet
    numColumns,
    scrollEventThrottle,
    keyboardShouldPersistTaps: 'handled',
    onEndReachedThreshold: 0.1,
    onEndReached,
    onScrollBeginDrag: Keyboard.dismiss,
    ListHeaderComponent: headerComponent,
    ListFooterComponent: footerComponent ?? (
      <KitAnimatedLoader
        show={isLoading}
        animationDuration={LOADING_ANIMATION_DURATION}
        callback={() => {
          /**
           * enable onEndReached method once the loading is done
           * and the loading animation is fully complete
           */
          if (!isLoading) {
            onEndReachedDisabled = false;
            debug('onEndReached enabled');
          }
        }}
      />
    ),
  };

  useEffect(() => {
    return () => {
      if (minDelayTimeoutId) clearTimeout(minDelayTimeoutId);
    };
  }, []);

  useEffect(() => {
    setAggregatedData(initialData);
  }, [initialData]);

  // @ts-ignore
  return <KitList {...props} />;
};
