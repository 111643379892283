import Axios, { AxiosRequestConfig } from 'axios';
import { Platform } from 'react-native';

import Environment, { ENV } from '../../Environment';
import { SharedAnalyticsHeaderOverrides } from './SetAnalyticsHeaderOverrides';
import { IAnalyticsData } from './Types';
import { AnalyticsType } from './Types';

const debug = require('debug')('tca:kit:services:AnalyticsService');

function getAnalyticsRequestHeaders(
  customHeaders: Record<string, string> = {}
): Record<string, string> {
  return {
    'Content-Type': 'application/json',
    'sap-device-os': Platform.OS,
    'sap-device-timezone-offset': String(
      (new Date().getTimezoneOffset() / 60) * 100
    ),
    'X-Sap-Service': 'omni-app',
    ...SharedAnalyticsHeaderOverrides,
    ...customHeaders,
  };
}

export default class AnalyticsService {
  private static get host() {
    return Environment.analyticsService;
  }

  public static sendEvent<T extends AnalyticsType>({
    type,
    appKey,
    data,
    customHeaders = {},
  }: {
    type: T;
    appKey: string;
    data: IAnalyticsData<T>;
    customHeaders?: Record<string, string>;
  }): Promise<void> {
    if (!appKey || !type) {
      return Promise.reject('No appKey or type');
    }

    data = {
      app_key: appKey,
      ...data,
    };

    const url = `${this.host}/${appKey}/${type}`;
    const headers = getAnalyticsRequestHeaders(customHeaders);

    /**
     * There are currently no analytics host for dev and stage and we do not
     * want to mix analytics for different environments. Therefore, we do not
     * send analytics if env is not prod.
     */
    if (ENV !== 'prod') {
      debug(
        `${ENV} environment. No need to send analytics to non-prod environment. Skipping, but would've sent data:`,
        JSON.stringify({ url, headers, data })
      );

      return Promise.resolve(undefined);
    }

    debug(
      `Sending analytics data:`,
      JSON.stringify({ url, headers, data })
    );

    const requestConfig: AxiosRequestConfig = {
      url,
      method: 'post',
      headers,
      data,
    };

    return Axios(requestConfig).then(this.onSuccess).catch(this.onFailure);
  }

  static async sendImpression({
    url,
    appKey,
    customHeaders,
  }: {
    url: string;
    appKey: string;
    customHeaders?: Record<string, string>;
  }): Promise<void> {
    if (!url || !appKey) {
      return Promise.reject('No url or appKey');
    }

    const headers = getAnalyticsRequestHeaders(customHeaders);

    AnalyticsService.sendEvent({
      type: 'impressions',
      appKey: appKey,
      customHeaders: headers,
      data: {
        app_key: appKey,
        url: url,
        root_app_key: headers['sap-root-appkey'],
        app_version: headers['sap-app-version'],
        device: headers['sap-device-model'],
        device_os: headers['sap-device-os'],
        device_os_version: headers['sap-device-os-version'],
        suiid: headers['sap-user-install-id'],
      },
    });
  }

  private static onSuccess() {
    debug('Sent analytic event success');
  }

  // @ts-ignore
  private static onFailure(e) {
    debug('Sent analytic event failed', e);
  }
}
