import pluralize from 'pluralize';

import Environment from '../../Environment';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '../../constants/identifiers';
import BaseServiceV2, { IHttpResponse } from '../BaseServiceV2';

const debug = require('debug')('omni:messaging:service:ImageService');

export default class ImageService {
  private static get host() {
    return Environment.imageService;
  }

  static async get(appKey: string, resource: string, id = '', token?: string) {
    const url = this._buildUrl(resource, id);

    return BaseServiceV2.Get({
      url: url,
      appKey: appKey,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      token: token,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(this._handleOneResponse)
      .catch(this._handleErrorResponse);
  }

  static async post(
    appKey: string,
    resource: string,
    endUserId: string,
    data: object,
    id = '',
    token?: string
  ) {
    const url = this._buildUrl(resource, id);

    const bodyData = {
      ...data,
      _embedded: {
        'end-user': {
          id: endUserId,
        },
      },
    };

    return BaseServiceV2.Post({
      appKey,
      url,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      data: bodyData,
      token,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(this._handleOneResponse)
      .catch(this._handleErrorResponse);
  }

  private static _buildUrl(resource: string, id?: string) {
    id = id ? `/${id}` : '';

    return `${this.host}/${pluralize(resource)}${id}`;
  }

  private static _handleOneResponse(response: IHttpResponse): {
    meta: { count?: number; total?: number };
    status: number;
    data?: any;
  } {
    const ok =
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204;

    if (!ok) throw response;

    return {
      meta: {},
      status: response.status || 0,
      data: response.body,
    };
  }

  private static _handleErrorResponse(response: IHttpResponse): {
    status: number;
    message: string;
    data?: any;
  } {
    return {
      status: response.status || 0,
      message: Array.isArray(response.body?.errors)
        ? response.body.errors[0].detail
        : JSON.stringify(response.body),
    };
  }
}
