import { StyleSheet, View } from 'react-native';

import ActionItem from '../ActionItem';
import KitModal from '@omni/kit/components/KitModalV2';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';
import { useChatContext } from '../../context/ChatContext';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTranslation } from 'react-i18next';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
}

export default ({ isVisible, onClose }: IProps): JSX.Element => {
  const insets = useSafeAreaInsets();
  const { onDeleteMessage } = useChatContext();
  const { t } = useTranslation(['liveChat']);

  const onPress = () => {
    onDeleteMessage?.();
  };

  return (
    <KitModal anchorBottom={true} isVisible={isVisible} onClose={onClose}>
      <View style={[styles.container, { paddingBottom: insets.bottom }]}>
        <ActionItem
          iconName={'delete'}
          iconSize={22}
          label={t('liveChat:deleteMessageActionName')}
          onPress={onPress}
        />
      </View>
    </KitModal>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: Spacing.m,
    paddingBottom: Spacing.xl,
    paddingHorizontal: Spacing.l,
    width: '100%',
  },
});
