import { KitIcon, KitSnack, KitText, KitTouchable } from '@omni/kit/components';
import { IconName } from '@omni/kit/components/KitIcon';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import GroupsService from '@omni/kit/services/GroupsService';
import {
  IEnrollmentType,
  IGroup,
} from '@omni/kit/services/GroupsService/Types';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import {
  ParamListBase,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';

import Label from '../../../mobile/components/Label';
import { accessTokenSelector } from '../../redux/selectors';

export interface RouteProps extends RouteProp<ParamListBase, string> {
  params: {
    group: IGroup;
  };
}

export default function VisibilityAndEnrollmentScreen(): JSX.Element {
  const navigation = useNavigation();
  const route = useRoute<RouteProps>();
  const accessToken = useSelector(accessTokenSelector);
  const [group, setGroup] = useState(route.params.group);
  const [isPublic, setIsPublic] = useState<boolean>(group.public);
  const [enrollmentType, setEnrollmentType] = useState<IEnrollmentType>(
    group.enrollment_type
  );
  const isEdited = useMemo(
    () => group.public !== isPublic || group.enrollment_type !== enrollmentType,
    [enrollmentType, group.enrollment_type, group.public, isPublic]
  );
  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
    marginBottom: 100,
  });
  const { t } = useTranslation(['messaging']);

  const save = useCallback(() => {
    const updateGroup = async () => {
      const response = await GroupsService.UpdateGroup({
        body: {
          public: isPublic,
          enrollment_type: enrollmentType,
        },
        id: group.id,
        token: accessToken,
      });

      if (response.status === 200 && response.body) {
        setGroup(response.body);
        KitSnack.show('Settings updated', KitSnackDuration.SHORT);
      } else {
        KitSnack.show('An error occurred', KitSnackDuration.SHORT);
      }
    };

    if (isEdited) {
      updateGroup();
    }
  }, [isEdited, isPublic, enrollmentType, group, accessToken]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <KitTouchable
          disabled={!isEdited}
          onPress={save}
          style={{
            paddingLeft: 12,
            paddingRight: Platform.OS === 'ios' ? 12 : 18,
            paddingVertical: 6,
            marginRight: -6,
            borderRadius: 40,
          }}
        >
          <KitText color={isEdited ? Colors.brand : Colors.N400} fontSize={17}>
            Save
          </KitText>
        </KitTouchable>
      ),
    });
  }, [isEdited, navigation, save]);

  return (
    <ScrollView style={{ padding: Spacing.l, backgroundColor: Colors.N0 }}>
      <Label>{t('messaging:labelVisibility')}</Label>
      <RadioBox
        iconName='publish'
        onPress={() => setIsPublic(true)}
        title={t('messaging:public')}
        subtitle={t('messaging:publicDescription')}
        selected={isPublic}
      />
      <RadioBox
        iconName='eye-slash'
        onPress={() => setIsPublic(false)}
        title={t('messaging:private')}
        subtitle={t('messaging:privateDescription')}
        selected={!isPublic}
      />
      <Label style={{ marginTop: Spacing.l }}>
        {t('messaging:labelEnrollment')}
      </Label>
      <RadioBox
        iconName='lock-open'
        onPress={() => setEnrollmentType('open')}
        title={t('messaging:open')}
        subtitle={t('messaging:openDescription')}
        selected={enrollmentType === 'open'}
      />
      <RadioBox
        iconName='lock'
        onPress={() => setEnrollmentType('closed')}
        title={t('messaging:closed')}
        subtitle={t('messaging:closedDescription')}
        selected={enrollmentType === 'closed'}
      />
      <RadioBox
        iconName='shield-check'
        onPress={() => setEnrollmentType('request_to_join')}
        title={t('messaging:request_to_join')}
        subtitle={t('messaging:requestToJoinDescription')}
        selected={enrollmentType === 'request_to_join'}
      />
    </ScrollView>
  );
}

const RadioBox = ({
  iconName,
  onPress,
  selected,
  title,
  subtitle,
}: {
  iconName: IconName;
  onPress: () => void;
  selected?: boolean;
  title: string;
  subtitle: string;
}) => {
  return (
    <KitTouchable
      style={{
        padding: Spacing.l,
        borderWidth: selected ? 2 : 1,
        borderColor: selected ? Colors.N900 : Colors.N100,
        borderRadius: BorderRadius.m,
        marginVertical: Spacing.xs,
        ...(selected && {
          marginLeft: -1,
          marginVertical: Spacing.xs - 1,
        }),
      }}
      borderRadius={BorderRadius.m} // to prevent cropping selected state border on Android
      touchableStyle={{ padding: 1 }} // to prevent cropping selected state left edge on Android
      onPress={onPress}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <KitIcon
          name={iconName}
          size={20}
          color={selected ? Colors.N900 : Colors.N600}
          style={{ marginRight: Spacing.l }}
        />
        <KitText gray style={{ flex: 1 }}>
          <KitText semiBold black>
            {title}
          </KitText>
          {' - ' + subtitle}
        </KitText>
      </View>
    </KitTouchable>
  );
};
