import {
  MaterialTopTabBarOptions,
  createMaterialTopTabNavigator,
} from '@react-navigation/material-top-tabs';
import React from 'react';
import { Platform } from 'react-native';

import { SizeClass, useSizeClass } from '../../contexts/SizeClassContext';
import Colors from '../../theming/Colors';

const Tab = createMaterialTopTabNavigator();

/*
 @deprecated
 Use @omni/kit/KitTab instead
 */
export const KitTab = Tab.Screen;

interface KitTabProps {
  initialRouteName?: string;
  children: JSX.Element[];
  tabBarOptions?: MaterialTopTabBarOptions;
}

/*
 @deprecated
 Use @omni/kit/KitTab instead
 */
export const KitTabGroup = ({
  initialRouteName,
  children,
  tabBarOptions,
}: KitTabProps): JSX.Element => {
  const { sizeClass } = useSizeClass();

  return (
    <Tab.Navigator
      initialRouteName={initialRouteName}
      tabBarOptions={
        {
          ...defaultTabBarOptions(sizeClass !== SizeClass.Small),
          ...tabBarOptions,
        } as MaterialTopTabBarOptions
      }
      swipeEnabled={false}
      lazy
    >
      {children}
    </Tab.Navigator>
  );
};

const defaultTabBarOptions = (isLargeScreen: boolean) => ({
  activeTintColor: Colors.N900,
  inactiveTintColor: Colors.N400,
  pressColor: Colors.N200,
  labelStyle: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 'bold',
    alignSelf: 'center',
    fontFamily: Platform.OS === 'web' ? 'inherit' : undefined,
  },
  indicatorStyle: {
    height: 3,
    backgroundColor: Colors.N900,
  },
  ...(isLargeScreen
    ? {
        style: {
          height: 65,
          justifyContent: 'center',
        },
      }
    : {}),
});
