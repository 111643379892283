import { PlatformAgnosticFile } from '../../FileTypes';

export interface ImageUploadProps {
  uploadImage: (
    imageObject: PlatformAgnosticFile,
    imageType: string
  ) => Promise<{ id: string; url: string }>; // the id and url of the uploaded image from the Image Service
  progress: number;
  status: ImageUploadStatus;
}

export enum ImageUploadStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Success = 'success',
  Error = 'error',
}
