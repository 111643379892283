import { KitIcon, KitListItem } from '@omni/kit/components';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Routes from '../../../../web/navigation/Routes';
import { JOIN_REQUESTS_SCREEN } from '../../../Constants';
import { appKeySelector } from '../../../redux/selectors';
import useJoinRequests from '../hooks/useJoinRequests';

const debug = require('debug')('groups:join-requests');

export default (): JSX.Element | null => {
  const appKey = useSelector(appKeySelector);
  const joinRequests = useJoinRequests();
  const { sizeClass } = useSizeClass();
  const { t } = useTranslation(['messaging']);

  let history,
    navigation: NavigationProp<ParamListBase>,
    isJoinRequestsRouteActive;
  try {
    history = useHistory();
    isJoinRequestsRouteActive = useRouteMatch(Routes.joinRequests())?.isExact;
  } catch (e) {}
  try {
    navigation = useNavigation();
  } catch (e) {}

  const goToJoinRequests = useCallback(() => {
    if (Platform.OS === 'web' && sizeClass !== SizeClass.Small) {
      history.push(Routes.joinRequests(appKey));
    } else {
      navigation.navigate(JOIN_REQUESTS_SCREEN);
    }
    // @ts-ignore
  }, [appKey, history, navigation, sizeClass]);

  if (joinRequests.length === 0) {
    return null;
  }

  return (
    <KitListItem
      isTitleBold
      title={t('messaging:buttonTitleJoinRequestsCount', {
        count: joinRequests.length,
      })}
      ImageComponent={
        <KitIcon
          name='people'
          size={20}
          style={{
            marginLeft: 26,
            marginRight: 12,
          }}
          color={Colors.N300}
        />
      }
      onPress={goToJoinRequests}
      rightIcon=''
      bottomBorder={false}
      style={{
        height: 78,
        justifyContent: 'center',
        backgroundColor: isJoinRequestsRouteActive ? Colors.N50 : undefined,
      }}
      hoverStyle={{ height: '100%', justifyContent: 'center' }}
      testID='join-request-count-button'
    />
  );
};
