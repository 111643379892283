import { IMediaPlaybackStateInfo } from '../KitPlayer/playbackTypes';
import CreateMediaPlayResource from './CreateMediaPlayResource';
import TrackMediaProgressState from './TrackMediaProgressState';
import UpdateMediaPlay from './UpdateMediaPlay';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:UpdateMediaPlayCount'
);

/**
 * Increment media play count
 *
 * if media play resource:
 * - exists, just increment play count - allow failed patch requests to proceed with setup
 * - does not exist, create one - failed to create will throw error and force retry in setup
 * @param mediaPlaybackStateInfo {IMediaPlaybackStateInfo}
 * @returns
 */
export default async ({
  appKey = '',
  durationMsec = 0,
  positionMsec = 0,
}: IMediaPlaybackStateInfo): Promise<void> => {
  if (
    !TrackMediaProgressState.userProfile?.userID ||
    !TrackMediaProgressState.currentMediaItem?.id
  ) {
    return;
  }

  debug('update media play count');

  if (TrackMediaProgressState.mediaPlay) {
    /** increment play count */
    debug('increment media play count');
    await UpdateMediaPlay({
      appKey,
      duration: durationMsec,
      mediaItemId: TrackMediaProgressState.currentMediaItem.id,
      mediaPlayId: TrackMediaProgressState.mediaPlay.id,
      playCount: TrackMediaProgressState.mediaPlay.play_count + 1,
      position: positionMsec,
      userId: TrackMediaProgressState.userProfile.userID,
    });
  } else {
    TrackMediaProgressState.mediaPlay = await CreateMediaPlayResource({
      appKey,
      userId: TrackMediaProgressState.userProfile.userID,
      mediaItemId: TrackMediaProgressState.currentMediaItem.id,
      position: positionMsec,
      duration: durationMsec,
    });
  }
};
