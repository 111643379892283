import MediaPlayService, {
  IMediaPlays,
} from '@omni/kit/services/MediaPersonalizationService/MediaPlayService';
import { MEDIA_FORMAT } from '@omni/kit/services/MediaPersonalizationService/MediaPlayService/Core/Update';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:CreateMediaPlayResource'
);

/**
 * Post media play resource to server
 */
export default async ({
  appKey,
  duration,
  mediaItemId = '',
  position,
  userId = '',
}: {
  appKey: string;
  duration: number;
  mediaItemId?: string;
  position: number;
  userId?: string;
}): Promise<IMediaPlays | null> => {
  if (
    duration === 0 ||
    position > duration ||
    !mediaItemId ||
    !userId ||
    !appKey
  ) {
    debug('Insufficient data to create media play', {
      position,
      duration,
      mediaItemId,
      userId,
      appKey,
    });

    return null;
  }

  const data = {
    app_key: appKey,
    duration_ms: duration,
    play_count: 1,
    progress_ms: position,
    last_media_format: MEDIA_FORMAT.VIDEO,
    _embedded: {
      'end-user': { id: userId },
      'media-item': { id: mediaItemId },
    },
  };

  debug('Create media play with data', data);

  try {
    const response = await MediaPlayService.create({
      appKey,
      data,
    });

    debug('Created media play success', response.body?.id);

    return response.body || null;
  } catch (e) {
    const errorMessage = 'failed to create media play';
    debug(errorMessage, e);

    throw new Error(errorMessage);
  }
};
