import React, { useContext, useState } from 'react';
import {
  Animated,
  Platform,
  StyleProp,
  StyleSheet,
  TouchableHighlight,
  TouchableNativeFeedback,
  View,
  ViewStyle,
} from 'react-native';

import Colors from '../theming/Colors';
import { ThemeContext } from '../theming/ThemeContext';
import KitIcon, { IconName } from './KitIcon';

//******************************************************************************
// Types
//******************************************************************************

export interface KitTouchableIconProps {
  buttonSize?: number;
  color?: string;
  disabled?: boolean;
  enableScaleAnimation?: boolean;
  name: IconName;
  onPress?: () => void;
  size?: number;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  underlayColor?: string;
}

//******************************************************************************
// Component
//******************************************************************************

const AnimatedTouchableHighlight =
  Animated.createAnimatedComponent(TouchableHighlight);
const AnimatedTouchableNativeFeedback = Animated.createAnimatedComponent(
  TouchableNativeFeedback
);

export default function KitTouchableIcon({
  buttonSize = 56,
  color,
  disabled = false,
  enableScaleAnimation = false,
  name,
  onPress = () => undefined,
  size = 20,
  style = {},
  testID,
  underlayColor = '',
}: KitTouchableIconProps): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);
  const animatedValue = new Animated.Value(0);
  const buttonScale = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.95],
  });

  const onPressIn = () => {
    if (enableScaleAnimation) {
      Animated.spring(animatedValue, {
        toValue: 1,
        useNativeDriver: true,
      }).start();
    }
  };

  const onPressOut = () => {
    if (enableScaleAnimation) {
      Animated.spring(animatedValue, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
    }
  };

  return Platform.OS === 'android' ? (
    <AnimatedTouchableNativeFeedback
      background={TouchableNativeFeedback.Ripple(underlayColor, false)}
      delayPressIn={0}
      disabled={disabled}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      testID={testID}
      useForeground={true}
      style={{ transform: [{ scale: buttonScale }] }}
    >
      <View
        style={[
          styles.button,
          {
            height: buttonSize,
            opacity: disabled ? 0.8 : 1.0,
            width: buttonSize,
          },
          style,
        ]}
      >
        <KitIcon
          color={color ? color : colorForScheme?.({ default: Colors.N900 })}
          name={name}
          size={size}
        />
      </View>
    </AnimatedTouchableNativeFeedback>
  ) : (
    <AnimatedTouchableHighlight
      disabled={disabled}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[
        styles.buttonContainer,
        {
          height: buttonSize,
          transform: [{ scale: buttonScale }],
          width: buttonSize,
        },
        style,
      ]}
      testID={testID}
      underlayColor={
        underlayColor ||
        colorForScheme?.({
          default: Colors.N100,
          dark: Colors.N900,
        })
      }
    >
      <View
        style={[
          styles.button,
          {
            height: buttonSize,
            opacity: disabled ? 0.5 : 1.0,
            width: buttonSize,
          },
        ]}
      >
        <KitIcon
          color={color ? color : colorForScheme?.({ default: Colors.N900 })}
          name={name}
          size={size}
        />
      </View>
    </AnimatedTouchableHighlight>
  );
}

const styles = StyleSheet.create({
  button: {
    ...Platform.select({ android: { overflow: 'hidden' } }),
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: 28,
    justifyContent: 'center',
  },
  buttonContainer: {
    borderRadius: 28,
    ...Platform.select({ web: { cursor: 'pointer' } }),
  },
});
