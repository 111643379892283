import { useCallback, useState } from 'react';

/**
 * Hook for keeping track of pagination state
 * Useful in combination with KitPagination component
 * @param pageSize
 * @param total
 */
const usePagination = (
  pageSize: number,
  total: number
): [number, () => void, (p: number) => void, () => void] => {
  const [page, setPage] = useState(1);
  const handlePreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);
  const handlePageSelection = useCallback(
    (p: number) => {
      if (p > 0 && p <= Math.ceil(total / pageSize)) {
        setPage(p);
      }
    },
    [pageSize, total]
  );
  const handleNextPage = useCallback(() => {
    if (page * pageSize < total) {
      setPage(page + 1);
    }
  }, [page, pageSize, total]);

  return [page, handlePreviousPage, handlePageSelection, handleNextPage];
};

export default usePagination;
