import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import { useEffect, useState } from 'react';

/*
 * Do not use this directly in a component, instead use KioskContext
 * */
export default (): {
  kioskDeviceId: string | undefined;
  setKioskDeviceId: (kioskDeviceId: string | undefined) => Promise<void>;
  kioskDeviceIdLoading: boolean;
} => {
  const [kioskDeviceId, setKioskDeviceId] = useState<string | undefined>();
  const [kioskDeviceIdLoading, setKioskDeviceIdLoading] =
    useState<boolean>(true);

  useEffect(() => {
    const getKioskDevice = async () => {
      const kioskDeviceId = await getStoredItem('check_in_device_id');

      if (kioskDeviceId) {
        setKioskDeviceId(kioskDeviceId);
      } else {
        setKioskDeviceId(undefined);
      }

      setKioskDeviceIdLoading(false);
    };
    getKioskDevice();
  }, []);

  const saveKioskDevice = async (deviceId: string | undefined) => {
    if (deviceId) {
      await setStoredItem('check_in_device_id', deviceId);
    } else {
      await removeStoredItem('check_in_device_id');
    }

    setKioskDeviceId(deviceId);
  };

  return {
    kioskDeviceId,
    setKioskDeviceId: saveKioskDevice,
    kioskDeviceIdLoading,
  };
};
