import TokensService from '@omni/kit/services/TokensService';

import { IPrepareHitsUrl, ISearchResults, prepareHitsUrl } from '.';
import BaseServiceV2, { IHttpResponse } from '../BaseServiceV2';
import { cache } from './Cache';

const debug = require('debug')('tca:service:SearchService');

interface ISearch extends IPrepareHitsUrl {
  getFromCache?: boolean;
}

export interface ISearchResponse extends IHttpResponse {
  body?: ISearchResults;
}

export const search = async ({
  getFromCache,
  token,
  ...buildUrlProps
}: ISearch): Promise<ISearchResponse> => {
  debug('search');
  const url = prepareHitsUrl(buildUrlProps);

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    getFromCache,
    skipTimestamp: true,
    cache,
    token: token ? token : guestToken,
  });
};
