import { IListItemData } from '@omni/kit/feeds/listItemTypes';
import { ITag } from '@omni/kit/services/TagService';

export default (tags: ITag[]): IListItemData[] => {
  return tags.map((tag: ITag) => {
    return {
      count: tag.tagging_count,
      description: tag.description,
      image: tag._embedded?.['square-image']?._links?.related?.href,
      showAvatar: true,
      subtitle: tag.short_description,
      title: tag.title,
      type: tag.type,
      value: tag.title,
    };
  });
};
