import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { KitText } from '../../components';
import KitModal from '../../components/KitModalV2/KitModalV2';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { ISnackbarContextProviderProps, TSnackbarContextType } from './types';

let hideSnackBarTimeoutId: NodeJS.Timeout | undefined;

const initialSnackbarContext: TSnackbarContextType = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showSnackbar: (_text: string) => {},
};

export const SnackbarContext = createContext(initialSnackbarContext);

const SnackbarModal = ({
  isVisible,
  children,
  onClose,
}: {
  isVisible: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}): JSX.Element => {
  return (
    <KitModal
      onModalPress={onClose}
      isVisible={isVisible}
      hasBackdrop={false}
      modalStyle={{
        alignItems: 'flex-start',
        backgroundColor: Colors.N900,
        bottom: Spacing.xl,
        left: Spacing.xl,
        maxWidth: 360,
        padding: Spacing.l,
        position: 'absolute',
        right: Spacing.xl,
      }}
    >
      {children}
    </KitModal>
  );
};

export const SnackbarContextProvider = ({
  children,
}: ISnackbarContextProviderProps): null | JSX.Element => {
  const [snackText, setSnackText] = useState<string>('');
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);

  const hideSnackbar = useCallback(() => {
    clearHideSnackbarTimeout();
    setIsSnackbarVisible(false);
  }, []);

  const showSnackbar = useCallback(
    (text: string) => {
      clearHideSnackbarTimeout();

      setSnackText(text);
      setIsSnackbarVisible(true);

      hideSnackBarTimeoutId = setTimeout(() => {
        hideSnackbar();
      }, 3000);
    },
    [hideSnackbar]
  );

  const clearHideSnackbarTimeout = () => {
    if (hideSnackBarTimeoutId) {
      clearTimeout(hideSnackBarTimeoutId);
      hideSnackBarTimeoutId = undefined;
    }
  };

  useEffect(() => {
    return () => {
      clearHideSnackbarTimeout();
    };
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <>
        {children}
        <SnackbarModal isVisible={isSnackbarVisible} onClose={hideSnackbar}>
          <KitText white>{snackText}</KitText>
        </SnackbarModal>
      </>
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = (): TSnackbarContextType => {
  return useContext<TSnackbarContextType>(SnackbarContext);
};
