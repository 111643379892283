import { KitButton, KitText } from '@omni/kit/components';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export default function ActionConfirmModal({
  isVisible,
  onClose,
  confirmAction,
  title,
  subtitle,
  actionType,
}: {
  isVisible: boolean;
  onClose: (isVisible: boolean) => void;
  confirmAction: () => void;
  title: string;
  subtitle?: string;
  actionType: string;
}): JSX.Element | null {
  const { sizeClass } = useSizeClass();
  const { t } = useTranslation(['common']);

  const onCloseModal = () => {
    onClose(false);
  };

  const onConfirmModal = () => {
    confirmAction();
    onClose(false);
  };

  return (
    <KitModalV2
      isVisible={isVisible}
      onClose={() => onCloseModal()}
      anchorBottom={sizeClass === SizeClass.Small}
      coverScreen
    >
      <View
        style={{
          width: '100%',
        }}
      >
        <KitText
          black
          extraBold
          fontSize={22}
          style={{ marginBottom: Spacing.s }}
        >
          {title}
        </KitText>
        <KitText gray fontSize={16}>
          {subtitle}
        </KitText>

        <View
          style={{
            ...(sizeClass === SizeClass.Small && {
              flexDirection: 'column',
            }),
            ...(sizeClass !== SizeClass.Small && {
              flexDirection: 'row-reverse',
              marginTop: Spacing.l,
            }),
          }}
        >
          <KitButton
            color={Colors.R500}
            title={
              actionType === 'leave'
                ? t('buttonTitleYesLeave')
                : t('buttonTitleYesDelete')
            }
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            }}
            onPress={() => onConfirmModal()}
            small={sizeClass !== SizeClass.Small}
          />
          <KitButton
            secondary
            color={Colors.N100}
            title={t('buttonTitleNoCancel')}
            onPress={() => onCloseModal()}
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
              ...(sizeClass !== SizeClass.Small && { marginRight: Spacing.m }),
            }}
            small={sizeClass !== SizeClass.Small}
          />
        </View>
      </View>
    </KitModalV2>
  );
}
