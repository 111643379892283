import { KitButton, KitLoader, KitText } from '@omni/kit/components';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

interface IProps {
  onRetryButtonPress: () => void;
}

export default function ErrorView({ onRetryButtonPress }: IProps): JSX.Element {
  const [showContent, setShowContent] = useState(false);
  const showContentTimeoutInMillis = 2000;

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, showContentTimeoutInMillis);
  }, []);

  return (
    <View style={styles.errorView}>
      {!showContent && <KitLoader />}
      {showContent && (
        <View>
          <KitText black fontSize={28} bold center style={styles.headerText}>
            Something went wrong
          </KitText>
          <KitText gray fontSize={16} center style={styles.paragraphText}>
            We had a problem loading some of your content. Please try again.
          </KitText>
          <KitButton title='Retry' onPress={onRetryButtonPress} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  errorView: {
    left: Spacing.l,
    position: 'absolute',
    right: Spacing.l,
    alignItems: 'center',
  },
  headerText: {
    marginTop: Spacing.l,
  },
  loadingStyle: {
    alignItems: 'center',
    bottom: 80,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  paragraphText: {
    marginBottom: Spacing.m,
    marginTop: Spacing.m,
  },
});
