import CheckInService from '@omni/check-in/kiosk/services/CheckInService';
import { useShellContext } from '@omni/kit';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import { useEffect, useState } from 'react';

export interface ISessionSuggestion {
  'end-user.id': string;
  'session.id': string;
  'session.title': string;
}

export default function useSuggestedSessions(
  eventId?: string,
  profiles?: IProfile[]
): { suggestions: ISessionSuggestion[]; loading: boolean } {
  const { app, tokens, user } = useShellContext();
  const [suggestions, setSuggestions] = useState<ISessionSuggestion[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchSuggestedSessions = async () => {
      setLoading(true);
      try {
        const ss: ISessionSuggestion[] = [];
        const promises = [];

        for (const profile of profiles as IProfile[]) {
          if (profile._embedded?.['end-user']?.id && eventId) {
            promises.push(
              CheckInService.GetSuggestedSessions({
                token: tokens.user,
                filters: [
                  ['app_key', app.appKey],
                  ['event.id', eventId],
                  [
                    'end-user.id',
                    profile._embedded?.['end-user']?.id as string,
                  ],
                ],
              }).then((response) => {
                if (
                  response?.body?._embedded.sessions &&
                  response?.body?._embedded.sessions[0] &&
                  response?.body?._embedded.sessions[0].id
                ) {
                  const newData = {
                    'end-user.id': profile._embedded?.['end-user']
                      ?.id as string,
                    'session.id': response?.body?._embedded.sessions[0].id,
                    'session.title':
                      response?.body?._embedded.sessions[0].title,
                  };
                  ss.push(newData);
                }
              })
            );
          }
        }

        await Promise.all(promises);

        setSuggestions(ss);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    if (eventId && profiles) {
      fetchSuggestedSessions();
    } else {
      setLoading(false);
    }
  }, [app.appKey, eventId, profiles, tokens.user]);

  return { suggestions, loading };
}
