import {
  IFacetResponse,
  TAG_TYPES,
  TagItem,
} from '@omni/kit/services/SearchService';
// @ts-ignore
import osisToEn from 'bible-reference-formatter/es6/en';

const debug = require('debug')('tca:search:utils:Browse.ts');

export function parseTagData(
  results: IFacetResponse,
  tagType: string
): TagItem[] {
  const body = results?.body;
  const facets = body?._embedded?.facets;

  let tags;

  switch (tagType) {
    case TAG_TYPES.SCRIPTURE:
      tags = facets?.books || [];
      break;
    default:
      tags = facets?.tags || [];
      break;
  }

  return tags.map((t) => {
    /**
     * The value of the tags varies dependent on the tag type
     * 1. topic tag - topic:test
     * 2. speaker tag - speaker:speaker-test
     * 3. scripture tag - Matt
     */
    const arr = t.value.split(':');
    const s1 = arr[0];

    /**
     * Remove the first index and join the rest in case there are colons in the
     * speaker tag value.
     */
    arr.splice(0, 1);
    const s2 = arr.join(':');

    let title = s2;
    let value = s2;

    if (tagType === TAG_TYPES.SCRIPTURE) {
      /** Attempt to prettify osis string. */
      try {
        title = osisToEn('niv-long', s1);
      } catch (e) {
        title = s1;
        debug(e);
      }
      value = s1;
    }

    const tag = {
      count: t.count,
      title,
      type: tagType,
      value,
    };

    if (tagType === TAG_TYPES.SPEAKER) {
      const speakerTag = t._embedded?.tag;
      const image = speakerTag?._embedded?.['square-image'];

      return {
        ...tag,
        averageHexColor: image?.average_color_hex,
        subtitle: speakerTag?.short_description,
        description: speakerTag?.description,
        image: image?._links?.related?.href,
        showAvatar: true,
      };
    }

    return tag;
  });
}
