import Environment from '@omni/kit/Environment';
import { KitButton, KitText } from '@omni/kit/components';
import KitAppIcon from '@omni/kit/components/KitAppIcon';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import { useRootAppContext } from '@omni/kit/contexts/RootAppContext';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import getAppStates from '@omni/kit/feeds/getAppStates';
import AccountsService from '@omni/kit/services/AccountsService';
import SearchService, {
  IPrepareHitsUrl,
} from '@omni/kit/services/SearchService';
import { App } from '@omni/kit/services/Types';
import { setStoredItem } from '@omni/kit/storage';
import Spacing from '@omni/kit/theming/Spacing';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { dispatchAction } from '@omni/kit/utilities/NativeHelpers';
import { getUserLocation } from '@omni/kit/utilities/UserLocation';
import memberAppLabelContextKey from '@omni/kit/utilities/memberAppLabelContextKey';
import { logButtonPressEvent, logEvent } from '@omni/kit/utilities/utilities';
import { useNavigation } from '@react-navigation/native';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Location } from 'react-native-get-location';
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  KEY_LOCATION_QUERY_DESCRIPTION,
  VALUE_CURRENT_LOCATION,
  VALUE_CURRENT_LOCATION_ERROR,
} from '../../Constants';
import ScreenContainer from '../../components/ScreenContainer';

const debug = require('debug')('tca:search:Screens:Tour.tsx');

export default (): JSX.Element => {
  const { colorForScheme } = useContext(ThemeContext);

  const navigation = useNavigation();
  const insets = useSafeAreaInsets();

  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: undefined,
  });

  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
    marginBottom: Spacing.l,
  });

  const rootApp = useRootAppContext();
  const [nearbyApp, setNearbyApp] = useState<App | undefined>(undefined);

  const { t } = useTranslation(['common', 'search']);

  const contextKey = memberAppLabelContextKey(rootApp);

  const onLocationButtonPress = async () => {
    logButtonPressEvent('enable_location');
    logEvent('enable_location', { enable_location_status: 'attempt' });

    // Attempt to obtain user's current location
    let userLocation: Location | undefined;
    try {
      userLocation = await getUserLocation();

      debug(`Got location: ${JSON.stringify(userLocation)}`);

      // this will cause the Search screen to default to use 'current location'
      setStoredItem(KEY_LOCATION_QUERY_DESCRIPTION, VALUE_CURRENT_LOCATION);
    } catch (error) {
      debug(`Error: ${JSON.stringify(error)}`);
    }

    setStoredItem('seen-search-tour', 'true');

    // Query for a church/site/campus that is in close proximity to the user's current location.
    if (userLocation) {
      logEvent('enable_location', { enable_location_status: 'success' });
      logEvent('find_nearby', { find_status: 'attempt' });
      try {
        const dist = 0.25;
        const config: IPrepareHitsUrl = {
          domain: 'apps',
          containerAppKey: rootApp?.id,
          filterQuery: `{"loc":["${userLocation.latitude},${userLocation.longitude}"],"dist":${dist}}`,
          page: 1,
          size: 1,
        };

        const data = await SearchService.search(config);
        debug(`findNearbyApp:response= ${JSON.stringify(data)}`);
        const foundNearbyApp = data?.body?._embedded?.apps?.[0];
        setNearbyApp(foundNearbyApp);
        debug(`foundNearbyApp:${JSON.stringify(foundNearbyApp)}`);

        if (foundNearbyApp) {
          logEvent('find_nearby', { find_status: 'found' });
        } else {
          logEvent('find_nearby', { find_status: 'not_found' });
          navigation.navigate('Search');
        }
      } catch (e) {
        debug('Nearby search request failed', e);
      }
    } else {
      logEvent('enable_location', { enable_location_status: 'failed' });
      showKitSnack(VALUE_CURRENT_LOCATION_ERROR, KitSnackDuration.SHORT);
      setTimeout(() => {
        navigation.navigate('Search');
      }, KitSnackDuration.SHORT);
    }
  };

  const onSearchButtonPress = () => {
    logButtonPressEvent('skip_and_search_by_name');
    setStoredItem('seen-search-tour', 'true');
    setTimeout(() => {
      navigation.navigate('Search');
    }, 300);
  };

  const showKitSnack = (msg: string, dur: number) => {
    setSnackOptions({
      visible: true,
      message: msg,
      duration: dur,
      marginBottom: Spacing.l,
    });
  };

  const TourContent = (): JSX.Element => {
    // TODO: add 2x and 3x png for 'graphic-search-tour-dark'
    const imageSource: ImageSourcePropType =
      colorForScheme?.({
        light: require('@omni/kit/img/graphic-search-tour.png'),
        dark: require('@omni/kit/img/graphic-search-tour-dark.png'),
      }) ?? require('@omni/kit/img/graphic-search-tour.png');

    return (
      <ScreenContainer>
        <View style={styles(insets, edgeSpacing).contentContainer}>
          <Image
            source={imageSource}
            style={{
              marginBottom: Spacing.xxl,
            }}
          />
          <KitText h1 center>
            {t('search:tourTitle', { context: contextKey })}
          </KitText>
          <KitText body1 center>
            {t('search:tourSubtitle', { context: contextKey })}
          </KitText>
        </View>
        <View style={styles(insets, edgeSpacing).buttonContainer}>
          <KitButton
            style={{ marginBottom: Spacing.l }}
            title={t('common:buttonTitleEnableLocation')}
            onPress={onLocationButtonPress}
          />
          <KitButton
            title={t('search:buttonTitleSkip')}
            secondary
            onPress={onSearchButtonPress}
          />
        </View>
        <KitSnackRender
          {...snackOptions}
          setVisible={(value) =>
            setSnackOptions({ ...snackOptions, visible: value })
          }
        />
      </ScreenContainer>
    );
  };

  const NearbyContent = (): JSX.Element => {
    const [appIconUrl, setAppIconUrl] = useState<string | undefined>(undefined);
    const [appIconBackgroundColor, setAppIconBackgroundColor] = useState<
      string | undefined
    >('#fff');

    useEffect(() => {
      if (nearbyApp != null) {
        AccountsService.getAppWithBranding(nearbyApp.id)
          .then((app) => {
            const brand = app?._embedded?.branding;
            const logo = brand?._embedded?.['icon-logo-image'];

            if (logo) {
              setAppIconUrl(logo._links?.dynamic?.href);
              setAppIconBackgroundColor(brand?.icon_background_color_hex);
            }
          })
          .catch((error) => {
            debug(
              `Unable to get app with branding, error: ${JSON.stringify(error)}`
            );
          });
      }
    }, []);

    const onPressOpen = () => {
      if (nearbyApp) {
        dispatchAction({
          handler: 'app',
          appkey: nearbyApp.id,
          states: getAppStates(nearbyApp.id, nearbyApp.title),
        });
      }
    };

    const onPressFindDifferent = () => {
      logButtonPressEvent('find_different');
      navigation.navigate('Search');
    };

    if (!nearbyApp) {
      return <ScreenContainer />;
    }

    return (
      <ScreenContainer>
        <View style={styles(insets, edgeSpacing).contentContainer}>
          <KitAppIcon
            size={120}
            imageUrl={appIconUrl}
            backgroundColor={appIconBackgroundColor}
            style={{ marginBottom: Spacing.xxl }}
          />
          <KitText h1 center>
            {t('search:nearbyTitle', { appTitle: nearbyApp.title })}
          </KitText>
          <KitText body1 center>
            {t('search:nearbySubtitle', { appTitle: nearbyApp.title })}
          </KitText>
        </View>
        <View style={styles(insets, edgeSpacing).buttonContainer}>
          <KitButton
            style={{ marginBottom: Spacing.l }}
            title={t('common:buttonTitleOpen')}
            onPress={onPressOpen}
          />
          <KitButton
            title={t('search:buttonTitleFindDifferent', {
              context: contextKey,
            })}
            secondary
            onPress={onPressFindDifferent}
          />
        </View>
      </ScreenContainer>
    );
  };

  return nearbyApp ? <NearbyContent /> : <TourContent />;
};

const styles = (insets: EdgeInsets, edgeSpacing: number): Record<string, any> =>
  StyleSheet.create({
    contentContainer: {
      marginHorizontal: edgeSpacing,
      flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    buttonContainer: {
      marginHorizontal: edgeSpacing,
      marginBottom: insets.bottom + Spacing.l,
    },
  });
