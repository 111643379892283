import { IMediaPlaybackStateInfo } from '../KitPlayer/playbackTypes';
import TrackMediaProgressState from './TrackMediaProgressState';
import UpdateMediaPlay from './UpdateMediaPlay';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:PatchProgressOnStateChange'
);

/**
 * Patch progress when media player specified state is flipping
 */
export default async (
  defaultState = false,
  trackedState: string,
  mediaPlaybackStateInfo: IMediaPlaybackStateInfo
): Promise<void> => {
  const { appKey = '', durationMsec, positionMsec } = mediaPlaybackStateInfo;

  /** Patch progress when media player trackedState is flip flopping */
  if (defaultState) {
    TrackMediaProgressState.previousStates[trackedState] = false;
  } else {
    /**  Ensures progress is not patched repeatedly to the server for ios */
    if (TrackMediaProgressState.previousStates[trackedState]) {
      debug(
        `${trackedState} was previously ${TrackMediaProgressState.previousStates[trackedState]}. Skip patching progress to server.`
      );
    } else {
      debug(
        `${trackedState} is ${TrackMediaProgressState.previousStates[trackedState]}, update progress to server`
      );

      /** Set previous state immediately to avoid updating media play resource repeatedly */
      TrackMediaProgressState.previousStates[trackedState] = true;

      if (
        TrackMediaProgressState.currentMediaItem &&
        TrackMediaProgressState.mediaPlay &&
        TrackMediaProgressState.userProfile
      ) {
        await UpdateMediaPlay({
          appKey,
          duration: durationMsec,
          mediaItemId: TrackMediaProgressState.currentMediaItem.id,
          mediaPlayId: TrackMediaProgressState.mediaPlay.id,
          position: positionMsec || 0,
          userId: TrackMediaProgressState.userProfile.userID,
        });
      }
    }
  }
};
