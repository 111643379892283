import { WindowSizeMin } from '@omni/kit/contexts/SizeClassContext';
import Spacing from '@omni/kit/theming/Spacing';

export default (size: 's' | 'm' | 'l', width: number): number => {
  switch (size) {
    case 's': {
      return Spacing.l;
    }
    case 'm': {
      if (width < WindowSizeMin.XS) {
        return Spacing.l;
      } else if (width < WindowSizeMin.M) {
        return Spacing.xl;
      } else {
        return Spacing.xxxl;
      }
    }
    case 'l': {
      if (width < WindowSizeMin.S) {
        return Spacing.l;
      } else if (width < WindowSizeMin.M) {
        return Spacing.xl;
      } else {
        return Spacing.xxxl;
      }
    }
    default:
      return 0;
  }
};
