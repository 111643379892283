import { dismissReactNativeModal } from '@omni/kit/utilities/NativeHelpers';
import React from 'react';
import { NativeModules, Platform, View } from 'react-native';
import BackButton from '../BackButton';
import { IHeaderLeft } from './types';

const debug = require('debug')('tca:search:components:HeaderLeft');

export default ({
  dismissApp,
  navBack,
  title,
  isNativeNavStackAtRoot,
  type,
  style,
}: IHeaderLeft): any => {
  const onPress = () => {
    if (dismissApp) {
      try {
        NativeModules.ReactPlatformBridge.dismiss();
      } catch (e) {
        debug('', e);
      }
    } else {
      /**
       * when isNativeNavStackAtRoot is not undefined,
       * it means that Search was dispatched from the native app
       * so back navigation in this case is expected to navigate back
       * to the previous activity/controller in the native stack.
       */
      const wasDispatchedFromNative = isNativeNavStackAtRoot !== undefined;

      if (wasDispatchedFromNative) {
        dismissReactNativeModal();
      } else {
        navBack();
      }
    }
  };

  return Platform.select({
    ios: {
      headerLeft: () => (
        <View style={style}>
          {BackButton({
            type,
            onPress,
          })}
        </View>
      ),
    },
    android: {
      headerLeft: () => (
        <View style={style}>
          {BackButton({
            onPress,
            type,
            title,
          })}
        </View>
      ),
    },
  });
};
