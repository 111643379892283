import {
  CHANNEL_DETAILS_MODAL,
  CHANNEL_DETAILS_SCREEN,
  CHAT_SCREEN,
  CHAT_SETTINGS_MODAL,
  CHAT_THREAD_SCREEN,
  CREATE_CHANNEL_MODAL,
  CREATE_DIRECT_MODAL,
  DISCOVER_TAB,
  EDIT_CHANNEL_SCREEN,
  EMPTY_SCREEN,
  JOIN_REQUESTS_SCREEN,
  PRAYER_LIST_SCREEN,
  THREADS_TAB,
} from '../../../shared/Constants';
import {
  KitIcon,
  KitText,
  KitTouchable,
  KitTouchableIcon,
} from '@omni/kit/components';
import LeftNavButton, { LeftNavType } from '../../components/LeftNavButton';
import {
  Platform,
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import {
  StackScreenProps,
  createStackNavigator,
} from '@react-navigation/stack';
import {
  appKeySelector,
  appTitleSelector,
  canCreateChannelsSelector,
  canCreateDirectMessageSelector,
  channelListSelector,
  channelSelector,
  viewIsReadySelector,
} from '../../../shared/redux/selectors';
import { useDispatch, useSelector } from 'react-redux';

import ActionSheetItem from '../../components/chat/actionSheet/ActionSheetItem';
import AppLoadingView from '../../components/AppLoadingView';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import ChannelAvatar from '../../../shared/scenes/channelList/components/ChannelAvatar';
import ChannelDetailsScreen from '@omni/messaging/shared/scenes/channelDetails/ChannelDetailsScreen';
import { ChannelDetailsStack } from '@omni/messaging/shared/scenes/channelDetails/ChannelDetailStack';
import ChannelListScreen from '../../screens/ChannelListScreen';
import { ChannelType } from '@sendbird/chat';
import ChatScreen from '../../scenes/channel/ChatScreen';
import { ChatStackParamList } from '../../Types';
import Colors from '@omni/kit/theming/Colors';
import Dropdown from '../../components/Dropdown';
import EmptyScreen from '../../screens/EmptyScreen';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import Groups from '@omni/groups/Groups';
import JoinRequestsScreen from '../../../shared/scenes/joinRequests/JoinRequestsScreen';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import PrayerListScreen from '../../scenes/channel/PrayerListScreen';
import { SafeAreaView } from 'react-native-safe-area-context';
import Spacing from '@omni/kit/theming/Spacing';
import ThreadListScreen from '../../scenes/channel/ThreadListScreen';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { getNavOptions } from '@omni/messaging/mobile/navigation/AppNavigator';
import { getShortChannelUrl } from '../../../utilities/chatUtilities';
import { setChannel } from '../../../shared/redux/actions/ChatActions';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

const debug = require('debug')('omni:chat:navigation:largeScreens:MainScreen');

// @ts-ignore
const ChatStack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

/**
 * Will get the active channel from the route and update the redux state.
 * Necessary for when the user wants to access a specific channel directly from
 * the URL.
 */
type ChatScreenWrapperProps = StackScreenProps<
  ChatStackParamList,
  'chat_screen'
>;

interface ChatScreenHeaderProps {
  channel: GroupChannel;
  route: any;
}
function ChatScreenHeader({ channel, route }: ChatScreenHeaderProps) {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const navToPrayers = () => {
    navigation.navigate(PRAYER_LIST_SCREEN);
  };

  const navToThreads = () => {
    navigation.navigate(THREADS_TAB);
  };

  const navToMessages = () => {
    navigation.navigate(CHAT_SCREEN);
  };

  const navToChannel = () => {
    navigation.navigate(CHAT_SCREEN, {
      channel,
      channelId: getShortChannelUrl(channel?.url || ''),
    });
  };

  const isRouteActive = (routeName: string) => {
    const activeIndex = route.state?.index;

    return route.state?.routes[activeIndex]?.name === routeName;
  };

  const navToChannelDetails = () => {
    navigation.navigate(CHANNEL_DETAILS_SCREEN, {
      channel,
      channelId: getShortChannelUrl(channel?.url || ''),
    });
  };

  if (isRouteActive(DISCOVER_TAB)) {
    return null;
  }

  return (
    <>
      {!isRouteActive(CHANNEL_DETAILS_SCREEN) && (
        <View style={styles.chatHeader}>
          {isRouteActive(CHAT_THREAD_SCREEN) ? (
            <TouchableOpacity onPress={navToChannel}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navigation.goBack}
                />
                <View style={{ marginLeft: Spacing.m }}>
                  <KitText black fontSize={16}>
                    Thread
                  </KitText>
                  <KitText brandColor fontSize={15}>
                    {channel.name}
                  </KitText>
                </View>
              </View>
            </TouchableOpacity>
          ) : isRouteActive(THREADS_TAB) ||
            isRouteActive(PRAYER_LIST_SCREEN) ? (
            <TouchableOpacity onPress={navToMessages}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navToMessages}
                />
                <View style={{ marginLeft: Spacing.m }}>
                  <KitText black bold fontSize={16}>
                    {isRouteActive(THREADS_TAB)
                      ? t('messaging:threads')
                      : t('messaging:prayersText')}
                  </KitText>
                  <KitText fontSize={16} style={{ marginTop: -2 }}>
                    {channel.name}
                  </KitText>
                </View>
              </View>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={navToChannelDetails}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <View
                  style={{ marginHorizontal: Spacing.s, alignItems: 'center' }}
                >
                  <ChannelAvatar channel={channel} />
                </View>
                <View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <KitText black semiBold fontSize={15}>
                      {channel.name}
                    </KitText>
                    <KitIcon
                      name='arrow-right'
                      size={20}
                      style={{ marginTop: 1 }}
                    />
                  </View>
                  <KitText fontSize={13} style={{ marginTop: -5 }}>
                    {channel.memberCount} members
                  </KitText>
                </View>
              </View>
            </TouchableOpacity>
          )}
          {isRouteActive(CHAT_SCREEN) && (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TouchableHighlight
                onPress={navToThreads}
                underlayColor={Colors.N100}
                delayPressIn={0}
                style={{ padding: Spacing.m, borderRadius: BorderRadius.xl }}
              >
                <KitIcon name='thread' size={20} />
              </TouchableHighlight>
              <TouchableHighlight
                onPress={navToPrayers}
                underlayColor={Colors.N100}
                delayPressIn={0}
                style={{
                  padding: Spacing.m,
                  borderRadius: BorderRadius.xl,
                }}
              >
                <KitIcon name='prayer' size={20} />
              </TouchableHighlight>
            </View>
          )}
        </View>
      )}
    </>
  );
}

export default function MainScreen({
  // @ts-ignore
  screenProps,
  // @ts-ignore
  route,
  // @ts-ignore
  navigation,
}): JSX.Element {
  const channel = useSelector(channelSelector);
  const channelList = useSelector(channelListSelector);
  const appKey = useSelector(appKeySelector);
  const appTitle = useSelector(appTitleSelector);
  const canCreateDirectMessage = useSelector(canCreateDirectMessageSelector);
  const canCreateChannels = useSelector(canCreateChannelsSelector);
  const viewIsReady = useSelector(viewIsReadySelector);

  const dispatch = useDispatch();
  const menuRef = useRef<PopupActions | null>(null);
  const [initialized, setInitialized] = useState(false);

  const navToChatSettings = () => {
    navigation.navigate(CHAT_SETTINGS_MODAL, { channel });
  };

  const navToCreateChannel = () => {
    navigation.navigate(CREATE_CHANNEL_MODAL);
    menuRef.current?.close();
  };

  const navToCreateDirect = () => {
    debug('navToCreateDirect()');
    dispatch(setChannel(null));
    navigation.navigate(CREATE_DIRECT_MODAL);
    menuRef.current?.close();
  };

  const navToDiscover = () => {
    navigation.navigate(DISCOVER_TAB);
  };

  const handleComposeClick = () => {
    if (canCreateChannels) {
      // Show the menu list if the user can create channels & DMs
      menuRef.current?.toggle();
    } else {
      // Show the create DM modal directly if the user cannot create channels
      navToCreateDirect();
    }
  };

  useEffect(() => {
    if (channelList && channelList.length > 0 && !channel && !initialized) {
      // TODO - this is kind of a hack - apparently it "needs" a channel in order to navigate
      // to the channel that is identified in the URL. it would be nice if we could go straight
      // to the URL channel instead of going to 0 first
      //
      // also causes [0] to be read unintentionally
      debug(
        "If no channel and we're not initialized, set the channel to the first one in the list"
      );
      dispatch(setChannel(channelList[0]));
      setInitialized(true);
    }
  }, [channelList, initialized, channel, dispatch]);

  if (!viewIsReady) {
    return <AppLoadingView screenProps={screenProps} />;
  }

  return (
    <>
      <SafeAreaView style={styles.wrapper}>
        <View
          style={{
            width: 375,
            borderRightWidth: 1,
            borderRightColor: Colors.N100,
          }}
        >
          <View style={styles.conversationsWrapper}>
            <LeftNavButton type={LeftNavType.Dismiss} title='' />
            <KitText black bold fontSize={24}>
              Conversations
            </KitText>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {Platform.OS !== 'web' && (
                <KitTouchableIcon
                  name='settings'
                  onPress={navToChatSettings}
                  size={25}
                />
              )}

              {Platform.OS === 'web' &&
                (canCreateChannels || canCreateDirectMessage) && (
                  <Popup
                    ref={menuRef}
                    // @ts-ignore ('manual' is used here so popup doesn't hijack the button click event)
                    on='manual'
                    trigger={
                      <button
                        style={{
                          borderWidth: 0,
                          backgroundColor: Colors.N50,
                          width: 36,
                          height: 36,
                          borderRadius: '100%',
                          cursor: 'pointer',
                        }}
                        onClick={handleComposeClick}
                      >
                        <KitIcon name='edit-alt' size={20} />
                      </button>
                    }
                    position='bottom right'
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div>
                      <Dropdown>
                        {canCreateDirectMessage && (
                          <ActionSheetItem
                            icon='messaging'
                            label='New message'
                            onPress={navToCreateDirect}
                          />
                        )}
                        {canCreateChannels && (
                          <ActionSheetItem
                            icon='channel'
                            label='New group'
                            onPress={navToCreateChannel}
                          />
                        )}
                      </Dropdown>
                    </div>
                  </Popup>
                )}
            </View>
          </View>
          <ChannelListScreen screenProps={screenProps} />
          <View style={styles.discoverWrapper}>
            <KitTouchable
              style={{
                paddingVertical: Spacing.m,
                backgroundColor: Colors.N50,
                flex: 1,
                borderRadius: 7,
              }}
              onPress={navToDiscover}
            >
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <KitIcon name='discover' size={20} />
                <KitText
                  black
                  fontWeight={600}
                  fontSize={16}
                  style={{ marginLeft: Spacing.m }}
                >
                  Discover
                </KitText>
              </View>
            </KitTouchable>
          </View>
        </View>
        <View style={{ flex: 1, backgroundColor: Colors.N0 }}>
          {channel ? (
            <>
              {/* Non-empty state navigator */}
              <ChatScreenHeader route={route} channel={channel} />
              <MainChatTabs />
            </>
          ) : (
            <ChatStack.Navigator
              initialRouteName={EMPTY_SCREEN}
              screenOptions={{
                headerShown: false,
                // @ts-ignore
                headerTopInsetEnabled: false, // react-native-screens (Android)
              }}
            >
              {/* Empty state navigator */}
              <ChatStack.Screen
                name={EMPTY_SCREEN}
                component={EmptyScreen}
                options={{ title: appTitle }}
              />
              <ChatStack.Screen
                name={DISCOVER_TAB}
                options={{ title: appTitle }}
              >
                {() => <Groups appKey={appKey} />}
              </ChatStack.Screen>
              <ChatStack.Screen
                name={JOIN_REQUESTS_SCREEN}
                component={JoinRequestsScreen}
                options={{ title: 'Join Requests' }}
              />
            </ChatStack.Navigator>
          )}
        </View>
      </SafeAreaView>
    </>
  );
}

export function MainChatTabs(): JSX.Element {
  const appKey = useSelector(appKeySelector);
  const appTitle = useSelector(appTitleSelector);
  const channel = useSelector(channelSelector);

  return (
    <ChatStack.Navigator
      initialRouteName={CHAT_SCREEN}
      screenOptions={{
        headerShown: false,
        // @ts-ignore
        headerTopInsetEnabled: false, // react-native-screens (Android)
      }}
    >
      <ChatStack.Screen name={DISCOVER_TAB} options={{ title: appTitle }}>
        {() => <Groups appKey={appKey} />}
      </ChatStack.Screen>
      <ChatStack.Screen
        name={CHAT_SCREEN}
        component={ChatScreen}
        options={{ title: appTitle }}
        initialParams={{ channelId: getShortChannelUrl(channel?.url) }}
      />
      <ChatStack.Screen
        name={PRAYER_LIST_SCREEN}
        component={PrayerListScreen}
        options={{ title: appTitle }}
        initialParams={{ channelId: getShortChannelUrl(channel?.url) }}
      />
      <ChatStack.Screen
        name={CHAT_THREAD_SCREEN}
        component={ChatScreen}
        options={{ title: appTitle }}
        initialParams={{ channelId: getShortChannelUrl(channel?.url) }}
      />
      <ChatStack.Screen
        name={THREADS_TAB}
        component={ThreadListScreen}
        options={{ title: appTitle }}
        initialParams={{ channelId: getShortChannelUrl(channel?.url) }}
      />
      <ChatStack.Screen
        name={CHANNEL_DETAILS_SCREEN}
        component={ChannelDetailsStack}
      />
    </ChatStack.Navigator>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: Colors.N0,
  },
  conversationsWrapper: {
    height: 65,
    paddingLeft: Spacing.l,
    paddingRight: Spacing.m,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.N100,
  },
  discoverWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    paddingVertical: Spacing.s,
    paddingHorizontal: Spacing.l,
    borderTopColor: Colors.N100,
  },
  chatHeader: {
    height: 65,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: Spacing.m,
    borderBottomColor: Colors.N100,
    borderBottomWidth: 1,
  },
});
