export const editorTemplate = {
  blocks: [
    {
      type: 'image',
      props: {
        averageColor: '#CCCCFF',
        imageUrl:
          'https://images.unsplash.com/photo-1598178265716-1b5664b69f67?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
        horizontalSpacing: 'medium',
        topSpacing: 'medium',
        bottomSpacing: 'large',
        imageRadius: 'medium',
        title: 'Image Block Title',
        subtitle: 'Image Block Subtitle',
        action: {
          handler: 'internalBrowser',
          url: 'https://example.com',
        },
      },
    },
    {
      type: 'text',
      props: {
        topSpacing: 'large',
        bottomSpacing: 'large',
        content: 'Horizontal List w/ Paging',
        h1: true,
      },
    },
    {
      type: 'actionRow',
      props: {
        horizontalSpacing: 'medium',
        title: 'Action Row',
        actionTitle: 'View all',
        action: {
          handler: 'blockPage',
          url: 'https://example.com/list/00000000-0000-0000-0000-000000000000.json',
        },
      },
    },
    {
      type: 'list',
      props: {
        title: 'Test',
        bottomSpacing: 'large',
        horizontalSpacing: 'medium',
        horizontal: true,
        pagingEnabled: true,
        listStyle: 'grid',
        itemStyle: 'wide',
        textPosition: 'overlay',
        textAlignmentHorizontal: 'center',
        textAlignmentVertical: 'end',
        indicatorRadius: 'small',
        more: {
          title: 'More',
          action: {
            handler: 'blockPage',
            url: 'https://example.com/list/00000000-0000-0000-0000-000000000000.json',
          },
        },
        data: [
          {
            title: 'New Media Title',
            subtitle: 'New Media Subtitle',
            image:
              'https://images.unsplash.com/photo-1598181780484-9a58d58496fb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 2',
            image:
              'https://images.unsplash.com/photo-1598178265716-1b5664b69f67?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 3',
            image:
              'https://images.unsplash.com/photo-1598146635303-dd566d5e3ac2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 4',
            image:
              'https://images.unsplash.com/photo-1598187776768-8c63b8885c4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 5',
            image:
              'https://images.unsplash.com/photo-1598130340413-5970fc015d43?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
        ],
      },
    },
    {
      type: 'text',
      props: {
        topSpacing: 'large',
        bottomSpacing: 'large',
        content: 'Horizontal List',
        h1: true,
      },
    },
    {
      type: 'list',
      props: {
        title: 'Test',
        bottomSpacing: 'large',
        horizontalSpacing: 'medium',
        horizontal: true,
        pagingEnabled: false,
        listStyle: 'grid',
        itemStyle: 'wide',
        textPosition: 'overlay',
        textAlignmentHorizontal: 'center',
        textAlignmentVertical: 'center',
        indicatorRadius: 'medium',
        more: {
          title: 'More',
          action: {
            handler: 'blockPage',
            url: 'https://example.com/list/00000000-0000-0000-0000-000000000000.json',
          },
        },
        data: [
          {
            title: 'New Media Title',
            subtitle: 'New Media Subtitle',
            image:
              'https://images.unsplash.com/photo-1598181780484-9a58d58496fb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 2',
            image:
              'https://images.unsplash.com/photo-1598178265716-1b5664b69f67?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 3',
            image:
              'https://images.unsplash.com/photo-1598146635303-dd566d5e3ac2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 4',
            image:
              'https://images.unsplash.com/photo-1598187776768-8c63b8885c4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 5',
            image:
              'https://images.unsplash.com/photo-1598130340413-5970fc015d43?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
        ],
      },
    },
    {
      type: 'text',
      props: {
        topSpacing: 'large',
        bottomSpacing: 'large',
        content: 'Vertical List',
        h1: true,
      },
    },
    {
      type: 'actionRow',
      props: {
        horizontalSpacing: 'medium',
        title: 'Recent',
        actionTitle: 'View all',
        action: {
          handler: 'blockPage',
          url: 'https://example.com/list/00000000-0000-0000-0000-000000000000.json',
        },
      },
    },
    {
      type: 'list',
      props: {
        bottomSpacing: 'large',
        listStyle: 'rows',
        itemStyle: 'square',
        indicatorRadius: 'circle',
        marginsEnabled: true,
        more: {
          title: 'More',
          action: {
            handler: 'blockPage',
            url: 'https://example.com/list/00000000-0000-0000-0000-000000000000.json',
          },
        },
        data: [
          {
            title: 'New Media Title',
            subtitle: 'New Media Subtitle',
            image: 'https://pbs.twimg.com/media/EewDirxUMAA8O_Y.jpg',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 2',
            image:
              'https://images.unsplash.com/photo-1598184613402-601e122198f1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w={width}&q=60',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 3',
            subtitle: 'With a missing image',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
        ],
      },
    },
    {
      type: 'image',
      props: {
        bottomSpacing: 'large',
        title: 'Commercial Crew',
        titleTextColor: '#FFFFFF',
        averageColor: '#123460',
        imageUrl: 'https://pbs.twimg.com/media/EewDirxUMAA8O_Y.jpg',
      },
    },
    {
      type: 'text',
      props: {
        content:
          'On the afternoon of May 30th, SpaceX is slated to launch its very first passengers to space, potentially heralding a new era of human spaceflight for the United States. It’ll be the first time in nearly a decade that people have launched to orbit from American soil, and it’ll be the first time that a private vehicle takes them there.',
      },
    },
    {
      type: 'list',
      props: {
        topSpacing: 'large',
        bottomSpacing: 'large',
        data: [
          {
            title: 'New Media Title',
            subtitle: 'New Media Subtitle',
            image: 'https://pbs.twimg.com/media/EewDirxUMAA8O_Y.jpg',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 2',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 3',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 4',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
        ],
      },
    },
    {
      type: 'circleButtonRow',
      props: {
        topSpacing: 'large',
        bottomSpacing: 'large',
        buttons: [
          {
            type: 'default',
            title: 'Share',
            icon: 'share',
            action: {
              handler: 'share',
              shareUrl: 'https://core.stage.subsplash.net/shortlinks/xnkcj3g',
              shareBody: 'Thomas the Train',
            },
          },
          {
            type: 'default',
            title: 'Save for later',
            icon: 'download',
            action: {
              handler: 'browser',
              contentUrl: 'https://spacex.com',
            },
          },
        ],
      },
    },
    {
      type: 'text',
      props: {
        h1: true,
        bottomSpacing: 'small',
        content: 'The Launch',
      },
    },
    {
      type: 'text',
      props: {
        content:
          'SpaceX’s Falcon 9 rocket will take off from the company’s launch site at NASA’s Kennedy Space Center in Cape Canaveral, Florida. Known as Launch Complex 39A, it’s seen launches of the Space Shuttle as well as Saturn V rockets that sent humans to the Moon. SpaceX started leasing the complex from NASA in 2014 and transformed the launchpad to support flights of the company’s Falcon 9 and Falcon Heavy rockets.',
      },
    },
    {
      type: 'list',
      props: {
        topSpacing: 'large',
        bottomSpacing: 'large',
        style: 'horizontal',
        data: [
          {
            title: 'New Media Title',
            subtitle: 'New Media Subtitle',
            image: 'https://pbs.twimg.com/media/EewDirxUMAA8O_Y.jpg',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 2',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 3',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
          {
            title: 'Media Item 4',
            action: {
              handler: 'browser',
              contentUrl: 'https://www.spacex.com',
              style: 'internal',
            },
          },
        ],
      },
    },
    {
      type: 'image',
      props: {
        topSpacing: 'large',
        bottomSpacing: 'large',
        imageUrl:
          'https://images.unsplash.com/photo-1446776811953-b23d57bd21aa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
        averageColor: '#a18b7a',
      },
    },
    {
      type: 'text',
      props: {
        h1: true,
        bottomSpacing: 'small',
        content: 'The Docking',
      },
    },
    {
      type: 'text',
      props: {
        bottomSpacing: 'small',
        content:
          'If all goes as planned, it’s a quick trip to Earth orbit for the two astronauts. The Falcon 9 rocket will release the Crew Dragon into low Earth orbit about 12 minutes after takeoff. The rocket will then return to Earth where it is scheduled to land on a drone ship in the Atlantic Ocean.',
      },
    },
    {
      type: 'button',
      props: {
        title: 'Watch Live',
        topSpacing: 'small',
        bottomSpacing: 'large',
        action: {
          handler: 'browser',
          contentUrl: 'https://www.spacex.com',
          style: 'internal',
        },
      },
    },
  ],
};
