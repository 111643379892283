import { ISession } from '@omni/kit/services/EventsService/Types';
import { useMemo } from 'react';

export default function useSessionTypes(sessions: ISession[] = []): {
  adultSessions: ISession[];
  childSessions: ISession[];
} {
  const childSessions = useMemo(() => {
    return sessions.filter(
      (session) => session.type === 'child' || session.type === 'everyone'
    );
  }, [sessions]);
  const adultSessions = useMemo(() => {
    return sessions.filter(
      (session) => session.type === 'adult' || session.type === 'everyone'
    );
  }, [sessions]);

  return {
    adultSessions,
    childSessions,
  };
}
