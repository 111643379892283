import { App } from '@omni/kit/services/Types';

/**
 * A helper function intended to be used in screens that need to customize text labels
 * based on whether a container app is designed for churches, sites or campuses.
 *
 * @param {App} rootApp - a root App object, see service call via search/utils/RootAppContext.tsx
 *
 * @returns {string} memberAppsLabel  - 'SITES|CAMPUSES|CHURCHES'
 */

type MemberAppsLabel = 'SITES' | 'CAMPUSES' | 'CHURCHES';

export type Variants = {
  [index in MemberAppsLabel]: string;
};

export const CONTEXT_SITE = 'site';
export const CONTEXT_CAMPUS = 'campus';
export const CONTEXT_CHURCH = 'church';

export default (rootApp: App | undefined): string => {
  const memberAppsLabel: MemberAppsLabel =
    (rootApp?._embedded?.['container-app-info']
      ?.member_apps_label as MemberAppsLabel) || 'CHURCHES';

  const variants: Variants = {
    SITES: CONTEXT_SITE,
    CAMPUSES: CONTEXT_CAMPUS,
    CHURCHES: CONTEXT_CHURCH,
  };

  return variants[memberAppsLabel];
};
