/**
 * If a `rev:*` revision is present, add to the root url to include it and
 * make sure omni retains the revision.
 * @example
 * Navigating to URL `https://url.com/rev:some-slug/route/in/app`,
 * make a rootURL of `/rev:some-slug`.
 * ref: https://subsplash.atlassian.net/wiki/spaces/CS/pages/2036957208/How+to+convert+a+repo+to+use+auto+tagging+and+semantic+release
 *
 *
 * e.g. https://messaging.stage.subsplash.net/CHURCH
 * Root URL = '/'
 *
 * e.g. https://messaging.stage.subsplash.net/rev:some-slug/CHURCH
 * Root URL = '/rev:some-slug/'
 */

import { Platform } from 'react-native';

const RevisionMatcher = /^\/(rev:[\w\d_+%:=-]+)\b/;

export default (): string => {
  if (Platform.OS !== 'web' || typeof window === 'undefined') return '';

  const [, match] = RevisionMatcher.exec(window.location.pathname) ?? [];

  return match ? `/${match}/` : '/';
};
