// @ts-ignore
import { DEV_NAV_TO_BLOCK_PAGE_INDEX } from '@env';
import KitButton from '@omni/kit/components/KitButton';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { NativeTopBarStyle } from '@omni/kit/utilities/NativeHelpers';
import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';

import BlockPageApp from './BlockPageApp';
import { sandboxData } from './templates/examples/SandboxData';

type PageItem = {
  data?: unknown;
  theme?: 'dark' | 'light';
  title: string;
  topBarStyle: NativeTopBarStyle;
  url?: string;
};

const staticMediaDetailData = require('./templates/examples/staticMediaDetail.json');
const liveMediaDetailData = require('./templates/examples/liveMediaDetail.json');
const profileData = require('./templates/examples/profile.json');
const textTestData = require('./templates/examples/textTest.json');
const buttonTestData = require('./templates/examples/buttonTest.json');
const buttonGroupTestData = require('./templates/examples/buttonGroupTest.json');
const whatsNewData = require('./templates/examples/whatsNew.json');
const listTestData = require('./templates/examples/listTest.json');
const eventsData = require('./templates/examples/events.json');
const accountData = require('./templates/examples/account.json');
const facebookAccountData = require('./templates/examples/facebookAccount.json');
const ligonierAccountData = require('./templates/examples/ligonierAccount.json');
const allData = require('./templates/examples/all.json');

const pages: PageItem[] = [
  {
    title: 'Sandbox',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: sandboxData,
  },
  {
    title: 'Hosted Media Detail',
    topBarStyle: NativeTopBarStyle.Transparent,
    url: 'https://api.npoint.io/30bf26dbdde2d6e21730',
  },
  {
    title: 'Static Media Detail',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: staticMediaDetailData,
  },
  {
    title: 'Live Media Detail',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: liveMediaDetailData,
  },
  {
    title: 'Profile',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: profileData,
  },
  {
    title: 'Whats New',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: whatsNewData,
  },
  {
    title: 'Text Test',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: textTestData,
  },
  {
    title: 'Button Test',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: buttonTestData,
  },
  {
    title: 'Button Group Test',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: buttonGroupTestData,
  },
  {
    title: 'List Test',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: listTestData,
  },
  {
    title: 'Account',
    topBarStyle: NativeTopBarStyle.Hidden,
    data: accountData,
    theme: 'light',
  },
  {
    title: 'Facebook Account',
    topBarStyle: NativeTopBarStyle.Hidden,
    data: facebookAccountData,
    theme: 'light',
  },
  {
    title: 'Ligonier Account',
    topBarStyle: NativeTopBarStyle.Hidden,
    data: ligonierAccountData,
    theme: 'light',
  },
  {
    title: 'Events Test',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: eventsData,
  },
  {
    title: 'All Block Test',
    topBarStyle: NativeTopBarStyle.Transparent,
    data: allData,
  },
];

const guestToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODc3NDc2NTcsInN1YiI6IjdiMDgwYjRmLWRhZDctNGE2MS1hMGViLWI0NGQ3YWJhMmY3NSIsImdsb2JhbF9yb2xlcyI6WyJhNWY0ZjkxMS03NjMzLTRlN2YtOGViYi1kMTYxZmU0Zjg1OWEiXX0.exabOyO3W7vK0shbOf0URJZcKNpQ9Mx0O3-3IcFA70g';

export default function Blocks(): JSX.Element {
  useEffect((): void => {
    // The .env may specify which module should be automatically dispatched.
    // This is useful when testing changes in a specific block page locally,
    // to avoid needing to manually tap on the button each time the package server is reloaded.
    const index = DEV_NAV_TO_BLOCK_PAGE_INDEX;

    if (index && index >= 0 && index < pages.length) {
      const item = pages[index];
      const page = (
        <BlockPageApp
          appKey={'CHURCH'}
          data={item.data}
          // @ts-ignore
          topBarStyle={item.topBarStyle}
          guestToken={guestToken}
          // @ts-ignore
          url={item.url}
        />
      );
      setSelectedPage(page);
    }
  }, []);

  const [selectedPage, setSelectedPage] = useState<JSX.Element | null>(null);

  const _onPress = (item: PageItem) => {
    const page = (
      <BlockPageApp
        appKey={'CHURCH'}
        data={item.data}
        guestToken={guestToken}
        topBarStyle={item.topBarStyle}
        url={item.url}
      />
    );
    setSelectedPage(page);
  };

  const _renderList = ({ item }: { item: PageItem }): JSX.Element => {
    return (
      <KitButton
        marginValue='20px 0 0 0'
        onPress={() => _onPress(item)}
        secondary
        title={item.title}
      />
    );
  };

  return (
    <SafeAreaProvider>
      {selectedPage ? (
        selectedPage
      ) : (
        <SafeAreaView
          style={[
            styles.container,
            { backgroundColor: colorForScheme({ default: Colors.N0 }) },
          ]}
        >
          <FlatList
            data={pages}
            keyboardShouldPersistTaps='handled'
            keyExtractor={(_, index) => index.toString()}
            renderItem={_renderList}
            style={styles.list}
            testID='ScrollView'
          />
        </SafeAreaView>
      )}
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: Spacing.l,
  },
  list: {
    height: '100%',
  },
});
