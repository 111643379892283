import { Geometry, Place } from '@omni/kit/placeTypes';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import WebView, { WebViewMessageEvent } from 'react-native-webview';

import Environment from '@omni/kit/Environment';
import { VALUE_CURRENT_LOCATION } from '../../Constants';
import { View } from 'react-native';
import { debounce } from 'lodash';

const debug = require('debug')(
  'tca:search:screens:Search:KitPlacesAutocomplete'
);

interface Props {
  value?: string;
  selectedPlace?: Place;
  setPlaces: (places: Place[]) => void;
  setSelectedPlaceWithGeometry: (place?: Place) => void;

  // only enable as a developer making fixes/improvements for places autocomplete
  developerModeEnabled?: boolean;
}

interface WebViewMessageData {
  type: 'initialize' | 'places' | 'geometry';
  input?: string;
  places?: Place[];
  geometry?: Geometry;
}

export default ({
  value,
  setPlaces,
  selectedPlace,
  setSelectedPlaceWithGeometry,
  developerModeEnabled = false,
}: Props): JSX.Element => {
  const webRef = useRef<WebView | null>(null);
  const [isServiceReady, setIsServiceReady] = useState(false);

  const fetchPlaces = useCallback((input: string): void => {
    webRef.current?.injectJavaScript(`window.emitPlacesForInput('${input}')`);
  }, []);

  const fetchGeometry = useCallback((placeId: string): void => {
    webRef.current?.injectJavaScript(
      `window.emitGeometryForInput('${placeId}')`
    );
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchPlaces = useCallback(debounce(fetchPlaces, 500), [
    fetchPlaces,
  ]);

  // Obtain new place suggestions from Google Places
  // whenever a user changes the input value
  useEffect(() => {
    if (setPlaces !== undefined) {
      if (
        value !== undefined &&
        Boolean(value) &&
        value !== VALUE_CURRENT_LOCATION
      ) {
        debouncedFetchPlaces(value);
      } else {
        setPlaces([]);
      }
    }
  }, [isServiceReady, setPlaces, value, debouncedFetchPlaces]);

  // User selected one of the 'places' in location search results
  // So we need to obtain the geometry for consumption by parent component
  useEffect(() => {
    const placeId = selectedPlace?.place_id;

    if (
      placeId !== undefined &&
      Boolean(placeId) &&
      VALUE_CURRENT_LOCATION !== placeId
    ) {
      fetchGeometry(placeId);
    }
  }, [selectedPlace, fetchGeometry]);

  const onReceivedMessageFromWebView = (event: WebViewMessageEvent) => {
    try {
      const jsonData = event.nativeEvent.data;
      const data: WebViewMessageData = JSON.parse(jsonData);

      switch (data.type) {
        case 'initialize':
          setIsServiceReady(true);
          break;
        case 'places': {
          if (data.input === value) {
            const places = data.places ?? [];
            setPlaces(places);
          }

          break;
        }
        case 'geometry': {
          if (data.input === selectedPlace?.place_id) {
            const place: Place | undefined = selectedPlace
              ? {
                  ...selectedPlace,
                  geometry: data.geometry,
                }
              : undefined;
            setSelectedPlaceWithGeometry(place);
          }

          break;
        }
        default:
          break;
      }
    } catch (error) {
      debug(`Unable to parse message: ${error}`);
    }
  };

  const key: string = Boolean(developerModeEnabled)
    ? Environment.googleMapsKeyDev
    : Environment.googleMapsKey;

  const injectedHtml = () => `
    <script async
      src="https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initialize">
    </script>


    <script>

    const d_log = (value) => {
      if (${developerModeEnabled}) {
        document.body.innerHTML = \`\${value}\`
      }
    }

    if (${developerModeEnabled}) {
      window.console.error = function(error) {
        d_log(\`Error: \${JSON.stringify(error)}\`)
      }
    }

    function gm_authFailure() {
      window.authFailure='Missing or invalid API Key';
    }

    function initialize() {
      d_log(\`WebView:initialize:started\`);

      if (window.authFailure) {
        return;
      }

      window.autocompleteService = new google.maps.places.AutocompleteService();
      window.sessionToken = new google.maps.places.AutocompleteSessionToken();

      // ref: https://stackoverflow.com/questions/14343965/google-places-library-without-map
      const mapPlaceholder = document.createElement('div');
      window.placesService = new google.maps.places.PlacesService(mapPlaceholder);

      if (window.ReactNativeWebView !== undefined) {
        const data = {
          type: 'initialize',
        }
        const jsonData = JSON.stringify(data)
        window.ReactNativeWebView.postMessage(jsonData);
      }

      d_log(\`WebView:initialize:finished\`);
    }

    window.emitPlacesForInput = (input) => {

      if (window.authFailure) {
        return;
      }

      if (window.autocompleteService !== undefined && window.sessionToken !== undefined) {
        window.autocompleteService.getPlacePredictions({
          input: input,
          types: ['geocode'],
          sessionToken: window.sessionToken,
        }).then((response) => {
          try {
            const places = response.predictions.map((prediction) => {
              return { description: prediction.description, place_id: prediction.place_id }
            });

            if (window.ReactNativeWebView !== undefined) {
              const data = {
                type: 'places',
                input: input,
                places: places,
              };
              const jsonData = JSON.stringify(data);
              window.ReactNativeWebView.postMessage(jsonData);
            }
            d_log(\`WebView:places:response:success:\${JSON.stringify(places)}\`);
          } catch (parseError) {
            d_log(\`WebView:places:response:error:\${JSON.stringify(parseError)}\`);
          }
        }).catch((error) => {
          d_log(\`WebView:places:api_error:\${JSON.stringify(error)}\`);
        });
      }
    }

    // ref: https://developers.google.com/maps/documentation/javascript/places#place_details_requests
    window.emitGeometryForInput = (input) => {
      if (window.placesService !== undefined) {
        const request = {
          placeId: input,
          fields: ['place_id', 'geometry'],
        };
        
        window.placesService.getDetails(request, geometryCallback);
      }

      function geometryCallback(place, status) {
        d_log(\`WebView:places:geometryCallback:\${JSON.stringify(place)}\`);
  
        if (window.ReactNativeWebView !== undefined) {
  
          const location = place.geometry.location;
          const data = {
            type: 'geometry',
            input: input,
            geometry: location,
          };
  
          const jsonData = JSON.stringify(data)
          window.ReactNativeWebView.postMessage(jsonData);
        }
      }
    }

    </script>
    `;

  // In developer mode, we render the web view for debugging purposes
  // This allows developers to see the raw responses or errors rendered within the webview
  if (developerModeEnabled) {
    const width = 400;
    const height = 200;

    return (
      <View style={{ width, height }}>
        <WebView
          ref={webRef}
          source={{ html: injectedHtml() }}
          style={[{ width, height }]}
          onMessage={onReceivedMessageFromWebView}
        />
      </View>
    );
  }

  return (
    <WebView
      ref={webRef}
      source={{ html: injectedHtml() }}
      style={{ width: 0, height: 0, opacity: 0 }}
      onMessage={onReceivedMessageFromWebView}
    />
  );
};
