import LeftNavButton, { LeftNavType } from '@omni/kit/components/LeftNavButton';
import { IModuleCommand } from '@omni/kit/contexts/types';
import Spacing from '@omni/kit/theming/Spacing';
import { dismissReactNativeModal } from '@omni/kit/utilities/NativeHelpers';
import { isWithinIframe } from '@omni/kit/utilities/utilities';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import AuthProviderList from './AuthProviderList';
import { AUTH_PROVIDERS_LIST_SCREEN, COMMAND_SET_TOKEN } from './Constants';
import { AuthStackParamsList } from './Types';

const AuthStack = Platform.select({
  default: createStackNavigator(),
  web: createStackNavigator(),
});

interface AuthNavigatorProps {
  appKey: string;
  customReturnUrl?: string;
  dismiss?: () => void | null;
  headerShown?: boolean;
  orgKey?: string;
  route?: RouteProp<AuthStackParamsList, 'auth_stack'>;
  screenProps?: {
    accessToken?: string;
    moduleCommand?: IModuleCommand;
    sapToken?: string;
    tokenUrl?: string;
  };
  targetAuthProviderId: string;
}

function AuthNavigator({
  appKey,
  customReturnUrl,
  dismiss,
  headerShown = true,
  orgKey,
  targetAuthProviderId,
}: AuthNavigatorProps): JSX.Element {
  const resetNav = () => {
    dismissReactNativeModal();
  };

  return (
    <SafeAreaProvider>
      <AuthStack.Navigator
        initialRouteName={AUTH_PROVIDERS_LIST_SCREEN}
        screenOptions={{
          headerShown,
          headerStyle: {
            borderBottomWidth: 0,
            elevation: 0,
            shadowColor: 'transparent',
            shadowOpacity: 0,
          },
        }}
      >
        <AuthStack.Screen
          name={AUTH_PROVIDERS_LIST_SCREEN}
          options={() => ({
            headerLeft: () => (
              <View
                style={{
                  marginLeft: Spacing.xl, // alignment with block page dismiss button
                  marginTop: -4, // alignment with block page dismiss button
                }}
              >
                <LeftNavButton
                  iconSize={20}
                  onPress={dismiss || resetNav}
                  title=''
                  type={LeftNavType.Dismiss}
                />
              </View>
            ),
            title: '',
          })}
        >
          {(props) => (
            <AuthProviderList
              {...props}
              appKey={appKey}
              orgKey={orgKey}
              customReturnUrl={customReturnUrl}
              targetAuthProviderId={targetAuthProviderId}
              openInNewTab={isWithinIframe()}
            />
          )}
        </AuthStack.Screen>
      </AuthStack.Navigator>
    </SafeAreaProvider>
  );
}

export default AuthNavigator;
