import { KitButton, KitIcon } from '@omni/kit/components';
import KitText from '@omni/kit/components/KitText';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

export default ({
  errorCode,
  onRetry,
}: {
  errorCode: number;
  onRetry: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation(['error']);

  return (
    <View style={{ alignItems: 'center' }}>
      <KitIcon name='disconnected' size={44} />
      <KitText h1 style={styles.heading}>
        {t('error:errorHeading')}
      </KitText>
      <KitText fontSize={14} style={styles.message}>
        {t('error:errorMessage')}
      </KitText>
      <KitText black fontSize={12} style={styles.errorCode}>
        {t('error:errorCode')} {errorCode}
      </KitText>
      <KitButton
        color={Colors.N1000}
        icon='replay'
        iconSize={14}
        onPress={onRetry}
        testID='retryButton'
        title={t('error:retryButton')}
        style={styles.retryButton}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  heading: {
    marginTop: Spacing.l,
  },
  message: {
    marginTop: Spacing.s,
  },
  errorCode: {
    marginTop: Spacing.l,
    backgroundColor: Colors.N100,
    borderRadius: 12,
    lineHeight: undefined,
    overflow: 'hidden',
    paddingHorizontal: Spacing.s,
    paddingVertical: 5,
  },
  retryButton: {
    marginTop: Spacing.xl,
  },
});
