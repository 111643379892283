import {
  IMediaPlays,
  IPrepareMediaPlaysUrl,
  prepareMediaPlaysUrl,
} from '../index';
import BaseServiceV2, { IHttpResponse } from '../../../BaseServiceV2';

import TokensService from '../../../TokensService/TokensService';

const debug = require('debug')(
  'tca:service:MediaPersonalizationService:MediaPlayService:Core:GetOne'
);

export enum MEDIA_FORMAT {
  AUDIO = 'audio',
  VIDEO = 'video',
}

interface IMediaPlayCreateBody {
  app_key: string;
  duration_ms?: number;
  last_media_format: MEDIA_FORMAT;
  play_count?: number;
  progress_ms?: number;
  _embedded: {
    'media-item': {
      id: string;
    };
    'end-user': {
      id: string;
    };
  };
}

interface IMediaPlaysCreate extends IPrepareMediaPlaysUrl {
  appKey?: string;
  data: IMediaPlayCreateBody;
}

interface IMediaPlaysCreateResponse extends IHttpResponse {
  body?: IMediaPlays;
}

export default async function Create({
  appKey,
  data,
  ...buildUrlProps
}: IMediaPlaysCreate): Promise<IMediaPlaysCreateResponse> {
  debug('Create');
  const token = await TokensService.getGuestToken();
  const url = prepareMediaPlaysUrl(buildUrlProps);

  return BaseServiceV2.Post({
    url,
    appKey,
    token,
    data,
  });
}
