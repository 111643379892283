import Depth from '@omni/kit/theming/Depth';
import React from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import KitText from '../KitText';

interface IKitInputValidationMessageProps {
  children: React.ReactNode;
  message: string | null;
  /**
   * @deprecated
   * Leave undefined to show the message when provided.
   * Message will automatically be hidden if the message is null or empty.
   * Set isNotValid to false to hide the message, even when the message is provided.
   */
  isNotValid?: boolean;
  messageStyle?: StyleProp<ViewStyle>;
  testID?: string;
}

export default function KitInputValidationMessage({
  children,
  message,
  isNotValid,
  messageStyle,
  testID,
}: IKitInputValidationMessageProps): JSX.Element {
  const showMessage = message && isNotValid !== false;
  const [height, setHeight] = React.useState<number>(0);

  return (
    <View>
      {children}
      {showMessage && (
        <View
          style={[
            styles.container,
            { bottom: -(height + Spacing.xs) },
            messageStyle,
            { opacity: height ? 1 : 0 },
          ]}
          onLayout={(event) => setHeight(event.nativeEvent.layout.height)}
          testID={testID}
        >
          <KitText color={Colors.N0} fontSize={16}>
            {message}
          </KitText>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: Colors.R500,
    paddingHorizontal: Spacing.l,
    paddingVertical: Spacing.m,
    borderRadius: Spacing.s,
    maxWidth: 360,
    zIndex: 1,
    ...Depth.xxl,
  },
});
