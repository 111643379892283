import moment from 'moment';

/** Time units in ms for convenience */
export const Time = {
  Second: 1000,
  Minute: 60 * 1000,
  Hour: 60 * 60 * 1000,
  Day: 24 * 60 * 60 * 1000,
};

const MAX_SNOOZE_DAYS = 2;

export function getSnoozeUntilText(snoozedUntil: number): string {
  const now = Date.now();

  /**
   * Because we don't have any option in the UI for a time period greater than
   * 2 days, if the difference is greater than 2 days it must mean that the user
   * has selected the "Until I turn them off" option.
   */
  if (snoozedUntil - now > MAX_SNOOZE_DAYS * Time.Day) {
    return 'you turn them back on';
  }

  const snoozeUntilDate = moment(snoozedUntil);
  const endOfYesterday = moment(now).subtract(1, 'day').endOf('day');
  const endOfToday = moment(now).endOf('day');
  const endOfTomorrow = moment(now).add(1, 'day').endOf('day');

  if (snoozeUntilDate > endOfYesterday && snoozeUntilDate < endOfToday) {
    // today
    return snoozeUntilDate.format('h:mm A');
  } else if (
    snoozeUntilDate > endOfYesterday &&
    snoozeUntilDate < endOfTomorrow
  ) {
    // tomorrow
    return 'tomorrow at ' + snoozeUntilDate.format('h:mm A');
  } else {
    return snoozeUntilDate.format('MMMM D [at] h:mm A');
  }
}
