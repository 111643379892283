import { KitIcon, KitText } from '@omni/kit/components';
import { IconName } from '@omni/kit/components/KitIcon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export interface AuthProviderProps {
  loggedInAuthProvider?: { title?: string; icon?: IconName; color?: string };
  email: string;
}

const AuthProvider: React.FC<AuthProviderProps> = ({
  loggedInAuthProvider,
  email,
}): JSX.Element => {
  return (
    <View style={styles.container}>
      {loggedInAuthProvider?.icon !== 'email' && (
        <KitIcon
          name={loggedInAuthProvider?.icon}
          size={36}
          color={loggedInAuthProvider?.color ?? Colors.brand}
          style={{ marginBottom: Spacing.m }}
        />
      )}
      <KitText
        bold
        style={{ marginBottom: Spacing.s, color: Colors.N900 }}
        testID='AuthProvider'
      >
        Logged in with {loggedInAuthProvider?.title}
      </KitText>
      <KitText>{email}</KitText>
    </View>
  );
};

export default AuthProvider;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 48,
    marginTop: Spacing.xxl,
  },
});
