import { IKeyboardEvent, KeyboardContextType } from './types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  registerKeyboardListeners,
  unregisterKeyboardListeners,
} from './keyboardListeners';

/**
 * Hook into native keyboard events
 *
 * Main use case:
 * 1. detect keyboard visibility
 * 2. determine keyboard dimension
 *
 * @param {JSX.Element} children
 * @returns
 */
export default function KeyboardContextProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [keyboardEvent, setKeyboardEvent] = useState(initialKeyboardEvent);

  useEffect(() => {
    registerKeyboardListeners(setKeyboardEvent);
    return () => {
      unregisterKeyboardListeners();
    };
  }, []);

  return (
    <KeyboardContext.Provider
      value={{
        keyboardEvent,
      }}
    >
      {children}
    </KeyboardContext.Provider>
  );
}

export const useKeyboardContext = (): KeyboardContextType =>
  useContext<KeyboardContextType>(KeyboardContext);

const initialKeyboardEvent: IKeyboardEvent = {
  duration: 250,
  easing: 'keyboard',
  endCoordinates: { height: 0, screenX: 0, screenY: 0, width: 0 },
};

const initialKeyboardContext = {
  keyboardEvent: initialKeyboardEvent,
};

const KeyboardContext = createContext<KeyboardContextType>(
  initialKeyboardContext
);
