import { KitButton, KitText } from '@omni/kit/components';
import KitModal from '@omni/kit/components/KitModalV2';
import { SizeClassV2, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { UserMessage } from '@sendbird/chat/message';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useChatContext } from '../../context/ChatContext';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  selectedMessage: UserMessage | null;
}

export default ({
  isVisible,
  onClose,
  selectedMessage,
}: IProps): JSX.Element => {
  const { t } = useTranslation(['liveChat']);
  const insets = useSafeAreaInsets();
  const { confirmDeleteMessage } = useChatContext();
  const { sizeClassV2 } = useSizeClass();

  const isSmall = (sizeClassV2 as number) < SizeClassV2.L;

  const onCancel = () => {
    onClose?.();
  };

  const onConfirmDelete = () => {
    confirmDeleteMessage?.();
  };

  return (
    <KitModal isVisible={isVisible} anchorBottom={isSmall} onClose={onClose}>
      <View
        style={[
          { padding: Spacing.xl },
          isSmall
            ? { paddingBottom: insets.bottom + Spacing.xl, width: '100%' }
            : { width: 620 },
        ]}
      >
        <KitText bold fontSize={20} color={Colors.N900}>
          {t('liveChat:deleteMessageConfirmationTitle')}
        </KitText>
        <KitText
          color={Colors.N900}
          fontSize={isSmall ? 16 : 14}
          style={styles.modalBody}
        >
          {selectedMessage?.message}
        </KitText>
        <View
          style={[
            styles.modalFooter,
            !isSmall && {
              alignItems: 'center',
              flexDirection: 'row',
            },
          ]}
        >
          <KitButton
            secondary
            title={t('liveChat:deleteMessageConfirmationCancelButton')}
            onPress={onCancel}
            style={{
              marginRight: isSmall ? 0 : Spacing.m,
              marginTop: isSmall ? Spacing.l : 0,
            }}
          />
          <KitButton
            red
            title={t('liveChat:deleteMessageConfirmationDeleteButton')}
            onPress={onConfirmDelete}
          />
        </View>
      </View>
    </KitModal>
  );
};

const styles = StyleSheet.create({
  modalBody: {
    marginBottom: Spacing.l,
    marginTop: Spacing.s,
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
  },
});
