import { KitText } from '@omni/kit/components';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyle } from 'react-native';

import KioskInput from './KioskInput';

type Props = {
  value: string;
  labelStyle: TextStyle;
  onChangeText: (text: string) => void;
};

export default function AllergyNotesInput({
  value,
  labelStyle,
  onChangeText,
}: Props): JSX.Element {
  const { t } = useTranslation(['profile']);

  return (
    <KioskInput
      autoCorrect
      label={t('profile:textAllergiesOptional')}
      multiline={false}
      placeholder={t('check-in:placeholderTextNone')}
      labelStyle={labelStyle}
      value={value}
      style={{ width: 552, marginBottom: Spacing.l, zIndex: -3 }}
      inputStyle={{ height: 54, paddingRight: 50 }}
      onChangeText={onChangeText}
      maxLength={40}
      rightIcon={<KitText>{40 - value.length}</KitText>}
      rightIconStyle={{
        bottom: 14,
        height: 22,
        right: 18,
        width: 29,
      }}
    />
  );
}
