import { IHttpRequest } from '..';
import GetRequestHeaders from '../../../Downloader/GetRequestHeaders';
import { AuthProvider } from '../../../auth';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '../../../constants/identifiers';
import axiosRequest from '../Request';
import { IHttpResponse, handleError } from '../Response';

const debug = require('debug')('tca:service:BaseServiceV2:Core:Patch');

interface ICustom {
  appKey?: string;
  authProviderId?: string;
  orgKey?: string;
  token?: string;
  targetProviders?: AuthProvider[];
  impression?: boolean;
}

type IPost = IHttpRequest & ICustom;

export default async ({
  appKey,
  authProviderId = SUBSPLASH_AUTH_PROVIDER_ID,
  data,
  headers,
  token = '',
  targetProviders,
  url,
  impression = false,
}: IPost): Promise<IHttpResponse> => {
  debug('Url', url);

  const tokenStripped = token.replace('Bearer ', '');

  const requestHeaders = await GetRequestHeaders({
    url,
    options: {
      appKey,
      authProviderId,
      targetProviders,
      extraHeaders: {
        Authorization: `Bearer ${tokenStripped}`,
      },
    },
  });

  const extraHeaders = { ...requestHeaders, ...headers };

  return axiosRequest({
    url,
    data,
    options: {
      appKey,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      extraHeaders,
      impression,
      method: 'PATCH',
    },
  })
    .then((response) => ({
      body: response.data,
      status: response.status,
    }))
    .catch(handleError);
};
