import { FavoriteAppsProvider } from '@omni/kit/contexts/FavoriteAppsContext';
import { FeatureFlagProvider } from '@omni/kit/contexts/FeatureFlagContext';
import KeyboardContextProvider from '@omni/kit/contexts/KeyboardContext';
import { RootAppContextProvider } from '@omni/kit/contexts/RootAppContext';
import { SizeClassContextProvider } from '@omni/kit/contexts/SizeClassContext';
import unleash from '@omni/kit/services/UnleashService/unleashClient';
import ThemeContextWrapper from '@omni/kit/theming/ThemeContext';
import { ApplicationContextProvider } from '@omni/native-shell/contexts/ApplicationContext';
import React from 'react';
import { LogBox, Platform } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import App from './App';
import SearchScreenContextProvider from './SearchScreenContextProvider';
import NativePropWrapper from './components/NativePropWrapper';
import { ISearchRootProps } from './types';

if (Platform.OS !== 'web') {
  LogBox.ignoreAllLogs(true);
}

const debug = require('debug');
const appDebug = debug('omni:search');

export default (props: ISearchRootProps): JSX.Element => {
  appDebug('Launching Search App');

  return (
    <ApplicationContextProvider {...props}>
      <ThemeContextWrapper theme='light'>
        <SizeClassContextProvider>
          <NativePropWrapper props={props}>
            <SafeAreaProvider>
              <KeyboardContextProvider>
                <SearchScreenContextProvider>
                  <RootAppContextProvider props={props}>
                    <FavoriteAppsProvider>
                      <FeatureFlagProvider
                        appKey={props.appKey}
                        unleashInstance={unleash}
                      >
                        <App {...props} />
                      </FeatureFlagProvider>
                    </FavoriteAppsProvider>
                  </RootAppContextProvider>
                </SearchScreenContextProvider>
              </KeyboardContextProvider>
            </SafeAreaProvider>
          </NativePropWrapper>
        </SizeClassContextProvider>
      </ThemeContextWrapper>
    </ApplicationContextProvider>
  );
};
