import { SizeClass, WindowSizeMax } from './types';

export default (width: number): SizeClass => {
  let currentClass = SizeClass.Large;

  if (width <= WindowSizeMax.Small) {
    currentClass = SizeClass.Small;
  } else if (width <= WindowSizeMax.Medium) {
    currentClass = SizeClass.Medium;
  }

  return currentClass;
};
