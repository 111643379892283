import Environment from '../../../Environment';
import BaseServiceV2, {
  buildUrl,
  IHttpResponse,
  IUri,
} from '../../BaseServiceV2';
import { IGroup } from '../Types';

const RESOURCE = 'group';

interface IUpdateGroup extends Omit<IUri, 'resource'> {
  id: string;
  body: Partial<IGroup>;
  token: string;
}

interface IUpdateGroupResponse extends IHttpResponse {
  body?: IGroup;
}

export default ({
  id,
  body,
  token,
}: IUpdateGroup): Promise<IUpdateGroupResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    resource: RESOURCE,
    id,
  });

  return BaseServiceV2.Patch({ url, token, data: body });
};
