/** Page where the event is emitted from */
export enum EVENT_SERVICE_SOURCE {
  TRACK_MEDIA_PROGRESS = 'track-media-progress',
  PLAYER_OVERLAY = 'player-overlay',
}
export interface IListenerCallbackData {
  data?: any;
  source?: EVENT_SERVICE_SOURCE;
}

export type IListenerCallback = (data?: IListenerCallbackData) => void;

export interface IListenerObject {
  context?: string; // useful for debugging which contexts auth came from
  callback: IListenerCallback;
}

export enum EVENT_SERVICE_EVENTS {
  CHECK_IN_AUTHORIZATION_CHANGED = 'check-in-authorization-changed',
  UPDATED_MEDIA_PLAY_RESOURCE = 'updated-media-play-resource',
}
