import { Animated, Clipboard, Platform, StyleSheet, View } from 'react-native';
import { ChannelType, MessageType } from '../../../shared/redux/types';
import { CustomMessageData, SendbirdUserMessage } from '../../Types';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { UserMessage } from '@sendbird/chat/message';
import { KitModal, KitTouchable } from '@omni/kit/components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ActionSheetItem from './actionSheet/ActionSheetItem';
import Colors from '@omni/kit/theming/Colors';
import KitText from '@omni/kit/components/KitText';
import Spacing from '@omni/kit/theming/Spacing';
import { sbGetUsersById } from '../../../utilities/sendbird/channelFunctions';
import { sbToggleReaction } from '../../../utilities/sendbird/chatFunctions';
import { updateMessageType } from '../../../shared/redux/actions/ChatActions';
import { userIdSelector } from '../../../shared/redux/selectors';
import { User } from '@sendbird/chat';

const debug = require('debug')('omni:chat:components:MessageActionSheet');

interface ReactionEntry {
  key: string;
  users: User[];
}

interface MessageActionSheetProps {
  channel: GroupChannel;
  deleteMessage: () => void;
  onClose: () => void;
  onCopy: () => void;
  onReply?: () => void;
  onReportMessage?: () => void;
  selectedMessage: SendbirdUserMessage | null;
  selectedReaction: string | null;
  setActiveMessage?: (message: SendbirdUserMessage | null) => void;
  setIsAnsweringPrayer?: (value: boolean) => void;
  setIsEditing?: (value: boolean) => void;
  setSelectedMessage?: (message: SendbirdUserMessage | null) => void;
  setTextMessage?: (message: string) => void;
  showReplyAction?: boolean;
  simpleView?: boolean;
}

export default function MessageActionSheet({
  channel,
  deleteMessage,
  onClose,
  onCopy,
  onReply = () => null,
  onReportMessage = () => null,
  selectedMessage,
  selectedReaction,
  setActiveMessage = () => null,
  setIsAnsweringPrayer = () => null,
  setIsEditing = () => null,
  setSelectedMessage = () => null,
  setTextMessage = () => null,
  showReplyAction = true,
  simpleView = false,
}: MessageActionSheetProps): JSX.Element | null {
  const [modalVisible, setModalVisible] = useState(false);
  const [reactions, setReactions] = useState<ReactionEntry[]>([]);

  const userId = useSelector(userIdSelector);
  const dispatch = useDispatch();

  let data: CustomMessageData | null = null;

  if (selectedMessage && selectedMessage.data) {
    try {
      data = JSON.parse(selectedMessage.data);
    } catch (e) {}
  }

  const _replyToMessage = () => {
    setModalVisible(false);
    onReply();
  };

  const _deleteSelectedMessage = () => {
    deleteMessage();
  };

  const _copyMessageText = () => {
    Clipboard.setString((selectedMessage as UserMessage).message);
    setModalVisible(false);
    onCopy();
  };

  const _reportMessage = () => {
    setActiveMessage(selectedMessage);
    setModalVisible(false);
    onReportMessage();
  };

  const _navToPrayerScreen = () => {
    setActiveMessage(selectedMessage);
    setIsAnsweringPrayer(true);
    setModalVisible(false);
  };

  const _editSelectedMessage = () => {
    setActiveMessage(selectedMessage);
    setTextMessage((selectedMessage as UserMessage).message);
    setModalVisible(false);
    setTimeout(() => setIsEditing(true), 250);
  };

  const _convertToPrayerRequest = () => {
    dispatch(
      updateMessageType(
        channel as GroupChannel,
        selectedMessage?.messageId,
        MessageType.Prayer
      )
    );
    setActiveMessage(null);
    setModalVisible(false);
  };

  const _renderReactionNames = (reaction: ReactionEntry) => {
    let names = '';

    if (reactions.length) {
      reaction.users.forEach((user, index) => {
        const name = `${user.nickname}${
          reaction.users.length > 1 && index + 1 !== reaction.users.length
            ? ', '
            : ''
        }`;
        names = names + name;
      });
    }

    return (
      <KitText black fontSize={16}>
        {names}
      </KitText>
    );
  };

  const _renderReactionCount = (reaction: ReactionEntry) => {
    if (reaction.users.length <= 1) return;

    return (
      <View style={styles.reactionCountContainer}>
        <KitText black bold fontSize={12}>
          {reaction.users.length}
        </KitText>
      </View>
    );
  };

  const _renderContent = () => {
    if (selectedReaction) {
      return (
        <View style={styles.container}>
          {reactions.map((reaction) => (
            <View style={styles.reactionContainer} key={reaction.key}>
              <View style={{ marginEnd: 10, paddingEnd: 8 }}>
                <KitText black fontSize={32} style={{ lineHeight: 40 }}>
                  {reaction.key}
                </KitText>
                {_renderReactionCount(reaction)}
              </View>
              <View style={{ flex: 1 }}>{_renderReactionNames(reaction)}</View>
            </View>
          ))}
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <EmojiBar toggleReaction={toggleReaction} />

        {selectedMessage?.isUser &&
          selectedMessage.customType === MessageType.Prayer &&
          !data?.isAnswered && (
            <ActionSheetItem
              icon='prayer'
              label='Mark as answered'
              onPress={_navToPrayerScreen}
            />
          )}

        {!simpleView &&
          showReplyAction &&
          !selectedMessage?.isAdminMessage() && (
            <ActionSheetItem
              icon='reply'
              label={data?.inChannel ? 'View thread' : 'Reply in thread'}
              onPress={_replyToMessage}
            />
          )}
        {!simpleView &&
          selectedMessage?.isUser &&
          (selectedMessage.customType === MessageType.Text ||
            selectedMessage.customType === MessageType.Prayer) && (
            <ActionSheetItem
              icon='edit'
              label='Edit message'
              onPress={_editSelectedMessage}
            />
          )}
        {(selectedMessage?.isUser ||
          ((channel as GroupChannel)?.myRole === 'operator' &&
            channel.customType !== ChannelType.Direct)) && (
          <ActionSheetItem
            icon='delete'
            label='Delete message'
            onPress={_deleteSelectedMessage}
          />
        )}
        {selectedMessage?.customType === MessageType.Text && (
          <ActionSheetItem
            icon='copy'
            label='Copy text'
            onPress={_copyMessageText}
          />
        )}
        {!simpleView &&
          !selectedMessage?.isUser &&
          !selectedMessage?.isAdminMessage() && (
            <ActionSheetItem
              icon='flag'
              label='Report'
              onPress={_reportMessage}
            />
          )}
        {selectedMessage?.isUser &&
          selectedMessage.customType === MessageType.Text && (
            <ActionSheetItem
              icon='prayer'
              label='Convert to prayer request'
              onPress={_convertToPrayerRequest}
            />
          )}
      </View>
    );
  };

  const toggleReaction = (emojiKey: string) => {
    /**
     * Close the modal by calling setSelectedMessage(null)
     * so that the useEffect will automatically dismiss it
     * when the selectedMessage becomes null.
     *
     * Attempting to use setModalVisible(false) here will cause
     * the modal to remain stuck open when setModalVisible(false)
     * is called twice in rapid succession.
     */
    setSelectedMessage(null);

    if (selectedMessage) {
      sbToggleReaction(channel, selectedMessage, emojiKey, userId as string);
    }
  };

  useEffect(() => {
    if (selectedMessage && selectedReaction && selectedMessage.reactions) {
      const reactionList: ReactionEntry[] = [];
      Promise.all(
        selectedMessage.reactions.map((reaction) => {
          return new Promise<void>((resolve, reject) => {
            sbGetUsersById(reaction.userIds)
              .then((userList) => {
                reactionList.push({
                  key: reaction.key,
                  users: userList,
                });
                resolve();
              })
              .catch((e) => {
                debug('Could not get users by ID: ', e);
                resolve();
              });
          });
        })
      ).then(() => {
        // Sort reaction list by count
        reactionList.sort(function (a, b) {
          return b.users.length - a.users.length;
        });
        setReactions(reactionList);
        setModalVisible(true); // We have to wait to set modal visible until we are done fetching user names
      });

      return;
    }

    setModalVisible(Boolean(selectedMessage && !selectedReaction));
  }, [selectedMessage, selectedReaction]);

  useEffect(() => {
    !modalVisible && setSelectedMessage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  return selectedMessage ? (
    <KitModal
      visible={modalVisible}
      setVisible={setModalVisible}
      onClose={onClose}
    >
      {_renderContent()}
    </KitModal>
  ) : null;
}

function EmojiBar({
  toggleReaction,
}: {
  toggleReaction: (reaction: string) => void;
}) {
  const quickReactions = ['👍', '❤️', '😄', '🙏', '🎉', '😂'];
  const animatedValues: Animated.Value[] = quickReactions.map(
    (_) => new Animated.Value(0)
  );

  const staggerAnimate = () => {
    const animations = animatedValues.map((animatedValue) =>
      Animated.spring(animatedValue, {
        delay: 150,
        friction: 5,
        toValue: 1,
        useNativeDriver: true,
      })
    );
    Animated.stagger(70, animations).start();
  };

  useEffect(() => {
    staggerAnimate();
  }, []);

  return (
    <View style={styles.emojiButtonWrapper}>
      {quickReactions.map((reaction, index) => (
        <Animated.View
          key={index}
          style={{
            flex: 1,
            opacity: animatedValues[index],
            transform: [
              {
                translateY: animatedValues[index].interpolate({
                  inputRange: [0, 1],
                  outputRange: [10, 0],
                }),
              },
            ],
          }}
        >
          <KitTouchable
            style={[styles.emojiButton, { backgroundColor: Colors.N100 }]}
            touchableStyle={[
              styles.emojiButton,
              { backgroundColor: Colors.N100 },
            ]}
            underlayColor={Colors.N300}
            onPress={() => toggleReaction(reaction)}
            borderRadius={50}
          >
            <KitText fontSize={24} style={{ lineHeight: 28 }}>
              {reaction}
            </KitText>
          </KitTouchable>
        </Animated.View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  actionSheetItem: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 18,
    paddingLeft: 3,
    paddingTop: 18,
  },
  actionSheetText: {
    marginLeft: 20,
  },
  container: {
    marginHorizontal: Spacing.l,
    marginTop: Spacing.m,
    paddingBottom: Spacing.xl,
  },
  emojiButton: {
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 50,
    height: 44,
    justifyContent: 'center',
    width: 44,
  },
  emojiButtonWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 12,
  },
  reactionContainer: {
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: Spacing.s,
    marginVertical: Spacing.l,
  },
  reactionCountContainer: {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    backgroundColor: '#FFFFFF',
    borderRadius: 11,
    height: 22,
    position: 'absolute',
    width: 22,
    ...Platform.select({
      ios: {
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.15,
        shadowRadius: 5,
      },
      android: {
        elevation: 3,
        overflow: 'hidden',
      },
    }),
  },
});
