import Environment from '../../Environment';
import GetAppHeaders from '../../Downloader/GetAppHeaders';
import { ICreateTokenResponseBody } from './Types';

export const fetchGuestTokenData = async (): Promise<
  ICreateTokenResponseBody | undefined
> => {
  let result: ICreateTokenResponseBody | undefined;

  let appHeaders: Record<string, string> = {};
  try {
    appHeaders = await GetAppHeaders();
  } catch {}

  try {
    const response = await fetch(`${Environment.tokensServiceHost}/token`, {
      method: 'POST',
      body: [
        'grant_type=client_credentials',
        `client_id=${Environment.tokensServiceClientId}`,
        `client_secret=${Environment.tokensServiceClientSecret}`,
      ].join('&'),
      headers: {
        ...appHeaders,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    result = await response.json();
  } catch (e) {
    result = {
      error_description: 'Token request failed. Check internet connection.',
    };
  }

  return result;
};
