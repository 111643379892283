import React, { useState } from 'react';
import { Linking, Platform, TextStyle } from 'react-native';

import Hoverable from '../Hoverable';
import { IKitTextProps } from '../KitText';
import { KitText } from '../index';

export default (
  props: {
    children?: React.ReactNode;
    fontSize?: number;
    href?: string;
    onPress?: () => void;
    style?: TextStyle;
    underline?: 'hover' | 'always' | 'none';
  } & IKitTextProps
): JSX.Element => {
  const {
    children = '',
    href = '',
    fontSize = 16,
    onPress,
    style = {},
    underline = 'always',
    ...rest
  } = props;
  const [hovered, setIsHovered] = useState(false);

  return (
    <Hoverable
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <KitText
        fontSize={fontSize}
        style={[
          // @ts-ignore
          { ...(Platform.OS === 'web' && onPress && { cursor: 'pointer' }) },
          {
            ...((underline === 'always' ||
              (underline === 'hover' && hovered)) && {
              textDecorationLine: 'underline',
            }),
          },
          style,
        ]}
        onPress={
          onPress ? onPress : href ? () => Linking.openURL(href) : undefined
        }
        {...rest}
      >
        {children}
      </KitText>
    </Hoverable>
  );
};
