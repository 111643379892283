import { KitIcon } from '@omni/kit/components';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Popup from 'reactjs-popup';

const CreateDropdown = React.forwardRef(
  (
    props: {
      children?: React.ReactNode | Array<React.ReactNode>;
      onClick: () => void;
    },
    ref
  ) => {
    const isSmall = useSizeClass().sizeClass === SizeClass.Small;

    return (
      <Popup
        // @ts-ignore
        ref={ref}
        // @ts-ignore ('manual' is used here so popup doesn't hijack the button click event)
        on='manual'
        trigger={
          <button
            style={{
              borderWidth: 0,
              backgroundColor: Colors.N100,
              width: 36,
              height: 36,
              borderRadius: 150,
              cursor: 'pointer',
              padding: 0,
            }}
            onClick={props.onClick}
          >
            {isSmall ? (
              <View
                style={{
                  backgroundColor: Colors.brand,
                  width: 50,
                  height: 50,
                  borderRadius: 150,
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: 25,
                  bottom: 25,
                }}
              >
                <KitIcon name='add' color={Colors.N0} />
              </View>
            ) : (
              <View style={styles.createIcon}>
                <KitIcon name='add' size={18} color={Colors.N900} />
              </View>
            )}
          </button>
        }
        position={`${isSmall ? 'top' : 'bottom'} right`}
        closeOnDocumentClick
        arrow={false}
      >
        {props.children}
      </Popup>
    );
  }
);

export default CreateDropdown;

const styles = StyleSheet.create({
  createIcon: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
