import React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Animated,
  Easing,
  FlatList,
  FlatListProps,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';

import KitPageControl from './KitPageControl';

/**
 * KitList
 * A FlatList with support for item entry animation and paging control
 */
interface IKitListProps<ItemT> extends FlatListProps<ItemT> {
  align?: 'start' | 'end' | 'center';
  animationEnabled?: boolean;
  pageWidth?: number; // used for horizontal list with paging enabled
}

export default React.forwardRef(KitList) as <ItemT>(
  props: IKitListProps<ItemT> & {
    ref?: React.ForwardedRef<FlatList<ItemT> | undefined>;
  }
) => ReturnType<typeof KitList>;

function KitList<ItemT>(
  props: IKitListProps<ItemT>,
  kitListRef: React.ForwardedRef<FlatList<ItemT> | undefined>
): JSX.Element {
  const {
    data,
    align,
    animationEnabled = true,
    numColumns,
    horizontal = false,
    pagingEnabled = false,
    pageWidth = 0,
  } = props;
  const prevData = useRef<ArrayLike<ItemT>>([]);
  const [animatedValues, setAnimatedValues] = useState<Animated.Value[]>([]);
  const [prevAnimatedLength, setPrevAnimatedLength] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0); // for horizontal lists with paging enabled
  const flatListRef = useRef<FlatList<ItemT>>();

  useEffect(() => {
    if (animationEnabled) {
      if (data && data.length > 0 && prevData.current.length <= 0) {
        const newAnimatedValues: Animated.Value[] = [];
        const MAX_ITEMS_TO_ANIMATE = 25;

        for (let i = 0; i <= Math.min(data.length, MAX_ITEMS_TO_ANIMATE); i++) {
          newAnimatedValues.push(new Animated.Value(0));
        }

        setAnimatedValues(newAnimatedValues);
        prevData.current = data;
      } else if (
        data &&
        prevData.current.length &&
        data.length > prevData.current.length
      ) {
        const newAnimatedValues: Animated.Value[] = [];

        for (let i = prevData.current.length; i < data.length; i++) {
          newAnimatedValues.push(new Animated.Value(0));
        }

        setPrevAnimatedLength(animatedValues.length);
        setAnimatedValues((prevState) => [...prevState, ...newAnimatedValues]);

        prevData.current = data;
      }
    }
  }, [data, animatedValues.length, animationEnabled]);

  const renderAnimatedItem = (item: ListRenderItemInfo<ItemT>) => {
    const isNewItem =
      prevAnimatedLength <= item.index && item.index < animatedValues.length;

    return (
      <Animated.View
        style={[
          // workaround issue in multi-column FlatList where items on last row are stretched unexpectedly,
          // note: do not attempt to use flex for this, otherwise the last row will not render as expected in the web
          numColumns
            ? {
                width: ((numColumns > 1 ? 1 / numColumns : 1) * 100 +
                  '%') as `${number}%`,
              }
            : {},
          /** Only animate new items */
          {
            opacity: isNewItem ? animatedValues[item.index] : 1.0,
            transform: isNewItem
              ? [
                  {
                    scale: animatedValues[item.index].interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.99, 1],
                    }),
                  },
                ]
              : [],
          },
        ]}
      >
        {props.renderItem && props.renderItem(item)}
      </Animated.View>
    );
  };

  useEffect(() => {
    const staggerAnimate = () => {
      /**
       * Only animate new items
       */
      const animations = [];

      for (let i = prevAnimatedLength; i < animatedValues.length; i++) {
        const animatedValue = animatedValues[i];
        animations.push(
          Animated.timing(animatedValue, {
            delay: 0,
            duration: 550,
            toValue: 1,
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          })
        );
      }

      Animated.stagger(70, animations).start();
    };

    if (props.data && props.data.length > 0) {
      staggerAnimate();
    }
  }, [animatedValues, props.data, prevAnimatedLength]);

  const _onPressDotIndicator = (index: number) => {
    // ideally we should use 'scrollToIndex' function instead, but this did not work in testing on the web
    flatListRef?.current?.scrollToOffset({
      offset: pageWidth * index,
      animated: true,
    });
  };

  const _onScroll =
    horizontal && pagingEnabled
      ? (event: NativeSyntheticEvent<NativeScrollEvent>) => {
          if (data && pageWidth > 0) {
            const offsetX = event.nativeEvent.contentOffset.x;
            let index = Math.floor((offsetX - pageWidth / 2) / pageWidth) + 1;
            index = Math.min(index, data.length - 1);
            setCurrentPage(index);
          }
        }
      : props.onScroll;

  // ARTEMIS-1071: prevent a flicker during entry animation
  if (animationEnabled && animatedValues.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Animated.FlatList
        // @ts-ignore
        ref={horizontal && pagingEnabled ? flatListRef : kitListRef}
        {...props}
        renderItem={renderAnimatedItem}
        onScroll={_onScroll}
        {...(align === 'center' && {
          contentContainerStyle: {
            flexGrow: 1,
            justifyContent: 'center',
          },
        })}
      />
      {horizontal && pagingEnabled && (
        <KitPageControl
          style={{ position: 'absolute', left: 0, right: 0, bottom: 10 }}
          numberOfPages={data?.length ?? 0}
          currentPage={currentPage}
          hidesForSinglePage
          pageIndicatorTintColor='gray'
          currentPageIndicatorTintColor='white'
          indicatorStyle={{ borderRadius: 5 }}
          currentIndicatorStyle={{ borderRadius: 5 }}
          indicatorSize={{ width: 8, height: 8 }}
          onPageIndicatorPress={_onPressDotIndicator}
        />
      )}
    </>
  );
}
