import KitFrame from '@omni/kit/components/KitFrame';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import { dispatchAction } from '@omni/kit/utilities/NativeHelpers';
import React from 'react';

import BlockPageNested from '../BlockPageNested';
import { IBlockProps } from './types';

export interface FrameBlockProps extends IBlockProps {
  title?: string;
  action?: any;
  enableChevron?: boolean;
  blocks?: [];
}

const FrameBlock = ({
  title,
  action,
  blocks,
  enableChevron,
  topSpacing,
  bottomSpacing,
}: FrameBlockProps): JSX.Element => {
  const _onPress = () => {
    dispatchAction(action);
  };

  const viewStyle = {
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
  };

  return (
    <KitFrame
      title={title}
      enableChevron={enableChevron}
      onPress={action ? _onPress : undefined}
      insetStyle={viewStyle}
    >
      <BlockPageNested data={{ blocks: blocks }} />
    </KitFrame>
  );
};

export default FrameBlock;
