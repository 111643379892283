export enum SEARCH_QUERY_STRING {
  APP_KEY = 'app_key',
  CHANNELS = 'channels',
  CHANNEL = 'channel',
  CONTEXT = 'context',
  CURSOR = 'cursor',
  C = 'c',
  D = 'd',
  FQ = 'fq',
  Q = 'q',
  Q_OPTIONS = 'q.options',
  Q_PARSER = 'q.parser',
  RETURN = 'return',
  SIZE = 'size',
  SORT = 'sort',
  START = 'start',
  TAG = 'tag',
  TYPE = 'type',
  FACET = 'facet',
  SCRIPTURE = 'scripture',
  INCLUDE = 'include',
}

export enum SEARCH_QUERY_INTEGER {
  SIZE = 'size',
  START = 'start',
}

export enum SEARCH_QUERY_BOOLEAN {
  INCLUDE = 'include',
}

export type QUERY_TUPLE =
  | [SEARCH_QUERY_STRING, string]
  | [SEARCH_QUERY_INTEGER, number]
  | [SEARCH_QUERY_BOOLEAN, boolean];

export interface TagItem {
  count: number;
  title: string;
  type: string;
  value: string;
  averageHexColor?: string;
  subtitle?: string;
  description?: string;
  image?: string;
  showAvatar?: boolean;
}

export enum TAG_TYPES {
  TOPIC = 'topic',
  SPEAKER = 'speaker',
  SCRIPTURE = 'scripture',
}
