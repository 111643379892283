import React, { createContext, useContext } from 'react';
import { View, ViewStyle } from 'react-native';

import BasicNavigator from '../components/BasicNavigator';
import useEdgeSpacing from '../hooks/useEdgeSpacing';
import useInterItemSpacing from '../hooks/useInterItemSpacing';
import useViewPortLayout from '../hooks/useViewPortLayout';
import { SpacingType, getSpacing } from '../theming/SpacingType';

export type ScreenContextType = {
  viewPortWidth: number;
  viewPortHeight: number;
  smallestViewPortWidth: number;
  edgeSpacing: number;
  interItemSpacing: number;
};

export const ScreenContext = createContext<ScreenContextType>({
  viewPortWidth: 0,
  viewPortHeight: 0,
  smallestViewPortWidth: 0,
  edgeSpacing: 0,
  interItemSpacing: 0,
});

interface ProviderProps {
  children?: React.ReactNode;
  style?: ViewStyle;
  initialState?: ScreenContextType; // important for jest tests
}

/**
 * BlockPage relies on 'onLayout' to measure the viewport size
 * instead of using Dimensions.get('window') via SizeClassContext.
 * This is necessary in order to support the case where Block Page may be a small web-embed within a react application.
 * (e.g. Block Page docs, see `yarn docs` or https://developer.subsplash.com/blocks/index.html)
 */
export const ScreenContextProvider = ({
  children,
  style,
  initialState,
}: ProviderProps): JSX.Element => {
  const {
    onViewPortLayout,
    viewPortWidth,
    viewPortHeight,
    smallestViewPortWidth,
  } = useViewPortLayout(style?.flex);

  const interItemSpacing = useInterItemSpacing(viewPortWidth);
  const edgeSpacing = useEdgeSpacing(viewPortWidth);

  const measuredState = {
    viewPortWidth: viewPortWidth,
    viewPortHeight: viewPortHeight,
    smallestViewPortWidth: smallestViewPortWidth,
    interItemSpacing: interItemSpacing,
    edgeSpacing: edgeSpacing,
  };

  const measuredStateReadyForRender =
    measuredState.viewPortWidth > 0 &&
    measuredState.edgeSpacing > 0 &&
    measuredState.interItemSpacing > 0;

  const value: ScreenContextType =
    initialState !== undefined && !measuredStateReadyForRender
      ? initialState
      : measuredState;

  const readyToRenderChildren =
    Boolean(initialState) || measuredStateReadyForRender;

  return (
    <BasicNavigator style={style} onLayout={onViewPortLayout}>
      {readyToRenderChildren && (
        <ScreenContext.Provider value={value}>
          {children}
        </ScreenContext.Provider>
      )}
    </BasicNavigator>
  );
};

/**
 * useScreenContext
 *
 * @param: fixedSpacingType
 * When fixedSpacingType is defined, 'edgeSpacing' and 'interItemSpacing'
 * will use the value returned from 'getSpacing(fixedSpacingType)',
 * suppressing both 'useInterItemSpacing' and 'useEdgeSpacing'
 */
export const useScreenContext = ({
  fixedSpacingType,
}: {
  fixedSpacingType?: SpacingType;
}): ScreenContextType => {
  let context = useContext<ScreenContextType>(ScreenContext);

  const fixedSpacing = getSpacing(fixedSpacingType);

  if (fixedSpacing) {
    context = {
      ...context,
      edgeSpacing: fixedSpacing,
      interItemSpacing: fixedSpacing,
    };
  }

  return context;
};
