import Environment from '../../Environment';
import BaseServiceV2, { IHttpResponse, IUri } from '../BaseServiceV2';

const HOST = Environment.feedsService;

export interface IUserProfileInfo {
  customProfileHeaderUrl?: string;
  email?: string;
  firstName?: string;
  image?: string;
  initials?: string;
  lastName?: string;
  profileID?: string;
  userID: string;
  fullName?: string;
}

interface IGetUserProfile extends IUri {
  appKey: string;
  token: string;
  getFromCache?: boolean;
}

export interface IUserProfileFeedResponse extends IHttpResponse {
  body?: IUserProfileInfo;
}

export default async function ({
  appKey,
  token,
  getFromCache = true,
}: IGetUserProfile): Promise<IUserProfileFeedResponse> {
  const url = `${HOST}/profile/${appKey}/user-info`;

  return BaseServiceV2.Get({
    token,
    url,
    getFromCache,
  });
}
