import { buildUrl, IUri } from '../BaseServiceV2';

import Environment from '../../Environment';

const host = Environment.chatService;

export function prepareUrl({
  filters,
  queries,
  id,
  includes,
  resource,
}: IUri): string {
  return buildUrl({
    host,
    resource,
    queries,
    filters,
    includes,
    id,
  });
}
