import { Portal } from '@gorhom/portal';
import { KitIcon, KitLoader, KitText } from '@omni/kit/components';
import Show from '@omni/kit/components/Show';
import { useKeyboardContext } from '@omni/kit/contexts/KeyboardContext';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  ScrollView,
  ScrollViewProps,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import Modal from 'react-native-modal';

import { useKioskContext } from '../contexts/KioskContext';
import modalAnimationProps from '../utilities/modalAnimationProps';

export default function KioskModal({
  children,
  isVisible,
  title,
  subtitle,
  graySubtitle,
  showCloseButton = false,
  isLoading = false,
  onClose,
  onModalHide,
  footer,
  enableScroll = false,
  width = 500,
  smallContent = false,
  centerTitle = false,
  isResetModal = false,
}: {
  children?: ReactNode;
  isVisible: boolean;
  onClose: () => void;
  onModalHide?: () => void;
  showCloseButton?: boolean;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  graySubtitle?: boolean;
  footer?: JSX.Element;
  enableScroll?: boolean;
  width?: number;
  smallContent?: boolean;
  centerTitle?: boolean;
  usePortal?: boolean;
  isResetModal?: boolean;
}): JSX.Element {
  const { keyboardEvent } = useKeyboardContext();
  const { windowHeight, windowWidth } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  const [showShadow, setShowShadow] = useState(false);
  const { setModalInteraction } = useKioskContext();

  const handleBackdropPress = () => {
    !isLoading && onClose();
  };

  const handleModalHide = () => {
    setModalInteraction(false);
    onModalHide && onModalHide();
  };

  useEffect(() => {
    // If any modal active setModalInteraction true
    if (isVisible && !isResetModal) {
      setModalInteraction(true);
    }
  }, [isVisible, isResetModal]);

  useEffect(() => {
    // Watch keyboard when
  }, [keyboardEvent.show]);

  return (
    <Portal>
      <Modal
        {...modalAnimationProps}
        isVisible={isVisible}
        avoidKeyboard={true}
        style={styles.container}
        onBackdropPress={handleBackdropPress}
        onBackButtonPress={onClose}
        onModalHide={handleModalHide}
        propagateSwipe={true}
        coverScreen={true}
      >
        <View
          style={[
            styles.modalContainer,
            {
              width: width,
              height:
                isLandscape && keyboardEvent.show && !smallContent
                  ? 400 - 36
                  : undefined,
              paddingTop: Spacing.xl,
              paddingBottom: footer || isLoading ? 0 : Spacing.xl,
            },
          ]}
        >
          {isLoading ? (
            <View
              style={{
                paddingVertical: 75,
                alignItems: 'center',
              }}
            >
              <KitLoader />
            </View>
          ) : (
            <>
              <Show show={Boolean(title) || Boolean(subtitle)}>
                <View
                  style={[
                    {
                      paddingHorizontal: Spacing.xl,
                      borderTopLeftRadius: 24,
                      borderTopRightRadius: 24,
                    },
                  ]}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: centerTitle ? 'center' : 'space-between',
                      marginBottom: subtitle ? Spacing.s : Spacing.xl,
                    }}
                  >
                    <KitText
                      center={centerTitle}
                      extraBold
                      black
                      fontSize={24}
                      lineHeight={24}
                    >
                      {title}
                    </KitText>
                    <Show show={showCloseButton}>
                      <TouchableOpacity onPress={onClose}>
                        <KitIcon name='remove' size={16} />
                      </TouchableOpacity>
                    </Show>
                  </View>

                  {subtitle && (
                    <View
                      style={{
                        paddingBottom: Spacing.xl,
                      }}
                    >
                      <KitText
                        fontSize={18}
                        black={!graySubtitle}
                        center={centerTitle}
                      >
                        {subtitle}
                      </KitText>
                    </View>
                  )}
                </View>
              </Show>
              <ScrollViewWrapper
                centerContent={false}
                scrollEnabled={enableScroll}
                style={[
                  {
                    paddingHorizontal: Spacing.xl,
                  },
                ]}
                keyboardShouldPersistTaps='handled'
                onEndReached={
                  enableScroll ? (isEnd) => setShowShadow(!isEnd) : undefined
                }
              >
                {children}
              </ScrollViewWrapper>
              <View
                style={[
                  {
                    borderBottomLeftRadius: BorderRadius.xl,
                    borderBottomRightRadius: BorderRadius.xl,
                  },
                  showShadow && {
                    backgroundColor: Colors.N0,
                    shadowColor: 'rgba(0, 0, 0, 0.2)',
                    shadowOffset: { width: 0, height: -3 },
                    shadowOpacity: 1,
                    shadowRadius: 10,
                    zIndex: 999,
                  },
                ]}
              >
                {footer}
              </View>
            </>
          )}
        </View>
      </Modal>
    </Portal>
  );
}

const ScrollViewWrapper = (
  props: ScrollViewProps & {
    onEndReached?: (isEnd: boolean) => void;
  }
) => {
  const [height, setHeight] = useState(0);

  if (!props.onEndReached) return <ScrollView {...props} />;

  return (
    <ScrollView
      {...props}
      onScroll={({
        nativeEvent: { layoutMeasurement, contentOffset, contentSize },
      }) => {
        // @ts-ignore
        props.onEndReached(
          layoutMeasurement.height + contentOffset.y >= contentSize.height
        );
      }}
      onContentSizeChange={(_, contentHeight) => {
        // @ts-ignore
        props.onEndReached(contentHeight <= height || height === 0);
      }}
      onLayout={({
        nativeEvent: {
          layout: { height },
        },
      }) => {
        setHeight(height);
      }}
      scrollEventThrottle={100}
    >
      {props.children}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: 'center',
  },
  modalContainer: {
    margin: 0,
    borderRadius: 24,
    backgroundColor: Colors.N0,
    flexDirection: 'column',
    alignSelf: 'center',
    maxHeight: '90%',
  },
});
