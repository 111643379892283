import { AuthService } from '@omni/kit/auth';

import { IChatSession, prepareUrl } from '..';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '../../../constants/identifiers';
import BaseServiceV2, { IHttpResponse, IUri } from '../../BaseServiceV2';

const RESOURCE = 'chat-sessions';

interface ICreateSession extends Omit<IUri, 'resource'> {
  appKey: string;
  retry?: number;
  source?: string;
}

export default async function createChatSession({
  appKey,
  retry = 3,
  source,
  ...buildUrlProps
}: ICreateSession): Promise<IChatSession> {
  const token = AuthService.accessTokenWithBearer;

  if (!token) {
    throw new Error('No access token provided');
  }

  const url = prepareUrl({
    ...buildUrlProps,
    resource: RESOURCE,
  });

  const headers: Record<string, string> = {
    'sap-active-appkey': appKey,
  };

  const response = await BaseServiceV2.Post({
    url,
    appKey,
    headers,
    token,
  });

  if (response.status === 401 || response.status === 403) {
    if (retry > 0) {
      await AuthService.refreshAccessToken(SUBSPLASH_AUTH_PROVIDER_ID, source);
      AuthService.notifyAuthorizationChanged();

      return createChatSession({
        appKey,
        retry: retry - 1,
        source,
        ...buildUrlProps,
      });
    } else {
      throw new Error(response.message);
    }
  }

  return handleResponse(response);
}

function handleResponse(response: IHttpResponse): Promise<IChatSession> {
  if (response?.status && response?.status >= 200 && response?.status < 400) {
    return Promise.resolve(response.body);
  }

  return Promise.reject(`Failed to create a chat-session: ${response?.status}`);
}
