import { useEffect, useState } from 'react';
import { NativeModules } from 'react-native';
import RNPrint, { PrinterType } from 'react-native-print';

import { IPrinter } from '../Types';

export type IPrinterStatus = 'connected' | 'offline';

const AUTOMATIC_OFFLINE_TIMEOUT = 1000; // 1 second
const RETRY_TIMEOUT = 15000; // 15 seconds

export default function usePrinterStatus(
  printer?: IPrinter
): IPrinterStatus | undefined {
  const [printerStatus, setPrinterStatus] = useState<IPrinterStatus>();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const checkPrinterStatus = async () => {
      let loading = true;
      checkConnection(printer?.url as string)
        .then((status) => {
          setPrinterStatus(status);
        })
        .catch((e) => {
          console.log(e);
          setPrinterStatus('offline');
        })
        .finally(() => {
          loading = false;
          // Check status again in RETRY_TIMEOUT seconds
          timeout = setTimeout(checkPrinterStatus, RETRY_TIMEOUT);
        });

      // If the current request is taking longer than AUTOMATIC_OFFLINE_TIMEOUT,
      // the printer's probably offline
      setTimeout(() => {
        if (loading) {
          setPrinterStatus('offline');
        }
      }, AUTOMATIC_OFFLINE_TIMEOUT);
    };

    if (printer?.url) {
      checkPrinterStatus();
    } else {
      setPrinterStatus('offline');
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [printer?.url]);

  if (printer) {
    return printerStatus;
  } else {
    return undefined;
  }
}

async function checkConnection(
  printerURL: string
): Promise<'connected' | 'offline'> {
  if (printerURL?.startsWith('ipp')) {
    const printerStatus = await RNPrint.checkConnection(printerURL);

    return printerStatus.status || 'offline';
  } else {
    return new Promise<'connected' | 'offline'>((resolve, reject) => {
      NativeModules.ReactPlatformBridge.checkPrinterStatus(
        printerURL.includes('.') ? 'wifi' : 'bluetooth',
        printerURL,
        (status: 'connected' | undefined) => {
          resolve(status || 'offline');
        }
      );
    });
  }
}
