import Hoverable from '@omni/kit/components/Hoverable';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitIcon from '@omni/kit/components/KitIcon';
import Show from '@omni/kit/components/Show';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { IUserProfileInfo } from '@omni/kit/services/FeedsService/GetUserProfile';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import Dropdown from '@omni/messaging/mobile/components/Dropdown';
import ActionSheetItem from '@omni/messaging/mobile/components/chat/actionSheet/ActionSheetItem';
import { universalLogout } from '@omni/messaging/shared/redux/actions/SystemActions';
import { profileImageUrlSelector } from '@omni/messaging/shared/redux/selectors';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

interface UserDropdownProps {
  user: IUserProfileInfo;
  onUserSettings: () => void;
  onAccountSelected: () => void;
  userDropdownPressed?: boolean;
  userDropdownHover?: boolean;
  handleUserDropdown: (val: boolean) => void;
}

export default function UserDropdown({
  user,
  onUserSettings,
  onAccountSelected,
  userDropdownPressed,
  userDropdownHover,
  handleUserDropdown,
}: UserDropdownProps): JSX.Element {
  const menuRef = useRef<PopupActions | null>(null);

  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const { sizeClass } = useSizeClass();
  const profileImageUrl = useSelector(profileImageUrlSelector);

  const [isPressed, setIsPressed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isHovered === true && userDropdownHover === true) {
      setIsHovered(false);
    }
  }, [isHovered, userDropdownHover, isPressed]);

  const _handleLogOut = async () => {
    dispatch(universalLogout());
  };

  return (
    <View
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => {
        setIsPressed(userDropdownPressed ? false : true);
      }}
      onMouseUp={() => {
        setIsPressed(false);
      }}
      onMouseOut={() => setIsPressed(false)}
      style={[
        {
          display: 'flex',
          position: 'relative',
          //  @ts-ignore
          cursor: 'pointer',
          borderWidth: 0,
          width: 68,
          height: 44,
          padding: Spacing.xs,
          backgroundColor:
            isPressed || userDropdownPressed
              ? Colors.N100
              : isHovered && !userDropdownHover
              ? Colors.N50
              : Colors.N0,
          transform: isPressed ? [{ scale: 0.95 }] : [{ scale: 1 }],
          borderRadius: 22,
          marginRight: 0,
        },
      ]}
    >
      <Popup
        ref={menuRef}
        offsetY={4}
        onOpen={() => handleUserDropdown(true)}
        onClose={() => handleUserDropdown(false)}
        trigger={
          <span>
            <button
              style={{
                borderWidth: 0,
                backgroundColor: 'transparent',
                padding: 0,
                cursor: 'pointer',
                fontFamily: 'inherit',
              }}
              onClick={() => {
                menuRef.current?.toggle();
              }}
            >
              <KitAvatar
                size={36}
                imageUrl={
                  profileImageUrl !== '' ? profileImageUrl : user?.image
                }
                nickname={`${user?.firstName} ${user.lastName}`}
              />
              <KitIcon
                style={{ position: 'absolute', top: 12, right: 4 }}
                name='arrow-down'
                size={20}
              />
            </button>
          </span>
        }
        position='bottom right'
        closeOnDocumentClick
        arrow={false}
      >
        <div>
          <Dropdown>
            <Hoverable>
              {(isHovering) => (
                <div
                  style={{
                    backgroundColor: isHovering ? Colors.N50 : 'transparent',
                  }}
                >
                  <ActionSheetItem
                    icon='settings'
                    iconColor={Colors.N900}
                    label='User settings'
                    onPress={() => {
                      menuRef.current?.close();
                      onUserSettings();
                    }}
                  />
                </div>
              )}
            </Hoverable>
            <Hoverable>
              {(isHovering) => (
                <div
                  style={{
                    backgroundColor: isHovering ? Colors.N50 : 'transparent',
                  }}
                >
                  <ActionSheetItem
                    icon='user-round-outlined'
                    iconColor={Colors.N900}
                    label='Account'
                    onPress={() => {
                      menuRef.current?.close();
                      onAccountSelected();
                    }}
                  />
                </div>
              )}
            </Hoverable>
            <Show show={sizeClass !== SizeClass.Small}>
              <Hoverable>
                {(isHovering) => (
                  <div
                    style={{
                      backgroundColor: isHovering ? Colors.N50 : 'transparent',
                    }}
                  >
                    <ActionSheetItem
                      icon='feedback'
                      iconColor={Colors.N900}
                      label={t('buttonTitleProductFeedback')}
                      onPress={() => {
                        menuRef.current?.close();
                        window.open(
                          'https://subsplash.wufoo.com/forms/web-messaging-feedback/',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                )}
              </Hoverable>
            </Show>
            <Hoverable>
              {(isHovering) => (
                <div
                  style={{
                    backgroundColor: isHovering ? Colors.N50 : 'transparent',
                  }}
                >
                  <ActionSheetItem
                    icon='logout'
                    iconColor={Colors.N900}
                    label='Log out'
                    onPress={() => {
                      menuRef.current?.close();
                      _handleLogOut();
                    }}
                  />
                </div>
              )}
            </Hoverable>
          </Dropdown>
        </div>
      </Popup>
    </View>
  );
}
