import React, { Children } from 'react';
import { View, ViewProps } from 'react-native';

interface StackProps extends ViewProps {
  spacing: number;
  wrapLastChild?: boolean;
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
}
export default function Stack({
  spacing,
  wrapLastChild,
  children,
  ...props
}: StackProps): JSX.Element {
  const childrenCount = Children.count(children);

  return (
    <View {...props}>
      {React.Children.map(children, (child, index) => {
        if (!child) return null;

        const isLast = index === childrenCount - 1;

        if (!isLast) {
          return <View style={{ marginBottom: spacing }}>{child}</View>;
        }

        return wrapLastChild ? <View>{child}</View> : child;
      })}
    </View>
  );
}
