import { KitIcon, KitModal, KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

interface IProps {
  onNewGroupChannelPressed: () => void;
  onNewMessagePressed: () => void;
  setVisible: (value: boolean) => void;
  visible: boolean;
}

export default function ListFabActionSheet({
  onNewGroupChannelPressed,
  onNewMessagePressed,
  setVisible,
  visible,
}: IProps): JSX.Element | null {
  if (!visible) return null;

  return (
    <KitModal visible={visible} setVisible={setVisible}>
      <View style={styles.actionSheetContainer}>
        <TouchableOpacity
          style={styles.actionSheetButton}
          onPress={onNewMessagePressed}
        >
          <KitIcon name='edit' size={22} color={Colors.N900} />
          <KitText fontSize={16} black style={styles.actionSheetButtonText}>
            New message
          </KitText>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.actionSheetButton}
          onPress={onNewGroupChannelPressed}
        >
          <KitIcon name='group' size={22} color={Colors.N900} />
          <KitText fontSize={16} black style={styles.actionSheetButtonText}>
            New group
          </KitText>
        </TouchableOpacity>
      </View>
    </KitModal>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  actionSheetButton: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    marginVertical: Spacing.l,
  },
  actionSheetButtonText: { marginLeft: 16 },
  actionSheetContainer: {
    marginHorizontal: Spacing.l,
    marginTop: Spacing.l,
    paddingBottom: Spacing.xl,
  },
});
