import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { useAuth } from '@omni/kit/contexts/AuthContext';
import useBrandData from '@omni/kit/hooks/useAppIcon';
import useGuestToken from '@omni/kit/hooks/useGuestToken';
import useUserInfo from '@omni/kit/hooks/useUserInfo';
import { App } from '@omni/kit/services/Types';
import { useMemo } from 'react';

import { ApplicationContextData } from '../ApplicationContext';

// useApplicationContextData
// This is an internal helper hook that manages the data for an application context.
// Do not use this hook directly in a component. Instead, use the `useShellContext` hook.
export default (app?: App): ApplicationContextData | undefined => {
  const branding = useBrandData(app);
  const guestToken = useGuestToken();
  const { accessTokens, isAuthReady } = useAuth();

  const appKey = useMemo(() => app?.id, [app?.id]);

  const userToken = useMemo(
    () => (isAuthReady ? accessTokens?.[SUBSPLASH_AUTH_PROVIDER_ID] : null),
    [accessTokens, isAuthReady]
  );

  const { userInfo, userInfoLoading } = useUserInfo(appKey, accessTokens);

  if (!guestToken || !app || userInfoLoading) {
    return;
  }

  return {
    url: {
      host: '',
      customPath: app._embedded?.['web-app']?.custom_path || '',
      headers: {
        'sap-app-version': '',
        'sap-omni-version': '',
        'sap-device-os': '',
      },
    },
    appUrls: {},
    app: {
      appKey: app?.id || '',
      orgKey: app.org_key || '',
      canceled: app?.canceled || false,
      isTrial: app?.is_trial || false,
      internal: app?.internal || false,
      suspended: app?.suspended || false,
      title: app.title || '',
      appStoreInfo: app?._embedded?.['app-store-info'] || {},
      branding: branding
        ? {
            brandColor: branding.brandColor,
            brandLogo: branding.brandLogo.url,
            brandLogoId: branding.brandLogo.id,
            brandLogoImageType: branding.brandLogo.imageType,
            brandLogoSize: branding.brandLogo.size,
            colorScheme: branding.colorScheme,
            iconBackgroundColor: branding.iconBackgroundColor,
            iconBackgroundImage: branding.iconBackgroundImage,
            silhouetteLogo: branding.silhouetteLogo,
          }
        : undefined,
      features: app?._embedded?.['app-features'] || [],
      address: undefined,
      phone: undefined,
      shortCode: app.short_code || '',
    },
    tokens: {
      guest: guestToken || '',
      user: userToken || undefined,
      accessTokens: accessTokens,
    },
    user: userInfo
      ? {
          id: userInfo.userID,
          profile: {
            id: userInfo.profileID || '',
            firstName: userInfo.firstName || '',
            lastName: userInfo.lastName || '',
            fullName: userInfo.fullName || '',
            photoUrl: userInfo.image,
            initials: userInfo.initials,
          },
        }
      : undefined,
    global: {
      isAuthModalVisible: false,
    },
  };
};
