import { UpdateActionInterface } from '@omni/blocks';
import { useBlockPageContext } from '@omni/blocks/BlockPageContext';
import { useShellContext } from '@omni/kit';
import {
  KitButton,
  KitLink,
  KitModal,
  KitSnack,
  KitText,
} from '@omni/kit/components';
import useShellAuthProviders from '@omni/kit/contexts/ShellContext/hooks/useShellAuthProviders';
import BaseServiceV2 from '@omni/kit/services/BaseServiceV2';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { universalLogout } from '@omni/messaging/shared/redux/actions/SystemActions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { useDispatch } from 'react-redux';

export interface DeleteAccountModalProps {
  appKey: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  /** The action to perform when a user delete their profile. */
  deleteAccountAction?: UpdateActionInterface;
}

export interface AccessItemsListProps {
  title: string;
  subtitle: string;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  appKey,
  visible,
  setVisible,
  deleteAccountAction,
}): JSX.Element => {
  const { tokens } = useShellContext();
  const { targetProviders } = useShellAuthProviders();
  const { authProviderId } = useBlockPageContext();

  const targetProviderToken = useMemo(
    () =>
      authProviderId
        ? tokens.accessTokens[authProviderId] || undefined
        : undefined,
    [authProviderId, tokens.accessTokens]
  );

  const { t } = useTranslation(['account']);

  // When account is deleted user will lose access to the following
  const accessItemsList: AccessItemsListProps[] = [
    {
      title: t('account:deleteAccountModalBody.list.messaging.title'),
      subtitle: t('account:deleteAccountModalBody.list.messaging.subtitle'),
    },
    {
      title: t('account:deleteAccountModalBody.list.giving.title'),
      subtitle: t('account:deleteAccountModalBody.list.giving.subtitle'),
    },
    {
      title: t('account:deleteAccountModalBody.list.notes.title'),
      subtitle: t('account:deleteAccountModalBody.list.notes.subtitle'),
    },
  ];

  // ---------------------------------------------------------------------------
  // Global State
  const reduxDispatch = useDispatch();

  const handleDeleteAccount = async (): Promise<void> => {
    if (deleteAccountAction?.updateUrl) {
      const url = deleteAccountAction.updateUrl;

      const res = await BaseServiceV2.Post({
        url: url as string,
        appKey,
        authProviderId,
        targetProviders,
        token: targetProviderToken,
        data: {},
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const ok = res.status === 204 || res.status === 200;

      if (ok) {
        setTimeout(() => {
          reduxDispatch(universalLogout(true));

          if (Platform.OS === 'web') {
            // prevent usage of web specific window and CustomEvent objects on iOS & Android
            window.dispatchEvent(
              new CustomEvent('account-deleted', {
                bubbles: true,
                cancelable: false,
                composed: true,
              })
            );
          }
        }, KitSnack.duration.SHORT);
        setVisible(false);
        KitSnack.show(t('Account deleted'), KitSnack.duration.SHORT);
      } else {
        setVisible(false);
        KitSnack.show(t('account:errorMessage'), KitSnack.duration.LONG);
      }
    }
  };

  return (
    <KitModal visible={visible} setVisible={setVisible}>
      <View style={{ margin: Spacing.xl }}>
        <KitText h1 testID='deleteAccountModalTitle'>
          {t('account:deleteAccountTitle')}
        </KitText>
        <View style={{ marginBottom: Spacing.xl, marginTop: Spacing.s }}>
          <KitText gray fontSize={16} style={{ marginBottom: Spacing.xl }}>
            {t('account:deleteAccountModalBody.firstParagraph')}
          </KitText>

          {accessItemsList.map((accessItemList, index) => (
            <View key={index} style={{ marginBottom: Spacing.l }}>
              <KitText fontSize={16} color={Colors.N900} bold>
                {accessItemList.title}
              </KitText>

              <KitText gray fontSize={16}>
                {accessItemList.subtitle}
              </KitText>
            </View>
          ))}
          <View style={{ marginTop: Spacing.xs }}>
            <KitLink
              href={t('account:deleteAccountModalBody.link.url')}
              color={Colors.brand}
              underline='none'
            >
              {t('account:deleteAccountModalBody.link.text')}
            </KitLink>
          </View>
        </View>
        {Platform.OS === 'web' ? (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: Spacing.m,
            }}
          >
            <KitButton
              secondary
              title={t('account:deleteAccountCancelButton')}
              onPress={() => setVisible(false)}
              style={{ marginRight: Spacing.m }}
            />
            <KitButton
              color={Colors.R500}
              title={t('account:deleteAccountButton')}
              onPress={handleDeleteAccount}
            />
          </View>
        ) : (
          <>
            <KitButton
              color={Colors.R500}
              title={t('account:deleteAccountButton')}
              onPress={handleDeleteAccount}
              style={{ marginBottom: Spacing.m }}
            />
            <KitButton
              secondary
              title={t('account:deleteAccountCancelButton')}
              onPress={() => setVisible(false)}
              style={{ marginBottom: Spacing.xl }}
            />
          </>
        )}
      </View>
    </KitModal>
  );
};

export default DeleteAccountModal;
