import Environment from '@omni/kit/Environment';
import Colors from '@omni/kit/theming/Colors';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { PlacesAutocompleteProps } from './types';

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({
  onPlaceSelected,
  placeholder,
}) => {
  const { t } = useTranslation();

  return (
    <View>
      <Autocomplete
        apiKey={Environment.googleMapsKey}
        onPlaceSelected={onPlaceSelected}
        options={{ types: ['geocode'] }}
        placeholder={placeholder || t('common:searchForAPlace')}
        style={styles.textInput}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  textInput: {
    height: 40,
    borderColor: Colors.N900,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 10,
    fontSize: 16,
  },
});

export default PlacesAutocomplete;
