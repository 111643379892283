export default (size: 's' | 'm' | 'l'): number => {
  switch (size) {
    case 's': {
      return 420;
    }
    case 'm': {
      return 800;
    }
    case 'l': {
      return 960;
    }
    default:
      return 0;
  }
};
