import create from './Create';
import getAll from './GetAll';
import getOne from './GetOne';
import update from './Update';

export default {
  create,
  getAll,
  getOne,
  update,
};
