import { App } from '@omni/kit/services/Types';

/**
 * Returns name of initial screen when a user opens the Search app
 * based on whether app is a container app and if user has already
 * seen the 'tour' screen.
 *
 * @param {App} rootApp - a root App object, see service call via search/utils/RootAppContext.tsx
 * @param {boolean} hasSeenTour - user has seen tour screen
 *
 * @returns {string} routeName  - name of route/screen
 */
export const initialRoute = ({
  rootApp,
  hasSeenTour,
}: {
  rootApp: App | undefined;
  hasSeenTour: boolean;
}): string => {
  let routeName = 'Search';

  if (rootApp?.is_container && !hasSeenTour) {
    routeName = 'Tour';
  }

  return routeName;
};
