import { KitIcon, KitLoader, KitText } from '@omni/kit/components';
import ListItem from '@omni/kit/components/KitListItem';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { IListItemData } from '@omni/kit/feeds/listItemTypes';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Keyboard, Pressable, StyleSheet, View } from 'react-native';

import { getBrowseOptions } from '../../utils/BrowseBy';

const debug = require('debug')('tca:search:Screens:Search.tsx');

export default ({
  appFilterValue,
  horizontalSpacing,
}: {
  appFilterValue: string;
  horizontalSpacing?: SpacingType;
}): JSX.Element => {
  const navigation = useNavigation();
  const { viewPortWidth } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [browseOptions, setBrowseOptions] = useState<IListItemData[]>([]);

  const isMobileSize = (): boolean => {
    return viewPortWidth <= 640;
  };

  useEffect(() => {
    const loadData = async (context: string) => {
      try {
        const updatedBrowseOptions = await getBrowseOptions(
          appFilterValue,
          context
        );

        setBrowseOptions(updatedBrowseOptions);
        context === 'cache'
          ? setLoading(updatedBrowseOptions.length === 0)
          : setLoading(false);
      } catch (e) {
        debug(`tag request failed for context ${context}`, e);
        context === 'cache' ? setLoading(true) : setLoading(false);
      }
    };

    const initialize = async () => {
      await loadData('cache');
      await loadData('network');
    };
    initialize();
  }, [appFilterValue]);

  return (
    <Pressable
      style={{ flex: 1 }}
      onPress={() => {
        Keyboard.dismiss();
      }}
    >
      <View style={{ flex: 1, marginHorizontal: Spacing.l }}>
        {isLoading ? (
          <View style={styles.container}>
            <KitLoader />
          </View>
        ) : (
          browseOptions?.length > 0 && (
            <View
              style={{
                marginTop: isMobileSize() ? 36 : 48,
              }}
            >
              <KitText bold style={styles.text}>
                Browse by
              </KitText>
              <View>
                {browseOptions.map((item: IListItemData, key: number) => (
                  <ListItem
                    title={item.title}
                    subtitle={item.subtitle}
                    count={item.count}
                    ImageComponent={
                      <KitIcon name={item.icon} color={Colors.N300} size={24} />
                    }
                    topBorder={key === 0}
                    key={key}
                    onPress={() => {
                      Keyboard.dismiss();
                      navigation.navigate('Browse', {
                        appFilterValue: appFilterValue,
                        tagType: (item.type as string).toLowerCase(),
                      });
                    }}
                  />
                ))}
              </View>
            </View>
          )
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    marginBottom: 18,
    color: Colors.N900,
  },
});
