import MediaPlayService, {
  IMediaPlays,
} from '@omni/kit/services/MediaPersonalizationService/MediaPlayService';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:FetchMediaPlayByUserAndMediaItem'
);

interface IFetchMediaPlayByUserAndMediaItem {
  appKey: string;
  userId?: string;
  mediaItemId?: string;
  signal?: AbortSignal;
}

export default async ({
  appKey,
  userId = '',
  mediaItemId = '',
  signal,
}: IFetchMediaPlayByUserAndMediaItem): Promise<IMediaPlays | null> => {
  try {
    debug('Fetching data', { appKey, userId, mediaItemId });
    const mediaPlays = await MediaPlayService.getAll({
      appKey,
      filters: [
        ['app_key', appKey],
        ['end-user.id', userId],
        ['media-item.id', mediaItemId],
      ],
      signal,
    });
    const mediaPlay = mediaPlays?.body?._embedded?.['media-plays']?.[0] || null;
    debug('fetched media play', mediaPlay);

    return mediaPlay;
  } catch (e) {
    const errorMessage = 'failed to fetch media play';
    debug(errorMessage, e);

    throw new Error(errorMessage);
  }
};
