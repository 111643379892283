import BaseServiceV2, { IHttpResponse } from '../../../BaseServiceV2';
import { IPassage, IPreparePassageUrl, preparePassageUrl } from '../';

import TokensService from '@omni/kit/services/TokensService';

const debug = require('debug')('tca:service:PassageService:Core:GetAll');

interface IPassageGetAll extends Omit<IPreparePassageUrl, 'id'> {
  getFromCache?: boolean;
  appKey?: string;
}

interface IPassagesGetAllResponse extends IHttpResponse {
  body?: {
    _embedded: {
      passages: IPassage[];
    };
  };
}

export default async ({
  getFromCache,
  appKey,
  ...buildUrlProps
}: IPassageGetAll): Promise<IPassagesGetAllResponse> => {
  debug('GetAll');
  const url = preparePassageUrl(buildUrlProps);

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    appKey,
    getFromCache,
    token: guestToken,
  });
};
