import { SizeClassContextProvider } from '@omni/kit/contexts/SizeClassContext';
import {
  SOURCE_TYPES,
  SourceContextProvider,
} from '@omni/kit/contexts/SourceContext';
import { IRootProps } from '@omni/kit/contexts/types';
import ThemeContextWrapper from '@omni/kit/theming/ThemeContext';
import { EmojiPickerProvider } from '@omni/media/liveChat/shared/context/EmojiPickerContext';
import { ApplicationContextProvider } from '@omni/native-shell/contexts/ApplicationContext';
import React, { ReactNode } from 'react';
import { RootSiblingParent } from 'react-native-root-siblings';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';

import { ChatAuthContextProvider } from './context/ChatAuthContext';
import store from './shared/redux/store';

export function AppWrapper({
  children,
  props,
}: {
  children: ReactNode;
  props?: IRootProps;
}): JSX.Element {
  return (
    <RootSiblingParent>
      <SourceContextProvider source={SOURCE_TYPES.MESSAGING}>
        <ApplicationContextProvider {...props}>
          <Provider store={store}>
            <ChatAuthContextProvider props={props}>
              <ThemeContextWrapper theme='light'>
                <SizeClassContextProvider>
                  <EmojiPickerProvider isSmall>
                    <SafeAreaProvider>{children}</SafeAreaProvider>
                  </EmojiPickerProvider>
                </SizeClassContextProvider>
              </ThemeContextWrapper>
            </ChatAuthContextProvider>
          </Provider>
        </ApplicationContextProvider>
      </SourceContextProvider>
    </RootSiblingParent>
  );
}
