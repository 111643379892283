import ProfileCard from '@omni/check-in/kiosk/components/ProfileCard';
import useHouseholdMembers from '@omni/check-in/kiosk/hooks/useHouseholdMembers';
import useSessionTypes from '@omni/check-in/kiosk/hooks/useSessionTypes';
import { ISessionSelection } from '@omni/check-in/pre-check/hooks/useStoredSessions';
import { ISessionSuggestion } from '@omni/check-in/pre-check/hooks/useSuggestedSessions';
import { KitButton, KitText, KitTouchableIcon } from '@omni/kit/components';
import { ISession } from '@omni/kit/services/EventsService/Types';
import { IHousehold, IProfile } from '@omni/kit/services/PeopleService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, ScrollView, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

export interface ProfileSelectionModalProps {
  visible: boolean;
  household?: IHousehold;
  sessions: ISession[];
  onBackPress?: () => void;
  onEditSession: (profile: IProfile) => void;
  onSaveProfiles: (profiles: IProfile[]) => void;
  storedSelections?: ISessionSelection[];
  suggestions: ISessionSuggestion[];
  selectedProfiles: IProfile[];
}

const ProfileSelectionModal: React.FC<ProfileSelectionModalProps> = ({
  visible,
  household,
  sessions,
  onBackPress,
  onEditSession,
  onSaveProfiles,
  storedSelections,
  suggestions,
  selectedProfiles,
}): JSX.Element | null => {
  const { t } = useTranslation(['common', 'check-in']);
  const { childMembers, childGuests, children, adults } =
    useHouseholdMembers(household);
  const { adultSessions, childSessions } = useSessionTypes(sessions);

  const showAdultCheckIn = useMemo(() => {
    return Boolean(adults.length && adultSessions.length);
  }, [adults, adultSessions]);

  const showChildCheckIn = useMemo(() => {
    return Boolean(children.length && childSessions.length);
  }, [children, childSessions]);

  const eligibleProfiles = useMemo(() => {
    return ([] as IProfile[]).concat(
      adults.length && adultSessions.length ? adults : [],
      children.length && childSessions.length ? children : []
    );
  }, [adultSessions.length, adults, children, childSessions.length]);

  const allProfilesSuggested = useMemo(() => {
    return suggestions.length === eligibleProfiles.length;
  }, [suggestions, eligibleProfiles]);

  const [profilesSelected, setSelectedProfiles] = useState<IProfile[]>(
    selectedProfiles
      ? selectedProfiles
      : storedSelections?.length
      ? eligibleProfiles.filter((p) =>
          storedSelections.some(
            (s) => s['end-user.id'] === p._embedded?.['end-user']?.id
          )
        )
      : allProfilesSuggested
      ? eligibleProfiles
      : []
  );

  const getSessionForProfile = useCallback(
    (profile: IProfile) => {
      return (storedSelections?.find(
        (s) => s['end-user.id'] === profile?._embedded?.['end-user']?.id
      ) ||
        suggestions.find(
          (s) => s['end-user.id'] === profile?._embedded?.['end-user']?.id
        ))?.['session.title'];
    },
    [storedSelections, suggestions]
  );

  const selectProfile = useCallback(
    (member: IProfile) => {
      setSelectedProfiles((prevState) => [...prevState, member]);
      if (!getSessionForProfile(member)) {
        let sessionsForMember;

        if (
          member.household_role === 'parent' ||
          member.household_role === 'guardian'
        ) {
          sessionsForMember = adultSessions;
        } else {
          sessionsForMember = childSessions;
        }

        if (sessionsForMember.length === 1) {
          storedSelections?.push({
            'end-user.id': member._embedded?.['end-user']?.id as string,
            'session.id': sessionsForMember[0].id,
            'session.title': sessionsForMember[0].title,
          });
        } else {
          onEditSession(member);
        }
      }
    },
    [adultSessions, childSessions, getSessionForProfile, onEditSession]
  );

  const deselectProfile = useCallback((member: IProfile) => {
    setSelectedProfiles((prevState) =>
      prevState.filter((selectedMember) => selectedMember.id !== member.id)
    );
  }, []);

  const onProfileSelect = (profile: IProfile) => {
    if (profilesSelected.includes(profile)) {
      deselectProfile(profile);
    } else {
      selectProfile(profile);
    }
  };

  return visible ? (
    <View
      style={{
        maxHeight: Dimensions.get('window').height - 150,
      }}
    >
      <KitTouchableIcon
        onPress={onBackPress}
        name='back-ios'
        size={20}
        style={{
          marginTop: Spacing.m,
          marginLeft: Spacing.xs,
        }}
        buttonSize={44}
      />
      <ScrollView
        style={{
          height: eligibleProfiles.length * 400,
          marginTop: Spacing.m,
        }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ marginHorizontal: Spacing.l }}>
          <KitText h2>{t('check-in:titleChoosePeopleToCheckIn')}</KitText>
          <View style={{ marginBottom: Spacing.xxxl, marginTop: Spacing.l }}>
            {/* Adults */}
            {showAdultCheckIn ? (
              <>
                <View
                  style={{
                    marginBottom: Spacing.m,
                  }}
                >
                  <KitText black bold fontSize={14}>
                    {t('check-in:titleAdults')}
                  </KitText>
                </View>
                <View
                  style={[
                    {
                      flex: 1,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      marginHorizontal: 0,
                    },
                  ]}
                >
                  {/* Profiles */}
                  {adults.map((profile, index) => (
                    <ProfileCard
                      key={profile.id}
                      profile={profile}
                      showSession={adultSessions.length > 1}
                      height={160}
                      isSelected={profilesSelected.includes(profile)}
                      onSelect={() => onProfileSelect(profile)}
                      onEditSession={() => onEditSession(profile)}
                      showEditIcon={adultSessions.length > 1}
                      session={getSessionForProfile(profile)}
                      index={index}
                    />
                  ))}
                </View>
              </>
            ) : null}
            {/* Children */}
            {showChildCheckIn ? (
              <>
                {showAdultCheckIn || childGuests.length ? (
                  <View
                    style={{
                      marginTop: showAdultCheckIn ? Spacing.l : 0,
                      marginBottom: Spacing.m,
                    }}
                  >
                    <KitText black bold fontSize={14}>
                      {t('check-in:titleChildren')}
                    </KitText>
                  </View>
                ) : null}
                <View
                  style={[
                    {
                      flex: 1,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      marginHorizontal: 0,
                    },
                  ]}
                >
                  {/* Child Household Members */}
                  {childMembers.map((profile, index) => (
                    <ProfileCard
                      key={profile.id}
                      profile={profile}
                      isChild
                      showSession={childSessions.length > 1}
                      height={160}
                      isSelected={profilesSelected.includes(profile)}
                      onSelect={() => onProfileSelect(profile)}
                      onEditSession={() => onEditSession(profile)}
                      showEditIcon={childSessions.length > 1}
                      session={getSessionForProfile(profile)}
                      index={index}
                    />
                  ))}
                </View>
                {/* Child Guest Profiles */}
                {childGuests.length ? (
                  <>
                    <View
                      style={{
                        marginTop: Spacing.l,
                        marginBottom: Spacing.m,
                      }}
                    >
                      <KitText black bold fontSize={14}>
                        {t('check-in:titleGuests')}
                      </KitText>
                    </View>
                    <View
                      style={[
                        {
                          flex: 1,
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          marginHorizontal: 0,
                        },
                      ]}
                    >
                      {/* Child Guests */}
                      {childGuests.map((profile, index) => (
                        <ProfileCard
                          key={profile.id}
                          profile={profile}
                          isChild
                          showSession={childSessions.length > 1}
                          height={160}
                          isSelected={profilesSelected.includes(profile)}
                          onSelect={() => onProfileSelect(profile)}
                          onEditSession={() => onEditSession(profile)}
                          showEditIcon={childSessions.length > 1}
                          session={getSessionForProfile(profile)}
                          index={index}
                        />
                      ))}
                    </View>
                  </>
                ) : null}
              </>
            ) : null}
          </View>
        </View>
      </ScrollView>
      {/* Footer */}
      <LinearGradient
        colors={['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)']}
        start={{ x: 0, y: -0.2 }}
        end={{ x: 0, y: 0.5 }}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: Spacing.l,
          paddingHorizontal: Spacing.l,
        }}
      >
        <KitButton
          onPress={() => onSaveProfiles(profilesSelected)}
          title={t('common:buttonTitleSave')}
          color={Colors.N900}
          textColor={Colors.N0}
          style={{ width: '100%' }}
          disabled={profilesSelected.length === 0}
        />
      </LinearGradient>
    </View>
  ) : null;
};

export default ProfileSelectionModal;
