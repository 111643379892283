import { useShellContext } from '@omni/kit';
import Environment from '@omni/kit/Environment';
import HealthMonitor from '@omni/kit/HealthMonitor';
import { AuthModal, CredentialProps } from '@omni/kit/auth';
import { KitButton, KitSnack } from '@omni/kit/components';
import KitInfo from '@omni/kit/components/KitInfo';
import KitList from '@omni/kit/components/KitList';
import KitListItem from '@omni/kit/components/KitListItem';
import KitLoader from '@omni/kit/components/KitLoader';
import KitMapView from '@omni/kit/components/KitMapView';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import KitText from '@omni/kit/components/KitText';
import Show from '@omni/kit/components/Show';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import AccountsService from '@omni/kit/services/AccountsService';
import { IChannel } from '@omni/kit/services/ChatService';
import GroupsService from '@omni/kit/services/GroupsService';
import {
  GroupRoles,
  IGroup,
  IGroupMember,
  IJoinRequest,
  IProfile,
} from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Depth from '@omni/kit/theming/Depth';
import Spacing from '@omni/kit/theming/Spacing';
import {
  dismissReactNativeModal,
  dispatchAction,
} from '@omni/kit/utilities/NativeHelpers';
import {
  createImageUrl,
  isWithinIframe,
  postContentHeight,
} from '@omni/kit/utilities/utilities';
import ChatService from '@omni/messaging/services/ChatService';
import { getInvitedList } from '@omni/messaging/shared/redux/actions/ChatActions';
import { invitedListSelector } from '@omni/messaging/shared/redux/selectors';
import { RTJConfirmationModal } from '@omni/messaging/shared/scenes/joinRequests/components/RTJConfirmationModal';
import { RequestToJoinModal } from '@omni/messaging/shared/scenes/joinRequests/components/RequestToJoinModal';
import { getShortChannelUrl } from '@omni/messaging/utilities/chatUtilities';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InteractionManager, Platform, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import KitAvatar from '../../kit/components/KitAvatar';
import { constructGroupSchedule, getGroupTypeDisplayName } from '../utilities';

const debug = require('debug')('groups:detail');

export type GroupDetailRoute = RouteProp<GroupDetailParamList, 'Result'>;

type GroupDetailParamList = {
  Result: {
    id: string;
  };
};

interface Props {
  appKey?: string;
  credentialProps?: CredentialProps;
}

export default (props: Props): JSX.Element => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['groups']);
  const navigation = useNavigation();
  const { params } = useRoute<GroupDetailRoute>();
  const { sizeClass } = useSizeClass();

  const { app, tokens, user } = useShellContext();
  const appKey = app.appKey;
  const accessToken = useMemo(() => tokens.user || '', [tokens.user]);
  const isAuthenticated = accessToken.length > 0;

  const invitedList = useSelector(invitedListSelector);

  // Local State
  const [group, setGroup] = useState<IGroup | undefined>();
  const [channel, setChannel] = useState<IChannel | undefined>();
  const [members, setMembers] = useState<IGroupMember[]>([]);
  const [orgKey, setOrgKey] = useState<string | undefined>();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isFetchingGroup, setIsFetchingGroup] = useState(false);
  const [requestToJoinModalVisible, setRequestToJoinModalVisible] =
    useState(false);
  const [rtjConfirmationModal, setRTJConfirmationModal] = useState(false);

  const [requestToJoinStatus, setRequestToJoinStatus] = useState<
    IJoinRequest | undefined
  >();
  const [createJoinRequestAfterLogin, setCreateJoinRequestAfterLogin] =
    useState(false);
  const [isJoiningGroup, setIsJoiningGroup] = useState(false);

  // Computed State
  const endUserId = user?.id;
  const managers = useMemo(() => {
    return members.filter((member) => member.role === GroupRoles.Manager);
  }, [members]);
  const enrollmentType = group?.enrollment_type;

  const myselfAsMember = useMemo(() => {
    return members.filter(
      (member) => member._embedded?.['end-user']?.id === endUserId
    )?.[0];
  }, [members, endUserId]);

  const groupSchedule = group && constructGroupSchedule(group);

  /**
   * The invite list will have a single item for the current user
   * when the user has a non-expired pending invite
   * (applies to both regular members and group managers)
   *
   * However, if a group manager has already accepted the invite to be a group manager,
   * then the invite list will contain pending invites for all users.
   * So we take care not to auto accept a pending invite in this case
   * by also checking 'messaging_status' === 'invited'.
   */
  const pendingInvite = useMemo(() => {
    return myselfAsMember?.messaging_status === 'invited' && invitedList?.[0];
  }, [invitedList, myselfAsMember]);

  const updateInvitedList = useCallback(() => {
    if (channel?.chat_client_channel_id) {
      dispatch(getInvitedList(channel?.chat_client_channel_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const onCreateRequest = async () => {
    if (isAuthenticated && group?.id && endUserId) {
      try {
        const response = await GroupsService.CreateJoinRequest({
          groupId: group.id,
          endUserId,
          token: accessToken,
        });

        if (response.status === 201) {
          setRequestToJoinStatus(response.body);
          setRequestToJoinModalVisible(false);
          setRTJConfirmationModal(true);
        }
      } catch (e) {
        console.error(e);
        KitSnack.show('An error occurred', KitSnackDuration.SHORT);
      }
    } else {
      setRequestToJoinModalVisible(false);
      setShowAuthModal(true);
      setCreateJoinRequestAfterLogin(true);
    }
  };
  // Handlers
  const onPressJoinGroup = useCallback(async () => {
    if (isAuthenticated && group?.id && endUserId) {
      setIsJoiningGroup(true);
      const response = await GroupsService.CreateGroupMember({
        groupId: group.id,
        endUserId,
        token: accessToken,
      });

      if (response.status === 201 && response.body) {
        setMembers((prevMembers) =>
          prevMembers.concat([response.body as IGroupMember])
        );
      } else if (response.status === 409) {
        // ignore - the user is already in the group
      } else {
        KitSnack.show(t('errorUnableToJoinGroup'));
      }
    } else {
      setShowAuthModal(true);
    }
  }, [accessToken, endUserId, group?.id, isAuthenticated, t]);

  const onPressViewInMessaging = useCallback(() => {
    if (Platform.OS === 'web') {
      const channelUrl = `${
        Environment.host
      }/${appKey}/channels/${getShortChannelUrl(channel?.id)}`;

      if (isWithinIframe()) {
        // Open in new tab
        window.open(channelUrl, '_blank');
      } else {
        /**
         * Do not attempt to use window.open using target _self
         * to avoid an infinite page reload issue in small web.
         * See window.opener.location.reload() logic in SmallScreenNavigator.
         * TODO: refactor to use a router to navigate
         */
        // @ts-ignore
        window.location = new URL(channelUrl, window.location).toString();
      }
    } else {
      /**
       * DISP-3013: Open conversation using a native action handler, otherwise
       * the conversation will not open when this button is tapped from a
       * group detail inside the 'groups' handler. The groups handler is used
       * in deep links to directly open a group detail outside the context of
       * Messaging.
       *
       * We must wait for the back/dismiss transition to finish before opening Messaging
       * otherwise the native action may not be dispatched.
       *
       * This is a special case because we're dealing with the transition
       * between two different micro-apps: "chat" and "groups",
       * so we have to use a dismiss + delay workaround here to prevent multiple instances
       * of chat running. Once groups and messaging has been moved to the
       * native-shell, we no longer need this workaround.
       * If we need to do this workaround anywhere else, move this into a helper function.
       */
      const action = {
        handler: 'chat',
        authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
        moduleCommand: {
          name: 'navigate_group_channel',
          chat_client_channel_id: channel?.id,
          require_login: true,
        },
      };

      if (Platform.OS === 'ios') {
        // iOS will not dispatch the action as expected using the InteractionManager when
        // the Group Detail was opened as a modal, so we must use setTimeout instead.
        // In testing, a 500ms delay was not enough, so we use 1000ms.
        setTimeout(() => {
          dispatchAction(action);
        }, 1000);
      } else {
        // Android will not dispatch the action with a timeout, we must use
        // InteractionManager to ensure the action is dispatched after the back/dismiss transition
        InteractionManager.runAfterInteractions(() => {
          dispatchAction(action);
        });
      }

      // goBack is necessary dismiss group detail modal on large screen native messaging.
      // We use this on all screen sizes for consistency.
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        // If the user navigated to the group detail directly (outside the context of Messaging),
        // we need to dismiss the group detail page using the native bridge.
        dismissReactNativeModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appKey, channel?.id]);

  const fetchGroup = useCallback(() => {
    const _fetchGroup = async (id: string) => {
      setIsFetchingGroup(true);
      try {
        const response = await GroupsService.GetGroup({
          id,
          token: accessToken,
        });

        if (response.body) {
          setGroup(response.body);
          if (response.body._embedded?.['group-members']) {
            setMembers(response.body._embedded?.['group-members']);
          }
        } else {
          debug('Could not load group details');
          KitSnack.show(t('errorUnableToLoadGroup'));
          navigation.navigate('Search', { path: 'groups' });
        }
      } catch {}

      setIsFetchingGroup(false);
    };

    _fetchGroup(params.id);

    /**
     * DISP-3448: 'params' is necessary as a dependency in this useEffect
     * so that the group will be fetched to prevent an infinite loading state
     * in the case where:
     * 1. The initial route is group detail (aka direct link to group detail)
     * 2. Then the user navigates back to the groups list
     * 3. Then the user navigates back to the group detail
     *
     * TODO: This is a temporary fix. We should find a better way to handle
     * triggering effects when the route changes. It was surprising that this
     * effect was not triggered with params.id.
     */
  }, [accessToken, navigation, t, params]);

  // Hooks
  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    // If the user is invited to the group, but not in the channel yet, poll the group details to check when the invite has been accepted
    // Their messaging_status will become 'active' when the chat-invite has been accepted (either automatically or by the user)
    if (
      requestToJoinStatus?.status === 'pending' ||
      myselfAsMember?.messaging_status === 'invited'
    ) {
      intervalId = setInterval(() => {
        fetchGroup();
      }, 3000);
    } else if (myselfAsMember?.messaging_status === 'active') {
      setIsJoiningGroup(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [
    fetchGroup,
    myselfAsMember?.messaging_status,
    requestToJoinStatus?.status,
  ]);

  useEffect(() => {
    const fetchJoinRequestStatus = async () => {
      if (isAuthenticated && group?.id) {
        const response = await GroupsService.GetJoinRequest({
          token: accessToken,
          groupId: group.id,
          appKey,
        });

        if (
          response.body?.count !== 0 &&
          response.body?._embedded?.['join-requests'][response.body?.count - 1]
            .status === 'pending'
        ) {
          setRequestToJoinStatus(
            response.body?._embedded?.['join-requests'][
              response.body?.count - 1
            ]
          );
        } else {
          debug('Could not load group details');
          setRequestToJoinStatus(undefined);
        }
      }
    };

    fetchJoinRequestStatus();
  }, [accessToken, appKey, group?.id, isAuthenticated]);

  useEffect(() => {
    const getOrgKey = async () => {
      try {
        const app = await AccountsService.getAppWithBranding(appKey);
        setOrgKey(app?.org_key);
      } catch (e) {
        debug(e);
      }
    };
    getOrgKey();
  }, [appKey]);

  // Fetch channel
  useEffect(() => {
    const fetchChannel = async (groupId: string) => {
      const response = await ChatService.getFiltered(
        'channel',
        [
          ['resource_uuid', groupId],
          ['app_key', appKey],
          ['custom_type', 'group'],
        ],
        accessToken
      );

      if (
        response.data &&
        response.data?.channels &&
        response.data?.channels[0]
      ) {
        setChannel(response.data?.channels[0]);
      } else {
        debug('Could not load channel details');
      }
    };

    if (isAuthenticated && myselfAsMember) {
      fetchChannel(params.id);
    } else {
      setChannel(undefined);
    }
  }, [appKey, accessToken, isAuthenticated, myselfAsMember, params.id]);

  useEffect(() => {
    return navigation.addListener('blur', () => {
      // Clear state when leaving group details page
      setGroup(undefined);
      setMembers([]);
      setChannel(undefined);
      setOrgKey(undefined);
      setCreateJoinRequestAfterLogin(false);
    });
  }, [navigation]);

  // Automatically join the group when returning from Auth
  useEffect(() => {
    if (!isFetchingGroup && isAuthenticated && showAuthModal) {
      setShowAuthModal(false);
      if (createJoinRequestAfterLogin === false) {
        if (!myselfAsMember) {
          onPressJoinGroup();
        }
      } else {
        if (!myselfAsMember) {
          onCreateRequest();
        }
      }
    }
  }, [
    isAuthenticated,
    myselfAsMember,
    onPressJoinGroup,
    showAuthModal,
    isFetchingGroup,
    createJoinRequestAfterLogin,
  ]);

  useEffect(() => {
    updateInvitedList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  // DISP-4176: auto accept pending invitation
  useEffect(() => {
    if (pendingInvite && isAuthenticated) {
      ChatService.patch(
        'channel-invite',
        pendingInvite.id,
        {
          accepted: true,
        },
        accessToken
      )
        .catch((error) => {
          if (error instanceof Error) {
            HealthMonitor.logError(error);
          }
        })
        .finally(() => {
          // refresh invite list, should be empty after accepting an invite
          updateInvitedList();

          // refresh group list for messaging_status change
          fetchGroup();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingInvite, isAuthenticated, accessToken]);

  // Display fields
  const memberCount = group?.member_count || 0;
  const groupType = getGroupTypeDisplayName(group?._embedded?.type?.name);
  const subtitle = `${t('membersWithCount', {
    count: memberCount,
  })}${groupType ? ` • ${groupType}` : ''}`;

  const joinButtons = useMemo(() => {
    let joinsButtons: React.JSX.Element;

    if (enrollmentType === 'closed') {
      joinsButtons = (
        <KitInfo
          icon='lock'
          iconColor={Colors.N600}
          title={t('enrollmentClosed')}
          subtitle={t('enrollmentClosedDesc')}
          style={{
            flex: sizeClass === SizeClass.Small ? undefined : 1,
            marginTop: sizeClass === SizeClass.Small ? Spacing.l : 0,
          }}
        />
      );
    } else if (enrollmentType === 'request_to_join') {
      if (requestToJoinStatus?.status !== undefined) {
        if (requestToJoinStatus.status === 'pending') {
          joinsButtons = (
            <KitInfo
              icon='clock'
              iconColor={Colors.N600}
              title={t('infoPendingApproval')}
              subtitle={t('infoRequestedToJoin', {
                date: moment(requestToJoinStatus.created_at).format(
                  'MMM D, yyyy'
                ),
              })}
              style={{
                flex: sizeClass === SizeClass.Small ? undefined : 1,
                marginTop: sizeClass === SizeClass.Small ? Spacing.l : 0,
              }}
            />
          );
        } else if (requestToJoinStatus.status === 'approved') {
          joinsButtons = (
            <KitButton
              title={t('buttonTitleViewInMessaging')}
              color={Colors.N900}
              small={sizeClass === SizeClass.Medium}
              style={{
                marginTop: sizeClass === SizeClass.Small ? Spacing.l : 0,
              }}
              onPress={onPressViewInMessaging}
            />
          );
        } else {
          joinsButtons = (
            <KitButton
              title={t('buttonTitleViewInRequestToJoin')}
              color={Colors.N900}
              style={{
                marginTop: sizeClass === SizeClass.Small ? Spacing.l : 0,
              }}
              onPress={() => setRequestToJoinModalVisible(true)}
            />
          );
        }
      } else {
        joinsButtons = (
          <KitButton
            title={t('buttonTitleViewInRequestToJoin')}
            color={Colors.N900}
            style={{
              marginTop: sizeClass === SizeClass.Small ? Spacing.l : 0,
            }}
            onPress={() => setRequestToJoinModalVisible(true)}
          />
        );
      }
    } else {
      joinsButtons = (
        <KitButton
          title={t('buttonTitleJoinGroup')}
          color={Colors.N900}
          style={{
            marginTop: sizeClass === SizeClass.Small ? Spacing.l : 0,
          }}
          onPress={onPressJoinGroup}
          isLoading={isJoiningGroup}
        />
      );
    }

    return joinsButtons;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channel,
    enrollmentType,
    isJoiningGroup,
    requestToJoinStatus?.created_at,
    requestToJoinStatus?.status,
    sizeClass,
    t,
  ]);

  // Loading state
  if (!group) {
    return (
      <View style={{ alignItems: 'center', flex: 1, justifyContent: 'center' }}>
        <KitLoader />
      </View>
    );
  }

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: Colors.N0,
          padding: Spacing.l,
        }}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={(_, height) =>
          // This is to support dynamically sizing the Group Finder embed
          postContentHeight(height + 2 * Spacing.l)
        }
      >
        <Show show={Platform.OS === 'web'}>
          <KitButton
            title={t('buttonTitleBackToGroups')}
            secondary
            small
            icon='bar-back-android'
            style={{ marginBottom: Spacing.xxl }}
            onPress={() => navigation.navigate('Search', { path: 'groups' })}
          />
        </Show>
        {/* Wrapping avatar, title, & button/info */}
        <View
          style={{
            flexDirection: sizeClass === SizeClass.Small ? 'column' : 'row',
            borderBottomWidth: sizeClass === SizeClass.Small ? 0 : 1,
            borderBottomColor: Colors.N100,
            paddingBottom: sizeClass === SizeClass.Small ? 0 : Spacing.xl,
            alignItems: sizeClass === SizeClass.Small ? 'stretch' : 'center',
          }}
        >
          {/* Wrapping avatar & title/subtitle */}
          <View
            style={{
              flexDirection: 'row',
              flex: sizeClass === SizeClass.Small ? undefined : 2,
              alignItems: sizeClass === SizeClass.Small ? 'stretch' : 'center',
            }}
          >
            <KitAvatar
              imageUrl={createImageUrl(group._embedded?.image?.id)}
              size={96}
              nickname={group.name}
              style={Depth.m}
            />
            <View
              style={{
                marginLeft: Spacing.l,
                alignSelf: 'center',
                flexShrink: 1,
              }}
            >
              <KitText
                black
                extraBold
                fontSize={sizeClass !== SizeClass.Small ? 36 : 22}
                style={{
                  maxWidth: 400,
                  lineHeight: sizeClass !== SizeClass.Small ? 36 : 22,
                }}
              >
                {group.name}
              </KitText>
              <KitText
                gray
                fontSize={sizeClass !== SizeClass.Small ? 16 : 14}
                style={{ marginTop: Spacing.xs, textTransform: 'capitalize' }}
              >
                {subtitle}
              </KitText>
            </View>
          </View>
          {myselfAsMember &&
          (!myselfAsMember.messaging_status ||
            myselfAsMember.messaging_status === 'active') ? (
            channel ? (
              <KitButton
                title={t('buttonTitleViewInMessaging')}
                color={Colors.N900}
                small={sizeClass === SizeClass.Medium}
                style={{
                  marginTop: sizeClass === SizeClass.Small ? Spacing.l : 0,
                }}
                onPress={onPressViewInMessaging}
              />
            ) : null
          ) : !myselfAsMember /* not invited yet */ ||
            (myselfAsMember.messaging_status === 'invited' &&
              !pendingInvite) /* expired invite */ ? (
            joinButtons
          ) : (
            <></>
          )}
          <KitModalV2
            anchorBottom={sizeClass === SizeClass.Small}
            coverScreen={requestToJoinModalVisible}
            isVisible={requestToJoinModalVisible}
            onClose={() => {
              setRequestToJoinModalVisible(false);
            }}
          >
            <View
              style={{
                width: sizeClass === SizeClass.Small ? '100%' : 620,
              }}
            >
              <Show show={requestToJoinModalVisible}>
                <RequestToJoinModal
                  onCancel={() => setRequestToJoinModalVisible(false)}
                  onConfirm={onCreateRequest}
                  sizeClass={sizeClass}
                />
              </Show>
            </View>
          </KitModalV2>
          <KitModalV2
            anchorBottom={sizeClass === SizeClass.Small}
            coverScreen={rtjConfirmationModal}
            isVisible={rtjConfirmationModal}
            onClose={() => {
              setRTJConfirmationModal(false);
            }}
          >
            <View style={{ margin: Spacing.xl }}>
              <Show show={rtjConfirmationModal}>
                <RTJConfirmationModal
                  onConfirm={() => setRTJConfirmationModal(false)}
                  sizeClass={sizeClass}
                />
              </Show>
            </View>
          </KitModalV2>
        </View>
        <View
          style={{
            flexDirection: sizeClass !== SizeClass.Small ? 'row' : 'column',
          }}
        >
          <View
            style={[
              {
                maxWidth: 510,
                marginRight: sizeClass !== SizeClass.Small ? Spacing.xxl : 0,
              },
              sizeClass !== SizeClass.Small && { width: 510 },
            ]}
          >
            <Show show={Boolean(group.description)}>
              <>
                <Show show={sizeClass !== SizeClass.Small}>
                  <KitText
                    black
                    bold
                    fontSize={16}
                    style={{ marginTop: Spacing.xl, marginBottom: Spacing.xs }}
                  >
                    {t('labelDescription')}
                  </KitText>
                </Show>
                <KitText
                  gray
                  fontSize={16}
                  style={{
                    marginTop: sizeClass !== SizeClass.Small ? 0 : Spacing.xl,
                  }}
                  numberOfLines={4}
                  expandable
                >
                  {group.description}
                </KitText>
              </>
            </Show>
            <Show show={Boolean(managers.length)}>
              <>
                <KitText
                  black
                  bold
                  fontSize={16}
                  style={{ marginTop: Spacing.xl }}
                >
                  {t('labelGroupManagers')}
                </KitText>
                <KitList
                  data={managers}
                  renderItem={({ item, index }) => {
                    try {
                      const profile = item?._embedded['end-user']?._embedded
                        ?.profiles[0] as IProfile;

                      return (
                        <KitListItem
                          ImageComponent={
                            <KitAvatar
                              nickname={`${profile.first_name} ${profile.last_name}`}
                              size={Spacing.xxl}
                              imageUrl={profile.photo_url}
                            />
                          }
                          title={`${profile.first_name} ${profile.last_name}`}
                          topBorder={
                            sizeClass === SizeClass.Small && index === 0
                          }
                          bottomBorder={sizeClass === SizeClass.Small}
                        />
                      );
                    } catch (e) {
                      return null;
                    }
                  }}
                  style={{ marginTop: Spacing.s }}
                />
              </>
            </Show>
          </View>
          <View>
            {groupSchedule !== '' && (
              <>
                <KitText
                  color={Colors.N900}
                  bold
                  fontSize={16}
                  style={{ marginTop: Spacing.xl, marginBottom: Spacing.xs }}
                >
                  {t('schedule:headerTitle')}
                </KitText>
                <KitText
                  fontSize={16}
                  lineHeight={22}
                  color={
                    sizeClass === SizeClass.Small ? Colors.N500 : Colors.N900
                  }
                  style={{
                    flex: 1,
                    marginBottom: Spacing.xl,
                  }}
                  lineBreakMode='tail'
                >
                  {groupSchedule}
                </KitText>
              </>
            )}

            {/* TODO after location BE work is done  */}
            {/* <KitText
              color={Colors.N900}
              bold
              fontSize={16}
              style={{ marginBottom: Spacing.xs }}
            >
              {t('labelGroupLocation')}
            </KitText>
         
          <KitText
              fontSize={16}
              lineHeight={22}
              color={sizeClass === SizeClass.Small ? Colors.N500 : Colors.N900}
            >
              Starbucks • Wenatchee, WA
            </KitText>
            <KitMapView
              latitude={group.latitude}
              longitude={group.longitude}
              circle={{
                radius: 3218.68,
                strokeColor: Colors.N1000,
                fillColor: 'rgba(0, 0, 0, 0.2)',
                strokeOpacity: 0.8,
                fillOpacity: 0.8,
                strokeWeight: 0.6,
              }}
            /> */}
          </View>
        </View>
      </ScrollView>
      <AuthModal
        appKey={appKey}
        orgKey={orgKey}
        returnUrl={`${Environment.host}/${appKey}/auth`}
        onClose={() => {
          setShowAuthModal(false);
        }}
        isVisible={showAuthModal}
        targetAuthProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
      />
    </>
  );
};
