import AsyncStorage from '@react-native-async-storage/async-storage';

import Environment from '../../Environment';
import { fetchGuestTokenData } from './fetchGuestTokenData';

export const getGuestToken = async (): Promise<string | undefined> => {
  const cachedTokenData = await getCachedGuestTokenData();

  if (isTokenDataValid(cachedTokenData?.token, cachedTokenData?.expiration)) {
    return cachedTokenData?.token;
  }

  const fetchedData = await fetchGuestTokenData();
  const token = fetchedData?.access_token ?? '';
  const expiresInSeconds = fetchedData?.expires_in;
  const expiration = Date.now() + (expiresInSeconds ?? 0) * 1000;

  if (isTokenDataValid(token, expiration)) {
    await cacheGuestTokenData(token, expiration);
  }

  return token;
};

const isTokenDataValid = (token?: string, expiration?: number): boolean => {
  const now = Date.now();

  // PPL-1798: A guest token may become expired by the time a network request
  // is handled on the backend if the guest token was nearly expired.
  const twoMinutesInMsec = 2 * 60 * 1000;
  const adjustedExpirationTime = (expiration ?? now) - twoMinutesInMsec;

  return Boolean(token) && now < adjustedExpirationTime;
};

const cacheGuestTokenData = async (
  token: string,
  expirationTimestamp: number
): Promise<void> => {
  await AsyncStorage.setItem('guest_token', token);
  await AsyncStorage.setItem(
    'guest_token_expiration_timestamp',
    `${expirationTimestamp}`
  );
  await AsyncStorage.setItem(
    'guest_token_client_id',
    Environment.tokensServiceClientId
  );
};

const getCachedGuestTokenData = async (): Promise<
  { token: string; expiration: number } | undefined
> => {
  const clientId = Environment.tokensServiceClientId;
  const cachedClientId = await AsyncStorage.getItem('guest_token_client_id');
  const token = await AsyncStorage.getItem('guest_token');

  if (clientId !== cachedClientId || token === null || !token.length) {
    return;
  }

  const expirationString = await AsyncStorage.getItem(
    'guest_token_expiration_timestamp'
  );

  const expiration = expirationString !== null ? Number(expirationString) : 0;

  return { token, expiration };
};

export default {
  getGuestToken,
};
