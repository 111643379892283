import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { Children, useState } from 'react';
import { ScrollView, View } from 'react-native';

export default function KitCarousel({
  cardWidth,
  children,
}: {
  cardWidth: number;
  children: React.ReactNode;
}): JSX.Element {
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  return (
    <>
      <ScrollView
        horizontal={true}
        decelerationRate='fast'
        snapToInterval={cardWidth}
        showsHorizontalScrollIndicator={false}
        onScroll={({ nativeEvent }) => {
          setActiveCardIndex(
            Math.round(nativeEvent.contentOffset.x / cardWidth)
          );
        }}
        scrollEventThrottle={100}
        scrollEnabled={Children.count(children) > 1}
      >
        {children}
      </ScrollView>
      {/* Dots */}
      {Children.count(children) > 1 ? (
        <View
          style={{
            justifyContent: 'center',
            marginBottom: -Spacing.l,
            marginTop: Spacing.s,
            flexDirection: 'row',
          }}
        >
          {Array(Children.count(children))
            .fill(0)
            .map((_, index) => {
              return (
                <View
                  key={index}
                  style={{
                    width: 8,
                    height: 8,
                    backgroundColor: Colors.N0,
                    opacity: index === activeCardIndex ? 1 : 0.5,
                    margin: 5,
                    borderRadius: BorderRadius.full,
                  }}
                />
              );
            })}
        </View>
      ) : null}
    </>
  );
}
