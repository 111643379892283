import Environment from '@omni/kit/Environment';
import React, { useCallback, useEffect } from 'react';

interface Props {
  onUpdateCaptcha: any;
  /**
   * If hideBadge is true, reCaptcha text and links must be visible the page.
   * https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
   */
  hideBadge?: boolean;
}

interface ExecuteOptions {
  action: string;
}

interface Grecaptcha {
  ready(callback: () => void): void;
  execute(googleCaptchaKey: string, options: ExecuteOptions): Promise<string>;
}

declare global {
  interface Window {
    grecaptcha: Grecaptcha;
  }
}

/**
 * The `action` passed as part of options is an arbitrary
 * string but must remain `submit_guest_gift` as the backend uses it for
 * an additional check to verify the authenticity of the request.
 */
const captchaActionString = 'submit_guest_gift';

export default ({ onUpdateCaptcha, hideBadge }: Props): JSX.Element => {
  const handleLoaded = useCallback(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(Environment.googleCaptchaKey, {
          action: captchaActionString,
        })
        .then((token: string) => {
          /** Send captcha string to parent so guest gift can proceed. */
          onUpdateCaptcha(token);
        });
    });
  }, [onUpdateCaptcha]);

  /** Append re-captcha script to the page after load. */
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://www.google.com/recaptcha/api.js?render=${Environment.googleCaptchaKey}`;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);
    if (hideBadge) {
      /**
       * For requirements when hiding the badge, see
       * https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
       */
      const style = document.createElement('style');
      style.innerHTML = '.grecaptcha-badge { visibility: hidden; }';
      document.head.appendChild(style);
    }
  }, [handleLoaded, hideBadge]);

  return <></>;
};
