import KitText from '@omni/kit/components/KitText';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useEffect, useState } from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';

export default function LiveTag(): JSX.Element {
  const [opacity] = useState(new Animated.Value(0.2));

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          duration: 2000,
          easing: Easing.inOut(Easing.ease),
          toValue: 1,
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          duration: 2000,
          easing: Easing.inOut(Easing.ease),
          toValue: 0.2,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [opacity]);

  return (
    <View style={[styles.tag]}>
      <Animated.View style={{ opacity }}>
        <KitText white fontSize={12} style={{ marginRight: 8 }}>
          ●
        </KitText>
      </Animated.View>
      <KitText white bold style={{ letterSpacing: 1.2 }}>
        LIVE
      </KitText>
    </View>
  );
}

const styles = StyleSheet.create({
  tag: {
    alignItems: 'center',
    backgroundColor: '#ff424f',
    borderRadius: 3,
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 2,
    position: 'absolute',
    right: Spacing.l,
    top: Spacing.l,
  },
});
