import {
  GridItem,
  IGridItemData,
  ItemAlignment,
  RadiusStyle,
} from '@omni/blocks';
import KitIcon from '@omni/kit/components/KitIcon';
import KitList from '@omni/kit/components/KitList';
import KitText from '@omni/kit/components/KitText';
import KitTouchable from '@omni/kit/components/KitTouchable';
import Show from '@omni/kit/components/Show';
import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
} from '@omni/kit/constants/ImageSizeDefault';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useCallback, useEffect, useState } from 'react';
import { View, ViewStyle } from 'react-native';

import styles from './styles';

const CIRCLE_ITEM_SIZE = 100;
const SILHOUETTE_LOGO_IMAGE_SIZE = 76;

export interface IHorizontalScroll {
  key?: string;
  data?: IGridItemData[];
  itemHeight?: number;
  itemWidth?: number;
  onItemPressed: (item: IGridItemData, index: number) => void;
  onTitlePressed?: () => void;
  silhouetteLogoImage?: string;
  silhouetteLogoImageSize?: number;
  showViewMore?: boolean;
  title: string | JSX.Element;
  isAvatar?: boolean;
  noImage?: boolean;
  numRows?: number;
  textWrap?: boolean;
  textCenter?: boolean;
  style?: ViewStyle;
  firstItemPadding?: number;
  betweenItemPadding?: number;
}

export default function HorizontalScroll({
  betweenItemPadding = Spacing.l,
  data,
  itemHeight = IMAGE_HEIGHT,
  itemWidth = IMAGE_WIDTH,
  onItemPressed,
  onTitlePressed,
  silhouetteLogoImage,
  silhouetteLogoImageSize = SILHOUETTE_LOGO_IMAGE_SIZE,
  showViewMore = true,
  title,
  isAvatar = false,
  noImage = false,
  numRows = 1,
  textWrap = false,
  textCenter = false,
  style,
  firstItemPadding = 0,
}: IHorizontalScroll): JSX.Element {
  const [adjustedData, setAdustedData] = useState<IGridItemData[][]>([]);

  const onListItemPressed = function (
    item: IGridItemData,
    _index: number
  ): void {
    onItemPressed(item, _index);
  };

  const renderOneItem = ({
    item,
    index,
  }: {
    item: IGridItemData;
    index: number;
  }) => {
    const columnIndex = Math.floor(index / numRows);
    let paddingLeft = 0;
    let paddingRight: number = betweenItemPadding;

    if (columnIndex === 0) {
      /** first column */
      paddingLeft = firstItemPadding;
    } else if (columnIndex === adjustedData.length - 1) {
      /** last column */
      paddingRight = firstItemPadding;
    }

    return (
      <GridItem
        isAvatar={isAvatar}
        key={index}
        averageColor={item.averageHexColor}
        data={item}
        fixedWidthEnabled={true}
        height={isAvatar ? CIRCLE_ITEM_SIZE : itemHeight}
        ignoreImageLoad={true}
        imageHeight={itemHeight}
        imageRadius={RadiusStyle.Medium}
        imageWidth={itemWidth}
        noImage={noImage}
        mediaDurationStyle={{
          marginBottom: 5,
          marginRight: 8,
        }}
        onPress={() => {
          onListItemPressed(item, index);
        }}
        silhouetteLogoImage={silhouetteLogoImage}
        silhouetteLogoImageSize={silhouetteLogoImageSize}
        shadowEnabled={true}
        style={{
          paddingLeft,
          paddingRight,
        }}
        width={isAvatar ? CIRCLE_ITEM_SIZE : itemWidth}
        textAlignmentHorizontal={isAvatar ? ItemAlignment.Center : undefined}
        textCenter={textCenter}
        textWrap={textWrap}
        titleLineHeight={16}
      />
    );
  };

  const renderMultiRowItem = ({
    item,
    index,
  }: {
    item: IGridItemData[];
    index: number;
  }) => {
    return (
      <View>
        {item.map((i, idx) =>
          renderOneItem({ item: i, index: index * numRows + idx })
        )}
      </View>
    );
  };

  const listKeyExtractor = useCallback(
    (item: IGridItemData[], index: number) => {
      return index.toString();
    },
    []
  );

  /** Support multi row rendering when horizontal scrolling is enabled */
  useEffect(() => {
    const adjustedData: IGridItemData[][] = [];

    data?.forEach((item, index) => {
      if (index % numRows === 0) {
        adjustedData.push([item]);
      } else {
        adjustedData[adjustedData.length - 1].push(item);
      }
    });

    setAdustedData(adjustedData);
  }, [data, numRows]);

  return (
    <View style={[styles.container, style]}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: firstItemPadding,
        }}
      >
        {typeof title === 'string' ? (
          <KitTouchable
            style={[styles.titleWrapper]}
            borderRadius={0}
            ripple={false}
            onPress={() => {
              if (!showViewMore) return;

              onTitlePressed?.();
            }}
            underlayColor={Colors.N1000}
          >
            <View style={styles.titleContainer}>
              <KitText color={Colors.N0} fontSize={20} bold>
                {title}
              </KitText>
              <Show show={Boolean(showViewMore)}>
                <KitIcon
                  color={Colors.N300}
                  name='arrow-right'
                  size={20}
                  style={{ marginLeft: Spacing.m }}
                />
              </Show>
            </View>
          </KitTouchable>
        ) : (
          title
        )}
      </View>
      <View>
        <KitList<IGridItemData[]>
          data={adjustedData}
          horizontal={true}
          initialNumToRender={10}
          keyExtractor={listKeyExtractor}
          maxToRenderPerBatch={10}
          renderItem={renderMultiRowItem}
          scrollEnabled={true}
          showsHorizontalScrollIndicator={false}
        />
      </View>
    </View>
  );
}
