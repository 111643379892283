import { UNLEASH_TOGGLES } from '@omni/kit/services/UnleashService';
import { useEffect, useState } from 'react';

import { FEATURE_FLAGS, useUnleash } from '../contexts/FeatureFlagContext';

interface Flag {
  isEnabled: boolean;
  isReady: boolean;
}

export const useFeatureFlag = (
  featureKey: FEATURE_FLAGS | UNLEASH_TOGGLES
): Flag => {
  const { client, isClientReady } = useUnleash();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const updateFlag = () => {
    if (!client) return;

    setIsEnabled(client.isEnabled(featureKey));
    setIsReady(true);
  };

  useEffect(() => {
    if (!client || !isClientReady) return;

    updateFlag();
    client.on('update', updateFlag);

    return () => {
      client.off('update', updateFlag);
    };
  }, [client, isClientReady]);

  return { isEnabled, isReady };
};
