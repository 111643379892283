import Environment from '../Environment';

const debug = require('debug')('tca:blockAccount:service:Location');

export async function getAll(resource: string) {
  const url = _buildUrl(resource);

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'X-Sap-Service': 'omni-app' },
    });

    return _handleAllResponse(response);
  } catch (response_1) {
    // @ts-ignore
    return _handleErrorResponse(response_1);
  }
}

const _buildUrl = (resource: string) => {
  return `${Environment.locationService}/${resource}`;
};

const _handleAllResponse = (response: Response) => {
  if (!response.ok) return Promise.reject(response);

  return response.json().then((data) => {
    return {
      meta: {
        count: data.count,
        total: data.total,
      },
      status: response.status,
      data: data._embedded ? data._embedded : data,
    };
  });
};

const _handleErrorResponse = (response: Response) => {
  return response.text().then((text) => {
    try {
      const json = JSON.parse(text);

      return Promise.reject({
        status: response.status,
        message: Array.isArray(json.errors) ? json.errors[0].detail : json,
      });
    } catch (e) {
      return Promise.reject({
        status: response.status,
        message: text,
      });
    }
  });
};
