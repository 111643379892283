import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IUri,
  buildUrl,
} from '../BaseServiceV2';

import Environment from '../../Environment';
import TokensService from '@omni/kit/services/TokensService';

const HOST = Environment.feedsService;
const RESOURCE = 'favorites';

interface IAction {
  is_home: boolean;
  is_media_search_enabled?: boolean;
  appkey: string;
  handler: string;
}
interface IFavorite {
  images: any;
  sapid: string;
  title: string;
  actions?: IAction[];
}

interface IGetFavorites extends IUri {
  token?: string;
  filters: IFilter[];
  getFromCache: boolean;
}

interface IGetFavoritesResponse extends IHttpResponse {
  body?: {
    items: IFavorite[];
  };
}

export default async function ({
  token,
  getFromCache,
  ...buildUrlProps
}: IGetFavorites): Promise<IGetFavoritesResponse> {
  const url = buildUrl({ host: HOST, resource: RESOURCE, ...buildUrlProps });

  if (!token?.length) {
    token = await TokensService.getGuestToken();
  }

  return BaseServiceV2.Get({
    url,
    token,
    getFromCache,
  });
}
