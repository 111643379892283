import AsyncStorage from '@react-native-async-storage/async-storage';
import { UnleashClient } from 'unleash-proxy-client';
import unleashConfig from './unleashConfig';

const PREFIX = 'unleash:repository';

const unleash = new UnleashClient({
  ...unleashConfig,
  storageProvider: {
    save: (name: string, data: any) => {
      const repo = JSON.stringify(data);
      const key = `${PREFIX}:${name}`;

      return AsyncStorage.setItem(key, repo);
    },
    get: async (name: string) => {
      const key = `${PREFIX}:${name}`;
      const data = await AsyncStorage.getItem(key);

      return data ? JSON.parse(data) : undefined;
    },
  },
});

export default unleash;
