const debug = require('debug')('tca:chat:utilities:fileUtilities.web');

// TODO: Implement download image for the web
export const downloadImage = (uri: string): Promise<void> => {
  debug('downloadImage()', uri);

  return new Promise((resolve, _reject) => {
    resolve();
  });
};

// TODO: Implement open file for the web
export const openFile = (name: string, url: string): Promise<void> => {
  return new Promise((resolve, _reject) => {
    resolve();
  });
};
