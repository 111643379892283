import { useShellContext } from '@omni/kit';
import { KitSelect, KitText } from '@omni/kit/components';
import { KitListItem } from '@omni/kit/components';
import KitInputMask from '@omni/kit/components/KitInputMask';
import KitInputValidationMessage from '@omni/kit/components/KitInputValidationMessage';
import KitOptionSelector from '@omni/kit/components/KitOptionSelector';
import Show from '@omni/kit/components/Show';
import { useSnackbarContext } from '@omni/kit/contexts/Snackbar';
import PeopleService from '@omni/kit/services/PeopleService';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { useCheckInContext } from '../contexts/CheckInContext';
import { useKioskContext } from '../contexts/KioskContext';
import AllergyNotesInput from './AllergyNotesInput';
import CareNotesInput from './CareNotesInput';
import KioskInput from './KioskInput';
import KioskModal from './KioskModal';
import KioskModalButton from './KioskModalButton';

const GRADES = [
  { label: 'Pre-K', value: 'pre-k' },
  { label: 'K', value: 'k' },
  { label: '1st', value: '1st' },
  { label: '2nd', value: '2nd' },
  { label: '3rd', value: '3rd' },
  { label: '4th', value: '4th' },
  { label: '5th', value: '5th' },
  { label: '6th', value: '6th' },
  { label: '7th', value: '7th' },
  { label: '8th', value: '8th' },
  { label: '9th', value: '9th' },
  { label: '10th', value: '10th' },
  { label: '11th', value: '11th' },
  { label: '12th', value: '12th' },
];

type ValidationKey = 'academicGrade' | 'birthDate' | 'firstName' | 'lastName';

type Props = {
  title: string;
  profile?: IProfile;
  subtitle?: string;
  householdName?: string;
  isVisible: boolean;
  isGuestCreate?: boolean;
  isGuestEdit?: boolean;
  showSession?: boolean;
  onClose: () => void;
  onEditSession?: () => void;
  refreshData?: () => void;
  onModalHide?: () => void;
  session?: string;
  setGuestProfileTemp?: (profile: IProfile) => void;
  handleUpdateSessionMap?: (id: string | undefined) => void;
};

export default function ChildProfileModal({
  title,
  profile,
  subtitle,
  householdName,
  isVisible,
  isGuestCreate,
  isGuestEdit,
  showSession,
  onClose,
  onEditSession,
  refreshData,
  onModalHide,
  session,
  setGuestProfileTemp,
  handleUpdateSessionMap,
}: Props): JSX.Element {
  const { app } = useShellContext();
  const { token } = useKioskContext();
  const { showSnackbar } = useSnackbarContext();
  const { t } = useTranslation(['check-in', 'common', 'profile']);
  const { selectedHousehold } = useCheckInContext();

  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState(profile?.first_name || '');
  const [lastName, setLastName] = useState(
    profile?.last_name || householdName || ''
  );
  const [gender, setGender] = useState(profile?.gender || '');
  const [birthDate, setBirthDate] = useState('');
  const [academicGrade, setAcademicGrade] = useState(
    profile?.academic_grade?.name || ''
  );
  const [allergyNotes, setAllergyNotes] = useState(
    profile?.allergy_notes || ''
  );
  const [careNotes, setCareNotes] = useState(profile?.care_notes || '');

  const [errors, setErrors] = useState<Record<ValidationKey, boolean>>({
    academicGrade: false,
    birthDate: false,
    firstName: false,
    lastName: false,
  });

  const [isValidBirthday, setIsValidBirthday] = useState(true);

  const genderOptions = [
    { id: 'male', title: t('profile:textMale') },
    { id: 'female', title: t('profile:textFemale') },
  ];

  const checkBirthdayValidation = (): boolean => {
    const now = moment().format();

    const formatedBirthDate = moment(birthDate, 'MM/DD/YYYY').format();
    const lowestDate = moment('01/01/1900', 'MM/DD/YYYY').format();

    if (formatedBirthDate < now && formatedBirthDate > lowestDate) {
      const isValid = moment(birthDate, 'MM/DD/YYYY', true).isValid();
      setIsValidBirthday(isValid);

      return isValid;
    } else {
      setIsValidBirthday(false);

      return false;
    }
  };

  const getBirthdayValidationMessage = useMemo(() => {
    if (birthDate.length === 0) {
      return t('check-in:textRequiredBirthday');
    }

    if (birthDate.length > 0 && birthDate.length < 10) {
      return t('check-in:textBirthDateInvalid');
    }

    if (birthDate) {
      if (moment(birthDate, 'MM/DD/YYYY').isAfter(moment())) {
        return t('check-in:textBirthDateCannotBeFuture');
      }
    }

    return '';

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthDate]);

  const hasChanges = useMemo(() => {
    if (profile && !isGuestCreate) {
      const isValid = checkBirthdayValidation();

      return (
        firstName !== (profile?.first_name || '') ||
        lastName !== (profile?.last_name || '') ||
        gender !== (profile?.gender || '') ||
        (isValid &&
          !isGuestEdit &&
          !moment(birthDate).isSame(moment(profile?.date_of_birth || ''))) ||
        academicGrade !== (profile?.academic_grade?.name || '')
      );
    } else {
      return true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, firstName, lastName, gender, birthDate, academicGrade]);

  const isFormValid = useMemo(
    () =>
      firstName &&
      lastName &&
      gender &&
      (birthDate || isGuestCreate || isGuestEdit) &&
      (session || !showSession) &&
      !Object.values(errors).some((val) => val === true),
    [
      errors,
      firstName,
      lastName,
      gender,
      birthDate,
      isGuestCreate,
      isGuestEdit,
      session,
      showSession,
    ]
  );

  const createProfile = async () => {
    if (!birthDate && !isGuestCreate) {
      setErrors({
        ...errors,
        birthDate: true,
      });

      return;
    }

    setIsSaving(true);
    try {
      const profileCreationResponse = await PeopleService.CreateProfile({
        body: {
          org_key: app.orgKey,
          first_name: firstName,
          last_name: lastName,
          household_role: isGuestCreate ? undefined : 'child',
          gender: gender,
          date_of_birth: birthDate
            ? moment(birthDate, 'MM/DD/YYYY').format()
            : null,
          academic_grade:
            isGuestCreate || !academicGrade
              ? null
              : {
                  name: academicGrade,
                },
          allergy_notes: allergyNotes,
          care_notes: careNotes,
          created_source: 'check_in',
          _embedded: {
            household: isGuestCreate
              ? null
              : {
                  id: selectedHousehold?.id,
                },
            'end-user': {
              first_name: firstName,
              last_name: lastName,
            },
          },
        },
        token,
      });

      if (profileCreationResponse.status === 201) {
        if (isGuestCreate) {
          await PeopleService.CreateHouseholdRelationship({
            body: {
              type: 'guest',
              _embedded: {
                household: {
                  id: selectedHousehold?.id,
                },
                profile: {
                  id: profileCreationResponse?.body?.id,
                },
              },
            },
            token,
          });

          handleUpdateSessionMap?.(profileCreationResponse?.body?.id);
        }

        showSnackbar(
          t('check-in:textProfileAdded', {
            name: `${firstName} ${lastName}`,
          })
        );
        onClose();
        refreshData?.();
      }
    } catch (error) {
      showSnackbar(t('check-in:textErrorUnableToSave'));
    } finally {
      setIsSaving(false);
    }
  };

  const updateProfile = async () => {
    if (!birthDate && !isGuestEdit) {
      setErrors({
        ...errors,
        birthDate: true,
      });

      return;
    }

    setIsSaving(true);
    try {
      await PeopleService.UpdateProfile({
        id: profile?.id,
        body: {
          org_key: app.orgKey,
          first_name: firstName,
          last_name: lastName,
          gender: gender,
          date_of_birth: birthDate
            ? moment(birthDate, 'MM/DD/YYYY').format()
            : null,
          academic_grade: academicGrade
            ? {
                name: academicGrade,
              }
            : null,
        },
        token,
      });
      onClose();
      refreshData?.();
    } catch (error) {
      showSnackbar(t('check-in:textErrorUnableToSave'));
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (profile?.date_of_birth) {
      const date = moment(profile?.date_of_birth).format('MM/DD/YYYY');
      setBirthDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KioskModal
      isVisible={isVisible}
      onClose={onClose}
      onModalHide={onModalHide}
      title={title}
      subtitle={subtitle}
      width={600}
      smallContent={true}
      enableScroll={true}
      footer={
        <View style={styles.footerContainer}>
          <KioskModalButton
            secondary
            style={{ marginRight: Spacing.l }}
            title={t('common:buttonTitleCancel')}
            onPress={() => {
              setGuestProfileTemp?.({
                id: 'guestTemp',
              });
              handleUpdateSessionMap?.(undefined);
              onClose();
            }}
          />
          <KioskModalButton
            disabled={!isFormValid || isSaving || !hasChanges}
            isLoading={isSaving}
            title={t('common:buttonTitleSave')}
            onPress={profile && !isGuestCreate ? updateProfile : createProfile}
          />
        </View>
      }
    >
      <View style={styles.container}>
        {/* First Name */}
        <KitInputValidationMessage
          isNotValid={errors.firstName}
          message={t('check-in:textRequiredFirstname')}
        >
          <KioskInput
            autoFocus
            placeholder={t('profile:textFirst')}
            label={t('profile:textName')}
            value={firstName}
            labelStyle={styles.labelStyle}
            style={{ width: 267 }}
            inputStyle={{ height: 54 }}
            onChangeText={(v) => {
              setFirstName(v);
              setErrors({ ...errors, firstName: false });
            }}
            isValid={!errors.firstName}
            onBlur={() =>
              setErrors({
                ...errors,
                firstName: firstName ? false : true,
              })
            }
          />
        </KitInputValidationMessage>

        {/* Last Name */}
        <KitInputValidationMessage
          isNotValid={errors.lastName}
          message={t('check-in:textRequiredLastname')}
        >
          <KioskInput
            placeholder={t('profile:textLast')}
            value={lastName}
            style={{ width: 267 }}
            inputStyle={{ height: 54 }}
            isValid={!errors.lastName}
            onChangeText={(v) => {
              setLastName(v);
              setErrors({ ...errors, lastName: false });
            }}
            onBlur={() =>
              setErrors({
                ...errors,
                lastName: lastName ? false : true,
              })
            }
          />
        </KitInputValidationMessage>
      </View>

      {/* Gender */}
      <View style={{ zIndex: -1, marginBottom: Spacing.l }}>
        <KitText
          bold
          black
          fontSize={16}
          lineHeight={18}
          style={{ marginBottom: Spacing.s }}
        >
          {t('profile:textGender')}
        </KitText>
        <KitOptionSelector
          options={genderOptions}
          selectedOption={genderOptions.findIndex(
            (option) => option.id === gender
          )}
          onSelectOption={(index) => setGender(genderOptions[index].id)}
          optionWidth={267}
          optionHeight={54}
          dynamicBoldStyle
          row
          fontSize={18}
        />
      </View>

      {/* Birthday */}
      <Show show={!isGuestCreate && !isGuestEdit}>
        <View style={{ zIndex: -2, marginBottom: Spacing.l }}>
          <KitInputValidationMessage
            isNotValid={errors.birthDate}
            message={getBirthdayValidationMessage}
          >
            <KitText
              bold
              black
              fontSize={16}
              lineHeight={18}
              style={{ marginBottom: Spacing.s }}
            >
              {t('profile:textBirthdate')}
            </KitText>
            <KitInputMask
              isValid={isValidBirthday}
              keyboardType='numeric'
              onBlur={() => {
                const isValid = checkBirthdayValidation();
                setErrors({
                  ...errors,
                  birthDate: Boolean(birthDate) && isValid ? false : true,
                });
              }}
              onChangeText={(masked) => {
                setBirthDate(masked);
                setErrors({ ...errors, birthDate: false });
              }}
              placeholder={t('common:placeholderDate')}
              style={{ height: 54 }}
              value={birthDate}
            />
          </KitInputValidationMessage>
        </View>
      </Show>

      {/* Grade (optional) */}
      <Show show={!isGuestCreate}>
        <View
          style={{
            zIndex: -3,
            marginBottom: Spacing.l,
          }}
        >
          <KitSelect
            label={t('profile:textGradeOptional')}
            labelStyle={styles.labelStyle}
            items={GRADES}
            onValueChange={(value: any) => setAcademicGrade(value.value)}
            placeholder={t('common:selectOne')}
            placeholderTextColor={Colors.N500}
            isValid={!errors.academicGrade}
            value={academicGrade}
            large
          />
        </View>
      </Show>

      {/* Session */}
      <Show show={Boolean(isGuestCreate && showSession)}>
        <View style={{ zIndex: -2 }}>
          <KitText color={Colors.N900} fontSize={16} bold lineHeight={18}>
            {t('check-in:textSession')}
          </KitText>
          <KitListItem
            title={session ? session : t('check-in:textChooseASession')}
            titleFontSize={18}
            rightIcon='edit'
            onPress={() => {
              setGuestProfileTemp?.({
                ...profile,
                first_name: firstName,
                last_name: lastName,
                gender: gender,
                allergy_notes: allergyNotes,
                care_notes: careNotes,
              });
              onEditSession?.();
            }}
            iconColor={Colors.N300}
            iconSize={20}
            minHeight={74}
            style={{ marginBottom: 16 }}
          />
        </View>
      </Show>

      {/* Allergies (optional) */}
      <Show show={Boolean(!profile) || Boolean(isGuestCreate)}>
        <AllergyNotesInput
          value={allergyNotes}
          labelStyle={styles.labelStyle}
          onChangeText={setAllergyNotes}
        />
      </Show>

      {/* Care Notes (optional) */}
      <Show show={Boolean(!profile) || Boolean(isGuestCreate)}>
        <CareNotesInput
          value={careNotes}
          labelStyle={styles.labelStyle}
          onChangeText={setCareNotes}
        />
      </Show>
    </KioskModal>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: Spacing.l,
    width: 552,
  },
  labelStyle: {
    color: Colors.N900,
    fontWeight: 'bold',
    fontSize: 16,
  },
  footerContainer: {
    paddingHorizontal: Spacing.xl,
    paddingVertical: Spacing.xl,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: Colors.N0,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
  },
});
