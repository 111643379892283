import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
// Adapted from https://www.npmjs.com/package/react-native-picker-select
import { Picker, PickerProps } from '@react-native-picker/picker';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Keyboard,
  Modal,
  ModalProps,
  Platform,
  ScrollView,
  Text,
  TextInputProps,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { TextStyle } from 'react-native';

import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import KitText from '../KitText';
import CustomPicker from './CustomPicker';
import Icon from './Icon';
import Trigger from './Trigger';
import { defaultStyles } from './styles';

export type ItemValue = number | string;
export interface SelectOption {
  label: string;
  value: ItemValue;
  disabled?: boolean;
}

type AnimationType = 'fade' | 'none' | 'slide' | undefined;
interface SelectStyles {
  done?: ViewStyle;
  doneDepressed?: ViewStyle;
  headlessAndroidPicker?: ViewStyle;
  invalid?: ViewStyle;
  modalViewBottom?: ViewStyle;
  modalViewMiddle?: ViewStyle;
  modalViewTop?: ViewStyle;
  placeholder?: ViewStyle;
  viewContainer?: ViewStyle;
}

export interface KitSelectProps {
  dividerIndex?: number;
  isValid?: boolean;
  items: SelectOption[];
  label?: string;
  labelStyle?: TextStyle;
  large?: boolean;
  onOpen?: () => void;
  onValueChange: (value: SelectOption, index: string | number) => void;
  pickerProps?: PickerProps;
  placeholder?: string;
  placeholderTextColor?: string;
  rightElement?: JSX.Element;
  style?: SelectStyles;
  textInputProps?: TextInputProps;
  value?: string | number;
  testID?: string;

  // Custom Modal props (iOS only)
  doneText?: string;
  modalProps?: ModalProps;
  onClose?: () => void;
  onDonePress?: () => void;
  onBlur?: () => void;
}

const dividerWidthInCharacters = 30;

const KitSelect: React.FC<KitSelectProps> = ({
  dividerIndex,
  doneText = 'Done',
  isValid = true,
  items = [],
  label = '',
  labelStyle = {},
  large = false,
  modalProps = {},
  onClose,
  onDonePress,
  onOpen,
  onValueChange,
  pickerProps = {},
  placeholder = '',
  placeholderTextColor = Colors.N900,
  rightElement,
  style,
  testID = '',
  textInputProps = {},
  value = '',
  onBlur,
}): JSX.Element | null => {
  //****************************************************************************
  // State
  //****************************************************************************

  const [animationType, setAnimationType] = useState<AnimationType>();
  const [doneDepressed, setDoneDePressed] = useState(false);
  const [orientation, _setOrientation] = useState('portrait');
  const [selectedItem, setSelectedItem] = useState<SelectOption | null>(null);
  const [showPicker, setShowPicker] = useState(false);
  const { windowHeight, windowWidth } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  const allItems = useMemo(
    () =>
      placeholder
        ? [{ label: placeholder, value: '', disabled: true }, ...items]
        : items,
    [items, placeholder]
  );

  const valueLabel = useMemo(() => {
    return allItems?.find((item) => value && item.value === value)?.label;
  }, [allItems, value]);

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  useEffect(() => {
    setSelectedItem(
      allItems?.find((item) => value && item.value === value) || null
    );
  }, [allItems, items, value]);

  //****************************************************************************
  // Methods
  //****************************************************************************

  const _onValueChange = (value: ItemValue, index: number) => {
    const item = allItems.find((item) => item.value === value);

    // On iOS, wait for the done button to be pressed before calling onValueChange
    if (item && Platform.OS !== 'ios') onValueChange(item, index);

    if (value !== selectedItem?.value) setSelectedItem(item || null);
  };

  const triggerOpenCloseCallbacks = () => {
    if (!showPicker && onOpen) onOpen();

    if (showPicker && onClose) onClose();
  };

  const togglePicker = (animate = false, postToggleCallback?: () => void) => {
    if (!showPicker) Keyboard.dismiss();

    if (showPicker) {
      onBlur?.();
    }

    const animationType =
      modalProps && modalProps.animationType
        ? modalProps.animationType
        : 'slide';

    triggerOpenCloseCallbacks();

    if (postToggleCallback) {
      postToggleCallback();
    } else {
      setShowPicker(!showPicker);
      setAnimationType(animate ? animationType : undefined);
    }
  };

  const renderPickerItems = (): JSX.Element[] => {
    return allItems.map((item: SelectOption, index: number) => {
      if (Platform.OS === 'web') {
        return (
          <React.Fragment key={item.value || item.label}>
            {dividerIndex && dividerIndex + (placeholder ? 1 : 0) === index ? (
              <option disabled>{'-'.repeat(dividerWidthInCharacters)}</option>
            ) : null}
            <Picker.Item label={item.label} value={item.value} />
          </React.Fragment>
        );
      }

      // Do not attempt to wrap Picker.Item in a fragment on iOS/Android
      // to avoid an issue where items fail to render
      return (
        <Picker.Item
          label={item.label}
          value={item.value}
          key={item.value || item.label}
          enabled={!item.disabled}
        />
      );
    });
  };
  const renderInputAccessoryView = () => (
    <View
      style={[defaultStyles.modalViewMiddle, style?.modalViewMiddle]}
      testID='input_accessory_view'
    >
      <TouchableOpacity
        hitSlop={{ top: 4, right: 4, bottom: 4, left: 4 }}
        onPress={() => {
          const index = allItems.indexOf(selectedItem as SelectOption);

          if (index > -1) onValueChange(allItems[index], index);

          togglePicker(true);
          if (onDonePress) onDonePress();
        }}
        onPressIn={() => {
          setDoneDePressed(true);
        }}
        onPressOut={() => {
          setDoneDePressed(false);
        }}
        testID='done_button'
      >
        <View testID='needed_for_touchable'>
          <Text
            allowFontScaling={false}
            style={[
              defaultStyles.done,
              style?.done,
              doneDepressed
                ? [defaultStyles.doneDepressed, style?.doneDepressed]
                : {},
            ]}
            testID='done_text'
          >
            {doneText}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );

  const renderIOS = () => (
    <View>
      {Boolean(label) && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <KitText
            color={Colors.N500}
            fontSize={14}
            semiBold
            style={{ lineHeight: 18, marginBottom: Spacing.s, ...labelStyle }}
          >
            {label}
          </KitText>
        </View>
      )}
      <Trigger
        active={showPicker}
        isValid={isValid}
        large={large}
        onPress={() => togglePicker(true)}
        style={style?.viewContainer}
        testID={testID}
        textInputProps={textInputProps}
        value={valueLabel}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        rightElement={rightElement}
      />
      <Modal
        {...modalProps}
        animationType={animationType}
        testID='ios_modal'
        transparent
        visible={showPicker}
      >
        <TouchableOpacity
          onPress={() => {
            togglePicker(true);
          }}
          style={[defaultStyles.modalViewTop, style?.modalViewTop]}
          testID='ios_modal_top'
        />

        {renderInputAccessoryView()}
        <View
          style={[
            defaultStyles.modalViewBottom,
            { height: orientation === 'portrait' ? 215 : 162 },
            style?.modalViewBottom,
          ]}
        >
          <Picker
            {...pickerProps}
            onValueChange={_onValueChange}
            selectedValue={selectedItem?.value}
            testID='ios_picker'
          >
            {renderPickerItems()}
          </Picker>
        </View>
      </Modal>
    </View>
  );

  const renderAndroid = () => (
    <View>
      {Boolean(label) && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <KitText
            color={Colors.N500}
            fontSize={14}
            semiBold
            style={{ lineHeight: 18, marginBottom: Spacing.m }}
          >
            {label}
          </KitText>
        </View>
      )}
      <View
        style={[
          defaultStyles.viewContainer,
          style?.viewContainer,
          large ? defaultStyles.large : undefined,
          showPicker ? defaultStyles.active : undefined,
          isValid ? undefined : defaultStyles.invalid,
        ]}
      >
        <Picker
          {...pickerProps}
          dropdownIconColor={Colors.N0}
          dropdownIconRippleColor={Colors.N0}
          onBlur={() => setShowPicker(false)}
          onFocus={() => setShowPicker(true)}
          onValueChange={_onValueChange}
          selectedValue={selectedItem?.value}
          style={[
            defaultStyles.viewContainer,
            style?.viewContainer,
            { backgroundColor: 'transparent' },
          ]}
          testID='android_picker'
        >
          {renderPickerItems()}
        </Picker>

        <Icon
          containerStyle={[defaultStyles.iconContainer, { top: 15, right: 18 }]}
        />
      </View>
    </View>
  );

  const renderWeb = () => (
    <View>
      {Boolean(label) && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <KitText
            color={Colors.N500}
            fontSize={14}
            semiBold
            style={[{ lineHeight: 18, marginBottom: Spacing.m }, labelStyle]}
          >
            {label}
          </KitText>
        </View>
      )}
      <View
        style={[
          defaultStyles.viewContainer,
          style?.viewContainer,
          large ? defaultStyles.large : undefined,
          isValid ? undefined : defaultStyles.invalid,
        ]}
      >
        <Picker
          {...pickerProps}
          onValueChange={_onValueChange}
          selectedValue={selectedItem?.value}
          style={[
            defaultStyles.inputWeb,
            large ? defaultStyles.inputWebLarge : undefined,
            { borderWidth: 0 },
          ]}
          testID='web_picker'
        >
          {renderPickerItems()}
        </Picker>
        <Icon />
      </View>
    </View>
  );

  return Platform.select({
    android: renderAndroid(),
    default: null,
    ios: renderIOS(),
    web: renderWeb(),
  });
};

export default KitSelect;
