import Environment from './../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.peopleService;
const RESOURCE = 'household-relationships';

interface IDeleteHouseholdRelationship extends IUri {
  token?: string;
  id: string;
}

export default async function ({
  token,
  id,
}: IDeleteHouseholdRelationship): Promise<IHttpResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    id: id,
  });

  return (await BaseServiceV2.Delete({
    url,
    token,
  })) as IHttpResponse;
}
