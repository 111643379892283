import AsyncStorage from '@react-native-async-storage/async-storage';

const debug = require('debug')('tca:kit:storage:index.ts');

export const getStoredItem = async (key: string): Promise<string | null> => {
  let value: string | null = null;
  try {
    value = await AsyncStorage.getItem(key);
  } catch (error) {
    debug(error);
  }

  return value;
};

export const setStoredItem = async (
  key: string,
  value: string
): Promise<void> => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (error) {
    debug(error);
  }
};

export const removeStoredItem = async (key: string): Promise<void> => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {
    debug(error);
  }
};

export default {
  getStoredItem,
  setStoredItem,
  removeStoredItem,
};
