import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IHousehold } from '../Types';

const HOST = Environment.peopleService;
const RESOURCE = 'households';

interface IGetHousehold extends IUri {
  token?: string;
  org_key?: string;
}

interface IGetHouseholdResponse extends IHttpResponse {
  body?: IHousehold;
}

export default async function ({
  token,
  ...buildUrlProps
}: IGetHousehold): Promise<IGetHouseholdResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
  });
}
