import { ColorString } from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { DimensionValue, FlexAlignType } from 'react-native';
import { EdgeInsets } from 'react-native-safe-area-context';

import { IInsetStyles, ItemAlignment, RadiusStyle } from './types';

export const getFlexForAlignment = (
  alignment: ItemAlignment
): 'flex-start' | 'flex-end' | 'center' => {
  let flex: FlexAlignType = 'flex-start'; // default

  switch (alignment) {
    case ItemAlignment.End:
      flex = 'flex-end';
      break;
    case ItemAlignment.Center:
      flex = 'center';
      break;
  }

  return flex;
};

export const getRadiusForStyle = (
  style: RadiusStyle,
  width: DimensionValue,
  height: number
): number => {
  let radius = 0;

  switch (style) {
    case RadiusStyle.Small:
      radius = 4;
      break;
    case RadiusStyle.Medium:
      radius = 8;
      break;
    case RadiusStyle.Large:
      radius = 16;
      break;
    case RadiusStyle.ExtraLarge:
      radius = 32;
      break;
    case RadiusStyle.Circle: {
      if (typeof width === 'number') {
        radius = Math.min(width, height) / 2;
      }

      break;
    }
  }

  return radius;
};

export const getStyleForInsets = (insets: EdgeInsets): IInsetStyles => {
  const horizontalPadding = {
    paddingLeft: insets.left,
    paddingRight: insets.right,
  };

  return {
    top: { paddingTop: insets.top, ...horizontalPadding },
    mid: horizontalPadding,
    bottom: { paddingBottom: insets.bottom + Spacing.xl, ...horizontalPadding },
  };
};

/*
 * Darkens color by 20%
 */
export const generateTintColor = (color: string): ColorString => {
  // Remove # if it's there
  color = color.replace('#', '');

  // Parse hexadecimal color to RGB
  let r: number = parseInt(color.substring(0, 2), 16);
  let g: number = parseInt(color.substring(2, 4), 16);
  let b: number = parseInt(color.substring(4, 6), 16);

  if (isLowContrastColor({ r, g, b })) {
    // Darken each RGB component by 40%
    r = Math.round(r * 0.6);
    g = Math.round(g * 0.6);
    b = Math.round(b * 0.6);
  }

  // Convert RGB to hexadecimal
  return ('#' +
    // eslint-disable-next-line no-bitwise
    ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')) as ColorString;
};

export const isLowContrastColor = ({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}): boolean => {
  const brightness = r * 0.229 + g * 0.587 + b * 0.114,
    colorDiff = r + g + b;

  return brightness > 93 || colorDiff > 370;
};
