import { useShellContext } from '@omni/kit';
import { BridgeAction } from '@omni/kit/Types';
import { getFavorites } from '@omni/kit/services/FeedsService';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useRootAppContext } from './RootAppContext';

export const FavoriteAppsContext = createContext<FavoritesSetup>({
  favoriteApps: [],
  reloadFavorites: () => null,
});

type ProviderProps = {
  children?: React.ReactNode;
};

export interface IFavoriteApp {
  appkey: string;
  image?: {
    url?: string;
  };
  is_home: boolean;
  is_media_search_enabled?: boolean;
  sapid?: string;
  title?: string;
  action?: BridgeAction;
}

export type FavoritesSetup = {
  favoriteApps: IFavoriteApp[];
  reloadFavorites: () => void;
};

export const FavoriteAppsProvider = ({
  children,
}: ProviderProps): null | JSX.Element => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [reloadIndex, setReloadIndex] = useState<number>(0);
  const [favoriteApps, setFavoriteApps] = useState<IFavoriteApp[]>([]);
  const [getFromCache, setGetFromCache] = useState<boolean>(true);

  const { tokens } = useShellContext();
  const accessToken = useMemo(() => tokens.user, [tokens.user]);

  const rootApp = useRootAppContext();
  const feedsServiceEnabled = Boolean(rootApp?.is_container);

  const userToken = accessToken?.replace('Bearer ', '');

  const reloadFavorites = React.useCallback(() => {
    setGetFromCache(false);
    setReloadIndex(reloadIndex + 1);
  }, [reloadIndex]);

  useEffect(() => {
    const setup = async () => {
      try {
        if (feedsServiceEnabled) {
          const data = await getFavorites({
            token: userToken,
            filters: [['type', 'app']],
            getFromCache: getFromCache,
          });

          // Flatten props from favorites feeds spec for simplicity
          // in consumers of the favorite app context
          const apps =
            data.body?.items?.map((item) => {
              const favoriteApp: IFavoriteApp = {
                sapid: item.sapid,
                title: item.title,
                appkey: item.actions?.[0]?.appkey ?? '',
                is_home: item.actions?.[0]?.is_home ?? false,
                is_media_search_enabled:
                  item.actions?.[0]?.is_media_search_enabled ?? false,
                image: item.images?.[0],
                action: item.actions?.[0],
              };

              return favoriteApp;
            }) || [];

          setFavoriteApps(apps);
        }

        setIsReady(true);
        setGetFromCache(true);
      } catch (e) {
        console.warn(e);
        setIsReady(true);
      }
    };
    setup();
  }, [feedsServiceEnabled, userToken, reloadIndex]);

  const FavoriteAppsContextValue = React.useMemo(() => {
    return {
      favoriteApps,
      reloadFavorites,
    };
  }, [favoriteApps, reloadFavorites]);

  if (!isReady) {
    return null;
  }

  return (
    <FavoriteAppsContext.Provider value={FavoriteAppsContextValue}>
      {children}
    </FavoriteAppsContext.Provider>
  );
};

export const useFavoriteAppsContext = (): FavoritesSetup => {
  return useContext<FavoritesSetup>(FavoriteAppsContext);
};
