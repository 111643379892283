import React, { useRef, useState } from 'react';
import {
  Platform,
  Image as RNImage,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import ImagePicker, {
  Image as ImagePickerImage,
  Options as ImagePickerOptions,
} from 'react-native-image-crop-picker';

import { PlatformAgnosticFile } from '../../FileTypes';
import useImageUpload from '../../hooks/useImageUpload';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import {
  ImageServiceType,
  bytesPerMb,
  fileTooLargeMessage,
  maxFileSizeInMb,
  parseImageUrl,
} from '../../utilities/utilities';
import KitActionSheetItem from '../KitActionSheetItem';
import KitImage from '../KitImage';
import { KitSnackDuration } from '../KitSnack';
import Show from '../Show';
import {
  KitIcon,
  KitLoader,
  KitModal,
  KitSnack,
  KitText,
  KitTouchable,
} from '../index';
import {
  nativeImageToAgnosticFile,
  webImageToAgnosticFile,
} from './imageUtilities';

const debug = require('debug')('tca:component:PhotoUpload');

export interface PhotoUploadProps {
  appKey: string;
  endUserId: string;
  nickname: string;
  imageUrl?: string;
  onPhotoChange?: any;
}

export default function PhotoUpload({
  appKey,
  endUserId,
  nickname = '',
  imageUrl = '',
  onPhotoChange,
}: PhotoUploadProps): JSX.Element {
  const { uploadImage } = useImageUpload(appKey, endUserId);
  const firstNameChar = nickname.charAt(0).toUpperCase();
  const lastNameChar = nickname.split(' ')[1]?.charAt(0).toUpperCase() || '';

  const [modalVisible, setModalVisible] = useState(false);
  const [profileImage, setProfileImage] = useState<string | undefined>(
    imageUrl
  );
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const _renderInitials = () => {
    return (
      <KitText
        fontSize={36}
        white
        center
        style={styles.initialText}
        testID='Initials'
      >
        {String(firstNameChar) + lastNameChar}
      </KitText>
    );
  };

  const _openWebFilePicker = () => {
    fileInputRef.current?.click();
  };

  const _uploadPhoto = (selectedFile: PlatformAgnosticFile) => {
    try {
      setUploading(true);
      setModalVisible(false);
      uploadImage(selectedFile, 'profile')
        .then(({ id, url }) => {
          setUploading(false);
          const imageUrl = parseImageUrl(
            url,
            100,
            100,
            ImageServiceType.ImageJpeg
          );
          setProfileImage(imageUrl);
          onPhotoChange && onPhotoChange({ id, url: imageUrl });
        })
        .catch(() => {
          debug('Error Uploading Photo');
          setUploading(false);
          KitSnack.show(
            'Unable to upload photo. Please try again.',
            KitSnackDuration.SHORT
          );
        });
    } catch (e) {
      debug('Error Uploading Photo');
      setUploading(false);
    }
  };

  const _onChangePhotoPress = async (
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    let selectedFile: PlatformAgnosticFile | null = null;

    if (Platform.OS === 'web' && event) {
      const imageObject = event.target.files?.[0];

      if (!imageObject) {
        return;
      }

      selectedFile = webImageToAgnosticFile(imageObject);
    } else {
      const options: ImagePickerOptions = {
        cropping: true,
        cropperCircleOverlay: true,
        // workaround photo orientation issue on Android by slightly lowering compressImageQuality
        // ref: https://github.com/ivpusic/react-native-image-crop-picker/issues/379#issuecomment-713690758
        ...Platform.select({ android: { compressImageQuality: 0.99 } }),
      };

      let imageObject: ImagePickerImage | undefined;
      try {
        imageObject = (await ImagePicker.openPicker(
          options
        )) as ImagePickerImage;
      } catch {}

      if (!imageObject) {
        return;
      }

      selectedFile = nativeImageToAgnosticFile(imageObject);
    }

    _uploadPhoto(selectedFile);
  };

  const _onCameraPress = async () => {
    let selectedFile: PlatformAgnosticFile | null = null;
    const options: ImagePickerOptions = {
      width: 1080,
      height: 1080,
      cropping: true,
    };
    const imageObject = (await ImagePicker.openCamera(
      options
    )) as ImagePickerImage;

    if (!imageObject) {
      return;
    }

    selectedFile = nativeImageToAgnosticFile(imageObject);
    _uploadPhoto(selectedFile);
  };

  const _onFilePress = async () => {
    try {
      const fileObject = await DocumentPicker.pickSingle({
        type: [DocumentPicker.types.images],
      });
      const fileSize = (fileObject.size ?? 0) / bytesPerMb; // filesize in megabytes

      if (fileSize > maxFileSizeInMb) {
        KitSnack.show(fileTooLargeMessage, KitSnackDuration.SHORT);

        return;
      }

      if (fileObject.type?.startsWith('image/')) {
        try {
          await RNImage.getSize(fileObject.uri, (width, height) => {
            if (width !== 0 && height !== 0) {
              const file: PlatformAgnosticFile = {
                uri: fileObject.uri,
                fileName: fileObject.name ? fileObject.name : 'file',
                contentType: fileObject.type ? fileObject.type : 'unknown',
                size: fileObject.size ?? 0,
                _original: {
                  // use default value because _original is only used when uploading from web
                  lastModified: 0,
                  name: '',
                } as File,
              };
              _uploadPhoto(file);
            }
          });
        } catch (error) {
          debug(error);
        }
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <View style={styles.container} testID='KitEdiableAvatar'>
        <View>
          <KitTouchable
            underlayColor={profileImage ? Colors.N1000 : Colors.N300}
            onPress={
              Platform.OS === 'web'
                ? _openWebFilePicker
                : () => setModalVisible(true)
            }
            style={styles.avatarContainer}
          >
            <View style={{ flex: 1 }}>
              <>
                <Show show={Boolean(!uploading)}>
                  <>
                    {imageUrl ? (
                      <KitImage
                        // @ts-ignore
                        style={styles.avatarImage}
                        source={{
                          uri: parseImageUrl(
                            imageUrl,
                            100,
                            100,
                            ImageServiceType.ImageJpeg
                          ),
                        }}
                      />
                    ) : (
                      <View
                        style={{
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {_renderInitials()}
                      </View>
                    )}
                  </>
                </Show>
                <Show show={Boolean(uploading)}>
                  <View
                    style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {uploading && <KitLoader white />}
                  </View>
                </Show>
              </>
            </View>
          </KitTouchable>
          {/* This does the same as the above KitTouchable, but needs to be outside the "overflow: hidden" property
        so the press state has the correct border radius. */}
          {Platform.OS !== 'web' ? (
            <TouchableWithoutFeedback onPress={() => setModalVisible(true)}>
              <View style={styles.photoIndicator}>
                <KitIcon name='camera' size={13} color={Colors.N900} />
              </View>
            </TouchableWithoutFeedback>
          ) : (
            // @ts-ignore
            <label style={photoIndicatorStyles}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  borderWidth: 3,
                  borderColor: Colors.N0,
                  borderRadius: 15,
                }}
              >
                <KitIcon name='camera' size={14} color={Colors.N900} />
              </View>
              <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                type='file'
                onChange={
                  Platform.OS === 'web'
                    ? _onChangePhotoPress
                    : () => setModalVisible(true)
                }
                accept='image/*'
              />
            </label>
          )}
        </View>
      </View>
      {Platform.OS !== 'web' && (
        <KitModal
          visible={modalVisible}
          setVisible={setModalVisible}
          testID='SelectImageModal'
        >
          <View style={styles.modalContainer}>
            <KitActionSheetItem
              icon='image-library'
              label='Photo Library'
              onPress={_onChangePhotoPress}
            />
            <KitActionSheetItem
              icon='camera'
              label='Take a photo'
              onPress={_onCameraPress}
            />
            {Platform.OS === 'ios' && (
              <KitActionSheetItem
                icon='action-h'
                label='Browse'
                onPress={_onFilePress}
              />
            )}
          </View>
        </KitModal>
      )}
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const photoIndicatorStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  width: 30,
  height: 30,
  borderRadius: 15,
  bottom: 0,
  right: -3,
  backgroundColor: Colors.N100,
  borderWidth: 3,
  borderColor: Colors.N0,
  ...Platform.select({ web: { cursor: 'pointer' } }),
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: Spacing.s,
  },
  modalContainer: {
    marginHorizontal: Spacing.l,
    marginTop: Spacing.m,
    paddingBottom: Spacing.xl,
  },
  avatarContainer: {
    width: 104,
    height: 104,
    borderRadius: 50,
    overflow: 'hidden',
    backgroundColor: Colors.N200,
  },
  avatarImage: {
    position: 'absolute',
    width: 104,
    height: 104,
    borderRadius: 50,
    zIndex: 2,
  },
  // @ts-ignore
  photoIndicator: {
    ...photoIndicatorStyles,
  },
  initialText: {
    zIndex: 1,
  },
});
