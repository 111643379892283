import React, { useCallback, useContext, useState } from 'react';
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  Platform,
  View,
} from 'react-native';
import HTML from 'react-native-render-html';

import Colors from '../../theming/Colors';
import { ThemeContext } from '../../theming/ThemeContext';
import { openLink } from '../../utilities/links';
import { TruncateButton } from '../KitText';

const debug = require('debug')('omni:packages:kit:components:KitHtml');

/**
 * Documentation:
 * https://subsplash.atlassian.net/wiki/spaces/CS/pages/2064220246/KitHtml
 */

interface IProps {
  content: string;
  expandable?: boolean;
  fontSize?: number;
  numberOfLines?: number;
  lineHeight?: number;
  textColor?: string;
  urlColor?: string;
  fadeColor?: string;
}

export default ({
  content,
  expandable,
  fontSize = 16,
  numberOfLines = 3,
  lineHeight,
  textColor,
  urlColor,
  fadeColor,
}: IProps): JSX.Element => {
  const { colorForScheme } = useContext(ThemeContext);

  const [showTruncateBtn, setShowTruncateBtn] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [viewHeight, setViewHeight] = useState<number>(0);

  const calculatedLineHeight = lineHeight || fontSize * 1.5;
  const defaultTextColor =
    textColor || colorForScheme?.({ light: Colors.N500, dark: Colors.N400 });
  const defaultUrlColor =
    urlColor || colorForScheme?.({ dark: Colors.N100, light: Colors.N900 });

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      debug('onLayout', event.nativeEvent.layout);
      const { height } = event.nativeEvent.layout;

      if (viewHeight > 0) return;

      if (expandable) {
        const collapsedHeight = calculatedLineHeight * numberOfLines;
        setViewHeight(height >= collapsedHeight ? collapsedHeight : height);
        setShowTruncateBtn(Math.ceil(height) >= collapsedHeight);
      }
    },
    [calculatedLineHeight, expandable, numberOfLines, viewHeight]
  );

  const onLinkPress = (_event: GestureResponderEvent, href: string) => {
    openLink(href);
  };

  const htmlTagStyles = React.useMemo(
    () => ({
      body: {
        color: defaultTextColor || Colors.N500,
        fontSize: fontSize,
        lineHeight: calculatedLineHeight,
      },
      p: {
        color: defaultTextColor || Colors.N500,
        fontSize: fontSize,
        lineHeight: calculatedLineHeight,
        margin: 0,
        padding: 0,
        ...(Platform.OS === 'web'
          ? { fontFamily: 'inherit' }
          : { minHeight: calculatedLineHeight }),
      },
      a: {
        color: defaultUrlColor,
        fontSize: fontSize,
        lineHeight: calculatedLineHeight,
        textDecorationColor: defaultUrlColor,
        textDecorationLine: 'underline',
      },
    }),
    [fontSize, calculatedLineHeight, defaultTextColor, defaultUrlColor]
  );

  /**
   * This is a workaround for line breaks rendering with too much vertical space in native
   */
  const preprocessHtmlContent = (html: string) => {
    return html.replace(/<p><br><\/p>/g, '<p>&nbsp;</p>');
  };

  return (
    <View
      onLayout={onLayout}
      style={{
        position: 'relative',
        height: isExpanded || viewHeight === 0 ? 'auto' : viewHeight,
        overflow: 'hidden',
      }}
    >
      <HTML
        allowFontScaling={false}
        onLinkPress={onLinkPress}
        html={Platform.OS === 'web' ? content : preprocessHtmlContent(content)}
        tagsStyles={htmlTagStyles}
      />
      {expandable && (
        <TruncateButton
          isExpanded={isExpanded}
          onPress={() => setIsExpanded(!isExpanded)}
          show={showTruncateBtn}
          fadeColor={fadeColor}
        />
      )}
    </View>
  );
};
