import { IListItemData } from '@omni/kit/feeds/listItemTypes';
import SearchService, { TAG_TYPES } from '@omni/kit/services/SearchService';

import { IBrowseOption } from '../screens/Search';

const BROWSE_OPTIONS: IListItemData[] = [
  {
    type: TAG_TYPES.TOPIC,
    title: TAG_TYPES.TOPIC,
    icon: 'thread',
  },
  {
    type: TAG_TYPES.SPEAKER,
    title: TAG_TYPES.SPEAKER,
    icon: 'microphone',
  },
  {
    type: TAG_TYPES.SCRIPTURE,
    title: TAG_TYPES.SCRIPTURE,
    icon: 'bible',
  },
];

export async function getBrowseOptions(
  appFilterValue: string,
  context: string
): Promise<IBrowseOption[]> {
  const updatedBrowseOptions: IBrowseOption[] = [];

  for (const option of BROWSE_OPTIONS) {
    const title = option.title;
    const response = await SearchService.facets({
      appFilterValue,
      tagType: title as string,
      getFromCache: context === 'cache',
    });
    const hasTotal = (response?.body?.total || 0) > 0;

    if (hasTotal) {
      const icon = option.icon;
      updatedBrowseOptions.push({
        type: title as string,
        title: title as string,
        icon,
      });
    }
  }

  return updatedBrowseOptions;
}
