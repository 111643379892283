import { formatPhoneNumber } from '@omni/blocks';
import { KitIcon, KitLink, KitText } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import Show from '@omni/kit/components/Show';
import { IEndUser } from '@omni/kit/services/ChatService';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { createImageUrl } from '@omni/kit/utilities/utilities';
import React from 'react';
import { Linking, Platform, View } from 'react-native';

export const UserContactSheet = ({
  endUser,
}: {
  endUser?: IEndUser;
}): JSX.Element | null => {
  const profile = endUser?._embedded?.profiles[0];

  if (!profile) {
    return null;
  }

  const fullName = profile.first_name + ' ' + profile.last_name;

  return (
    <View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <KitAvatar
          size={64}
          nickname={fullName}
          imageUrl={createImageUrl(profile?._embedded?.photo?.id)}
        />
        <KitText
          extraBold
          fontSize={22}
          black
          style={{
            marginLeft: Spacing.l,
            flex: 1,
          }}
        >
          {fullName}
        </KitText>
      </View>
      <View
        style={{
          flexDirection: 'row',
          marginTop: Spacing.xl,
          alignItems: 'center',
          width: '100%',
        }}
      >
        <KitIcon name='email' size={20} color={Colors.N600} />
        <KitLink
          black
          fontSize={16}
          style={{ marginLeft: Spacing.l, flexShrink: 1 }}
          onPress={
            Platform.OS !== 'web'
              ? () => Linking.openURL(`mailto:${profile.email}`)
              : undefined
          }
          underline='none'
        >
          {profile.email}
        </KitLink>
      </View>
      <Show show={profile.phone !== undefined}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            marginTop: Spacing.l,
          }}
        >
          <KitIcon name='phone' size={20} color={Colors.N600} />
          <KitLink
            black
            fontSize={16}
            style={{ marginLeft: Spacing.l, flexShrink: 1 }}
            onPress={
              Platform.OS !== 'web'
                ? () => Linking.openURL(`sms:+1${profile.phone}`)
                : undefined
            }
            underline='none'
          >
            {profile.phone ? formatPhoneNumber(profile.phone) : undefined}
          </KitLink>
        </View>
      </Show>
    </View>
  );
};
