import { AssignmentImage } from '@omni/schedule/components/AssignmentImage';
import Colors from '@omni/kit/theming/Colors';
import { IAssignment } from '@omni/kit/services/ScheduleService/Types';
import { KitListItem } from '@omni/kit/components';
import { KitText } from '@omni/kit/components';
import { Pressable } from 'react-native';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';
import { StyleSheet } from 'react-native';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import { dispatch } from '@omni/kit/ActionHandler';
import { useTranslation } from 'react-i18next';

interface ScheduleListItemsProps {
  accessToken: string | null;
  assignments: IAssignment[];
}

export default function ScheduleListItems({
  accessToken,
  assignments,
}: ScheduleListItemsProps): JSX.Element {
  const { t } = useTranslation(['schedule']);

  return (
    <>
      {assignments?.map((assignment, index) => {
        const {
          id: eventId,
          _links: { image: { href: eventImage = '' } = {} } = {},
          title: eventTitle,
          start_at: eventStart,
          end_at: eventEnd,
        } = assignment?._embedded?.['fluro-event'] || {};

        return (
          <KitListItem
            key={`${eventId}-${index}`}
            ImageComponent={
              <AssignmentImage
                accessToken={accessToken}
                redirectUrl={eventImage}
                date={eventStart}
              />
            }
            title={eventTitle}
            subtitle={dateFormatRange({
              startDate: eventStart,
              endDate: eventEnd,
            })}
            minHeight={78}
          />
        );
      })}
      <Pressable
        onPress={() => dispatch({ handler: 'schedule', theme: 'light' })}
        style={({ pressed }) => [
          styles.viewSchedule,
          {
            backgroundColor: pressed ? Colors.N50 : Colors.N0,
          },
        ]}
      >
        <KitText semiBold black>
          {t('schedule:viewSchedule')}
        </KitText>
      </Pressable>
    </>
  );
}

const styles = StyleSheet.create({
  viewSchedule: {
    paddingVertical: Spacing.l,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
