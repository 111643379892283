import type { AuthStackParamsList } from '@omni/kit/auth';
import { User as SendbirdUser } from '@sendbird/chat';
import {
  GroupChannel,
  Member as SendbirdMember,
} from '@sendbird/chat/groupChannel';
import { AdminMessage, FileMessage, UserMessage } from '@sendbird/chat/message';

import { MessageType } from './shared/redux/types';

export interface MessagingProps {
  accessToken?: string;
  appKey?: string;
  orgKey?: string;
  brandColor?: string;
  moduleCommand?: Record<string, unknown>;
  tokenUrl?: string;
}

export enum InviteType {
  Email = 'email',
  None = 'none',
  Phone = 'phone',
  User = 'user',
}

export interface CustomMessageAttributes {
  customType: MessageType;
  isUser: boolean;
  progress?: ProgressEvent;
  showTime: boolean;
}

export type SendbirdUserMessage = UserMessage & CustomMessageAttributes;
export type SendbirdFileMessage = FileMessage & CustomMessageAttributes;
export type SendbirdSenderMessage = (UserMessage | FileMessage) &
  CustomMessageAttributes;
export type SendbirdAdminMessage = AdminMessage & CustomMessageAttributes;
export type SendbirdMessage = (SendbirdSenderMessage | SendbirdAdminMessage) &
  CustomMessageAttributes;

export interface CustomMessageData {
  adminSenderName?: string;
  answeredId?: number;
  bannerImageUrl?: string;
  displayReference?: string;
  inChannel?: boolean;
  isAnswered?: boolean;
  options?: string[];
  osis?: string;
  ratio?: string;
  replyId?: number;
  sentToChannel?: boolean;
  uri?: string;
  versionId?: string;
}

export enum MemberRole {
  Member = 'member',
  Operator = 'operator',
}

export interface ChannelMember {
  appKey: string;
  id: string;
  isBlockedByMe: boolean;
  isOnline: boolean;
  nickname: string;
  profileUrl: string;
  role: MemberRole;
  userId: string;
  _embedded?: {
    'chat-user'?: {
      id: string;
      profile_url?: string;
    };
    channel?: GroupChannel;
  };
}

export interface IInvite {
  data: SendbirdMember | SendbirdUser;
  title: string;
  type: InviteType;
}

export interface IInvited {
  timestamp: number;
  title: string;
}

export interface IInviteStatus {
  success: boolean;
  title: string;
  type: InviteType;
}

export enum PushPreference {
  All = 'all',
  Default = 'default',
  MentionOnly = 'mention_only',
  Off = 'off',
}

export interface ChatModuleProps {
  accessToken?: string;
  appKey: string;
  brandColor?: string;
}

export type ChatStackParamList = AuthStackParamsList & {
  app_stack?: {
    appKey?: string;
  };
  chat_details_modal?: {
    channelId?: string;
  };
  chat_screen?: {
    channelId?: string;
  };
  not_found_screen: undefined;
};

export interface MessageUpdates {
  customType?: MessageType;
  data?: CustomMessageData;
  message?: string;
}
