import { KitSnack, KitText } from '@omni/kit/components';
import KitList from '@omni/kit/components/KitList';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import Show from '@omni/kit/components/Show';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import GroupsService from '@omni/kit/services/GroupsService';
import { IJoinRequest } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { User as SendbirdUser } from '@sendbird/chat';
import { removeJoinRequest } from '../../redux/actions/ChatActions';
import { accessTokenSelector, userSelector } from '../../redux/selectors';
import { ApproveConfirmation } from './components/ApprovalConfirmation';
import { DenyConfirmation } from './components/DenyConfirmation';
import JoinRequestItem from './components/JoinRequestItem';
import { UserContactSheet } from './components/UserContactSheet';
import useJoinRequests from './hooks/useJoinRequests';

export default (): JSX.Element => {
  const joinRequests = useJoinRequests();
  const { sizeClass } = useSizeClass();
  const accessToken = useSelector(accessTokenSelector);
  const currentUser: SendbirdUser | null = useSelector(userSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation(['messaging']);

  const [selectedRequest, setSelectedRequest] = useState<
    IJoinRequest | undefined
  >();
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isDenyModalVisible, setIsDenyModalVisible] = useState(false);
  const [isMoreActionsModalVisible, setIsMoreActionsModalVisible] =
    useState(false);

  const onApprovePress = (request: IJoinRequest) => {
    setSelectedRequest(request);
    setIsApproveModalVisible(true);
  };

  const onApproveConfirm = async () => {
    if (selectedRequest) {
      const firstname =
        selectedRequest._embedded['requesting-user']._embedded?.profiles[0]
          .first_name;
      try {
        const response = await GroupsService.UpdateJoinRequest({
          id: selectedRequest?.id,
          status: 'approved',
          token: accessToken,
        });

        if (response.status === 200) {
          KitSnack.show(
            `${firstname}'s request was approved`,
            KitSnackDuration.SHORT
          );
          dispatch(removeJoinRequest(selectedRequest));
        }
      } catch (e) {
        console.error(e);
        KitSnack.show('An error occurred', KitSnackDuration.SHORT);
      }
    }

    setSelectedRequest(undefined);
    setIsApproveModalVisible(false);
  };

  const onDenyPress = (request: IJoinRequest) => {
    setSelectedRequest(request);
    setIsDenyModalVisible(true);
  };

  const onDenyConfirm = async () => {
    if (selectedRequest) {
      const firstname =
        selectedRequest._embedded['requesting-user']._embedded?.profiles[0]
          .first_name;
      try {
        const response = await GroupsService.UpdateJoinRequest({
          id: selectedRequest?.id,
          status: 'denied',
          token: accessToken,
        });

        if (response.status === 200) {
          KitSnack.show(
            `${firstname}'s request was denied`,
            KitSnackDuration.SHORT
          );
          dispatch(removeJoinRequest(selectedRequest));
        }
      } catch (e) {
        console.error(e);
        KitSnack.show('An error occurred', KitSnackDuration.SHORT);
      }
    }

    setSelectedRequest(undefined);
    setIsDenyModalVisible(false);
  };

  const onMoreActions = (request: IJoinRequest) => {
    setSelectedRequest(request);
    setIsMoreActionsModalVisible(true);
  };

  return (
    <View
      style={{
        backgroundColor: Colors.N0,
        flex: 1,
      }}
    >
      <Show show={sizeClass !== SizeClass.Small}>
        <KitText
          black
          bold
          fontSize={16}
          style={{
            display: 'flex',
            alignItems: 'center',
            height: 78,
            paddingLeft: Spacing.xl,
            borderBottomColor: Colors.N100,
            borderBottomWidth: 1,
          }}
        >
          {t('messaging:pageTitleJoinRequests')}
        </KitText>
      </Show>
      {/*<Show show={joinRequests.length === 0} />*/}
      <KitList
        animationEnabled={false}
        data={joinRequests}
        renderItem={({ item }) => (
          <JoinRequestItem
            request={item}
            onApprove={onApprovePress}
            onDeny={onDenyPress}
            onMoreActions={onMoreActions}
          />
        )}
        style={{
          paddingHorizontal: Spacing.xl,
        }}
        contentContainerStyle={{
          ...(joinRequests.length === 0 && {
            flex: 1,
            marginBottom: Spacing.xxl,
          }),
        }}
        ListEmptyComponent={
          <View
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <KitText fontSize={36}>🎉</KitText>
            <KitText bold black style={{ marginTop: Spacing.s }}>
              {t('messaging:emptyMessageJoinRequestsTitle')}
            </KitText>
            <KitText gray fontSize={14} style={{ marginTop: Spacing.s }}>
              {t('messaging:emptyMessageJoinRequestsSubtitle')}
            </KitText>
          </View>
        }
      />
      <KitModalV2
        anchorBottom={sizeClass === SizeClass.Small}
        coverScreen={true}
        isVisible={
          isApproveModalVisible ||
          isDenyModalVisible ||
          isMoreActionsModalVisible
        }
        onClose={() => {
          setSelectedRequest(undefined);
          setIsApproveModalVisible(false);
          setIsDenyModalVisible(false);
          setIsMoreActionsModalVisible(false);
        }}
      >
        <Show show={isApproveModalVisible}>
          <ApproveConfirmation
            onCancel={() => setIsApproveModalVisible(false)}
            onConfirm={onApproveConfirm}
            request={selectedRequest}
            sizeClass={sizeClass}
          />
        </Show>
        <Show show={isDenyModalVisible}>
          <DenyConfirmation
            onCancel={() => setIsDenyModalVisible(false)}
            onConfirm={onDenyConfirm}
            request={selectedRequest}
            sizeClass={sizeClass}
          />
        </Show>
        <Show show={isMoreActionsModalVisible}>
          <UserContactSheet
            endUser={selectedRequest?._embedded['requesting-user']}
          />
        </Show>
      </KitModalV2>
    </View>
  );
};
