import { HEADER_FOOTER_HEIGHT, MIN_MODAL_WIDTH } from '@omni/kit/auth';
import React, { useContext, useState } from 'react';
import { View } from 'react-native';

import { KitTouchableIcon } from '../../components';
import KitModal from '../../components/KitModalV2';
import KitScripture from '../../components/KitScripture';
import BorderRadius from '../../theming/BorderRadius';
import Colors from '../../theming/Colors';
import { ThemeContext } from '../../theming/ThemeContext';
import { WindowSizeMin, useSizeClass } from '../SizeClassContext';

export default ({
  isVisible,
  onClose,
  osis,
  bibleVersion,
}: {
  bibleVersion: string;
  isVisible: boolean;
  onClose?: () => void;
  osis: string;
}): JSX.Element => {
  const { smallestWindowWidth } = useSizeClass();

  const { colorForScheme } = useContext(ThemeContext);

  const isSmall = smallestWindowWidth < WindowSizeMin.M;

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <KitModal
      onClose={onClose}
      isVisible={isVisible}
      anchorBottom={isSmall}
      coverScreen={true}
      enableScroll={true}
      modalStyle={{
        maxHeight: isSmall ? '95%' : '80%',
        backgroundColor:
          colorForScheme?.({
            dark: Colors.N1000,
            light: Colors.N0,
          }) || Colors.N0,
      }}
      onModalHide={() => setIsModalVisible(false)}
      onModalShow={() => setIsModalVisible(true)}
    >
      <View
        style={[
          {
            width: '100%',
            height: '100%',
          },
          !isSmall && {
            borderRadius: BorderRadius.l,
            width: MIN_MODAL_WIDTH,
          },
        ]}
      >
        <KitTouchableIcon
          color={
            colorForScheme?.({
              light: Colors.N900,
              dark: Colors.N0,
            }) || Colors.N900
          }
          name={isSmall ? 'chevron-down' : 'remove'}
          onPress={onClose}
          style={{
            alignSelf: isSmall ? 'flex-start' : 'flex-end',
          }}
          buttonSize={HEADER_FOOTER_HEIGHT}
        />
        <KitScripture
          osis={isModalVisible ? osis : ''}
          /** Fetch osis and render passage after modal is fully rendered to avoid stuttering
           */
          version={bibleVersion}
          showBorder={true}
          style={{ height: '100%' }}
          showReferenceForOnePassage={true}
        />
      </View>
    </KitModal>
  );
};
