import BaseServiceV2, {
  buildUrl,
  IHttpResponse,
  IUri,
} from '../../../BaseServiceV2';
import Environment from '../../../../Environment';
import { IAssignment, IConfirmationStatus } from '../../Types';

const HOST = Environment.fluroProxyService;
const RESOURCE = 'assignments';

interface IUpdateAssignment extends Omit<IUri, 'resource'> {
  id: string;
  body: {
    confirmation_status: IConfirmationStatus;
    confirmation_reason?: string;
  };
  token: string;
}

interface IUpdateAssignmentResponse extends IHttpResponse {
  body?: IAssignment;
}

export default async function updateConfirmationStatus({
  id,
  body,
  token,
}: IUpdateAssignment): Promise<IUpdateAssignmentResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    id,
  });

  return BaseServiceV2.Patch({ url: `${url}/response`, token, data: body });
}
