import * as React from 'react';

import Colors from '@omni/kit/theming/Colors';
import { KitText } from '@omni/kit/components';
import { TextStyle } from 'react-native';

type LabelProps = {
  children: string;
  style?: TextStyle;
};
const Label = ({ children, style }: LabelProps): JSX.Element => (
  <KitText fontSize={14} semiBold color={Colors.N600} style={style || {}}>
    {children}
  </KitText>
);

export default Label;
