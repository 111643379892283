import React from 'react';
import { Platform, ViewStyle } from 'react-native';

import Icons from '../img';
import { normalizeStyleProp } from '../utilities/normalizeStyleProp';

export interface KitIconProps {
  name?: IconName;
  size?: number;
  width?: number;
  height?: number;
  color?: string;
  style?: ViewStyle;
}

function KitIcon({
  name = 'edit',
  size = 25,
  width,
  height,
  color = '#000',
  style = {},
}: KitIconProps): JSX.Element {
  const props = {
    width: width || size,
    height: height || size,
    fill: color,
    style: normalizeStyleProp(style),
  };

  const extraProps =
    Platform.OS !== 'web'
      ? {
          accessibilityLabel: name,
        }
      : { 'aria-label': name };

  // Default to an empty fragment if icon not found
  const icon = Icons[name]?.default ?? React.Fragment;

  return React.createElement(icon, {
    ...props,
    ...extraProps,
  });
}

export default KitIcon;

export type IconName = keyof typeof Icons;

export const IconList = Object.keys(Icons) as IconName[];
