import KitEmojiPicker from '@omni/kit/components/KitEmojiPicker';
import KitModal from '@omni/kit/components/KitModalV2';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import React, { createContext, useContext, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

interface IEmoji {
  emoji: string;
}

interface IEmojiPickerContext {
  isEmojiPickerOpen: boolean;
  selectedEmoji?: IEmoji;
  setIsEmojiPickerOpen: (value: boolean) => void;
  setSelectedEmoji: (emoji: IEmoji | undefined) => void;
  _activeView: any;
}

export const EmojiPickerContext = createContext<IEmojiPickerContext>({
  isEmojiPickerOpen: false,
  selectedEmoji: undefined,
  setIsEmojiPickerOpen: () => undefined,
  setSelectedEmoji: () => undefined,
  _activeView: undefined,
});

interface ProviderProps {
  children?: React.ReactNode;
  isSmall: boolean;
}

export const EmojiPickerProvider = ({
  children,
  isSmall,
}: ProviderProps): JSX.Element => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const [selectedEmoji, setSelectedEmoji] = useState<
    { emoji: string } | undefined
  >(undefined);

  const onEmojiSelected = (emoji: { emoji: string }) => {
    setSelectedEmoji(emoji);
    setIsEmojiPickerOpen(false);
    // Avoid it getting consumed by other views using the same context
    setTimeout(() => setSelectedEmoji(undefined));
  };

  const activeView = useRef();

  return (
    <EmojiPickerContext.Provider
      value={{
        isEmojiPickerOpen,
        selectedEmoji,
        setIsEmojiPickerOpen,
        setSelectedEmoji,
        _activeView: activeView,
      }}
    >
      {children}
      <KitModal
        anchorBottom={isSmall}
        isVisible={isEmojiPickerOpen}
        modalStyle={styles.emojiModal}
        onClose={() => setIsEmojiPickerOpen(false)}
        noScrollView
      >
        <View style={styles.emojiModalContainer}>
          <KitEmojiPicker onEmojiSelected={onEmojiSelected} />
        </View>
      </KitModal>
    </EmojiPickerContext.Provider>
  );
};

export const useEmojiPickerContext = (
  ignoreActiveView = false
): IEmojiPickerContext => {
  const view = useRef();
  const ctx = useContext<IEmojiPickerContext>(EmojiPickerContext);

  const setIsEmojiPickerOpen = (v: boolean) => {
    // Store the current context consumer as the active view
    ctx._activeView.current = view;
    ctx.setIsEmojiPickerOpen(v);
  };

  // Return the selected emoji only to the active view
  const selectedEmoji =
    view === ctx._activeView?.current || ignoreActiveView
      ? ctx.selectedEmoji
      : undefined;
  return { ...ctx, setIsEmojiPickerOpen, selectedEmoji };
};

const styles = StyleSheet.create({
  emojiModal: {
    borderRadius: BorderRadius.l,
    height: '75%',
  },
  emojiModalContainer: {
    height: '100%',
    width: '100%',
  },
});
