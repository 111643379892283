import { KitText, KitTouchable } from '@omni/kit/components';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import { createStackNavigator } from '@react-navigation/stack';
import SendbirdChat from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppState, NativeEventSubscription, Platform } from 'react-native';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { useDispatch, useSelector } from 'react-redux';
import useIdleDetectorWeb from '../../hooks/useIdleDetectorWeb';
import {
  APP_STACK,
  CHANNEL_DETAILS_SCREEN,
  EDIT_CHANNEL_SCREEN,
  INVITE_SCREEN,
  MEMBER_LIST_SCREEN,
  NOT_FOUND_SCREEN,
} from '../../shared/Constants';
import { setViewReady } from '../../shared/redux/actions/SystemActions';
import {
  appIdSelector,
  appTitleSelector,
  channelSelector,
  userIdSelector,
  userTokenSelector,
} from '../../shared/redux/selectors';
import { ChannelType } from '../../shared/redux/types';

import ChannelDetailsScreen from '../../shared/scenes/channelDetails/ChannelDetailsScreen';
import EditChannelScreen from '../../shared/scenes/channelDetails/EditChannelScreen';
import InviteScreen from '../../shared/scenes/channelDetails/InviteScreen';
import MemberListScreen from '../../shared/scenes/channelDetails/MemberListScreen';
import {
  sbConnect,
  sbDisconnect,
} from '../../utilities/sendbird/userFunctions';
import LeftNavButton, { LeftNavType } from '../components/LeftNavButton';
import NotFoundScreen from '../scenes/NotFoundScreen';
import { ChatModuleProps } from '../Types';
import LargeScreenNavigator from './LargeScreenNavigator';
import navStyles from './navStyles';
import SmallScreenNavigator from './SmallScreenNavigator';

const debug = require('debug')('tca:chat:navigation:AppNavigator');

//******************************************************************************
// Stacks
//******************************************************************************

const ChannelDetailsStack = Platform.select({
  ios: createNativeStackNavigator(),
  android: createNativeStackNavigator(),
  // @ts-ignore
  web: createStackNavigator(),
});

// @ts-ignore
const AppNavigatorStack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

//******************************************************************************
// Navigators
//******************************************************************************

export const ChannelDetailsModalWithHeader = () => {
  const channel = useSelector(channelSelector) as GroupChannel;

  const parseChannelData = useCallback(() => {
    try {
      const data = JSON.parse(channel.data);
      debug('channel data ', data);

      return data;
    } catch {
      debug('Could not parse channel data');
    }
  }, [channel]);

  return (
    <ChannelDetailsStack.Navigator
      screenOptions={{
        headerHideShadow: true,
        headerTitleStyle: navStyles.headerTitleStyle,
        headerTopInsetEnabled: false, // react-native-screens (Android)
      }}
    >
      <ChannelDetailsStack.Screen
        name={CHANNEL_DETAILS_SCREEN}
        component={ChannelDetailsScreen}
        options={(props) => ({
          ...getNavOptions(
            channel?.customType === ChannelType.Direct
              ? 'Conversation details'
              : 'Group details',
            props,
            LeftNavType.Dismiss
          ),
          headerRight: () =>
            channel?.customType !== ChannelType.Direct &&
            channel.myRole === 'operator' ? (
              <KitTouchable
                onPress={() => {
                  props.navigation.navigate(EDIT_CHANNEL_SCREEN, {
                    channelData: parseChannelData(),
                  });
                }}
                style={{
                  paddingLeft: 12,
                  paddingRight: Platform.OS === 'ios' ? 12 : 18,
                  paddingVertical: 6,
                  marginRight: -6,
                  borderRadius: 40,
                }}
              >
                <KitText brandColor fontSize={17}>
                  Edit
                </KitText>
              </KitTouchable>
            ) : null,
        })}
      />

      <ChannelDetailsStack.Screen
        name={EDIT_CHANNEL_SCREEN}
        component={EditChannelScreen}
        options={(props) => ({
          ...getNavOptions('Edit group', props),
          headerRight: () => (
            <KitTouchable
              onPress={null}
              style={{
                paddingLeft: 12,
                paddingRight: Platform.OS === 'ios' ? 12 : 18,
                paddingVertical: 6,
                marginRight: -6,
                borderRadius: 40,
              }}
            >
              <KitText color={Colors.N400} fontSize={17}>
                Save
              </KitText>
            </KitTouchable>
          ),
        })}
      />

      <ChannelDetailsStack.Screen
        name={MEMBER_LIST_SCREEN}
        component={MemberListScreen}
        options={(props) =>
          // @ts-ignore
          getNavOptions(props.route.params.title || 'Members', props)
        }
      />

      <ChannelDetailsStack.Screen
        name={INVITE_SCREEN}
        component={InviteScreen}
        options={(props) => getNavOptions('Add people', props)}
      />
    </ChannelDetailsStack.Navigator>
  );
};

interface AppNavigatorProps {
  screenProps: ChatModuleProps;
}

export default function AppNavigator({
  screenProps,
}: AppNavigatorProps): JSX.Element {
  const appTitle = useSelector(appTitleSelector);
  const userId = useSelector(userIdSelector);
  const userToken = useSelector(userTokenSelector);
  const appId = useSelector(appIdSelector);
  const channel = useSelector(channelSelector);

  const dispatch = useDispatch();

  /**
   * HACK: We update lastActive when the user switches from idle to active. This
   * is used to force re-render the app tree without refreshing the page so that
   * the user gets all the updates that happened while they were idle.
   */
  const [lastActive, setLastActive] = useState(Date.now());

  /**
   * On Android, when Messaging is closed, the AppState change event is fired twice
   * in rapid succession ('background' for the ChatActivity, then immediately 'active'
   * for the MainActivity). This useRef below ensures app state only changes once
   * within 100 ms.
   */
  const appStateLastUpdatedRef = useRef<number>(0);

  const _handleAppStateChange = (nextAppState: string) => {
    if (SendbirdChat.instance) {
      if (
        nextAppState === 'active' &&
        Date.now() - appStateLastUpdatedRef.current > 100
      ) {
        debug('App is into foreground');
        SendbirdChat.instance.setForegroundState();
      } else if (nextAppState === 'background') {
        debug('App is into background');
        SendbirdChat.instance.setBackgroundState();
        appStateLastUpdatedRef.current = Date.now();
      }
    }
  };

  useIdleDetectorWeb({
    onUserBecomesActive: async () => {
      if (userId && userToken && appId) {
        await sbConnect({ userId, token: userToken, appId });
        setLastActive(Date.now());

        channel?.refresh();
      }
    },
    onUserBecomesIdle: async () => {
      // Disconnect SB after user has been idle for a certain period of time
      await sbDisconnect();
    },
  });

  useEffect(() => {
    dispatch(setViewReady(false));
  }, []);

  useEffect(() => {
    let listener: NativeEventSubscription | undefined;

    if (Platform.OS !== 'web') {
      debug('App is launched');
      listener = AppState.addEventListener('change', _handleAppStateChange);
    }

    return () => {
      if (Platform.OS !== 'web') {
        debug('App is killed');

        listener?.remove();
        // Execute any logic that should occur when Chat is closed.
      }

      sbDisconnect();
    };
  }, []);

  const { sizeClass } = useSizeClass();

  return (
    <AppNavigatorStack.Navigator
      screenOptions={{
        headerShown: false,
        // @ts-ignore
        headerTopInsetEnabled: false, // react-native-screens (Android)
      }}
    >
      <AppNavigatorStack.Screen name={APP_STACK} options={{ title: appTitle }}>
        {(stackProps) =>
          sizeClass === SizeClass.Small ? (
            /* React will trigger a re-render of this component whenver the key changes */
            <SmallScreenNavigator
              key={lastActive}
              {...stackProps}
              screenProps={screenProps}
            />
          ) : (
            <LargeScreenNavigator
              key={lastActive}
              {...stackProps}
              screenProps={screenProps}
            />
          )
        }
      </AppNavigatorStack.Screen>
      <AppNavigatorStack.Screen
        name={NOT_FOUND_SCREEN}
        options={{ title: appTitle }}
        component={NotFoundScreen}
      />
    </AppNavigatorStack.Navigator>
  );
}

//******************************************************************************
// Helpers
//******************************************************************************

export const getNavOptions = (
  // @ts-ignore
  title,
  // @ts-ignore
  { navigation },
  type = LeftNavType.Back,
  onBackPress?: () => void
) => ({
  title,
  headerTitleStyle: navStyles.headerTitleStyle,
  headerTintColor: Colors.N900,
  ...Platform.select({
    default: {
      headerLeft: () => (
        <LeftNavButton
          type={type}
          title={title}
          onPress={onBackPress || navigation.goBack}
        />
      ),
    },
    android: null,
  }),
});
