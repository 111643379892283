/**
 * KitScripture.tsx
 *
 * Bible widget
 */
import { ImageIndicator } from '@omni/blocks';
import { parseSearchResults } from '@omni/kit/services/SearchService/parseSearchResults';
import Axios from 'axios';
import Color from 'color';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  GestureResponderEvent,
  Image,
  LayoutAnimation,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import HTML, {
  HTMLNode,
  HTMLTagNode,
  HTMLTextNode,
} from 'react-native-render-html';

import { IMediaItemData } from '../../feeds/mediaItemTypes';
import PassageService from '../../services/Bible/PassageService';
import SearchService from '../../services/SearchService';
import BorderRadius from '../../theming/BorderRadius';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { ThemeContext } from '../../theming/ThemeContext';
import { ImageServiceType, parseImageUrl } from '../../utilities/utilities';
import KitError from '../KitError';
import KitListItem from '../KitListItem';
import KitLoader from '../KitLoader';
import KitText from '../KitText';
import Show from '../Show';
import { Props, VerseLocation } from './types';

const debug = require('debug')('tca:kit:component:KitScripture');

const IMAGE_SIZE = 80;
const SILHOUETTE_IMAGE_SIZE = 20;

export default function KitScripture(props: Props): JSX.Element {
  const {
    appKey = '',
    bibleContent = '',
    bookName = '',
    enableHighlights,
    enableSelection,
    footerComponent,
    highlightedVerses,
    initialSelected = {},
    onRelatedMediaPress,
    osis,
    readyToFetchRelatedMedia = false,
    relatedMediaTitle = '',
    showBorder = false,
    showReferenceForOnePassage = false,
    showRelatedMedia = false,
    showTitles = true,
    silhouetteLogoImage = '',
    updateSelectedVerses,
    useBlockPage = false,
  } = props;

  const { colorForScheme } = useContext(ThemeContext);

  //**************************************************************************
  // State Values
  //**************************************************************************

  const [bibleContentState, setBibleContentState] = useState({
    content: bibleContent,
    contentLoaded: bibleContent.length !== 0,
    contentOpacity: bibleContent.length === 0 ? 0 : 1,
  });
  const [errorCode, setErrorCode] = useState<number | null>(null);

  const [selectedVerses, setSelectedVerses] = useState(initialSelected);
  const [relatedMedia, setRelatedMedia] = useState<IMediaItemData | null>(null);
  const [relatedMediaTotal, setRelatedMediaTotal] = useState<number>(0);

  //**************************************************************************
  // Methods
  //**************************************************************************

  const onLinkPress = (
    _event: GestureResponderEvent,
    _link: string,
    htmlAttribs: Partial<VerseLocation> & { verseText?: string }
  ) => {
    onVersePress(htmlAttribs);
  };

  const onVersePress = (
    htmlAttribs: Partial<VerseLocation> & { verseText?: string }
  ) => {
    if (props.onPress) {
      props.onPress();
    }

    if (!enableSelection && !enableHighlights) {
      return;
    }

    debug('html attribs', htmlAttribs);
    const { book, chapter, verse, verseText } = htmlAttribs as VerseLocation & {
      verseText: string;
    };
    const verseId = `${book}-${chapter}-${verse}`;
    const newVerses = { ...selectedVerses };

    if (newVerses[verseId]) {
      delete newVerses[verseId];
    } else {
      newVerses[verseId] = {
        id: verseId,
        location: { book, chapter, verse },
        text: verseText,
      };
    }

    setSelectedVerses(newVerses);
    // Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
  };

  const alterNode = (node: HTMLNode): HTMLNode => {
    const { name } = node as HTMLTagNode;

    // This adds the verse text as an attribute to the verse
    // "a" tag
    if (name === 'a') {
      try {
        node.attribs = {
          ...(node.attribs || {}),
          verseText: ((node as HTMLTagNode).children[1] as HTMLTextNode).data,
        };
      } catch (err) {}
    }

    if (name === 'sup') {
      try {
        node.attribs = {
          ...(node.attribs || {}),
          style: `color: ${colorForScheme?.({
            default: Colors.N500,
          })};font-size: 10;`,
        };
      } catch (err) {}
    }

    // This adds styling for selected / highlighted verses.
    let addedStyle = '';

    if (enableSelection) {
      const isSelected = verseIsSelected(node);

      if (isSelected) {
        addedStyle = `
        background-color: ${colorForScheme?.({
          light: Colors.N100,
          dark: Colors.N500,
        })};
        text-decoration-line: underline;
      `;
      }
    }

    if (enableHighlights) {
      const isHighlighted = verseIsHighlighted(node);

      let highlightColor = '#ffefb7';

      highlightColor =
        colorForScheme?.({
          light: highlightColor,
          dark: Color(highlightColor).darken(0.5).hex(),
        }) || highlightColor;

      if (node.attribs) {
        const { book, chapter, verse } = node.attribs;
        highlightColor = highlightedVerses?.[
          `${book}-${chapter}-${verse}`
        ] as string;
      }

      if (isHighlighted) {
        addedStyle = `${addedStyle}background-color: ${colorForScheme?.({
          light: highlightColor,
          dark: Color(highlightColor).darken(0.5).hex(),
        })};`;
      }
    }

    if (name === 'a') {
      node.attribs = {
        ...(node.attribs || {}),
        style: `${addedStyle}${node.attribs.style || ''}`,
      };
    }

    if (name === 'span') {
      node.attribs = {
        ...(node.attribs || {}),
        style: `${node.attribs.style || ''}${
          (node.attribs.style as string)?.includes('color: red')
            ? `color: ${colorForScheme?.({
                light: 'red',
                dark: Colors.R500,
              })};font-weight:500;`
            : ''
        }`,
      };
    }

    return node;
  };

  const verseIsSelected = (node: HTMLNode): boolean => {
    if (!node.attribs || !enableSelection) {
      return false;
    }

    const { book, chapter, verse } = node.attribs;

    return Boolean(selectedVerses[`${book}-${chapter}-${verse}`]);
  };

  const verseIsHighlighted = (node: HTMLNode): boolean => {
    if (!node.attribs || !enableHighlights) {
      return false;
    }

    const { book, chapter, verse } = node.attribs;

    return Boolean(highlightedVerses?.[`${book}-${chapter}-${verse}`]);
  };

  const fetchContent = async (): Promise<void> => {
    try {
      if (osis && !content) {
        const result = await PassageService.getAll({
          filters: [
            ['translation_code', props.version],
            ['osis', osis],
          ],
        });

        const passages = result.body?._embedded.passages;

        const status = result.status || 550;

        setErrorCode(status >= 400 ? status : null);

        if (passages) {
          let html = '';

          if (passages.length > 1) {
            passages.forEach((passage, i) => {
              if (showBorder && i) {
                html += `<div style="width: 100%; height: 1px; background-color:${
                  colorForScheme?.({
                    light: Colors.N300,
                    dark: Colors.N500,
                  }) || Colors.N300
                }; margin-top: ${Spacing.xxl}; margin-bottom: ${
                  Spacing.xxl
                };"></div>`;
              }

              html += `<h3>${passage.reference}</h3>${passage.content_html}`;
            });
          } else if (passages.length === 1) {
            const passage = passages[0];

            if (showReferenceForOnePassage) {
              html += `<h3>${passage.reference}</h3>`;
            }

            html += passage.content_html;
          }

          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

          setBibleContentState({
            content: html,
            contentLoaded: true,
            contentOpacity: 1,
          });
        }
      }
    } catch (err: any) {
      if (Axios.isCancel(err)) {
        debug('Bible fetch cancelled.', osis);
      } else {
        console.warn('Caught error fetching Bible content: ', err.message);
      }
    }
  };

  const fetchRelatedMedia = async (): Promise<void> => {
    try {
      if (!osis) return;

      const response = await SearchService.search({
        appFilterValue: appKey,
        page: 1,
        size: 1,
        type: ['media-item'],
        scripture: osis,
        getFromCache: false,
      });

      if (response.body) {
        const relatedMediaItems = parseSearchResults(response, useBlockPage);
        setRelatedMediaTotal(response.body.total);

        if (relatedMediaItems.length) {
          setRelatedMedia(relatedMediaItems[0] as IMediaItemData);
        }
      }
    } catch (e) {
      debug('Bible fetch related media failed.', e);
    }
  };

  const textColor = colorForScheme?.({ light: Colors.N900, dark: Colors.N0 });
  const containerStyle = {
    paddingBottom: Spacing.xl,
  };
  const tagsStyles = {
    a: {
      color: textColor,
      textDecorationLine: 'none',
    },
    h3: showTitles
      ? {
          marginTop: Spacing.m,
          marginBottom: Spacing.xl,
          fontFamily: Platform.OS === 'android' ? 'Roboto-Bold' : null,
          fontWeight: '700',
        }
      : {
          display: 'none',
        },
    h4: {
      color: colorForScheme?.({ light: Colors.N500, dark: Colors.N300 }),
      fontSize: 16,
      fontStyle: 'italic',
      fontWeight: '300',
      marginBottom: Spacing.m,
    },
    p: {
      marginBottom: Spacing.m,
      fontFamily:
        Platform.OS === 'ios' ? 'NewYorkMedium-Regular' : 'NotoSerif-Regular',
    },
  };

  const baseFontStyle = {
    color: textColor,
    fontSize: Platform.OS === 'ios' ? 16 : 15,
    letterSpacing: 0,
    lineHeight: 30.5,
    ...props.baseFontStyle,
  };

  const { content, contentLoaded, contentOpacity } = bibleContentState;
  const _renderBibleHtml = useMemo(() => {
    if (content) {
      /** Don't render html view unless we've finished fetching html content. */
      return (
        <HTML
          alterNode={alterNode}
          baseFontStyle={baseFontStyle}
          containerStyle={containerStyle}
          classesStyles={classesStyles}
          html={content}
          onLinkPress={onLinkPress}
          tagsStyles={tagsStyles}
          textSelectable={false}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, selectedVerses]);

  //**************************************************************************
  // Lifecycle
  //**************************************************************************

  useEffect(() => {
    debug('osis: ', osis);
    fetchContent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osis]);

  useEffect(() => {
    if (!readyToFetchRelatedMedia) return;

    fetchRelatedMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osis, showRelatedMedia, readyToFetchRelatedMedia]);

  useEffect(() => {
    if (enableSelection && updateSelectedVerses) {
      updateSelectedVerses(selectedVerses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVerses]);

  return (
    <View style={props.style}>
      <Show show={Boolean(!contentLoaded && errorCode === null)}>
        <View
          style={{
            alignItems: 'center',
            bottom: 0,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <KitLoader />
        </View>
      </Show>

      <ScrollView
        onContentSizeChange={(contentWidth, contentHeight) => {
          if (contentLoaded && props.onContentSizeChange) {
            props.onContentSizeChange(contentWidth, contentHeight);
          }
        }}
        onMomentumScrollEnd={props.onMomentumScrollEnd}
        onScroll={props.onScroll}
        scrollEventThrottle={500}
        style={[
          {
            opacity: contentOpacity,
          },
          props.scrollViewStyle,
        ]}
      >
        <Show show={Boolean(bookName)}>
          <KitText
            h1
            color={colorForScheme?.({
              light: Colors.N900,
              dark: Colors.N0,
            })}
            style={{ marginBottom: Spacing.m, marginTop: Spacing.l }}
          >
            {bookName}
          </KitText>
        </Show>
        <Show show={Boolean(showRelatedMedia && relatedMedia)}>
          <KitListItem
            bottomBorder={false}
            ImageComponent={
              <RelatedMediaImage
                averageHexColor={relatedMedia?.averageHexColor}
                backgroundColor={colorForScheme?.({
                  light: Colors.N150,
                  dark: Colors.N700,
                })}
                image={relatedMedia?.image}
                logoImage={silhouetteLogoImage}
              />
            }
            isTitleBold={true}
            onPress={() =>
              onRelatedMediaPress?.(
                relatedMediaTotal,
                relatedMedia as IMediaItemData,
                osis as string,
                bookName
              )
            }
            onPressUnderlayColor={colorForScheme?.({
              light: Colors.N100,
              dark: Colors.N700,
            })}
            showRightIconOnAndroid={true}
            style={{
              ...styles.relatedMediaContainer,
              backgroundColor: colorForScheme?.({
                light: Colors.N0,
                dark: Colors.N1000,
              }),
              borderColor: colorForScheme?.({
                light: Colors.N100,
                dark: Colors.N700,
              }),
            }}
            subtitle={relatedMedia?.scriptures}
            subtitleColor={colorForScheme?.({
              light: Colors.N500,
              dark: Colors.N300,
            })}
            subtitleFontSize={16}
            title={relatedMediaTitle}
            titleColor={colorForScheme?.({
              light: Colors.N1000,
              dark: Colors.N0,
            })}
            titleFontSize={16}
          />
        </Show>
        {_renderBibleHtml}
        {footerComponent}
      </ScrollView>

      <Show show={Boolean(!contentLoaded && errorCode !== null)}>
        <View
          style={{
            alignItems: 'center',
            bottom: 0,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <KitError
            errorCode={errorCode as number}
            onRetry={() => {
              setErrorCode(null);
              fetchContent();
            }}
          />
        </View>
      </Show>
    </View>
  );
}

//****************************************************************************
// Styles
//****************************************************************************

const classesStyles = { smallcaps: { fontVariant: ['small-caps'] } };

interface RelatedMediaImageProps {
  averageHexColor?: string;
  backgroundColor?: string;
  image?: string;
  logoImage?: string;
}

const RelatedMediaImage = ({
  averageHexColor = '',
  backgroundColor = '',
  image = '',
  logoImage = '',
}: RelatedMediaImageProps) => {
  return (
    <View
      style={[
        {
          backgroundColor: averageHexColor || backgroundColor,
        },
        styles.leftContent,
      ]}
    >
      <Show show={Boolean(logoImage)}>
        <Image
          resizeMode='cover'
          source={{
            uri: parseImageUrl(
              logoImage,
              SILHOUETTE_IMAGE_SIZE,
              SILHOUETTE_IMAGE_SIZE,
              ImageServiceType.ImagePng,
              'fit-white'
            ),
            height: SILHOUETTE_IMAGE_SIZE,
            width: SILHOUETTE_IMAGE_SIZE,
          }}
          style={styles.silhouette}
        />
      </Show>
      <Show show={Boolean(image)}>
        <ImageIndicator
          image={image}
          imageHeight={IMAGE_SIZE}
          imageRadius={BorderRadius.s}
          imageWidth={IMAGE_SIZE}
          style={styles.image}
        />
      </Show>
    </View>
  );
};

const styles = StyleSheet.create({
  relatedMediaContainer: {
    borderRadius: Spacing.l,
    borderWidth: 1,
    marginVertical: Spacing.m,
    padding: Spacing.l,
  },
  image: {
    bottom: 0,
    borderRadius: BorderRadius.s,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  leftContent: {
    alignItems: 'center',
    borderRadius: BorderRadius.s,
    height: 48,
    justifyContent: 'center',
    width: 85,
  },
  silhouette: {
    opacity: 0.3,
  },
});
