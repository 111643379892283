import KitText from '@omni/kit/components/KitText';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Animated, Easing, View } from 'react-native';

/**
 * @deprecated
 * Use the new `LiveCountdown` component in `@omni/media` package instead.
 * It will handle the polling and refetching, but you must provide a fetch
 * method that updates the broadcast data in the context you need.
 */
export default function LiveCountdown({
  broadcastStatus = '',
  height,
  playMedia = () => null,
  untilDate = moment(),
  width,
}: {
  broadcastStatus: string;
  height: number;
  playMedia: () => void;
  untilDate: moment.Moment;
  width: number;
}): JSX.Element {
  const [opacity] = useState(new Animated.Value(0));
  const [timeUntil, setTimeUntil] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });
  const [showBroadcastStartingMessage, setShowBroadcastStartingMessage] =
    useState(false);

  let updateTimeUntilId: NodeJS.Timeout;

  const updateTimeUntil = (): void => {
    clearTimeout(updateTimeUntilId);
    const isAfter = moment(Date.now()).isAfter(moment(untilDate));

    if (isAfter) {
      setShowBroadcastStartingMessage(true);

      if (broadcastStatus === 'live') playMedia();

      return;
    }

    const duration = moment.duration(moment().diff(untilDate));
    const dayNum = Math.floor(Math.abs(duration.asDays()));
    const hourNum = Math.abs(duration.hours());
    const minuteNum = Math.abs(duration.minutes());
    const secondNum = Math.abs(duration.seconds());
    const days = `${dayNum <= 9 ? '0' : ''}${dayNum}`;
    const hours = `${hourNum <= 9 ? '0' : ''}${hourNum}`;
    const minutes = `${minuteNum <= 9 ? '0' : ''}${minuteNum}`;
    const seconds = `${secondNum <= 9 ? '0' : ''}${secondNum}`;
    setTimeUntil({ days, hours, minutes, seconds });
    updateTimeUntilId = setTimeout(updateTimeUntil, 1000);
  };

  useEffect(() => {
    updateTimeUntil();

    return () => {
      clearTimeout(updateTimeUntilId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastStatus]);

  useEffect(() => {
    Animated.timing(opacity, {
      duration: 250,
      easing: Easing.out(Easing.ease),
      toValue: 1,
      useNativeDriver: true,
    }).start();
  }, [opacity]);

  const titleProps = {
    fontSize: 18,
    style: { opacity: 0.75 },
    white: true,
  };

  return (
    <Animated.View
      style={{
        alignItems: 'center',
        backgroundColor: '#000000dd',
        borderRadius: 3,
        height,
        justifyContent: 'center',
        left: 0,
        opacity: opacity,
        position: 'absolute',
        top: 0,
        width,
      }}
    >
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        {showBroadcastStartingMessage ? (
          <KitText
            white
            extraBold
            fontSize={24}
            center
            style={{ lineHeight: 35, width: width / 1.3 }}
          >
            This live stream will begin shortly.
          </KitText>
        ) : (
          <>
            <KitText {...titleProps}>
              Live on {untilDate.local().format('ddd, MMM DD [at] h:mma')}
            </KitText>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {parseInt(timeUntil.days, 10) > 0 && (
                <>
                  <NumberGroup number={timeUntil.days} label='Days' />
                  <Colon />
                </>
              )}
              {(parseInt(timeUntil.days, 10) > 0 ||
                parseInt(timeUntil.hours, 10) > 0) && (
                <>
                  <NumberGroup number={timeUntil.hours} label='Hours' />
                  <Colon />
                </>
              )}
              {(parseInt(timeUntil.days, 10) > 0 ||
                parseInt(timeUntil.hours, 10) > 0 ||
                parseInt(timeUntil.minutes, 10) > 0) && (
                <>
                  <NumberGroup number={timeUntil.minutes} label='Mins.' />
                  <Colon />
                </>
              )}
              <NumberGroup number={timeUntil.seconds} label='Secs.' />
            </View>
          </>
        )}
      </View>
    </Animated.View>
  );
}

const numberProps = {
  extraBold: true,
  fontSize: 40,
  style: { lineHeight: 50, marginTop: 12 },
  white: true,
};

const labelProps = {
  style: { marginTop: -8, opacity: 0.75 },
  white: true,
};

function NumberGroup({ number = '0', label = 'label' }): JSX.Element {
  return (
    <View style={{ alignItems: 'center', width: 68 }}>
      <KitText {...numberProps}>{number}</KitText>
      <KitText {...labelProps}>{label}</KitText>
    </View>
  );
}

function Colon(): JSX.Element {
  return (
    <View
      style={{ alignItems: 'center', marginBottom: 18, marginHorizontal: 4 }}
    >
      <KitText {...numberProps}>:</KitText>
    </View>
  );
}
