import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';

import Colors from '../theming/Colors';
import Spacing from '../theming/Spacing';
import { isHoverEnabled } from './HoverState';
import { IconName } from './KitIcon';
import { KitIcon, KitText } from './index';

interface ActionSheetItemProps {
  icon: IconName;
  label: string;
  iconColor?: string;
  iconSize?: number;
  onPress?: () => void;
  hovered?: boolean;
}

export default function ActionSheetItem({
  icon,
  label,
  iconColor,
  iconSize,
  onPress,
  hovered,
}: ActionSheetItemProps): JSX.Element {
  return onPress ? (
    <TouchableOpacity
      style={[
        styles.actionSheetItem,
        { backgroundColor: hovered ? '#fafbfc' : 'transparent' },
      ]}
      onPress={onPress}
    >
      <KitIcon
        name={icon}
        size={iconSize ?? 22}
        color={iconColor || Colors.brand}
      />
      <KitText
        black
        fontSize={Platform.OS === 'web' ? 16 : 18}
        style={styles.actionSheetText}
      >
        {label}
      </KitText>
    </TouchableOpacity>
  ) : (
    <View style={styles.actionSheetItem}>
      <KitIcon
        name={icon}
        size={iconSize ?? 22}
        color={iconColor || Colors.brand}
      />
      <KitText
        black
        fontSize={Platform.OS === 'web' ? 16 : 18}
        style={styles.actionSheetText}
      >
        {label}
      </KitText>
    </View>
  );
}

const styles = StyleSheet.create({
  actionSheetItem: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingTop: Platform.OS === 'web' ? Spacing.m : 18,
    paddingBottom: Platform.OS === 'web' ? Spacing.m : 18,
    paddingRight: Platform.OS === 'web' ? Spacing.l : 0,
    paddingLeft: Platform.OS === 'web' ? Spacing.l : 3,
  },
  actionSheetText: {
    marginLeft: Spacing.l,
  },
});
