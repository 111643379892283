import { getMediaPlayPositionForKey } from '@omni/kit/utilities/NativeHelpers';

import {
  ICachedProgress,
  PatchMediaPlayResource,
  TrackMediaProgressCache,
} from '.';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:UpdateServerWithAllSavedProgress'
);

/**
 * Update all local media progress to server
 */
export default async (currentUserId = ''): Promise<void> => {
  if (!currentUserId) return;

  const response = await TrackMediaProgressCache.getAll();
  debug('updateServerWithAllSavedProgress', response);

  /**
   * Update each key to server
   */
  Object.keys(response).forEach(async (saveStateKey) => {
    if (!response[saveStateKey] || !currentUserId) return;

    const json = JSON.parse(response[saveStateKey].value) as ICachedProgress;
    const position = await getMediaPlayPositionForKey(saveStateKey);

    /**
     * patch progress only if
     * - media play exists
     * - user viewing the saved media is the same as currently authenticated user
     */
    if (json.mediaPlayId && json.userId === currentUserId) {
      await PatchMediaPlayResource({
        appKey: json.appKey,
        duration: json.duration,
        mediaItemId: json.mediaItemId,
        mediaPlayId: json.mediaPlayId,
        position,
        userProfileId: json.userId,
      });
    }
  });
};
