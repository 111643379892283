import Environment from '@omni/kit/Environment';
import { KitButton, KitLoader, KitModal, KitText } from '@omni/kit/components';
import KitAppIcon from '@omni/kit/components/KitAppIcon';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import AccountsService from '@omni/kit/services/AccountsService';
import TokensService from '@omni/kit/services/TokensService';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import {
  ImageServiceType,
  createDeepLink,
  imageTypeFromContentType,
} from '@omni/kit/utilities/utilities';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LayoutAnimation,
  Linking,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { setInviteAccepted } from '../../shared/redux/actions/ChatActions';
import {
  appKeySelector,
  inviteAcceptedSelector,
} from '../../shared/redux/selectors';

type Props = {
  screenName: string;
  channelName?: string;
};

interface BrandLogo {
  url: string;
  size: { width: number; height: number };
  imageType: ImageServiceType;
}

export default function OpenInAppModal({ screenName, channelName }: Props) {
  const [brandLogo, setBrandLogo] = useState<BrandLogo | undefined>(undefined);
  const [brandColor, setBrandColor] = useState<string | undefined>('#fff');
  const [downloadLink, setDownloadLink] = useState<string | undefined>(
    'https://subsplash.com/sunny/app'
  );
  const [appName, setAppName] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(true);
  const appKey = useSelector(appKeySelector);
  const inviteAccepted = useSelector(inviteAcceptedSelector);
  const dispatch = useDispatch();
  const { sizeClass } = useSizeClass();
  const isSmall = sizeClass === SizeClass.Small;

  let title;

  if (channelName && appName) {
    title = `Open ${channelName} in ${appName}`;
  } else if (appName) {
    title = `Open ${appName}`;
  } else if (channelName) {
    title = `Open ${channelName}`;
  }

  const _openInApp = useCallback(() => {
    // TODO make use of screenName to send to appropriate screen in mobile app
    window.location.href = createDeepLink({ handler: 'chat' }, appKey);
  }, [appKey, dispatch]);

  const _openDownloadLink = useCallback(() => {
    if (downloadLink) {
      Linking.openURL(downloadLink);
    }
  }, [downloadLink, dispatch]);

  const _continueInBrowser = useCallback(() => {
    setIsOpen(false);

    // TODO: save this choice for later?
  }, [dispatch]);

  const updateLogo = useCallback(async () => {
    const app = await AccountsService.getAppWithBranding(appKey);
    const brand = app?._embedded?.branding;
    const logo = brand?._embedded?.['icon-logo-image'];

    if (logo) {
      setBrandLogo({
        url: logo._links?.dynamic?.href ?? '',
        size: { width: logo.width, height: logo.height },
        imageType: imageTypeFromContentType(logo.content_type),
      });
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setBrandColor(brand?.brand_color_hex);
    }
  }, [appKey]);

  const getDownloadLink = useCallback(async () => {
    const guestToken = await TokensService.getGuestToken();

    const axiosInstance = Axios.create({
      headers: {
        Authorization: 'Bearer ' + guestToken,
        'X-Sap-Service': 'omni-app',
      },
    });
    axiosInstance
      .get(`${Environment.feedsService}/setup/${appKey}?analytics=0`)
      .then(({ data }) => {
        if (data?.is_live_on_google_and_apple) {
          if (data?.constants?.custom_path) {
            setDownloadLink(
              `https://subsplash.com/${data.constants.custom_path}/app`
            );
          }

          if (data?.constants?.title) {
            setAppName(data.constants.title);
          }
        }
      });
  }, [appKey]);

  useEffect(() => {
    if (inviteAccepted && isSmall) {
      updateLogo();
      getDownloadLink();
    }

    dispatch(setInviteAccepted(false));
  }, [inviteAccepted, isSmall, updateLogo, getDownloadLink]);

  return brandLogo && downloadLink && appName ? (
    // @ts-ignore
    <KitModal visible={isOpen} setVisible={setIsOpen} anchorBottom>
      {brandLogo ? (
        <KitAppIcon
          size={50}
          imageUrl={brandLogo.url}
          backgroundColor={brandColor}
          style={styles.logo}
        />
      ) : (
        <KitLoader />
      )}
      <View style={styles.content}>
        <KitText
          bold
          fontSize={20}
          lineHeight={30}
          center
          color={
            colorForScheme?.({
              light: Colors.N900,
              dark: Colors.N0,
            }) || Colors.N900
          }
        >
          {title}
        </KitText>
        <KitButton
          title='Open'
          style={{ marginTop: Spacing.xl, width: '90%' }}
          onPress={_openInApp}
        />
        <KitButton
          title='Get the app'
          white
          style={{ marginTop: Spacing.m, width: '90%' }}
          onPress={_openDownloadLink}
        />
        <Pressable
          onPress={_continueInBrowser}
          style={({ pressed }) => ({
            marginVertical: Spacing.xl,
            opacity: pressed ? 0.5 : 1,
          })}
        >
          <KitText brandColor fontSize={15}>
            No thanks, I'll continue in my browser.
          </KitText>
        </Pressable>
      </View>
    </KitModal>
  ) : null;
}

const styles = StyleSheet.create({
  logo: {
    marginTop: Spacing.xl,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: Colors.N0,
    marginVertical: Spacing.xl,
    width: '100%',
    paddingBottom: Spacing.xl,
  },
  downloadSection: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
