import { ShellContextData } from '@omni/kit/Types';

export default {
  url: {
    host: '',
    customPath: '',
    headers: {
      'sap-app-version': '',
      'sap-omni-version': '',
      'sap-device-os': '',
      'ss-mobile-app': '',
    },
  },
  appUrls: {},
  tokens: {
    guest: '',
    user: '',
    refresh: '',
    accessTokens: {},
  },
  user: {
    id: '',
    profile: {
      id: '',
      fullName: '',
      photoUrl: '',
    },
  },
  app: {
    appKey: '',
    orgKey: '',
    canceled: false,
    isTrial: false,
    internal: false,
    suspended: false,
    title: '',
    customPath: '',
    appStoreInfo: {},
    branding: {
      brandColor: '',
      brandLogoId: '',
      brandLogo: '',
      colorScheme: 'light',
      silhouetteLogo: '',
      iconBackgroundColor: '',
      iconBackgroundImage: '',
    },
    features: [],
    address: {
      city: '',
      country: '',
      postal_code: '',
      state: '',
      street: '',
    },
    phone: '',
    shortCode: '',
  },
  dispatchEvent: () => undefined,
} as ShellContextData;
