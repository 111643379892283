import { KitButton, KitText } from '@omni/kit/components';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';

import Colors from '@omni/kit/theming/Colors';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import React from 'react';
import { Sender } from '@sendbird/chat/message';
import Spacing from '@omni/kit/theming/Spacing';
import { View } from 'react-native';

export default function BlockUserModal({
  isVisible,
  userToBlock,
  onClose,
  confirmBlockUser,
}: {
  isVisible: boolean;
  userToBlock: Sender | null;
  onClose: (isVisible: boolean) => void;
  confirmBlockUser: () => void;
}): JSX.Element | null {
  const { sizeClass } = useSizeClass();

  if (userToBlock === null) {
    return null;
  }

  const onCloseBlockModal = () => {
    onClose(false);
  };

  const onConfirmBlockModal = () => {
    confirmBlockUser();
    onClose(false);
  };

  return (
    <KitModalV2
      isVisible={isVisible}
      onClose={() => onCloseBlockModal()}
      anchorBottom={sizeClass === SizeClass.Small}
      coverScreen
    >
      <View
        style={{
          width: '100%',
        }}
      >
        <KitText
          black
          extraBold
          fontSize={22}
          style={{ marginBottom: Spacing.s }}
        >
          Block {userToBlock.nickname}
        </KitText>
        <KitText gray fontSize={16}>
          Are you sure you want to block this person? They will not be able to
          send or receive messages from you
        </KitText>
        <View
          style={{
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderColor: Colors.N100,
            marginVertical: Spacing.l,
            paddingVertical: Spacing.m,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <KitAvatar
              imageUrl={userToBlock.plainProfileUrl}
              nickname={userToBlock.nickname}
              size={36}
            />
            <KitText black style={{ marginLeft: Spacing.m }}>
              {userToBlock.nickname}
            </KitText>
          </View>
        </View>

        <View
          style={{
            ...(sizeClass === SizeClass.Small && {
              flexDirection: 'column',
            }),
            ...(sizeClass !== SizeClass.Small && {
              flexDirection: 'row-reverse',
              marginTop: Spacing.l,
            }),
          }}
        >
          <KitButton
            color={Colors.N900}
            title='Yes, block'
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            }}
            onPress={() => onConfirmBlockModal()}
            small={sizeClass !== SizeClass.Small}
          />
          <KitButton
            secondary
            color={Colors.N100}
            title='No, cancel'
            onPress={() => onCloseBlockModal()}
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
              ...(sizeClass !== SizeClass.Small && { marginRight: Spacing.m }),
            }}
            small={sizeClass !== SizeClass.Small}
          />
        </View>
      </View>
    </KitModalV2>
  );
}
