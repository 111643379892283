import { IMediaPlaybackStateInfo } from '../KitPlayer/playbackTypes';
import TrackMediaProgressState from './TrackMediaProgressState';
import UpdateMediaPlay from './UpdateMediaPlay';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:PatchProgressOnSeek'
);

export default (mediaPlaybackStateInfo: IMediaPlaybackStateInfo): void => {
  const {
    appKey = '',
    durationMsec,
    positionMsec,
    isSeeking,
  } = mediaPlaybackStateInfo;

  if (isSeeking) {
    debug('patchProgressOnSeek - Seeking has started');
    TrackMediaProgressState.previousStates.wasSeeking = true;
  } else {
    if (TrackMediaProgressState.previousStates.wasSeeking) {
      TrackMediaProgressState.previousStates.wasSeeking = false;
      debug('patchProgressOnSeek - Seeking is complete');

      if (
        TrackMediaProgressState.currentMediaItem &&
        TrackMediaProgressState.mediaPlay &&
        TrackMediaProgressState.userProfile
      ) {
        debug('patchProgressOnSeek - patching progress at ', positionMsec);
        UpdateMediaPlay({
          appKey,
          duration: durationMsec,
          mediaItemId: TrackMediaProgressState.currentMediaItem.id,
          mediaPlayId: TrackMediaProgressState.mediaPlay.id,
          position: positionMsec || 0,
          userId: TrackMediaProgressState.userProfile.userID,
        });
      } else {
        debug(
          'patchProgressOnSeek - Missing resources, unable to patch seeked progress',
          {
            currentMediaItem: TrackMediaProgressState.currentMediaItem,
            mediaPlay: TrackMediaProgressState.mediaPlay,
            userProfile: TrackMediaProgressState.userProfile,
          }
        );
      }
    }
  }
};
