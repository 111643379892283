import { AUTH_PROVIDERS_LIST_SCREEN, AUTH_STACK } from '@omni/kit/auth';
import getRouteRootPath from '@omni/kit/utilities/getRouteRootPath.web';
import {
  NavigationState,
  PartialState,
  PathConfigMap,
  getPathFromState,
} from '@react-navigation/native';
import { Platform } from 'react-native';

import {
  ACCEPT_INVITATION_SCREEN,
  ACCOUNT_SCREEN,
  APP_STACK,
  BLOCKED_USER_LIST_SCREEN,
  CHANNEL_DETAILS_MODAL,
  CHANNEL_DETAILS_SCREEN,
  CHANNEL_LIST_SCREEN,
  CHAT_SCREEN,
  CHAT_SETTINGS_MODAL,
  CHAT_SETTINGS_SCREEN,
  CHAT_TAB,
  CHAT_THREAD_SCREEN,
  CONVERSATIONS_TAB,
  CREATE_CHANNEL_MODAL,
  CREATE_DIRECT_MODAL,
  DISCOVER_DETAIL_SCREEN,
  DISCOVER_TAB,
  EDIT_CHANNEL_SCREEN,
  EMPTY_SCREEN,
  GROUPS_DETAIL,
  GROUPS_SCREEN,
  INTRO_SCREEN,
  JOIN_REQUESTS_SCREEN,
  MAIN_STACK,
  NOT_FOUND_SCREEN,
  POLL_RESULTS_MODAL,
  PRAYER_LIST_SCREEN,
  PRAYER_TAB,
  THREADS_TAB,
} from '../../shared/Constants';

const LinkingConfig = {
  // ARTEMIS-952: Do not enable linking yet for iOS and Android, these projects already handle deep linking
  // if we attempt to enable linking for mobile, the app will crash on launch when a user opens a deep link
  enabled: Platform.OS === 'web',
  prefixes: [
    /(?:http|https):\/\/(?:www\.)?messaging\.subsplash\.com\//,
    'http://localhost:8080/',
  ],
  config: {
    screens: {
      [NOT_FOUND_SCREEN]: '404',
      [APP_STACK]: {
        path: `${getRouteRootPath()}:appKey`,
        screens: {
          [POLL_RESULTS_MODAL]: {
            path: 'poll_results',
          },
          [MAIN_STACK]: {
            path: 'channels',
            screens: {
              [EMPTY_SCREEN]: '',
              [JOIN_REQUESTS_SCREEN]: 'join-requests',
              [PRAYER_LIST_SCREEN]: ':channelId/prayers',
              [THREADS_TAB]: ':channelId/threads',
              [DISCOVER_TAB]: 'discover',
              [CHAT_SCREEN]: {
                path: ':channelId',
                screens: {
                  // SmallScreen tab navigation routes
                  [PRAYER_TAB]: '_prayers',
                  [CHAT_TAB]: '',
                  [THREADS_TAB]: '_threads',
                },
              },
              [CHAT_THREAD_SCREEN]: {
                path: ':channelId/threads/:parentMessageId',
                parse: {
                  parentMessageId: Number,
                },
              },

              // SmallScreen only screens:
              [CHANNEL_LIST_SCREEN]: {
                screens: {
                  [CONVERSATIONS_TAB]: 'conversations',
                  [DISCOVER_TAB]: 'discover',
                },
                path: 'list',
              },
            },
          },
          [INTRO_SCREEN]: 'intro',
          [GROUPS_SCREEN]: 'groups',
          [GROUPS_DETAIL]: 'groups/:id',
          [CHANNEL_DETAILS_MODAL]: {
            path: ':channelId',
            screens: {
              [CHANNEL_DETAILS_SCREEN]: 'details',
              [EDIT_CHANNEL_SCREEN]: 'edit',
            },
          },
          [CHAT_SETTINGS_MODAL]: {
            path: 'settings',
            screens: {
              [CHAT_SETTINGS_SCREEN]: '',
              [BLOCKED_USER_LIST_SCREEN]: 'blocked',
            },
          },
          [ACCOUNT_SCREEN]: 'account',
          [CREATE_CHANNEL_MODAL]: 'create-channel',
          [CREATE_DIRECT_MODAL]: 'create-direct',
          [DISCOVER_DETAIL_SCREEN]: 'discover-detail/:channelId',
          [AUTH_STACK]: {
            path: 'auth/:access_token?/:token_type?',
            screens: {
              [AUTH_PROVIDERS_LIST_SCREEN]: 'providers',
            },
          },
          ...Platform.select({
            web: { [ACCEPT_INVITATION_SCREEN]: 'accept/:invitePayload' },
            default: {},
          }),
        },
      },
    },
  },
  getPathFromState(
    state: NavigationState | Omit<PartialState<NavigationState>, 'stale'>,
    config: {
      initialRouteName?: string;
      screens: PathConfigMap;
    }
  ): string {
    const path = getPathFromState(state, config);

    const rootPath = getRouteRootPath();

    /**
     * By default, react-navigation encodes uri components,
     * but we need to bypass this for rev links
     * e.g. https://messaging.subsplash.com/rev:some-slug/CHURCH
     * convert: rev%3Asome-slug -> rev:some-slug
     */
    if (rootPath.length > 1) {
      const rawString = rootPath.replaceAll('/', '');
      const encodedString = encodeURIComponent(rawString);

      return path.replaceAll(encodedString, rawString);
    }

    return path;
  },
};

export default LinkingConfig;
