import { AUTH_STACK } from '@omni/kit/auth';
import { KitLoader } from '@omni/kit/components';
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';

import { useChatAuth } from '../../context/ChatAuthContext';
import { CHANNEL_LIST_SCREEN, MAIN_STACK } from '../../shared/Constants';

interface AcceptInvitationScreenProps {
  route: any;
  navigation: any;
}

export default function AcceptInvitationScreen({
  route,
  navigation,
}: AcceptInvitationScreenProps): JSX.Element {
  const { isAuthenticated, isReady: isAuthReady } = useChatAuth();

  /**
   * The invite is automatically accepted when an invite is sent
   * to an existing chat-user or when an invite is sent to a new user
   * and the user signs up with the same email address as the invite.
   *
   * So at this point, we just need to prompt the user to login
   * if they are not already logged in.
   */
  const processInvite = useCallback(() => {
    if (isAuthReady && !isAuthenticated) {
      navigation.navigate(AUTH_STACK);
    } else if (isAuthReady && isAuthenticated) {
      navigation.navigate(MAIN_STACK, { screen: CHANNEL_LIST_SCREEN });
    }
  }, [isAuthReady, isAuthenticated, navigation]);

  useEffect(() => {
    processInvite();
  }, [processInvite]);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <KitLoader />
    </View>
  );
}
