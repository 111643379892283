import { useEffect, useState } from 'react';

import { App } from '@omni/kit/services/Types';
import { initialRoute } from '../utils/initialRoute';
import useHasSeenTour from './useHasSeenTour';

export default function useInitialRouteName(
  rootApp: App | undefined
): string | null {
  const [initialRouteName, setInitialRouteName] = useState<string | null>(null);
  const { hasSeenTour } = useHasSeenTour();

  useEffect(() => {
    if (hasSeenTour !== null) {
      const routeName = initialRoute({
        rootApp,
        hasSeenTour,
      });
      setInitialRouteName(routeName);
    }
  }, [rootApp, hasSeenTour]);

  return initialRouteName;
}
