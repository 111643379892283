import { KitIcon, KitText } from '@omni/kit/components';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useState } from 'react';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';

import Colors from '@omni/kit/theming/Colors';
import KitProgress from '@omni/kit/components/KitProgress';
import { SendbirdFileMessage } from '../../../../Types';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { openFile } from '../../../../../utilities/fileUtilities';

const debug = require('debug')('tca:chat:component:FileMessageBubble');

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  message: SendbirdFileMessage;
  onLongPress?: () => void;
  disabled?: boolean;
}

//******************************************************************************
// Component
//******************************************************************************

export default function FileMessageBubble({
  message,
  onLongPress,
  disabled,
}: IProps) {
  const [isDownloading, setIsDownloading] = useState(false);

  const { sizeClass } = useSizeClass();

  const progress = message.progress;

  //****************************************************************************
  // Methods
  //****************************************************************************

  const _renderContent = () => {
    const isSending = message.sendingStatus === 'pending' || progress;
    const fileType = message.type;
    let fileName = '';
    let fileIcon = '';

    if (fileType === 'application/pdf') {
      fileName = 'PDF';
      fileIcon = 'file-pdf';
    } else if (fileType === 'application/zip') {
      fileName = 'ZIP';
      fileIcon = 'file-zip';
    } else if (fileType === 'text/csv') {
      fileName = 'CSV';
      fileIcon = 'file-csv';
    } else if (fileType.startsWith('text/')) {
      fileName = 'Text';
      fileIcon = 'file-text';
    } else if (fileType.startsWith('audio/')) {
      fileName = 'Audio';
      fileIcon = 'file-audio';
    } else if (fileType.startsWith('video/')) {
      fileName = 'Video';
      fileIcon = 'file-video';
    } else {
      fileName = 'File';
      fileIcon = 'file-file';
    }

    return (
      <View style={[{ flexDirection: 'row', maxWidth: '100%' }]}>
        <View style={{ justifyContent: 'center', marginRight: Spacing.m }}>
          {/* @ts-ignore */}
          <KitIcon name={fileIcon} size={52} />
        </View>
        <View style={{ flexDirection: 'column', flexShrink: 1 }}>
          <KitText
            semiBold
            color={Colors.N900}
            fontSize={16}
            style={{ textAlign: 'left' }}
          >
            {message.name}
          </KitText>
          {isSending && (
            <View style={{ marginTop: Spacing.l }}>
              <KitProgress
                percentage={
                  progress
                    ? Math.floor((progress?.loaded! / progress?.total!) * 100)
                    : message.sendingStatus === 'pending'
                    ? 10
                    : 0
                }
              />
            </View>
          )}
          {!isSending && (
            <KitText fontSize={16} color={Colors.N600} style={{ marginTop: 3 }}>
              {fileName}
            </KitText>
          )}
        </View>
      </View>
    );
  };

  const _onMessagePressed = async () => {
    if (Platform.OS === 'web') {
      const win = window.open(message.url, '_blank');
      // @ts-ignore
      win.focus();

      return;
    }

    setIsDownloading(true);
    openFile(message.name, message.url)
      .then(() => {
        setIsDownloading(false);
      })
      .catch(() => {
        setIsDownloading(false);
      });
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  return (
    <View
      style={[
        styles.imageMessageContainer,
        { backgroundColor: isDownloading ? Colors.N100 : Colors.N0 },
        {
          maxWidth:
            sizeClass === SizeClass.Large
              ? '55%'
              : sizeClass === SizeClass.Medium
              ? '65%'
              : '100%',
        },
      ]}
    >
      <TouchableOpacity
        style={{
          flex: 1,
          margin: Spacing.l,
          alignItems: 'flex-start',
        }}
        onPress={_onMessagePressed}
        activeOpacity={0.5}
        onLongPress={Platform.OS === 'web' ? undefined : onLongPress}
        delayLongPress={200}
        disabled={disabled}
      >
        {_renderContent()}
      </TouchableOpacity>
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  imageMessageContainer: {
    minWidth: 250,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colorForScheme({ default: Colors.N100 }),
  },
});
