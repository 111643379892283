import React, { useEffect } from 'react';
import {
  getFullChannelUrl,
  getShortChannelUrl,
} from '../../../../utilities/chatUtilities';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { CHAT_SCREEN } from '../../../../shared/Constants';
import ChannelList from '../../../../shared/scenes/channelList/ChannelList';
import Colors from '@omni/kit/theming/Colors';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { OpenChannel } from '@sendbird/chat/openChannel';
import Routes from '../../../navigation/Routes';
import SidebarHeader from './SidebarHeader';
import { View } from 'react-native';
import { appKeySelector } from '../../../../shared/redux/selectors';
import { sbGetGroupChannel } from '../../../../utilities/sendbird/channelFunctions';
import { setChannel } from '../../../../shared/redux/actions/ChatActions';
import { useHistory } from 'react-router-dom';

const debug = require('debug')('omni:messaging:web:scenes:split:Sidebar');

export default function Sidebar() {
  const appKey = useSelector(appKeySelector);
  const dispatch = useDispatch();
  const history = useHistory();
  // @ts-ignore
  const { channelId } = useParams();

  const onChannelSelection = (channel: GroupChannel | OpenChannel) => {
    debug('onChannelSelection() ', channel.name);
    history.push(Routes.channelScreen(appKey, getShortChannelUrl(channel.url)));
  };

  useEffect(() => {
    if (channelId) {
      debug('channelId changed in the params');
      sbGetGroupChannel(getFullChannelUrl(channelId)).then((group) => {
        dispatch(setChannel(group));
      });
    }
  }, [channelId]);

  return (
    <View style={{ width: 374, borderRightWidth: 1, borderColor: Colors.N100 }}>
      <SidebarHeader />
      <View style={{ flex: 1 }}>
        <ChannelList onChannelSelection={onChannelSelection} />
      </View>
    </View>
  );
}
