import TokensService from '@omni/kit/services/TokensService';
import pluralize from 'pluralize';

import Environment from '../Environment';
import BaseServiceV2 from './BaseServiceV2';
import { App, ContainerAppMembership } from './Types';

const debug = require('debug')('omni:messaging:service:AccountsService');

export default class AccountsService {
  private static get host() {
    return Environment.accountsService;
  }

  public static async getAppWithBranding(
    appKey: string
  ): Promise<App | undefined> {
    return this.getApp(appKey, { includeBranding: true });
  }

  public static async getApp(
    appKey: string,
    options?: {
      includeAppStoreInfo?: boolean;
      includeBranding?: boolean;
      includeContainerAppInfo?: boolean;
      includeFeatures?: boolean;
      includeWebApp?: boolean;
    }
  ): Promise<App | undefined> {
    let queryParams: [string, string][] = [];
    let includes: string[] = [];

    if (options?.includeBranding) {
      includes = [...includes, 'branding', 'branding.icon-logo-image'];
    }

    if (options?.includeContainerAppInfo) {
      includes = [...includes, 'container-app-info'];
    }

    if (options?.includeFeatures) {
      includes = [...includes, 'app-features'];
    }

    if (options?.includeAppStoreInfo) {
      includes = [...includes, 'app-store-info'];
    }

    if (options?.includeWebApp) {
      includes = [...includes, 'web-app'];
    }

    if (includes.length > 0) {
      queryParams = [...queryParams, ['include', includes.join(',')]];
    }

    const response = await this.getOne<App>('app', appKey, queryParams);

    return response?.body;
  }

  public static async getContainerAppMemberships(
    containerAppKey: string,
    page: number,
    size: number
  ): Promise<{ memberships: ContainerAppMembership[]; total: number }> {
    const queryParams: [string, string][] = [
      ['filter[container_app_key]', containerAppKey],
      ['page[number]', `${page}`],
      ['page[size]', `${size}`],
      ['include', 'app'],
      ['sort', 'title'],
    ];

    const response = await this.getAll(
      'container-app-memberships',
      queryParams
    );

    return {
      memberships:
        (response.data?.[
          'container-app-memberships'
        ] as ContainerAppMembership[]) ?? [],
      total: response.meta?.total ?? 0,
    };
  }

  static async getAll(resource: string, queryParams: [string, string][] = []) {
    const url = this._buildUrl(resource, undefined, [...queryParams]);

    const guestToken = await TokensService.getGuestToken();

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + guestToken,
        'X-Sap-Service': 'omni-app',
      },
    })
      .then(this._handleAllResponse)
      .catch(this._handleErrorResponse);
  }

  static async getOne<T = any>(
    resource: string,
    id: string,
    queryParams: [string, string][] = []
  ) {
    const url = this._buildUrl(resource, id, [...queryParams]);

    const guestToken = await TokensService.getGuestToken();

    const requestProps = {
      url: url,
      headers: {
        Authorization: 'Bearer ' + guestToken,
      },
    };

    const cachedResponse = await BaseServiceV2.Get<T>({
      ...requestProps,
      getFromCache: true,
    });

    try {
      if (cachedResponse.body) {
        // Keep app info up-to-date. Do not use 'await' here so that
        // screens will render immediately with cached data while fetching the latest.
        BaseServiceV2.Get<T>(requestProps);

        return cachedResponse;
      } else {
        return BaseServiceV2.Get<T>(requestProps);
      }
    } catch {
      this._handleErrorResponse;
    }
  }

  private static _buildUrl(
    resource: string,
    id?: string,
    queryParams?: [string, string][]
  ) {
    id = id ? `/${id}` : '';

    let queryString = '';

    if (queryParams) {
      let prefix = '?';
      queryParams.forEach(([key, value]) => {
        queryString = queryString + `${prefix}${key}=${value}`;
        prefix = '&';
      });
    }

    return `${this.host}/${pluralize(resource)}${id}${queryString}`;
  }

  private static _handleAllResponse(response: Response) {
    if (!response.ok) return Promise.reject(response);

    return response.json().then((data) => {
      return {
        meta: {
          count: data.count,
          total: data.total,
        },
        status: response.status,
        data: data._embedded,
      };
    });
  }

  private static _handleOneResponse(response: Response) {
    if (!response.ok) return Promise.reject(response);

    return response.json().then((data) => {
      return {
        status: response.status,
        data,
      };
    });
  }

  private static _handleErrorResponse(response?: Response) {
    if (!response?.text) {
      return Promise.reject({
        status: 0,
        message: 'Request failed. Check internet connection',
      });
    }

    return response.text().then((text) => {
      try {
        const json = JSON.parse(text);

        return Promise.reject({
          status: response.status,
          message: Array.isArray(json.errors) ? json.errors[0].detail : json,
        });
      } catch (e) {
        return Promise.reject({
          status: response.status,
          message: text,
        });
      }
    });
  }
}
