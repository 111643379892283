import React from 'react';
import { View, ViewStyle } from 'react-native';

import KitIcon, { KitIconProps } from './KitIcon';

interface KitIconCircleProps extends KitIconProps {
  circleSize: number;
  backgroundColor: string;
  circleStyle?: ViewStyle;
}

function KitIconCircle(props: KitIconCircleProps): JSX.Element {
  const { backgroundColor, circleSize, circleStyle } = props;

  return (
    <View
      style={[
        {
          alignItems: 'center',
          backgroundColor,
          borderRadius: 1000,
          height: circleSize,
          justifyContent: 'center',
          padding: circleSize / 4,
          width: circleSize,
        },
        circleStyle,
      ]}
    >
      <KitIcon {...props} />
    </View>
  );
}
export default KitIconCircle;
