import { IEndUser } from '../ChatService';

export interface IGroup {
  id: string;
  name: string;
  description?: string;
  app_key: string;
  org_key: string;
  member_count: number;
  schedule?: string;
  location?: string;
  frequency?: string;
  day_of_week?: string;
  scheduleTitle?: string;
  type: string;
  enrollment_type: IEnrollmentType;
  public: boolean;
  created_at: Date; // '2021-11-29T17:50:30Z'
  updated_at: Date; // '2021-11-29T17:50:30Z'
  _embedded?: {
    image?: {
      id: string;
      average_color_hex?: string;
    };
    'group-type'?: IGroupType;
    'group-members'?: IGroupMember[];
    type?: IGroupType;
    location?: {};
  };
}

export interface IGroupType {
  id: string;
  name: string;
}

export type IEnrollmentType = 'open' | 'closed' | 'request_to_join';

export interface IGroupTypes {
  count: number;
  _embedded?: {
    'group-types': IGroupType[];
  };
}

export interface IGroupMember {
  id: string;
  role: 'member' | 'manager';
  messaging_status: 'invited' | 'active' | 'banned' | 'error' | undefined;
  created_at: Date;
  updated_at: Date;
  _embedded: {
    group?: IGroup;
    'end-user'?: IEndUser;
  };
}

export interface IGroupMembers {
  count: number;
  _embedded?: {
    'group-members': IGroupMember[];
  };
}

export interface IProfile {
  id: string;
  short_id: number;
  org_key: string;
  first_name?: string;
  last_name?: string;
  emails: IEmail[];
  email?: string;
  phone?: string;
  photo_url?: string;
  status: 'active' | unknown;
  created_at: Date;
  updated_at: Date;
  _embedded?: {
    ['end-user']?: {
      id?: string;
    };
    photo?: {
      id?: string;
    };
  };
}

export interface IEmail {
  value: string;
}

export const GroupRoles = {
  Manager: 'manager',
  Member: 'member',
};

export interface IJoinRequest {
  id: string;
  status: 'pending' | 'approved' | 'rejected' | 'denied';
  created_at: Date;
  updated_at: Date;
  _embedded: {
    'requesting-user': IEndUser;
    group: IGroup;
    'responding-manager'?: IGroupMember;
  };
}

export interface IJoinRequests {
  count: number;
  _embedded?: {
    'join-requests': IJoinRequest[];
  };
}
