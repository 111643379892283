import {
  checkUnknownHouseholdRoleMembers,
  getAdultProfiles,
  getChildGuestProfiles,
  getChildMemberProfiles,
} from '@omni/check-in/kiosk/utilities/household';
import { IHousehold, IProfile } from '@omni/kit/services/PeopleService/Types';
import { useMemo } from 'react';

export default function useHouseholdMembers(household?: IHousehold): {
  childMembers: IProfile[];
  childGuests: IProfile[];
  children: IProfile[];
  adults: IProfile[];
  allProfiles: IProfile[];
  hasUnknownHouseholdRoleMembers: boolean;
} {
  const childMembers = useMemo(() => {
    return getChildMemberProfiles(household);
  }, [household]);
  const childGuests = useMemo(() => {
    return getChildGuestProfiles(household);
  }, [household]);
  const children = useMemo(() => {
    return [...childMembers, ...childGuests];
  }, [childMembers, childGuests]);

  // TODO: this eventually will also include trusted people PPL-2091
  const adults = useMemo(() => {
    return getAdultProfiles(household);
  }, [household]);
  const allProfiles = useMemo(() => {
    return [...children, ...adults];
  }, [children, adults]);
  const hasUnknownHouseholdRoleMembers = useMemo(() => {
    return checkUnknownHouseholdRoleMembers(household?._embedded?.members);
  }, [household]);

  return {
    childMembers,
    childGuests,
    children,
    adults,
    allProfiles,
    hasUnknownHouseholdRoleMembers,
  };
}
