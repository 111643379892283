import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../theming/Colors';
import Spacing from '../theming/Spacing';
import { IconName } from './KitIcon';
import KitText from './KitText';
import { KitIcon } from './index';

interface IProps {
  icon: IconName;
  style?: StyleProp<ViewStyle>;
  subtitle: string;
  title: string;
  iconColor?: string;
  textColor?: string;
  link?: () => void;
  linkTitle?: string;
}

const ICON_SIZE = 20;

export default (props: IProps): JSX.Element => {
  const {
    icon,
    style,
    subtitle,
    title,
    iconColor,
    textColor,
    link,
    linkTitle,
  } = props;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.icon}>
        <KitIcon name={icon} size={ICON_SIZE} color={iconColor} />
      </View>
      <View
        style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}
      >
        {title === '' ? (
          <></>
        ) : (
          <KitText black={!textColor} bold color={textColor}>
            {title}
          </KitText>
        )}
        {subtitle === '' ? (
          <></>
        ) : (
          <KitText black={!textColor} color={textColor}>
            {subtitle}
            {linkTitle && (
              <KitText
                onPress={link}
                color={Colors.N900}
                style={{ textDecorationLine: 'underline' }}
              >
                {linkTitle}
              </KitText>
            )}
          </KitText>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: Spacing.l,
    borderRadius: 8,
    backgroundColor: Colors.N50,
    alignSelf: 'center',
    flexDirection: 'row',
  },
  icon: {
    alignSelf: 'center',
    marginRight: Spacing.l,
    minWidth: ICON_SIZE,
  },
  text: {
    alignSelf: 'center',
    flex: 1,
  },
});
