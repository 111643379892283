type SchemeValueType = {
  default?: string; // This is given for components whose appearance doesn't change between modes.
  light?: string;
  dark?: string;
};

export const colorForScheme = (
  schemeValues: SchemeValueType
): string | undefined => {
  const scheme = getAppScheme();
  switch (scheme) {
    case 'dark':
      return schemeValues.dark || schemeValues.default;
    case 'light':
      return schemeValues.light || schemeValues.default;
    default:
      return schemeValues.default; // We should only get here if evaluating the scheme fails.
  }
};

const getAppScheme = () => {
  return 'default';
};
