import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { Platform, StyleSheet, TextInput } from 'react-native';

export interface IKitEditorProps {
  src?: string;
  onChange?: (newValue: string) => void;
}

export default function KitEditor(props: IKitEditorProps): JSX.Element {
  const onChange = (newValue: string) => {
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  // avoid re-rendering while user is still typing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(debounce(onChange, 500), [onChange]);

  return (
    <TextInput
      multiline
      style={styles.textInput}
      onChangeText={debouncedChange}
      defaultValue={props.src}
    />
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  textInput: {
    fontFamily: Platform.OS === 'ios' ? 'Menlo-Bold' : 'monospace',
    color: '#DCDCDC',
    backgroundColor: '#1E1E1E',
    flex: 1,
  },
});
