import { IProfile } from '@omni/kit/services/PeopleService/Types';
import {
  ImageServiceType,
  createImageUrl,
  parseImageUrl,
} from '@omni/kit/utilities/utilities';

export const profileImageUrl = (profile: IProfile): string | undefined =>
  parseImageUrl(
    createImageUrl(profile?._embedded?.photo?.id),
    222,
    230,
    ImageServiceType.ImageJpeg
  );

export const getGradeOrdinal = (gradeInteger: number): string => {
  if (gradeInteger < 1 || gradeInteger > 14) {
    return '';
  }

  if (gradeInteger === 1) {
    return 'Pre-K';
  }

  if (gradeInteger === 2) {
    return 'K';
  }

  const gradeNumeral = gradeInteger - 2;
  const lastDigit = gradeNumeral % 10;

  if (gradeNumeral >= 11 && gradeNumeral <= 13) {
    return gradeNumeral + 'th';
  }

  const suffixes = ['st', 'nd', 'rd'];

  return gradeNumeral + (suffixes[lastDigit - 1] || 'th');
};
