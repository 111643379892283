import { KitBadge, KitListItem } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';

import { userSelector } from '../../../shared/redux/selectors';
import { ChannelType } from '../../../shared/redux/types';
import { ChannelMember } from '../../Types';

const debug = require('debug')('tca:chat:component:MemberListRow');

//******************************************************************************
// Types
//******************************************************************************

export interface MemberListRowProps {
  channel: GroupChannel;
  key: string;
  member: ChannelMember;
  onMemberEditPress?: (member: ChannelMember) => void;
  renderWebActionSheet?: (
    member: ChannelMember,
    closeMenu: () => void
  ) => React.ReactNode;
  style?: ViewStyle;
  topBorder?: boolean;
  isManager?: boolean;
}

export default function MemberListRow({
  member,
  channel,
  onMemberEditPress,
  topBorder,
  isManager,
}: MemberListRowProps): JSX.Element {
  const { t } = useTranslation(['groups']);
  const currentUser = useSelector(userSelector);
  const currentId = currentUser?.userId;
  const isDirect = channel.customType === ChannelType.Direct;
  const isOperator = channel.myRole === 'operator';
  const isCurrentUser = currentId === member.userId;
  const showActionMenu =
    (isDirect || isOperator) && !isCurrentUser && onMemberEditPress;
  const nickname = `${member.nickname}${
    member.userId === currentId ? ' (you)' : ''
  }`;
  const profileImageURL = member.profileUrl
    ? member.profileUrl
    : member._embedded?.['chat-user']?.profile_url;

  return (
    <KitListItem
      minHeight={80}
      ImageComponent={
        <KitAvatar
          nickname={nickname}
          size={Spacing.xxl}
          imageUrl={profileImageURL}
        />
      }
      onPress={
        showActionMenu && onMemberEditPress
          ? () => onMemberEditPress(member)
          : undefined
      }
      rightIcon='action-h'
      title={nickname}
      topBorder={topBorder}
      rightElement={
        isManager ? (
          <KitBadge text={t('labelGroupManager')} size='l' />
        ) : undefined
      }
    />
  );
}
