import { useSourceContext } from '@omni/kit/contexts/SourceContext';
import { UserInfoService } from '@omni/kit/services/UserInfoService';
import { useCallback, useEffect, useState } from 'react';

import { useAuth } from '../contexts/AuthContext';
import { IUserProfileInfo } from '../services/FeedsService/GetUserProfile';

const debug = require('debug')('omni:kit:hooks:useUserInfo');

/**
 * @deprecated in favor of useShellContext
 */
export default function useUserInfo(
  appKey?: string,
  accessTokens?: { [authProviderId: string]: string | null }
): {
  userInfo?: IUserProfileInfo;
  userInfoLoading: boolean;
} {
  const { isAuthReady, targetProviders } = useAuth();
  const source = useSourceContext();

  // Local state
  const [userInfo, setUserInfo] = useState<IUserProfileInfo | undefined>();
  const [userInfoLoading, setUserInfoLoading] = useState(true);

  useEffect(() => {
    const setup = async () => {
      const userInfo = await UserInfoService.loadUserInfo({
        appKey: appKey || '',
        targetProviders,
        accessTokens: accessTokens || {},
      });

      setUserInfo(userInfo);
      setUserInfoLoading(false);
    };

    setup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appKey, isAuthReady, targetProviders]);

  // Keep context up-to-date with updates to user-info in UserInfoService
  useEffect(() => {
    if (!appKey) {
      return;
    }

    const removeListener = UserInfoService.addListener(
      appKey,
      'UserInfoChanged',
      userInfoCallback,
      'useUserInfo'
    );

    return () => {
      removeListener();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appKey]);

  const userInfoCallback = useCallback(
    (data: { appKey: string; userInfo: IUserProfileInfo | undefined }) => {
      const { userInfo } = data;

      setUserInfo(userInfo);
      setUserInfoLoading(false);
    },
    []
  );

  return { userInfo, userInfoLoading };
}
