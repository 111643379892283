import { AppFeature, AppStoreInfo } from './services/Types';
import { ImageServiceType } from './utilities/utilities';

export type Optional<T> = T | undefined | null;

export enum NATIVE_EVENT_KEY {
  CONTINUE_PLAYING = 'continuePlaying',
  MEDIA_DETAIL = 'mediaDetail',
  ACCOUNT_MENU = 'accountMenu',
  LIST_MEDIA_SERIES = 'listMediaSeries',
  LIST_MEDIA_ITEMS_PER_SERIES = 'listMediaItemsPerSeries',
}

export enum CALLOUT_KEY {
  ACCOUNT_MENU_BUTTON = 'account-avatar-callout',
  ACCOUNT_MENU_BUTTON_PRECHECK_CALLOUT = 'precheck-callout',
}

type NATIVE_EVENT_KEY_ID = `${NATIVE_EVENT_KEY}-${string}`;

export interface Link {
  href: string;
}

export interface IAppImage {
  url: string;
  type: string;
  templated: boolean;
  aspectRatio: number;
}

export interface IAppState {
  title?: string;
  images?: IAppImage[];
}

export interface IAppStates {
  loading?: IAppState;
}

export interface BridgeAction {
  handler: string;
  command?: string;
  nativeEventKey?: NATIVE_EVENT_KEY | NATIVE_EVENT_KEY_ID;
  states?: IAppStates; // use case: app handler loading state

  [key: string]: any;
}

export type DispatchEvent =
  | {
      type: 'AUTHENTICATE_USER';
      appKey?: string;
      orgKey?: string;
      source?: string;
      targetAuthProviderId?: string;
      customReturnUrl?: string;
      openInNewTab?: boolean;
    }
  | { type: 'SET_SNACKBAR_MESSAGE'; payload: string }
  | { type: 'REFRESH_TOKEN' };

export interface ShellContextData {
  url: {
    host: string;
    customPath: string;
    headers: Record<string, unknown>;
  };
  appUrls: {
    webAppUrl?: string;
    givingUrl?: string;
  };
  tokens: {
    guest: string;
    user?: string;
    refresh?: string;
    accessTokens: { [authProviderId: string]: string | null };
  };
  user?: {
    id: string;
    email?: string;
    profile: {
      id: string;
      firstName?: string;
      lastName?: string;
      fullName: string;
      initials?: string;
      photoUrl?: string;
    };
  };
  app: {
    appKey: string;
    orgKey: string;
    canceled?: boolean;
    isTrial?: boolean;
    internal?: boolean;
    suspended?: boolean;
    title: string;
    customPath?: string;
    appStoreInfo?: AppStoreInfo;
    branding?: {
      brandColor?: string;
      brandLogo?: string;
      brandLogoId?: string;
      brandLogoImageType?: ImageServiceType;
      brandLogoSize?: { width: number; height: number };
      colorScheme: 'light' | 'dark';
      silhouetteLogo?: string;
      iconBackgroundColor?: string;
      iconBackgroundImage?: string;
    };
    features: AppFeature[];
    address?: {
      city: string;
      country: string;
      postal_code: string;
      state: string;
      street: string;
    };
    phone?: string;
    shortCode: string;
  };
  device?: {
    installId?: string;
  };
  dispatchEvent: (evt: DispatchEvent) => void;
}

export type ActionTypeAndPayloadObj = {
  payload?: unknown;
  type: string;
};
