import KitIcon, { IconName } from '@omni/kit/components/KitIcon';
import Colors from '@omni/kit/theming/Colors';
import React, { ReactNode } from 'react';
import { Platform, StyleSheet, TouchableHighlight, View } from 'react-native';

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  buttonSize?: number;
  color?: string;
  disabled?: boolean;
  light?: boolean;
  name: IconName;
  onPress: () => void;
  size?: number;
}

//******************************************************************************
// Component
//******************************************************************************

const RoundImageButton: (props: IProps) => ReactNode = Platform.select({
  default: ({
    buttonSize = 56,
    color,
    disabled,
    light,
    name,
    onPress,
    size = 22,
  }: IProps): JSX.Element => (
    <TouchableHighlight
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.buttonContainer,
        { height: buttonSize, width: buttonSize },
      ]}
      underlayColor={light ? '#FFFFFF22' : '#0000001e'}
    >
      <View
        style={[
          styles.button,
          {
            height: buttonSize,
            opacity: disabled ? 0.8 : 1.0,
            width: buttonSize,
          },
        ]}
      >
        <KitIcon name={name} color={color ? color : Colors.N900} size={size} />
      </View>
    </TouchableHighlight>
  ),
});

export default RoundImageButton;

//******************************************************************************
//
//******************************************************************************

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: 28,
    justifyContent: 'center',
    ...Platform.select({
      android: {
        overflow: 'hidden',
      },
    }),
  },
  buttonContainer: {
    borderRadius: 28,
  },
});
