import React from 'react';
import { StyleSheet, View } from 'react-native';
import EnhancedModal from 'react-native-modal';

import Spacing from '../../theming/Spacing';

interface KitModalProps {
  anchorBottom?: boolean;
  children: JSX.Element | JSX.Element[] | undefined;
  gestureEnabled?: boolean;
  innerScrollEnabled?: boolean;
  onClose?: () => void;
  setVisible: (value: boolean) => void;
  showHandle?: boolean;
  visible: boolean;
}

export default function KitModal({
  anchorBottom,
  children,
  setVisible = () => undefined,
  visible = false,
  ...props
}: KitModalProps): JSX.Element {
  return (
    <EnhancedModal
      isVisible={visible}
      onBackdropPress={() => {
        setVisible(false);
      }}
      style={[styles.container, anchorBottom && styles.anchorBottom]}
      {...props}
    >
      <View
        style={[
          styles.modalContainer,
          anchorBottom && styles.anchorBottomContainer,
        ]}
      >
        {children}
      </View>
    </EnhancedModal>
  );
}

const styles = StyleSheet.create({
  anchorBottom: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  anchorBottomContainer: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: '100%',
  },
  container: {
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    margin: Spacing.xxl,
  },
  modalContainer: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    justifyContent: 'center',
    margin: 0,
    maxWidth: 700,
  },
});
