import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IHouseholdRelationship } from '../Types';

const HOST = Environment.peopleService;
const RESOURCE = 'household-relationships';

interface ICreateHouseholdRelationship extends Omit<IUri, 'resource'> {
  body: IHouseholdRelationship;
  token: string;
}

interface ICreateHouseholdRelationshipResponse extends IHttpResponse {
  body?: IHouseholdRelationship;
}

export default ({
  body,
  token,
}: ICreateHouseholdRelationship): Promise<ICreateHouseholdRelationshipResponse> => {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
  });

  return BaseServiceV2.Post({ url, token, data: body });
};
