import { KitButton } from '@omni/kit/components';
import React from 'react';

//******************************************************************************
// Types
//******************************************************************************

export interface NavNextButtonProps {
  onPress: () => void;
}

export default function NavNextButton({
  onPress,
}: NavNextButtonProps): JSX.Element {
  return <KitButton title='Add' onPress={onPress} />;
}
