import Environment from '@omni/kit/Environment';
import BaseServiceV2 from '@omni/kit/services/BaseServiceV2';
import {
  REQUEST_ERROR,
  validateResponse,
} from '@omni/kit/utilities/requestError';

import { ITokenResponse, ITokenResponseBody } from '../Types';
import { ITokenStatus, getTokenStatus } from './GetTokenData';

/*
 * Do not use this directly in a component, instead call handleSetupCode from KioskContext
 */
const VerifySetupCode = async (props?: {
  app_key?: string;
  org_key?: string;
  short_code?: string;
  long_code?: string;
}): Promise<ITokenResponseBody | undefined> => {
  const app_key = props?.app_key;
  const org_key = props?.org_key;
  const short_code = props?.short_code;
  const long_code = props?.long_code;

  if (!app_key && !org_key) {
    throw new Error('Missing app_key or org_key.');
  }

  if (!short_code && !long_code) {
    throw new Error('Missing short_code or long_code.');
  }

  let result: ITokenResponse | undefined;

  try {
    const url = `${Environment.checkInService}/setup-codes/verify`;
    const data = {
      app_key,
      org_key,
      short_code,
      long_code,
    };

    result = await BaseServiceV2.Post({ url, data });
  } catch (e) {
    throw new Error(REQUEST_ERROR.NO_INTERNET);
  }

  validateResponse(result?.status, result?.headers);

  const fetchedData = result?.body;

  const accessToken = fetchedData?.access_token ?? '';
  const accessTokenExpiresAt = fetchedData?.access_token_expires_at ?? '';

  const tokenStatus = getTokenStatus(accessToken, accessTokenExpiresAt);

  if (tokenStatus === ITokenStatus.Valid) {
    return fetchedData;
  }
};

export default VerifySetupCode;
