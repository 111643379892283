import MediaPlayService, {
  IMediaPlays,
} from '@omni/kit/services/MediaPersonalizationService/MediaPlayService';
import { IMediaPlayUpdateBody } from '@omni/kit/services/MediaPersonalizationService/MediaPlayService/Core/Update';
import {
  UNLEASH_TOGGLES,
  setupUnleash,
} from '@omni/kit/services/UnleashService';
import { FeatureFlag } from '@omni/kit/services/UnleashService/FeatureFlag';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:PatchMediaPlayResource'
);

/**
 * Patch media play resource to server
 *
 * Note:
 * checks unleash flag for kill switch in case of server overload from patching media progress
 *
 * @param playCount {number}
 * @param position {number}
 * @param mediaPlayId {string}
 */
export default async ({
  appKey,
  duration = 0,
  mediaItemId = '',
  mediaPlayId = '',
  playCount,
  position = 0,
  userProfileId = '',
}: {
  appKey: string;
  duration?: number;
  mediaItemId?: string;
  mediaPlayId?: string;
  playCount?: number;
  position?: number;
  userProfileId?: string;
}): Promise<IMediaPlays | null> => {
  await setupUnleash(appKey);

  if (FeatureFlag.isMediaSuppressProgressTrackingActive) {
    debug(
      `Unleash kill switch - ${UNLEASH_TOGGLES.MEDIA_SUPPRESS_PROGRESS_TRACKING} - enabled. Disable patching.`
    );

    return null;
  }

  position = Math.floor(position);

  const data: IMediaPlayUpdateBody = {
    progress_ms: duration > 0 ? Math.min(duration, position) : position,
    _embedded: {
      'end-user': { id: userProfileId },
      'media-item': { id: mediaItemId },
    },
    app_key: appKey,
  };

  if (duration > 0) {
    data.duration_ms = duration;
  }

  if (typeof playCount === 'number') {
    data.play_count = playCount;
  }

  debug('Patching with data', data);

  try {
    const response = await MediaPlayService.update({
      appKey,
      id: mediaPlayId,
      data,
    });

    if (response.status && response.status >= 400) throw response;

    debug('updated media play resource', response.body);

    return response.body || null;
  } catch (e) {
    debug('Failed to update media play', e);
  }

  return null;
};
