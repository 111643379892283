import React from 'react';
import { View } from 'react-native';

import formatDuration from '../../utilities/formatDuration';
import KitText from '../KitText';
import styles from './styles';
import { IKitMediaDurationIndicator } from './types';

const FONT_SIZE = 12;
/**
 * https://developer.mozilla.org/en-US/docs/Web/CSS/line-height#values
 *
 * Normal line height is typically 1.2 of the font size
 */
const LINE_HEIGHT_PROPORTION = 1.2;

export default ({
  duration = 0,
  wrapperStyle,
}: IKitMediaDurationIndicator): JSX.Element => {
  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      <KitText
        fontSize={FONT_SIZE}
        fontWeight={500}
        lineHeight={FONT_SIZE * LINE_HEIGHT_PROPORTION}
        white
      >
        {duration >= 0 ? formatDuration(duration / 1000) : ''}
      </KitText>
    </View>
  );
};
