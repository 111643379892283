import { KitInput } from '@omni/kit/components';
import { IKitInputProps } from '@omni/kit/components/KitInput';
import { useThemeContext } from '@omni/kit/theming/ThemeContext';
import React, { useEffect, useRef } from 'react';
import { TextStyle, ViewStyle } from 'react-native';

import { useKioskContext } from '../contexts/KioskContext';

interface KioskInputProps extends IKitInputProps {
  inputStyle?: ViewStyle | TextStyle;
}

export default function KioskInput({
  autoCorrect = false,
  inputStyle,
  onChangeText,
  ...props
}: KioskInputProps): JSX.Element {
  const kioskInputStyle = {
    ...inputStyle,
    fontFamily: useThemeContext().fontFamily,
  };
  const { setIsTyping } = useKioskContext();
  const typingRef = useRef<NodeJS.Timeout | null>(null);

  const handleChangetText = (t: string) => {
    if (onChangeText) {
      onChangeText(t);
      setIsTyping(true);
      if (typingRef.current) {
        clearTimeout(typingRef.current);
      }
      typingRef.current = setTimeout(() => {
        setIsTyping(false);
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      setIsTyping(false);
    };
  }, []);

  return (
    <KitInput
      autoCorrect={autoCorrect}
      inputStyle={kioskInputStyle}
      onChangeText={handleChangetText}
      {...props}
    />
  );
}
