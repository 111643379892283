import { IDownloaderResponse } from '../../Downloader';

export interface IHttpResponse<T = any> {
  status?: number;
  body?: T;
  ok?: boolean;
  message?: string;
  detail?: any;
  errors?: APIError[];
  headers?: Record<string, string>;
}

export interface IHttpErrorResponse {
  status?: number;
  ok: false;
  message?: string;
  detail?: any;
  errors?: APIError[];
  headers?: Record<string, string>;
}

export interface APIError {
  code?: string;
  detail?: string;
  source?: {
    pointer?: string;
    parameter?: string;
  };
}

export async function handleSuccess(
  response: IDownloaderResponse
): Promise<IHttpResponse> {
  return {
    status: response?.status,
    ok: true,
    body: response?.body,
  };
}

export function isHttpErrorResponse(
  obj: IHttpResponse
): obj is IHttpErrorResponse {
  return obj && 'ok' in obj && obj.ok === false;
}

export async function handleError({
  response,
  message,
}: {
  response: IDownloaderResponse;
  message: string;
}): Promise<IHttpErrorResponse | IHttpResponse> {
  return {
    // using status 0 when the device is offline or cannot reach the internet
    status: response?.status ?? 0,
    ok: false,
    message: response?.data?.errors?.[0]?.detail ?? message,
    errors: response?.data?.errors,
    headers: response?.headers,
  };
}
