import {
  KitButton,
  KitLink,
  KitText,
  KitTouchableIcon,
} from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitPopup from '@omni/kit/components/KitPopup.web';
import Show from '@omni/kit/components/Show';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { IJoinRequest } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { createImageUrl } from '@omni/kit/utilities/utilities';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { UserContactSheet } from './UserContactSheet';

export default ({
  request,
  onApprove,
  onDeny,
  onMoreActions,
}: {
  request: IJoinRequest;
  onApprove: (request: IJoinRequest) => void;
  onDeny: (request: IJoinRequest) => void;
  onMoreActions: (request: IJoinRequest) => void;
}): JSX.Element | null => {
  if (
    !request._embedded['requesting-user']?._embedded?.profiles ||
    !request._embedded.group?.name
  ) {
    return null;
  }

  const { sizeClass } = useSizeClass();
  const { t } = useTranslation(['messaging']);

  const endUser = request._embedded['requesting-user'];
  const profile = endUser?._embedded?.profiles[0];
  const userFullName = `${profile?.first_name} ${profile?.last_name}`;
  const group = request._embedded.group;

  return (
    <View
      style={{
        flexDirection: sizeClass === SizeClass.Small ? 'column' : 'row',
        paddingVertical: Spacing.xl,
        borderBottomWidth: 1,
        borderBottomColor: Colors.N100,
      }}
      testID='join-request-row'
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          flex: 1,
          ...(sizeClass === SizeClass.Small && { alignItems: 'flex-start' }),
        }}
        testID='join-request-profile-photo-and-details'
      >
        <KitAvatar
          size={36}
          nickname={userFullName}
          imageUrl={createImageUrl(profile?._embedded?.photo?.id)}
          style={{ marginRight: Spacing.m }}
        />
        <View style={{ flex: 1 }} testID='join-request-text-details'>
          <KitText black style={{ lineHeight: 22 }}>
            {Platform.OS === 'web' ? (
              <KitPopup
                content={<UserContactSheet endUser={endUser} />}
                triggerType='click'
              >
                <KitLink black bold underline='hover'>
                  {userFullName}
                </KitLink>
              </KitPopup>
            ) : (
              <KitText black bold>
                {userFullName}
              </KitText>
            )}{' '}
            {t('messaging:textWouldLikeToJoinGroup', { group: group.name })}
          </KitText>
          <KitText
            fontSize={14}
            gray
            style={{
              lineHeight: 14,
              ...(sizeClass !== SizeClass.Large && { marginTop: Spacing.s }),
            }}
            testID='join-request-date'
          >
            {moment(request.created_at).fromNow()}
          </KitText>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          ...(sizeClass === SizeClass.Small
            ? {
                marginTop: Spacing.l,
                marginLeft: 0,
                alignSelf: 'stretch',
              }
            : {
                alignSelf: 'center',
                marginLeft: Spacing.xl,
              }),
          ...(Platform.OS !== 'web' && {
            flexBasis: 300,
          }),
        }}
        testID='join-request-actions'
      >
        <KitButton
          small
          title={t('messaging:buttonTitleApprove')}
          style={{ marginRight: Spacing.m, flex: 1 }}
          color={Colors.N900}
          onPress={() => onApprove(request)}
        />
        <KitButton
          small
          title={t('messaging:buttonTitleDeny')}
          color={Colors.N100}
          secondary
          style={{ flex: 1 }}
          onPress={() => onDeny(request)}
        />
        <Show show={sizeClass === SizeClass.Small || Platform.OS !== 'web'}>
          <KitTouchableIcon
            name='action-h'
            enableScaleAnimation
            buttonSize={44}
            color={Colors.N900}
            style={{ backgroundColor: Colors.N100, marginLeft: Spacing.m }}
            underlayColor={Colors.N200}
            onPress={() => onMoreActions(request)}
          />
        </Show>
      </View>
    </View>
  );
};
