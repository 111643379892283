import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  brandingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});
