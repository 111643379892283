import pluralize from 'pluralize';

import buildParams from './BuildParams';
import { IField, IFilter, IInclude, IPage, IQuery, ISort } from './Types';

export interface IUri {
  host?: string;
  resource?: string;
  id?: string;
  queries?: IQuery[];
  filters?: IFilter[];
  includes?: IInclude[];
  fields?: IField[];
  pages?: IPage[];
  sorts?: ISort[];
  pluralizeResource?: boolean;
}

export function buildUrl({
  host,
  filters,
  queries,
  pages,
  includes,
  fields,
  sorts,
  resource = 'GET',
  id,
  pluralizeResource = true,
}: IUri): string {
  id = id ? `/${id}` : '';

  return `${host}/${
    pluralizeResource ? pluralize(resource) : resource
  }${id}${buildParams({
    filters,
    queries,
    pages,
    includes,
    fields,
    sorts,
  })}`;
}
