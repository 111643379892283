import { ComponentType } from '../../blocks/types';
import { BlockPageErrorData } from './../../types';

export const generateErrorPage = (
  error: number,
  callback?: () => void
): BlockPageErrorData => {
  return {
    blocks: [{ props: { callback, error }, type: ComponentType.Error }],
  };
};
