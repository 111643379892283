import { AuthService } from '@omni/kit/auth';
import { IMediaPlaybackStateInfo } from '@omni/kit/components/KitPlayer/playbackTypes';
import moment from 'moment';

import CancelPolling from './CancelPolling';
import HandleTimer from './HandleTimer';
import PatchProgressOnSeek from './PatchProgressOnSeek';
import PatchProgressOnStateChange from './PatchProgressOnStateChange';
import ResetPatchTimer from './ResetPatchTimer';
import Setup from './Setup';
import TrackMediaProgressState from './TrackMediaProgressState';
import UpdateMediaPlay from './UpdateMediaPlay';
import { RETRY_WAIT } from './constants';

const debug = require('debug')('tca:packages:TrackMediaProgress');

/**
 * TrackProgress is invoked every 1 second while media is playing
 * mediaPlaybackStateInfo?: IMediaPlaybackStateInfo
 * @param mediaPlaybackStateInfo {IMediaPlaybackStateInfo}
 */
export default async (
  mediaPlaybackStateInfo: IMediaPlaybackStateInfo
): Promise<void> => {
  const {
    appKey = '',
    mediaItemShortCode,
    isLiveStream,
    isSeeking,
    isPlaying,
    isComplete,
    durationMsec,
    positionMsec = 0,
  } = mediaPlaybackStateInfo;

  /** Stop tracking in case of live streams. */
  if (isLiveStream) {
    return debug('Media is live, stop tracking.');
  }

  if (isComplete) {
    debug('Media is complete');
    CancelPolling();

    if (
      TrackMediaProgressState.currentMediaItem &&
      TrackMediaProgressState.mediaPlay &&
      TrackMediaProgressState.userProfile
    ) {
      debug('Update media progress to server');
      await UpdateMediaPlay({
        appKey,
        duration: durationMsec,
        mediaItemId: TrackMediaProgressState.currentMediaItem.id,
        mediaPlayId: TrackMediaProgressState.mediaPlay.id,
        position: positionMsec,
        userId: TrackMediaProgressState.userProfile.userID,
      });
    } else {
      debug('Unable to update media progress due to missing resources', {
        currentMediaItem: TrackMediaProgressState.currentMediaItem,
        mediaPlay: TrackMediaProgressState.mediaPlay,
        userProfile: TrackMediaProgressState.userProfile,
      });
    }

    return;
  }

  /** Exit early if unauthenticated */
  if (!AuthService.accessTokenWithBearer) {
    /** Clears user profile to prompt refetch */
    TrackMediaProgressState.userProfile = null;

    /** Clears retry timer */
    TrackMediaProgressState.retryTimer = null;

    /** Update progress to server when a new user logs in */
    ResetPatchTimer(true);

    return;
  }

  /** Retry setup logic */
  if (TrackMediaProgressState.retryTimer) {
    const minutesWaited = moment
      .utc()
      .diff(TrackMediaProgressState.retryTimer, 'minutes');

    if (minutesWaited < RETRY_WAIT) {
      /** Wait 10 minutes to retry progress tracking */
      return;
    } else {
      debug('Its been 10 minutes. Time to rety progress tracking');
      TrackMediaProgressState.retryTimer = null;
    }
  }

  if (TrackMediaProgressState.isSetupInProgress) {
    return debug('setup is in progress');
  }

  /** Media item short code is missing. Disable progress tracking. */
  if (!mediaItemShortCode) {
    if (TrackMediaProgressState.currentMediaItem) {
      debug('Media item short code is missing. Reset currentMediaItem.');
      TrackMediaProgressState.currentMediaItem = null;
    }

    return;
  }

  /**  Setup progress tracking */
  try {
    TrackMediaProgressState.isSetupInProgress = true;
    await Setup(mediaPlaybackStateInfo);
  } catch (e) {
    debug('Retry initializing due to ', e);

    if (TrackMediaProgressState.retryAttempts < 3) {
      TrackMediaProgressState.retryAttempts++;
      debug('Retry attempt ', TrackMediaProgressState.retryAttempts);
    } else {
      debug(`Retry in ${RETRY_WAIT} minutes`);
      TrackMediaProgressState.retryAttempts = 0;
      TrackMediaProgressState.retryTimer = moment.utc();
    }

    return;
  } finally {
    TrackMediaProgressState.isSetupInProgress = false;
  }

  /** Ensure all resources are fetched before proceeding*/
  if (
    !TrackMediaProgressState.userProfile ||
    !TrackMediaProgressState.currentMediaItem ||
    !TrackMediaProgressState.mediaPlay
  ) {
    return;
  }

  /** Patch progress when player is paused only when not seeking */
  if (!isSeeking) {
    PatchProgressOnStateChange(isPlaying, 'wasPaused', mediaPlaybackStateInfo);
  }

  /** Patch progress when player seeked */
  PatchProgressOnSeek(mediaPlaybackStateInfo);

  /** Patch progress at least once every 5 minutes */
  HandleTimer(mediaPlaybackStateInfo);
};
