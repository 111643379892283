import React, { useMemo } from 'react';
import { View } from 'react-native';

import { DEFAULT_PAGE_SIZE } from '../services/SearchService/Constants';
import Colors from '../theming/Colors';
import Spacing from '../theming/Spacing';
import KitIcon from './KitIcon';
import KitText from './KitText';
import KitTouchable from './KitTouchable';
import KitTouchableIcon from './KitTouchableIcon';

interface IKitPaginationProps {
  handleNextPage: () => void;
  handlePageSelection: (page: number) => void;
  handlePreviousPage: () => void;
  page?: number;
  pageSize?: number;
  total: number;
}

/**
 * KitPagination
 *
 * Displays a series of numbers that represent pages of content that a user
 * can navigate to.
 *
 */
export default function KitPagination({
  handleNextPage,
  handlePageSelection,
  handlePreviousPage,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  total,
}: IKitPaginationProps): JSX.Element {
  const totalPages = useMemo(() => {
    return Math.ceil(total / pageSize);
  }, [pageSize, total]);

  const pageNumbers = useMemo(() => {
    return getPageNumbers(page, totalPages);
  }, [page, totalPages]);

  return (
    <View style={{ flexDirection: 'row' }} testID='kit-pagination'>
      <KitTouchableIcon
        buttonSize={40}
        disabled={page === 1}
        onPress={handlePreviousPage}
        name='back-ios'
        size={16}
        testID='previous-page-btn'
      />
      {pageNumbers.map((pageNum, index) => {
        if (typeof pageNum === 'number') {
          return (
            <PageNumberButton
              active={page === pageNum}
              key={index}
              page={pageNum}
              onPress={() => handlePageSelection(pageNum)}
            />
          );
        } else {
          return (
            <KitIcon
              key={index}
              name='action-h'
              color={Colors.N300}
              size={18}
              style={{ margin: Spacing.xxs, alignSelf: 'center' }}
            />
          );
        }
      })}
      <KitTouchableIcon
        buttonSize={40}
        disabled={page >= totalPages}
        name='forward-ios'
        onPress={handleNextPage}
        size={16}
        testID='next-page-btn'
      />
    </View>
  );
}

/**
 * Lifted from https://subsplash.io/ember/kit/-/blob/main/addon/components/kit-pagination.js
 */
const getPageNumbers = function (
  currentPage: number,
  totalPages: number,
  isCompact?: boolean
) {
  /**
   * Determines how many pages will be shown at the start or end of the
   * rendered output. For example:
   *
   * buffer = 3:
   * `(1),2,3,...,6`
   *
   * buffer = 5:
   * `1,...,5,6,7,8,(9)`;
   */
  const buffer = isCompact ? 3 : 5;

  /** the max numbers of pages to show before truncating */
  const maxPages = isCompact ? 5 : 7;

  /** checks if the currentPage is within the buffer from the end */
  const withinBufferFromEnd =
    currentPage > totalPages - buffer + (isCompact ? 0 : 1);

  /** checks if the currentPage is within the buffer from the start */
  const withinBufferFromStart = currentPage < buffer + (isCompact ? 1 : 0);

  /** builds an array of all the page numbers (before truncation) */
  const pageNumbers: Array<number | string> = totalPages
    ? new Array(totalPages).fill(null).map((i, idx) => idx + 1)
    : [];

  /**
   * If the total number of pages is greater than the number of pages
   * allowed, run the truncation logic.
   */
  if (totalPages > maxPages) {
    if (withinBufferFromEnd && !withinBufferFromStart) {
      /** trim from the start of the array up until the end buffer. */
      pageNumbers.splice(0, pageNumbers.length - buffer, 1, '...');
    } else if (withinBufferFromStart && !withinBufferFromEnd) {
      /** trim from the end of the start buffer to the end of the array */
      pageNumbers.splice(
        buffer,
        pageNumbers.length - buffer,
        '...',
        totalPages
      );
    } else {
      /**
       * One of the following scenarios is true:
       *
       * 1.the currentPage is not within the start or the end buffer,
       * meaning it is in the middle of a larger page range and the page
       * array needs to be truncated on both sides.
       *
       * 2. the currentPage is within both the start and the end buffer,
       * meaning it is in the middle of a shorter page range and still needs
       * to be truncated on both sides of the array to match the intended
       * behavior.
       */
      pageNumbers.splice(
        0,
        pageNumbers.indexOf(currentPage) - (isCompact ? 0 : 1),
        1,
        '...'
      );
      pageNumbers.splice(
        pageNumbers.indexOf(currentPage) + 1 + (isCompact ? 0 : 1),
        pageNumbers.length,
        '...',
        totalPages
      );
    }
  }

  return pageNumbers;
};

const PageNumberButton = ({
  active,
  onPress,
  page,
}: {
  active?: boolean;
  onPress?: (page: number) => void;
  page: string | number;
}) => {
  return (
    <KitTouchable
      onPress={
        active || typeof page !== 'number' ? undefined : () => onPress?.(page)
      }
      style={{
        width: 40,
        height: 40,
        marginHorizontal: Spacing.xxs,
        borderRadius: 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: active ? Colors.N900 : undefined,
      }}
      testID='page-number-btn'
    >
      <KitText color={active ? Colors.N0 : Colors.N800} semiBold>
        {page}
      </KitText>
    </KitTouchable>
  );
};
