import { Cache } from 'react-native-cache';
import defaultCache from './DefaultCache';

const debug = require('debug')('tca:kit:services:CacheService:saveToCache');

export default async function saveToCache(
  key: string,
  value: string,
  cache?: Cache
): Promise<void> {
  try {
    cache = cache || defaultCache;
    await cache.set(key, value);
    debug(`Data for ${key} saved to cache`);
  } catch (e) {
    debug(e);
  }
}
