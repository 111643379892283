import { useShellContext } from '@omni/kit';
import { KitLink, KitText } from '@omni/kit/components';
import { KitListItem } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import Show from '@omni/kit/components/Show';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { useSnackbarContext } from '@omni/kit/contexts/Snackbar';
import PeopleService from '@omni/kit/services/PeopleService';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { createImageUrl } from '@omni/kit/utilities/utilities';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { useKioskContext } from '../contexts/KioskContext';
import AllergyNotesInput from './AllergyNotesInput';
import CareNotesInput from './CareNotesInput';
import FramelessButton from './FramelessButton';
import KioskModal from './KioskModal';
import KioskModalButton from './KioskModalButton';

type Props = {
  profile: IProfile;
  session?: string;
  isVisible: boolean;
  refreshData?: () => void;
  onRemoveProfile?: () => void;
  onEditProfile?: () => void;
  onEditSession?: () => void;
  onClose: () => void;
  showSession: boolean;
};

export default function ChildDetailModal({
  profile,
  session,
  isVisible,
  refreshData,
  onClose,
  onRemoveProfile,
  onEditProfile,
  onEditSession,
  showSession,
}: Props): JSX.Element {
  const { app } = useShellContext();
  const { token } = useKioskContext();
  const { showSnackbar } = useSnackbarContext();
  const { t } = useTranslation(['check-in', 'profile']);
  const { windowHeight, windowWidth } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;

  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({
    allergyNotes: profile.allergy_notes || '',
    careNotes: profile.care_notes || '',
  });

  const hasChanges =
    formData.allergyNotes !== (profile.allergy_notes || '') ||
    formData.careNotes !== (profile.care_notes || '');

  const profileFullName = `${profile.first_name} ${profile.last_name}`;

  const handleFieldChange = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const updateProfile = async () => {
    setIsUpdating(true);

    try {
      await PeopleService.UpdateProfile({
        id: profile.id,
        body: {
          org_key: app.orgKey,
          first_name: profile.first_name,
          last_name: profile.last_name,
          allergy_notes: formData.allergyNotes,
          care_notes: formData.careNotes,
        },
        token,
      });
      onClose();
      refreshData?.();
    } catch (error) {
      showSnackbar(t('check-in:textErrorUnableToSave'));
    } finally {
      setIsUpdating(false);
    }
  };

  const Wrapper = isLandscape ? KeyboardAwareScrollView : View;

  return (
    <KioskModal
      isVisible={isVisible}
      onClose={onClose}
      title={t('profile:textProfile')}
      width={600}
      smallContent
      enableScroll
      footer={
        <View style={styles.footerContainer}>
          <FramelessButton onPress={onRemoveProfile}>
            <KitLink
              fontSize={20}
              style={{
                color: Colors.N900,
                fontWeight: 'bold',
              }}
            >
              Remove
            </KitLink>
          </FramelessButton>
          <View style={{ flexDirection: 'row' }}>
            <KioskModalButton
              secondary
              style={{ marginRight: Spacing.l }}
              title={t('common:buttonTitleCancel')}
              onPress={onClose}
            />
            <KioskModalButton
              disabled={isUpdating || !hasChanges}
              isLoading={isUpdating}
              title={t('common:buttonTitleSave')}
              onPress={updateProfile}
            />
          </View>
        </View>
      }
    >
      <Wrapper extraHeight={200} viewIsInsideTabBar={true}>
        {/* Avatar and name */}
        <KitListItem
          title={profileFullName}
          titleFontSize={18}
          ImageComponent={
            <KitAvatar
              nickname={profileFullName}
              imageUrl={createImageUrl(profile._embedded?.photo?.id)}
              size={44}
            />
          }
          rightIcon='edit'
          onPress={onEditProfile}
          iconColor={Colors.N300}
          iconSize={20}
          minHeight={74}
          style={{ marginBottom: 16 }}
          topBorder
        />

        {/* Session */}
        <Show show={showSession}>
          <>
            <KitText color={Colors.N900} fontSize={16} bold lineHeight={18}>
              {t('check-in:textSession')}
            </KitText>
            <KitListItem
              title={session ? session : t('check-in:textNoSession')}
              titleFontSize={18}
              rightIcon='edit'
              onPress={onEditSession}
              iconColor={Colors.N300}
              iconSize={20}
              minHeight={74}
              style={{ marginBottom: 16 }}
            />
          </>
        </Show>
        {/* Allergies (optional) */}
        <AllergyNotesInput
          value={formData.allergyNotes}
          labelStyle={styles.labelStyle}
          onChangeText={(text) => handleFieldChange('allergyNotes', text)}
        />

        {/* Care Notes (optional) */}
        <CareNotesInput
          value={formData.careNotes}
          labelStyle={styles.labelStyle}
          onChangeText={(text) => handleFieldChange('careNotes', text)}
        />
      </Wrapper>
    </KioskModal>
  );
}

const styles = StyleSheet.create({
  labelStyle: {
    color: Colors.N900,
    fontWeight: 'bold',
    fontSize: 16,
  },
  footerContainer: {
    paddingHorizontal: Spacing.xl,
    paddingVertical: Spacing.xl,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.N0,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
  },
});
