import React from 'react';
import { StyleSheet, View } from 'react-native';

import Colors from '../theming/Colors';
import Spacing from '../theming/Spacing';
import { KitButton, KitText } from './index';

export type ConfirmModalProps = {
  title: string;
  message: string;
  cancelButtonText: string;
  confirmButtonText: string;
  setConfirmModalVisible: (visible: boolean) => void;
  onConfirm: (action: string) => void;
  action: string;
};

export default function ConfirmModal({
  title,
  message,
  cancelButtonText,
  confirmButtonText,
  setConfirmModalVisible,
  onConfirm,
  action,
}: ConfirmModalProps): JSX.Element {
  return (
    <View style={styles.modalContainer}>
      <View style={styles.modalBody}>
        <View>
          <KitText black bold fontSize={20} style={{ marginBottom: Spacing.l }}>
            {title}
          </KitText>
          {message && (
            <KitText black style={{ marginBottom: Spacing.xl }}>
              {message}
            </KitText>
          )}
        </View>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: Spacing.m,
          }}
        >
          <KitButton
            testID='DismissButton'
            secondary
            title={cancelButtonText}
            marginValue='0 12px'
            onPress={() => setConfirmModalVisible(false)}
          />
          <KitButton
            testID='ConfirmButton'
            red
            title={confirmButtonText}
            onPress={() => onConfirm(action)}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: '#00000040',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: -200,
  },
  modalBody: {
    width: 500,
    backgroundColor: Colors.N0,
    borderRadius: 12,
    paddingTop: Spacing.xl,
    paddingHorizontal: Spacing.xl,
    justifyContent: 'space-between',
  },
});
