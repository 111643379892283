import React, { useContext } from 'react';
import {
  Animated,
  Platform,
  TextStyle,
  TouchableNativeFeedback,
  View,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';

import { animatePressIn, animatePressOut } from '../../theming/Animating';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { ThemeContext } from '../../theming/ThemeContext';
import KitIcon, { IconName } from '../KitIcon';
import KitLoader from '../KitLoader';
import KitText from '../KitText';

const defaultIconSize = 20;
const DEFAULT_HEIGHT = 44;

//******************************************************************************
// Types
//******************************************************************************
export interface IProps {
  circle?: boolean;
  onPress?: () => void;
  title?: string;
  disabled?: boolean;
  isLoading?: boolean;
  secondary?: boolean;
  marginValue?: string;
  color?: string;
  borderRadius?: number;
  height?: number;
  iconColor?: string;
  textColor?: string;
  icon?: IconName;
  iconSize?: number;
  red?: boolean;
  white?: boolean;
  style?: ViewStyle;
  titleStyle?: TextStyle;
  small?: boolean;
  testID?: string;
  paddingLeft?: number;
  paddingRight?: number;
  underlayColor?: string;
}

interface IButtonWrapperStyle {
  backgroundColor: string;
  color: string;
}

export default function KitButton({
  circle = false,
  color = undefined,
  iconColor = undefined,
  textColor = undefined,
  disabled = false,
  height,
  borderRadius,
  icon = undefined,
  iconSize = defaultIconSize,
  isLoading = false,
  marginValue = '0px',
  onPress,
  red = false,
  secondary = false,
  small = false,
  style = {},
  testID = '',
  title = '',
  titleStyle = {},
  white = false,
  paddingLeft,
  paddingRight,
  underlayColor,
}: IProps): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);

  const scale = new Animated.Value(1);

  const onPressIn = () => {
    animatePressIn(scale);
  };

  const onPressOut = () => {
    animatePressOut(scale);
  };

  const SharedButton = ({
    children,
  }: {
    children: JSX.Element | JSX.Element[] | undefined;
  }) => (
    <Animated.View
      style={[
        { transform: [{ scale: scale }], ...style },
        small &&
          Platform.select({
            native: { width: 'auto' },
            web: { alignSelf: 'flex-start' },
          }),
      ]}
    >
      <SharedButtonWrapper
        borderRadius={borderRadius}
        disabled={disabled}
        onPress={onPress}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        underlayColor={underlayColor || '#000'}
        marginValue={marginValue}
        delayPressIn={0}
        testID={testID || title}
      >
        {children}
      </SharedButtonWrapper>
    </Animated.View>
  );

  const AndroidButton = ({
    children,
  }: {
    children: JSX.Element | JSX.Element[] | undefined;
  }) => (
    <Animated.View
      style={[
        { transform: [{ scale: scale }], ...style },
        small && { alignSelf: 'flex-start' },
      ]}
    >
      <AndroidButtonWrapper secondary={secondary} marginValue={marginValue}>
        <TouchableNativeFeedback
          disabled={disabled}
          onPress={onPress}
          onPressIn={onPressIn}
          onPressOut={onPressOut}
          useForeground={true}
          delayPressIn={0}
          testID={testID || title}
        >
          {children}
        </TouchableNativeFeedback>
      </AndroidButtonWrapper>
    </Animated.View>
  );

  const _getBackgroundColor = () => {
    if (color) {
      return color;
    } else if (secondary) {
      return (
        colorForScheme?.({ dark: Colors.N900, light: Colors.N100 }) ||
        Colors.N100
      );
    } else if (red) {
      return Colors.R500;
    } else if (white) {
      return Colors.N0;
    } else {
      return colorForScheme?.({ default: Colors.brand }) || Colors.brand;
    }
  };

  const _getButtonTextColor = () => {
    if (white) {
      return Colors.N900;
    }

    if (textColor) {
      return textColor;
    }

    if (secondary) {
      return disabled
        ? 'rgba(0,0,0,0.4)'
        : colorForScheme?.({ light: Colors.N900, dark: Colors.N100 }) ||
            Colors.N900;
    } else {
      return disabled ? 'rgba(255,255,255,0.4)' : Colors.N0;
    }
  };

  const Button: any = Platform.select({
    ios: SharedButton,
    android: AndroidButton,
    web: SharedButton,
  });

  const themedStyle: IButtonWrapperStyle = {
    backgroundColor: _getBackgroundColor(),
    color: _getButtonTextColor(),
  };

  return (
    <Button>
      <InnerButton
        backgroundColor={themedStyle.backgroundColor}
        borderRadius={borderRadius}
        iconColor={iconColor}
        color={themedStyle.color}
        disabled={disabled}
        height={height}
        icon={icon}
        iconSize={iconSize}
        isLoading={isLoading}
        secondary={secondary}
        title={title}
        titleStyle={titleStyle}
        small={small}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        circle={circle}
      />
    </Button>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const SharedButtonWrapper = styled.TouchableHighlight`
  border-radius: ${({ borderRadius }: IProps) =>
    borderRadius ? borderRadius : 27};
  margin: ${({ marginValue }: IProps) => marginValue};
`;

const AndroidButtonWrapper = styled.View`
  border-radius: ${({ borderRadius }: IProps) =>
    borderRadius ? borderRadius : 27};
  margin: ${({ marginValue }: IProps) => marginValue};
  ${({ secondary }: IProps) => (secondary ? '' : 'elevation: 3;')}
  overflow: hidden;
`;

const InnerButtonWrapper = styled.View`
  height: ${({ height }: InnerButtonProps) =>
    height ? height : DEFAULT_HEIGHT};
  width: ${({ circle, height }: InnerButtonProps) =>
    circle ? height || DEFAULT_HEIGHT : '100%'};
  border-radius: ${({ borderRadius }: InnerButtonProps) =>
    borderRadius ? borderRadius : 27};
  justify-content: center;
  align-items: center;
  opacity: ${({ disabled }: InnerButtonProps) => (disabled ? 0.8 : 1)};
  background-color: ${({ backgroundColor }: InnerButtonProps) =>
    backgroundColor};
  padding-left: ${({ paddingLeft }: InnerButtonProps) =>
    paddingLeft ?? Spacing.xl};
  padding-right: ${({ paddingRight }: InnerButtonProps) =>
    paddingRight ?? Spacing.xl};

  /* iOS shadow */
  ${({ secondary }: InnerButtonProps) =>
    !secondary ? 'box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);' : ''};
`;

interface InnerButtonProps {
  circle?: boolean;
  backgroundColor: string;
  borderRadius?: number;
  color?: string;
  iconColor?: string;
  disabled?: boolean;
  height?: number;
  icon?: IconName;
  iconSize?: number;
  isLoading?: boolean;
  secondary?: boolean;
  small?: boolean;
  title?: string;
  titleStyle?: TextStyle;
  paddingLeft?: number;
  paddingRight?: number;
}

const getInnerButtonStyle = (props: InnerButtonProps) => {
  const innerButtonStyle: { [key: string]: string } = {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    ...(props.circle ? { width: '100%' } : { width: 'auto', maxWidth: '100%' }),
  };

  // if the icon isn't the default size, center it vertically with the text
  return !props.iconSize || props.iconSize === defaultIconSize
    ? innerButtonStyle
    : { ...innerButtonStyle, alignItems: 'center' };
};

const InnerButton = (props: InnerButtonProps) => (
  <InnerButtonWrapper {...props}>
    {props.isLoading && <KitLoader small color={props.color} />}
    {!props.isLoading && (
      <View style={getInnerButtonStyle(props)}>
        {Boolean(props.icon) && (
          <KitIcon
            style={props.title ? { marginRight: Spacing.s } : undefined}
            name={props.icon}
            size={props.iconSize}
            color={props.iconColor || props.color}
          />
        )}
        <KitText
          semiBold
          fontSize={Platform.OS === 'web' ? 14 : 16}
          center
          color={props.color}
          numberOfLines={1}
          style={props.titleStyle}
        >
          {props.title}
        </KitText>
      </View>
    )}
  </InnerButtonWrapper>
);
