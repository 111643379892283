import { KitIcon, KitText, KitTouchable } from '@omni/kit/components';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import {
  channelSelector,
  userSelector,
} from '../../../../../shared/redux/selectors';

import Colors from '@omni/kit/theming/Colors';
import KitCheckmark from '@omni/kit/components/KitCheckmark';
import { POLL_RESULTS_MODAL } from '../../../../../shared/Constants';
import React from 'react';
import { Reaction } from '@sendbird/chat/message';
import { SendbirdUserMessage } from '../../../../Types';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { sbToggleReaction } from '../../../../../utilities/sendbird/chatFunctions';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const debug = require('debug')('tca:chat:components:PollMessageBubble');

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  message: SendbirdUserMessage;
  handleNavToResults?: (resultCount: number) => void;
  onPress?: () => void;
  onLongPress?: () => void;
  disabled?: boolean;
}

//******************************************************************************
// Component
//******************************************************************************

const PollMessageBubble = ({
  message,
  handleNavToResults = (_resultCount: number) => null,
  onPress = () => null,
  onLongPress = () => null,
  disabled,
}: IProps) => {
  const user = useSelector(userSelector);
  const channel = useSelector(channelSelector);
  const { sizeClass } = useSizeClass();
  const navigation = useNavigation();

  const question = message.message;
  const data = message.data!;

  let resultCount = 0;

  const _renderOptions = () => {
    const options = JSON.parse(data).options;

    // @ts-ignore
    return options?.map((option, index: number) =>
      _renderPollOption(option, index)
    );
  };

  const _onToggle = (index: number) => {
    sbToggleReaction(channel!, message, `{poll}-{${index}}`, user!.userId);
  };

  const navToResults = () => {
    if (Platform.OS === 'web' && sizeClass !== SizeClass.Small) {
      handleNavToResults(resultCount);
    } else {
      navigation.navigate(POLL_RESULTS_MODAL, {
        message: message,
        resultCount: resultCount,
        options: JSON.parse(data).options,
      });
    }
  };

  const _renderResults = () => {
    return (
      <KitTouchable
        disabled={resultCount < 1}
        onPress={!disabled ? navToResults : undefined}
        style={{
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
        touchableStyle={{
          borderRadius: 0,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <View
          style={{
            margin: Spacing.m,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <KitText
            color={resultCount > 0 ? Colors.brand : Colors.N500}
            fontSize={14}
          >
            {resultCount > 0
              ? `View responses (${resultCount})`
              : 'No responses yet'}
          </KitText>
        </View>
      </KitTouchable>
    );
  };

  const _renderPollOption = (option: string, index: number) => {
    // type-index-name -- {poll}-{2}
    const keyRegex = /\{([^{}]*)\}/g;
    const reaction = message.reactions?.filter((reaction) => {
      const result = Array.from(reaction.key.matchAll(keyRegex), ([_, g]) => g);

      if (result && result?.length >= 2) {
        // we know this is a validly formatted reaction data block
        return result[1] === index.toString();
      }
    })[0];

    if (reaction) {
      resultCount += reaction?.userIds.length;
    }

    if (!option) {
      return null;
    }

    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          borderBottomColor: Colors.N100,
          borderBottomWidth: 1,
          paddingVertical: Spacing.xs,
        }}
      >
        <View
          style={{ marginLeft: -Spacing.s }}
          pointerEvents={!disabled ? 'auto' : 'none'}
        >
          <KitCheckmark
            checked={
              reaction !== undefined && reaction?.userIds.includes(user!.userId)
            }
            onToggle={() => _onToggle(index)}
          />
        </View>
        <View
          style={{
            flex: 1,
            marginHorizontal: Spacing.xs,
            justifyContent: 'center',
          }}
        >
          <KitText black>{option}</KitText>
          {reaction && reaction?.userIds.length > 0 && (
            <KitText semiBold fontSize={12}>
              {reaction?.userIds.length} votes
            </KitText>
          )}
        </View>
      </View>
    );
  };

  return (
    <View
      style={[
        styles.container,
        {
          maxWidth:
            sizeClass === SizeClass.Large
              ? '65%'
              : sizeClass === SizeClass.Medium
              ? '75%'
              : '100%',
        },
      ]}
    >
      <TouchableOpacity
        disabled={Platform.OS === 'web'}
        activeOpacity={0.5}
        onPress={onPress}
        onLongPress={onLongPress}
        delayLongPress={200}
        style={styles.topContainer}
      >
        <View style={{ width: 16, height: 16, marginRight: Spacing.m }}>
          <KitIcon name='poll' size={16} color={Colors.N1000} />
        </View>
        <View style={{ maxWidth: '92%' }}>
          <KitText color={Colors.N900} bold fontSize={14}>
            {question}
          </KitText>
        </View>
      </TouchableOpacity>
      <View style={styles.bottomContainer}>
        <View style={styles.optionsContainer}>{_renderOptions()}</View>
        {_renderResults()}
      </View>
    </View>
  );
};

export { PollMessageBubble };

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    minWidth: 250,
    borderRadius: 20,
    borderColor: Colors.N100,
  },
  topContainer: {
    padding: Spacing.m,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: colorForScheme({ default: Colors.N100 }),
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomContainer: {
    borderColor: Colors.N100,
    borderWidth: 1,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: Colors.N0,
  },
  optionsContainer: {
    paddingHorizontal: Spacing.m,
  },
});
