import { isUrlAuthorizedForContentDomain } from '@omni/kit/auth';
import {
  Linking,
  NativeEventEmitter,
  NativeModules,
  Platform,
  Share,
  ShareContent,
} from 'react-native';

import { BridgeAction } from './Types';
import { dispatchAction, getNativeDebugInfo } from './utilities/NativeHelpers';
import { MapAction, getMapLink } from './utilities/maps';

const { ReactPlatformBridge } = NativeModules;

const debug = require('debug')('tca:kit:ActionHandler');

export const dispatch = async (
  action: BridgeAction | null | undefined,
  callback?: (event: any) => void
): Promise<void> => {
  if (!action) {
    return;
  }

  const { handler, command } = action;
  switch (handler) {
    case 'playlist': {
      if (command === 'download') {
        if (ReactPlatformBridge != null) {
          const ReactPlatformBridgeEventEmitter = new NativeEventEmitter(
            ReactPlatformBridge
          );
          ReactPlatformBridgeEventEmitter.addListener(
            'DownloadStatusChanged',
            (event) => callback?.(event)
          );
        }

        dispatchAction(action);

        return;
      }

      break;
    }
    case 'map': {
      getMapLink(action as MapAction).then((mapUrl) => {
        if (mapUrl) {
          Linking.openURL(mapUrl);
        }
      });
      break;
    }
    case 'share': {
      const url = action.shareUrl;
      const body = action.shareBody;

      const shareAction: ShareContent & { message?: string } = {
        title: body,
        url: url,
      };

      if (Platform.OS === 'android') {
        shareAction.message = `${body}\n${url}`;
      }

      Share.share(shareAction).then(() => {
        debug('share completed');
      });

      return;
    }
    default:
      dispatchAction(action);
  }
};
