import { ICheckIn } from '../services/CheckInService/Types';

export function getFilteredCheckIns(
  checkIns?: ICheckIn[],
  checkOuts?: ICheckIn[]
): {
  filteredCheckIns: ICheckIn[];
  filteredCheckOuts: ICheckIn[];
} {
  const filteredCheckIns =
    checkIns?.filter((checkIn) => {
      const matchingCheckOutExists = checkOuts?.some((checkOut) => {
        return (
          checkIn._embedded['end-user'].id ===
            checkOut._embedded['end-user'].id &&
          checkIn.label_code === checkOut.label_code
        );
      });

      return !matchingCheckOutExists;
    }) || [];

  const filteredCheckOuts =
    checkOuts?.filter((checkOut) => {
      const areThereLaterCheckInsForSameUser = checkIns?.some((checkIn) => {
        return (
          checkIn._embedded['end-user'].id ===
            checkOut._embedded['end-user'].id &&
          checkIn.created_at > checkOut.created_at
        );
      });

      return !areThereLaterCheckInsForSameUser;
    }) || [];

  return { filteredCheckIns, filteredCheckOuts };
}
