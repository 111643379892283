import React from 'react';
import { Platform, View, ViewStyle } from 'react-native';

import Spacing from '../../theming/Spacing';
import KitIcon from '../KitIcon';
import { defaultStyles } from './styles';

export default function Icon({
  containerStyle,
}: {
  containerStyle?: ViewStyle | ViewStyle[];
}): JSX.Element {
  return (
    <View testID='icon_container' style={containerStyle}>
      <KitIcon name='close' size={14} />
    </View>
  );
}
