import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';

import Environment from '../../../Environment';
import { IGroupMembers } from '../Types';
import TokensService from '@omni/kit/services/TokensService';

const RESOURCE = 'group-member';

interface IGetGroupMembers extends Omit<IUri, 'resource'> {
  id: string;
  role: string;
}

interface IGetGroupMembersResponse extends IHttpResponse {
  body?: IGroupMembers;
}

export default async ({
  id,
  role,
}: IGetGroupMembers): Promise<IGetGroupMembersResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    filters: [
      ['group.id', id],
      ['role', role],
    ],
    includes: ['end-user,', 'end-user.profiles', 'end.user.profiles.image'],
    resource: RESOURCE,
  });

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({ url, token: guestToken, skipTimestamp: true });
};
