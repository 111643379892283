import { KitIcon, KitListItem, KitText } from '@omni/kit/components';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import { SizeClassV2, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { differenceBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  FlatList,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { ChannelMember, IInvited } from '../../../mobile/Types';
import InvitedListRow from '../../../mobile/components/channelDetails/InvitedListRow';
import MemberActionSheet from '../../../mobile/components/channelDetails/MemberActionSheet';
import MemberListRow from '../../../mobile/components/channelDetails/MemberListRow';
import * as Constants from '../../Constants';
import {
  setAddedUsers,
  setBlockedUser,
  setRemovedMember,
  setUnblockedUser,
} from '../../redux/actions/ChatActions';
import { hideChannel } from '../../redux/actions/SystemActions';
import {
  addedUsersSelector,
  blockedUserListSelector,
  blockedUserSelector,
  channelSelector,
  invitedListSelector,
  memberListSelector,
  ownerListSelector,
  removedUserSelector,
  userSelector,
} from '../../redux/selectors';
import { ChannelType } from '../../redux/types';

export default function MemberListScreen({
  navigation,
  route,
  isOpenMemberListModal,
  setMemberListModal,
  setOpenInviteScreenModal,
}: {
  navigation: any;
  route: any;
  isOpenMemberListModal?: boolean;
  setMemberListModal?: (open: boolean) => void;
  setOpenInviteScreenModal?: (open: boolean) => void;
}): JSX.Element | null {
  const { showManagers, allowInvites } = route.params;

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ChannelMember | null>(null);
  const [isSelectedUserOwner, setIsSelectedUserOwner] = useState(false);
  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
  });

  const channel = useSelector(channelSelector) || ({} as GroupChannel);

  const ownerList = useSelector(ownerListSelector);
  const memberList = useSelector(memberListSelector);
  const invitedList = useSelector(invitedListSelector);
  const blockedList = useSelector(blockedUserListSelector);

  const currentUser = useSelector(userSelector);
  const blockedUser = useSelector(blockedUserSelector);
  const unblockedUser = useSelector(blockedUserSelector);
  const addedUsers = useSelector(addedUsersSelector);
  const removedUser = useSelector(removedUserSelector);

  const dispatch = useDispatch();

  const isDirect = channel.customType === ChannelType.Direct;
  const members: ChannelMember[] = isDirect
    ? differenceBy(memberList, blockedList, 'userId')
    : differenceBy(memberList, ownerList, 'userId').filter(
        (m) => m.role !== 'operator'
      );
  const { sizeClassV2 } = useSizeClass();
  const isLarge = sizeClassV2 >= SizeClassV2.L;

  const _toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const _onMemberEditPress = (member: ChannelMember) => {
    setSelectedUser(member);
    setIsSelectedUserOwner(false);
    _toggleModal();
  };

  const _onOwnerEditPress = (member: ChannelMember) => {
    setSelectedUser(member);
    setIsSelectedUserOwner(true);
    _toggleModal();
  };

  const isManager = (member: ChannelMember): boolean => {
    return ownerList.some((m) => m.userId === member.userId);
  };

  // @ts-ignore
  const renderItem = ({ item: member }) => {
    const onPress = () =>
      isManager(member)
        ? _onOwnerEditPress(member)
        : _onMemberEditPress(member);

    return (
      <MemberListRow
        key={member.userId}
        member={member}
        channel={channel}
        onMemberEditPress={onPress}
        isManager={isManager(member)}
      />
    );
  };

  const renderInvite = ({ item }: { item: IInvited }) => {
    const invite = item;

    return <InvitedListRow key={invite.timestamp} invited={invite} />;
  };

  const showKitSnack = (msg: string, dur: number) => {
    setSnackOptions({ visible: true, message: msg, duration: dur });
  };

  useEffect(() => {
    if (removedUser) {
      showKitSnack(`${removedUser.nickname} removed.`, KitSnackDuration.SHORT);
      dispatch(setRemovedMember(null));
    }
  }, [removedUser]);

  useEffect(() => {
    if (addedUsers) {
      // @ts-ignore
      showKitSnack(addedUsers, KitSnackDuration.LONG);
      dispatch(setAddedUsers(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedUsers]);

  useEffect(() => {
    if (blockedUser) {
      showKitSnack(`${blockedUser.nickname} blocked`, KitSnackDuration.SHORT);
      dispatch(setBlockedUser(null));

      // By design, when all participants have been blocked, we will hide the channel.
      // The channel will be auto unhidden if one of the members is unblocked and they send a new message.
      const remainingMembers = memberList.filter(
        (member) =>
          member &&
          !member.isBlockedByMe &&
          member.userId !== blockedUser.userId &&
          member.userId !== currentUser?.userId
      );

      if (!remainingMembers || remainingMembers.length <= 0) {
        dispatch(hideChannel(channel));
        navigation.navigate(Constants.CHANNEL_LIST_SCREEN);
      }
    }
  }, [blockedUser]);

  useEffect(() => {
    if (unblockedUser) {
      showKitSnack(
        `${unblockedUser.nickname} unblocked`,
        KitSnackDuration.SHORT
      );
      dispatch(setUnblockedUser(null));
    }
  }, [unblockedUser]);

  return (
    <View style={{ flex: 1, backgroundColor: Colors.N0 }}>
      {/* Open this header only if it is Platform.OS==='web' */}
      {isOpenMemberListModal && (
        <View style={styles.headerContainer}>
          <KitText color={Colors.N900} style={styles.title}>
            {route.params.title}
          </KitText>
          <Pressable
            onPress={() => {
              if (setMemberListModal) {
                setMemberListModal(false);
              }
            }}
          >
            <KitIcon name='remove' size={20} color={Colors.N300} />
          </Pressable>
        </View>
      )}
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: isLarge ? 0 : Spacing.l,
        }}
        showsVerticalScrollIndicator
        scrollEnabled
      >
        {!showManagers
          ? allowInvites && (
              <KitListItem
                title='Add people'
                subtitle='by name, phone number, or email'
                topBorder={false}
                ImageComponent={
                  <View
                    style={{
                      backgroundColor: Colors.N900,
                      padding: 8,
                      borderRadius: 8,
                      width: 24,
                      height: 24,
                      marginHorizontal: 6,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <KitIcon name='add-s' size={12} color='white' />
                  </View>
                }
                onPress={() => {
                  if (isOpenMemberListModal && setOpenInviteScreenModal) {
                    setOpenInviteScreenModal(true);
                  } else {
                    navigation.navigate(Constants.INVITE_SCREEN, {
                      channel,
                    });
                  }
                }}
                rightIcon=''
              />
            )
          : null}
        <FlatList
          scrollEnabled={false}
          data={ownerList.concat(members)}
          renderItem={renderItem}
          showsVerticalScrollIndicator
        />
        {!showManagers && invitedList && invitedList.length > 0 && (
          <View>
            <KitText
              semiBold
              color={Colors.N900}
              fontSize={16}
              style={styles.invitedLabelText}
            >
              Invited people
            </KitText>
            <FlatList
              scrollEnabled={false}
              data={invitedList}
              renderItem={renderInvite}
            />
          </View>
        )}
      </ScrollView>
      <MemberActionSheet
        visible={modalVisible}
        setVisible={setModalVisible}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        isSelectedUserOwner={isSelectedUserOwner}
        isSelectedUserBlocked={selectedUser?.isBlockedByMe ?? false}
        enableChannelActions={channel.customType !== ChannelType.Direct}
        enableBlockUserActions={channel.customType === ChannelType.Direct}
        showKitSnack={showKitSnack}
      />
      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    borderRadius: 18,
    height: 36,
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.brand,
  },
  titleLabelText: {
    marginBottom: Spacing.m,
  },
  invitedLabelText: {
    marginTop: Spacing.l,
    marginBottom: Spacing.s,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    marginBottom: 20,
    marginTop: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});
