import React from 'react';
import { View, ViewStyle, useWindowDimensions } from 'react-native';

import { getMaxWidth, getPadding } from './utilities';

interface IProps {
  size: 's' | 'm' | 'l';
  style?: ViewStyle;
  children?: React.ReactNode;
  noFlex?: boolean;
}

export default function KitContainer({
  size = 'm',
  style = {},
  children,
  noFlex,
}: IProps): JSX.Element {
  const { width } = useWindowDimensions();

  const paddingHorizontal = getPadding(size, width);

  /**
   * RN sizes boxes with "border-box", so we need to add the padding to the
   * width to size container properly.
   */
  const maxWidth = getMaxWidth(size) + paddingHorizontal * 2;

  return (
    <View
      style={{
        alignItems: 'center',
        flex: noFlex ? undefined : 1,
      }}
    >
      <View
        style={{
          ...style,
          paddingHorizontal,
          maxWidth,
          width: '100%',
        }}
      >
        {children}
      </View>
    </View>
  );
}
