import i18n from '@omni/kit/localization';

// keys
export const KEY_LOCATION_QUERY_DESCRIPTION = 'location-query-description';

// values
export const VALUE_CURRENT_LOCATION: string = i18n.t('common:currentLocation');
export const VALUE_CURRENT_LOCATION_ERROR: string = i18n.t(
  'common:currentLocationError'
);

// context keys for localization purposes
export const CONTEXT_MEDIA = 'media';
export const CONTEXT_GROUPS = 'groups';

// search domains
export const DOMAIN_APPS = 'apps';
export const DOMAIN_LIBRARY = 'library';
export const DOMAIN_GROUPS = 'groups';

// commands
export const COMMAND_NAVIGATE_SEARCH = 'navigate_search';
export const COMMAND_NAVIGATE_BROWSE = 'navigate_browse';
export const COMMAND_NAVIGATE_RESULT = 'navigate_result';
export const COMMAND_NAVIGATE_LIST = 'navigate_list';
