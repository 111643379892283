import { BlockPageApp } from '@omni/blocks';
import { KitButton, KitText } from '@omni/kit/components';
import KitIcon from '@omni/kit/components/KitIcon';
import * as Icons from '@omni/kit/img';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { NativeTopBarStyle } from '@omni/kit/utilities/NativeHelpers';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import {
  Animated,
  Dimensions,
  SafeAreaView,
  StyleSheet,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';

import { appKeySelector } from '../../shared/redux/selectors';
import {
  saveIntroPresentedToDevice,
  saveOmniVersion,
} from '../../utilities/mobileStorage';

const whatsNew = require('@omni/blocks/templates/examples/whatsNew.json');

const debug = require('debug')('tca:chat:screen:IntroScreen');

//******************************************************************************
// Types
//******************************************************************************
interface IIntroProps {
  navigation: StackNavigationProp<any, any>;
}

//******************************************************************************
// Component
//******************************************************************************

export default function IntroScreen({ navigation }: IIntroProps): JSX.Element {
  const _onButtonPress = () => {
    saveIntroPresentedToDevice(true);
    saveOmniVersion();
    navigation.goBack();
  };

  return (
    <SafeAreaView style={styles.container}>
      <WhatsNewBlock onContinuePress={_onButtonPress} />
    </SafeAreaView>
  );
}

const WhatsNewBlock = ({
  onContinuePress,
}: {
  onContinuePress: () => void;
}) => {
  const appKey = useSelector(appKeySelector);

  return (
    <>
      <BlockPageApp
        appKey={appKey}
        data={whatsNew}
        theme='light'
        topBarStyle={NativeTopBarStyle.Default}
      />
      <KitButton
        title='Continue'
        onPress={onContinuePress}
        style={{ marginHorizontal: Spacing.xl, marginVertical: Spacing.xl }}
      />
    </>
  );
};

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: colorForScheme({ default: Colors.N0 }),
  },
});
