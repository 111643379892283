import { KitText } from '@omni/kit/components';
import React from 'react';
import { View, ViewStyle } from 'react-native';

import { IndicatorSize } from '../../blocks/types';

interface IPositionIndicatorProps {
  color?: string;
  position: string;
  size: IndicatorSize;
  style?: ViewStyle;
}

export default function PositionIndicator(
  props: IPositionIndicatorProps
): JSX.Element {
  const { style = {}, color, position, size } = props;

  let fontSize = 32;

  switch (size) {
    case IndicatorSize.Medium:
      fontSize = 40;
      break;
    case IndicatorSize.Large:
      fontSize = 60;
      break;
    case IndicatorSize.ExtraLarge:
      fontSize = 80;
      break;
    default:
      break;
  }

  const lineHeight = fontSize * 1.2;

  return (
    <View
      style={[
        style,
        {
          justifyContent: 'center',
          alignItems: 'center',
        },
      ]}
    >
      <KitText
        style={{ lineHeight, letterSpacing: -0.75 }}
        fontSize={fontSize}
        color={color}
      >
        {position}
      </KitText>
    </View>
  );
}
