import { AUTH_STACK, AuthNavigator } from '@omni/kit/auth';
import ModalWrapper from '@omni/kit/components/ModalWrapper';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import {
  StackScreenProps,
  createStackNavigator,
} from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { useDispatch, useSelector } from 'react-redux';

import { useChatAuth } from '../../context/ChatAuthContext';
import useAppInfo from '../../hooks/useAppInfo';
import {
  ACCEPT_INVITATION_SCREEN,
  ACCOUNT_SCREEN,
  BIBLE_LOCATION_SCREEN,
  CHANNEL_DETAILS_MODAL,
  CHAT_SETTINGS_MODAL,
  CREATE_CHANNEL_MODAL,
  CREATE_DIRECT_MODAL,
  DISCOVER_DETAIL_SCREEN,
  GIF_SCREEN,
  INTRO_SCREEN,
  MAIN_STACK,
  MEDIA_SELECTION_SCREEN,
  NOT_FOUND_SCREEN,
  POLL_RESULTS_MODAL,
  POLL_SCREEN,
  PRAYER_REQUEST_SCREEN,
} from '../../shared/Constants';
import { universalLogout } from '../../shared/redux/actions/SystemActions';
import { appTitleSelector, userSelector } from '../../shared/redux/selectors';
import { ChannelDetailsStack } from '../../shared/scenes/channelDetails/ChannelDetailStack';
import { ChatModuleProps, ChatStackParamList } from '../Types';
import AcceptInvitationScreen from '../screens/AcceptInvitationScreen';
import AccountScreen from '../screens/AccountScreen';
import CreateChannelModal from '../screens/CreateChannelModal';
import CreateDirectScreen from '../screens/CreateDirectScreen';
import GifModal from '../screens/GifModal';
import IntroScreen from '../screens/IntroScreen';
import MediaSelectionScreen from '../screens/MediaSelectionScreen';
import PollResultModal from '../screens/PollResultModal';
import PollScreen from '../screens/PollScreen';
import PrayerRequestScreen from '../screens/PrayerRequestScreen';
import { BibleSelectionModalWithHeader } from './SmallScreenNavigator';
import MainScreen from './largeScreens/MainScreen';
import { ChatSettingsStack } from './stacks/ChatSettingsStack';

// @ts-ignore
const AppModalStack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

const modalStackPresentationOptions = Platform.select({
  ios: {
    stackPresentation: 'modal',
    headerHideShadow: true,
    headerShown: false,
  },
  android: {
    stackPresentation: 'modal',
    headerHideShadow: true,
    headerShown: false,
    headerTopInsetEnabled: false, // react-native-screens
  },
  web: {
    stackPresentation: 'transparentModal',
    cardStyle: { backgroundColor: 'transparent' },
    headerShown: false,
    contentStyle: { backgroundColor: 'transparent' },
  },
});

interface LargeScreenNavigatorProps
  extends StackScreenProps<ChatStackParamList, 'app_stack'> {
  screenProps: ChatModuleProps;
}

export default function LargeScreenNavigator({
  screenProps,
  route,
  navigation,
}: LargeScreenNavigatorProps): JSX.Element | null {
  const user = useSelector(userSelector);
  const appTitle = useSelector(appTitleSelector);

  const appKey = route.params?.appKey || screenProps.appKey;

  const { isReady, branding, logo, orgKey } = useAppInfo(appKey);

  const { isAuthenticated, isReady: isAuthReady } = useChatAuth();
  const dispatch = useDispatch();

  const _navToUserSettings = () => {
    // @ts-ignore
    navigation.navigate(CHAT_SETTINGS_MODAL);
  };

  const _navToAccountsScreen = () => {
    // @ts-ignore
    navigation.navigate(ACCOUNT_SCREEN);
  };

  useEffect(() => {
    if (!appKey) {
      navigation.replace(NOT_FOUND_SCREEN);
    }
  }, [appKey, navigation]);

  const _handleLogOut = async () => {
    dispatch(universalLogout());
  };

  if (!isReady || !isAuthReady) {
    return null;
  }

  /**
   * Closes the current popup after login succeeds
   * and reload the parent window for access token
   */
  if (Platform.OS === 'web' && window.opener) {
    window.opener.location.reload();
    window.close();
  }

  return (
    <View style={styles.navContainer}>
      <AppModalStack.Navigator
        mode='modal'
        screenOptions={modalStackPresentationOptions}
      >
        {isAuthenticated ? (
          <>
            <AppModalStack.Screen
              name={MAIN_STACK}
              options={{ gestureEnabled: false, title: appTitle }}
            >
              {(props) => (
                <MainScreen
                  {...props}
                  screenProps={{ ...screenProps, appKey }}
                />
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={INTRO_SCREEN}
              options={{
                gestureEnabled: false,
                // @ts-ignore
                stackPresentation:
                  Platform.OS === 'web' ? 'transparentModal' : 'modal',
                contentStyle: { backgroundColor: 'transparent' },
                title: appTitle,
              }}
            >
              {(props) => (
                <ModalWrapper>
                  <IntroScreen {...props} />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={CHANNEL_DETAILS_MODAL}
              options={{ title: appTitle }}
            >
              {() => (
                <ModalWrapper>
                  <ChannelDetailsStack />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={CHAT_SETTINGS_MODAL}
              options={{ title: appTitle }}
            >
              {() => (
                <ModalWrapper>
                  <ChatSettingsStack />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={ACCOUNT_SCREEN}
              options={{ title: appTitle }}
            >
              {(props) => (
                <ModalWrapper>
                  <AccountScreen {...props} />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={GIF_SCREEN}
              options={{ title: appTitle }}
            >
              {() => (
                <ModalWrapper>
                  <GifModal />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={PRAYER_REQUEST_SCREEN}
              options={{ title: appTitle }}
            >
              {() => (
                <ModalWrapper>
                  <PrayerRequestScreen />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={MEDIA_SELECTION_SCREEN}
              options={{ title: appTitle }}
            >
              {() => (
                <ModalWrapper>
                  <MediaSelectionScreen />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={POLL_SCREEN}
              options={{ title: appTitle }}
            >
              {() => (
                <ModalWrapper>
                  <PollScreen />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={BIBLE_LOCATION_SCREEN}
              options={{ title: appTitle }}
            >
              {() => (
                <ModalWrapper>
                  <BibleSelectionModalWithHeader />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={CREATE_CHANNEL_MODAL}
              options={{ title: appTitle }}
            >
              {(props) => (
                <ModalWrapper>
                  <CreateChannelModal {...props} />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen
              name={CREATE_DIRECT_MODAL}
              options={{ title: appTitle }}
            >
              {() => (
                <ModalWrapper>
                  <CreateDirectScreen />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
            <AppModalStack.Screen name={POLL_RESULTS_MODAL}>
              {(props) => (
                <ModalWrapper>
                  <PollResultModal {...props} />
                </ModalWrapper>
              )}
            </AppModalStack.Screen>
          </>
        ) : (
          <AppModalStack.Screen name={AUTH_STACK} options={{ title: appTitle }}>
            {(props) => (
              <ModalWrapper>
                <AuthNavigator
                  {...props}
                  appKey={appKey}
                  orgKey={orgKey}
                  headerShown={false}
                  screenProps={screenProps}
                  targetAuthProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
                />
              </ModalWrapper>
            )}
          </AppModalStack.Screen>
        )}
        <AppModalStack.Screen
          name={ACCEPT_INVITATION_SCREEN}
          component={AcceptInvitationScreen}
        />
      </AppModalStack.Navigator>
    </View>
  );
}

const styles = StyleSheet.create({
  navContainer: {
    flex: 1,
  },
});
