import React, { useState } from 'react';
import { View } from 'react-native';

import { SizeClass, useSizeClass } from '../contexts/SizeClassContext';
import { PresentationStyle } from '../utilities/NativeHelpers';
import KitModalV2 from './KitModalV2';

export const PresentationWrapper = ({
  children,
  onDismiss,
  contentBackgroundColor,
  presentationStyle,
}: {
  children: JSX.Element;
  contentBackgroundColor?: string;
  onDismiss?: () => void;
  presentationStyle?: PresentationStyle;
}): JSX.Element => {
  const { sizeClass, windowHeight } = useSizeClass();

  const [kitModalVisible, setKitModalVisible] = useState(
    presentationStyle === PresentationStyle.KitModal
  );

  return presentationStyle === PresentationStyle.KitModal ? (
    <KitModalV2
      onClose={() => {
        setKitModalVisible(false);
        onDismiss?.();
      }}
      isVisible={kitModalVisible}
      anchorBottom={sizeClass === SizeClass.Small}
      coverScreen={true}
    >
      <View
        style={{
          backgroundColor: contentBackgroundColor,
          maxHeight: windowHeight * 0.75,
          width: sizeClass === SizeClass.Small ? '100%' : 620,
        }}
      >
        {children}
      </View>
    </KitModalV2>
  ) : (
    children
  );
};
