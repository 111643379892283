import { App } from '../services/Types';
import {
  ImageServiceType,
  imageTypeFromContentType,
} from '../utilities/utilities';

export interface BrandLogo {
  id: string;
  url: string;
  size: { width: number; height: number };
  imageType: ImageServiceType;
}

export interface BrandData {
  brandLogo: BrandLogo;
  brandColor: string;
  colorScheme: 'light' | 'dark';
  iconBackgroundColor: string;
  iconBackgroundImage?: string;
  silhouetteLogo?: string;
}

export default function useBrandData(app?: App): BrandData | undefined {
  const brand = app?._embedded?.branding;
  const logo = brand?._embedded?.['icon-logo-image'];
  const iconBackground = brand?._embedded?.['icon-background-image'];
  const silhouetteLogo = brand?._embedded?.['silhouette-logo-image'];

  if (logo) {
    return {
      brandLogo: {
        id: logo?.id,
        url: logo?._links?.dynamic?.href ?? '',
        size: { width: logo.width, height: logo.height },
        imageType: imageTypeFromContentType(logo.content_type),
      },
      brandColor: brand.brand_color_hex,
      colorScheme: brand.app_color_scheme,
      iconBackgroundColor: brand.icon_background_color_hex,
      iconBackgroundImage: iconBackground?._links?.dynamic?.href,
      silhouetteLogo: silhouetteLogo?._links?.dynamic?.href,
    };
  }

  return undefined;
}
