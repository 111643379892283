import Environment from '../../../Environment';
import { IHousehold } from '../Types';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.peopleService;
const RESOURCE = 'households';

interface ICreateHousehold extends IUri {
  body: IHousehold;
  token: string;
}

export interface ICreateHouseholdResponse extends IHttpResponse {
  body?: IHousehold;
}

export default async function ({
  body,
  token,
}: ICreateHousehold): Promise<ICreateHouseholdResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
  });

  return BaseServiceV2.Post({
    url,
    token,
    data: body,
  });
}
