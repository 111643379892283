import { Platform } from 'react-native';

import Environment from '../../Environment';
import { PlatformAgnosticFile } from '../../FileTypes';
import ImageService from '../../services/ImageService';

const debug = require('debug')(
  'omni:messaging:kit:utilities:imageUploadUtilities'
);

export enum ImageUploadStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Success = 'success',
  Error = 'error',
}

export const getSignedUrl = async (
  appKey: string,
  imageType: string,
  contentType: string,
  endUserId: string,
  token?: string
): Promise<{ id: string; signedUrl: string }> => {
  const response = await ImageService.post(
    appKey,
    'image',
    endUserId,
    {
      type: imageType,
      content_type: contentType,
    },
    '',
    token
  );

  return {
    id: response.data.id,
    signedUrl: response.data._links.presigned_upload_url.href,
  };
};

export const submitUploadRequest = (
  id: string,
  signedUrl: string,
  imageObject: PlatformAgnosticFile,
  setProgress?: (progress: number) => void,
  setStatus?: (status: ImageUploadStatus) => void
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedUrl);
    xhr.setRequestHeader('Content-Type', imageObject.contentType);
    xhr.setRequestHeader('x-amz-acl', 'public-read');

    if (Platform.OS === 'web') {
      xhr.send(imageObject._original);
    } else {
      xhr.send({
        uri: imageObject.uri,
        type: imageObject.contentType,
        name: imageObject.fileName,
      });
    }

    const url = `${Environment.imagesHost}/{name}.{ext}?id=${id}&w={width}&h={height}`;

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable && event.total > 0) {
        const percentComplete = event.loaded / event.total;
        setProgress?.(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        setStatus?.(ImageUploadStatus.Success);
        debug('Image successfully uploaded to S3');
        resolve(url);
      } else {
        setStatus?.(ImageUploadStatus.Error);
        debug(
          'Error while sending the image to S3: ',
          xhr.status,
          xhr.statusText
        );
        reject(xhr.status);
      }
    };

    xhr.onerror = (err) => {
      reject(err);
    };
  });
};

export const uploadToImageService = (
  appKey: string,
  imageObject: PlatformAgnosticFile,
  imageType: string,
  endUserId?: string,
  token?: string,
  setProgress?: (progress: number) => void,
  setStatus?: (status: ImageUploadStatus) => void
): Promise<{ id: string; url: string }> => {
  return new Promise<{ id: string; url: string }>(async (resolve, reject) => {
    if (!endUserId || endUserId.length <= 0) {
      reject('Missing required end user id');

      return;
    }

    setStatus?.(ImageUploadStatus.InProgress);

    getSignedUrl(appKey, imageType, imageObject.contentType, endUserId, token)
      .then(({ id, signedUrl }) => {
        submitUploadRequest(id, signedUrl, imageObject, setProgress, setStatus)
          .then((url) => {
            resolve({ id, url });
          })
          .catch((e) => {
            debug('Error while uploading Image resource: ', e);
            reject(e.status);
          });
      })
      .catch((e) => {
        debug('Error while getting signed url: ', e);
        reject(e.status);
      });
  });
};
