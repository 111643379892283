import TokensService from '@omni/kit/services/TokensService';

import { IMediaItem, IPrepareMediaItemUrl, prepareMediaUrl } from '../';
import BaseServiceV2, { IHttpResponse } from '../../BaseServiceV2';

const debug = require('debug')('tca:service:MediaItemService:Core:GetAll');

interface IMediaItemGetAll extends Omit<IPrepareMediaItemUrl, 'id'> {
  getFromCache?: boolean;
  appKey?: string;
  headers?: { [key: string]: string };
}

interface IMediaItemGetAllResponse extends IHttpResponse {
  body?: {
    _embedded: {
      'media-items': IMediaItem[];
    };
    total?: number;
    count: number;
  };
}

export default async ({
  getFromCache,
  appKey,
  headers,
  ...buildUrlProps
}: IMediaItemGetAll): Promise<IMediaItemGetAllResponse> => {
  debug('GetAll');
  const url = prepareMediaUrl(buildUrlProps);

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    appKey,
    getFromCache,
    headers,
    token: guestToken,
  });
};
