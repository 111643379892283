import React, { useRef, useState } from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import ReactNativeModal from 'react-native-modal';

import BorderRadius from '../../theming/BorderRadius';
import Colors from '../../theming/Colors';
import Depth from '../../theming/Depth';
import Spacing from '../../theming/Spacing';
import { IProps } from './types';

export default function KitModal(props: IProps): JSX.Element {
  const {
    anchorBottom,
    children,
    coverScreen = false,
    hasBackdrop,
    isVisible = false,
    onClose,
    onModalPress,
    enableScroll = false,
    noScrollView = false,
    swipeEnabled = true,
  } = props;

  const { width, height } = useWindowDimensions();

  // use the longest length for the backdrop width and height to cover the whole screen
  // this is a workaround for an issue where the backdrop is too short on iPad 12.9-inch in portrait mode
  // ref: https://github.com/facebook/react-native/issues/41375
  const longestLength = Math.max(width, height);

  const [scrollOffset, setScrollOffset] = useState(0);

  const scrollViewRef = useRef<ScrollView | null>(null);

  /**
   * Scrolling support references
   * https://github.com/react-native-modal/react-native-modal/blob/master/example/src/modals/ScrollableModal.tsx
   * @param event
   */
  const handleOnScroll = (event: any) => {
    setScrollOffset(event.nativeEvent.contentOffset.y);
  };

  const handleScrollTo = (p: number) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo(p);
    }
  };

  /** Platform specific props */
  const platformProps = Platform.select({
    web: {
      /**
       * Custom backdrop is required to fix resizing issue on web
       */
      customBackdrop: (
        <Pressable
          style={[styles.customBackdrop, props.backdropStyle]}
          onPress={onClose}
        />
      ),
    },
    default: {},
  });

  /**
   * Use Pressable component if onPress is desired
   * otherwise use View component to allow scrolling
   */
  const Modal = onModalPress ? Pressable : View;
  const onModalPressProp = onModalPress ? { onPress: onModalPress } : {};

  return (
    <ReactNativeModal
      {...platformProps}
      {...props}
      deviceHeight={longestLength}
      deviceWidth={longestLength}
      isVisible={isVisible}
      onBackdropPress={onClose}
      onBackButtonPress={onClose}
      onSwipeComplete={anchorBottom ? onClose : undefined}
      swipeDirection={swipeEnabled && anchorBottom ? 'down' : undefined}
      animationInTiming={anchorBottom ? 300 : 1}
      animationOutTiming={anchorBottom ? 300 : 1}
      scrollTo={handleScrollTo}
      scrollOffset={scrollOffset}
      scrollOffsetMax={9999} // an arbitrary large number to prevent the scroll view content bouncing back during scrolling
      hasBackdrop={hasBackdrop}
      /** Prevent backdrop flickering on android */
      useNativeDriverForBackdrop={true}
      propagateSwipe={true}
      statusBarTranslucent={true}
      style={[
        styles.container,
        anchorBottom && styles.anchorBottom,
        props.backdropStyle,
      ]}
      /**
       * coverScreen false is needed to
       * - Render multiple sibling modals
       * - Allow non-children elements to appear in front
       * of the modal
       *
       * https://github.com/react-native-modal/react-native-modal#available-props
       */
      coverScreen={coverScreen}
    >
      <Modal
        style={[
          Depth.l,
          styles.modalContainer,
          anchorBottom && styles.anchorBottomContainer,
          props.modalStyle,
        ]}
        {...onModalPressProp}
      >
        {noScrollView ? (
          children
        ) : (
          <ScrollView
            {...props.scrollViewProps}
            scrollEnabled={enableScroll}
            ref={scrollViewRef}
            onScroll={handleOnScroll}
          >
            {children}
          </ScrollView>
        )}
      </Modal>
    </ReactNativeModal>
  );
}

const styles = StyleSheet.create({
  customBackdrop: {
    backgroundColor: Colors.N1000,
    flex: 1,
    // @ts-ignore
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  anchorBottom: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  anchorBottomContainer: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: '100%',
    padding: Spacing.l,
    paddingBottom: Spacing.xxl,
  },
  // @ts-ignore
  container: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    ...Platform.select({
      web: { position: 'fixed', top: 0, right: 0, left: 0, bottom: 0 },
      default: { flex: 1 },
    }),
  },
  modalContainer: {
    backgroundColor: Colors.N0,
    borderRadius: BorderRadius.l,
    justifyContent: 'center',
    margin: 0,
    maxWidth: 700,
    padding: Spacing.xl,
  },
});
