import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import { useEffect, useState } from 'react';

const DEFAULT_LABEL_SIZE = 'DK-1202';
const SELECT_LABEL_SIZE_KEY = 'selected-label-size';

export type ILabelSize = 'DK-1201' | 'DK-1202' | 'DK-2205';
/*
 * Do not use this directly in a component, instead use KioskContext
 * */
export default (): [
  ILabelSize | undefined,
  (selectedLabelSize?: ILabelSize) => Promise<void>
] => {
  const [labelSize, setLabelSize] = useState<ILabelSize | undefined>();
  useEffect(() => {
    const getLabelSize = async () => {
      try {
        const storedLabelSize = await getStoredItem(SELECT_LABEL_SIZE_KEY);

        if (storedLabelSize) {
          setLabelSize(storedLabelSize as ILabelSize);
        } else {
          setLabelSize(DEFAULT_LABEL_SIZE);
        }
      } catch (e) {
        // no labelsize connected
      }
    };
    getLabelSize();
  }, []);
  const saveLabelSizeSelection = async (selectedLabelSize?: ILabelSize) => {
    if (selectedLabelSize) {
      await setStoredItem(SELECT_LABEL_SIZE_KEY, selectedLabelSize);
      setLabelSize(selectedLabelSize);
    } else {
      setLabelSize(DEFAULT_LABEL_SIZE);
      await removeStoredItem(SELECT_LABEL_SIZE_KEY);
    }
  };

  return [labelSize, saveLabelSizeSelection];
};
