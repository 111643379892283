import Environment from '@omni/kit/Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '@omni/kit/services/BaseServiceV2';

import { KioskConfig } from '../Types';

const HOST = Environment.checkInService;
const RESOURCE = 'kiosk-configs';

interface IGetKiosk extends IUri {
  token?: string;
  id?: string;
}

interface IGetKioskResponse extends IHttpResponse {
  body?: KioskConfig;
}

export default async function getKiosk({
  token,
  id,
}: IGetKiosk): Promise<IGetKioskResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    id,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
