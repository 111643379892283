import Environment from '../../../Environment';
import { IProfile } from '../Types';
import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.peopleService;
const RESOURCE = 'profiles';

interface IGetProfiles extends IUri {
  token?: string;
}

interface IProfilesResponse {
  count: number;
  _embedded: {
    profiles: IProfile[];
  };
}

interface IGetProfilesResponse extends IHttpResponse {
  body?: IProfilesResponse;
}

export default async function ({
  token,
  ...buildUrlProps
}: IGetProfiles): Promise<IGetProfilesResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
  });
}
