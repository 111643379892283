import { KitButton, KitIcon, KitText } from '@omni/kit/components';
import { StyleSheet, Text, View } from 'react-native';
import {
  canCreateChannelsSelector,
  canCreateDirectMessageSelector,
  publicChannelListSelector,
} from '../../../redux/selectors';

import Colors from '@omni/kit/theming/Colors';
import { DISCOVER_TAB } from '../../../Constants';
import React from 'react';
import Routes from '../../../../web/navigation/Routes';
import Spacing from '@omni/kit/theming/Spacing';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

//******************************************************************************
// Component
//******************************************************************************

interface EmptyConversationListProps {
  onCreatePressed: () => void;
}

export default function EmptyConversationList({
  onCreatePressed,
}: EmptyConversationListProps) {
  const canCreateChannels = useSelector(canCreateChannelsSelector);
  const canCreateDirectMessage = useSelector(canCreateDirectMessageSelector);
  const publicChannels = useSelector(publicChannelListSelector);

  // @ts-ignore
  let navigation;
  // @ts-ignore
  let history, params;
  try {
    navigation = useNavigation();
  } catch {
    history = useHistory();
    params = useParams();
  }

  const navToDiscover = () => {
    // @ts-ignore
    if (navigation) {
      // @ts-ignore
      navigation.navigate(DISCOVER_TAB);
    } else {
      // @ts-ignore
      history.push(Routes.groups(params.appKey));
    }
  };

  const _renderText = () => {
    if (publicChannels && publicChannels.length > 0) {
      return (
        <View>
          <KitText black fontSize={26} bold center style={styles.headerText}>
            Let's get the conversation started!
          </KitText>
          <KitButton
            title='Join a group'
            onPress={navToDiscover}
            style={{ marginTop: Spacing.m }}
          />
          {(canCreateDirectMessage || canCreateChannels) && (
            <KitButton
              secondary
              title='Start message'
              onPress={onCreatePressed}
              style={{ marginTop: Spacing.l }}
            />
          )}
        </View>
      );
    } else if (canCreateDirectMessage || canCreateChannels) {
      return (
        <View>
          <KitText black fontSize={26} bold center style={styles.headerText}>
            Let's get the conversation started!
          </KitText>
          <KitText gray fontSize={16} style={styles.paragraphText}>
            Tap + to begin a direct message.
          </KitText>
        </View>
      );
    } else {
      return (
        <View>
          <KitText black fontSize={26} bold center style={styles.headerText}>
            Nothing to see here, yet!
          </KitText>
          <KitText gray fontSize={16} style={styles.paragraphText}>
            You haven't been added to any groups yet, but when you are, this is
            where you'll see them.
          </KitText>
          <KitText
            gray
            fontSize={16}
            style={[styles.paragraphText, { marginTop: Spacing.m }]}
          >
            If you'd like to try out this new way of communicating with people
            in your church, talk to a church leader!
          </KitText>
        </View>
      );
    }
  };

  return (
    <View style={styles.container}>
      <KitIcon name='messaging' color='#999b9e' size={44} />
      {_renderText()}
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.N0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Spacing.xxl,
    paddingBottom: Spacing.xxl,
  },
  headerText: {
    marginTop: Spacing.m,
    marginBottom: Spacing.m,
  },
  paragraphText: {
    lineHeight: 24,
    textAlign: 'center',
  },
});
function useHistory(): any {
  throw new Error('Function not implemented.');
}

function useParams(): any {
  throw new Error('Function not implemented.');
}
