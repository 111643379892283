import { IEmoji, TEmojiList } from '../types';
import emoji from './emoji.json';

// Merge 'smileys' & 'people' into 'Smileys & People'

type ExtendedList = TEmojiList & { 'Smileys & People': IEmoji[] };
type OmittedList = Omit<ExtendedList, 'People & Body' | 'Smileys & Emotion'>;

const mergeEmojiList = (l: TEmojiList): OmittedList => {
  l['Smileys & People'] = [...l['Smileys & Emotion'], ...l['People & Body']];

  delete l['Smileys & Emotion'];
  delete l['People & Body'];

  return l;
};

export const formattedEmojiList = mergeEmojiList(emoji as TEmojiList);
