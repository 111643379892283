import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import getRouteAppKey from '@omni/kit/utilities/getRouteAppKey.web';
import React from 'react';

import { AppWrapper } from './AppWrapper';
import { MessagingProps } from './Types';
import LargeWebMessaging from './web/navigation/LargeWebMessaging';
import SmallWebMessaging from './web/navigation/SmallWebMessaging';

export default function Messaging(props: MessagingProps): JSX.Element | null {
  const isSmall = useSizeClass().sizeClass === SizeClass.Small;
  const isGroups = window.location.href.includes('/groups');

  const appKey = getRouteAppKey();

  if (!appKey) {
    return null;
  }

  return (
    <AppWrapper props={{ appKey: appKey }}>
      {/* Show web navigator for medium/large screens or the group finder */}
      {!isSmall || isGroups ? (
        <LargeWebMessaging appKey={appKey} />
      ) : (
        <SmallWebMessaging {...props} appKey={appKey} />
      )}
    </AppWrapper>
  );
}
