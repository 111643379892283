import Environment from '../../Environment';
import { IFilter, IInclude, IPage, ISort, buildUrl } from '../BaseServiceV2';
import { IMediaItem } from './Types';

const host = Environment.mediaService;

export interface IPrepareMediaItemUrl {
  filters?: IFilter[];
  id?: string;
  includes?: IInclude[];
  pages?: IPage[];
  sorts?: ISort[];
}

export function prepareMediaUrl({
  filters,
  id,
  includes,
  pages,
  sorts,
}: IPrepareMediaItemUrl): string {
  return buildUrl({
    host,
    resource: 'media-item',
    filters,
    includes,
    id,
    pages,
    sorts,
  });
}

export function getAudioPlaybackUrl(mediaItem: IMediaItem): string | undefined {
  const audio = mediaItem._embedded?.audio;
  const broadcast = mediaItem._embedded?.broadcast;

  // Sim Live broadcasts that are uploaded will get transcoded to mp3, but until the broadcast has
  // completed, we don't want to use that mp3, since it allows for the viewer/listener to scrub
  // forward past the live point in the audio stream (since mp3 isn't a live media format).
  // ref: https://subsplash.io/go/feeds/-/blob/30e78934f093500c27e77c606f7f967173053aa9/internal/handlers/mediadetail_handler.go#L836-847
  const simLiveScheduledOrLive =
    broadcast &&
    broadcast.simulated_live &&
    (broadcast.status === 'scheduled' || broadcast.status === 'live');

  if (audio && (broadcast === undefined || !simLiveScheduledOrLive)) {
    if (audio?._links?.related?.href) {
      return audio._links.related.href;
    }

    if (audio?._embedded?.['audio-outputs']?.length) {
      return audio._embedded['audio-outputs'][0]._links.related.href;
    }
  }

  if (mediaItem.external_audio_url) {
    return mediaItem.external_audio_url;
  }
}

export function getVideoPlaybackUrl(mediaItem: IMediaItem): string | undefined {
  const video = mediaItem._embedded?.video;
  const broadcast = mediaItem._embedded?.broadcast;

  // ref: https://subsplash.io/go/feeds/-/blob/f47b629123a78ae2f819503c463c3db2e7904a8c/internal/handlers/mediadetail_handler.go#L849-853
  const simLiveEnded =
    broadcast &&
    broadcast.simulated_live &&
    (broadcast.status === 'ended' || broadcast.status === 'on-demand');
  const trueLive = broadcast && !broadcast.simulated_live;

  if (video && (!broadcast || simLiveEnded || trueLive)) {
    if (video?._links?.related?.href) {
      return video._links.related.href;
    }

    if (video?._embedded?.playlists?.length) {
      return video._embedded.playlists[0]._links.related?.href;
    }

    if (video?._embedded?.['video-outputs']?.length) {
      return video._embedded['video-outputs'][0]._links.related?.href;
    }
  }

  if (mediaItem.external_video_url) {
    return mediaItem.external_video_url;
  }

  if (mediaItem.external_m3u8_url) {
    return mediaItem.external_m3u8_url;
  }
}
