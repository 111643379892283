import { NativeEventEmitter, NativeModules } from 'react-native';

import { getMediaPlaybackStateInfo } from '../../utilities/NativeHelpers';
import { IMediaPlaybackStateInfo } from '../KitPlayer/playbackTypes';
import CancelPolling from './CancelPolling';
import TrackMediaProgress from './TrackMediaProgress';
import TrackMediaProgressState from './TrackMediaProgressState';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:AddIosMediaPlaybackListener'
);

const IOS_TRACK_PROGRESS_POLL_INTERVAL = 1000; // 1 second

/**
 * Ios abstraction layer to match android's headless runner
 */
export default (): void => {
  const { ReactPlatformBridge } = NativeModules;

  if (ReactPlatformBridge != null) {
    const ReactPlatformBridgeEventEmitter = new NativeEventEmitter(
      ReactPlatformBridge
    );

    ReactPlatformBridgeEventEmitter.addListener(
      'MediaPlaybackStateChanged',
      (mediaPlaybackStateInfo: IMediaPlaybackStateInfo) => {
        const { isComplete, isPlaying, isSeeking, isLiveStream } =
          mediaPlaybackStateInfo;

        /** Stop tracking in case of live streams. */
        if (isLiveStream) {
          return debug('Media is live, stop tracking');
        }

        /** Reset polling */
        CancelPolling();

        /** Ensure patching progress on complete event is handled once */
        if (isComplete) {
          if (TrackMediaProgressState.previousStates.wasComplete) {
            debug('Media is already completed. Skip progress tracking.');
          } else {
            debug('Media is complete. Track one last time.');
            TrackMediaProgress(mediaPlaybackStateInfo);
          }

          TrackMediaProgressState.previousStates.wasComplete = true;

          return;
        } else {
          debug('Track progress on MediaPlaybackStateChanged event');
          TrackMediaProgress(mediaPlaybackStateInfo);
          TrackMediaProgressState.previousStates.wasComplete = false;
        }

        /**
         * Stop polling if player is paused and NOT seeking
         *
         * Note:
         * Polling will continue during seeking until seeking is complete
         */
        if (!isPlaying && !isSeeking) {
          return debug('Media is paused and not seeking, skip polling.');
        }

        /** Set up polling */
        TrackMediaProgressState.pid = setInterval(async () => {
          const mediaPlaybackStateInfo = await getMediaPlaybackStateInfo();
          const { isPlaying, isSeeking } = mediaPlaybackStateInfo;
          TrackMediaProgress(mediaPlaybackStateInfo);

          if (!isPlaying && !isSeeking) {
            debug('Media is paused and not seeking, cancel polling.');
            CancelPolling();
          }
        }, IOS_TRACK_PROGRESS_POLL_INTERVAL);
      }
    );
  }
};
