import { useShellContext } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import KitSection from '@omni/kit/components/KitSection';
import { useScreenVisibility } from '@omni/kit/contexts/ScreenVisibilityContext';
import AssignmentsService from '@omni/kit/services/ScheduleService/AssignmentsService';
import { IAssignment } from '@omni/kit/services/ScheduleService/Types';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import NoUpcomingEvents from './NoUpcomingEvents';
import ScheduleListItems from './ScheduleListItems';

const debug = require('debug')('tca:accountMenu:Schedule');

export default function SchedulePreview(): JSX.Element {
  const { tokens } = useShellContext();
  const accessToken = useMemo(() => tokens.user, [tokens.user]);
  const userToken = accessToken?.replace('Bearer ', '');
  const { t } = useTranslation(['schedule']);
  const [assignments, setAssignments] = useState<IAssignment[]>([]);
  const isScreenVisible = useScreenVisibility();

  const fetchAssignments = async () => {
    try {
      if (!userToken) return;

      const response = await AssignmentsService.getAll({
        token: userToken,
        includes: ['fluro-event'],
      });

      if (response.body) {
        setAssignments(response.body?._embedded?.assignments || []);
      }
    } catch (e) {
      debug(e);
    }
  };

  const filteredAssignments = useMemo(() => {
    return assignments
      ?.filter((assignment) => assignment?.confirmation_status !== 'denied')
      ?.slice(0, 2);
  }, [assignments]);

  const getNumberOfRequests = useMemo(() => {
    const requests = assignments?.filter(
      (assignment) => assignment?.confirmation_status === 'unknown'
    ).length;

    if (requests > 0) {
      switch (requests) {
        case 1:
          return `1 ${t('schedule:request')}`;
        default:
          return `${requests > 9 ? '9+' : requests} ${t('schedule:requests')}`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignments]);

  useEffect(() => {
    if (isScreenVisible) {
      fetchAssignments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenVisible]);

  return (
    <KitSection
      title={t('schedule:headerTitle')}
      badge={getNumberOfRequests}
      onPress={() => dispatch({ handler: 'schedule', theme: 'light' })}
    >
      <View style={{ marginHorizontal: Spacing.l }}>
        {filteredAssignments.length && accessToken ? (
          <ScheduleListItems
            accessToken={accessToken}
            assignments={filteredAssignments}
          />
        ) : (
          <NoUpcomingEvents />
        )}
      </View>
    </KitSection>
  );
}
