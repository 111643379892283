import { ICheckIn } from '@omni/check-in/kiosk/services/CheckInService/Types';
import { KitListItem, KitTouchableIcon } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitInfo from '@omni/kit/components/KitInfo';
import KitText from '@omni/kit/components/KitText';
import { IEvent } from '@omni/kit/services/EventsService/Types';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import { createImageUrl } from '@omni/kit/utilities/utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, ScrollView, View } from 'react-native';

export interface PreCheckSecurityCodeBlockProps {
  profiles: IProfile[];
  checkIns: ICheckIn[];
  event: IEvent;
  labelCode: string;
  backgroundColor: string;
  onClose: () => void;
}

export default function PreCheckSecurityCodeSummary({
  profiles,
  checkIns,
  event,
  labelCode,
  backgroundColor,
  onClose,
}: PreCheckSecurityCodeBlockProps): JSX.Element {
  const { t } = useTranslation();
  const showSession =
    event?._embedded?.sessions && event._embedded.sessions.length > 1;

  const bottomViewHeight = Dimensions.get('window').height - 320;

  return (
    <View
      style={{ backgroundColor: backgroundColor, paddingTop: Spacing.xxxl }}
    >
      <KitTouchableIcon
        onPress={onClose}
        name='back-ios'
        size={20}
        style={{
          marginTop: Spacing.m,
          marginLeft: Spacing.xs,
        }}
        color={Colors.N0}
        buttonSize={44}
        underlayColor='#FFFFFF11'
      />
      <View
        style={{
          paddingBottom: Spacing.xl,
          backgroundColor: backgroundColor,
          alignItems: 'center',
        }}
      >
        <KitText
          style={{
            textAlign: 'center',
            marginTop: Spacing.m,
            marginBottom: Spacing.xs,
          }}
          h2
          color={Colors.N0}
        >
          {t('check-in:textSecurityCodeLowerCase')}
        </KitText>
        <KitText
          style={{ textAlign: 'center', marginBottom: Spacing.l }}
          color={Colors.N0}
        >
          {t('check-in:textShowDuringCheckOut')}
        </KitText>
        <View
          style={[
            {
              backgroundColor: Colors.N0,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: BorderRadius.m,
              paddingVertical: Spacing.l,
              paddingHorizontal: Spacing.xxxxl,
            },
          ]}
        >
          <KitText black extraBold fontSize={36}>
            {labelCode}
          </KitText>
        </View>
      </View>
      <View
        style={{
          padding: Spacing.l,
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
          backgroundColor: Colors.N0,
        }}
      >
        <ScrollView
          style={{ height: bottomViewHeight }}
          showsVerticalScrollIndicator
        >
          <KitInfo
            icon='check'
            iconColor={Colors.G300}
            title={t('check-in:textCheckedIn')}
            subtitle={`${event.title} • ${dateFormatRange({
              startDate: checkIns[0].created_at,
              timeOnly: true,
              timezone: event.timezone,
            })}`}
            style={{
              marginBottom: Spacing.l,
            }}
          />
          <KitText h2>{t('check-in:textPeople')}</KitText>

          {profiles.map((p, i) => (
            <KitListItem
              key={i}
              minHeight={80}
              title={`${p.first_name} ${p.last_name}`}
              titleFontSize={16}
              subtitle={
                showSession
                  ? checkIns.find(
                      (c) =>
                        c._embedded['end-user'].id ===
                        p?._embedded?.['end-user']?.id
                    )?._embedded['session-snapshot']?.title
                  : undefined
              }
              subtitleFontSize={14}
              capitalizeTitles={false}
              ImageComponent={
                <KitAvatar
                  nickname={`${p.first_name} ${p.last_name}`}
                  imageUrl={createImageUrl(p._embedded?.photo?.id)}
                  size={36}
                />
              }
            />
          ))}
        </ScrollView>
      </View>
    </View>
  );
}
