import Environment from '@omni/kit/Environment';
import { Branding } from '@omni/kit/services/Types';

export interface CompositedIconOptions {
  size?: number;
  shape?: 'square' | 'circle' | 'rounded_rectangle';
  shadow?: boolean;
}

export default function appIconFromBranding(
  branding: Branding,
  { size = 128, shape = 'square', shadow = true }: CompositedIconOptions = {}
): string | undefined {
  if (!branding || !branding._embedded?.['icon-logo-image']) return;

  const brandLogo = branding && new URL(`${Environment.imagesHost}/icon.png`);

  brandLogo.searchParams.append('s', String(size));
  brandLogo.searchParams.append(
    'logo_id',
    branding._embedded['icon-logo-image'].id
  );
  brandLogo.searchParams.append(
    'logo_scale',
    String(branding.icon_logo_scale_factor ?? '70')
  );
  brandLogo.searchParams.append('shadow', String(shadow));
  brandLogo.searchParams.append('shape', shape);

  if (branding._embedded['icon-background-image']) {
    brandLogo.searchParams.append(
      'bg_id',
      branding._embedded['icon-background-image']?.id
    );
  } else {
    brandLogo.searchParams.append(
      'bg_color',
      branding.icon_background_color_hex?.slice(1) ?? '444444'
    );
  }

  return brandLogo.toString();
}
