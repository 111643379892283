import AsyncStorage from '@react-native-async-storage/async-storage';
import { Cache } from 'react-native-cache';

const defaultCache = new Cache({
  namespace: 'downloader',
  // @ts-ignore
  policy: {
    maxEntries: 50,
  },
  backend: AsyncStorage,
});

export default defaultCache;
