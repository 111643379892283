import { StyleSheet, Text, View } from 'react-native';

import Colors from '@omni/kit/theming/Colors';
import { KitText } from '@omni/kit/components';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';

export default function NotFoundScreen() {
  return (
    <View style={styles.fullScreen}>
      <View style={styles.container}>
        <KitText fontSize={32} black bold>
          Page not found
        </KitText>
        <KitText fontSize={18} gray style={styles.description}>
          We couldn't find the page you were looking for. Please make sure you
          have typed the correct URL.
        </KitText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  fullScreen: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    maxWidth: 620,
    paddingHorizontal: Spacing.l,
    textAlign: 'center',
  },
  description: {
    marginTop: Spacing.m,
  },
});
