const S_IN_M = 60;
const S_IN_H = 3600;
const M_IN_H = 60;
const S_IN_D = 86400;
const H_IN_D = 24;

/**
 * Format duration into d:hh:mm:ss format. Must be a positive number.
 */
export default function formatDuration(seconds = 0): string {
  if (seconds < 0) seconds = 0;

  const dur = {
    ...(seconds >= S_IN_D ? { days: Math.floor(seconds / S_IN_D) } : {}),
    ...(seconds >= S_IN_H
      ? { hours: Math.floor((seconds / S_IN_H) % H_IN_D) }
      : {}),
    minutes: Math.floor((seconds / S_IN_M) % M_IN_H),
    seconds: Math.floor(seconds % S_IN_M),
  };

  return [dur.days, dur.hours, dur.minutes, dur.seconds]
    .filter((n) => n !== undefined)
    .map((num) => String(num).padStart(2, '0'))
    .join(':');
}
