import React, { useContext, useState } from 'react';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';

import { ChatContext } from '../../../../../mobile/scenes/channel/ChatScreen';
import { Platform } from 'react-native';
import { PollMessageBubble } from '../../../../../mobile/scenes/channel/components/messageTypes/PollMessageBubble';
import PollResultModal from '../../../../../mobile/screens/PollResultModal';
import WebModal from '../../../../../web/scenes/app/components/WebModal';

// @ts-ignore
export default function PollMessage({ message, disabled }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [pollResultParams, setPollResultParams] = useState({});
  const { handleSelectingMessage } = useContext(ChatContext);
  const isSmall = useSizeClass().sizeClass === SizeClass.Small;

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleNavToResults = (resultCount: number) => {
    setPollResultParams({
      navigation: {
        setOptions: () => null,
        goBack: closeModal,
      },
      route: {
        params: {
          message,
          resultCount,
          options: JSON.parse(message.data).options,
        },
      },
    });
    openModal();
  };

  return (
    <>
      <PollMessageBubble
        message={message}
        handleNavToResults={handleNavToResults}
        onLongPress={handleSelectingMessage}
        disabled={disabled}
      />
      {Platform.OS === 'web' && !isSmall && (
        <WebModal open={modalOpen} onClose={closeModal}>
          {/* @ts-ignore */}
          <PollResultModal {...pollResultParams} />
        </WebModal>
      )}
    </>
  );
}
