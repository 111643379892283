import Environment from '@omni/kit/Environment';
import BaseServiceV2, {
  IHttpResponse,
  IInclude,
  IUri,
  buildUrl,
} from '@omni/kit/services/BaseServiceV2';

import { ICheckIn } from '../Types';

const HOST = Environment.checkInService;
const RESOURCE = 'check-ins-multiple';

interface ICreateCheckIns extends IUri {
  token?: string;
  data?: Partial<ICheckIn>[];
}

interface IGetAllCheckInsResponse extends IHttpResponse {
  body?: ICheckIn[];
}

export default async function GetCheckIns({
  token,
  data,
}: ICreateCheckIns): Promise<IGetAllCheckInsResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    pluralizeResource: false,
  });

  return BaseServiceV2.Post({
    url,
    token,
    data,
  });
}
