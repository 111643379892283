import React from 'react';
import { View } from 'react-native';

import { BasicNavigatorProps } from './types';

const debug = require('debug')('tca:kit:navigation:BasicNavigator:web');

/**
 * No custom implementation needed on iOS or Web, just pass through children
 * @param children
 * @returns children
 */
export default function BasicNavigator({
  children,
  style,
  onLayout,
}: BasicNavigatorProps): JSX.Element {
  return (
    <View style={style} onLayout={onLayout}>
      {children}
    </View>
  );
}
