import AsyncStorage from '@react-native-async-storage/async-storage';
import { Cache } from 'react-native-cache';

export const cache = new Cache({
  namespace: 'searchApp',
  // @ts-ignore
  policy: {
    maxEntries: 50,
  },
  backend: AsyncStorage,
});
