import i18n from '@omni/kit/localization';
import { IGroup } from '@omni/kit/services/GroupsService/Types';
import moment from 'moment';

const defaultGroupTypes = ['activity', 'community', 'small_group', 'team'];
const defaultFrequencies = ['daily', 'weekly', 'every_other_week', 'monthly'];

export const getGroupTypeDisplayName = (name?: string): string => {
  if (!name) {
    return '';
  }

  return defaultGroupTypes.includes(name)
    ? (i18n.t(`groups:groupType_${name}`) as string)
    : name;
};

export const getLocalizedFrequence = (name?: string): string => {
  if (!name) {
    return '';
  }

  return defaultFrequencies.includes(name)
    ? i18n.t('groups:frequency', { context: name })
    : name;
};

export const getFullDayName = (abbreviation: string): string => {
  const dayMap: Record<string, number> = {
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
    SU: 7,
  };

  return moment().day(dayMap[abbreviation]).format('dddd');
};

export const constructGroupSchedule = (group: IGroup): string => {
  const scheduleParts: string[] = [];

  if (group.frequency) {
    scheduleParts.push(getLocalizedFrequence(group.frequency));
  }

  if (group.day_of_week) {
    const dayName = getFullDayName(group.day_of_week);

    if (dayName) {
      scheduleParts.push(dayName);
    }
  }

  return scheduleParts.join(' • ');
};
