import { KitButton, KitText } from '@omni/kit/components';
import { SizeClass } from '@omni/kit/contexts/SizeClassContext';

import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export function RequestToJoinModal({
  onCancel,
  onConfirm,
  sizeClass,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  sizeClass: SizeClass;
}): JSX.Element | null {
  const { t } = useTranslation(['messaging']);

  return (
    <View>
      <KitText bold fontSize={20} black>
        {t('messaging:requestToJoinModalTitle')}
      </KitText>
      <KitText
        fontSize={14}
        gray
        style={{ marginTop: Spacing.s, marginBottom: Spacing.l }}
      >
        {t('messaging:requestToJoinModalDesc')}
      </KitText>
      <View
        style={{
          ...(sizeClass === SizeClass.Small && {
            flexDirection: 'column',
          }),
          ...(sizeClass !== SizeClass.Small && {
            flexDirection: 'row-reverse',
          }),
        }}
      >
        <KitButton
          title={t('groups:buttonTitleViewInRequestToJoin')}
          color={Colors.N900}
          onPress={onConfirm}
          small={sizeClass !== SizeClass.Small}
        />
        <KitButton
          title={t('messaging:buttonTitleCancel')}
          color={Colors.N100}
          secondary
          onPress={onCancel}
          style={{
            ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            ...(sizeClass !== SizeClass.Small && { marginRight: Spacing.m }),
          }}
          small={sizeClass !== SizeClass.Small}
        />
      </View>
    </View>
  );
}
