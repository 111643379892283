import { IUserProfileInfo } from '@omni/kit/services/FeedsService/GetUserProfile';
import MediaPlayService, {
  IMediaPlays,
} from '@omni/kit/services/MediaPersonalizationService/MediaPlayService';
import { getMediaDetailAction } from '@omni/kit/utilities/getMediaDetailAction';

import { CONTEXT_TYPES } from '../../../../src/dynamicMedia/types';
import { IMediaPlayItem } from './types';

const ENDING_THRESHOLD = 1; // in seconds

const debug = require('debug')('tca:blocks:ContinuePlayingBlock:utilities');

export const fetchMediaPlays = async ({
  context,
  user,
  appKey,
  useBlockPage = false,
}: {
  context: CONTEXT_TYPES;
  appKey?: string;
  user?: IUserProfileInfo;
  useBlockPage?: boolean;
}): Promise<IMediaPlayItem[] | undefined> => {
  try {
    if (!user || !appKey) return;

    const response = await MediaPlayService.getAll({
      appKey,
      filters: [
        ['end-user.id', user.userID],
        ['app_key', appKey],
      ],
      pages: [
        ['number', 0],
        ['size', 10],
      ],
      sorts: ['-updated_at'],
      includes: ['media-item'],
      getFromCache: context === CONTEXT_TYPES.CACHE,
    });

    if (response?.body && response?.body?.count > 0) {
      const mediaPlays = response.body?._embedded['media-plays'];
      const items = mediaPlays.map((mediaPlay: any) =>
        parseMediaPlay(mediaPlay as IMediaPlays, useBlockPage)
      );

      return items;
    }
  } catch (e) {
    debug('Failed to fetch media plays', e);
  }
};

const parseMediaPlay = (
  mediaPlay: IMediaPlays,
  useBlockPage = false
): IMediaPlayItem => {
  const mediaItem = mediaPlay._embedded['media-item'];
  const images = mediaItem._embedded?.images || [];
  const image = images[0]?._links?.related?.href;
  let durationLeftMs = mediaPlay.duration_ms;
  let percentage = 0;

  if (mediaPlay.progress_ms) {
    durationLeftMs = mediaPlay.duration_ms - mediaPlay.progress_ms;
    percentage = (mediaPlay.progress_ms * 100) / mediaPlay.duration_ms;
  }

  return {
    id: mediaItem.id,
    shortCode: mediaItem.short_code,
    averageColor: images[0]?.average_color_hex,
    title: mediaItem.title,
    subtitle: prepareDurationLeft(durationLeftMs),
    image,
    percentage,
    duration: mediaPlay.duration_ms,
    action: getMediaDetailAction({
      id: mediaItem.id,
      shortCode: mediaItem.short_code,
      useBlockPage,
    }),
  };
};

const prepareDurationLeft = (duration: number): string => {
  const durationLeft = [];

  duration = Math.floor(duration / 1000);
  const seconds = duration % 60;

  duration = Math.floor(duration / 60);
  const minutes = duration % 60;

  duration = Math.floor(duration / 60);
  const hours = duration;

  if (hours || minutes) {
    if (hours) {
      durationLeft.push(`${hours} hr`);
    }

    if (minutes) {
      durationLeft.push(`${minutes} min`);
    }
  } else {
    durationLeft.push(`${seconds > ENDING_THRESHOLD ? seconds : 0} sec`);
  }

  return `${durationLeft.join(' ')} left`;
};
