import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import { getNativeDeviceName } from '@omni/kit/utilities/NativeHelpers';
import { useEffect, useState } from 'react';

/*
 * Do not use this directly in a component, instead use KioskContext
 * */
export default (): {
  deviceName: string | undefined;
  setDeviceName: (deviceName: string | undefined) => Promise<void>;
  resetDeviceName: () => Promise<void>;
  deviceNameLoading: boolean;
} => {
  const [deviceName, setDeviceName] = useState<string | undefined>();
  const [deviceNameLoading, setDeviceNameLoading] = useState<boolean>(true);

  useEffect(() => {
    const getDeviceName = async () => {
      const deviceName = await getStoredItem('device-name');

      if (deviceName) {
        setDeviceName(deviceName);
      } else {
        setDeviceName(await getNativeDeviceName());
      }

      setDeviceNameLoading(false);
    };
    getDeviceName();
  }, []);

  const saveDeviceName = async (deviceName: string | undefined) => {
    if (deviceName) {
      await setStoredItem('device-name', deviceName);
    } else {
      await removeStoredItem('device-name');
    }

    setDeviceName(deviceName);
  };

  const resetDeviceName = async () => {
    setDeviceName(await getNativeDeviceName());
  };

  return {
    deviceName,
    setDeviceName: saveDeviceName,
    resetDeviceName,
    deviceNameLoading,
  };
};
