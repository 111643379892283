import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import React from 'react';
import { Platform, View } from 'react-native';
import WebView from 'react-native-webview';

import { IBlockProps } from '../types';

const debug = require('debug')('tca:blocks:BrowserBlock');

export interface BrowserBlockProps extends IBlockProps {
  height?: number | `${number}%`;
  scrollEnabled: boolean;
  url: string;
}

/** Initial support added in release 6.12.0 */
export default function BrowserBlock(
  props: BrowserBlockProps
): JSX.Element | null {
  const {
    height = '100%',
    topSpacing = null,
    bottomSpacing = null,
    horizontalSpacing,
    scrollEnabled,
    url,
  } = props;

  const { edgeSpacing, viewPortHeight } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const viewStyle = {
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
    marginHorizontal: edgeSpacing,
  };

  let webViewHeight = 0;

  if (typeof height === 'string' && height.endsWith('%')) {
    const percentage = parseInt(height.slice(0, -1), 10);

    if (!isNaN(percentage)) {
      webViewHeight = viewPortHeight * (percentage / 100);
    }
  } else {
    webViewHeight = height as number;
  }

  return (
    <View style={{ ...viewStyle, height: webViewHeight }}>
      {Platform.OS === 'web' ? (
        <iframe
          src={url}
          style={{ width: '100%', height: '100%', border: 0 }}
        />
      ) : (
        <WebView
          source={{ uri: url }}
          style={[{ width: '100%', height: '100%' }]}
          scrollEnabled={scrollEnabled}
        />
      )}
    </View>
  );
}
