import KitInfiniteScroll from '@omni/kit/components/KitInfiniteScroll';
import KitPagedList from '@omni/kit/components/KitPagedList';
import { useRootAppContext } from '@omni/kit/contexts/RootAppContext';
import { SearchResultItemProps } from '@omni/kit/feeds/searchListItemTypes';
import { Place } from '@omni/kit/placeTypes';
import {
  DOMAIN_APPS,
  DOMAIN_LIBRARY,
} from '@omni/kit/services/SearchService/Constants';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import memberAppLabelContextKey from '@omni/kit/utilities/memberAppLabelContextKey';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import FullPageLoader from '../../../search/components/FullPageLoader';
import NoResults from '../../components/NoResult';
import { Domain } from '../../types';
import BrowseBy from './BrowseBy';
import FeaturedApps from './FeaturedApps';
import SearchResultItem from './SearchResultItem';

interface Props<T> {
  appFilterValue: string;
  domain: Domain;
  onFetchData: (page?: number) => Promise<any[] | undefined>;
  hasNextPage: boolean;
  initialData: any[] | undefined;
  horizontalSpacing?: SpacingType;
  isLoading?: boolean;
  setSelectedPlace?: (value: Place | undefined) => void;
  searchable: boolean;
  total: number;
}

export default <T,>({
  appFilterValue,
  domain,
  onFetchData,
  hasNextPage,
  initialData,
  isLoading = false,
  setSelectedPlace,
  searchable,
  total,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation(['search']);
  const rootApp = useRootAppContext();
  const contextKey = memberAppLabelContextKey(rootApp);
  const [pagedData, setPagedData] = useState(initialData);

  const webRef = useRef<HTMLDivElement>(null);

  const onChangePage = useCallback(
    async (page: number) => {
      const newPage = await onFetchData(page);
      setPagedData(newPage);

      if (Platform.OS === 'web') {
        webRef.current?.parentElement?.scrollIntoView();
      }
    },
    [onFetchData]
  );

  useEffect(() => {
    setPagedData(initialData);
  }, [initialData]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!searchable) {
    return (
      <NoResults
        icon='search'
        title={t('search:notSearchableTitle')}
        description={t('search:notSearchableSubtitle', {
          context: contextKey,
          rootAppTitle: rootApp?.title,
        })}
      />
    );
  }

  if (!initialData) {
    switch (domain) {
      case DOMAIN_LIBRARY:
        return <BrowseBy appFilterValue={appFilterValue} />;
      case DOMAIN_APPS:
        return <FeaturedApps appFilterValue={appFilterValue} />;
      default:
        break;
    }
  }

  const renderItem = ({
    item,
    index,
  }: {
    item: SearchResultItemProps;
    index: number;
  }) => {
    return (
      <SearchResultItem
        appFilterValue={appFilterValue}
        item={item}
        index={index}
        setSelectedPlace={setSelectedPlace}
      />
    );
  };

  if (initialData?.length) {
    if (Platform.OS === 'web') {
      return (
        <KitPagedList
          ref={webRef as any}
          onChangePage={onChangePage}
          renderItem={renderItem}
          data={pagedData}
          total={total}
        />
      );
    } else {
      return (
        <KitInfiniteScroll
          onFetchData={onFetchData}
          renderItem={renderItem}
          hasMoreItems={hasNextPage}
          initialData={initialData}
        />
      );
    }
  }

  return (
    <NoResults
      icon='search'
      title={t('search:noResultsTitle', { context: 'default' })}
      description={t('search:noResultsSubtitle', { context: 'default' })}
    />
  );
};
