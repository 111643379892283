import BaseServiceV2, { IHttpResponse } from '../../BaseServiceV2';
import { IPrepareWebAppUrl, prepareWebApprUrl } from '../BuildUrl';

import { IWebApp } from '../Types';
import TokensService from '@omni/kit/services/TokensService';

const debug = require('debug')('tca:service:WebAppService:Core:GetAll');

interface IWebAppGetAll extends Omit<IPrepareWebAppUrl, 'id'> {
  getFromCache?: boolean;
  appKey?: string;
}

interface IWebAppGetAllResponse extends IHttpResponse {
  body?: {
    _embedded: {
      'web-apps': IWebApp[];
    };
  };
}

export default async ({
  appKey,
  ...buildUrlProps
}: IWebAppGetAll): Promise<IWebAppGetAllResponse> => {
  debug('GetAll');
  const url = prepareWebApprUrl(buildUrlProps);

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    appKey,
    token: guestToken,
  });
};
