import { KitButton, KitModal, KitText } from '@omni/kit/components';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import KitOptionSelector from '@omni/kit/components/KitOptionSelector';
import { IEvent } from '@omni/kit/services/EventsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, ScrollView, View } from 'react-native';

export interface EditSelectedEventModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  events: IEvent[];
  onSaveEvent: (event: IEvent) => void;
  selectedEvent?: IEvent;
}

const EditSelectedEventModal: React.FC<EditSelectedEventModalProps> = ({
  visible,
  setVisible,
  events,
  onSaveEvent,
  selectedEvent,
}): JSX.Element | null => {
  const { t } = useTranslation(['common', 'check-in']);
  const [selectedEventLocal, setSelectedEvent] = useState<IEvent | undefined>(
    selectedEvent
  );

  return visible ? (
    <View
      style={{
        padding: Spacing.xl,
        paddingBottom: 0,
        maxHeight: Dimensions.get('window').height - 150,
      }}
    >
      <KitText h2>{t('check-in:titleChooseEvent')}</KitText>
      <ScrollView
        style={{
          marginTop: Spacing.l,
          maxHeight: Dimensions.get('window').height - 200,
        }}
        showsVerticalScrollIndicator={false}
      >
        {/* Events */}
        <KitOptionSelector
          options={events.map((event) => ({
            ...event,
            subtitle: dateFormatRange({
              startDate: event.start_at,
              endDate: event.end_at,
              timeOnly: true,
              timezone: event.timezone,
            }),
          }))}
          selectedOption={events.findIndex(
            (option) => option.id === selectedEventLocal?.id
          )}
          onSelectOption={(index) => setSelectedEvent(events[index])}
          optionHeight={80}
          dynamicBoldStyle
          fontSize={16}
          rowMargin={8}
        />
      </ScrollView>
      <KitButton
        onPress={() => selectedEventLocal && onSaveEvent(selectedEventLocal)}
        title={t('common:buttonTitleContinue')}
        color={Colors.N900}
        textColor={Colors.N0}
        style={{ marginTop: Spacing.xl }}
        disabled={!selectedEventLocal}
      />
    </View>
  ) : null;
};

export default EditSelectedEventModal;
