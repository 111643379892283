import React from 'react';
import { Platform, TouchableNativeFeedback, View } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import styled from 'styled-components/native';

import Colors from '../theming/Colors';
import { colorForScheme } from '../theming/Theming';
import KitIcon from './KitIcon';

//******************************************************************************
// Types
//******************************************************************************
interface Props {
  icon?: string;
  onPress?: any;
  disabled?: boolean;
  children?: React.ReactNode;
  customStyle?: any;
}

const onButtonPress = () => {
  ReactNativeHapticFeedback.trigger('impactLight', {
    enableVibrateFallback: true,
    ignoreAndroidSystemSettings: false,
  });
};

//******************************************************************************
// Component
//******************************************************************************

export default function KitFloatingButton(props: Props): JSX.Element {
  return (
    <Wrapper {...props}>
      <ButtonWrapper disabled={props.disabled} style={props.customStyle}>
        {/* @ts-ignore */}
        <KitIcon name={props.icon} color={Colors.N0} size={20} />
      </ButtonWrapper>
    </Wrapper>
  );
}

function Wrapper({ children, disabled = false, onPress = () => null }: Props) {
  if (Platform.OS === 'android') {
    return (
      <View style={{ borderRadius: 28, overflow: 'hidden', elevation: 3 }}>
        <TouchableNativeFeedback
          disabled={disabled}
          onPress={() => {
            onButtonPress();
            onPress();
          }}
          useForeground={true}
        >
          {children}
        </TouchableNativeFeedback>
      </View>
    );
  } else {
    return (
      <ButtonContainerIOS
        disabled={disabled}
        onPress={() => {
          onButtonPress();
          onPress();
        }}
        underlayColor='#000'
      >
        {children}
      </ButtonContainerIOS>
    );
  }
}

//******************************************************************************
// Styles
//******************************************************************************
const ButtonContainerIOS = styled.TouchableHighlight`
  border-radius: 28;
  height: 56;
  width: 56;
`;

const ButtonWrapper = styled.View`
  align-items: center;
  background-color: ${colorForScheme({ default: Colors.brand })};
  border-radius: 28;
  height: 56;
  width: 56;
  justify-content: center;
  opacity: ${({ disabled }: Props) => (disabled ? 0.8 : 1.0)};

  /* iOS shadow */
  shadow-color: rgba(0, 0, 0, 0.35);
  shadow-offset: 0px 5px;
  shadow-opacity: 1;
  shadow-radius: 5px;
`;
