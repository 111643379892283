import { useEffect, useState } from 'react';

import Spacing from '../theming/Spacing';

// use this in cases where you want sensible defaults for spacing between items within a screen component,
// usage: see ScreenContext.tsx
export default function useInterItemSpacing(viewPortWidth: number): number {
  const [spacing, setSpacing] = useState(0);

  useEffect(() => {
    if (viewPortWidth > 0) {
      let spacing = 0;

      if (viewPortWidth <= 375) {
        spacing = Spacing.l;
      } else {
        spacing = Spacing.xl;
      }

      setSpacing(spacing);
    }
  }, [viewPortWidth]);

  return spacing;
}
