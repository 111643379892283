import { SizeClassV2 } from '.';
import Spacing from '../../theming/Spacing';

export default (sizeClass: SizeClassV2): number => {
  switch (sizeClass) {
    case SizeClassV2.XXS:
    case SizeClassV2.XS:
      return Spacing.l;
    case SizeClassV2.S:
      return Spacing.xl;
    case SizeClassV2.M:
    case SizeClassV2.L:
      return 48;
    default:
      return 0;
  }
};
