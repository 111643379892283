import { useEffect, useState } from 'react';

import Spacing from '../theming/Spacing';

// use this in cases where you want sensible defaults for margins of a screen component
// usage: see ScreenContext.tsx
export default function useEdgeSpacing(viewPortWidth: number): number {
  const [spacing, setSpacing] = useState(0);

  useEffect(() => {
    if (viewPortWidth > 0) {
      const spacing = calculateSpacing(viewPortWidth);
      setSpacing(spacing);
    }
  }, [viewPortWidth]);

  return spacing;
}

export function calculateSpacing(width: number): number {
  let spacing = 0;

  if (width < 640) {
    // XS screens
    spacing = Spacing.l; // 18
  } else if (width >= 640 && width < 768) {
    // SM screens
    spacing = Spacing.xl; // 24
  } else if (width >= 768 && width < 960) {
    // MD screens
    spacing = Spacing.xxxl; // 48
  } else if (width >= 960) {
    // LG screens
    const maxWidth = Math.min(800, width - 380);
    const availableWidth = width - maxWidth;
    spacing = availableWidth / 2;
  }

  return spacing;
}
