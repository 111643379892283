import { KitText, KitTouchable } from '@omni/kit/components';
import KitList from '@omni/kit/components/KitList';
import KitLoader from '@omni/kit/components/KitLoader';
import LeftNavButton, { LeftNavType } from '@omni/kit/components/LeftNavButton';
import Show from '@omni/kit/components/Show';
import {
  ScreenContextProvider,
  useScreenContext,
} from '@omni/kit/contexts/ScreenContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import {
  NativeTopBarStyle,
  PresentationStyle,
} from '@omni/kit/utilities/NativeHelpers';
import { updateOnScrollTopBarStyle } from '@omni/kit/utilities/updateOnScrollTopBarStyle';
import React, { useContext } from 'react';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { RootSiblingParent } from 'react-native-root-siblings';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useBlockPageContext } from './BlockPageContext';
import { renderBlock } from './blocks/resolver';
import { BlockData, IInsetStyles } from './blocks/types';
import { getStyleForInsets } from './blocks/utilities';
import { BlockPageProps } from './types';

export default function BlockPageContent(props: BlockPageProps): JSX.Element {
  const {
    initialBlockPageContextState,
    leftNavButtonStyle,
    pageGradientEnabled,
    presentationStyle,
    topBarStyle,
    onScrollTopBarStyle,
  } = props;

  const {
    addressRequired,
    align,
    animationEnabled,
    appKey,
    authProviderId,
    blockPageDeltaInsetTop,
    blockPageUrl,
    blocksData,
    clientBrandColor,
    guestToken,
    includeCloseButton,
    includeLogoutButton,
    isBlockPageEdited,
    isBlockPageLoading,
    isBlockPageRefreshing,
    isBlockPageReplacing,
    largeSaveButtonEnabled,
    onDismiss,
    refreshBlockPage,
    setCurrentTopBarStyle,
    setIsBlockPageEdited,
    setIsBlockPageLoading,
    setIsBlockPageRefreshing,
    submitActionRef,
  } = useBlockPageContext();

  const { colorForScheme } = useContext(ThemeContext);

  const insets = useSafeAreaInsets();
  const insetStyles: IInsetStyles = getStyleForInsets(insets);

  const _onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>): void => {
    setCurrentTopBarStyle(
      updateOnScrollTopBarStyle(event, topBarStyle, onScrollTopBarStyle)
    );
  };

  const _renderBlock = ({
    item,
    index,
  }: {
    item: BlockData;
    index: number;
  }): JSX.Element | null => {
    let insetStyle: ViewStyle = insetStyles.mid;

    // apply inset for first block
    if (index === 0) {
      insetStyle = {
        ...insetStyles.top,
        paddingTop:
          insets.top +
          (align /*vertical*/ === 'center' ? 0 : blockPageDeltaInsetTop),
      };
    }

    // apply inset for last block
    if (index === blocksData.length - 1) {
      insetStyle = {
        // ARTEMIS-1237: Preserve top inset for case where page has a single block.
        ...insetStyle,

        ...insetStyles.bottom,
      };
    }

    const componentProps = { ...item.props, index, insetStyle };

    return renderBlock(
      item,
      componentProps,

      // TODO: remove these props from renderBlock and use from BlockPageContext inside relevant Blocks
      authProviderId,
      guestToken,
      appKey,
      {
        addressRequired,
        clientBrandColor,
        includeLogoutButton,
        largeSaveButtonEnabled,
        isLoading: isBlockPageLoading,
        onDismiss,
        setIsEdited: setIsBlockPageEdited,
        setIsLoading: setIsBlockPageLoading,
        setRefreshing: setIsBlockPageRefreshing,
        submitActionRef,
      }
    );
  };

  const onSavePress = () => {
    submitActionRef.current();
  };

  const CloseAndSaveButtons = (): JSX.Element => {
    // TODO: Can this assignment be removed?
    const { edgeSpacing: _edgeSpacing } = useScreenContext({
      fixedSpacingType: Platform.OS === 'web' ? SpacingType.Small : undefined,
    });
    // Optionally show save button in navbar (vs. bottom of component).
    const includeSaveButton = !largeSaveButtonEnabled;

    return (
      <View>
        <View
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: Spacing.xl,
            marginTop: insets.top,
          }}
        >
          <Show show={includeCloseButton}>
            <LeftNavButton
              onPress={() => onDismiss()}
              title=''
              iconSize={20}
              type={
                leftNavButtonStyle === LeftNavType.Back
                  ? LeftNavType.Back
                  : LeftNavType.Dismiss
              }
            />
          </Show>

          <Show show={includeSaveButton}>
            <>
              <KitTouchable
                onPress={isBlockPageEdited ? onSavePress : undefined}
                style={{
                  marginLeft: !includeCloseButton ? 'auto' : undefined,
                  opacity: isBlockPageEdited ? 1 : 0,
                }}
              >
                <KitText
                  brandColor
                  semiBold
                  fontSize={17}
                  testID={isBlockPageEdited ? 'SaveText' : 'SaveTextHidden'}
                >
                  Save
                </KitText>
              </KitTouchable>

              <Show show={isBlockPageLoading}>
                <KitLoader small style={{ marginRight: Spacing.s }} />
              </Show>
            </>
          </Show>
        </View>
      </View>
    );
  };

  return (
    <RootSiblingParent>
      <ScreenContextProvider
        initialState={initialBlockPageContextState}
        style={{
          ...(presentationStyle !== PresentationStyle.KitModal && { flex: 1 }),
          backgroundColor: colorForScheme?.({
            dark: Colors.N1000,
            light: Colors.N0,
          }),
        }}
      >
        {pageGradientEnabled && (
          <LinearGradient
            colors={[Colors.N0, Colors.N50]}
            style={{ position: 'absolute', height: '100%', width: '100%' }}
          />
        )}
        <Show show={topBarStyle === NativeTopBarStyle.Hidden}>
          <CloseAndSaveButtons />
        </Show>
        <KitList
          animationEnabled={animationEnabled}
          align={align}
          data={blocksData as BlockData[]}
          keyboardShouldPersistTaps='handled'
          keyExtractor={(_, index) => index.toString()}
          onRefresh={blockPageUrl ? refreshBlockPage : null}
          onScroll={_onScroll}
          refreshing={isBlockPageRefreshing || isBlockPageReplacing}
          renderItem={_renderBlock}
          scrollEventThrottle={5}
        />
        {blocksData?.length === 0 && (
          <LinearGradient
            colors={['#00000033', '#00000000']}
            style={styles.gradient}
          />
        )}
        {blocksData?.length === 0 && (
          <View style={styles.loadingIndicator}>
            <KitLoader />
          </View>
        )}
      </ScreenContextProvider>
    </RootSiblingParent>
  );
}

const styles = StyleSheet.create({
  gradient: {
    backgroundColor: '#ffffff00',
    height: 200,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 5,
  },
  loadingIndicator: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});
