import { KitButton } from '@omni/kit/components';
import { KitButtonProps } from '@omni/kit/components/KitButton';
import Colors from '@omni/kit/theming/Colors';
import React, { useMemo } from 'react';

export default function KioskModalButton(props: KitButtonProps): JSX.Element {
  const color = useMemo(() => {
    if (props.secondary) {
      return Colors.N100;
    } else if (props.red) {
      return undefined;
    } else {
      return Colors.N900;
    }
  }, [props.secondary, props.red]);

  return (
    <KitButton
      color={color}
      paddingLeft={32}
      paddingRight={32}
      titleStyle={{ fontSize: 20, fontWeight: 'bold' }}
      height={54}
      borderRadius={37}
      isLoading={props.isLoading}
      {...props}
    />
  );
}
