import { StyleProp, ViewStyle } from 'react-native';

export enum EVerticalAlignAchor {
  TOP = 'top',
  BOT = 'bottom',
}

export enum EHorizontalAlignAchor {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface ICalloutProps {
  title?: string;
  body?: string;
  icon?: string;
  verticalAlignAnchor?: EVerticalAlignAchor;
  horizontalAlignAnchor?: EHorizontalAlignAchor;
  anchorX?: number;
  anchorY?: number;
  showCallout?: boolean;
  dismiss?: () => void;
  backgroundColor?: string;
  textColor?: string;
  underlayColor?: string;
  calloutPadding?: number;
  customStyles?: StyleProp<ViewStyle>;
  animate?: boolean;
  delay?: number;
  fullScreenModal?: boolean;
  themed?: boolean;
}

export interface ICalloutDimension {
  height: number;
  width: number;
}

export interface ICalloutPosition {
  left: number;
  top: number;
}

export interface IAnchorPosition {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}
