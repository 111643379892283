import Environment from '@omni/kit/Environment';
import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IInclude,
  IUri,
  buildUrl,
} from '@omni/kit/services/BaseServiceV2';
import { ISession } from '@omni/kit/services/EventsService/Types';

const HOST = Environment.checkInService;
const RESOURCE = 'suggested-sessions';

interface IGetSuggestedSessions extends IUri {
  token?: string;
  includes?: IInclude[];
}

interface IGetAllSuggestedSessionsResponse extends IHttpResponse {
  body?: {
    _embedded: {
      sessions: ISession[];
    };
    count: number;
    _links: {
      self: {
        href: string;
      };
    };
  };
}

export default async function GetSuggestedSessions({
  token,
  includes,
  sorts,
  filters,
}: IGetSuggestedSessions): Promise<IGetAllSuggestedSessionsResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    includes,
    sorts,
    filters,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
