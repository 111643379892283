import { getPushToken } from '@omni/kit/utilities/NativeHelpers';
import { sbCreateSessionHandler } from '@omni/kit/utilities/sendbirdUserFunctions';
import SendbirdChat, { ApplicationUserListQuery, User } from '@sendbird/chat';
import { SendbirdGroupChat } from '@sendbird/chat/groupChannel';
import { NativeModules, Platform } from 'react-native';

import { getStore } from '../../shared/redux/store';
import { init } from './sb';

const debug = require('debug')('tca:chat:utilities:sendbird:userFunctions');

export const sbRegisterPushToken = (): Promise<void> => {
  return new Promise(async (resolve, _reject) => {
    if (Platform.OS === 'web') {
      debug(
        'Platform not supported for push notifications. Skipping sbRegisterPushToken'
      );
      resolve();

      return;
    }

    if (!sbIsConnected()) {
      debug('Not connected. Skipping sbRegisterPushToken');
      resolve();

      return;
    }

    let token: string | undefined;

    try {
      token = await getPushToken();
    } catch {
      debug('No device push token exists. Skipping sbRegisterPushToken');
    }

    // check connection state again after bridge callback
    // to double check that we're still connected to SendBird
    const connected = sbIsConnected();

    if (!connected) {
      debug(
        'Not connected after bridge callback. Skipping sbRegisterPushToken'
      );
    }

    if (token && connected) {
      if (Platform.OS === 'ios') {
        debug(`Register iOS push token: ${token}`);

        try {
          await SendbirdChat.instance.registerAPNSPushTokenForCurrentUser(
            token
          );
        } catch {
          debug('Failed to register token');
        }
      } else if (Platform.OS === 'android') {
        debug(`Register Android push token: ${token}`);

        try {
          await SendbirdChat.instance.registerFCMPushTokenForCurrentUser(token);
        } catch {
          debug('Failed to register token');
        }
      }
    }

    resolve();
  });
};

export const sbUnregisterPushToken = (): Promise<void> => {
  return new Promise((resolve, _reject) => {
    if (!NativeModules.ReactPlatformBridge) {
      debug('No bridge. Skipping sbUnregisterPushToken');

      resolve();

      return;
    }

    if (!sbIsConnected()) {
      debug('Not connected. Skipping sbUnregisterPushToken');
      resolve();

      return;
    }

    NativeModules.ReactPlatformBridge.getPushToken((token?: string) => {
      if (!token) {
        debug('Push device token unavailable. Skipping unregister step.');

        resolve();

        return;
      }

      if (!sbIsConnected()) {
        debug(
          'Not connected after bridge callback. Skipping sbUnregisterPushToken'
        );
        resolve();

        return;
      }

      if (Platform.OS === 'ios') {
        SendbirdChat.instance
          .unregisterAPNSPushTokenForCurrentUser(token)
          .finally(() => resolve());
      } else if (Platform.OS === 'android') {
        SendbirdChat.instance
          .unregisterFCMPushTokenForCurrentUser(token)
          .finally(() => resolve());
      } else {
        // no-op: do nothing on other platforms
        resolve();
      }
    });
  });
};

export const sbConnect = ({
  userId,
  token,
  appId,
}: {
  userId: string;
  token: string;
  appId: string;
}): Promise<User> => {
  debug('sbConnect()');
  init(appId);

  const store = getStore();
  const appKey = store.getState().system.appKey;
  sbCreateSessionHandler(appKey);

  return SendbirdChat.instance.connect(userId, token);
};

export const sbIsConnected = (): boolean => {
  return (
    Boolean(SendbirdChat.instance?.currentUser) &&
    SendbirdChat.instance?.connectionState === 'OPEN'
  );
};

export const sbHasUser = (): boolean => {
  return Boolean(SendbirdChat.instance?.currentUser);
};

export const sbDisconnect = async (): Promise<void> => {
  try {
    debug('sbDisconnect() ', SendbirdChat.instance?.connectionState);

    if (
      SendbirdChat.instance &&
      SendbirdChat.instance.connectionState === 'OPEN'
    ) {
      await SendbirdChat.instance.disconnect();

      debug('sbDisconnect() ', SendbirdChat.instance?.connectionState);
    }
  } catch (e) {
    debug('Error on sbDisconnect', e);
  }
};

export const sbGetTotalUnreadMessageCount = (): Promise<number> => {
  if (!sbIsConnected()) {
    return Promise.reject(
      'Not connected. Skipping sbGetTotalUnreadMessageCount'
    );
  }

  return (
    SendbirdChat.instance as SendbirdGroupChat
  ).groupChannel.getTotalUnreadMessageCount();
};

export const sbCreateApplicationUserListQuery =
  (): ApplicationUserListQuery | null => {
    if (!sbIsConnected()) {
      debug('Not connected. Skipping sbCreateApplicationUserListQuery');

      return null;
    }

    if (SendbirdChat.instance) {
      return SendbirdChat.instance.createApplicationUserListQuery({
        limit: 100,
      });
    } else {
      return null;
    }
  };

// @ts-ignore
export const sbGetAppUsers = async (
  applicationUserListQuery: ApplicationUserListQuery,
  userList: User[] = []
): Promise<User[]> => {
  if (!applicationUserListQuery) {
    return userList;
  }

  if (!sbIsConnected()) {
    debug('Skipping sbGetAppUsers. Not connected.');

    return userList;
  }

  try {
    const userPage = await getNextUserPage(applicationUserListQuery);
    userList = [...userPage, ...userList];
    const hasNext = applicationUserListQuery.hasNext;

    if (!hasNext) {
      return userList;
    } else return await sbGetAppUsers(applicationUserListQuery, userList);
  } catch (e) {
    debug(`sbGetAppUsers failed. Error: ${JSON.stringify(e)}`);
  }

  return userList;
};

const getNextUserPage = (
  applicationUserListQuery: ApplicationUserListQuery
) => {
  if (!sbIsConnected()) {
    debug('Skipping getNextUserPage query. Not connected.');

    return Promise.reject('Skipping getNextUserPage. Not connected');
  }

  return applicationUserListQuery.next();
};
