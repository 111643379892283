'use client';

import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit';
import { KitButton, KitLink, KitLoader, KitText } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitCopyrightLogo from '@omni/kit/components/KitCopyrightLogo';
import { useSourceContext } from '@omni/kit/contexts/SourceContext';
import { buildAppIconUrl } from '@omni/kit/hooks/useFavicon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PixelRatio, SafeAreaView, StyleSheet, View } from 'react-native';

import useShellAuthProviders from '../contexts/ShellContext/hooks/useShellAuthProviders';
import { AuthProvider, AuthService } from './index';

const debug = require('debug')('tca:auth:AuthProviderList');

interface AuthProviderListProps {
  appKey: string;
  customReturnUrl?: string;
  customHeader?: JSX.Element;
  appBrandingUrl?: string;
  navigation?: StackNavigationProp<ParamListBase>;
  openInNewTab?: boolean;
  orgKey?: string;
  targetAuthProviderId: string;
  showFooter?: boolean;
}

export default function AuthProviderList({
  appKey,
  customReturnUrl,
  customHeader,
  appBrandingUrl,
  openInNewTab,
  orgKey,
  targetAuthProviderId,
  showFooter = true,
}: AuthProviderListProps): JSX.Element {
  const source = useSourceContext();

  const { isFetchingAuthProviders, loginEnabledProviders, providers } =
    useShellAuthProviders();

  const targetProvider = providers.find(
    (c) => c.authproviderid === targetAuthProviderId
  );

  // Enterprise apps with custom login providers will not show the Subsplash branding (e.g. Ligonier and Wild at Heart)
  const suppressSubsplashBranding =
    loginEnabledProviders.length > 0 &&
    providers.find(
      (authProvider) =>
        authProvider.authproviderid === SUBSPLASH_AUTH_PROVIDER_ID &&
        !authProvider.enable_login
    );

  return (
    <SafeAreaView
      style={{
        alignItems: 'center',
        backgroundColor: Colors.N0,
        justifyContent: 'center',
        flexGrow: 1, // needed to fill the remaining space
      }}
    >
      {isFetchingAuthProviders ? (
        <View style={styles.loadingStyle}>
          <KitLoader />
        </View>
      ) : (
        <View style={styles.container}>
          <View
            style={[
              styles.authProviderContainer,
              suppressSubsplashBranding && { marginBottom: 0 },
            ]}
          >
            {customHeader || (
              <LoginHeader appKey={appKey} appBrandingUrl={appBrandingUrl} />
            )}
            <View style={styles.authProvider}>
              <LoginButtons
                appKey={appKey}
                customReturnUrl={customReturnUrl}
                loginEnabledProviders={loginEnabledProviders || []}
                openInNewTab={openInNewTab}
                source={source}
                targetProvider={targetProvider}
              />
              {!suppressSubsplashBranding && (
                <KitText
                  center
                  fontSize={14}
                  style={{ marginTop: 6, paddingHorizontal: Spacing.xs }}
                >
                  By continuing you agree to Subsplash's{' '}
                  <KitLink
                    href='https://www.subsplash.com/legal/terms-of-use'
                    fontSize={14}
                  >
                    Terms of Use
                  </KitLink>{' '}
                  and{' '}
                  <KitLink
                    href='https://www.subsplash.com/legal/privacy'
                    fontSize={14}
                  >
                    Privacy Policy
                  </KitLink>
                  .
                </KitText>
              )}
            </View>
          </View>
          {showFooter && !suppressSubsplashBranding && <KitCopyrightLogo />}
        </View>
      )}
    </SafeAreaView>
  );
}

const LoginButtons = ({
  appKey,
  customReturnUrl,
  loginEnabledProviders,
  openInNewTab,
  source,
  targetProvider,
}: {
  appKey: string;
  customReturnUrl?: string;
  loginEnabledProviders: AuthProvider[];
  openInNewTab?: boolean;
  source?: string;
  targetProvider?: AuthProvider;
}): JSX.Element => {
  const { t } = useTranslation(['common']);

  if (loginEnabledProviders.length === 1) {
    const provider = loginEnabledProviders[0];

    return (
      <View key={provider.authproviderid} style={{ marginBottom: Spacing.m }}>
        <KitButton
          titleStyle={{ marginLeft: 10 }}
          onPress={() =>
            AuthService.authenticate({
              appKey,
              provider,
              targetProvider,
              customReturnUrl,
              source,
              openInNewTab,
            })
          }
          title={t('common:buttonTitleLogInOrSignUp')}
          color={Colors.N900}
          secondary={false}
        />
      </View>
    );
  }

  return (
    <>
      {loginEnabledProviders.map((provider) => (
        <View key={provider.authproviderid} style={{ marginBottom: Spacing.m }}>
          <KitButton
            icon={provider.icon}
            titleStyle={{ marginLeft: 10 }}
            onPress={() =>
              AuthService.authenticate({
                appKey,
                provider,
                targetProvider,
                customReturnUrl,
                source,
                openInNewTab,
              })
            }
            title={`Continue with ${provider.title}`}
            color={provider.service_name === 'apple' ? Colors.N1000 : undefined}
            secondary={provider.service_name !== 'apple'}
          />
        </View>
      ))}
    </>
  );
};

const LoginHeader = ({
  appKey,
  appBrandingUrl,
}: {
  appKey: string;
  appBrandingUrl?: string;
}) => {
  const { t } = useTranslation(['auth']);

  return (
    <>
      <KitAvatar
        imageUrl={
          appBrandingUrl ||
          buildAppIconUrl(appKey, PixelRatio.getPixelSizeForLayoutSize(64))
        }
        nickname={appKey}
        size={64}
      />
      <KitText center h1 style={{ marginTop: Spacing.m }}>
        {t('auth:loginHeaderTitle')}
      </KitText>
    </>
  );
};

const styles = StyleSheet.create({
  appleWarningBox: {
    alignItems: 'center',
    backgroundColor: Colors.N50,
    borderRadius: 6,
    flexDirection: 'row',
    marginTop: Spacing.xxl,
    maxWidth: 423,
    padding: Spacing.l,
  },
  authProvider: {
    marginTop: Spacing.xl,
    width: '100%',
  },
  authProviderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: Spacing.xxl,
    paddingHorizontal: Spacing.s,
    maxWidth: 350,
    width: '100%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 455,
    width: '100%',
  },
  brandingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  loadingStyle: {
    alignItems: 'center',
    minHeight: 200,
    justifyContent: 'center',
  },
});
