import {
  BLOCKED_USER_LIST_SCREEN,
  CHAT_SETTINGS_SCREEN,
} from '../../../shared/Constants';

import BlockedUserListScreen from '../../screens/BlockedUserListScreen';
import ChatSettingsScreen from '../../screens/ChatSettingsScreen';
import { LeftNavType } from '../../components/LeftNavButton';
import { Platform } from 'react-native';
import React from 'react';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { createStackNavigator } from '@react-navigation/stack';
import { getNavOptions } from '../AppNavigator';

// @ts-ignore
const Stack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

type Props = {
  onBackPress?: () => void;
};

export const ChatSettingsStack = ({
  onBackPress = undefined,
}: Props): JSX.Element => {
  return (
    <Stack.Navigator
      screenOptions={{
        // @ts-ignore
        headerHideShadow: true,
        headerStyle: {
          borderBottomWidth: 0,
          elevation: 0,
          shadowColor: 'transparent',
        },
        headerTopInsetEnabled: false, // react-native-screens (Android)
      }}
    >
      <Stack.Screen
        name={CHAT_SETTINGS_SCREEN}
        component={ChatSettingsScreen}
        options={(props) => ({
          ...getNavOptions(
            'User Settings',
            props,
            LeftNavType.Dismiss,
            onBackPress
          ),
        })}
      />
      <Stack.Screen
        name={BLOCKED_USER_LIST_SCREEN}
        component={BlockedUserListScreen}
        options={(props) => ({
          ...getNavOptions('Blocked', props),
        })}
      />
    </Stack.Navigator>
  );
};
