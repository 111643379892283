import { useAuth } from '@omni/kit/contexts/AuthContext';
import { useEffect, useState } from 'react';

import { AuthService } from '../auth';

interface Props {
  showLoginOptions: () => void;
}

interface DispatchAuthPromptProps {
  appKey: string;
  orgKey: string;
  source: string;
  targetAuthProviderId: string;
  customReturnUrl?: string;
  openInNewTab?: boolean;
}

interface ReturnType {
  dispatchAuthPrompt(props: DispatchAuthPromptProps): void;
}

export default function useAuthPrompt({ showLoginOptions }: Props): ReturnType {
  const [pendingDispatch, setPendingDispatch] = useState<
    DispatchAuthPromptProps | undefined
  >(undefined);

  const { loginEnabledProviders, providers } = useAuth();

  useEffect(() => {
    if (!pendingDispatch) {
      return;
    }

    if (providers.length === 0 || loginEnabledProviders.length === 0) {
      return;
    }

    const targetProvider = providers.find(
      (c) => c.authproviderid === pendingDispatch?.targetAuthProviderId
    );

    if (!targetProvider) {
      return;
    }

    // If there's only one valid login option, auto-select it immediately (e.g. Ligonier and Wild at Heart)
    if (loginEnabledProviders.length === 1) {
      AuthService.authenticate({
        provider: loginEnabledProviders[0],
        targetProvider: targetProvider,
        appKey: pendingDispatch.appKey,
        customReturnUrl: pendingDispatch.customReturnUrl,
        source: pendingDispatch.source,
        openInNewTab: pendingDispatch.openInNewTab,
      });
    } else {
      showLoginOptions();
    }

    setPendingDispatch(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingDispatch, providers, loginEnabledProviders]);

  const dispatchAuthPrompt = (props: DispatchAuthPromptProps) => {
    setPendingDispatch(props);
  };

  return {
    dispatchAuthPrompt,
  };
}
