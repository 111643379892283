import { getMaxWidth, getPadding } from '.';

interface ContainerWidth {
  padding: number;
  isMaxWidthActive: boolean;
  maxWidth: number;
  width: number;
  windowMaxWidth: number;
}

export default (
  windowWidth: number,
  containerSize: 's' | 'm' | 'l'
): ContainerWidth => {
  const containerMaxWidth = getMaxWidth(containerSize);
  const containerPadding = getPadding(containerSize, windowWidth);

  const windowMaxWidth = containerMaxWidth + containerPadding * 2;
  const isContainerMaxWidthActive = windowWidth >= windowMaxWidth;

  const containerWidth = isContainerMaxWidthActive
    ? containerMaxWidth
    : windowWidth - containerPadding * 2;

  return {
    padding: containerPadding,
    isMaxWidthActive: containerWidth >= containerMaxWidth,
    maxWidth: containerMaxWidth,
    width: containerWidth,
    windowMaxWidth,
  };
};
