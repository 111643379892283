import { AuthService } from '../../auth';
import { fetchTargetAuthProviders } from '../../auth/Service/fetchTargetAuthProviders';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '../../constants/identifiers';
import { FALLBACK_APPKEY } from './constants';

export default async (appKey = FALLBACK_APPKEY): Promise<string | null> => {
  const targetProviders = await fetchTargetAuthProviders(appKey);

  let accessToken: string | null = null;

  try {
    const accessTokens = await AuthService.loadCredentials({
      appKey,
      source: 'app',
      targetProviders,
    });

    accessToken = accessTokens[SUBSPLASH_AUTH_PROVIDER_ID];
  } catch (e) {}

  return accessToken;
};
