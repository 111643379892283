import * as ActionHandler from '@omni/kit/ActionHandler';
import GetAppHeaders from '@omni/kit/Downloader/GetAppHeaders';
import Environment from '@omni/kit/Environment';
import {
  ANY_AUTH_PROVIDER_ID,
  SUBSPLASH_AUTH_PROVIDER_ID,
} from '@omni/kit/constants/identifiers';
import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import {
  dispatchAction,
  getRootAppKey,
  setRefreshToken,
  setTokenData,
} from '@omni/kit/utilities/NativeHelpers';
import appendUrlParams from '@omni/kit/utilities/appendUrlParams';
import {
  EmitterSubscription,
  NativeEventEmitter,
  NativeModules,
  Platform,
} from 'react-native';

import { POPUP_HEIGHT, POPUP_WIDTH } from '../Constants';
import { AuthProvider, CredentialProps } from './Types';
import { requestAccessToken } from './requestAccessToken';
import { parseTokenUrlForAuthProviderId } from './utilities';

const debug = require('debug')('omni:kit:AuthService');

const { ReactPlatformBridge } = NativeModules;

interface ListenerObject {
  context?: string; // useful for debugging which contexts auth came from
  callback: () => void;
}

export class AuthService {
  public static _currentAppKey?: string = undefined;
  public static _targetProviders: AuthProvider[] = [];

  private static _accessTokenMap: {
    [key: string]: string | null;
  } = {};

  private static _refreshTokenEndpointMap: {
    [key: string]: string | null;
  } = {};

  private static _refreshToken: string | null = null;

  public static isRefreshing = false;

  // only public for unit tests and deep links
  public static lastTokenRefreshTime = 0;
  public static lastTokenRefreshAppKey = '';

  public static async loadCredentials(props: CredentialProps): Promise<{
    [key: string]: string | null;
  }> {
    const { appKey, targetProviders, sapToken, source } = props;

    if (!targetProviders) {
      return {};
    }

    this._currentAppKey = appKey;
    this._targetProviders = targetProviders;

    for (const provider of targetProviders) {
      const authProviderId = provider.authproviderid;
      const tokenUrl = provider.token_url;

      try {
        const authProviderId = provider.authproviderid;

        /**
         * Restore session from cached credentials:
         * These credentials are less likely to be expired compared to root props
         * and have a higher chance of producing cache-hits for feed requests
         * that require Authorization since the accessToken is refreshed
         * within this method prior to rendering children of the provider.
         */
        await this.loadSavedCredentials(authProviderId);

        /**
         * User may have switched apps in a container app.
         * Always use tokenUrl from props when available
         * to avoid making requests with wrong app_key param in /token URL
         */
        if (tokenUrl) {
          this._refreshTokenEndpointMap[authProviderId] = tokenUrl;
        }
      } catch (e) {
        debug(`Unable to get access token for ${authProviderId}: ${e}`);
      }
    }

    /**
     * Check root props for a sapToken, but only apply if unset in AuthService
     * to reduce chance of using an expired sapToken
     */
    if (sapToken && !AuthService.refreshToken) {
      this.refreshToken = sapToken;
    }

    /**
     * The access token may be expired.
     * Try to keep the user logged-in by executing token refresh on all auth providers.
     * If the refreshToken is expired, then token refresh will fail
     * and the user must be prompted to login.
     *
     * We need to refresh the token for all auth providers to support use-cases
     * where a Subsplash user token and an enterprise user token
     * are used on the same screen.
     * e.g. Personalized Account Menu (PAM) in Wild at Heart (WAH)
     * which has a custom profile header url that fetches user info from
     * a WAH domain which requires a WAH user token.
     *
     * We ignore errors such as network request failure when user is offline
     * and keep the token state as-is so that we can continue to get cache-hits
     * on feed request that rely on Authorization such as Block Page User Profile
     *
     * However, if a 401/403 occurs, the token state will be cleared
     * and user will be prompted to login.
     *
     * Also, loadCredentials will be called from multiple micro-apps
     * such as the AccountMenuButtonWrapper and Block Page,
     * so we avoid unnecessary refresh by allowing one refresh every 30 seconds.
     * We no longer need this workaround when we wrap the entire app in a
     * ApplicationContextProvider instead of each micro-app.
     */

    const targetProviderIdSet = targetProviders.reduce(
      (accumulator, currentValue) => {
        accumulator.add(currentValue.authproviderid);

        return accumulator;
      },
      new Set<string>()
    );
    const targetProviderIds = Array.from(targetProviderIdSet);

    await AuthService.refreshAccessTokens(appKey, targetProviderIds, source);

    return this._accessTokenMap;
  }

  private static isTokenRefreshAllowed(appKey: string): boolean {
    const time = new Date().getTime();

    return (
      !AuthService.lastTokenRefreshAppKey ||
      AuthService.lastTokenRefreshAppKey !== appKey ||
      !AuthService.lastTokenRefreshTime ||
      time - AuthService.lastTokenRefreshTime > 30 * 1000
    );
  }

  public static get refreshToken(): string | null {
    return this._refreshToken;
  }

  public static set refreshToken(value: string | null) {
    if (value) {
      setStoredItem('refresh_token', value);
    } else {
      removeStoredItem('refresh_token');
    }

    this._refreshToken = value;
  }

  // deprecated, replaced by sendTokenDataToNative
  // Keeping here for remaining use-cases, like deep links for testing tokens
  public static sendRefreshTokenToNative(): void {
    if (this.refreshToken) {
      setRefreshToken(this.refreshToken);
    }
  }

  public static sendTokenDataToNative({
    authProviderId,
    accessToken,
    refreshToken,
  }: {
    authProviderId: string;
    accessToken: string;
    refreshToken: string;
  }): void {
    setTokenData({ authProviderId, accessToken, refreshToken });
  }

  public static getRefreshTokenEndpoint(authProviderId: string): string | null {
    return this._refreshTokenEndpointMap[authProviderId] || null;
  }

  // return the Subsplash refreshTokenEndpoint
  // to get a refreshTokenEndpoint for a different auth provider, use getRefreshTokenEndpoint(authProviderId)
  public static get refreshTokenEndpoint(): string | null {
    return this._refreshTokenEndpointMap[SUBSPLASH_AUTH_PROVIDER_ID];
  }

  public static setRefreshTokenEndpoint(
    authProviderId: string,
    value: string | null
  ): void {
    if (value) {
      setStoredItem(`refresh_token_endpoint_${authProviderId}`, value);
    } else {
      removeStoredItem(`refresh_token_endpoint_${authProviderId}`);
    }

    this._refreshTokenEndpointMap[authProviderId] = value;
  }

  public static getCurrentAppKey(): string | undefined {
    return this._currentAppKey;
  }

  public static getAccessToken(authProviderId: string): string | null {
    return this._accessTokenMap[authProviderId] || null;
  }

  public static getAccessTokens(): { [key: string]: string | null } {
    return this._accessTokenMap;
  }

  // return the Subsplash access token
  // to get a token for a different auth provider, use getAccessToken(authProviderId)
  public static get accessToken(): string | null {
    return this._accessTokenMap[SUBSPLASH_AUTH_PROVIDER_ID] || null;
  }

  public static get accessTokenWithBearer(): string | null {
    const token = this._accessTokenMap[SUBSPLASH_AUTH_PROVIDER_ID] || null;

    return token ? `Bearer ${token.replace('Bearer ', '')}` : null;
  }

  public static setAccessToken(
    authProviderId: string,
    token: string | null
  ): void {
    this._accessTokenMap[authProviderId] = token;

    if (token) {
      setStoredItem(`access_token_${authProviderId}`, token);
    } else {
      removeStoredItem(`access_token_${authProviderId}`);
    }
  }

  public static notifyAuthorizationChanged(): void {
    this._dispatch('AuthorizationChanged');
  }

  private static bridgeListener?: EmitterSubscription;

  public static initialize(): void {
    if (ReactPlatformBridge) {
      const _listener = (event: {
        appKey: string;
        authProviderId: string;
        token?: string;
        refreshToken?: string;
        refreshTokenUrl?: string;
        source?: string;
      }) => {
        const {
          appKey,
          authProviderId,
          refreshToken,
          refreshTokenUrl,
          token,
          source,
        } = event;

        this.refreshToken = refreshToken || null;
        this._refreshTokenEndpointMap[authProviderId] = refreshTokenUrl || null;
        this.setAccessToken(
          authProviderId,
          token?.replace('Bearer ', '') || null
        );

        // refresh token for other auth providers
        const targetProviderIdSet = AuthService._targetProviders
          .filter((provider) => provider.authproviderid !== authProviderId)
          .reduce((accumulator, currentValue) => {
            accumulator.add(currentValue.authproviderid);

            return accumulator;
          }, new Set<string>());
        const targetProviderIds = Array.from(targetProviderIdSet);

        AuthService.lastTokenRefreshAppKey = '';
        AuthService.lastTokenRefreshTime = 0;

        AuthService.refreshAccessTokens(appKey, targetProviderIds, source);
      };

      const ReactPlatformBridgeEventEmitter = new NativeEventEmitter(
        ReactPlatformBridge
      );

      AuthService.bridgeListener?.remove();
      AuthService.bridgeListener = ReactPlatformBridgeEventEmitter.addListener(
        'AuthorizationChanged',
        _listener
      );
    }
  }

  public static async loadSavedCredentials(
    authProviderId: string
  ): Promise<string | null> {
    const refreshToken = await getStoredItem('refresh_token');

    if (refreshToken) {
      this.refreshToken = refreshToken;
    }

    await this.moveLegacySavedCredentials();

    const endpoint = await getStoredItem(
      `refresh_token_endpoint_${authProviderId}`
    );

    if (endpoint) {
      this._refreshTokenEndpointMap[authProviderId] = endpoint;
    }

    const token = await getStoredItem(`access_token_${authProviderId}`);

    if (token) {
      this.setAccessToken(authProviderId, token?.replace('Bearer ', ''));
    }

    return token;
  }

  // move refresh token endpoint to new storage key
  // this logic can be removed once 95% of users have updated to 6.5.0+
  private static async moveLegacySavedCredentials() {
    const endpoint = await getStoredItem('refresh_token_endpoint');

    if (endpoint) {
      const authProviderId = parseTokenUrlForAuthProviderId(endpoint);

      await removeStoredItem('refresh_token_endpoint');
      await setStoredItem(`refresh_token_endpoint_${authProviderId}`, endpoint);
      this._refreshTokenEndpointMap[authProviderId] = endpoint;

      const token = await getStoredItem('access_token');

      if (token) {
        await removeStoredItem('access_token');
        await setStoredItem(`access_token_${authProviderId}`, token);
        this._accessTokenMap[authProviderId] = token;
      }
    }
  }

  /**
   * Allows logging in with a given auth provider through the native bridge. Only works in the native
   * apps.
   * ref: https://subsplash.atlassian.net/wiki/spaces/BKND/pages/208797699/End+User+Login+Workflow#Target-Provider
   * @param targetProvider - The AuthProvider which will issue the Bearer token (usually Subsplash)
   * @param selectedProvider - The selected AuthProvider which the user logs in with
   */
  public static async loginFromNativeBridge(
    targetProvider: AuthProvider,
    selectedProvider: AuthProvider,
    source?: string
  ): Promise<void> {
    /**
     * PLAT-1509: Handle special case where we must force web-based Google OAuth
     * to use external browser on Kindle.
     *
     * MED-5393: The request will not have the standard 'sap-*' request headers,
     * so we need to append a root_app_key query param so that the user will be
     * redirected back to TCA (GH936H) as expected on Kindle.
     *
     * TODO: This android workaround is being done here so it can be delivered
     * through Code Push to Kindle users on 5.19. We may move this logic
     * to the native side within the native Google auth provider for 5.20+.
     * See the iOS native implementation for this in GoogleAuthProvider.m.
     */
    if (
      Platform.OS === 'android' &&
      selectedProvider.service_name === 'google' &&
      selectedProvider.auth_handler === 'browser'
    ) {
      const rootAppKey = await getRootAppKey();

      const params: string[] = [];

      if (rootAppKey) params.push(`root_app_key=${rootAppKey}`);

      if (source) params.push(`source=${source}`);

      const url = appendUrlParams(selectedProvider.auth_url, params);

      dispatchAction({
        handler: 'externalBrowser',
        url: url,
      });

      return;
    }

    ActionHandler.dispatch({
      handler: 'appUser',
      command: 'authenticate',
      authProviderId: targetProvider.authproviderid,

      /**
       * Controls which buttons will be visible in the (native) auth screen. If only one item
       * is passed, no (native) auth screen will be shown and the auth flow with the selected
       * provider will immediately be triggered.
       */
      filteredLoginAuthProviderIds: [selectedProvider.authproviderid],

      source: source,
    });
  }

  public static clearAllTokens(): void {
    this.lastTokenRefreshAppKey = '';
    this.lastTokenRefreshTime = 0;

    this.clearRefreshToken();

    Object.keys(this._accessTokenMap).forEach((authProviderId) => {
      this.clearAccessToken(authProviderId);
    });
  }

  public static clearAccessToken(authProviderId: string): void {
    removeStoredItem(`access_token_${authProviderId}`);
    this._accessTokenMap[authProviderId] = null;
  }

  public static clearRefreshToken(): void {
    removeStoredItem('refresh_token');
    this._refreshToken = null;
  }

  public static async logout(): Promise<void> {
    /**
     * Pass through the native sap-user-install-id request header
     * which is important for unlinking an install from an end user on logout in EUA
     * so that the install will not receive targeted push notifications when logged-out
     * ref: https://api.docs.subsplash.net/end-user-auth/#tag/AppInstall
     */
    let appHeaders: Record<string, string> = {};
    try {
      appHeaders = await GetAppHeaders();
    } catch {}

    try {
      debug(`Request started: ${Environment.euaService}/logout`);
      const response = await fetch(`${Environment.euaService}/logout`, {
        method: 'POST',
        // credentials: 'include' is required in order to clear the sap_token cookie on web.
        ...(Platform.OS === 'web' && { credentials: 'include' }),
        headers: {
          ...appHeaders,
          /**
           * Include end user token so that EUA knows what user
           * needs to be updated to unlink the app install on logout
           * so that the install will not continue to receive targeted notifications
           * when a user is logged out
           * ref: https://api.docs.subsplash.net/end-user-auth/#tag/AppInstall
           */
          ...(this.accessToken && {
            Authorization: this.accessToken,
          }),
        },
      });

      if (!response.ok) {
        debug(`EUA logout request failed: ${JSON.stringify(response)}`);
      }
    } catch (e) {
      debug(`EUA logout request error: ${JSON.stringify(e)}`);
    }

    debug('Clearing tokens');
    AuthService.clearAllTokens();

    this.notifyAuthorizationChanged();
  }

  public static async refreshAccessTokens(
    appKey: string,
    targetProviderIds: string[],
    source = 'app'
  ): Promise<void> {
    if (AuthService.isTokenRefreshAllowed(appKey)) {
      AuthService.lastTokenRefreshAppKey = appKey;
      AuthService.lastTokenRefreshTime = new Date().getTime();
      AuthService.isRefreshing = true;

      for (const authProviderId of targetProviderIds) {
        if (this._refreshTokenEndpointMap[authProviderId]) {
          try {
            await this.refreshAccessToken(authProviderId, source);
          } catch {}
        }
      }

      this.notifyAuthorizationChanged();
      AuthService.isRefreshing = false;
    }
  }

  public static async refreshAccessToken(
    authProviderId?: string,
    source?: string
  ): Promise<void> {
    if (!authProviderId) {
      debug('skipping token refresh, no authProviderId');

      return;
    }

    const refreshTokenUrl = this._refreshTokenEndpointMap[authProviderId];

    if (!refreshTokenUrl) {
      debug('skipping token refresh, no endpoint');

      return;
    }

    const sapToken = this.refreshToken;

    // web auth uses a refresh cookie and does not use the sapToken from local storage
    if (!sapToken && Platform.OS !== 'web') {
      debug(
        'token refresh failed, no sap token, assume user is logged-out or token is expired'
      );

      this.clearAccessToken(authProviderId);

      return;
    }

    const response = await requestAccessToken(
      refreshTokenUrl,
      sapToken,
      source
    );

    if (!response.ok) {
      /**
       * If token refresh failed, user should be prompted to login again.
       * To prompt user to login again, we must clear both the
       * AuthService.accessToken and the redux accessToken.
       *
       * ARTEMIS-2481: This prevents a user from getting stuck
       * in an unauthorized state when switching between orgs in web messaging.
       */
      if (response.status === 401 || response.status === 403) {
        this.clearAccessToken(authProviderId);
      }

      return;
    }

    const json = await response.json();

    this.setAccessToken(authProviderId, json.access_token);

    this.refreshToken = json.sap_token;

    if (json.access_token && this.refreshToken) {
      this.sendTokenDataToNative({
        authProviderId,
        accessToken: `${json.token_type} ${json.access_token}`,
        refreshToken: this.refreshToken,
      });
    }
  }

  private static listeners: {
    [event: string]: Array<ListenerObject>;
  } = {};

  public static addListener(
    event: string,
    fn: () => void,
    context?: string
  ): () => void {
    if (!context) {
      context = 'unknown';
    }

    const listenerObject = { context: context, callback: fn };

    this.listeners[event]
      ? this.listeners[event].push(listenerObject)
      : (this.listeners[event] = [listenerObject]);

    return () => {
      const listenerIdx = this.listeners[event].findIndex(
        (registeredObj) => registeredObj.callback === fn
      );

      if (listenerIdx >= 0) {
        this.listeners[event].splice(listenerIdx, 1 /* delete count */);
      }
    };
  }

  private static _dispatch(event: string): void {
    if (!this.listeners[event]) {
      return;
    }

    for (const listenerObj of this.listeners[event]) {
      listenerObj.callback();
    }
  }

  public static authenticate({
    appKey,
    provider,
    targetProvider,
    customReturnUrl,
    source,
    openInNewTab,
  }: {
    appKey: string;
    provider: AuthProvider;
    targetProvider?: AuthProvider;
    customReturnUrl?: string;
    source?: string;
    openInNewTab?: boolean;
  }): void {
    const params = [];
    params.push(`branding_app_key=${appKey}`);
    if (source) params.push(`source=${source}`);

    const authUrl = appendUrlParams(provider.auth_url, params);

    debug(
      `target:${targetProvider?.authproviderid}, selected:${provider.authproviderid}`
    );

    /**
     * The target auth provider is usually the Subsplash auth provider context,
     * except in enterprise apps, like Ligonier (JF2Q5K).
     * ref: https://subsplash.atlassian.net/wiki/spaces/BKND/pages/208797699/End+User+Login+Workflow#Target-Provider
     */
    if (!targetProvider) {
      return;
    }

    const refreshTokenEndpoint = targetProvider?.token_url || '';

    AuthService.setRefreshTokenEndpoint(
      targetProvider.authproviderid,
      refreshTokenEndpoint
    );

    if (Platform.OS === 'web') {
      const returnUrl = encodeURIComponent(
        customReturnUrl || `${Environment.host}/${appKey}/auth`
      );

      const url = `${authUrl}&return_url=${returnUrl}&skip_deep_link=true&use_refresh_cookie=true`;

      // @ts-ignore
      window.reloadAuth = ({
        accessToken,
        refreshToken,
      }: {
        accessToken?: string;
        refreshToken?: string;
      }) => {
        debug('reloadAuth');
        if (accessToken) {
          AuthService.setAccessToken(
            targetProvider.authproviderid,
            accessToken
          );
        }

        if (refreshToken) {
          AuthService.refreshToken = refreshToken;
        }

        AuthService.loadSavedCredentials(targetProvider.authproviderid);
        AuthService.notifyAuthorizationChanged();
      };

      if (openInNewTab) {
        window.open(
          url,
          'subsplash_auth',
          `location=yes,height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${
            window.screen.height / 2 - POPUP_HEIGHT / 2
          },left=${
            window.screen.width / 2 - POPUP_WIDTH / 2
          },scrollbars=yes,status=yes`
        );
      } else {
        // @ts-ignore
        window.location = new URL(url, window.location).toString();
      }
    } else {
      AuthService.loginFromNativeBridge(targetProvider, provider, source);
    }
  }
}
