import { IEndUser } from '../../ChatService';
import { ILinks } from '../../Types';
import { IMediaItem } from '../../MediaItemService';

export enum MediaFormat {
  AUDIO = 'audio',
  VIDEO = 'video',
}

export interface IMediaPlays {
  _embedded: IEmbedded;
  _links: ILinks;
  app_key: string;
  created_at: Date;
  duration_ms: number;
  id: string;
  is_complete: boolean;
  last_media_format: MediaFormat;
  play_count: number;
  progress_ms: number;
  updated_at: Date;
}

interface IEmbedded {
  'end-user': Partial<IEndUser>;
  'media-item': IMediaItem;
}
