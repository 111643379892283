import Axios, { AxiosRequestConfig } from 'axios';
import { isEqual } from 'lodash';
import { Cache } from 'react-native-cache';

import { AuthProvider } from '../auth';
import AnalyticsService from '../services/AnalyticsService';
import CacheService from '../services/CacheService';
import GetRequestHeaders, { IRequestHeaders } from './GetRequestHeaders';

const debug = require('debug')('tca:kit:Downloader');

export const NOT_MODIFIED = 304;
export const GATEWAY_TIMEOUT = 504;

export type IDownloaderResponse =
  | {
      headers: any;
      body: any;
      data?: any;
      status: number;
      authorization?: string;
    }
  | undefined;

export interface RequestOptions {
  authProviderId?: string;
  targetProviders?: AuthProvider[] | undefined;
  domainPreValidated?: boolean; // only set to true if domain is trusted for Authorization
  method?: AxiosRequestConfig['method'];
  extraHeaders?: IRequestHeaders;
  /** appKey must also be provided for impression tracking to work properly */
  impression?: boolean;
  appKey?: string;
  orgKey?: string;
}

interface IDownloadUrl {
  url: string;
  options: RequestOptions;
  cachedResponse?: IDownloaderResponse;
  cache?: Cache;
  skipCaching?: boolean;
  skipTimestamp?: boolean;
  signal?: AbortSignal;
}

export default async function downloadUrl({
  url,
  options,
  cachedResponse,
  cache,
  skipCaching = false,
  skipTimestamp,
  signal,
}: IDownloadUrl): Promise<IDownloaderResponse> {
  const requestHeaders = await GetRequestHeaders({
    url,
    options,
    cachedResponse,
  });

  // opt out of caching in Fetch API's XMLHttpRequest
  // ref: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest#Bypassing_the_cache
  // TODO: refactor how we opt out of the cache, ref: https://www.npmjs.com/package/axios-etag-cache
  const ampersandOrQuestionMark = /\?/.test(url) ? '&' : '?';
  const timestamp = new Date().getTime();
  const adjustedUrl = skipTimestamp
    ? url
    : `${url}${ampersandOrQuestionMark}timestamp=${timestamp}`;

  debug('downloadUrl ', adjustedUrl);

  const requestConfig: AxiosRequestConfig = {
    method: options.method,
    url: adjustedUrl,
    headers: requestHeaders,
    validateStatus: function (status) {
      // ref: https://github.com/axios/axios
      return (status >= 200 && status < 300) || status === NOT_MODIFIED;
    },
    signal,
  };

  const response = await Axios.request(requestConfig);
  const parsedResponse = parseResponse(response, requestHeaders);

  // Be aware that Fetch will auto resolve a 304 to a 200 depending on what it has cached, this is expected.
  // We do our best to opt out of using the Fetch cache with the 'no-store' option, but in testing, this does not entirely disable the Fetch cache.

  if (!skipCaching) {
    saveResultToCache({ cachedResponse, url, cache, result: parsedResponse });
  } else {
    debug('downloadUrl - skipped caching');
  }

  /** Send analytics */
  if (options.impression && options.appKey) {
    AnalyticsService.sendImpression({ url, appKey: options.appKey });
  }

  return parsedResponse;
}

function parseResponse(
  response: any,
  requestHeaders: IRequestHeaders
): IDownloaderResponse {
  return {
    headers: response.headers,
    body: response.data,
    status: response.status,
    authorization: requestHeaders.Authorization as string | undefined,
  };
}

function saveResultToCache({
  cachedResponse,
  url,
  cache,
  result,
}: {
  cachedResponse: IDownloaderResponse;
  url: string;
  cache?: Cache;
  result: IDownloaderResponse;
}) {
  if (result) {
    const isModified =
      cachedResponse &&
      isEqual(result.body, cachedResponse?.body) &&
      isEqual(result.authorization, cachedResponse?.authorization)
        ? false
        : true;

    if (!isModified) result.status = NOT_MODIFIED;

    if (result.status !== NOT_MODIFIED) {
      try {
        const json = JSON.stringify(result);

        if (json) {
          return CacheService.saveToCache(url, json, cache);
        } else {
          debug('Failed to stringify response for cache');
        }
      } catch (e) {
        debug('Failed to stringify response for cache');
      }
    } else {
      debug(`Result is not cached: 304 Not Modified. URL: ${url}`);
    }
  } else {
    debug(`Result is not cached: No response. URL: ${url}`);
  }
}
