import { KitText, KitTouchableIcon } from '@omni/kit/components';
import KitIcon, { IconName } from '@omni/kit/components/KitIcon';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { dismissReactNativeModal } from '@omni/kit/utilities/NativeHelpers';
import React from 'react';
import { Platform, StyleSheet, TouchableHighlight, View } from 'react-native';

import { updateUnread } from '../../utilities/sendbird/chatFunctions';

export enum LeftNavType {
  Back,
  Dismiss,
}

export const dismissChat = (): void => {
  dismissReactNativeModal();
  updateUnread();
};

interface IProps {
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
  title: string;
  type: LeftNavType;
  underlayColor?: string;
}

export default function LeftNavButton({
  color = Colors.N900,
  disabled = false,
  onPress,
  title,
  type,
  underlayColor = Colors.N100,
}: IProps): JSX.Element {
  const _dismissAppButtonPressed = () => {
    dismissChat();
  };

  const _onLeftNavButtonPress = () => {
    if (onPress) return onPress;

    if (type === LeftNavType.Dismiss) return _dismissAppButtonPressed;
  };

  const _leftNavButtonIcon = () => {
    switch (type) {
      case LeftNavType.Back:
        return Platform.select({
          android: 'bar-back-android',
          ios: 'back-ios',
          web: 'bar-back-android',
        }) as IconName;
      case LeftNavType.Dismiss:
        return Platform.select({
          android: 'bar-back-android',
          ios: 'close',
          web: 'remove',
        }) as IconName;
    }
  };

  const maxTitleLength = 26;
  const ellipses = '...';
  const truncatedTitleLength = maxTitleLength - ellipses.length;

  return (
    <View
      style={{
        flexDirection: 'row',
        marginLeft: -12,
      }}
    >
      {Platform.OS === 'android' ? (
        <View style={{ marginRight: Spacing.l }}>
          <KitTouchableIcon
            disabled={disabled}
            color={color}
            name='bar-back-android'
            onPress={_onLeftNavButtonPress()}
            buttonSize={40}
          />
        </View>
      ) : (
        <TouchableHighlight
          disabled={disabled}
          underlayColor={underlayColor}
          delayPressIn={0}
          onPress={_onLeftNavButtonPress()}
          style={styles.leftIcon}
        >
          <View
            style={{
              paddingHorizontal: 10,
            }}
          >
            <KitIcon name={_leftNavButtonIcon()} color={color} size={20} />
          </View>
        </TouchableHighlight>
      )}

      {Platform.OS === 'android' && (
        <KitText
          color={color}
          fontSize={18}
          bold
          style={{ textAlignVertical: 'center' }}
        >
          {title.length > maxTitleLength
            ? `${title.slice(0, truncatedTitleLength)}${ellipses}`
            : title}
        </KitText>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  leftIcon: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: BorderRadius.xl,
    marginLeft: Platform.select({ web: Spacing.l, native: Spacing.s }),
    paddingVertical: 10,
  },
});
