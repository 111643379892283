import * as React from 'react';

import { StyleSheet, View } from 'react-native';

import Colors from '@omni/kit/theming/Colors';
import { KitText } from '@omni/kit/components';
import Spacing from '@omni/kit/theming/Spacing';

export default function ReplySeparator({
  replyCount,
}: {
  replyCount: number;
}): JSX.Element {
  return (
    <View style={styles.container}>
      <KitText fontSize={12} gray semiBold>
        {replyCount === 1 ? `${replyCount} reply` : `${replyCount} replies`}
      </KitText>
      <View style={styles.line} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: Spacing.m,
    marginTop: Spacing.m,
  },
  line: {
    backgroundColor: Colors.N100,
    flex: 1,
    height: 1,
    marginHorizontal: Spacing.m,
  },
});
