import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { ModuleNamespaces } from '@sendbird/chat/lib/__definition';
import { MessageModule } from '@sendbird/chat/message';
import { OpenChannelModule } from '@sendbird/chat/openChannel';

export let sb: SendbirdChat &
  ModuleNamespaces<
    [GroupChannelModule, MessageModule],
    GroupChannelModule | MessageModule
  >;
export const init = (appId: string): void => {
  sb = SendbirdChat.init<[GroupChannelModule, OpenChannelModule]>({
    appId,
    // Initializing both group and open channel modules so group messaging & live chat can both be accessed
    modules: [new GroupChannelModule(), new OpenChannelModule()],
  });
};
