import HealthMonitor from '@omni/kit/HealthMonitor';
import { useSnackbarContext } from '@omni/kit/contexts/Snackbar';
import EventService from '@omni/kit/services/EventService';
import { EVENT_SERVICE_EVENTS } from '@omni/kit/services/EventService/types';
import { setStoredItem } from '@omni/kit/storage';
import { attemptRequest } from '@omni/kit/utilities/attemptRequest';
import { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GetTokenData, {
  ITokenData,
  ITokenStatus,
  getCachedTokenData,
  getTokenStatus,
} from '../../services/CheckInService/Core/GetTokenData';

/*
 * Do not use this directly in a component, instead use KioskContext
 */
export default (): {
  token: string;
  tokenLoading: boolean;
  tokenStatus: ITokenStatus;
  checkRefreshToken: () => Promise<void>;
  setToken: (token: string) => Promise<void>;
  setTokenStatus: Dispatch<React.SetStateAction<ITokenStatus>>;
  resetToken: () => Promise<void>;
} => {
  const [token, setToken] = useState<string>('');
  const [tokenLoading, setTokenLoading] = useState<boolean>(true);
  const [tokenStatus, setTokenStatus] = useState<ITokenStatus>(
    ITokenStatus.Missing
  );
  const { t } = useTranslation(['check-in']);

  const { showSnackbar } = useSnackbarContext();

  useEffect(() => {
    (async () => {
      try {
        await checkRefreshToken();
      } catch (e) {
        showSnackbar(t('check-in:textErrorVerifySetup'));
        HealthMonitor.logError(e as Error);
      }
      setTokenLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRefreshToken = async () => {
    const cache = await getCachedTokenData();

    const cachedTokenStatus = getTokenStatus(
      cache?.access_token,
      cache?.access_token_expires_at
    );

    setToken(cache?.access_token ?? '');
    setTokenStatus(cachedTokenStatus);

    if (cachedTokenStatus === ITokenStatus.Missing) {
      setTokenLoading(false);

      return;
    }

    const refreshedTokenData = await attemptRequest<ITokenData>(
      async () => await GetTokenData()
    );

    if (refreshedTokenData.token_status === ITokenStatus.Valid) {
      saveToken(refreshedTokenData.access_token);

      await setStoredItem(
        'check_in_access_token_expires_at',
        refreshedTokenData.access_token_expires_at
      );
      await setStoredItem(
        'check_in_refresh_token',
        refreshedTokenData.refresh_token
      );

      EventService.dispatch(
        EVENT_SERVICE_EVENTS.CHECK_IN_AUTHORIZATION_CHANGED
      );
    }

    setTokenStatus(refreshedTokenData.token_status);
    setTokenLoading(false);
  };

  const saveToken = async (token: string) => {
    await setStoredItem('check_in_access_token', token);
    setToken(token);
  };

  const resetToken = async (): Promise<void> => {
    saveToken('');
    setTokenStatus(ITokenStatus.Missing);
  };

  return {
    token,
    tokenStatus,
    tokenLoading,
    checkRefreshToken,
    setToken: saveToken,
    setTokenStatus,
    resetToken,
  };
};
