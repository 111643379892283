import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import Axios from 'axios';

import { IHttpRequest } from '..';
import GetRequestHeaders from '../../../Downloader/GetRequestHeaders';
import { AuthProvider } from '../../../auth';
import { IHttpResponse, handleError } from '../Response';

const debug = require('debug')('tca:service:BaseServiceV2:Core:Remove');

interface ICustomDelete {
  appKey?: string;
  authProviderId?: string;
  orgKey?: string;
  token?: string;
  targetProviders?: AuthProvider[];
}

type IDelete = IHttpRequest & ICustomDelete;

export default async ({
  appKey,
  authProviderId = SUBSPLASH_AUTH_PROVIDER_ID,
  headers,
  token = '',
  targetProviders,
  url,
}: IDelete): Promise<IHttpResponse> => {
  debug('Url', url);

  const tokenStripped = token.replace('Bearer ', '');

  const requestHeaders = await GetRequestHeaders({
    url,
    options: {
      appKey,
      authProviderId,
      targetProviders,
      extraHeaders: {
        Authorization: `Bearer ${tokenStripped}`,
      },
    },
  });

  const extraHeaders = { ...requestHeaders, ...headers };

  return Axios.delete(url, { headers: extraHeaders })
    .then((response) => ({
      body: response.data,
      status: response.status,
    }))
    .catch(handleError);
};
