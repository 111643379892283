import { Platform } from 'react-native';

import { BridgeAction } from '../Types';
import { getUserLocation } from './UserLocation';

interface MapLinkParams {
  address?: string;
  longitude?: number;
  latitude?: number;
}

const getUrlSearchParams = (obj: { [key: string]: string | undefined }) => {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(
    ([key, value]) => value && params.set(key, value)
  );

  return params;
};

const getIosSearchParams = async (
  address?: string,
  coordinatesString?: string
): Promise<URLSearchParams> => {
  try {
    const location = await getUserLocation();
    const daddr =
      address && coordinatesString
        ? `${address}@${coordinatesString}`
        : address || coordinatesString;

    return getUrlSearchParams({
      saddr: `${location.latitude},${location.longitude}`,
      daddr,
    });
  } catch (error) {
    return getUrlSearchParams({
      q: address,
      sll: coordinatesString,
    });
  }
};

const getGoogleSearchParams = async (
  address?: string,
  coordinatesString?: string
): Promise<URLSearchParams> => {
  try {
    const location = await getUserLocation();

    return getUrlSearchParams({
      saddr: `${location.latitude},${location.longitude}`,
      daddr: address,
      sll: coordinatesString,
    });
  } catch (error) {
    return getUrlSearchParams({
      q: address,
      ll: coordinatesString,
      z: '16',
    });
  }
};

export interface MapAction extends BridgeAction, MapLinkParams {}

/**
 * Internally uses URLSearchParams. May require the inclusion of
 * react-native-url-polyfill/auto in the index of React Native applications
 * to function correctly on iOS and Android.
 * @returns Apple Maps link for iOS, Google Maps link for all other devices.
 */
export const getMapLink = async ({
  address,
  latitude,
  longitude,
}: MapLinkParams): Promise<string | undefined> => {
  const coordinatesString =
    latitude && longitude ? `${latitude},${longitude}` : undefined;

  if (!address && !coordinatesString) {
    return undefined;
  }

  if (Platform.OS === 'ios') {
    const params = await getIosSearchParams(address, coordinatesString);

    return `https://maps.apple.com/maps?${params.toString()}`;
  }

  const params = await getGoogleSearchParams(address, coordinatesString);

  return `https://maps.google.com/maps?${params.toString()}`;
};
