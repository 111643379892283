import {
  EVENT_SERVICE_EVENTS,
  IListenerCallback,
  IListenerCallbackData,
  IListenerObject,
} from './types';

export default class EventService {
  private static listeners: {
    [event: string]: Array<IListenerObject>;
  } = {};

  public static addListener(
    event: EVENT_SERVICE_EVENTS,
    fn: IListenerCallback,
    context?: string
  ): () => void {
    if (!context) {
      context = 'unknown';
    }

    const listenerObject = { context: context, callback: fn };

    this.listeners[event]
      ? this.listeners[event].push(listenerObject)
      : (this.listeners[event] = [listenerObject]);

    return () => {
      const listenerIdx = this.listeners[event].findIndex(
        (registeredObj) => registeredObj.callback === fn
      );

      if (listenerIdx >= 0) {
        this.listeners[event].splice(listenerIdx, 1 /* delete count */);
      }
    };
  }

  public static dispatch(
    event: EVENT_SERVICE_EVENTS,
    data?: IListenerCallbackData
  ): void {
    if (!this.listeners[event]) {
      return;
    }

    for (const listenerObj of this.listeners[event]) {
      listenerObj.callback(data);
    }
  }
}
