import React from 'react';
import {
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewProps,
} from 'react-native';

/**
 * KitPageControl
 * This component was adapted from https://github.com/silentcloud/react-native-page-control/blob/master/PageControl.js
 * It did not support rendering on the web.
 * The component is lightweight, so forking this project was not worth the maintenance overhead.
 */

interface IProps extends ViewProps {
  numberOfPages: number;
  currentPage: number;
  hidesForSinglePage: boolean;
  pageIndicatorTintColor: string;
  currentPageIndicatorTintColor: string;
  currentPageIndicatorSize?: { width: number; height: number };
  indicatorSize: { width: number; height: number };
  indicatorStyle: object;
  currentIndicatorStyle: object;
  onPageIndicatorPress: (index: number) => void;
}

export default function KitPageControl({
  numberOfPages = 0,
  currentPage = 0,
  hidesForSinglePage = false,
  pageIndicatorTintColor = 'gray',
  currentPageIndicatorTintColor = 'white',
  indicatorSize = { width: 5, height: 5 },
  currentPageIndicatorSize = { width: 10, height: 10 },
  indicatorStyle = {},
  currentIndicatorStyle = {},
  onPageIndicatorPress = (_) => null,
  style,
}: IProps): JSX.Element | null {
  const defaultStyle = {
    height: currentPageIndicatorSize.height,
  };

  const indicatorItemStyle = {
    width: indicatorSize.width,
    height: indicatorSize.height,
    borderRadius: indicatorSize.height / 2,
    marginLeft: 5,
    marginRight: 5,
  };

  const resolvedIndicatorStyle = {
    ...indicatorItemStyle,
    ...indicatorStyle,
    ...{
      backgroundColor: pageIndicatorTintColor,
    },
  };

  const resolvedCurrentIndicatorStyle = {
    ...currentIndicatorStyle,
    ...{
      backgroundColor: currentPageIndicatorTintColor,
      width: currentPageIndicatorSize.width,
      height: currentPageIndicatorSize.height,
      borderRadius: currentPageIndicatorSize.height / 2,
      marginLeft: 5,
      marginRight: 5,
    },
  };

  const pages: number[] = [];

  for (let i = 0; i < numberOfPages; i++) {
    pages.push(i);
  }

  return hidesForSinglePage && pages.length <= 1 ? null : (
    <View style={[styles.container, defaultStyle, style]}>
      {pages.map((el, i) => (
        <TouchableWithoutFeedback
          key={i}
          onPress={() => {
            onPageIndicatorPress(i);
          }}
        >
          <View
            style={
              i === currentPage
                ? resolvedCurrentIndicatorStyle
                : resolvedIndicatorStyle
            }
          />
        </TouchableWithoutFeedback>
      ))}
    </View>
  );
}

//******************************************************************************
//
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
