import Environment from '../../../Environment';
import { ISession } from '../Types';
import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.eventsService;
const RESOURCE = 'session';

interface IGetSessions extends IUri {
  token?: string;
}

interface ISessionsResponse {
  count: number;
  _embedded: {
    sessions: ISession[];
  };
}

interface IGetSessionsResponse extends IHttpResponse {
  body?: ISessionsResponse;
}

export default async function ({
  token,
  ...buildUrlProps
}: IGetSessions): Promise<IGetSessionsResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
