import useActiveCheckInEvents from '@omni/check-in/kiosk/hooks/useActiveCheckInEvents';
import CheckInService from '@omni/check-in/kiosk/services/CheckInService';
import { ICheckIn } from '@omni/check-in/kiosk/services/CheckInService/Types';
import { getFilteredCheckIns } from '@omni/check-in/kiosk/utilities/getFilteredCheckIns';
import { getChildGuestProfiles } from '@omni/check-in/kiosk/utilities/household';
import { useShellContext } from '@omni/kit';
import { FEATURE_FLAGS } from '@omni/kit/contexts/FeatureFlagContext';
import { useFeatureFlag } from '@omni/kit/hooks/useFeatureFlag';
import { IFilter } from '@omni/kit/services/BaseServiceV2';
import EventsService from '@omni/kit/services/EventsService';
import { IEvent } from '@omni/kit/services/EventsService/Types';
import PeopleService from '@omni/kit/services/PeopleService';
import { IHousehold } from '@omni/kit/services/PeopleService/Types';
import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

const SELECTED_EVENT_KEY = 'selected-pre-check-event';

export default function usePreCheck(isRefreshing?: boolean): {
  loading: boolean;
  selectedEvent: IEvent | undefined;
  activeEvents: IEvent[];
  household: IHousehold | undefined;
  activeCheckIns: ICheckIn[]; // Profiles that are still checked into the open selected event
  previousCheckIns: ICheckIn[]; // Profiles that previously checked into another repeating event instance as the current selected event
  showPreCheckCallout: boolean;
  showCheckOut: boolean;
  refreshCheckIns: () => void;
  setNewSelectedEvent: (event: IEvent | undefined) => void;
} {
  const { isEnabled, isReady } = useFeatureFlag(FEATURE_FLAGS.CHECKIN_PRECHECK);
  const { app, tokens, user } = useShellContext();
  const { appKey, orgKey } = app;
  const { user: token } = tokens;
  const profileId = user?.profile.id;
  const [loading, setLoading] = useState<boolean>(true);
  const [activeEvents, setActiveEvents] = useState<IEvent[]>([]);
  const [household, setHousehold] = useState<IHousehold | undefined>();
  const [householdCheckIns, setHouseholdCheckIns] = useState<ICheckIn[]>([]);
  const [storedSelectedEventId, setStoredSelectedEventId] = useState<string>();

  const setNewSelectedEvent = useCallback(async (event?: IEvent) => {
    if (event) {
      await setStoredItem(SELECTED_EVENT_KEY, event.id);
      setStoredSelectedEventId(event.id);
    } else {
      await removeStoredItem(SELECTED_EVENT_KEY);
      setStoredSelectedEventId(undefined);
    }
  }, []);

  const selectedEvent: IEvent | undefined = useMemo(() => {
    if (loading) {
      return;
    }

    if (
      storedSelectedEventId &&
      activeEvents.find((e) => e.id === storedSelectedEventId)
    ) {
      return activeEvents.find((e) => e.id === storedSelectedEventId);
    } else {
      setNewSelectedEvent(undefined);
    }

    if (householdCheckIns?.length) {
      const lastCheckIn = householdCheckIns[0];

      return (
        activeEvents.find((e) => e.id === lastCheckIn._embedded.event.id) ||
        activeEvents.find(
          (e) =>
            e._embedded?.['repeating-event']?.id ===
            lastCheckIn._embedded.event._embedded?.['repeating-event']?.id
        )
      );
    }

    if (activeEvents.length === 1) {
      return activeEvents[0];
    }
  }, [
    loading,
    storedSelectedEventId,
    activeEvents,
    householdCheckIns,
    setNewSelectedEvent,
  ]);

  // Check-ins for the selected event
  const selectedCheckIns = householdCheckIns.filter(
    (c) => c._embedded.event.id === selectedEvent?.id
  );

  const previousCheckIns = householdCheckIns.filter(
    (c) =>
      c._embedded.event._embedded?.['repeating-event']?.id ===
        selectedEvent?._embedded?.['repeating-event']?.id &&
      c._embedded.event.id !== selectedEvent?.id
  );

  // Check-ins filtered by check-in and check-out pairs
  const { filteredCheckIns: activeCheckIns } = getFilteredCheckIns(
    selectedCheckIns?.filter((c) => c.action_type === 'check-in'),
    selectedCheckIns?.filter((c) => c.action_type === 'check-out')
  );

  // Show the check-out code(s)
  const showCheckOut = useMemo(() => {
    return Boolean(activeCheckIns.length);
  }, [activeCheckIns]);

  const showPreCheckCallout = useMemo(() => {
    return Boolean(selectedEvent && !showCheckOut && isReady && isEnabled);
  }, [isEnabled, isReady, selectedEvent, showCheckOut]);

  const fetchRepeatEvents = useCallback(async () => {
    if (!appKey || !token) {
      return;
    }

    const filter: IFilter[] = [
      ['app_key', appKey],
      ['check_in_enabled', 'true'],
      ['status', 'published'],
      // Event already started or starting within the next hour
      [
        'start_at',
        `<${moment.utc().add(1, 'hours').format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
      ],
      // Events that are occurring today
      // Don't use utc() here because this filter uses event timezone
      ['occurring_on_day', `${moment().format('YYYY-MM-DD')}`],
    ];
    const res = await EventsService.GetEvents({
      token: token,
      filters: filter,
      includes: ['sessions'],
    });

    if (res.body?._embedded.events) {
      const eventsWithOpenCheckInWindow = res.body._embedded.events.filter(
        (e) => moment.utc().isBefore(e._embedded?.sessions?.[0].check_in_end_at)
      );
      setActiveEvents(eventsWithOpenCheckInWindow);
    }
  }, [appKey, token]);

  useEffect(() => {
    token && appKey && fetchRepeatEvents();
  }, [fetchRepeatEvents]);

  const fetchHousehold = useCallback(async () => {
    if (!token) {
      return;
    }

    if (token !== undefined) {
      const response = await PeopleService.GetProfile({
        token: token,
        id: profileId,
      });

      if (response.body?._embedded?.household?.id) {
        const { body: household } = await PeopleService.GetHousehold({
          id: response.body?._embedded?.household?.id,
          token: token,
          includes: ['members,guest-profiles'],
        });

        if (household && household._embedded?.members) {
          setHousehold(household);
        }
      }
    }
  }, [token, profileId]);

  useEffect(() => {
    token && profileId && fetchHousehold();
  }, [fetchHousehold]);

  const fetchCheckInsForHouseholds = useCallback(async () => {
    if (!token) {
      return;
    }

    if (!orgKey) {
      return;
    }

    if (activeEvents.length && household?.id !== undefined) {
      const eventIds = activeEvents.map((e) => e.id);
      const uniqueEventIds = [...new Set(eventIds)];
      try {
        const response = await CheckInService.GetCheckIns({
          token: token,
          filters: [
            ['org_key', orgKey],
            ['event.id', uniqueEventIds.join(',')],
            ['profile-snapshot.household.id', household.id],
          ],
          sorts: ['-created_at'],
        });
        let checkIns = response?.body?._embedded['check-ins'];
        const householdGuests = getChildGuestProfiles(household);

        if (householdGuests.length) {
          const guestCheckInsResponse = await CheckInService.GetCheckIns({
            token: token,
            filters: [
              ['org_key', orgKey],
              ['event.id', uniqueEventIds.join(',')],
              [
                'end-user.id',
                householdGuests
                  .map((guest) => guest._embedded?.['end-user']?.id)
                  .join(','),
              ],
              ['profile-snapshot.household.id', household.id],
            ],
            sorts: ['-created_at'],
          });
          const guestCheckIns =
            guestCheckInsResponse?.body?._embedded['check-ins'];

          if (guestCheckIns) {
            checkIns = (checkIns || []).concat(guestCheckIns);
          }
        }

        // Past check-ins
        const repeatingEventIds = activeEvents
          .map((e) => e._embedded?.['repeating-event']?.id)
          .filter(Boolean);
        const uniqueRepeatingEventIds = [...new Set(repeatingEventIds)];
        const pastCheckInsResponse = await CheckInService.GetCheckIns({
          token: token,
          filters: [
            ['org_key', orgKey],
            ['repeating-event.id', uniqueRepeatingEventIds.join(',')],
            ['profile-snapshot.household.id', household.id],
          ],
          sorts: ['-created_at'],
        });
        const pastCheckIns = pastCheckInsResponse?.body?._embedded['check-ins'];

        if (pastCheckIns) {
          checkIns = (checkIns || []).concat(pastCheckIns);
        }

        if (checkIns?.length) {
          setHouseholdCheckIns(checkIns);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  }, [token, orgKey, activeEvents, household]);

  useEffect(() => {
    fetchCheckInsForHouseholds();
  }, [fetchCheckInsForHouseholds]);

  useEffect(() => {
    if (isRefreshing) {
      fetchRepeatEvents();
      fetchHousehold();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  useEffect(() => {
    const getStoredSelectedEventId = async () => {
      const storedSelectedEventId = await getStoredItem(SELECTED_EVENT_KEY);

      if (storedSelectedEventId) {
        setStoredSelectedEventId(storedSelectedEventId);
      }
    };
    getStoredSelectedEventId();
  }, []);

  return {
    loading,
    selectedEvent,
    activeEvents,
    household,
    activeCheckIns,
    previousCheckIns,
    showPreCheckCallout,
    showCheckOut,
    refreshCheckIns: fetchCheckInsForHouseholds,
    setNewSelectedEvent,
  };
}
