import { PlatformAgnosticFile } from '@omni/kit/FileTypes';
import KitIcon from '@omni/kit/components/KitIcon';
import KitImage from '@omni/kit/components/KitImage';
import KitTouchable from '@omni/kit/components/KitTouchable';
import Colors from '@omni/kit/theming/Colors';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import ImagePicker, {
  Image as ImagePickerImage,
  Options as ImagePickerOptions,
} from 'react-native-image-crop-picker';

import {
  nativeImageToAgnosticFile,
  webImageToAgnosticFile,
} from '../../../../../utilities/imageUtilities';

export const stockImages = [
  'https://images.subsplash.com/{name}.{ext}?id=adb18c92-b64f-4bb1-91f0-0adb020d3406&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=65eefdef-815c-463e-9fa1-9c17033306f8&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=908b0815-b43d-483f-af6c-bae400f112da&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=6a9d9a33-970a-4a30-b095-0e8fa8f01ff0&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=593568c8-0bbd-4191-8804-3076ace83876&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=510fdc94-2fc9-4c93-8332-e057e268a70d&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=75b4d814-84ea-4ba7-9193-306a38152927&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=60e599fc-95c6-46fa-8666-e0e24828b2b5&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=cc9184c6-1213-46e3-b159-b9ba42bec366&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=02a57025-971d-4025-84dd-75e1450ad8ca&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=16ab152f-5e3c-49ca-8c04-edec94a3b53e&w={width}&h={height}',
  'https://images.subsplash.com/{name}.{ext}?id=a3dbed98-fa43-4641-8ce7-7a6a517b5d7f&w={width}&h={height}',
];

const webFilePickerStyles = {
  backgroundColor: Colors.N100,
  width: 56,
  height: 56,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 28,
  cursor: 'pointer',
};

interface ChannelAvatarListProps {
  channel: GroupChannel | null;
  channelImage: string;
  userImage: PlatformAgnosticFile | null;
  setChannelImage: (img: string) => void;
  onCustomImageSelected: (userImg: PlatformAgnosticFile) => void;
}

export default function ChannelAvatarList({
  channel,
  channelImage,
  userImage,
  setChannelImage,
  onCustomImageSelected,
}: ChannelAvatarListProps) {
  const handleImagePress = (url: string) => {
    setChannelImage(url);
  };

  const handleSelectImagePress = (
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (Platform.OS !== 'web') {
      const options: ImagePickerOptions = {};
      ImagePicker.openPicker(options)
        .then((image) => {
          const imageObject = image as ImagePickerImage;
          const agnosticFile = nativeImageToAgnosticFile(imageObject);
          onCustomImageSelected(agnosticFile);
        })
        .catch(() => undefined);
    } else if (event) {
      const file = event.target.files?.[0];

      if (!file) {
        return;
      }

      const agnosticFile = webImageToAgnosticFile(file);
      onCustomImageSelected(agnosticFile);
    }
  };

  const renderImageCircle = (baseUrl: string, i: number) => {
    const selected = channelImage === baseUrl;
    const imageUrl = parseImageUrl(baseUrl, 54, 54, ImageServiceType.ImageJpeg);

    return (
      <View
        key={i}
        style={[
          styles.avatarWrapper,
          selected ? { borderColor: Colors.brand } : { borderColor: Colors.N0 },
        ]}
      >
        <KitTouchable
          key={i}
          underlayColor={Colors.N900}
          onPress={() => {
            handleImagePress(baseUrl);
          }}
          style={styles.avatarButton}
        >
          {imageUrl && (
            <KitImage
              source={{
                uri: imageUrl,
              }}
              style={[
                {
                  width: 56,
                  height: 56,
                  borderRadius: 28,
                  opacity: 1,
                },
              ]}
              resizeMode='cover'
            />
          )}
        </KitTouchable>
      </View>
    );
  };

  return (
    <>
      <View style={[styles.avatarWrapper, { borderColor: Colors.N0 }]}>
        {Platform.OS === 'web' ? (
          <label style={webFilePickerStyles}>
            <input
              style={{ display: 'none' }}
              type='file'
              accept='image/*'
              onChange={handleSelectImagePress}
            />
            <KitIcon name='image' size={20} />
          </label>
        ) : (
          <KitTouchable
            onPress={handleSelectImagePress}
            underlayColor={Colors.N200}
            touchableStyle={{
              marginTop: 3,
              marginLeft: 3,
              width: 56,
              height: 56,
            }}
            style={styles.avatarButton}
          >
            <KitIcon name='image' size={20} />
          </KitTouchable>
        )}
      </View>
      {userImage && renderImageCircle(userImage.uri, 0)}
      {channel?.coverUrl &&
        !stockImages.includes(channel.coverUrl) &&
        renderImageCircle(channel.coverUrl, 0)}
      {stockImages.map((baseUrl, i) => {
        // Avoid key collision with the above `renderImageCircle` with key 0.
        return renderImageCircle(baseUrl, i + 1);
      })}
    </>
  );
}

const styles = StyleSheet.create({
  avatarWrapper: {
    margin: 1,
    width: 68,
    height: 68,
    borderRadius: 34,
    borderWidth: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarButton: {
    backgroundColor: Colors.N100,
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 28,
  },
});
