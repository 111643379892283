import Environment from '@omni/kit/Environment';
import { decode } from 'base-64';

import { shuffleArray } from '../utilities/sendbird/chatFunctions';

// Function to decode Base64 string
function decodeBase64(base64: string) {
  try {
    return decode(base64);
  } catch (error) {
    console.error('Error decoding base64 string', error);

    return null;
  }
}

// Decode the Tenor API key
const TENOR_API_KEY = decodeBase64(Environment.encodedTenorApiKey);
const BASE_URL = Environment.tenorBaseUrl;

interface ITenorGif {
  media_formats: {
    tinygif?: {
      url: string;
    };
  };
}

const TenorService = {
  searchGifs: async (
    query: string
  ): Promise<{ uri: string; isSelected: boolean }[]> => {
    const endpoint = query
      ? `${BASE_URL}search?q=${query}&key=${TENOR_API_KEY}&limit=30&contentfilter=high`
      : `${BASE_URL}trending_terms?key=${TENOR_API_KEY}&limit=30&contentfilter=high`;

    try {
      const response = await fetch(endpoint);
      const data = await response.json();

      return data.results.map((result: ITenorGif) => ({
        uri: result.media_formats?.tinygif?.url,
        isSelected: false,
      }));
    } catch (error) {
      console.error(`Error fetching from Tenor`);

      return [];
    }
  },

  fetchTrendingGifs: async (): Promise<
    { uri: string; isSelected: boolean }[]
  > => {
    const trendingTermsEndpoint = `${BASE_URL}trending_terms?key=${TENOR_API_KEY}&limit=30&contentfilter=high`;

    try {
      const response = await fetch(trendingTermsEndpoint);
      const data = await response.json();

      // Fetch GIFs for each trending term
      const gifPromises = data.results.map(async (term: string) => {
        const gifs = await TenorService.searchGifs(term);

        return gifs;
      });

      // Resolve all promises to get arrays of GIFs
      const gifArrays = await Promise.all(gifPromises);

      // Flatten the arrays and remove duplicates using a Set
      const seenUrls = new Set<string>();
      const uniqueGifs = gifArrays.flat().filter((gif) => {
        if (seenUrls.has(gif.uri)) {
          return false;
        } else {
          seenUrls.add(gif.uri);

          return true;
        }
      });

      // Shuffle the unique GIFs
      const shuffledGifs = shuffleArray(uniqueGifs);

      return shuffledGifs;
    } catch (error) {
      console.error(`Error fetching trending GIFs from Tenor`);

      return [];
    }
  },
};

export default TenorService;
