import getRouteRootPath from './getRouteRootPath.web';

// Examples:
// https://messaging.subsplash.com/rev:some-slug/CHURCH
// https://messaging.subsplash.com/CHURCH
//
export default (): string => {
  const rootPath = getRouteRootPath();
  const rootPathStartIndex = window.location.pathname.indexOf(rootPath);
  const rootPathEndIndex = rootPathStartIndex + rootPath.length;
  const appKeyStartIndex = rootPathEndIndex;
  const appKey = window.location.pathname.slice(
    appKeyStartIndex,
    appKeyStartIndex + 6
  );

  // validation: appkey must be alphanumeric
  if (!/^[A-Za-z0-9]*$/.test(appKey)) return '';

  return appKey;
};
