import BaseServiceV2, {
  IHttpResponse,
  IInclude,
  IUri,
} from '../../../BaseServiceV2';

import Environment from '../../../../Environment';
import { IAssignment } from '../../Types';
import { buildUrl } from '@omni/kit/services/BaseServiceV2';

const HOST = Environment.fluroProxyService;
const RESOURCE = 'assignments';

interface IGetAssignments extends IUri {
  token?: string;
  includes: IInclude[];
}

interface IGetAllAssignmentsResponse extends IHttpResponse {
  body?: {
    _embedded: {
      assignments: IAssignment[];
    };
    count: number;
  };
}

export default async function getAll({
  token,
  includes,
}: IGetAssignments): Promise<IGetAllAssignmentsResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    includes,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
