import React, { ReactNode, useEffect } from 'react';

import { Animated } from 'react-native';

export interface IProps {
  children: ReactNode;
  style?: object;
}

export default function FadeInView({
  children,
  style = {},
}: IProps): JSX.Element {
  const opacityValue = new Animated.Value(0);

  useEffect(() => {
    Animated.timing(opacityValue, {
      duration: 250,
      toValue: 1,
      useNativeDriver: true,
    }).start();
  }, []);

  return (
    <Animated.View
      style={{
        ...style,
        opacity: opacityValue,
      }}
    >
      {children}
    </Animated.View>
  );
}
