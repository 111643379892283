// TODO: fix @env module name resolution
import {
  BUILD_ENV,
  DEV_RETURN_URL_ACCESS_TOKEN,
  DEV_STRIPE_KEY,
  DEV_STRIPE_TEST_KEY,
  DEV_UNLEASH_CLIENT_KEY,
  ENCODED_TENOR_API_KEY,
  GA_MEASUREMENT_ID,
  GOOGLE_CAPTCHA_KEY_DEV,
  GOOGLE_CAPTCHA_KEY_PROD,
  GOOGLE_CAPTCHA_KEY_STAGE,
  GOOGLE_MAPS_KEY,
  GOOGLE_MAPS_KEY_DEV,
  GUEST_TOKENS_SERVICE_CLIENT_ID_DEV,
  GUEST_TOKENS_SERVICE_CLIENT_ID_PROD,
  GUEST_TOKENS_SERVICE_CLIENT_ID_STAGE,
  GUEST_TOKENS_SERVICE_CLIENT_SECRET_DEV,
  GUEST_TOKENS_SERVICE_CLIENT_SECRET_PROD,
  GUEST_TOKENS_SERVICE_CLIENT_SECRET_STAGE,
  PROD_STRIPE_KEY,
  PROD_STRIPE_TEST_KEY,
  PROD_UNLEASH_CLIENT_KEY,
  STAGE_STRIPE_KEY,
  STAGE_STRIPE_TEST_KEY,
} from '@env';

import hostWithRootPath from './utilities/hostWithRootPath';

/** The localHost, if currently being used. */
const localHost =
  typeof window !== 'undefined' && window?.location?.hostname === 'localhost'
    ? window.location.origin
    : null;

export type EnvType = 'dev' | 'stage' | 'prod';

// Casting to String to side-step caching issue with local development
// https://github.com/goatandsheep/react-native-dotenv/issues/199#issuecomment-905977493
export const ENV: EnvType =
  Boolean(BUILD_ENV) && String(BUILD_ENV) ? BUILD_ENV : 'prod';

const LOCAL_HOST = 'http://localhost:8080';
const DEV_HOST = 'https://messaging.dev.subsplash.net';
const STAGE_HOST = 'https://messaging.stage.subsplash.net';
const PROD_HOST = 'https://messaging.subsplash.com';

const DEV_LIVE_CHAT_HOST = 'https://live.dev.subsplash.net';
const STAGE_LIVE_CHAT_HOST = 'https://live.stage.subsplash.net';
const PROD_LIVE_CHAT_HOST = 'https://live.subsplash.com';

const DEV_SERVICE_HOST = 'https://core.dev.subsplash.net';
const STAGE_SERVICE_HOST = 'https://core.stage.subsplash.net';
const PROD_SERVICE_HOST = 'https://core.subsplash.com';

const DEV_FEEDS_HOST = 'https://feeds.dev.subsplash.net';
const STAGE_FEEDS_HOST = 'https://feeds.stage.subsplash.net';
const PROD_FEEDS_HOST = 'https://feeds.subsplash.com';

const DEV_LEGACY_GIVING_HOST = 'https://secure.dev.giving.subsplash.net';
const STAGE_LEGACY_GIVING_HOST = 'https://secure.stage.giving.subsplash.net';
const PROD_LEGACY_GIVING_HOST = 'https://secure.subsplash.com';

const DEV_IMAGES_HOST = 'https://images.dev.subsplash.net';
const STAGE_IMAGES_HOST = 'https://images.stage.subsplash.net';
const PROD_IMAGES_HOST = 'https://images.subsplash.com';

const DEV_UNLEASH_HOST = 'https://ffproxy.core.dev.subsplash.net';
const PROD_UNLEASH_HOST = 'https://ffproxy.core.subsplash.com';

const DEV_LEGACY_WEBAPP_HOST = 'https://web-app.dev.subsplash.net';
const STAGE_LEGACY_WEBAPP_HOST = 'https://web-app.stage.subsplash.net';
const PROD_LEGACY_WEBAPP_HOST = 'https://subsplash.com';

const DEV_NEW_WEBAPP_HOST = 'https://app.dev.subsplash.net';
const STAGE_NEW_WEBAPP_HOST = 'https://app.stage.subsplash.net';
const PROD_NEW_WEBAPP_HOST = 'https://subsplash.com';

const DEV_AUTH_HOST = 'http://auth-local.subsplash.com';
const STAGE_AUTH_HOST = 'https://auth.stage.subsplash.net';
const PROD_AUTH_HOST = 'https://auth.subsplash.com';

const ANALYTICS_HOST = 'https://t.subsplash.com';

const ACCOUNTS_SERVICE = '/accounts/v1';
const BILLING_SERVICE = '/billing/v1';
const API_URL = '/api/v1';
const BIBLE_SERVICE = '/bible/v1';
const BUILDER_SERVICE = '/builder/v1';
const CHAT_SERVICE = '/chat/v1';
const CHECK_IN_SERVICE = '/check-in/v1';
const DONATIONS_SERVICE = '/donations/v1';
const DOCUMENTS_SERVICE = '/documents/v1';
const END_USER_AUTH_SERVICE = '/end-user-auth/v1';
const EVENTS_SERVICE = '/events/v2';
const FEEDS_SERVICE = '/api/v1';
const FEEDS_AUTH = '/feeds/v1';
const FORMS_SERVICE = '/forms/v1';
const FLURO_PROXY_SERVICE = '/fluro-proxy/v1';
const GIVING_URL = '/ui/access';
const GROUPS_SERVICE = '/groups/v1';
const IMAGE_SERVICE = '/files/v1';
const LIVE_SERVICE = '/live/v1';
const LOCATION_SERVICE = '/locations/v1';
const MEDIA_PERSONALIZATION_SERVICE = '/media-personalization/v1';
const MEDIA_SERVICE = '/media/v1';
const PAYMENTS_SERVICE = '/payments/v1';
const SEARCH_SERVICE = '/search/v1';
const TAG_SERVICE = '/tags/v1';
const TOKENS_SERVICE = '/tokens/v1';
const UNLEASH_SERVICE = '/proxy';
const WALLET_BRANDING = '/wallet/v1';
const PEOPLE_SERVICE = '/people/v1';
const NOTIFICATIONS_SERVICE = '/notifications/v1';
const TENOR_BASE_URL = 'https://tenor.googleapis.com/v2/';
const PLEDGES_SERVICE = '/pledges/v1';

const Environment = {
  dev: {
    host: hostWithRootPath(DEV_HOST),
    liveChatHost: hostWithRootPath(DEV_LIVE_CHAT_HOST),
    analyticsService: ANALYTICS_HOST + API_URL,
    fluroProxyService: DEV_SERVICE_HOST + FLURO_PROXY_SERVICE,
    gaMeasurementId: GA_MEASUREMENT_ID,
    googleCaptchaKey: GOOGLE_CAPTCHA_KEY_DEV,
    googleMapsKey: GOOGLE_MAPS_KEY,
    googleMapsKeyDev: GOOGLE_MAPS_KEY_DEV,
    builderService: DEV_SERVICE_HOST + BUILDER_SERVICE,
    chatService: DEV_SERVICE_HOST + CHAT_SERVICE,
    checkInService: DEV_SERVICE_HOST + CHECK_IN_SERVICE,
    mediaPersonalizationService:
      DEV_SERVICE_HOST + MEDIA_PERSONALIZATION_SERVICE,
    mediaService: DEV_SERVICE_HOST + MEDIA_SERVICE,
    liveService: DEV_SERVICE_HOST + LIVE_SERVICE,
    bibleService: DEV_SERVICE_HOST + BIBLE_SERVICE,
    euaService: DEV_SERVICE_HOST + END_USER_AUTH_SERVICE,
    accountsService: DEV_SERVICE_HOST + ACCOUNTS_SERVICE,
    billingService: DEV_SERVICE_HOST + BILLING_SERVICE,
    donationsService: DEV_SERVICE_HOST + DONATIONS_SERVICE,
    documentsService: DEV_SERVICE_HOST + DOCUMENTS_SERVICE,
    eventsService: DEV_SERVICE_HOST + EVENTS_SERVICE,
    formsService: DEV_SERVICE_HOST + FORMS_SERVICE,
    imageService: DEV_SERVICE_HOST + IMAGE_SERVICE,
    feedsAuth: DEV_AUTH_HOST + FEEDS_AUTH,
    feedsHost: DEV_FEEDS_HOST,
    feedsService: DEV_FEEDS_HOST + FEEDS_SERVICE,
    locationService: DEV_SERVICE_HOST + LOCATION_SERVICE,
    givingUrl: DEV_LEGACY_GIVING_HOST + GIVING_URL,
    newGivingHost: DEV_NEW_WEBAPP_HOST,
    returnUrlToken: DEV_RETURN_URL_ACCESS_TOKEN,
    imagesHost: DEV_IMAGES_HOST,
    searchHost: DEV_SERVICE_HOST + SEARCH_SERVICE,
    tagHost: DEV_SERVICE_HOST + TAG_SERVICE,
    groupsHost: DEV_SERVICE_HOST + GROUPS_SERVICE,
    tokensServiceHost: DEV_SERVICE_HOST + TOKENS_SERVICE,
    tokensServiceClientId: GUEST_TOKENS_SERVICE_CLIENT_ID_DEV,
    tokensServiceClientSecret: GUEST_TOKENS_SERVICE_CLIENT_SECRET_DEV,
    stripeKey: DEV_STRIPE_KEY,
    stripeTestKey: DEV_STRIPE_TEST_KEY,
    unleashClientKey: DEV_UNLEASH_CLIENT_KEY,
    unleashService: DEV_UNLEASH_HOST + UNLEASH_SERVICE,
    legacyWebAppHost: DEV_LEGACY_WEBAPP_HOST,
    paymentsService: DEV_SERVICE_HOST + PAYMENTS_SERVICE,
    walletBranding: DEV_SERVICE_HOST + WALLET_BRANDING,
    peopleService: DEV_SERVICE_HOST + PEOPLE_SERVICE,
    notificationsService: DEV_SERVICE_HOST + NOTIFICATIONS_SERVICE,
    tenorBaseUrl: TENOR_BASE_URL,
    encodedTenorApiKey: ENCODED_TENOR_API_KEY,
    pledgesService: DEV_SERVICE_HOST + PLEDGES_SERVICE,
    webAppHost: localHost || DEV_NEW_WEBAPP_HOST,
  },
  stage: {
    host: hostWithRootPath(STAGE_HOST),
    liveChatHost: hostWithRootPath(STAGE_LIVE_CHAT_HOST),
    analyticsService: ANALYTICS_HOST + API_URL,
    fluroProxyService: STAGE_SERVICE_HOST + FLURO_PROXY_SERVICE,
    gaMeasurementId: GA_MEASUREMENT_ID,
    googleCaptchaKey: GOOGLE_CAPTCHA_KEY_STAGE,
    googleMapsKey: GOOGLE_MAPS_KEY,
    googleMapsKeyDev: GOOGLE_MAPS_KEY_DEV,
    builderService: STAGE_SERVICE_HOST + BUILDER_SERVICE,
    chatService: STAGE_SERVICE_HOST + CHAT_SERVICE,
    checkInService: STAGE_SERVICE_HOST + CHECK_IN_SERVICE,
    eventsService: STAGE_SERVICE_HOST + EVENTS_SERVICE,
    formsService: STAGE_SERVICE_HOST + FORMS_SERVICE,
    mediaPersonalizationService:
      STAGE_SERVICE_HOST + MEDIA_PERSONALIZATION_SERVICE,
    mediaService: STAGE_SERVICE_HOST + MEDIA_SERVICE,
    liveService: STAGE_SERVICE_HOST + LIVE_SERVICE,
    bibleService: STAGE_SERVICE_HOST + BIBLE_SERVICE,
    euaService: STAGE_SERVICE_HOST + END_USER_AUTH_SERVICE,
    accountsService: STAGE_SERVICE_HOST + ACCOUNTS_SERVICE,
    billingService: STAGE_SERVICE_HOST + BILLING_SERVICE,
    donationsService: STAGE_SERVICE_HOST + DONATIONS_SERVICE,
    documentsService: STAGE_SERVICE_HOST + DOCUMENTS_SERVICE,
    imageService: STAGE_SERVICE_HOST + IMAGE_SERVICE,
    feedsAuth: STAGE_AUTH_HOST + FEEDS_AUTH,
    feedsHost: STAGE_FEEDS_HOST,
    feedsService: STAGE_FEEDS_HOST + FEEDS_SERVICE,
    locationService: STAGE_SERVICE_HOST + LOCATION_SERVICE,
    givingUrl: STAGE_LEGACY_GIVING_HOST + GIVING_URL,
    newGivingHost: STAGE_NEW_WEBAPP_HOST,
    returnUrlToken: null,
    imagesHost: STAGE_IMAGES_HOST,
    searchHost: STAGE_SERVICE_HOST + SEARCH_SERVICE,
    tagHost: STAGE_SERVICE_HOST + TAG_SERVICE,
    groupsHost: STAGE_SERVICE_HOST + GROUPS_SERVICE,
    tokensServiceHost: STAGE_SERVICE_HOST + TOKENS_SERVICE,
    tokensServiceClientId: GUEST_TOKENS_SERVICE_CLIENT_ID_STAGE,
    tokensServiceClientSecret: GUEST_TOKENS_SERVICE_CLIENT_SECRET_STAGE,
    stripeKey: STAGE_STRIPE_KEY,
    stripeTestKey: STAGE_STRIPE_TEST_KEY,
    unleashClientKey: DEV_UNLEASH_CLIENT_KEY,
    unleashService: DEV_UNLEASH_HOST + UNLEASH_SERVICE,
    legacyWebAppHost: STAGE_LEGACY_WEBAPP_HOST,
    paymentsService: STAGE_SERVICE_HOST + PAYMENTS_SERVICE,
    walletBranding: STAGE_SERVICE_HOST + WALLET_BRANDING,
    peopleService: STAGE_SERVICE_HOST + PEOPLE_SERVICE,
    notificationsService: STAGE_SERVICE_HOST + NOTIFICATIONS_SERVICE,
    tenorBaseUrl: TENOR_BASE_URL,
    encodedTenorApiKey: ENCODED_TENOR_API_KEY,
    pledgesService: STAGE_SERVICE_HOST + PLEDGES_SERVICE,
    webAppHost: STAGE_NEW_WEBAPP_HOST,
  },
  prod: {
    host: hostWithRootPath(PROD_HOST),
    liveChatHost: hostWithRootPath(PROD_LIVE_CHAT_HOST),
    givingHost: PROD_LEGACY_GIVING_HOST,
    analyticsService: ANALYTICS_HOST + API_URL,
    fluroProxyService: PROD_SERVICE_HOST + FLURO_PROXY_SERVICE,
    gaMeasurementId: GA_MEASUREMENT_ID,
    googleCaptchaKey: GOOGLE_CAPTCHA_KEY_PROD,
    googleMapsKey: GOOGLE_MAPS_KEY,
    googleMapsKeyDev: GOOGLE_MAPS_KEY_DEV,
    builderService: PROD_SERVICE_HOST + BUILDER_SERVICE,
    chatService: PROD_SERVICE_HOST + CHAT_SERVICE,
    checkInService: PROD_SERVICE_HOST + CHECK_IN_SERVICE,
    eventsService: PROD_SERVICE_HOST + EVENTS_SERVICE,
    formsService: PROD_SERVICE_HOST + FORMS_SERVICE,
    mediaPersonalizationService:
      PROD_SERVICE_HOST + MEDIA_PERSONALIZATION_SERVICE,
    mediaService: PROD_SERVICE_HOST + MEDIA_SERVICE,
    liveService: PROD_SERVICE_HOST + LIVE_SERVICE,
    bibleService: PROD_SERVICE_HOST + BIBLE_SERVICE,
    euaService: PROD_SERVICE_HOST + END_USER_AUTH_SERVICE,
    accountsService: PROD_SERVICE_HOST + ACCOUNTS_SERVICE,
    billingService: PROD_SERVICE_HOST + BILLING_SERVICE,
    donationsService: PROD_SERVICE_HOST + DONATIONS_SERVICE,
    documentsService: PROD_SERVICE_HOST + DOCUMENTS_SERVICE,
    imageService: PROD_SERVICE_HOST + IMAGE_SERVICE,
    feedsAuth: PROD_AUTH_HOST + FEEDS_AUTH,
    feedsHost: PROD_FEEDS_HOST,
    feedsService: PROD_FEEDS_HOST + FEEDS_SERVICE,
    locationService: PROD_SERVICE_HOST + LOCATION_SERVICE,
    givingUrl: PROD_LEGACY_GIVING_HOST + GIVING_URL,
    newGivingHost: PROD_NEW_WEBAPP_HOST,
    returnUrlToken: null,
    imagesHost: PROD_IMAGES_HOST,
    searchHost: PROD_SERVICE_HOST + SEARCH_SERVICE,
    tagHost: PROD_SERVICE_HOST + TAG_SERVICE,
    groupsHost: PROD_SERVICE_HOST + GROUPS_SERVICE,
    tokensServiceHost: PROD_SERVICE_HOST + TOKENS_SERVICE,
    tokensServiceClientId: GUEST_TOKENS_SERVICE_CLIENT_ID_PROD,
    tokensServiceClientSecret: GUEST_TOKENS_SERVICE_CLIENT_SECRET_PROD,
    stripeKey: PROD_STRIPE_KEY,
    stripeTestKey: PROD_STRIPE_TEST_KEY,
    unleashClientKey: PROD_UNLEASH_CLIENT_KEY,
    unleashService: PROD_UNLEASH_HOST + UNLEASH_SERVICE,
    legacyWebAppHost: PROD_LEGACY_WEBAPP_HOST,
    paymentsService: PROD_SERVICE_HOST + PAYMENTS_SERVICE,
    walletBranding: PROD_SERVICE_HOST + WALLET_BRANDING,
    peopleService: PROD_SERVICE_HOST + PEOPLE_SERVICE,
    notificationsService: PROD_SERVICE_HOST + NOTIFICATIONS_SERVICE,
    tenorBaseUrl: TENOR_BASE_URL,
    encodedTenorApiKey: ENCODED_TENOR_API_KEY,
    pledgesService: PROD_SERVICE_HOST + PLEDGES_SERVICE,
    webAppHost: PROD_NEW_WEBAPP_HOST,
  },
};

export default Environment[ENV];
