import { KitIcon, KitText } from '@omni/kit/components';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';

import Colors from '@omni/kit/theming/Colors';
import { IconName } from '@omni/kit/components/KitIcon';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';

interface ActionSheetItemProps {
  icon: IconName;
  label: string;
  iconColor?: string;
  onPress?: () => void;
}

export default function ActionSheetItem({
  icon,
  label,
  iconColor,
  onPress,
}: ActionSheetItemProps): JSX.Element {
  return onPress ? (
    <TouchableOpacity style={styles.actionSheetItem} onPress={onPress}>
      <KitIcon name={icon} size={22} color={iconColor || Colors.N900} />
      <KitText
        black
        fontSize={Platform.OS === 'web' ? 16 : 18}
        style={styles.actionSheetText}
      >
        {label}
      </KitText>
    </TouchableOpacity>
  ) : (
    <View style={styles.actionSheetItem}>
      <KitIcon name={icon} size={22} color={iconColor || Colors.brand} />
      <KitText
        black
        fontSize={Platform.OS === 'web' ? 16 : 18}
        style={styles.actionSheetText}
      >
        {label}
      </KitText>
    </View>
  );
}

const styles = StyleSheet.create({
  actionSheetItem: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    paddingBottom: Platform.OS === 'web' ? 8 : 18,
    paddingLeft: Platform.OS === 'web' ? Spacing.l : 3,
    paddingRight: Platform.OS === 'web' ? Spacing.l : 0,
    paddingTop: Platform.OS === 'web' ? 8 : 18,
  },
  actionSheetText: {
    marginLeft: 20,
  },
});
