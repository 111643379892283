import { getStoredItem, setStoredItem } from '@omni/kit/storage';
import { useEffect, useState } from 'react';

export interface ISessionSelection {
  'end-user.id': string;
  'session.id': string;
  'session.title': string;
}

export default function useStoredSessions(
  eventId: string
): [
  ISessionSelection[] | undefined,
  (selections: ISessionSelection[]) => void
] {
  const [storedSelections, setStoredSelections] = useState<
    ISessionSelection[] | undefined
  >();

  useEffect(() => {
    const getStoredSessions = async () => {
      if (eventId) {
        const storedSessionsString = await getStoredItem(
          `pre-check:${eventId}`
        );

        if (storedSessionsString) {
          setStoredSelections(JSON.parse(storedSessionsString));

          return;
        }
      }

      setStoredSelections([]);
    };

    getStoredSessions();
  }, [eventId]);

  const storeSelections = (newSelections: ISessionSelection[]) => {
    if (eventId) {
      setStoredItem(`pre-check:${eventId}`, JSON.stringify(newSelections));
    }

    setStoredSelections(newSelections);
  };

  return [storedSelections, storeSelections];
}
