/**
 * Can be overridden by any consuming app through the
 * `setRequestHeaderOverrides` method exported by Downloader.
 * @see SetRequestHeaderOverrides
 */
export let SharedHeaderOverrides: Record<string, string> = {};

export default function setRequestHeaderOverrides(
  headers: Record<string, string>
): void {
  SharedHeaderOverrides = headers;
}
