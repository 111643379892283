import { KitSnack } from '@omni/kit/components';
import { IJoinRequest } from '@omni/kit/services/GroupsService/Types';
import SendbirdChat, { SendbirdError, User } from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelHandler,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import {
  AdminMessage,
  BaseMessage,
  FileMessage,
  MessageRequestHandler,
  PreviousMessageListQuery,
  UserMessage,
} from '@sendbird/chat/message';
import * as _ from 'lodash';
import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import {
  ChannelMember,
  CustomMessageData,
  SendbirdMessage,
} from '../../../Types';
import ChatService from '../../../services/ChatService';
import {
  findMessageIndex,
  getMessageIndex,
  mapThreads,
} from '../../../utilities/chatUtilities';
import {
  formatSnackList,
  sbBlockUser,
  sbGetBlockedUserList,
  sbUnblockUser,
} from '../../../utilities/sendbird/channelFunctions';
import {
  sbAdjustMessageList,
  sbGetMessage,
  sbGetMessageList,
  sbGetThreadedMessageList,
  sbIsTyping,
  sbSendBibleMessage,
  sbSendFileMessage,
  sbSendGifMessage,
  sbSendMediaMessage,
  sbSendPollMessage,
  sbSendPrayerRequestMessage,
  sbSendTextMessage,
  sbTypingEnd,
  sbTypingStart,
  sbUpdateMessage,
  sbUpdateMessageType,
} from '../../../utilities/sendbird/chatFunctions';
import { sbIsConnected } from '../../../utilities/sendbird/userFunctions';
import {
  appKeySelector,
  currentMessagesSelector,
  currentPrayerSelector,
  memberListSelector,
  parentMessageIdSelector,
  sendToChannelSelector,
} from '../selectors';
import {
  IAppState,
  IFile,
  IInvite,
  IInviteRecord,
  IInvited,
  InviteType,
  MediaMessageDataType,
  MessageType,
} from '../types';

type Message = UserMessage | FileMessage | AdminMessage;

const debug = require('debug')('omni:messaging:redux:actions:ChatActions');

export const ChatActionTypes = {
  INIT_CHAT_SCREEN: 'INIT_CHAT_SCREEN',
  UPDATE_MESSAGE_LIST: 'UPDATE_MESSAGE_LIST',
  UPDATE_PRAYER_LIST: 'UPDATE_PRAYER_LIST',
  SET_USER_TYPING: 'SET_USER_TYPING',
  UPDATE_OWNER_LIST: 'UPDATE_OWNER_LIST',
  UPDATE_MEMBER_LIST: 'UPDATE_MEMBER_LIST',
  UPDATE_BLOCKED_USER_LIST: 'UPDATE_BLOCKED_USER_LIST',
  UPDATE_INVITED_LIST: 'UPDATE_INVITED_LIST',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_BLOCKED_USER: 'SET_BLOCKED_USER',
  SET_UNBLOCKED_USER: 'SET_UNBLOCKED_USER',
  SET_ADDED_USERS: 'SET_ADDED_USERS',
  SET_REMOVED_MEMBER: 'SET_REMOVED_MEMBER',
  SET_CHANNEL: 'SET_CHANNEL',
  SET_CHANNEL_NAME: 'SET_CHANNEL_NAME',
  SET_PARENT_MESSAGE_ID: 'SET_PARENT_MESSAGE_ID',
  SET_SEND_TO_CHANNEL: 'SET_SEND_TO_CHANNEL',
  SET_INVITE_ACCEPTED: 'SET_INVITE_ACCEPTED',
  SET_JOIN_REQUESTS: 'SET_JOIN_REQUESTS',
  REMOVE_JOIN_REQUEST: 'REMOVE_JOIN_REQUEST',
  RESET_CHAT_STATE: 'RESET_CHAT_STATE',
};

//******************************************************************************
// Simple Actions
//******************************************************************************

export const initChatScreen = (): Action => ({
  type: ChatActionTypes.INIT_CHAT_SCREEN,
});

// @ts-ignore
export const updateMessageList = (messages: BaseMessage[]): AnyAction => ({
  type: ChatActionTypes.UPDATE_MESSAGE_LIST,
  payload: messages,
});

// @ts-ignore
export const updatePrayerList = (prayers): AnyAction => ({
  type: ChatActionTypes.UPDATE_PRAYER_LIST,
  payload: prayers,
});

// @ts-ignore
export const setUserTyping = (value): AnyAction => ({
  type: ChatActionTypes.SET_USER_TYPING,
  payload: value,
});

// @ts-ignore
export const updateMemberList = (memberList): AnyAction => ({
  type: ChatActionTypes.UPDATE_MEMBER_LIST,
  payload: memberList,
});

// @ts-ignore
export const updateBlockedUserList = (blockedUserList): AnyAction => ({
  type: ChatActionTypes.UPDATE_BLOCKED_USER_LIST,
  payload: blockedUserList,
});

// @ts-ignore
export const updateInvitedList = (invitedList): AnyAction => ({
  type: ChatActionTypes.UPDATE_INVITED_LIST,
  payload: invitedList,
});

export const setIsLoading = (value: boolean): AnyAction => ({
  type: ChatActionTypes.SET_IS_LOADING,
  payload: value,
});

// @ts-ignore
export const setUnblockedUser = (member): AnyAction => ({
  type: ChatActionTypes.SET_UNBLOCKED_USER,
  payload: member,
});

// @ts-ignore
export const setBlockedUser = (member): AnyAction => ({
  type: ChatActionTypes.SET_BLOCKED_USER,
  payload: member,
});

// @ts-ignore
export const setAddedUsers = (users): AnyAction => ({
  type: ChatActionTypes.SET_ADDED_USERS,
  payload: users,
});

// @ts-ignore
export const setRemovedMember = (member): AnyAction => ({
  type: ChatActionTypes.SET_REMOVED_MEMBER,
  payload: member,
});

// @ts-ignore
export const updateChannel = (channel: GroupChannel | null): AnyAction => ({
  type: ChatActionTypes.SET_CHANNEL,
  payload: channel,
});

export const setChannelName = (name: string): AnyAction => ({
  type: ChatActionTypes.SET_CHANNEL_NAME,
  payload: name,
});

export const setParentMessageId = (parentMessageId?: number): AnyAction => ({
  type: ChatActionTypes.SET_PARENT_MESSAGE_ID,
  payload: parentMessageId,
});

export const setSendToChannel = (sendToChannel: boolean): AnyAction => ({
  type: ChatActionTypes.SET_SEND_TO_CHANNEL,
  payload: sendToChannel,
});

export const setInviteAccepted = (inviteAccepted: boolean): AnyAction => ({
  type: ChatActionTypes.SET_INVITE_ACCEPTED,
  payload: inviteAccepted,
});

export const setJoinRequests = (
  joinRequests: Array<IJoinRequest>
): AnyAction => ({
  type: ChatActionTypes.SET_JOIN_REQUESTS,
  payload: joinRequests,
});

export const removeJoinRequest = (joinRequest: IJoinRequest): AnyAction => ({
  type: ChatActionTypes.REMOVE_JOIN_REQUEST,
  payload: joinRequest,
});

export const resetChatState = (): AnyAction => ({
  type: ChatActionTypes.RESET_CHAT_STATE,
});

//******************************************************************************
// Thunk Actions
//******************************************************************************

export const setChannel = (
  channel: GroupChannel | null
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState().chat;

    if (state.channel?.url && state.channel?.url !== channel?.url) {
      debug('SendbirdChat.instance.removeChannelHandler');
      (
        SendbirdChat.instance as SendbirdGroupChat
      ).groupChannel.removeGroupChannelHandler(state.channel?.url as string);
      debug('remove handler for ', state.channel?.name);
    }

    dispatch(updateChannel(channel));
  };
};

export const startTyping = (
  channel: GroupChannel
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    sbTypingStart(channel.url)
      .then(() => {
        const userTyping = sbIsTyping(channel);
        dispatch(setUserTyping(userTyping));
      })
      .catch(() => dispatch(setUserTyping(null)));
  };
};

export const endTyping = (
  channel: GroupChannel
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    sbTypingEnd(channel.url)
      .then(() => dispatch(setUserTyping(null)))
      .catch((error) => {
        debug('ERROR ending typing: ', error);
      });
  };
};

export const initMessageData = (
  getState: () => IAppState,
  parentId = undefined,
  sendChannel = undefined
): CustomMessageData => {
  const parentMessageId = parentId || parentMessageIdSelector(getState());
  const sendToChannel = sendChannel || sendToChannelSelector(getState());
  let data: CustomMessageData = {};

  if (parentMessageId) {
    data = { replyId: parentMessageId };
    if (sendToChannel) {
      data.sentToChannel = true;
    }
  }

  return data;
};

const sendMessage = (
  handler: MessageRequestHandler | null,
  dispatch: ThunkDispatch<IAppState, unknown, AnyAction>,
  getState: () => IAppState
) => {
  if (handler !== null) {
    handler
      .onPending((message) => {
        onMessageSending(dispatch, getState(), message);
      })
      .onSucceeded((message) => {
        onMessageSent(dispatch, getState(), message);
      })
      .onFailed((error) => {
        debug('Error sending text message: ', error);
      });
  }
};

export const sendTextMessage = (
  channel: GroupChannel | null,
  textMessage: string,
  mentions: string[]
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (!channel) return;

    const data = initMessageData(getState);
    sendMessage(
      sbSendTextMessage(channel, textMessage, mentions, data),
      dispatch,
      getState
    );

    if (data.sentToChannel) {
      // Send again to channel (outside of thread)
      data.inChannel = true;
      sendMessage(
        sbSendTextMessage(channel, textMessage, mentions, data),
        dispatch,
        getState
      );
    }
  };
};

export const updateTextMessage = (
  channel: GroupChannel,
  messageId: number | undefined,
  textMessage: string
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (!messageId) return;

    sbUpdateMessage(channel, messageId, { message: textMessage })
      .then((message) => {
        onMessageSent(dispatch, getState(), message);
      })
      .catch((error) => {
        debug('Error updating text message: ', error);
      });
  };
};

export const updateMessageType = (
  channel: GroupChannel,
  messageId: number | undefined,
  newType: MessageType
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (!messageId) return;

    // @ts-ignore
    sbUpdateMessageType(channel, messageId, newType)
      .then((message) => {
        onMessageSent(dispatch, getState(), message);
      })
      .catch((error) => {
        debug('Error updating text message: ', error);
      });
  };
};

export const updatePrayerRequestAnswered = (
  channel: GroupChannel,
  messageId: number | undefined,
  data: CustomMessageData
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (!messageId) return;

    // @ts-ignore
    sbUpdateMessage(channel, messageId, { data })
      .then((message) => {
        onMessageSent(dispatch, getState(), message);
      })
      .catch((error) => {
        debug('Error updating text message: ', error);
      });
  };
};

// @ts-ignore
export const sendFileMessage = (
  channel: GroupChannel,
  file: IFile
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const sendMessage = (data: CustomMessageData) => {
      const handler = sbSendFileMessage(channel, file, data);

      if (handler !== null) {
        handler
          .onPending((message) => {
            onMessageSending(dispatch, getState(), message);
          })
          .onSucceeded((message) => {
            onMessageSent(dispatch, getState(), message);
          })
          .onFailed((error, message) => {
            const sbError = error as SendbirdError;

            if (sbError.code === 900066 || sbError.code === 900065) {
              KitSnack.show('Image blocked due to content filtering');
            } else {
              KitSnack.show('Error sending ' + file.name);
            }

            debug('Error sending file message: ', error);
            if (message?.messageId) {
              const newMessages = removeMessages(
                [message.messageId],
                currentMessagesSelector(getState())
              );
              dispatch(updateMessageList(newMessages));
            }
          });
      }
    };

    const data = { ...initMessageData(getState), ...file.data };
    sendMessage(data);

    if (data.sentToChannel) {
      // Also send to channel (outside of thread)
      data.inChannel = true;
      sendMessage(data);
    }
  };
};

export const sendPollMessage = (
  channel: GroupChannel,
  question: string,
  options: string[]
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const data: CustomMessageData = { ...initMessageData(getState), options };
    sendMessage(sbSendPollMessage(channel, question, data), dispatch, getState);

    if (data.sentToChannel) {
      data.inChannel = true;
      sendMessage(
        sbSendPollMessage(channel, question, data),
        dispatch,
        getState
      );
    }
  };
};

export const sendPrayerRequestMessage = (
  channel: GroupChannel,
  request: string,
  data?: CustomMessageData
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const messageData = { ...initMessageData(getState), ...data };
    sendMessage(
      sbSendPrayerRequestMessage(channel, request, messageData),
      dispatch,
      getState
    );
    if (messageData.sentToChannel) {
      messageData.inChannel = true;
      sendMessage(
        sbSendPrayerRequestMessage(channel, request, messageData),
        dispatch,
        getState
      );
    }
  };
};

export const sendMediaMessage = (
  channel: GroupChannel,
  data: MediaMessageDataType
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const messageData = { ...initMessageData(getState), ...data };
    sendMessage(sbSendMediaMessage(channel, messageData), dispatch, getState);
    if (messageData.sentToChannel) {
      messageData.inChannel = true;
      sendMessage(sbSendMediaMessage(channel, messageData), dispatch, getState);
    }
  };
};

export const sendGifMessage = (
  channel: GroupChannel,
  file: IFile,
  parentMessageId: number,
  sendToChannel: boolean
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const data = {
      // @ts-ignore
      ...initMessageData(getState, parentMessageId, sendToChannel),
      ...file.data,
    };
    sendMessage(sbSendGifMessage(channel, data), dispatch, getState);
    if (data.sentToChannel) {
      data.inChannel = true;
      sendMessage(sbSendGifMessage(channel, data), dispatch, getState);
    }
  };
};

export const sendBibleMessage = (
  channel: GroupChannel,
  verseData: CustomMessageData
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const data = { ...initMessageData(getState), ...verseData };
    sendMessage(sbSendBibleMessage(channel, data), dispatch, getState);
    if (data.sentToChannel) {
      data.inChannel = true;
      sendMessage(sbSendBibleMessage(channel, data), dispatch, getState);
    }
  };
};

const onMessageSending = (
  dispatch: ThunkDispatch<IAppState, unknown, AnyAction>,
  state: IAppState,
  message: BaseMessage
) => {
  debug('Message Sending');
  if (message) {
    const currentThreadId = parentMessageIdSelector(state);
    const parentMessageId = message.parentMessageId || undefined;

    if (parentMessageId === currentThreadId) {
      const newMessages = insertMessages(
        [message],
        currentMessagesSelector(state)
      );
      // find parent message, update the threadInfo properties on it
      // @ts-ignore
      newMessages[newMessages.length - 1].threadInfo = { replyCount: 1 };
      dispatch(updateMessageList(newMessages));
    }
  }
};

const onMessageSent = (
  dispatch: ThunkDispatch<IAppState, unknown, AnyAction>,
  state: IAppState,
  message: BaseMessage
) => {
  debug('Message Sent');
  if (message) {
    const currentThreadId = parentMessageIdSelector(state);
    const parentMessageId = message.parentMessageId || undefined;

    if (
      parentMessageId === currentThreadId ||
      message.messageId === currentThreadId
    ) {
      const newMessages = insertMessages(
        [message],
        currentMessagesSelector(state)
      );
      dispatch(updateMessageList(newMessages));
      if (message.customType === MessageType.Prayer) {
        const newPrayers = insertMessages(
          [message],
          currentPrayerSelector(state)
        );
        dispatch(updatePrayerList(newPrayers));
      }
    }
  }
};

const insertMessages = (
  messages: BaseMessage[],
  newMessages: BaseMessage[]
) => {
  messages.forEach((message) => {
    const msgIndex = findMessageIndex(message, newMessages);
    const curIndex = getMessageIndex(message, newMessages);

    if (msgIndex >= 0 && !(curIndex >= 0)) {
      newMessages.splice(msgIndex, 0, message);
    }

    if (curIndex >= 0) newMessages.splice(curIndex, 1, message);
  });

  return newMessages;
};

const updateMessages = (
  messages: BaseMessage[],
  newMessages: BaseMessage[]
) => {
  messages.forEach((message) => {
    const msgIndex = getMessageIndex(message, newMessages);

    if (msgIndex >= 0) {
      newMessages[msgIndex] = message;
    }
  });

  return newMessages;
};

const removeMessages = (messageIds: number[], messageList: BaseMessage[]) => {
  const newMessages = [...messageList];
  messageIds.forEach((messageId) => {
    const msgIndex = newMessages.findIndex(
      (msg) => msg.messageId === messageId
    );

    if (msgIndex >= 0) newMessages.splice(msgIndex, 1);
  });

  return newMessages;
};

export const createChatHandler = (
  channelUrl: string,
  parentMessageId?: number
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (!sbIsConnected()) {
      debug('Skipping createChatHandler. Not connected.');

      return;
    }

    const groupChannelHandler: GroupChannelHandler = new GroupChannelHandler({
      onMessageReceived: (ch, message) => {
        debug('SendbirdChat.instance.ChannelHandler.onMessageReceived');
        if (
          ch.url === channelUrl &&
          (!message.parentMessageId ||
            parentMessageId === message.parentMessageId)
        ) {
          const newMessages = insertMessages(
            [message],
            currentMessagesSelector(getState())
          );
          dispatch(updateMessageList(newMessages));
          if (message.customType === MessageType.Prayer) {
            const newPrayers = insertMessages(
              [message],
              currentPrayerSelector(getState())
            );
            dispatch(updatePrayerList(newPrayers));
          }
        }
      },
      onMessageUpdated: (channel, message) => {
        if (channel.url === channelUrl) {
          const newMessages = updateMessages(
            [message],
            currentMessagesSelector(getState())
          );
          dispatch(updateMessageList(newMessages));
        }
      },
      onThreadInfoUpdated: (channel, message) => {
        const messageChanged = currentMessagesSelector(getState()).find(
          (m) => m.messageId === message.targetMessageId
        );

        if (messageChanged) {
          if (channel.url === channelUrl) {
            messageChanged.threadInfo = message.threadInfo;
            const newMessages = updateMessages(
              [messageChanged],
              currentMessagesSelector(getState())
            );
            dispatch(updateMessageList(newMessages));
          }
        }
      },
      onMessageDeleted: (channel, messageId) => {
        if (channel.url === channelUrl) {
          const newMessages = removeMessages(
            [messageId],
            currentMessagesSelector(getState())
          );
          const newPrayers = removeMessages(
            [messageId],
            currentPrayerSelector(getState())
          );
          dispatch(updateMessageList(newMessages));
          dispatch(updatePrayerList(newPrayers));
        }
      },
      onTypingStatusUpdated: (channel) => {
        if (channel.url === channelUrl) {
          const typing = sbIsTyping(channel);
          dispatch(setUserTyping(typing));
        }
      },
      onReactionUpdated: (channel, reactionEvent) => {
        debug('onReactionUpdated', reactionEvent);
        if (channel.url === channelUrl) {
          // @ts-ignore
          const message = currentMessagesSelector(getState()).find(
            (m) => m.messageId === reactionEvent.messageId
          );
          const prayer = currentPrayerSelector(getState()).find(
            (m) => m.messageId === reactionEvent.messageId
          );

          if (message) {
            message.applyReactionEvent(reactionEvent);
            debug(
              'Message reactions after "applyReactionEvent: "',
              message.reactions
            );
            const newMessages = updateMessages(
              [message],
              currentMessagesSelector(getState())
            );
            dispatch(updateMessageList(newMessages));
          } else if (prayer) {
            prayer.applyReactionEvent(reactionEvent);
            debug(
              'Message reactions after "applyReactionEvent: "',
              prayer.reactions
            );
            const newPrayers = [...currentPrayerSelector(getState())];
            const msgIndex = getMessageIndex(prayer, newPrayers);

            if (msgIndex >= 0) {
              newPrayers[msgIndex] = prayer;
            }

            dispatch(updatePrayerList(newPrayers));
          }
        }
      },
    });

    debug('SendbirdChat.instance.addGroupChannelHandler');
    (
      SendbirdChat.instance as SendbirdGroupChat
    ).groupChannel.addGroupChannelHandler(channelUrl, groupChannelHandler);
  };
};

export const getPrevMessageList = (
  previousMessageListQuery: PreviousMessageListQuery
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (previousMessageListQuery.hasNext) {
      sbGetMessageList(previousMessageListQuery)
        .then((messages: BaseMessage[]) => {
          const uniqueList = _.uniqBy(
            [...currentMessagesSelector(getState()), ...messages],
            'messageId'
          );
          dispatch(updateMessageList(uniqueList));
        })
        .catch((error) => {
          debug('Error fetching prev message list: ', error);
        });
    } else {
      debug('No more messages to fetch.');
    }
  };
};

export const getPrevPrayerList = (
  previousMessageListQuery: PreviousMessageListQuery
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (previousMessageListQuery.hasNext) {
      sbGetMessageList(previousMessageListQuery)
        .then((prayers: BaseMessage[]) => {
          const uniqueList = _.uniqBy(
            [...currentPrayerSelector(getState()), ...prayers],
            'messageId'
          );
          dispatch(updatePrayerList(uniqueList));
        })
        .catch((error) => debug('Error fetching prev prayer list: ', error));
    } else {
      debug('No more prayers to fetch.');
    }
  };
};

export const getThreadedMessageList = (
  messageId: number,
  channel: GroupChannel
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    sbGetMessage(messageId, channel)
      .then((message) => {
        if (message) return sbGetThreadedMessageList(message as UserMessage);
      })
      .then((messages) => {
        if (messages) dispatch(updateMessageList(messages));
      })
      .catch((error) =>
        debug(`Error fetching threaded message list: ${error}`)
      );
  };
};

export const getThreadsList = (
  previousMessageListQuery: PreviousMessageListQuery
): Promise<SendbirdMessage[][]> => {
  return new Promise<SendbirdMessage[][]>((resolve, _reject) => {
    if (previousMessageListQuery.hasNext) {
      sbGetAllMessagesList(previousMessageListQuery)
        .then((messages: BaseMessage[]) => sbAdjustMessageList(messages))
        .then((messages: SendbirdMessage[]) => resolve(mapThreads(messages)))
        .catch((error) => {
          debug(`Error fetching prev message list for threads: ${error}`);
          resolve([]);
        });
    } else {
      debug('No more messages to fetch.');
      resolve([]);
    }
  });
};

const sbGetAllMessagesList = (
  previousMessageListQuery: PreviousMessageListQuery
): Promise<BaseMessage[]> => {
  if (!sbIsConnected()) {
    debug('Skipping previousMessageListQuery query. Not connected.');

    return Promise.reject('Not connected. Skipping query');
  }

  return previousMessageListQuery.load();
};

//******************************************************************************
// Users
//******************************************************************************

export const getMemberList = (
  channelUrl: string
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    if (!channelUrl) return;

    sbGetBlockedUserList()
      .then((blockedUsers) => {
        ChatService.getFiltered('channel-members', [['channel_id', channelUrl]])
          .then((response) => {
            const transformedMembers: ChannelMember[] = response.data[
              'channel-members'
              // @ts-ignore
            ].map((member) => {
              const isBlockedByMe = (blockedUsers as User[]).some(
                (user) => user.userId === member.userId
              );

              return {
                id: member.id,
                appKey: member.app_key,
                nickname: member.nickname,
                profileUrl: member.profile_url,
                isOnline: member.is_online,
                userId: member.user_id,
                role: member.role,
                isBlockedByMe,
                _embedded: member._embedded,
              };
            });
            dispatch(updateMemberList(transformedMembers));
          })
          .catch((e) => {
            debug('Error getting channel members: ', e);
          });
      })
      .catch((error) => dispatch(updateMemberList([])));
  };
};

export const getInvitedList = (
  channelUrl: string
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (!channelUrl) return;

    const filters: [string, string][] = [
      ['app_key', appKeySelector(getState())],
      ['chat_client_channel_id', channelUrl],
      ['accepted_at', 'null'],
    ];
    ChatService.getFiltered('channel-invites', filters)
      .then((invites) => {
        const list: IInvited[] = invites.data['channel-invites'].map(
          (invite: IInviteRecord) => {
            return {
              id: invite.id,
              timestamp: invite.created_at,
              title: invite.message_endpoint,
            };
          }
        );
        dispatch(updateInvitedList(list));
      })
      .catch((error) => {
        debug('Error getting invite list: ', error);
        dispatch(updateInvitedList([]));
      });
  };
};

export const inviteMemberList = (
  invites: IInvite[],
  channel: GroupChannel
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    dispatch(setIsLoading(true));

    const successList: string[] = [];
    const failedList: string[] = [];

    Promise.all(
      invites.map(async (invite) => {
        debug('invite', invite);

        return new Promise<void>((resolve, reject) => {
          if (invite.type === InviteType.User) {
            ChatService.post('channel-members', {
              app_key: appKeySelector(getState()),
              user_id: invite.data.userId,
              role: 'member',
              _embedded: { channel: { id: channel.url } },
            })
              .then(() => {
                successList.push(invite.title);
                resolve();
              })
              .catch((err) => {
                debug('Error adding user: ', err);
                const message = `${invite.title} ${
                  err.status === 409
                    ? 'is already added'
                    : 'failed to be added.'
                }`;
                failedList.push(message);
                resolve();
              });
          } else {
            ChatService.post('channel-invite', {
              app_key: appKeySelector(getState()),
              message_type: invite.type.toString(),
              message_endpoint: invite.data.toString(),
              chat_client_channel_id: channel.url,
            })
              .then(() => {
                successList.push(invite.title);
                resolve();
              })
              .catch((err) => {
                debug('Error inviting user: ', err);
                const message = `${invite.title} ${
                  err.status === 409
                    ? 'is already added'
                    : 'failed to be added.'
                }`;
                failedList.push(message);
                resolve();
              });
          }
        });
      })
    ).then(() => {
      dispatch(getMemberList(channel.url));
      dispatch(getInvitedList(channel.url));
      dispatch(setIsLoading(false));

      let snackMessage = '';

      if (successList.length > 0) {
        snackMessage = formatSnackList(successList, 'invited', 3);
      }

      if (failedList.length > 0) {
        snackMessage = snackMessage + failedList;
      }

      dispatch(setAddedUsers(snackMessage));
    });
  };
};

export const getBlockedUserList = (): ThunkAction<
  void,
  IAppState,
  unknown,
  AnyAction
> => {
  return (dispatch) => {
    sbGetBlockedUserList()
      .then((users) => {
        debug('Blocked Users', users);
        dispatch(updateBlockedUserList(users));
      })
      .catch((_) => dispatch(updateBlockedUserList([])));
  };
};

export const unblockUser = (
  channel: GroupChannel,
  user: User | ChannelMember
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    sbUnblockUser(user.userId)
      .then(() => {
        dispatch(setUnblockedUser(user));
        dispatch(getBlockedUserList());
        dispatch(getMemberList(channel.url));
      })
      .catch((error) => debug('Error blocking member: ', error));
  };
};

export const blockUser = (
  _channel: GroupChannel,
  user: User | ChannelMember
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const memberList = memberListSelector(getState());
    sbBlockUser(user.userId)
      .then(() => {
        dispatch(setBlockedUser(user));
        dispatch(getBlockedUserList());
        const newMemberList = memberList.filter(
          (m) => m.userId !== user.userId
        );
        dispatch(updateMemberList(newMemberList));
      })
      .catch((error) => debug('Error blocking member: ', error));
  };
};

export const removeUser = (
  channel: GroupChannel,
  member: ChannelMember
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const memberList = memberListSelector(getState());
    ChatService.delete('channel-members', `${channel.url}_${member?.userId}`)
      .then(() => {
        dispatch(setRemovedMember(member));
        const newMemberList = memberList.filter(
          (m) => m.userId !== member.userId
        );
        dispatch(updateMemberList(newMemberList));
      })
      .catch((error) => debug('Error removing member: ', error));
  };
};

export const changeUserToManager = (
  channel: GroupChannel,
  member: ChannelMember
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    const body = {
      chat_user_id: member.userId,
    };
    ChatService.post('channel-owner', body, channel.url)
      .then((response) => {
        dispatch(getMemberList(channel.url));
      })
      .catch((error) => debug('Error changing member to manager: ', error));
  };
};

export const changeManagerToUser = (
  channel: GroupChannel,
  member: ChannelMember,
  appKey: string
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    // Note that using 'channer-owner' endpoint does not work due to a CORS issue in the service.
    // This usage of 'channel-members' is equivalent.
    const body = {
      app_key: appKey,
      role: 'member',
    };
    ChatService.patch(
      'channel-members',
      `${channel.url}_${member.userId}`,
      body
    )
      .then((response) => {
        dispatch(getMemberList(channel.url));
      })
      .catch((error) => debug('Error changing manager to member: ', error));
  };
};
