import { PresentationWrapper } from '@omni/kit/components/PresentationWrapper';
import { FeatureFlagProvider } from '@omni/kit/contexts/FeatureFlagContext';
import { ScreenVisibilityContextProvider } from '@omni/kit/contexts/ScreenVisibilityContext';
import { ScriptureModalContextProvider } from '@omni/kit/contexts/ScriptureModalContext';
import { SizeClassContextProvider } from '@omni/kit/contexts/SizeClassContext';
import unleash from '@omni/kit/services/UnleashService/unleashClient';
import Colors from '@omni/kit/theming/Colors';
import ThemeContextWrapper, {
  ThemeContext,
} from '@omni/kit/theming/ThemeContext';
import store from '@omni/messaging/shared/redux/store';
import React, { useContext } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';

import { BlockPageProps } from './types';

export default function BlockPageWrapper(
  props: BlockPageProps & { children: React.ReactNode | React.ReactNode[] }
): JSX.Element {
  const { appKey, presentationStyle, theme, onDismiss } = props;

  const { colorForScheme } = useContext(ThemeContext);

  return (
    <Provider store={store}>
      <ThemeContextWrapper theme={theme}>
        <SafeAreaProvider>
          <SizeClassContextProvider>
            <PresentationWrapper
              contentBackgroundColor={colorForScheme?.({
                dark: Colors.N1000,
                light: Colors.N0,
              })}
              presentationStyle={presentationStyle}
              onDismiss={onDismiss}
            >
              <FeatureFlagProvider unleashInstance={unleash} appKey={appKey}>
                <ScriptureModalContextProvider>
                  <ScreenVisibilityContextProvider
                    nativeEventKey={props.nativeEventKey}
                  >
                    {props.children}
                  </ScreenVisibilityContextProvider>
                </ScriptureModalContextProvider>
              </FeatureFlagProvider>
            </PresentationWrapper>
          </SizeClassContextProvider>
        </SafeAreaProvider>
      </ThemeContextWrapper>
    </Provider>
  );
}
