import { KitIconCircle, KitListItem, KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { useNavigation } from '@react-navigation/native';
import { differenceBy } from 'lodash';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import InvitedListRow from '../../../../../mobile/components/channelDetails/InvitedListRow';
import MemberListRow from '../../../../../mobile/components/channelDetails/MemberListRow';

import { ChannelMember } from '../../../../../mobile/Types';
import * as Constants from '../../../../Constants';
import {
  blockedUserListSelector,
  channelSelector,
  invitedListSelector,
  memberListSelector,
  ownerListSelector,
  userSelector,
} from '../../../../redux/selectors';

interface MemberSectionProps {
  isRestricted?: boolean;
  isDirect?: boolean;
  isOwner: boolean;
  showManagersOnly?: boolean;
  showMembersOnly?: boolean;
  renderMemberWebActionSheet?: (
    member: ChannelMember,
    closeMenu: () => void
  ) => React.ReactNode;
  renderOwnerWebActionSheet?: (
    member: ChannelMember,
    closeMenu: () => void
  ) => React.ReactNode;
  onMemberEditPress?: (member: ChannelMember) => void;
  onOwnerEditPress?: (member: ChannelMember) => void;
}

export default function MemberSection({
  isRestricted = false,
  isDirect = false,
  isOwner,
  showManagersOnly = false,
  showMembersOnly = false,
  renderMemberWebActionSheet,
  renderOwnerWebActionSheet,
  onMemberEditPress,
  onOwnerEditPress,
}: MemberSectionProps): JSX.Element | null {
  const channel = useSelector(channelSelector);
  const ownerList = useSelector(ownerListSelector);
  const blockedList = useSelector(blockedUserListSelector);
  const invitedList = useSelector(invitedListSelector);
  const user = useSelector(userSelector);
  const memberList = useSelector(memberListSelector);

  const navigation = useNavigation();

  if (channel === null) {
    return null;
  }

  const _renderManagerList = () => {
    let owners = ownerList;
    const you = owners.find(
      (owner) => owner && user && owner.userId === user.userId
    );

    if (you) {
      owners = ownerList.filter(
        (owner) => owner && you && owner.userId !== you.userId
      );
      owners = [you, ...owners];
    }

    return owners.map((owner) => (
      <MemberListRow
        key={owner.userId}
        member={owner}
        channel={channel}
        onMemberEditPress={onOwnerEditPress}
        renderWebActionSheet={renderOwnerWebActionSheet}
      />
    ));
  };

  const _renderMemberList = () => {
    const members: ChannelMember[] = isDirect
      ? differenceBy(memberList, blockedList, 'userId')
      : differenceBy(memberList, ownerList, 'userId');

    return members.map((member, index) => (
      <MemberListRow
        key={member.userId}
        member={member}
        channel={channel}
        topBorder={index === 0}
        onMemberEditPress={onMemberEditPress}
        renderWebActionSheet={renderMemberWebActionSheet}
      />
    ));
  };

  return (
    <>
      {(!isDirect || showManagersOnly) && !showMembersOnly && (
        <View style={{ marginBottom: Spacing.l }}>
          {!showManagersOnly && (
            <KitText style={styles.labelText} fontSize={16} semiBold black>
              {ownerList.length === 1 ? 'Manager' : 'Managers'}
            </KitText>
          )}
          {_renderManagerList()}
        </View>
      )}
      {!showManagersOnly && (
        <>
          {!showMembersOnly && (
            <KitText style={styles.labelText} fontSize={16} semiBold black>
              {isDirect
                ? 'People'
                : memberList.length > 90
                ? `Members (${memberList.length})`
                : 'Members'}
            </KitText>
          )}
          {(!isRestricted || isOwner) && (
            <KitListItem
              title='Add people'
              subtitle='by name, phone number, or email'
              ImageComponent={
                <KitIconCircle
                  name='add'
                  color={Colors.N0}
                  backgroundColor={Colors.brand}
                  circleSize={Spacing.xxl}
                />
              }
              onPress={() =>
                navigation.navigate(Constants.INVITE_SCREEN, { channel })
              }
              rightIcon=''
            />
          )}
          {_renderMemberList()}
          {invitedList && invitedList.length > 0 && (
            <KitText semiBold black fontSize={16} style={styles.labelText}>
              Invited people
            </KitText>
          )}
          {invitedList &&
            invitedList.map((invite) => (
              <InvitedListRow key={invite.timestamp} invited={invite} />
            ))}
        </>
      )}
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  labelText: {
    marginBottom: Spacing.l,
    paddingTop: Spacing.l,
  },
});
