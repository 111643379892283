import { KitText } from '@omni/kit/components';
import KitIcon from '@omni/kit/components/KitIcon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import TouchableBase from '../../../../../../kit/components/TouchableBase';
import { InviteType } from '../../../../redux/types';

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  text: string;
  type: InviteType;
  onPress: () => void;
}

//******************************************************************************
// Component
//******************************************************************************

export default function ValidInviteListRow({ text, onPress }: IProps) {
  return (
    <TouchableBase
      onPress={onPress}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 18,
        paddingVertical: 6,
        backgroundColor: Colors.N0,
      }}
    >
      <View style={styles.image}>
        <KitIcon name='add' color='#fff' size={20} />
      </View>
      <View style={{ marginLeft: Spacing.m }}>
        <KitText fontSize={16} black>
          Add {text}
        </KitText>
      </View>
    </TouchableBase>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  image: {
    borderRadius: 18,
    height: 36,
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.brand,
  },
});
