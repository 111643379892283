import { IMediaPlaybackStateInfo } from '@omni/kit/components/KitPlayer/playbackTypes';
import CacheService from '@omni/kit/services/CacheService';

import { ICachedProgress, TrackMediaProgressCache } from '.';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:SaveProgressLocally'
);

interface IProps {
  mediaPlaybackStateInfo: IMediaPlaybackStateInfo;
  mediaPlayId?: string;
  mediaItemId?: string;
  userId?: string;
}

/**
 * Save progress locally
 * @param mediaPlaybackStateInfo {IMediaPlaybackStateInfo}
 */
export default ({
  mediaPlaybackStateInfo,
  mediaPlayId = '',
  mediaItemId = '',
  userId = '',
}: IProps): void => {
  const {
    savedStateKey = '',
    durationMsec = 0,
    appKey = '',
  } = mediaPlaybackStateInfo;

  if (savedStateKey) {
    const data: ICachedProgress = {
      appKey,
      mediaPlayId,
      mediaItemId,
      userId,
      duration: durationMsec,
    };
    CacheService.saveToCache(
      savedStateKey,
      JSON.stringify(data),
      TrackMediaProgressCache
    );

    debug(`saveStateKey: ${savedStateKey} to AsyncStorage`, data);
  }
};
