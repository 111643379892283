import { constructGroupSchedule } from '@omni/groups/utilities';
import { IGroup } from '@omni/kit/services/GroupsService/Types';
import { IMediaItem } from '@omni/kit/services/MediaItemService/Types';
import {
  IHit,
  ISearchEmbeddedKeyType,
  ISearchResponse,
} from '@omni/kit/services/SearchService';
import { ITag } from '@omni/kit/services/TagService/Types';
import pluralize from 'pluralize';

export function extractSearchResults(
  response: ISearchResponse
): IMediaItem[] | ITag[] | IGroup[] {
  const results: IMediaItem[] | ITag[] | IGroup[] = [];
  const embedded = response?.body?._embedded;

  if (embedded && embedded.hits) {
    embedded.hits.forEach((hit: IHit) => {
      if (hit.type) {
        let relationshipName = hit.type;
        const scheduleData = {
          frequency: hit.fields.frequency,
          day_of_week: hit.fields.day_of_week,
        };
        const scheduleTitle = constructGroupSchedule(scheduleData as any);

        let includedObject;

        if (['tag:topic', 'tag:speaker'].includes(relationshipName)) {
          relationshipName = relationshipName?.split(':')[1];
        }

        if (relationshipName === 'app') {
          includedObject = hit;
        } else {
          const key = pluralize(relationshipName) as ISearchEmbeddedKeyType;
          includedObject = embedded[key]?.find((model: any) => {
            if (relationshipName === 'app') {
              return model.id === hit.fields.app_key;
            } else {
              return model.id === hit.id;
            }
          });
        }

        if (includedObject) {
          results.push({
            ...includedObject,
            type: relationshipName,
            scheduleTitle: scheduleTitle,
          });
        }
      }
    });
  }

  return results;
}
