import { KitIcon, KitText, KitTouchable } from '@omni/kit/components';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';

import Colors from '@omni/kit/theming/Colors';
import { IconName } from '@omni/kit/components/KitIcon';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';

interface ActionButtonProps {
  icon: IconName;
  label: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

export default function ActionButton({
  icon,
  label,
  onPress,
  style = {},
}: ActionButtonProps): JSX.Element {
  const margin = { margin: Spacing.s, marginRight: 0 };

  return (
    <KitTouchable
      onPress={onPress}
      underlayColor={Colors.N200}
      style={[
        styles.actionButton,
        Platform.OS !== 'android' ? margin : {},
        style,
      ]}
      touchableStyle={margin}
    >
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <KitIcon name={icon} size={22} color={Colors.N900} />
        <KitText black semiBold style={{ marginLeft: Spacing.m }}>
          {label}
        </KitText>
      </View>
    </KitTouchable>
  );
}

const styles = {
  actionButton: {
    backgroundColor: Colors.N100,
    borderRadius: 80,
    paddingHorizontal: Spacing.xl,
    paddingVertical: Spacing.s,
  },
};
