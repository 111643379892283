import i18n from 'i18next';
import 'intl-pluralrules';
import moment from 'moment';
import 'moment/min/locales';
import { initReactI18next } from 'react-i18next';
import { NativeModules, Platform } from 'react-native';

import { en } from './translations';

// get language from native modules per https://github.com/facebook/react-native/issues/26606
const getDeviceLocale = () => {
  if (Platform.OS === 'ios') {
    let locale = NativeModules.SettingsManager?.settings.AppleLocale; // "fr_FR"

    if (locale === undefined) {
      // iOS 13 workaround, take first of AppleLanguages array  ["en", "en-NZ"]
      locale = NativeModules.SettingsManager?.settings.AppleLanguages[0];
    }

    if (locale) {
      return locale;
    }
  }

  if (Platform.OS === 'android') {
    return NativeModules.I18nManager.localeIdentifier;
  }

  return i18n.language;
};

moment.locale(getDeviceLocale());

// ref: https://react.i18next.com/guides/quick-start
i18n
  .use(initReactI18next) // required for using i18n via React Context API
  .init({
    resources: { en },
    lng: getDeviceLocale(),
    fallbackLng: (code) => {
      const baseLanguage = code?.split('_')?.[0];

      return [baseLanguage, 'en']; // fallback to base language, then to English
    },
    interpolation: {
      escapeValue: false, // react already safe from XSS injection
    },
  });

export default i18n;
