import { useShellContext } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import { Optional } from '@omni/kit/Types';
import {
  KitButton,
  KitIcon,
  KitInput,
  KitSnack,
  KitText,
  KitTouchable,
} from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitEditableAvatar from '@omni/kit/components/KitEditableAvatar';
import { IconName } from '@omni/kit/components/KitIcon';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import Show from '@omni/kit/components/Show';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import useShellAuthProviders from '@omni/kit/contexts/ShellContext/hooks/useShellAuthProviders';
import { WindowSizeMin } from '@omni/kit/contexts/SizeClassContext';
import BaseServiceV2 from '@omni/kit/services/BaseServiceV2';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { notifyAppUserSettingsUpdated } from '@omni/kit/utilities/NativeHelpers';
import {
  capitalizeFirstLetter,
  handleHttpErrorResponse,
} from '@omni/kit/utilities/utilities';
import {
  setProfileImageUrl,
  universalLogout,
} from '@omni/messaging/shared/redux/actions/SystemActions';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, Platform, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch } from 'react-redux';

import { useBlockPageContext } from '../../BlockPageContext';
import { IBlockProps, SettingInterface, UpdateActionInterface } from '../types';
import ExtraFields from './ExtraFields';
import AuthProvider from './authProvider/AuthProvider';
import ChangeEmailModal from './modals/ChangeEmailModal';
import ChangePasswordModal from './modals/ChangePasswordModal';
import DeleteAccountModal from './modals/DeleteAccountModal';
import { IUserProfile } from './types';
import {
  calculateProgressNumber,
  checkBirthdayValidation,
  checkPhoneValidation,
  formatPhoneNumber,
  isAccountEdited,
  isValidAddress,
  sanitizePhoneNumber,
} from './utilities';

const debug = require('debug')('tca:blocks:blocks:AccountBlock');

export interface IAccountBlockProps extends IBlockProps, IUserProfile {
  /** Action to perform when a user delete their profile. */
  deleteAccountAction?: UpdateActionInterface;

  /**
   * Action to perform when a user changes account info such as first and last
   * name.
   */
  updateAccountAction?: UpdateActionInterface;

  /**
   * Action to perform when a user changes account info such when it is a fluro
   * user.
   */
  updateProfileAction?: UpdateActionInterface;

  /** Action to perform when a user changes their email address. */
  updateEmailAction?: UpdateActionInterface;

  /** Action to perform when a user changes their password. */
  updatePasswordAction?: UpdateActionInterface;

  loggedInAuthProvider?: { title?: string; icon?: IconName; color?: string };
}

/** These additional props are added by resolver.tsx and are not part of the API feed for the account block */
export interface AccountBlockComponentProps extends IAccountBlockProps {
  addressRequired?: boolean;
  appKey: string;
  clientBrandColor?: string;
  includeLogoutButton?: boolean;
  isLoading?: boolean;
  largeSaveButtonEnabled?: boolean;
  onDismiss?: () => void;
  submitActionRef?: React.MutableRefObject<() => void>;
  setIsEdited?: (isEdited: boolean) => void;
  setIsLoading?: (isLoading: boolean) => void;
  setRefreshing?: (refreshing: boolean) => void;
}

/**
 * Changelog:
 * - 5.15.0: Support for editable profile photo and new fields such as birthday, phone number and location
 * - 5.11.0: AccountBlock added. Support for editable name and email.
 */
const AccountBlock: React.FC<AccountBlockComponentProps> = ({
  // Data
  accountDeleted = false,
  addressLine1 = '',
  addressLine2 = '',
  addressRequired = false,
  appKey,
  appTitle = '',
  city = '',
  clientBrandColor,
  country = '',
  dateOfBirth = '',
  email,
  firstName,
  horizontalSpacing,
  id,
  image = '',
  includeLogoutButton = false,
  isLoading = false,
  largeSaveButtonEnabled = false,
  lastName,
  loggedInAuthProvider,
  phoneNumber = '',
  postalCode = '',
  profileID,
  state = '',

  // Actions
  onDismiss,
  setIsEdited,
  setIsLoading,
  setRefreshing,
  submitActionRef,
  deleteAccountAction,
  updateAccountAction,
  updateEmailAction,
  updatePasswordAction,
  updateProfileAction,
}): JSX.Element => {
  const { t } = useTranslation(['account']);

  // ---------------------------------------------------------------------------
  // Global State
  const { tokens } = useShellContext();
  const { targetProviders } = useShellAuthProviders();
  const { authProviderId } = useBlockPageContext();
  const reduxDispatch = useDispatch();

  const targetProviderToken = useMemo(
    () =>
      authProviderId
        ? tokens.accessTokens[authProviderId] || undefined
        : undefined,
    [authProviderId, tokens.accessTokens]
  );

  // ---------------------------------------------------------------------------
  // Local State
  const [_snackOptions, _setSnackOptions] = useState({
    duration: KitSnackDuration.SHORT,
    marginBottom: Spacing.l,
    marginTop: '95vh',
    message: '',
    visible: false,
  });

  const [addressLine1Value, setAddressLine1Value] = useState('');
  const [addressLine2Value, setAddressLine2Value] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [countryValue, setCountryValue] = useState<string | number>('');
  const [dateOfBirthValue, setDateOfBirthValue] = useState('');
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isNameValid, setIsNameValid] = useState<boolean>(true);
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] =
    useState<boolean>(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [postalCodeValue, setPostalCodeValue] = useState('');
  const [profilePhotoId, setProfilePhotoId] = useState('');
  const [profilePhotoUrlValue, setProfilePhotoUrlValue] = useState('');
  const [stateValue, setStateValue] = useState<string | number>('');

  // ---------------------------------------------------------------------------
  // Computed State

  const progressActivityNumber = useMemo((): number => {
    // calculate percentage how many fields are completed
    return calculateProgressNumber({
      addressLine1,
      city,
      country,
      dateOfBirth,
      phoneNumber,
      postalCode,
      state,
    });
  }, [
    addressLine1,
    city,
    country,
    dateOfBirth,
    phoneNumber,
    postalCode,
    state,
  ]);

  const isEdited = useMemo((): boolean => {
    return isAccountEdited({
      addressLine1,
      addressLine1Value,
      addressLine2,
      addressLine2Value,
      city,
      cityValue,
      country,
      countryValue,
      dateOfBirth,
      dateOfBirthValue,
      firstName,
      firstNameValue,
      hasExtraFields: Boolean(updateProfileAction),
      image,
      lastName,
      lastNameValue,
      phoneNumber,
      phoneNumberValue,
      postalCode,
      postalCodeValue,
      profilePhotoUrlValue,
      state,
      stateValue,
    });
  }, [
    addressLine1,
    addressLine1Value,
    addressLine2,
    addressLine2Value,
    city,
    cityValue,
    country,
    countryValue,
    dateOfBirth,
    dateOfBirthValue,
    firstName,
    firstNameValue,
    image,
    lastName,
    lastNameValue,
    phoneNumber,
    phoneNumberValue,
    postalCode,
    postalCodeValue,
    profilePhotoUrlValue,
    state,
    stateValue,
    updateProfileAction,
  ]);

  const hasMissingAddressFields = useMemo((): boolean => {
    return (
      addressRequired &&
      !isValidAddress(
        countryValue,
        addressLine1Value,
        cityValue,
        stateValue,
        postalCodeValue
      )
    );
  }, [
    addressLine1Value,
    addressRequired,
    cityValue,
    countryValue,
    postalCodeValue,
    stateValue,
  ]);

  // ---------------------------------------------------------------------------
  // Callbakcs

  const handleNamesErrorMessage = useCallback(
    (errorMessage: Optional<string> = null, error: Optional<string> = null) => {
      if (error === 'first_name_cannot_contain_emojis') {
        setIsFirstNameValid(false);
      } else if (error === 'last_name_cannot_contain_emojis') {
        setIsLastNameValid(false);
      } else if (error === 'names_cannot_contain_emojis') {
        setIsNameValid(false);
      } else {
        const snackMessage = errorMessage?.length
          ? capitalizeFirstLetter(errorMessage)
          : 'Unable to save. Please try again.';

        KitSnack.show(snackMessage, KitSnack.duration.SHORT);
      }
    },
    []
  );

  const handleNameUpdated = useCallback(
    (
      success = true,
      data: Optional<{ error: string }> = null,
      errorMessage: Optional<string> = null
    ): void => {
      setIsFirstNameValid(true);
      setIsLastNameValid(true);
      setIsNameValid(true);
      const error = data?.error;

      if (success) {
        KitSnack.show('Account updated');
        // Server takes a while before returning the updated data
        setTimeout(() => {
          if (Platform.OS === 'web') {
            // This triggers a reload of the account feed
            setRefreshing?.(true);
          }

          notifyAppUserSettingsUpdated();
        }, 500);
      } else {
        // Show save button if any error occurs
        setIsEdited?.(true);
        handleNamesErrorMessage(errorMessage, error);
      }

      setIsLoading?.(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const saveNewPassword = async (
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    const originalAction = updatePasswordAction;

    if (originalAction?.updateUrl && originalAction?.settings?.[0]) {
      Keyboard.dismiss();

      const settings = [...(originalAction?.settings ?? [{}])];
      settings[0] = { ...settings[0], value: [oldPassword, newPassword] };

      const url = originalAction.updateUrl;

      const res = await BaseServiceV2.Put({
        url,
        appKey,
        authProviderId,
        targetProviders,
        token: targetProviderToken,
        data: { profileID, settings },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      notifyAppUserSettingsUpdated();

      const ok = res.status === 201 || res.status === 200;

      if (!ok) {
        throw new Error();
      }
    }
  };

  const handleLogOut = (): void => {
    if (onDismiss) {
      onDismiss();
    }

    // DISP-4928: Dispatch logout after a delay to prevent the app from
    // freezing, crashing or the auth modal being rendered unexpectedly
    // after returning to PAM on the 2nd+ login attempt
    setTimeout(() => {
      reduxDispatch(universalLogout());
    }, 500);
  };

  const handleSave = (): void => {
    if (addressRequired && hasMissingAddressFields) {
      KitSnack.show('Address fields are required');

      return;
    }

    submitActionRef?.current?.();

    if (Platform.OS === 'web') {
      // prevent usage of web specific window and CustomEvent objects on iOS & Android
      window.dispatchEvent(
        new CustomEvent('account-saved', {
          bubbles: true,
          cancelable: false,
          composed: true,
        })
      );
    }
  };

  const saveNewName = useCallback(async (): Promise<void> => {
    if ((updateAccountAction?.settings || []).length > 1) {
      setIsLoading?.(true);
      setIsEdited?.(false);
      const settings = [...(updateAccountAction?.settings ?? [{}])];

      for (let i = 0; i < settings.length; i++) {
        if (settings[i].sapid === 'app_user_first_name') {
          settings[i] = { ...settings[i], value: firstNameValue };
        } else if (settings[i].sapid === 'app_user_last_name') {
          settings[i] = { ...settings[i], value: lastNameValue };
        }
      }

      const url = updateAccountAction?.updateUrl;

      const res = await BaseServiceV2.Put({
        url: url as string,
        appKey,
        authProviderId,
        targetProviders,
        token: targetProviderToken,
        data: { settings },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 201 || res.status === 200) {
        handleNameUpdated();
      } else {
        const { data, message } = await handleHttpErrorResponse(res);
        handleNameUpdated(false, data, message);
      }

      Keyboard.dismiss();
    }
  }, [
    appKey,
    firstNameValue,
    handleNameUpdated,
    lastNameValue,
    setIsEdited,
    setIsLoading,
    updateAccountAction,
  ]);

  const saveAccountDataFields = useCallback(async (): Promise<void> => {
    if (
      (updateProfileAction?.settings || []).length > 1 &&
      checkBirthdayValidation(dateOfBirthValue) &&
      checkPhoneValidation(phoneNumberValue)
    ) {
      setIsLoading?.(true);
      setIsEdited?.(false);
      const settings: Array<SettingInterface | null> = [
        ...(updateProfileAction?.settings ?? [{}]),
      ];

      for (let i = 0; i < settings.length; i++) {
        if (settings[i]?.sapid === 'app_user_first_name') {
          settings[i] = { ...settings[i], value: firstNameValue };
        } else if (settings[i]?.sapid === 'app_user_last_name') {
          settings[i] = { ...settings[i], value: lastNameValue };
        } else if (settings[i]?.sapid === 'app_user_profile_photo_id') {
          if (profilePhotoUrlValue !== image) {
            settings[i] = {
              ...settings[i],
              value: profilePhotoId,
            };
          } else {
            // Set app_user_profile_photo_id as null if photo is not updated.
            settings[i] = null;
          }
        } else if (settings[i]?.sapid === 'app_user_date_of_birth') {
          settings[i] = {
            ...settings[i],
            value: dateOfBirthValue
              ? moment(dateOfBirthValue, 'MM/DD/YYYY').format()
              : '',
          };
        } else if (settings[i]?.sapid === 'app_user_phone_number') {
          settings[i] = {
            ...settings[i],
            value: phoneNumberValue
              ? sanitizePhoneNumber(phoneNumberValue)
              : '',
          };
        } else if (settings[i]?.sapid === 'app_user_address_line_1') {
          settings[i] = { ...settings[i], value: addressLine1Value };
        } else if (settings[i]?.sapid === 'app_user_address_line_2') {
          settings[i] = { ...settings[i], value: addressLine2Value };
        } else if (settings[i]?.sapid === 'app_user_city') {
          settings[i] = { ...settings[i], value: cityValue };
        } else if (settings[i]?.sapid === 'app_user_state') {
          settings[i] = { ...settings[i], value: stateValue };
        } else if (settings[i]?.sapid === 'app_user_country') {
          settings[i] = { ...settings[i], value: countryValue };
        } else if (settings[i]?.sapid === 'app_user_postal_code') {
          settings[i] = { ...settings[i], value: postalCodeValue };
        }
      }

      /**
       * In case profile image is not updated, we should remove null that is set
       * above and send the payload without image object.
       */
      const settingsToUpdate = settings.filter(Boolean) as SettingInterface[];

      const url = updateProfileAction?.updateUrl;

      const res = await BaseServiceV2.Put({
        url: url as string,
        appKey,
        authProviderId,
        targetProviders,
        token: targetProviderToken,
        data: { profileID, settings: settingsToUpdate },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const ok = res.status === 201 || res.status === 200;

      if (ok) {
        handleNameUpdated();
        if (profilePhotoUrlValue !== image) {
          reduxDispatch(setProfileImageUrl(profilePhotoUrlValue));
        }
      } else {
        const { data, message } = await handleHttpErrorResponse(res);
        handleNameUpdated(false, data, message);
      }

      Keyboard.dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addressLine1Value,
    addressLine2Value,
    appKey,
    cityValue,
    countryValue,
    dateOfBirthValue,
    firstNameValue,
    handleNameUpdated,
    image,
    lastNameValue,
    phoneNumberValue,
    postalCodeValue,
    profileID,
    profilePhotoId,
    profilePhotoUrlValue,
    setIsEdited,
    setIsLoading,
    stateValue,
    updateProfileAction?.settings,
    updateProfileAction?.updateUrl,
  ]);

  // ---------------------------------------------------------------------------
  // Effects

  useEffect((): void => {
    /**
     * The initial state may have changed while the component is mounted
     * (e.g. during cache refresh).
     */
    setAddressLine1Value(addressLine1);
    setAddressLine2Value(addressLine2);
    setCityValue(city);
    setCountryValue(country);
    setDateOfBirthValue(
      dateOfBirth ? moment(dateOfBirth).format('MM/DD/YYYY') : ''
    );
    setFirstNameValue(firstName);
    setLastNameValue(lastName);
    setPhoneNumberValue(formatPhoneNumber(phoneNumber));
    setPostalCodeValue(postalCode);
    setProfilePhotoUrlValue(image);
    setStateValue(state);
  }, [
    addressLine1,
    addressLine2,
    city,
    country,
    dateOfBirth,
    firstName,
    image,
    lastName,
    phoneNumber,
    postalCode,
    state,
  ]);

  useEffect((): void => {
    setIsEdited?.(isEdited);

    // remove names error message when account is not edited
    if (!isEdited) {
      setIsFirstNameValid(true);
      setIsLastNameValid(true);
      setIsNameValid(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdited]);

  useEffect((): void => {
    // Logout user from device if the acccount is deleted
    if (accountDeleted) {
      handleLogOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDeleted]);

  useEffect((): void => {
    if (submitActionRef) {
      submitActionRef.current = updateProfileAction
        ? saveAccountDataFields
        : saveNewName;
    }
  }, [
    saveAccountDataFields,
    saveNewName,
    submitActionRef,
    updateProfileAction,
  ]);

  const { edgeSpacing, viewPortWidth } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });
  const layout2Column = viewPortWidth >= WindowSizeMin.Medium;

  return (
    <View testID='AccountBlock'>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps='always'
        enableOnAndroid={true}
        extraScrollHeight={Platform.OS === 'android' ? 100 : 0}
        testID='KeyboardAwareScrollView'
      >
        <View
          style={{ marginHorizontal: edgeSpacing, paddingBottom: Spacing.xxl }}
          testID='AccountBlockContainer'
        >
          <Show show={!isFirstNameValid || !isLastNameValid || !isNameValid}>
            <View
              testID='ValidationContainer'
              style={[
                styles.errorLabel,
                !isLastNameValid ? { right: 0 } : { left: 0 },
              ]}
            >
              <KitText
                fontSize={14}
                bold
                style={{ color: Colors.N0 }}
                testID='NameErrorMessage'
              >
                Names cannot contain emojis
              </KitText>
            </View>
          </Show>
          <View style={styles.headerContainer} testID='AvatarContainer'>
            <Show show={Boolean(updateAccountAction)}>
              <KitAvatar
                border={false}
                nickname={firstNameValue + ' ' + lastNameValue || ''}
                size={104}
                testID='Avatar'
              />
            </Show>
            <Show show={Boolean(updateProfileAction)}>
              <KitEditableAvatar
                appKey={appKey}
                endUserId={id}
                imageUrl={profilePhotoUrlValue}
                nickname={firstNameValue + ' ' + lastNameValue || ''}
                onPhotoChange={(imageValue: { id: string; url: string }) => {
                  setProfilePhotoId(imageValue.id);
                  setProfilePhotoUrlValue(imageValue.url);
                }}
              />
            </Show>
            <KitText
              h1
              style={{ marginTop: Spacing.s }}
              testID='FistAndLastName'
            >
              {firstNameValue} {lastNameValue}
            </KitText>
          </View>
          <Show show={Boolean(updateAccountAction || updateProfileAction)}>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: Spacing.l,
                marginTop: Spacing.m,
              }}
              testID='UserNameContainer'
            >
              <KitInput
                label='First name'
                onChangeText={setFirstNameValue}
                style={{
                  flex: 1,
                  marginRight: Spacing.l,
                }}
                testID='FirstName'
                value={firstNameValue}
                isValid={isFirstNameValid && isNameValid}
              />
              <KitInput
                label='Last name'
                onChangeText={setLastNameValue}
                style={{ flex: 1 }}
                testID='LastName'
                value={lastNameValue}
                isValid={isLastNameValid && isNameValid}
              />
            </View>
          </Show>
          <Show show={Boolean(updateEmailAction)}>
            <View style={{ marginBottom: Spacing.l }} testID='EmailContainer'>
              <KitText semiBold style={styles.label} testID='Label Email'>
                Email address
              </KitText>
              <KitTouchable
                testID='EmailAddressTouchable'
                noRadius
                onPress={() => {
                  updateEmailAction?.handler === 'appUser' &&
                  updateEmailAction?.updateUrl
                    ? setEmailModalVisible(true)
                    : dispatch(updateEmailAction);
                }}
              >
                <KitInput
                  editable={false}
                  outline
                  rightIcon={<KitIcon name='edit' size={20} />}
                  testID='EmailAccount'
                  value={email}
                />
              </KitTouchable>
            </View>
          </Show>
          <Show show={Boolean(updatePasswordAction)}>
            <View
              style={{ marginBottom: Spacing.l }}
              testID='PasswordContainer'
            >
              <KitText semiBold style={styles.label} testID='LabelPassword'>
                Password
              </KitText>
              <KitTouchable
                testID='PasswordTouchable'
                noRadius
                onPress={() => {
                  updatePasswordAction?.handler === 'appUser' &&
                  updatePasswordAction?.updateUrl
                    ? setPasswordModalVisible(true)
                    : dispatch(updatePasswordAction);
                }}
              >
                <KitInput
                  editable={false}
                  outline
                  testID='PasswordAccount'
                  rightIcon={<KitIcon name='edit' size={20} />}
                  value='••••••••••'
                />
              </KitTouchable>
            </View>
          </Show>

          <Show
            show={Boolean(
              ((!updateAccountAction && !updateProfileAction) ||
                !updateEmailAction ||
                !updatePasswordAction) &&
                loggedInAuthProvider?.title &&
                loggedInAuthProvider?.icon
            )}
          >
            <AuthProvider
              email={email}
              loggedInAuthProvider={loggedInAuthProvider}
            />
          </Show>

          <Show show={Boolean(updateProfileAction)}>
            <ExtraFields
              addressLine1Value={addressLine1Value}
              addressLine2Value={addressLine2Value}
              appTitle={appTitle}
              cityValue={cityValue}
              countryValue={countryValue}
              dateOfBirthValue={dateOfBirthValue}
              layout2Column={layout2Column}
              missingAddressFields={hasMissingAddressFields}
              phoneNumberValue={phoneNumberValue}
              postalCodeValue={postalCodeValue}
              progressActivityNumber={progressActivityNumber}
              setAddressLine1Value={setAddressLine1Value}
              setAddressLine2Value={setAddressLine2Value}
              setCityValue={setCityValue}
              setCountryValue={setCountryValue}
              setDateOfBirthValue={setDateOfBirthValue}
              setPhoneNumberValue={setPhoneNumberValue}
              setPostalCodeValue={setPostalCodeValue}
              setStateValue={setStateValue}
              stateValue={stateValue}
            />
          </Show>

          <Show show={largeSaveButtonEnabled}>
            <KitButton
              color={clientBrandColor}
              disabled={!isEdited || isLoading}
              isLoading={isLoading}
              onPress={handleSave}
              style={{ marginBottom: Spacing.m }}
              title='Save'
              testID='button-save'
            />
          </Show>

          <Show show={includeLogoutButton}>
            <KitButton
              onPress={handleLogOut}
              secondary
              style={{ marginBottom: Spacing.m }}
              title='Log out'
              testID='button-logout'
            />
          </Show>

          <Show show={Boolean(deleteAccountAction)}>
            <KitButton
              onPress={() => setDeleteAccountModalVisible(true)}
              color={Colors.R500}
              style={{ marginBottom: Spacing.m }}
              title={t('account:deleteAccountTitle')}
              testID='button-delete'
            />
          </Show>

          <ChangeEmailModal
            appKey={appKey}
            clientBrandColor={clientBrandColor}
            email={email}
            profileID={profileID}
            setRefreshing={setRefreshing}
            setVisible={setEmailModalVisible}
            updateEmailAction={updateEmailAction}
            visible={emailModalVisible}
          />

          <ChangePasswordModal
            clientBrandColor={clientBrandColor}
            saveNewPassword={saveNewPassword}
            setVisible={setPasswordModalVisible}
            visible={passwordModalVisible}
          />

          <DeleteAccountModal
            appKey={appKey}
            setVisible={setDeleteAccountModalVisible}
            visible={deleteAccountModalVisible}
            deleteAccountAction={deleteAccountAction}
          />
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

export default AccountBlock;

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: Spacing.m,
  },
  label: {
    color: Colors.N500,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 18,
    marginBottom: Spacing.s,
  },
  errorLabel: {
    backgroundColor: Colors.R500,
    borderRadius: Spacing.s,
    marginTop: Spacing.s,
    paddingBottom: Spacing.s,
    paddingLeft: Spacing.l,
    paddingRight: Spacing.l,
    paddingTop: Spacing.s,
    position: 'absolute',
    top: 247,
    zIndex: 2,
  },
});
