import React from 'react';
import { Linking, Platform, Pressable, View, ViewStyle } from 'react-native';

import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { dispatchAction } from '../../utilities/NativeHelpers';
import KitIcon from '../KitIcon';
import KitText from '../KitText';
import { styles } from './styles';

export interface KitCopyrightLogoProps {
  linkToWebsite?: boolean;
  showDebug?: boolean;
  viewStyle?: ViewStyle;
}

export default function KitCopyrightLogo({
  linkToWebsite,
  showDebug,
  viewStyle,
}: KitCopyrightLogoProps): JSX.Element {
  const fullYear = new Date().getFullYear();

  const _onPress = () => {
    Linking.openURL('https://www.subsplash.com/');
  };

  const _onLongPress = () => {
    dispatchAction({
      handler: 'debug',
    });
  };

  return (
    <Pressable
      onPress={linkToWebsite ? _onPress : undefined}
      onLongPress={showDebug ? _onLongPress : undefined}
      style={[styles.brandingContainer, viewStyle]}
    >
      <KitIcon
        name='subsplash-logo'
        width={122}
        height={11}
        style={{
          marginTop: Platform.OS === 'web' ? Spacing.xxl : 0,
        }}
      />
      <KitText
        color={Colors.N500}
        fontSize={14}
        style={{ marginTop: Spacing.s }}
      >
        {`© ${fullYear} Subsplash, Inc.`}
      </KitText>
    </Pressable>
  );
}
