import Color from 'color';
import React, { useEffect, useState } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';

import BorderRadius from '../theming/BorderRadius';
import Colors from '../theming/Colors';
import KitIcon from './KitIcon';
import KitTouchable from './KitTouchable';

interface IKitCheckmarkProps {
  checked: boolean;
  onToggle: (checked: boolean) => void;
}

export default function KitCheckmark(props: IKitCheckmarkProps): JSX.Element {
  const [checkScaleValue] = useState(new Animated.Value(0));
  const [checkOpacityValue] = useState(new Animated.Value(0));

  const _onPress = () => {
    ReactNativeHapticFeedback.trigger('impactMedium', {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
    props.onToggle(!props.checked);
  };

  useEffect(() => {
    Animated.parallel([
      Animated.spring(checkScaleValue, {
        toValue: props.checked ? 1 : 0,
        bounciness: 6,
        speed: 45,
        useNativeDriver: true,
      }),
      Animated.spring(checkOpacityValue, {
        toValue: props.checked ? 1 : 0,
        bounciness: 6,
        speed: 45,
        useNativeDriver: true,
      }),
    ]).start();
  }, [checkOpacityValue, checkScaleValue, props.checked]);

  return (
    <View style={{ alignSelf: 'center' }}>
      <KitTouchable
        onPress={_onPress}
        style={[
          {
            width: 40,
            height: 40,
            borderRadius: 50,
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}
        underlayColor={Colors.N75}
      >
        <View
          style={[
            styles.container,
            { borderColor: props.checked ? Colors.N1000 : Colors.N200 },
          ]}
        >
          <Animated.View
            style={{
              width: 20,
              height: 20,
              padding: 2,
              backgroundColor: Colors.N1000,
              transform: [{ scale: checkScaleValue }],
              opacity: checkOpacityValue,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <KitIcon name='check' size={12} color={Colors.N0} />
          </Animated.View>
        </View>
      </KitTouchable>
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: 20,
    height: 20,
    borderWidth: 1,
    borderRadius: BorderRadius.s,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
