import { SUBSPLASH_AUTH_PROVIDER_ID, useShellContext } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import Downloader, {
  IDownloaderResponse,
  NOT_MODIFIED,
} from '@omni/kit/Downloader';
import { BridgeAction } from '@omni/kit/Types';
import { isUrlAuthorizedForProviders } from '@omni/kit/auth';
import useShellAuthProviders from '@omni/kit/contexts/ShellContext/hooks/useShellAuthProviders';
import CacheService from '@omni/kit/services/CacheService';
import {
  NativeTopBarStyle,
  dismissReactNativeModal,
  getNativeTopBarHeight,
  setTopBarStyle,
  setTopBarTitle,
} from '@omni/kit/utilities/NativeHelpers';
import React, {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  EmitterSubscription,
  NativeEventEmitter,
  NativeModules,
  Platform,
} from 'react-native';

import { BlockData, ComponentType, ErrorBlockData } from './blocks/types';
import { FallbackFileName, getFallbackFile } from './fallbacks';
import { generateErrorPage } from './templates/examples/GeneratePage';
import {
  BlockPageContentData,
  BlockPageErrorData,
  BlockPageProps,
} from './types';
import { getActionUrl, isBlockPageContentHandler } from './utilities';

const debug = require('debug')('tca:blocks:BlockPageContext');

interface ProviderProps {
  children?: React.ReactNode;
  props: BlockPageProps;
}

interface ContextData {
  addressRequired: boolean;
  align: 'start' | 'end' | 'center' | undefined;
  animationEnabled: boolean;
  appKey: string | undefined;
  authProviderId: string | undefined;
  blockPageDeltaInsetTop: number;
  blockPageUrl: string | undefined;
  blocksData: Array<BlockData | ErrorBlockData>;
  clientBrandColor: string | undefined;
  contentData: BlockPageContentData | null;
  guestToken: string | undefined;
  includeCloseButton: boolean;
  includeLogoutButton: boolean;
  isBlockPageEdited: boolean;
  isBlockPageLoading: boolean;
  isBlockPageRefreshing: boolean;
  isBlockPageReplacing: boolean;
  largeSaveButtonEnabled: boolean;
  dispatchAction: (
    action: BridgeAction | null | undefined,
    callback?: (event: any) => void
  ) => void;
  onDismiss: () => void;
  refreshBlockPage: () => void;
  setCurrentTopBarStyle: (topBarStyle: NativeTopBarStyle | undefined) => void;
  setIsBlockPageEdited: (isEdited: boolean) => void;
  setIsBlockPageLoading: (isLoading: boolean) => void;
  setIsBlockPageRefreshing: (isRefreshing: boolean) => void;
  submitActionRef: MutableRefObject<() => void>;
}

const BlockPageContext = createContext<ContextData>({
  addressRequired: false,
  align: undefined,
  animationEnabled: false,
  appKey: undefined,
  authProviderId: undefined,
  blockPageDeltaInsetTop: 0,
  blockPageUrl: undefined,
  blocksData: [],
  contentData: null,
  clientBrandColor: undefined,
  guestToken: undefined,
  includeCloseButton: true,
  includeLogoutButton: true,
  isBlockPageEdited: false,
  isBlockPageLoading: false,
  isBlockPageRefreshing: false,
  isBlockPageReplacing: false,
  largeSaveButtonEnabled: false,
  dispatchAction: () => undefined,
  onDismiss: () => undefined,
  refreshBlockPage: () => undefined,
  setCurrentTopBarStyle: () => undefined,
  setIsBlockPageEdited: () => undefined,
  setIsBlockPageLoading: () => undefined,
  setIsBlockPageRefreshing: () => undefined,
  submitActionRef: { current: () => undefined },
});

export const BlockPageContextProvider = ({
  children,
  props,
}: ProviderProps): null | JSX.Element => {
  const {
    addressRequired = false,
    animationEnabled = true,
    authProviderId,
    clientBrandColor,
    data,
    fallbackResource,
    guestToken,
    largeSaveButtonEnabled = false,
    includeCloseButton = true,
    includeLogoutButton = true,
    topBarStyle,
    url,
    onDismiss = dismissReactNativeModal,
  } = props;

  const { app, tokens, dispatchEvent } = useShellContext();
  const { targetProviders } = useShellAuthProviders();

  const appKey = app.appKey;

  const accessToken = useMemo(() => {
    return tokens?.accessTokens?.[authProviderId ?? SUBSPLASH_AUTH_PROVIDER_ID];
  }, [authProviderId, tokens?.accessTokens]);

  const [blockPageUrl, setBlockPageUrl] = useState<string | undefined>(url);

  const [contentData, setContentData] = useState<BlockPageContentData | null>(
    null
  );
  const [errorCode, setErrorCode] = useState(0);
  const [errorData, setErrorData] = useState<BlockPageErrorData | null>(null);
  const [blocksData, setBlocksData] = useState<
    Array<BlockData | ErrorBlockData>
  >([]);
  const [align, setAlign] = useState<'start' | 'end' | 'center' | undefined>(
    undefined
  );
  const [isBlockPageEdited, setIsBlockPageEdited] = useState(false);
  const [isBlockPageLoading, setIsBlockPageLoading] = useState(false);
  const [isBlockPageRefreshing, setIsBlockPageRefreshing] = useState(false);
  const [isBlockPageReplacing, setIsBlockPageReplacing] = useState(false);

  const [initialTopBarStyle, setInitialTopBarStyle] = useState(topBarStyle);
  const [currentTopBarStyle, setCurrentTopBarStyle] =
    useState(initialTopBarStyle);
  const [blockPageDeltaInsetTop, setBlockPageDeltaInsetTop] = useState(0);
  const [deltaInsetRetrieved, setDeltaInsetRetrieved] = useState(false);

  const submitActionRef = useRef<() => void>() as MutableRefObject<() => void>;

  const _loadCachedFeed = async (): Promise<IDownloaderResponse | void> => {
    if (!blockPageUrl) return;

    const token = accessToken?.replace('Bearer ', '');

    return await CacheService.getCachedResponse({
      url: blockPageUrl,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    });
  };

  const _downloadFeed = (
    cachedResponse?: IDownloaderResponse,
    impression?: boolean
  ): void => {
    if (!blockPageUrl) return;

    const context = 'network';

    const extraHeaders: { Authorization?: string } = {};

    if (accessToken && authProviderId) {
      const token = accessToken?.replace('Bearer ', '');
      extraHeaders.Authorization = `Bearer ${token}`;
    }

    Downloader.downloadUrl({
      url: blockPageUrl,
      options: {
        appKey: appKey,
        authProviderId: authProviderId,
        extraHeaders,
        impression: impression,
        method: 'GET',
        targetProviders: targetProviders,
      },
      cachedResponse,
    })
      .then((response) => {
        _handleFeedResponse(response, context);
        setIsBlockPageRefreshing(false);
        setIsBlockPageReplacing(false);
      })
      .catch((err) => {
        const status = (err?.response?.status as number) || 504;
        _handleFeedLoadError(status, err, context);
        setIsBlockPageRefreshing(false);
        setIsBlockPageReplacing(false);
      });
  };

  const _handleFeedLoadError = (
    code: number,
    err?: {
      response: {
        headers?: Record<string, unknown>;
        request?: { responseURL: string };
      };
    },
    context?: string
  ) => {
    debug(
      'Error loading feed data',
      '| Context:',
      context,
      '| Code:',
      code,
      '| Error:',
      err
    );

    if (
      NativeModules.ReactPlatformBridge != null &&
      (code === 401 || code === 403)
    ) {
      debug('Prompt user to login');

      dispatchEvent({
        type: 'AUTHENTICATE_USER',
        openInNewTab: true,
        targetAuthProviderId: authProviderId || SUBSPLASH_AUTH_PROVIDER_ID,
      });

      /**
       * 'setErrorCode' causes an error screen to render,
       * so here we are opting out of that error code/screen for the case where
       * we are prompting a user to login
       */
    } else {
      setErrorCode(code);
    }
  };

  const _handleErrorCode = (code: number): void => {
    if (code) {
      let fallback: BlockPageContentData | undefined;

      if (fallbackResource) {
        fallback = getFallbackFile(
          fallbackResource as FallbackFileName
        ) as BlockPageContentData;
      }

      if (fallback?.blocks && !contentData) {
        setContentData(fallback as BlockPageContentData);
      } else {
        const data = generateErrorPage(code, (): void => {
          setErrorCode(0); // dismiss error
          _downloadFeed(undefined, false); // retry feed request
        });

        setErrorData(data);
      }
    } else {
      setErrorData(null);
    }
  };

  const _handleFeedResponse = async (
    response?: { body?: BlockPageContentData; status?: number },
    context?: string
  ) => {
    if (!response && context === 'cache') {
      debug('cache miss');

      return;
    }

    debug(
      `Handle response. Context: ${context} Status: ${response?.status}. ` +
        `URL: ${blockPageUrl}`
    );

    if (response?.status === NOT_MODIFIED) return;

    let status = response?.status ?? 550;
    const body = response?.body ?? null;

    if (status < 400 && !body?.blocks) {
      status = 550;
      debug('Response data is malformed.  No blocks found.');
    }

    if (status < 400) {
      setContentData(body);
    } else {
      _handleFeedLoadError(status, undefined, context);
    }
  };

  useEffect((): void => {
    const dataObject = typeof data === 'string' ? JSON.parse(data) : data;

    if (dataObject?.blocks) {
      setContentData(dataObject as BlockPageContentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch feed on mount and whenever auth changes
  useEffect(() => {
    _loadCachedFeed().then((cachedResponse) => {
      const initialRender = !contentData && !errorData;

      if (initialRender) {
        _handleFeedResponse(cachedResponse as IDownloaderResponse, 'cache');
      }

      const trackImpression = initialRender;
      _downloadFeed(cachedResponse as IDownloaderResponse, trackImpression);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (Platform.OS === 'android') {
      getNativeTopBarHeight()
        .then((height) => {
          setBlockPageDeltaInsetTop(height);
          setDeltaInsetRetrieved(true);
        })
        .catch(() => {
          setDeltaInsetRetrieved(true);
        });
    } else {
      setDeltaInsetRetrieved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * TODO: move this native auth error event listener to AuthContext.
   * Keeping here for now to reduce regression risk in 5.17
   */
  useEffect(() => {
    const listeners = _registerEmitters();

    return () => {
      listeners?.forEach((listener) => {
        listener?.remove();
      });
    };
  }, []);

  const _registerEmitters = (): EmitterSubscription[] | void => {
    if (!NativeModules.ReactPlatformBridge) return;

    const ReactPlatformBridgeEventEmitter = new NativeEventEmitter(
      NativeModules.ReactPlatformBridge
    );

    const authorizationFailedSubscription =
      ReactPlatformBridgeEventEmitter.addListener(
        'AuthorizationError',
        (_error) => setErrorCode(401)
      );

    return [authorizationFailedSubscription];
  };

  useEffect(() => {
    _handleErrorCode(errorCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode]);

  useEffect(() => {
    const dataObject = typeof data === 'string' ? JSON.parse(data) : data;

    if (dataObject?.blocks) {
      setContentData(dataObject as BlockPageContentData);
    }
  }, [data]);

  useEffect(() => {
    if (currentTopBarStyle) {
      setTopBarStyle(
        currentTopBarStyle,
        false /* updateInsets */,
        true /* animated */
      );
    }
  }, [currentTopBarStyle]);

  useEffect(() => {
    const { ReactPlatformBridge } = NativeModules;

    if (!ReactPlatformBridge) return;

    const ReactPlatformBridgeEventEmitter = new NativeEventEmitter(
      ReactPlatformBridge
    );

    const listener = ReactPlatformBridgeEventEmitter.addListener(
      'NotifyAppUserSettingsUpdated',
      () => {
        refreshBlockPage();
      }
    );

    return () => {
      listener?.remove();
    };
  }, []);

  useEffect(() => {
    if (initialTopBarStyle) {
      setTopBarStyle(
        initialTopBarStyle,
        true /* updateInsets */,
        true /* animated */
      );
    }
  }, [initialTopBarStyle]);

  useEffect(() => {
    if (deltaInsetRetrieved && (contentData || errorData)) {
      const title = contentData?.title ?? errorData?.title;

      if (title) setTopBarTitle(title);

      const align = contentData ? contentData.align : errorData?.align;

      setAlign(align);

      const contentBlocks = contentData?.blocks ?? errorData?.blocks;

      if (!contentBlocks) return;

      /**
       * Media banner is currently the only block that supports transparent top
       * bar style. If it's not the first block, the transparent top bar style
       * must be suppressed.
       */
      if (
        initialTopBarStyle &&
        initialTopBarStyle !== NativeTopBarStyle.Hidden
      ) {
        setInitialTopBarStyle(
          contentBlocks.length &&
            contentBlocks[0].type === ComponentType.MediaBanner
            ? topBarStyle
            : NativeTopBarStyle.Default
        );
      }

      setBlocksData(contentBlocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deltaInsetRetrieved, contentData, errorData]);

  useEffect(() => {
    if (isBlockPageRefreshing) {
      _loadCachedFeed().then((cachedResponse) => {
        _downloadFeed(cachedResponse as IDownloaderResponse, false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockPageRefreshing]);

  useEffect(() => {
    if (isBlockPageReplacing) {
      _loadCachedFeed().then((cachedResponse) => {
        _handleFeedResponse(cachedResponse as IDownloaderResponse, 'cache');
        _downloadFeed(cachedResponse as IDownloaderResponse, false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockPageReplacing]);

  const refreshBlockPage = () => {
    setIsBlockPageRefreshing(true);
  };

  const replaceBlockPage = async (url: string): Promise<void> => {
    const authorized = await isUrlAuthorizedForProviders({
      providers: targetProviders || [],
      url: url,
    });

    if (authorized) {
      setBlockPageUrl(url);
      setIsBlockPageReplacing(true);
    }
  };

  const dispatchAction = async (
    action: BridgeAction | null | undefined,
    callback?: (event: any) => void
  ): Promise<void> => {
    if (isBlockPageContentHandler(action)) {
      // DISP-4731: enterprise use-case to have buttons that can replace a block page
      const url = getActionUrl(action);
      replaceBlockPage(url);
    } else {
      dispatch(action, callback);
    }
  };

  const contextData = {
    addressRequired,
    align,
    animationEnabled,
    appKey,
    authProviderId,
    blockPageDeltaInsetTop,
    blocksData,
    clientBrandColor,
    contentData,
    guestToken,
    includeCloseButton,
    includeLogoutButton,
    isBlockPageEdited,
    isBlockPageLoading,
    isBlockPageRefreshing,
    isBlockPageReplacing,
    largeSaveButtonEnabled,
    blockPageUrl,
    dispatchAction,
    onDismiss,
    refreshBlockPage,
    setCurrentTopBarStyle,
    setIsBlockPageEdited,
    setIsBlockPageLoading,
    setIsBlockPageRefreshing,
    submitActionRef,
  };

  return (
    <BlockPageContext.Provider value={contextData}>
      {children}
    </BlockPageContext.Provider>
  );
};

export const useBlockPageContext = (): ContextData => {
  return useContext<ContextData>(BlockPageContext);
};
