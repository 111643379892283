import { element, func, oneOfType } from 'prop-types';
import React from 'react';

import { isHoverEnabled } from './HoverState';

interface HoverableProps {
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  onHoverMove?: (e?: MouseEvent) => void;
  children: React.ReactNode | ((isHovering: boolean) => React.ReactNode);
}

export default function Hoverable({
  onHoverIn,
  onHoverOut,
  onHoverMove,
  children,
}: HoverableProps): JSX.Element {
  const [isHovered, setHovered] = React.useState(false);
  const [showHover, setShowHover] = React.useState(true);

  function handleMouseEnter() {
    if (isHoverEnabled() && !isHovered) {
      if (onHoverIn) onHoverIn();

      setHovered(true);
    }
  }

  function handleMouseLeave() {
    if (isHovered) {
      if (onHoverOut) onHoverOut();

      setHovered(false);
    }
  }

  function handleMouseMove(e: MouseEvent) {
    if (isHoverEnabled() && isHovered) {
      if (onHoverMove) onHoverMove(e);
    }
  }

  function handleGrant() {
    setShowHover(false);
  }

  function handleRelease() {
    // no-op
  }

  const child =
    typeof children === 'function'
      ? children(showHover && isHovered)
      : children;

  // @ts-ignore
  return React.cloneElement(React.Children.only(child), {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onMouseMove: handleMouseMove,
    // prevent hover showing while responder
    onResponderGrant: () => setShowHover(false),
    onResponderRelease: () => setShowHover(true),
    // if child is Touchable
    onPressIn: handleGrant,
    onPressOut: handleRelease,
  });
}

Hoverable.displayName = 'Hoverable';

Hoverable.propTypes = {
  children: oneOfType([func, element]),
  onHoverIn: func,
  onHoverMove: func,
  onHoverOut: func,
};
