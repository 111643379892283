import {
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import React, { Component, RefObject } from 'react';

import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import { IInvite } from '../../../shared/redux/types';
import KitChip from './KitChip';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';

//******************************************************************************
// Types
//******************************************************************************
export interface IProps {
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  autoCorrect?: boolean;
  chips: IInvite[];
  editable?: boolean;
  inputRef?: RefObject<TextInput>;
  keyboardType?:
    | 'default'
    | 'number-pad'
    | 'decimal-pad'
    | 'numeric'
    | 'email-address'
    | 'phone-pad';
  onBackspace: () => void;
  onChangeText: (inputText: string) => void;
  onDeleteChipPress: (invite: IInvite) => void;
  placeholder?: string;
  value: string;
}

//******************************************************************************
// Component
//******************************************************************************

class KitChipInput extends Component<IProps> {
  //****************************************************************************
  // Class Properties
  //****************************************************************************

  _scrollView = React.createRef<ScrollView>();

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  render(): JSX.Element {
    const {
      autoCapitalize,
      autoCorrect,
      chips,
      editable,
      inputRef,
      keyboardType,
      onBackspace,
      onChangeText,
      onDeleteChipPress,
      placeholder,
      value,
    } = this.props;
    const chipComponents = chips.map(
      (chip): JSX.Element => (
        <KitChip
          onDeletePress={onDeleteChipPress}
          title={chip.title}
          key={Math.random()}
          invite={chip}
        />
      )
    );

    return (
      <ScrollView
        style={styles.scrollView}
        ref={this._scrollView}
        keyboardShouldPersistTaps='handled'
        onContentSizeChange={() => {
          this._scrollView.current?.scrollToEnd({ animated: true });
        }}
      >
        <View style={styles.container}>
          {chipComponents}
          <TextInput
            autoCompleteType='off'
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            editable={editable}
            keyboardType={keyboardType}
            onChangeText={onChangeText}
            placeholder={chips.length ? '' : placeholder}
            placeholderTextColor='#999B9E'
            ref={inputRef}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === 'Backspace') {
                onBackspace();
              }
            }}
            style={[
              styles.input,
              // @ts-ignore (`outlineWidth` is not recognized as a TextStyle)
              { ...(Platform.OS === 'web' && { outlineWidth: 0 }) },
            ]}
            value={value}
          />
        </View>
      </ScrollView>
    );
  }
}
export default KitChipInput;

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 5,
  },
  input: {
    ...(Platform.OS === 'web' && { fontFamily: 'inherit' }),
    borderWidth: 0,
    color: colorForScheme({ default: Colors.N900 }),
    flexGrow: 1,
    fontSize: 17,
    height: 38,
    marginLeft: Spacing.s,
    marginRight: Spacing.xs,
    minWidth: 40,
    padding: 0,
  },
  scrollView: {
    backgroundColor: colorForScheme({ default: Colors.N100 }),
    borderRadius: BorderRadius.m,
    maxHeight: 330,
    paddingHorizontal: 5,
    paddingTop: 5,
  },
});
