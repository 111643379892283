import { dispatch } from '@omni/kit/ActionHandler';
import KitIcon from '@omni/kit/components/KitIcon';
import KitLoader from '@omni/kit/components/KitLoader';
import KitText from '@omni/kit/components/KitText';
import KitTouchable from '@omni/kit/components/KitTouchable';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { getMediaDownloadState } from '@omni/kit/utilities/NativeHelpers';
import React, { useContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useBlockPageContext } from '../BlockPageContext';
import { IButtonItem } from '../blocks/CircleButtonRowBlock';

interface ICircleButton {
  item: IButtonItem;
}

type DownloadState = 'none' | 'in_progress' | 'completed';

export default function CircleButton(props: ICircleButton): JSX.Element {
  const { dispatchAction } = useBlockPageContext();
  const { colorForScheme } = useContext(ThemeContext);
  const { action, icon, title, type } = props.item;

  const [downloadState, setDownloadState] = useState<DownloadState>('none');

  // Get current download state
  if (type === 'mediaDownload') {
    getMediaDownloadState(props.item.downloadKey as string)
      .then((state): void => {
        setDownloadState(state as DownloadState);
      })
      .catch(() => undefined);
  }

  const _onPress = (): void => {
    if (type !== 'mediaDownload') {
      dispatchAction(action);

      return;
    }

    if (['completed', 'in_progress'].includes(downloadState)) {
      dispatch({ handler: 'mediaDownloads' });

      return;
    }

    const playlist = props.item.playlistUrl;
    const downloadAction = {
      command: 'download',
      handler: 'playlist',
      url: playlist,
    };
    const _downloadCallback = ({
      key,
      status,
    }: {
      key: string;
      status: DownloadState;
    }): void => {
      if (key === props.item.downloadKey) {
        setDownloadState(status);
      }
    };

    dispatch(downloadAction, _downloadCallback);
  };

  const _getDownloadButtonTitle = ():
    | 'Download'
    | 'Downloaded'
    | 'Downloading' => {
    switch (downloadState) {
      case 'in_progress':
        return 'Downloading';
      case 'completed':
        return 'Downloaded';
      case 'none':
      default:
        return 'Download';
    }
  };

  const _getDownloadButtonIcon = (iconColor: string): JSX.Element => {
    switch (downloadState) {
      case 'in_progress':
        return <KitLoader small color={iconColor} />;
      case 'completed':
        return <KitIcon name='check' size={20} color={iconColor} />;
      case 'none':
      default:
        return <KitIcon name='download' size={20} color={iconColor} />;
    }
  };

  const textColor =
    colorForScheme?.({ dark: Colors.N100, light: Colors.N900 }) || Colors.N900;
  const iconColor =
    colorForScheme?.({ dark: Colors.N100, light: Colors.N900 }) || Colors.N900;
  const iconBackground =
    colorForScheme?.({ dark: Colors.N900, light: Colors.N100 }) || Colors.N100;
  const iconUnderlay = colorForScheme?.({ dark: '#1D1D1D', light: '#CACACC' });

  const buttonIcon =
    type === 'mediaDownload' ? (
      _getDownloadButtonIcon(iconColor)
    ) : (
      // @ts-ignore
      <KitIcon name={icon} size={20} color={iconColor} />
    );
  const buttonTitle =
    type === 'mediaDownload' ? _getDownloadButtonTitle() : title;

  return (
    <View style={{ alignContent: 'center', alignItems: 'center', width: 100 }}>
      <KitTouchable
        testID='circleButtonTouchable'
        disabled={type === 'mediaDownload' && downloadState === 'in_progress'}
        underlayColor={iconUnderlay}
        onPress={_onPress}
        style={[styles.button, { backgroundColor: iconBackground }]}
      >
        {buttonIcon}
      </KitTouchable>
      <KitText
        testID='circleButtonTitle'
        style={{ color: textColor, fontSize: 14, marginTop: 8 }}
      >
        {buttonTitle}
      </KitText>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: BorderRadius.l * 2,
    height: 44,
    justifyContent: 'center',
    width: 44,
  },
});
