import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Keyboard, Platform, StyleSheet, View, ViewStyle } from 'react-native';

import { useSizeClass } from '../../contexts/SizeClassContext';
import Colors from '../../theming/Colors';

export interface KeyboardAccessoryView {
  alwaysVisible?: boolean;
  inSafeAreaView?: boolean;
  hideBorder?: boolean;
  style?: ViewStyle;
  children: ReactNode;
}

export default function KitKeyboardAccessoryView({
  alwaysVisible = false,
  inSafeAreaView = false,
  hideBorder = false,
  style,
  children,
}: KeyboardAccessoryView): JSX.Element {
  const { windowHeight, windowWidth } = useSizeClass();
  const isSafeAreaSupported = useMemo(
    () => Platform.OS === 'ios' && (windowHeight > 800 || windowWidth > 800),
    [windowHeight, windowWidth]
  );
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [accessoryHeight, setAccessoryHeight] = useState(50);
  const [visibleAccessoryHeight, setVisibleAccessoryHeight] = useState(50);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const onLayout = (layoutEvent: any) => {
    setVisibleAccessoryHeight(layoutEvent.nativeEvent.layout.height);
    setAccessoryHeight(
      alwaysVisible || isKeyboardVisible
        ? layoutEvent.nativeEvent.layout.height
        : 0
    );
  };

  const handleKeyboardShow = (keyboardEvent: any) => {
    if (!keyboardEvent.endCoordinates) {
      return;
    }

    const keyboardHeight =
      keyboardEvent.endCoordinates.height > 320
        ? keyboardEvent.endCoordinates.height - 20
        : keyboardEvent.endCoordinates.height;

    setTimeout(() => {
      setIsKeyboardVisible(true);
      setKeyboardHeight(keyboardHeight);
      setAccessoryHeight(visibleAccessoryHeight);
    }, 300);
  };

  const handleKeyboardHide = () => {
    setIsKeyboardVisible(false);
    setKeyboardHeight(0);
    setAccessoryHeight(alwaysVisible ? visibleAccessoryHeight : 0);
  };

  useEffect(() => {
    const keyboardShowEvent =
      Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow';
    const keyboardHideEvent =
      Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide';

    const keyboardShowEventListener = Keyboard.addListener(
      keyboardShowEvent,
      handleKeyboardShow
    );
    const keyboardHideEventListener = Keyboard.addListener(
      keyboardHideEvent,
      handleKeyboardHide
    );

    return () => {
      keyboardShowEventListener.remove();
      keyboardHideEventListener.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applySafeArea = isSafeAreaSupported && inSafeAreaView;

  return (
    <View
      style={{
        minHeight: isKeyboardVisible || alwaysVisible ? accessoryHeight : 0,
      }}
    >
      <View
        style={[
          styles.accessory,
          !hideBorder && styles.accessoryBorder,
          style,
          {
            opacity: isKeyboardVisible || alwaysVisible ? 1 : 0,
            bottom: keyboardHeight - (applySafeArea ? 20 : 0),
            minHeight:
              accessoryHeight +
              (applySafeArea ? (!isKeyboardVisible ? 20 : -10) : 0),
          },
        ]}
      >
        <View onLayout={onLayout}>{children}</View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  accessory: {
    position: 'absolute',
    right: 0,
    left: 0,
    backgroundColor: '#EFF0F1',
  },
  accessoryBorder: {
    borderTopWidth: 1,
    borderTopColor: Colors.N100,
  },
});
