import AsyncStorage from '@react-native-async-storage/async-storage';
import { Cache } from 'react-native-cache';

/**
 * Maps media item progress metadata to saveStateKey
 */
export default new Cache({
  namespace: 'TrackMediaProgress',
  // @ts-ignore
  policy: {
    maxEntries: 50,
  },
  backend: AsyncStorage,
});
