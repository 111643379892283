import Colors from '@omni/kit/theming/Colors';
import { colorForScheme } from '@omni/kit/theming/Theming';
import React, { ReactNode } from 'react';
import { Platform, TouchableHighlight, View } from 'react-native';

import { TouchableBaseProps } from './TouchableBase';

const TouchableBase: (props: TouchableBaseProps) => ReactNode = Platform.select(
  {
    default: ({
      children,
      onPress,
      underlayColor,
      style,
    }: TouchableBaseProps) => (
      <TouchableHighlight
        onPress={onPress}
        underlayColor={
          underlayColor
            ? underlayColor
            : colorForScheme({ default: Colors.N100 })
        }
        delayPressIn={0}
      >
        <View style={style}>{children}</View>
      </TouchableHighlight>
    ),
  }
);

export default TouchableBase;
