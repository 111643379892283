import { App, Branding, ILogoImage } from '@omni/kit/services/Types';
import {
  setAppBranding,
  setAppKey,
  setAppTitle,
  setOrgKey as setReduxOrgKey,
} from '../shared/redux/actions/SystemActions';
import { useEffect, useState } from 'react';

import AccountsService from '@omni/kit/services/AccountsService';
import { useDispatch } from 'react-redux';

/**
 * This hook will fetch initial app data. It should be called only once at the
 * beginning.
 */
export default function useAppInfo(appKey?: string): {
  app?: App;
  branding?: Branding;
  isReady: boolean;
  logo?: ILogoImage;
  iconLogo?: ILogoImage;
  orgKey?: string;
  title?: string;
} {
  const [isReady, setIsReady] = useState(false);
  const [app, setApp] = useState<App | undefined>();
  const [branding, setBranding] = useState<Branding | undefined>();
  const [silhouetteLogo, setSilhouetteLogo] = useState<
    ILogoImage | undefined
  >();
  const [iconLogo, setIconLogo] = useState<ILogoImage | undefined>();

  const [orgKey, setOrgKey] = useState<string | undefined>();
  const [title, setTitle] = useState<string | undefined>();

  const dispatch = useDispatch();

  useEffect(() => {
    const setup = async (appKey: string) => {
      try {
        const response = await AccountsService.getAppWithBranding(appKey);
        const brand = response?._embedded?.branding;
        const silhouetteLogo = brand?._embedded?.['silhouette-logo-image'];
        const iconLogo = brand?._embedded?.['icon-logo-image'];

        if (response) {
          dispatch(setAppTitle(response.title));
          dispatch(setAppKey(response.id));
          dispatch(setAppBranding({ branding: brand, logo: silhouetteLogo }));
          dispatch(setReduxOrgKey(response.org_key || ''));
          setApp(response);
          setBranding(brand);
          setSilhouetteLogo(silhouetteLogo);
          setIconLogo(iconLogo);
          setOrgKey(response.org_key);
          setTitle(response.title);
        }

        setIsReady(true);
      } catch (e) {
        console.warn(e);
        setIsReady(true);
      }
    };

    if (appKey) {
      setup(appKey);
    }
  }, [appKey, dispatch]);

  return {
    app,
    branding,
    isReady,
    logo: silhouetteLogo,
    iconLogo,
    orgKey,
    title,
  };
}
