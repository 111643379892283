import {
  KitFloatingButton,
  KitIcon,
  KitInput,
  KitText,
  KitTouchable,
} from '@omni/kit/components';
import LeftNavButton, { LeftNavType } from '../components/LeftNavButton';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Colors from '@omni/kit/theming/Colors';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import Spacing from '@omni/kit/theming/Spacing';
import { channelSelector } from '../../shared/redux/selectors';
import { sendPollMessage } from '../../shared/redux/actions/ChatActions';
import { useNavigation } from '@react-navigation/native';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';

const debug = require('debug')('tca:chat:screen:GifModal');

//******************************************************************************
// Component
//******************************************************************************

export default function PollScreen({ togglePopup = undefined }) {
  const [question, setQuestion] = useState<string>('');
  const [options, setOptions] = useState<string[]>(['']);
  const [isValid, setIsValid] = useState(false);
  const navigation = useNavigation();

  const channel = useSelector(channelSelector);
  const { sizeClass } = useSizeClass();
  const dispatch = useDispatch();

  const deleteIcon = (index: number) => {
    return (
      <KitTouchable onPress={() => _onDeleteOptionPress(index)}>
        <View
          style={{
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            width: 20,
            height: 20,
            borderRadius: 10,
            backgroundColor: Colors.N400,
          }}
        >
          <KitIcon name='remove' size={12} color={Colors.N0} />
        </View>
      </KitTouchable>
    );
  };

  const _onDeleteOptionPress = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const _renderOptions = () => {
    return options.map((option, index) => (
      <KitInput
        leftIcon={options.length > 1 ? deleteIcon(index) : undefined}
        key={index}
        marginValue='8px 0 0 0'
        placeholder='Option'
        value={option}
        onChangeText={(text) => _onOptionsTextChange(text, index)}
      />
    ));
  };

  const _onQuestionTextChange = (text: string) => {
    setQuestion(text);
  };

  const _onOptionsTextChange = (text: string, index: number) => {
    const newOptions = [...options];
    newOptions[index] = text;
    setOptions(newOptions);
  };

  const _onAddOption = () => {
    setOptions([...options, '']);
  };

  const _onSendPress = useCallback(() => {
    dispatch(sendPollMessage(channel as GroupChannel, question, options));
    if (togglePopup) {
      // @ts-ignore
      togglePopup();
    } else {
      try {
        navigation.goBack();
      } catch {}
    }
  }, [channel, dispatch, navigation, options, question]);

  useEffect(() => {
    navigation.setOptions({
      title: 'New Poll',
      headerLeft: () => (
        <LeftNavButton
          type={LeftNavType.Dismiss}
          title='New Poll'
          onPress={navigation.goBack}
        />
      ),
      headerRight:
        Platform.OS !== 'web'
          ? () => (
              <KitTouchable
                disabled={!isValid}
                onPress={_onSendPress}
                style={{
                  paddingLeft: 12,
                  paddingRight: Platform.OS === 'ios' ? 12 : 18,
                  paddingVertical: 6,
                  marginRight: -6,
                  borderRadius: 40,
                }}
              >
                <KitText color={Colors.N400} brandColor={isValid} fontSize={17}>
                  Send
                </KitText>
              </KitTouchable>
            )
          : null,
    });
  }, [_onSendPress, isValid, navigation]);

  useEffect(() => {
    if (question && !options.includes('')) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [question, options]);

  return (
    <>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        style={styles.container}
      >
        <KitInput
          label='Question'
          multiline
          placeholder='Ask a question'
          value={question}
          onChangeText={_onQuestionTextChange}
        />
        <KitText
          color={Colors.N600}
          semiBold
          fontSize={14}
          style={{ lineHeight: 18, marginTop: Spacing.l }}
        >
          Options
        </KitText>
        {_renderOptions()}
        <KitTouchable style={styles.addButton} onPress={_onAddOption}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.addIcon}>
              <KitIcon name={'add-s'} size={14} color={Colors.N0} />
            </View>
            <KitText black>Add another option</KitText>
          </View>
        </KitTouchable>
      </ScrollView>
      {Platform.OS === 'web' && question.length > 0 && (
        <View
          // @ts-ignore
          style={{
            position: 'fixed',
            bottom: Spacing.l,
            right: Spacing.l,
          }}
        >
          <KitFloatingButton icon='send' onPress={_onSendPress} />
        </View>
      )}
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Spacing.l,
    backgroundColor: Colors.N0,
    paddingTop: Platform.OS === 'web' ? Spacing.l : 0,
  },
  addButton: {
    marginVertical: Spacing.m,
    padding: Spacing.s,
    borderRadius: 20,
    marginBottom: Spacing.xxl,
  },
  addIcon: {
    marginEnd: Spacing.m,
    backgroundColor: Colors.brand,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
  },
});
