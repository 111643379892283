import { IMediaPlaybackStateInfo } from '../KitPlayer/playbackTypes';
import FetchMediaItemByShortCode from './FetchMediaItemByShortCode';
import FetchMediaPlayByUserAndMediaItem from './FetchMediaPlayByUserAndMediaItem';
import FetchUserProfile from './FetchUserProfile';
import SaveProgressLocally from './SaveProgressLocally';
import TrackMediaProgressState from './TrackMediaProgressState';
import UpdateMediaPlayCount from './UpdateMediaPlayCount';

const debug = require('debug')('tca:packages:TrackMediaProgress:Setup');

/**
 * Setup media progress tracking by fetching all necessary resources
 * @param mediaPlaybackStateInfo {IMediaPlaybackStateInfo}
 */
export default async (
  mediaPlaybackStateInfo: IMediaPlaybackStateInfo
): Promise<void> => {
  const { mediaItemShortCode = '', appKey = '' } = mediaPlaybackStateInfo;

  if (!appKey) return debug('setup - app key is missing. Exit setup.');

  /**
   * Nullify currentMediaItem if
   * - currentMediaItem exists
   * - mediaItemShortCode exists
   * - and media item shortcode has been updated (starts playing a new media item)
   */
  if (
    TrackMediaProgressState.currentMediaItem &&
    mediaItemShortCode &&
    TrackMediaProgressState.currentMediaItem.short_code !== mediaItemShortCode
  ) {
    debug('setup - reset currentMediaitem', {
      currentShortCode: TrackMediaProgressState.currentMediaItem.short_code,
      mediaItemShortCode,
    });
    TrackMediaProgressState.currentMediaItem = null;
  }

  /**
   * Fetch media item if
   * - current media item does not exist and
   * - media item short code exists
   */
  if (!TrackMediaProgressState.currentMediaItem && mediaItemShortCode) {
    debug('setup - fetch media item', { mediaItemShortCode, appKey });
    /** Refetching media item would also require mediaPlay to be refetched */
    TrackMediaProgressState.mediaPlay = null;
    /** fetch media item */
    TrackMediaProgressState.currentMediaItem = await FetchMediaItemByShortCode({
      appKey,
      mediaItemShortCode,
    });

    TrackMediaProgressState.retryAttempts = 0;
  }

  if (!TrackMediaProgressState.userProfile) {
    debug('setup - fetch userProfile', { appKey });
    /** Refetching user profile would also require mediaPlay to be refetched */
    TrackMediaProgressState.mediaPlay = null;

    /** Fetch user profile */
    TrackMediaProgressState.userProfile = await FetchUserProfile(appKey);

    TrackMediaProgressState.retryAttempts = 0;
  }

  /**
   * Fetch media play resource if
   * - current media item exists
   * - user profile exists
   */
  if (
    !TrackMediaProgressState.mediaPlay &&
    TrackMediaProgressState.currentMediaItem &&
    TrackMediaProgressState.userProfile
  ) {
    debug('setup - fetch media play', {
      appKey,
      userId: TrackMediaProgressState.userProfile.userID,
      mediaItemId: TrackMediaProgressState.currentMediaItem.id,
    });
    /** Fetch media play by user and media item */
    TrackMediaProgressState.mediaPlay = await FetchMediaPlayByUserAndMediaItem({
      appKey,
      userId: TrackMediaProgressState.userProfile.userID,
      mediaItemId: TrackMediaProgressState.currentMediaItem.id,
    });

    TrackMediaProgressState.retryAttempts = 0;

    await UpdateMediaPlayCount(mediaPlaybackStateInfo);

    SaveProgressLocally({
      mediaPlaybackStateInfo,
      mediaItemId: TrackMediaProgressState.currentMediaItem?.id,
      mediaPlayId: TrackMediaProgressState.mediaPlay?.id,
      userId: TrackMediaProgressState.userProfile.userID,
    });
  }
};
