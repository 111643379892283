import { IconName } from '@omni/kit/components/KitIcon';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Animated,
  Dimensions,
  Modal,
  Platform,
  Pressable,
  View,
} from 'react-native';

import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { useThemeContext } from '../../theming/ThemeContext';
import Show from '../Show';
import { KitIcon, KitText, KitTouchable } from '../index';
import Delayed from './Delayed';
import {
  EHorizontalAlignAchor,
  EVerticalAlignAchor,
  IAnchorPosition,
  ICalloutDimension,
  ICalloutPosition,
  ICalloutProps,
} from './types';

// ----------------------------------------------------------------------
// CONSTANTS

const ANCHOR_SIZE = 18;
const ANCHOR_BORDER_RADIUS = 3;

const CALLOUT_VERTICAL_OFFSET = ANCHOR_SIZE / 2 + ANCHOR_BORDER_RADIUS;

const DEFAULT_CALLOUT_POSITION = { left: 0, top: 0 };
const DEFAULT_CALLOUT_DIMENSION = { height: 0, width: 0 };

const HORIZONTAL_MARGIN = Spacing.l;

/**
 * Callout component
 *
 * 1. will position to the provided anchor points
 * 2. can specify where anchor is located relative to the callout
 */
export default React.memo(
  ({
    title,
    body,
    icon,
    verticalAlignAnchor = EVerticalAlignAchor.BOT,
    horizontalAlignAnchor = EHorizontalAlignAchor.RIGHT,
    anchorX = 0,
    anchorY = 0,
    showCallout,
    dismiss,
    backgroundColor = Colors.N1000, // black background by default
    textColor = Colors.N0, // white text by default
    underlayColor = Colors.N1000,
    calloutPadding = 18,
    customStyles = {},
    animate,
    delay,
    fullScreenModal = false,
    themed = false,
  }: ICalloutProps): JSX.Element => {
    // ----------------------------------------------------------------------
    // STATES

    const [calloutDimensions, setCalloutDimensions] =
      useState<ICalloutDimension>({ ...DEFAULT_CALLOUT_DIMENSION });
    const [calloutPosition, setCalloutPosition] = useState<ICalloutPosition>({
      ...DEFAULT_CALLOUT_POSITION,
    });
    const [anchorPosition, setAnchorPosition] = useState<IAnchorPosition>({});
    const fadeAnim = React.useRef(new Animated.Value(0)).current;
    const { colorForScheme } = useThemeContext();

    const themedBackgroundColor = colorForScheme?.({
      light: Colors.N1000,
      dark: Colors.N0,
    });

    const themedTextColor = colorForScheme?.({
      light: Colors.N0,
      dark: Colors.N1000,
    });

    // ----------------------------------------------------------------------
    // FUNCTIONS

    const onLayout = (event: any) => {
      const dimensions = event.nativeEvent.layout;

      setCalloutDimensions({
        width: Math.round(dimensions.width),
        height: Math.round(dimensions.height),
      });
    };

    const getCalloutVerticalOffset = useCallback(() => {
      return -calloutPadding - ANCHOR_SIZE / 2 + ANCHOR_BORDER_RADIUS;
    }, [calloutPadding]);

    // ----------------------------------------------------------------------
    // EFFECTS

    useEffect(() => {
      const updateCalloutPosition = () => {
        const { width = 0, height = 0 } = calloutDimensions;
        const updatedCalloutPosition: ICalloutPosition = {
          ...DEFAULT_CALLOUT_POSITION,
        };
        const updatedAnchorPosition: IAnchorPosition = {};

        /** Vertical alignment */
        if (verticalAlignAnchor === EVerticalAlignAchor.BOT) {
          updatedCalloutPosition.top =
            anchorY - height - CALLOUT_VERTICAL_OFFSET;
          updatedAnchorPosition.bottom = getCalloutVerticalOffset();
        } else {
          updatedCalloutPosition.top = anchorY + CALLOUT_VERTICAL_OFFSET;
          updatedAnchorPosition.top = getCalloutVerticalOffset();
        }

        /** Horizontal alignment */
        if (horizontalAlignAnchor === EHorizontalAlignAchor.RIGHT) {
          updatedCalloutPosition.left =
            Dimensions.get('window').width - width - HORIZONTAL_MARGIN;
          updatedAnchorPosition.right =
            Dimensions.get('window').width -
            anchorX -
            HORIZONTAL_MARGIN -
            calloutPadding -
            ANCHOR_SIZE / 2;
        } else {
          updatedCalloutPosition.left = Math.max(
            HORIZONTAL_MARGIN,
            anchorX - calloutPadding
          );
          const absolutePosition = anchorX - ANCHOR_SIZE / 2;
          updatedAnchorPosition.left =
            absolutePosition - updatedCalloutPosition.left - calloutPadding;
        }

        /** Set position */
        setAnchorPosition(updatedAnchorPosition);
        setCalloutPosition(updatedCalloutPosition);
        if (animate) {
          Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }
      };

      if (
        calloutDimensions.height &&
        calloutDimensions.width &&
        anchorX &&
        anchorY
      ) {
        updateCalloutPosition();
      }
    }, [
      calloutDimensions.height,
      calloutDimensions.width,
      calloutPadding,
      anchorX,
      anchorY,
      calloutDimensions,
      verticalAlignAnchor,
      horizontalAlignAnchor,
      getCalloutVerticalOffset,
      fadeAnim,
      animate,
    ]);

    // ----------------------------------------------------------------------
    // RENDER
    const style = {
      backgroundColor: themed ? themedBackgroundColor : backgroundColor,
      borderRadius: BorderRadius.m,
      elevation: 5,
      width: '100%',
      justifyContent: 'center',
      shadowOffset: { width: 0, height: 10 },
      shadowRadius: 25,
      padding: calloutPadding,
      overflow: 'visible',
      opacity: 1,
    };

    const content = (
      <Animated.View
        style={
          Platform.OS === 'web'
            ? {
                width: '100%',
                position: 'absolute',
                opacity: animate ? fadeAnim : 1,
              }
            : {
                position: 'absolute',
                ...calloutPosition,
                opacity: animate ? fadeAnim : 1,
              }
        }
      >
        <KitTouchable
          onLayout={onLayout}
          touchableStyle={[style, customStyles]}
          underlayColor={underlayColor}
          onPress={() => dismiss?.()}
          style={
            Platform.OS === 'ios'
              ? [style, customStyles]
              : Platform.OS === 'web'
              ? {
                  backgroundColor: themed
                    ? themedBackgroundColor
                    : backgroundColor,
                  padding: calloutPadding,
                  zIndex: 2,
                  top: -100,
                  width: '100%',
                  borderRadius: BorderRadius.m,
                }
              : {}
          }
        >
          <View style={{ flexDirection: 'row' }}>
            {icon && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  marginRight: calloutPadding,
                }}
              >
                <KitIcon
                  name={icon as IconName}
                  size={20}
                  color={themedTextColor}
                />
              </View>
            )}
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Show show={Boolean(title)}>
                <KitText
                  fontSize={16}
                  bold
                  color={themed ? themedTextColor : textColor}
                >
                  {title}
                </KitText>
              </Show>
              <Show show={Boolean(body)}>
                <KitText
                  fontSize={16}
                  color={themed ? themedTextColor : textColor}
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  {body}
                </KitText>
              </Show>
            </View>
            <View
              style={{
                position: 'absolute',
                width: ANCHOR_SIZE,
                height: ANCHOR_SIZE,
                transform: [{ rotate: '45deg' }],
                backgroundColor: themed
                  ? themedBackgroundColor
                  : backgroundColor,
                borderRadius: ANCHOR_BORDER_RADIUS,
                ...anchorPosition,
              }}
            />
          </View>
        </KitTouchable>
      </Animated.View>
    );

    return (
      <Delayed delay={delay}>
        <CalloutModal
          showCallout={showCallout}
          dismiss={dismiss}
          fullScreenModal={fullScreenModal}
        >
          {content}
        </CalloutModal>
        {!fullScreenModal ? content : <></>}
      </Delayed>
    );
  }
);

const CalloutModal = ({
  children,
  showCallout,
  dismiss,
  fullScreenModal,
}: {
  children?: JSX.Element;
  showCallout?: boolean;
  dismiss?: () => void;
  fullScreenModal?: boolean;
}): JSX.Element => {
  return (
    <Modal
      transparent={true}
      visible={showCallout}
      presentationStyle='overFullScreen'
    >
      <Pressable style={{ flex: 1 }} onPress={dismiss}>
        {fullScreenModal && children}
      </Pressable>
    </Modal>
  );
};
