import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import { useEffect, useState } from 'react';

/*
 * Do not use this directly in a component, instead use KioskContext
 * */
export default (): {
  directPrintStationId: string | undefined;
  setDirectPrintStationId: (
    directPrintStationId: string | undefined
  ) => Promise<void>;
  directPrintStationIdLoading: boolean;
} => {
  const [directPrintStationId, setDirectPrintStationId] = useState<
    string | undefined
  >();
  const [directPrintStationIdLoading, setDirectPrintStationIdLoading] =
    useState(true);

  useEffect(() => {
    const getDirectPrintStation = async () => {
      const directPrintStationId = await getStoredItem(
        'direct-print-station-id'
      );

      if (directPrintStationId) {
        setDirectPrintStationId(directPrintStationId);
      } else {
        setDirectPrintStationId(undefined);
      }

      setDirectPrintStationIdLoading(false);
    };
    getDirectPrintStation();
  }, []);

  const saveDirectPrintStation = async (printerId: string | undefined) => {
    if (printerId) {
      await setStoredItem('direct-print-station-id', printerId);
    } else {
      await removeStoredItem('direct-print-station-id');
    }

    setDirectPrintStationId(printerId);
  };

  return {
    directPrintStationId,
    setDirectPrintStationId: saveDirectPrintStation,
    directPrintStationIdLoading,
  };
};
