import React, { useRef } from 'react';
import {
  Animated,
  Platform,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import { SizeClass, useSizeClass } from '../../contexts/SizeClassContext';
import { animatePressIn, animatePressOut } from '../../theming/Animating';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { capitalizeFirstLetter } from '../../utilities/utilities';
import { isHoverEnabled } from '../HoverState';
import Hoverable from '../Hoverable';
import { IconName } from '../KitIcon';
import Show from '../Show';
import { KitBadge, KitIcon, KitText, KitTouchable } from '../index';

const ARROW_SIZE = 20;

export interface ListItemProps {
  BadgeComponent?: JSX.Element;
  bottomBorder?: boolean;
  callout?: {
    CalloutComponent?: () => JSX.Element;
    onLayout?: (
      event: any,
      elementHeight: number,
      elementWidth?: number
    ) => void;
    showCallout?: boolean;
  };
  count?: number;
  capitalizeTitles?: boolean;
  CustomActions?: JSX.Element;
  contentStyle?: StyleProp<ViewStyle>;
  fullBorder?: boolean;
  fullBorderStyle?: StyleProp<
    Pick<
      ViewStyle,
      'borderColor' | 'borderStyle' | 'borderWidth' | 'borderRadius'
    >
  >;
  disableHover?: boolean;
  hoverStyle?: ViewStyle;
  ImageComponent?: JSX.Element;
  innerPaddingHorizontal?: ViewStyle['paddingHorizontal'];
  innerPaddingVertical?: ViewStyle['paddingVertical'];
  isTitleBold?: boolean;
  minHeight?: number;
  onPress?: () => void;
  onPressUnderlayColor?: string;
  onRightActionPress?: () => void;
  onShowUnderlay?: () => void;
  onHideUnderlay?: () => void;
  rightActionIcon?: IconName | '';
  rightIcon?: IconName | '';
  scaleOnTouch?: boolean;
  secondarySubtitle?: string;
  setContentMargin?: boolean;
  showRightIconOnAndroid?: boolean;
  stackImage?: boolean;
  style?: ViewStyle;
  subtitle?: string | React.ReactNode;
  subtitleColor?: string;
  subtitleFontSize?: number;
  subtitlePaddingTop?: number;
  subtitleLength?: number;
  subtitleLineHeight?: number;
  testID?: string;
  title?: string;
  titleColor?: string;
  titleFontWeight?: string;
  titleFontSize?: number;
  titleLineHeight?: number;
  topBorder?: boolean;
  iconColor?: string;
  iconSize?: number;
  rightElement?: JSX.Element;
  customHeight?: number;
  sectionUnderTitle?: JSX.Element;
}

export default ({
  BadgeComponent,
  bottomBorder = true,
  callout,
  count,
  capitalizeTitles = true,
  CustomActions,
  contentStyle,
  fullBorder = false,
  fullBorderStyle,
  disableHover = false,
  hoverStyle,
  ImageComponent,
  innerPaddingHorizontal,
  innerPaddingVertical,
  isTitleBold = false,
  minHeight,
  onPress,
  onPressUnderlayColor,
  onRightActionPress,
  onShowUnderlay,
  onHideUnderlay,
  rightActionIcon,
  rightIcon = 'arrow-right',
  scaleOnTouch,
  secondarySubtitle,
  setContentMargin,
  showRightIconOnAndroid = false,
  stackImage = false,
  style,
  subtitle,
  subtitleColor,
  subtitleFontSize = 14,
  subtitlePaddingTop,
  subtitleLength,
  subtitleLineHeight,
  testID,
  title,
  titleColor,
  titleFontSize,
  titleFontWeight,
  titleLineHeight,
  topBorder = false,
  iconColor,
  iconSize,
  rightElement,
  customHeight,
  sectionUnderTitle,
}: ListItemProps): JSX.Element => {
  const { sizeClass } = useSizeClass();

  const layoutProp = callout?.onLayout
    ? {
        onLayout: (event: any) => callout?.onLayout?.(event, ARROW_SIZE, 22),
      }
    : {};

  const onItemPress = onPress
    ? () => {
        if (onPress) {
          onPress();
        }
      }
    : undefined;

  let content = (
    <>
      {topBorder && !fullBorder ? (
        <View
          style={{
            marginHorizontal: setContentMargin ? Spacing.l : 0,
            borderTopWidth: 1,
            borderColor: Colors.N100,
          }}
        />
      ) : null}

      <View
        style={[
          {
            paddingHorizontal: setContentMargin
              ? Spacing.l
              : innerPaddingHorizontal ?? 0,
            paddingVertical: innerPaddingVertical ?? 0,
            alignItems:
              secondarySubtitle || BadgeComponent ? 'flex-start' : 'center',
            display: 'flex',
            minHeight:
              minHeight || minHeight === 0
                ? minHeight
                : bottomBorder || topBorder
                ? customHeight
                  ? customHeight
                  : 64
                : 48,
            flexDirection: 'row',
            justifyContent: 'space-between',
            ...(stackImage && {
              alignItems: 'stretch',
              flexDirection: 'column',
            }),
            ...((secondarySubtitle || BadgeComponent) &&
            (bottomBorder || topBorder)
              ? { paddingBottom: Spacing.l, paddingTop: Spacing.l }
              : {}),
          },
          fullBorder && {
            borderColor: Colors.N100,
            borderStyle: 'solid',
            borderWidth: 1,
          },
          fullBorder && fullBorderStyle,
          contentStyle,
        ]}
      >
        <Show
          show={
            Boolean(callout?.CalloutComponent) && Boolean(callout?.showCallout)
          }
        >
          <>{callout?.CalloutComponent?.()}</>
        </Show>
        <Show show={Boolean(ImageComponent)}>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              ...(stackImage && {
                alignItems: 'stretch',
              }),
            }}
          >
            {ImageComponent}
          </View>
        </Show>
        <View
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginEnd: Spacing.m,
            marginLeft: ImageComponent ? Spacing.m : 0,
            ...(stackImage && {
              marginBottom: Spacing.m,
              marginLeft: 0,
            }),
          }}
        >
          <KitText
            ellipsizeMode='tail'
            numberOfLines={1}
            style={{
              color: titleColor ?? Colors.N900,
              fontSize: titleFontSize ?? 16,
              fontWeight: titleFontWeight ?? isTitleBold ? '700' : '400',
              lineHeight:
                titleLineHeight ?? Math.floor((titleFontSize ?? 16) * 1.18),
            }}
          >
            {capitalizeTitles
              ? capitalizeFirstLetter(title || '')
              : title || ''}
          </KitText>
          <Show show={Boolean(subtitle)}>
            <KitText
              ellipsizeMode='tail'
              numberOfLines={subtitleLength ?? 1}
              style={{
                ...(subtitlePaddingTop
                  ? { paddingTop: subtitlePaddingTop }
                  : {}),
                color: subtitleColor ?? Colors.N500,
                fontSize:
                  subtitleFontSize ?? (sizeClass === SizeClass.Large ? 16 : 14),
                ...(subtitleLineHeight
                  ? { lineHeight: subtitleLineHeight }
                  : {}),
              }}
            >
              {capitalizeTitles
                ? capitalizeFirstLetter(
                    (typeof subtitle === 'string' && subtitle) || ''
                  )
                : subtitle || ''}
            </KitText>
          </Show>
          <Show show={Boolean(secondarySubtitle)}>
            <KitText
              ellipsizeMode='tail'
              numberOfLines={1}
              style={{
                color: subtitleColor ?? Colors.N500,
                fontSize:
                  subtitleFontSize ?? (sizeClass === SizeClass.Large ? 16 : 14),
                ...(subtitleLineHeight
                  ? { lineHeight: subtitleLineHeight }
                  : {}),
              }}
            >
              {capitalizeTitles
                ? capitalizeFirstLetter(secondarySubtitle || '')
                : secondarySubtitle || ''}
            </KitText>
          </Show>
          {sectionUnderTitle && sectionUnderTitle}
          <Show show={Boolean(BadgeComponent)}>
            <View
              style={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: 4,
              }}
            >
              {BadgeComponent}
            </View>
          </Show>
        </View>
        <View
          {...layoutProp}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Show show={Boolean(rightElement)}>
            <View style={{ marginRight: Spacing.s }}>{rightElement}</View>
          </Show>
          {count !== undefined ? (
            <KitBadge
              text={count.toString()}
              size='l'
              style={{
                marginRight: 8,
              }}
            />
          ) : null}
          <Show
            show={
              onPress !== undefined &&
              rightIcon !== '' &&
              (showRightIconOnAndroid ||
                Platform.OS === 'ios' ||
                Platform.OS === 'web') &&
              !CustomActions
            }
          >
            <KitIcon
              color={iconColor ?? Colors.N200}
              name={rightIcon as IconName}
              size={iconSize ?? ARROW_SIZE}
            />
          </Show>
          <Show
            show={
              onRightActionPress !== undefined &&
              rightActionIcon !== '' &&
              !CustomActions
            }
          >
            <Pressable
              onPress={onRightActionPress}
              style={{
                zIndex: 99999,
              }}
            >
              <KitIcon
                color={iconColor ? iconColor : Colors.N900}
                name={rightActionIcon as IconName}
                size={iconSize ?? ARROW_SIZE}
                style={
                  secondarySubtitle || BadgeComponent
                    ? {
                        marginTop: Spacing.s,
                      }
                    : {}
                }
              />
            </Pressable>
          </Show>
          <Show show={Boolean(CustomActions)}>
            <>{CustomActions}</>
          </Show>
        </View>
      </View>

      {bottomBorder && !fullBorder ? (
        <View
          style={{
            marginHorizontal: setContentMargin ? Spacing.l : 0,
            borderBottomWidth: 1,
            borderColor: Colors.N100,
          }}
        />
      ) : null}
    </>
  );

  if (onPress && !disableHover && isHoverEnabled()) {
    content = <HoverWrapper style={hoverStyle}>{content}</HoverWrapper>;
  }

  if (scaleOnTouch) {
    content = (
      <ScalingTouchable onItemPress={onItemPress} style={style} testID={testID}>
        {content}
      </ScalingTouchable>
    );
  } else {
    content = (
      <KitTouchable
        onPress={onItemPress}
        style={style}
        borderRadius={0}
        ripple={false}
        testID={testID}
        underlayColor={onPressUnderlayColor}
        onShowUnderlay={onShowUnderlay}
        onHideUnderlay={onHideUnderlay}
      >
        {content}
      </KitTouchable>
    );
  }

  return content;
};

interface WrapperProps {
  children: JSX.Element;
  onItemPress?: () => void;
  style?: ViewStyle;
  testID?: string;
}

const ScalingTouchable = (props: WrapperProps) => {
  const { children, onItemPress, style } = props;
  const scale = new Animated.Value(1);

  return (
    <KitTouchable
      style={style}
      onPress={onItemPress}
      onPressIn={() => animatePressIn(scale)}
      onPressOut={() => animatePressOut(scale)}
      ripple={false}
      underlayColor={Platform.OS !== 'web' ? Colors.N50 : ''} // Web applies this color on hover
      borderRadius={0}
      {...props}
    >
      {/* Scaling container */}
      <Animated.View style={[{ transform: [{ scale: scale }] }]}>
        {children}
      </Animated.View>
    </KitTouchable>
  );
};

const HoverWrapper = ({
  style,
  children,
}: {
  children: JSX.Element;
  style?: ViewStyle;
}) => {
  const hoverContainerRef = useRef<View>(null);

  if (!isHoverEnabled()) {
    return children;
  }

  return (
    <Hoverable
      onHoverIn={() =>
        hoverContainerRef.current?.setNativeProps({
          style: { backgroundColor: Colors.N50 },
        })
      }
      onHoverOut={() =>
        hoverContainerRef.current?.setNativeProps({
          style: { backgroundColor: undefined },
        })
      }
    >
      {/* Hover state background container */}
      <View style={style} ref={hoverContainerRef}>
        {children}
      </View>
    </Hoverable>
  );
};
