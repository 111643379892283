import React from 'react';

import BlockPageContent from './BlockPageContent';
import {
  BlockPageContextProvider,
  useBlockPageContext,
} from './BlockPageContext';
import { BlockPageProps } from './types';

/**
 * BlockPageNested
 * A BlockPage that will be nested within another BlockPage
 *
 * Dev notes:
 * 1. A nested block page must define a context provider to provide data independently from the parent Block Page
 * 2. We need to avoid passing a `topBarStyle` value to prevent the nested BlockPage from changing
 * the state of top navbar in the parent block page.
 */
export default function BlockPageNested(props: BlockPageProps): JSX.Element {
  const { appKey, authProviderId, clientBrandColor, guestToken } =
    useBlockPageContext();

  return (
    <BlockPageContextProvider
      props={{
        appKey: appKey,
        authProviderId: authProviderId,
        clientBrandColor: clientBrandColor,
        guestToken: guestToken,
        ...props,
        topBarStyle: undefined,
      }}
    >
      <BlockPageContent />
    </BlockPageContextProvider>
  );
}
