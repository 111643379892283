import { IHttpRequest } from '..';
import Downloader, { RequestOptions } from '../../../Downloader';
import { AuthProvider } from '../../../auth';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '../../../constants/identifiers';
import CacheService from '../../CacheService';
import { ICache } from '../../Types';
import { IHttpResponse, handleError, handleSuccess } from '../Response';

const debug = require('debug')('tca:service:BaseServiceV2:Core:Get');

export type BaseGetReturn<T> = Promise<IHttpResponse<T>>;
export interface BaseGetProps {
  url: IHttpRequest['url'];
  appKey?: string;
  authProviderId?: string;
  targetProviders?: AuthProvider[];
  cache?: ICache['cache'];
  domainPreValidated?: boolean; // only set to true if domain is trusted for Authorization
  getFromCache?: boolean;
  headers?: IHttpRequest['headers'];
  impression?: boolean;
  orgKey?: string;
  signal?: AbortSignal;
  skipTimestamp?: ICache['skipTimestamp'];
  token?: string;
}

export default <T = any>({
  appKey,
  domainPreValidated,
  authProviderId = SUBSPLASH_AUTH_PROVIDER_ID,
  targetProviders,
  cache,
  getFromCache = false,
  orgKey,
  skipTimestamp,
  token,
  url,
  headers,
  signal,
  impression = false,
}: BaseGetProps): BaseGetReturn<T> => {
  debug('Url', url);

  const strippedToken = token ? token.replace(/[Bb]earer/, '') : '';
  const bear_token = strippedToken ? `Bearer ${strippedToken}` : '';

  if (getFromCache) {
    debug('Cached response');

    return CacheService.getCachedResponse({
      url: url,
      authorization: bear_token,
      cache: cache,
    })
      .then(handleSuccess)
      .catch(handleError);
  }

  debug('Network request');
  const extraHeaders = {
    ...headers,
    ...(bear_token && { Authorization: bear_token }),
  };

  const options: RequestOptions = {
    appKey,
    domainPreValidated,
    orgKey,
    authProviderId,
    targetProviders,
    method: 'GET',
    extraHeaders,
    impression,
  };

  /**
   * TODO:
   * - replace downloadUrl with axios or js fetch method
   */
  return Downloader.downloadUrl({
    url,
    options,
    cache,
    skipTimestamp,
    signal,
  })
    .then(handleSuccess)
    .catch(handleError);
};
