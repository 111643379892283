import { dispatch } from '@omni/kit/ActionHandler';
import { KitIcon, KitTouchable } from '@omni/kit/components';
import KitText from '@omni/kit/components/KitText';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import Colors from '@omni/kit/theming/Colors';
import { SpacingType, getSpacing } from '@omni/kit/theming/SpacingType';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import React, { useContext } from 'react';
import { View } from 'react-native';

import { useBlockPageContext } from '../BlockPageContext';
import { IBlockProps } from './types';

const debug = require('debug')('tca:blocks:blocks:ActionRowBlock');

// These props are publicly available
export interface IActionRowBlockProps extends IBlockProps {
  /** A title that appears on the left side */
  title?: string;

  /** An action that will occur when a user taps/clicks on the block.*/
  action?: any;

  /** A title that appears on the right side. */
  actionTitle?: string;

  horizontalSpacing?: SpacingType;

  /**
   * Show a chevron arrow indicator that appears on the right side instead of
   * the actionTitle.
   */
  enableChevron?: boolean;
}

export default function ActionRowBlock(
  props: IActionRowBlockProps
): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);

  const { dispatchAction } = useBlockPageContext();

  const {
    title = null,
    action = null,
    actionTitle = null,
    insetStyle = {},
    topSpacing = null,
    bottomSpacing = null,
    horizontalSpacing,
    enableChevron = true,
  } = props;

  const { edgeSpacing, interItemSpacing } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const viewStyle = {
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
    ...insetStyle,
  };

  return (
    <View style={viewStyle}>
      <KitTouchable
        style={{ marginHorizontal: edgeSpacing }}
        borderRadius={0}
        onPress={() => {
          dispatchAction(action);
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop:
              interItemSpacing > 0
                ? interItemSpacing / 2 - 3 /* text padding */
                : 0,
            marginBottom:
              interItemSpacing > 0
                ? interItemSpacing / 2 - 3 /* text padding */
                : 0,
          }}
        >
          {title && (
            <KitText style={{ flex: 1 }} h1>
              {title}
            </KitText>
          )}

          <View>
            {enableChevron ? (
              // same color as h1 title
              <KitIcon
                name='arrow-right'
                size={16}
                color={colorForScheme?.({
                  light: Colors.N900,
                  dark: Colors.N0,
                })}
              />
            ) : (
              <KitText subtitle>{actionTitle}</KitText>
            )}
          </View>
        </View>
      </KitTouchable>
    </View>
  );
}
