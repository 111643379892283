import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { ShellContext } from '@omni/kit/contexts/ShellContext';
import React from 'react';

import InitialData from './InitialData';
import { ShellAppProviderProps } from './types';

/**
 * This provider should only be used to wrap micro apps for mocking the web shell app environment.
 */
export default ({
  rootProps,
  children,
}: {
  rootProps: ShellAppProviderProps;
  children: React.ReactNode;
}): null | JSX.Element => {
  let value = rootProps.shellData || InitialData;

  /**
   * micro-apps that support enterprise protected content like Block Page
   * lookup the token by auth provider id, so we need to add the userToken
   * to the accessTokens object to avoid breaking Subsplash protected content
   * in micro-apps on the web that look up tokens by auth provider id.
   * TODO: remove this workaround if we decide to support enterprise protected content
   * in the web shell in the future per https://subsplash.io/javascript/web-app/-/merge_requests/211
   */
  if (
    Object.keys(value.tokens.accessTokens).length === 0 &&
    value.tokens.user
  ) {
    value = {
      ...value,
      tokens: {
        ...value.tokens,
        accessTokens: {
          [`${SUBSPLASH_AUTH_PROVIDER_ID}`]: value.tokens.user,
        },
      },
    };
  }

  return (
    <ShellContext.Provider value={value}>{children}</ShellContext.Provider>
  );
};
