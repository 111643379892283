import { DEFAULT_PAGE_SIZE } from '@omni/kit/services/SearchService/Constants';

export default (
  totalItems: number,
  pageSize: number = DEFAULT_PAGE_SIZE
): number => {
  if (!totalItems) {
    return 1;
  }

  return totalItems < pageSize ? 1 : Math.ceil(totalItems / pageSize);
};
