import SendbirdChat, { User } from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import {
  BaseMessage,
  MessageRequestHandler,
  PreviousMessageListQuery,
  UserMessage,
  UserMessageCreateParams,
} from '@sendbird/chat/message';
import {
  OpenChannel,
  OpenChannelModule,
  ParticipantListQuery,
  SendbirdOpenChat,
} from '@sendbird/chat/openChannel';

const BANNED_USER_SECONDS = 86400; // 24 hours

const sbBanUser = (userId: string, channel: OpenChannel): Promise<string> => {
  return channel
    .banUserWithUserId(userId, BANNED_USER_SECONDS)
    .then(() => userId);
};

const sbConnect = (userId: string, accessToken: string): Promise<User> => {
  return SendbirdChat.instance?.connect(userId, accessToken);
};

const sbEnterOpenChannel = (channelUrl: string): Promise<OpenChannel> => {
  if (!sbIsConnected()) {
    return Promise.reject('Not connected');
  }

  let returnedChannel: OpenChannel;

  return (SendbirdChat.instance as SendbirdOpenChat)?.openChannel
    .getChannel(channelUrl)
    .then((channel) => {
      returnedChannel = channel;
      channel.enter();
    })
    .then(() => returnedChannel);
};

const sbDeleteMessage = (
  channel: OpenChannel,
  message: UserMessage
): Promise<void> => {
  return channel.deleteMessage(message);
};

const sbDisconnect = async (): Promise<void> => {
  try {
    if (!sbIsConnected()) return;

    await SendbirdChat.instance?.disconnect();
  } catch {}
};

const sbGetParticipantsList = (
  listQuery: ParticipantListQuery
): Promise<User[]> => {
  return listQuery.next();
};

const sbInitialize = (appId: string): void => {
  SendbirdChat.init({
    appId,
    // Initializing both open and group channel modules so live chat & group messaging can both be accessed
    modules: [new OpenChannelModule(), new GroupChannelModule()],
  });
};

const sbIsConnected = (): boolean => {
  return (
    Boolean(SendbirdChat.instance?.currentUser) &&
    SendbirdChat.instance?.connectionState === 'OPEN'
  );
};

const sbLoadMessages = (
  query: PreviousMessageListQuery
): Promise<BaseMessage[]> => {
  if (query.hasNext) {
    return query.load();
  }

  return Promise.resolve([]);
};

const sbRefreshChannel = (channel: OpenChannel): Promise<OpenChannel> => {
  return channel.refresh();
};

const sbSendMessage = (
  channel: OpenChannel,
  textMessage: string,
  type = 'text'
): MessageRequestHandler | null => {
  const params: UserMessageCreateParams = {
    message: textMessage,
    customType: type,
  };

  if (!sbIsConnected()) {
    return null;
  }

  return channel.sendUserMessage(params);
};

export {
  sbBanUser,
  sbConnect,
  sbDisconnect,
  sbDeleteMessage,
  sbEnterOpenChannel,
  sbGetParticipantsList,
  sbLoadMessages,
  sbInitialize,
  sbIsConnected,
  sbRefreshChannel,
  sbSendMessage,
};
