/**
 * New sizes are based on https://subsplash.atlassian.net/browse/MED-7048
 * which is still being refined by the design team
 */

export enum SizeClass {
  /** legacy sizes */
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum SizeClassV2 {
  /**
   * new sizes
   *
   * Note: use number for each enum to allow operator comparsion
   * Example:
   *
   * const { sizeClassV2 } = useSizeClass();
   * const largeScreen = sizeClassV2 >= SizeClass.L
   */
  XXS = 0,
  XS = 1,
  S = 2,
  M = 3,
  L = 4,
  XL = 5,
  XXL = 6,
}

export enum WindowSizeMin {
  /** legacy sizes */
  Medium = 836,
  Large = 1201,

  /** new sizes */
  XXS = 0,
  XS = 420,
  S = 640,
  M = 768,
  L = 1024,
  XL = 1280,
  XXL = 1536,
}

export enum WindowSizeMax {
  /** legacy sizes */
  Small = 835,
  Medium = 1200,

  /** new sizes */
  XXS = WindowSizeMin.XS - 1,
  XS = WindowSizeMin.S - 1,
  S = WindowSizeMin.M - 1,
  M = WindowSizeMin.L - 1,
  L = WindowSizeMin.XL - 1,
  XL = WindowSizeMin.XXL - 1,
  XXL = Infinity,
}

export type SizeClassContextType = {
  sizeClass: SizeClass /** legacy size class */;
  sizeClassV2: SizeClassV2 /** new size class */;
  windowWidth: number;
  windowHeight: number;
  smallestWindowWidth: number;
};
