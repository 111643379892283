import useImageUpload from '@omni/kit/hooks/useImageUpload';
import { ImageUploadProps } from '@omni/kit/hooks/useImageUpload/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { appKeySelector } from '../shared/redux/selectors';
import { retrieveEndUserIdFromDevice } from '../utilities/mobileStorage';

const debug = require('debug')('tca:messaging:hooks:useChatImageUpload');

// This is used for apps + profiles that have not yet been migrated to Fluro
// or for apps below mobile release 5.15.
// TODO: Once all apps, users have been migrated to fluro and on 5.15+, this
// hook can be removed.
export default function useChatImageUpload(): ImageUploadProps {
  const appKey = useSelector(appKeySelector);
  const [endUserId, setEndUserId] = useState<string | undefined>(undefined);

  const upload = useImageUpload(appKey, endUserId);

  useEffect(() => {
    if (endUserId === undefined) {
      retrieveEndUserIdFromDevice()
        .then((storedEndUserId) => {
          setEndUserId(storedEndUserId ?? '');
        })
        .catch((err) => {
          debug('Error retrieving end user id', err);
          setEndUserId('');
        });
    }
  }, [endUserId]);

  return upload;
}
