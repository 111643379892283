import { ISessionSelection } from '@omni/check-in/pre-check/hooks/useStoredSessions';
import {
  KitButton,
  KitModal,
  KitText,
  KitTouchableIcon,
} from '@omni/kit/components';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import KitOptionSelector from '@omni/kit/components/KitOptionSelector';
import { ISession } from '@omni/kit/services/EventsService/Types';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, ScrollView, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

export interface EditSessionModalProps {
  visible: boolean;
  sessions: ISession[];
  profile: IProfile | undefined;
  onBackPress: () => void;
  onSaveSession: (session: ISession) => void;
  currentSession: ISessionSelection | undefined;
}

const EditSessionModal: React.FC<EditSessionModalProps> = ({
  visible,
  sessions,
  profile,
  onBackPress,
  onSaveSession,
  currentSession,
}): JSX.Element | null => {
  const { t } = useTranslation(['common', 'check-in']);
  const [selectedSession, setSelectedSession] = useState<
    ISession | undefined
  >();

  useEffect(() => {
    if (currentSession) {
      setSelectedSession(
        sessions.find((s) => s.id === currentSession['session.id'])
      );
    }
  }, [currentSession, sessions]);

  return visible ? (
    <View
      style={{
        maxHeight: Dimensions.get('window').height - 150,
      }}
    >
      <KitTouchableIcon
        onPress={onBackPress}
        name='back-ios'
        size={20}
        style={{
          marginTop: Spacing.m,
          marginLeft: Spacing.xs,
        }}
        buttonSize={44}
      />
      <ScrollView
        style={{
          height: sessions.length * 65 + 100,
          paddingHorizontal: Spacing.l,
          marginTop: Spacing.m,
        }}
        showsVerticalScrollIndicator={false}
      >
        <KitText h2>
          {t('check-in:textChooseASessionFor', { name: profile?.first_name })}
        </KitText>
        <View
          style={[
            {
              marginTop: Spacing.l,
              flex: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginBottom: Spacing.xxxxl,
            },
          ]}
        >
          {/* Sessions */}
          <KitOptionSelector
            options={sessions}
            selectedOption={sessions.findIndex(
              (option) => option.id === selectedSession?.id
            )}
            onSelectOption={(index) => setSelectedSession(sessions[index])}
            optionWidth={Dimensions.get('screen').width - 36}
            optionHeight={56}
            dynamicBoldStyle
            fontSize={16}
            rowMargin={8}
          />
        </View>
      </ScrollView>
      <LinearGradient
        colors={['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)']}
        start={{ x: 0, y: -0.2 }}
        end={{ x: 0, y: 0.5 }}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: Spacing.l,
          paddingHorizontal: Spacing.l,
        }}
      >
        <KitButton
          onPress={() => selectedSession && onSaveSession(selectedSession)}
          title={t('common:buttonTitleSave')}
          color={Colors.N900}
          textColor={Colors.N0}
          style={{ width: '100%' }}
          disabled={!selectedSession}
        />
      </LinearGradient>
    </View>
  ) : null;
};

export default EditSessionModal;
