import React, { useContext, useState } from 'react';
import { Animated, Platform } from 'react-native';

import { SizeClass, SizeClassContext } from '../contexts/SizeClassContext';
import Colors from '../theming/Colors';

const debug = require('debug')('tca:auth:screen:Authorization');

interface IModalWrapperProps {
  children: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ModalWrapper({ children = null }: IModalWrapperProps) {
  const [animateValue] = useState(new Animated.Value(0));
  const sizeClass = useContext(SizeClassContext).sizeClass;

  // Only render this on large web
  if (Platform.OS !== 'web' || sizeClass === SizeClass.Small) {
    return <>{children}</>;
  }

  Animated.sequence([
    Animated.parallel([
      Animated.timing(animateValue, {
        toValue: 1,
        delay: 500,
        duration: 300,
        useNativeDriver: true,
      }),
    ]),
  ]).start();

  return (
    <Animated.View
      style={{
        flex: 1,
        opacity: animateValue,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#00000066', // 40% opacity based on designs
      }}
    >
      <Animated.View
        style={{
          opacity: animateValue,
          transform: [
            {
              scale: animateValue.interpolate({
                inputRange: [0, 1],
                outputRange: [0.97, 1],
              }),
            },
          ],
          justifyContent: 'center',
          backgroundColor: Colors.N0,
          width: '100%',
          flex: 1,
          maxWidth: 650,
          maxHeight: 600,
          borderRadius: 10,
          padding: 10,
          ...Platform.select({
            android: {
              elevation: 10,
              overflow: 'hidden',
            },
            default: {
              shadowColor: '#000000',
              shadowOpacity: 0.12,
              shadowOffset: { width: 0, height: 10 },
              shadowRadius: 25,
            },
          }),
        }}
      >
        {children}
      </Animated.View>
    </Animated.View>
  );
}
