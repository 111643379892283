import Environment from '../../../Environment';
import { buildUrl, IFilter } from '../../BaseServiceV2';

const host = Environment.bibleService;

export interface IPrepareTranslationUrl {
  filters?: IFilter[];
}

export function prepareTranslationUrl({
  filters,
}: IPrepareTranslationUrl): string {
  return buildUrl({
    host,
    resource: 'translation',
    filters,
  });
}
