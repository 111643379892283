import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AuthProvider, AuthService, CredentialProps } from '../auth';
import useAuthProviders from '../hooks/useAuthProviders';
import { App } from '../services/Types';
import { IRootProps } from './types';

const debug = require('debug')('omni:kit:context:AuthContext');

interface AuthContextProps {
  accessTokens: { [authProviderId: string]: string | null };
  isAuthenticated: boolean;
  providers: AuthProvider[];
  targetProviders: AuthProvider[];
  targetProviderIds: string[];
  loginEnabledProviders: AuthProvider[];
  isAuthReady: boolean;
}

export const AuthContext = createContext<AuthContextProps>({
  accessTokens: {},
  isAuthenticated: false,
  providers: [],
  targetProviders: [],
  targetProviderIds: [],
  loginEnabledProviders: [],
  isAuthReady: false,
});

interface ProviderProps extends IRootProps {
  app?: App;
  context?: string;
  children?: React.ReactNode | Array<React.ReactNode>;
}

/**
 * AuthContext is designed to be used to provide tokens for a ShellContextProvider.
 * It also will automatically refresh tokens for all auth providers except
 * Google, Apple, and Facebook. This allows the same screen to fetch data from
 * multiple sources like Subsplash and Enterprise providers without needing to
 * manage the tokens manually within the screen.
 */
export const AuthContextProvider = (
  props: ProviderProps & { context?: string }
): JSX.Element | null => {
  const initialAccessTokens = AuthService.getAccessTokens() ?? {};
  Object.keys(initialAccessTokens).forEach((authProviderId) => {
    initialAccessTokens[authProviderId] =
      initialAccessTokens[authProviderId]?.replace('Bearer ', '') || null;
  });

  const [accessTokens, setAccessTokens] = useState<{
    [authProviderId: string]: string | null;
  }>(initialAccessTokens);

  const accessTokensRef = useRef<{
    [authProviderId: string]: string | null;
  }>(initialAccessTokens);

  const [isAuthReady, setIsAuthReady] = useState(false);
  const isAuthenticated = useMemo(
    () => Boolean(accessTokens?.[SUBSPLASH_AUTH_PROVIDER_ID]),
    [accessTokens]
  );

  /**
   * Do not fetch auth providers on the container app instance.
   * A container app instance on first launch is designed to only show
   * a Tour/Search UX that does not need or use any end-user authorization
   * A container app like TCA has many auth providers which are only used
   * in the context of the login flow for login with google, apple or
   * enterprise auth providers. Letting them load here will cause an unnecesary
   * series of token refresh requests for the container app root instance.
   */
  const isContainer = props?.app?.is_container;
  const appKey = !isContainer ? props?.appKey : undefined;
  const orgKey = !isContainer ? props?.app?.org_key : undefined;

  const {
    isFetchingAuthProviders,
    loginEnabledProviders,
    targetProviders,
    targetProviderIds,
    providers,
  } = useAuthProviders({
    appKey,
    orgKey,
  });

  useEffect(() => {
    if (isFetchingAuthProviders) {
      return;
    }

    if (isContainer) {
      setIsAuthReady(true);

      return;
    }

    if (!appKey || !orgKey) {
      return;
    }

    if (targetProviders.length === 0) {
      setIsAuthReady(true);

      return;
    }

    AuthService.initialize();

    const setup = async () => {
      const credentialProps: CredentialProps = {
        appKey,
        orgKey,
        targetProviders,
        sapToken: props.sapToken,
        source: 'app',
      };

      try {
        const accessTokens = await AuthService.loadCredentials(credentialProps);
        accessTokensRef.current = {
          ...accessTokensRef.current,
          ...accessTokens,
        };
      } catch (e) {
        debug(
          `Unable to get access token for ${credentialProps.authProviderId}: ${e}`
        );
      }

      setAccessTokens(accessTokensRef.current);

      setIsAuthReady(!AuthService.isRefreshing);
    };

    setup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appKey, orgKey, isFetchingAuthProviders, targetProviders, isContainer]);

  const authCallback = useCallback(
    () => {
      const accessTokens = AuthService.getAccessTokens();

      accessTokensRef.current = {
        ...accessTokensRef.current,
        ...accessTokens,
      };

      setAccessTokens(accessTokensRef.current);
      setIsAuthReady(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Keep context up-to-date with any changes to credentials in AuthService
  const removeListeners = useRef<(() => void)[]>([]);
  useEffect(() => {
    if (targetProviders.length === 0) {
      return;
    }

    removeListeners.current.forEach((removeListener) => removeListener());
    removeListeners.current = [];

    removeListeners.current.push(
      AuthService.addListener(
        'AuthorizationChanged',
        authCallback,
        'AuthContext'
      )
    );

    // ref: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#potential-issues
    const cleanup = removeListeners.current;

    return () => {
      cleanup.forEach((removeListener) => removeListener());
    };

    /**
     * NOTE: Do not add 'accessTokens' or 'authCallback' to dependencies array,
     * otherwise listeners will be added and removed in rapid succession,
     * leading to inconsistent auth state (e.g. user appears logged out
     * when the user is actually logged-in)
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetProviders]);

  if (!isAuthReady) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        accessTokens,
        isAuthenticated,
        providers,
        targetProviders,
        targetProviderIds,
        loginEnabledProviders,
        isAuthReady,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

/**
 * @deprecated in favor of useShellContext
 * Do not use this within micro apps.
 * Only use this within the ApplicationContext
 */
export const useAuth = (): AuthContextProps => {
  return useContext<AuthContextProps>(AuthContext);
};
