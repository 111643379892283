import AnalyticsService from '@omni/kit/services/AnalyticsService';
import Axios, { AxiosResponse } from 'axios';

import { RequestOptions } from '../../Downloader';

const axiosRequest = async ({
  url,
  data,
  options,
}: {
  data?: object;
  url: string;
  options: RequestOptions;
}): Promise<AxiosResponse<any>> => {
  const response = await Axios.request({
    data,
    headers: options.extraHeaders,
    method: options.method,
    url,
  });

  /** Send analytics */
  if (options.impression && options.appKey) {
    AnalyticsService.sendImpression({
      url,
      appKey: options.appKey,
      customHeaders: options.extraHeaders as Record<string, string>,
    });
  }

  return response;
};

export default axiosRequest;
