import { version } from '@omni/kit';
import { Platform } from 'react-native';

import { getNativeAppHeaders } from '../utilities/NativeHelpers';

const debug = require('debug')('tca:kit:Downloader:GetAppHeaders');

export default async (appKey?: string): Promise<Record<string, string>> => {
  let appHeaders: Record<string, string> = {};
  try {
    const headerString = await getNativeAppHeaders();
    appHeaders = JSON.parse(headerString);
    appHeaders['sap-omni-version'] = version; // Override native omni version with our known package version
  } catch {
    debug('could not fetch native app headers');

    // This is necessary for the Dev app when debugging in the Simulator
    appHeaders['sap-omni-version'] = version;
    if (appKey) {
      appHeaders['sap-active-appkey'] = appKey;
    }

    // sap-app-version, sap-omni-version and sap-device-os are
    // required to support confirmation code field request on 5.13+
    appHeaders['sap-app-version'] = version;
    appHeaders['sap-device-os'] = Platform.OS;
  }

  if (Platform.isTV) {
    appHeaders['sap-platform'] = 'tv';
  }

  if (Platform.OS === 'web') {
    // add web headers
    appHeaders['sap-omni-version'] = version;
    if (appKey) {
      appHeaders['sap-active-appkey'] = appKey;
    }

    appHeaders['sap-app-version'] = version;
    appHeaders['sap-platform'] = 'web';
  }

  // ref: https://subsplash.atlassian.net/wiki/spaces/BKND/pages/1912275021/Communicating+between+services#Tracing
  appHeaders['X-Sap-Service'] = 'omni-app';

  debug(appHeaders);

  return appHeaders;
};
