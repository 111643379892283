import TokensService from '@omni/kit/services/TokensService';

import Environment from '../../Environment';
import BaseServiceV2, { IHttpResponse, buildUrl } from '../BaseServiceV2';

export type SetupResponseBody = {
  constants: {
    app_key: string;
    app_states_template_url: string;
    callback_url: string;
    feature_notifications: string;
    giving_url: string;
    metrics_url: string;
    push_token_url: string;
    search_url: string;
    session_url: string;
    settings_url: string;
    structure_url: string;
    title: string;
    wrapper_url: string; // analytics wrapper
    custom_path: string;
  };
  cache_manifest: {
    block_app_launch: boolean;
    url: string;
    hash: string;
  };
};

interface PropOptions {
  appKey: string;
  analytics?: boolean;
  token?: string;
}

export default async function GetSetup({
  appKey,
  analytics,
  token,
}: PropOptions): Promise<IHttpResponse<SetupResponseBody>> {
  const url = buildUrl({
    host: Environment.feedsHost,
    resource: 'v3/setup',
    id: appKey,
    pluralizeResource: false,
    ...(!analytics ? { queries: [['analytics', '0']] } : {}),
  });

  return BaseServiceV2.Get({
    url,
    token: token ?? (await TokensService.getGuestToken()),
  });
}
