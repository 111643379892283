import { isEqual } from 'lodash';
import {
  EmitterSubscription,
  NativeEventEmitter,
  NativeModules,
} from 'react-native';

import HealthMonitor from '../../HealthMonitor';
import { AuthProvider, AuthService, getAuthorizedProvider } from '../../auth';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '../../constants/identifiers';
import GetCustomProfileHeader from '../FeedsService/GetCustomProfileHeader';
import GetUserProfile, {
  IUserProfileInfo,
} from '../FeedsService/GetUserProfile';
import { UserInfoServiceProps } from './types';

const debug = require('debug')('omni:kit:UserInfoService');

interface ListenerObject {
  appKey: string;
  context?: string; // useful for debugging which contexts requests to user-info came from
  callback: (data: {
    appKey: string;
    userInfo: IUserProfileInfo | undefined;
  }) => void;
}

export class UserInfoService {
  private static _userInfoMap: {
    [appKey: string]: IUserProfileInfo | undefined;
  } = {};

  private static _targetProviders: AuthProvider[] = [];

  public static async loadUserInfo(
    props: UserInfoServiceProps
  ): Promise<IUserProfileInfo | undefined> {
    this._targetProviders = props.targetProviders;

    this.setupNativeListeners(props);
    this.setupAuthServiceListener();

    const userInfo = await this.loadSavedUserInfo(props);

    return userInfo;
  }

  private static _appUserSettingsUpdatedListener:
    | EmitterSubscription
    | undefined;

  private static setupNativeListeners(props: UserInfoServiceProps): void {
    const { appKey, accessTokens, targetProviders } = props;

    const { ReactPlatformBridge } = NativeModules;

    if (!ReactPlatformBridge) return;

    const ReactPlatformBridgeEventEmitter = new NativeEventEmitter(
      ReactPlatformBridge
    );

    this._appUserSettingsUpdatedListener?.remove();
    this._appUserSettingsUpdatedListener =
      ReactPlatformBridgeEventEmitter.addListener(
        'NotifyAppUserSettingsUpdated',
        async () => {
          if (appKey && accessTokens && targetProviders.length > 0) {
            await UserInfoService.refreshUserInfo({
              appKey,
              targetProviders,
              accessTokens,
            });
          }
        }
      );
  }

  private static _authServiceListenersRemoveFn: (() => void)[] = [];

  /*
   * Caution: Do not register/unregister auth listeners from multiple contexts
   * in rapid succession because this can cause a race condition where
   * AuthorizationChanged events are missed when the listeners are mutated
   * in the middle of an event being dispatched. In testing, this race condition
   * is possible when opening deep links when app is not running.
   *
   * It is safe to keep a single listener registered in UserInfoService
   * for all contexts because it uses the same logic to
   * refresh user info using static props from AuthService for all contexts.
   */
  private static setupAuthServiceListener(): void {
    if (this._authServiceListenersRemoveFn.length > 0) {
      return;
    }

    this._authServiceListenersRemoveFn.push(
      AuthService.addListener(
        'AuthorizationChanged',
        () => {
          const appKey = AuthService.getCurrentAppKey();
          const targetProviders = UserInfoService._targetProviders;
          const accessTokens = AuthService.getAccessTokens();

          if (accessTokens[SUBSPLASH_AUTH_PROVIDER_ID]) {
            if (appKey) {
              UserInfoService.refreshUserInfo({
                appKey,
                targetProviders,
                accessTokens,
              });
            }
          } else {
            const appKey = AuthService.getCurrentAppKey();

            if (appKey) {
              UserInfoService.setUserInfo(appKey, undefined);
            }
          }
        },
        'useUserInfo'
      )
    );
  }

  public static setUserInfo(
    appKey: string,
    userInfo: IUserProfileInfo | undefined
  ): void {
    this._userInfoMap[appKey] = userInfo;

    this.notifyUserInfoChanged(appKey);
  }

  private static notifyUserInfoChanged(appKey: string) {
    const userInfo = this._userInfoMap[appKey] || undefined;
    this._dispatch('UserInfoChanged', { appKey, userInfo });
  }

  public static async loadSavedUserInfo(
    props: UserInfoServiceProps
  ): Promise<IUserProfileInfo | undefined> {
    const appKey = props.appKey;
    let userInfo = this._userInfoMap[appKey];

    if (!userInfo) {
      try {
        userInfo = await this.fetchUser({ ...props, getFromCache: true });
        this._userInfoMap[appKey] = userInfo;
      } catch {}
    }

    return userInfo;
  }

  public static clearUserInfo(notify?: boolean): void {
    Object.keys(this._userInfoMap).forEach((appKey) => {
      this._userInfoMap[appKey] = undefined;
      if (notify) {
        this.notifyUserInfoChanged(appKey);
      }
    });
  }

  public static async refreshUserInfo(
    props: UserInfoServiceProps
  ): Promise<IUserProfileInfo | undefined> {
    try {
      const cachedUserInfo = this._userInfoMap[props.appKey];

      const fetchedUserInfo = await this.fetchUser({
        ...props,
        getFromCache: false,
      });

      const isModified =
        !cachedUserInfo ||
        (cachedUserInfo && !isEqual(fetchedUserInfo, cachedUserInfo));

      if (isModified) {
        this._userInfoMap[props.appKey] = fetchedUserInfo;
        this.notifyUserInfoChanged(props.appKey);
      }

      return fetchedUserInfo;
    } catch {}
  }

  private static async fetchUser(
    props: UserInfoServiceProps & {
      getFromCache: boolean;
    }
  ): Promise<IUserProfileInfo | undefined> {
    const appKey = props.appKey;
    const token = props.accessTokens[SUBSPLASH_AUTH_PROVIDER_ID] ?? '';
    const targetProviders = props.targetProviders;

    if (!token || !appKey || targetProviders.length === 0) {
      return;
    }

    const response = await GetUserProfile({
      appKey: props.appKey,
      token: props.accessTokens[SUBSPLASH_AUTH_PROVIDER_ID] ?? '',
      getFromCache: props.getFromCache,
    });

    return await this.fetchAdjustedUserInfo({
      ...props,
      baseUserInfo: response.body,
    });
  }

  private static async fetchAdjustedUserInfo(
    props: UserInfoServiceProps & {
      getFromCache: boolean | undefined;
      baseUserInfo?: IUserProfileInfo;
    }
  ): Promise<IUserProfileInfo | undefined> {
    const {
      appKey,
      baseUserInfo,
      accessTokens,
      getFromCache,
      targetProviders,
    } = props;

    if (baseUserInfo) {
      let customName: string | undefined;
      let customInitials: string | undefined;
      let customImage: string | undefined;

      // Enterprise clients may customize the initials, e.g. 5289PV
      if (baseUserInfo.customProfileHeaderUrl && appKey) {
        const url = baseUserInfo.customProfileHeaderUrl;
        try {
          const authorizedProvider = getAuthorizedProvider({
            providers: targetProviders,
            url,
          });

          if (!authorizedProvider) {
            // do not log this error to sentry to avoid error noise
            throw `Custom Profile Header: No authorized provider for ${url}`;
          }

          const token = accessTokens?.[authorizedProvider.authproviderid];

          if (!token) {
            const error = `Custom Profile Header: No token for ${url}`;
            HealthMonitor.logError(new Error(error));

            throw error;
          }

          const customProfileHeader = await GetCustomProfileHeader({
            url,
            token,
            getFromCache,
          });

          customName = customProfileHeader?.body?.title;
          customInitials = customProfileHeader?.body?.subtitle;
          customImage = customProfileHeader?.body?.image;
        } catch (error) {
          debug(error);
        }
      }

      const adjustedUserInfo: IUserProfileInfo = {
        ...baseUserInfo,
        ...(customName && { fullName: customName }),
        ...(customInitials && { initials: customInitials }),
        ...(customImage && { image: customImage }),
      };

      return adjustedUserInfo;
    }
  }

  private static listeners: {
    [event: string]: Array<ListenerObject>;
  } = {};

  public static addListener(
    appKey: string,
    event: string,
    fn: (data: {
      appKey: string;
      userInfo: IUserProfileInfo | undefined;
    }) => void,
    context?: string
  ): () => void {
    if (!context) {
      context = 'unknown';
    }

    const listenerObject = { appKey, context: context, callback: fn };

    this.listeners[event]
      ? this.listeners[event].push(listenerObject)
      : (this.listeners[event] = [listenerObject]);

    return () => {
      const listenerIdx = this.listeners[event].findIndex(
        (registeredObj) => registeredObj.callback === fn
      );

      if (listenerIdx >= 0) {
        this.listeners[event].splice(listenerIdx, 1 /* delete count */);
      }
    };
  }

  private static _dispatch(
    event: string,
    data: { appKey: string; userInfo: IUserProfileInfo | undefined }
  ): void {
    if (!this.listeners[event]) {
      return;
    }

    for (const listenerObj of this.listeners[event]) {
      if (data.appKey === listenerObj.appKey) {
        listenerObj.callback(data);
      }
    }
  }
}
