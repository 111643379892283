export default (phoneNumber: string | undefined): string | undefined => {
  const sanitized = phoneNumber?.replace(/\D/g, '');

  switch (sanitized?.length) {
    case 10:
      return sanitized.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    case 11:
      return sanitized.replace(
        /(\d{1})(\d{3})(\d{3})(\d{4})/,
        '+$1 ($2) $3-$4'
      );

    default:
      return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
};

export const withCountryCode = (
  phoneNumber: string | undefined
): string | undefined => {
  // Add a 1 to the beginning of the phone number (assuming a US number) if it's not already there
  if (phoneNumber && phoneNumber.length === 10) {
    return `1${phoneNumber}`;
  }

  return phoneNumber;
};
