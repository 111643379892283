import { IChatSession, prepareUrl } from '..';
import BaseServiceV2, { IHttpResponse, IUri } from '../../BaseServiceV2';

const RESOURCE = 'chat-sessions';

interface ICreateSession extends Omit<IUri, 'resource'> {
  appKey: string;
  token?: string;
}

export const REFRESH_ERROR_MESSAGE =
  'Access token expired, waiting for token to be refreshed..';

export default async function createChatSession({
  appKey,
  token,
  ...buildUrlProps
}: ICreateSession): Promise<IChatSession> {
  if (!token) {
    throw new Error('No access token provided');
  }

  const url = prepareUrl({
    ...buildUrlProps,
    resource: RESOURCE,
  });

  const headers: Record<string, string> = {
    'sap-active-appkey': appKey,
  };

  const response = await BaseServiceV2.Post({
    url,
    appKey,
    headers,
    token,
  });

  return handleResponse(response);
}

function handleResponse(response: IHttpResponse): Promise<IChatSession> {
  if (response?.status && response?.status >= 200 && response?.status < 400) {
    return Promise.resolve(response.body);
  }

  return Promise.reject(
    new Error(`Failed to create a chat-session: ${response?.status}`)
  );
}
