import { BridgeAction } from '@omni/kit/Types';

export const isBlockPageContentHandler = (
  action: BridgeAction | null | undefined
): boolean => {
  if (action?.handler === 'blockPage') {
    const actionUrl =
      action && action.hasOwnProperty('url') ? action.url : undefined;
    const actionType =
      action && action.hasOwnProperty('type') ? action.type : undefined;

    return actionUrl && actionType === 'content';
  }

  return false;
};

export const getActionUrl = (
  action: BridgeAction | null | undefined
): string => {
  return action && action.hasOwnProperty('url') ? action.url : '';
};
