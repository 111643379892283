import Environment from '@omni/kit/Environment';
import { Platform } from 'react-native';

import { SystemActionTypes } from '../actions/SystemActions';
import { ISystemState } from '../types';

// const debug = require('debug')('tca:chat:redux:SystemReducer');

export const chatInitialSystemState: ISystemState = {
  user: null,
  userId: undefined,
  channel: null,
  canCreateDirectMessage: false,
  canCreateChannels: false,
  userToken: '',
  accessToken: '',
  environment: Environment.chatService,
  systemError: '',
  userList: [],
  channelsList: null,
  publicChannels: [],
  deletedChannel: '',
  isLoading: false,
  appKey: '',
  orgKey: '',
  appId: '',
  appTitle: 'Subsplash Messaging',
  brandColor: '',
  pushPreference: 'default',
  viewIsReady: false,
  appBranding: {
    // @ts-ignore
    branding: null,
    // @ts-ignore
    logo: null,
  },
  profileImageUrl: '',
};

const SystemReducer = (
  state = chatInitialSystemState,
  action: { type: string; payload: any }
) => {
  if (!SystemActionTypes) return { ...state };

  switch (action.type) {
    case SystemActionTypes.INIT_STATE:
      const { appKey, brandColor } = action.payload;

      return { ...state, appKey, brandColor };
    case SystemActionTypes.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case SystemActionTypes.RESET:
      return {
        ...state,
        accessToken: '',
        user: null,
        userId: undefined,
        userToken: '',
        viewIsReady: false,
        channel: null,
        canCreateDirectMessage: false,
        canCreateChannels: false,
        systemError: '',
        userList: [],
        channelsList: null,
        publicChannels: [],
        deletedChannel: '',
        isLoading: false,
        appKey: '',
        orgKey: '',
        appId: '',
        brandColor: '',
        appBranding: {
          // @ts-ignore
          branding: null,
          // @ts-ignore
          logo: null,
        },
        profileImageUrl: '',
      };
    case SystemActionTypes.LOGOUT:
      return {
        ...state,
        accessToken: '',
        user: null,
        userId: undefined,
        userToken: '',
        viewIsReady: false,
        channel: null,
        canCreateDirectMessage: false,
        canCreateChannels: false,
        systemError: '',
        userList: [],
        channelsList: null,
        publicChannels: [],
        deletedChannel: '',
        isLoading: false,
        profileImageUrl: '',
      };
    case SystemActionTypes.RESOLVE_NATIVE_AUTH_SUCCESS:
      const { userId, canCreateDirectMessage, canCreateChannels, userToken } =
        action.payload;

      return {
        ...state,
        userId,
        canCreateDirectMessage,
        canCreateChannels,
        userToken,
      };
    case SystemActionTypes.RESOLVE_NATIVE_AUTH_ERROR:
      return { ...state, systemError: action.payload };
    case SystemActionTypes.SET_APP_ID:
      return { ...state, appId: action.payload };
    case SystemActionTypes.SET_APP_TITLE:
      return { ...state, appTitle: action.payload };
    case SystemActionTypes.LOGIN_SUCCESS:
      return { ...state, user: action.payload };
    case SystemActionTypes.LOGIN_FAIL:
      return { ...state, systemError: action.payload };
    case SystemActionTypes.SET_CURRENT_CHANNEL:
      return { ...state, channel: action.payload };
    case SystemActionTypes.GROUP_CHANNEL_PROGRESS_START:
      return { ...state, isLoading: true };
    case SystemActionTypes.GROUP_CHANNEL_PROGRESS_END:
      return { ...state, isLoading: false };
    case SystemActionTypes.UPDATE_PUBLIC_CHANNEL_LIST:
      return {
        ...state,
        publicChannels: [...action.payload],
      };
    case SystemActionTypes.UPDATE_CHANNEL_LIST:
      return {
        ...state,
        isLoading: false,
        channelsList: action.payload.append
          ? [...(state.channelsList || []), ...action.payload.channelsList]
          : [...action.payload.channelsList],
      };
    case SystemActionTypes.GROUP_CHANNEL_LIST_FAIL:
      return { ...state, isLoading: false };
    case SystemActionTypes.CLEAR_SELECTED_GROUP_CHANNEL:
      return { ...state, channel: null };
    case SystemActionTypes.UPDATE_USER_LIST:
      return { ...state, userList: action.payload };
    case SystemActionTypes.UPDATE_PUSH_PREF:
      return { ...state, pushPreference: action.payload };
    case SystemActionTypes.SET_DELETED_CHANNEL:
      return { ...state, deletedChannel: action.payload };
    case SystemActionTypes.UPDATE_USER:
      return { ...state, user: action.payload };
    case SystemActionTypes.SET_USER_ID:
      return { ...state, userId: action.payload };
    case SystemActionTypes.SET_VIEW_READY:
      return { ...state, viewIsReady: action.payload };
    case SystemActionTypes.SET_APP_BRANDING:
      return { ...state, appBranding: action.payload };
    case SystemActionTypes.SET_APP_KEY:
      return { ...state, appKey: action.payload };
    case SystemActionTypes.SET_ORG_KEY:
      return { ...state, orgKey: action.payload };
    case SystemActionTypes.SET_PROFILE_IMAGE_URL:
      return { ...state, profileImageUrl: action.payload };
    default:
      return state;
  }
};

export default SystemReducer;
