import { dispatch } from '@omni/kit/ActionHandler';
import KitList from '@omni/kit/components/KitList';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType, getSpacing } from '@omni/kit/theming/SpacingType';
import { ColorValueKey, ThemeContext } from '@omni/kit/theming/ThemeContext';
import { useCoalescedSize } from '@omni/kit/utilities/utilities';
import React, { useContext, useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import { useBlockPageContext } from '../BlockPageContext';
import GridItem from '../components/List/GridItem';
import MoreItem from '../components/List/MoreItem';
import RowItem from '../components/List/RowItem';
import {
  IBlockProps,
  ItemAlignment,
  ItemTextPosition,
  ListBlockStyle,
  RadiusStyle,
} from './types';

const debug = require('debug')('tca:blocks:blocks:ListBlock');

export enum ListItemStyle {
  Square = 'square',
  Wide = 'wide',
  Tall = 'tall',
  StackedWide = 'stacked-wide',
  StackedBanner = 'stacked-banner',
}

// These props are publicly available
export interface IListBlockProps extends IBlockProps {
  /**
   * Array of items. Keys for item:
   *
   * title
   *
   * subtitle
   *
   * action: {handler: string, url: string}
   *
   * image: a string url on which the app will replace {width} and {height} (if present) with whatever size is needed
   *
   * startDate: an ISO-8601 formatted timestamp string
   * The app will render the date as an overlay in the bottom right corner of the *image*. Also the app will render the date along with the subtitle in rows.
   *
   * endDate: an ISO-8601 formatted timestamp string
   * Used in combination with startDate. Used to render date range string in list row subtitles.
   *
   * allDay: a boolean, used in combination with startDate + endDate. Avoids rendering the 'time' element of a date range.
   *
   * timeZone: an IANA timezone string used for rendering the date according to a specific timezone, for use with all-day events.
   * (if excluded, the app will render the date in the end user's locale)
   *
   * position: a short string, up to 3 characters.
   * The app will render this string in the absence of *image* and *date*.
   *
   * @type dictionary[]
   */
  data?: IListBlockItem[];

  /**
   * Keys: `title, action`
   * @type dictionary
   */
  more?: IListBlockMoreItem;

  listStyle: ListBlockStyle;

  itemStyle: ListItemStyle;

  /**
   * If `undefined`, this is determined based on presets for `itemStyle`.
   * e.g. 1.0 = square, 1.78 = wide, 0.5625 = tall
   */
  aspectRatio?: number;

  /** When `true`, in list style 'rows', a chevron indicator will be visible on the right side of each item. */
  chevronEnabled?: boolean;

  /**
   * line items at the edge the screen
   */
  edgeRendering?: boolean;

  /** When `true`, the list items are rendered horizontally instead of vertically. */
  horizontal: boolean;

  /** When `true`, in horizontal lists, items become full width of viewport and scrolling automatically stops on edge of each item. */
  pagingEnabled?: boolean;

  /**
   * If `undefined`, this is determined based on presets for `listStyle`, `itemStyle` and current viewport width.
   */
  numColumns?: number;

  /**
   * Number of items visible at once for horizontal grid
   */
  numItemsVisible?: number;

  /**
   * Controls whether shadows are rendered behind the item's image/indicator.
   * This will be suppressed when items have no horizontal margin in a grid,
   * since rendering shadows does not make sense if there is no affordance between the grid items.
   */
  shadowsEnabled: boolean;

  indicatorRadius: RadiusStyle;

  /**
   * Controls horizontal alignment of `title` and `subtitle` in listStyle 'grid'.
   * This does not affect listStyle: `rows`.
   */
  textAlignmentHorizontal: ItemAlignment;

  /**
   * Controls vertical alignment of `title` and `subtitle` in listStyle 'grid'.
   * This does not affect listStyle: `rows`.
   */
  textAlignmentVertical: ItemAlignment;

  /**
   * Controls whether the `title` and `subtitle` is rendered overlay on top of the `grid` item image/indicator or below it.
   * This does not affect listStyle: `rows`.
   */
  textPosition: ItemTextPosition;
}

interface IListBlockItem {
  title?: string;
  subtitle?: string;
  titleTextColor?: string;
  subtitleTextColor?: string;
  image?: string;
  action?: any;
  averageColor?: string;
}

interface IListBlockMoreItem {
  title?: string;
  action?: any;
}

export default function ListBlock({
  aspectRatio,
  bottomSpacing = SpacingType.None,
  chevronEnabled = Platform.OS === 'ios',
  data = [],
  edgeRendering,
  horizontal = false,
  horizontalSpacing,
  insetStyle = {},
  itemStyle = ListItemStyle.Square,
  listStyle = ListBlockStyle.Rows,
  more,
  numColumns,
  numItemsVisible = 2.75,
  pagingEnabled = false,
  shadowsEnabled = true,
  topSpacing = SpacingType.None,
  indicatorRadius = listStyle === ListBlockStyle.Rows
    ? RadiusStyle.Small
    : RadiusStyle.Medium,
  textPosition = ItemTextPosition.Below,
  textAlignmentHorizontal = textPosition === ItemTextPosition.Below
    ? ItemAlignment.Start
    : ItemAlignment.Center,
  textAlignmentVertical = ItemAlignment.Center,
}: IListBlockProps): JSX.Element {
  if (more && data[data.length - 1] !== more) data.push(more);

  const { colorForScheme } = useContext(ThemeContext);

  const { dispatchAction } = useBlockPageContext();

  const viewStyle = {
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
    ...insetStyle,
  };

  const [enableEdgeRendering, setEnableEdgeRendering] = useState(edgeRendering);
  const [gridItemHeight, setGridItemHeight] = useState(0);
  const [gridItemWidth, setGridItemWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(55);
  const [imageWidth, setImageWidth] = useState(55);
  const [initialLayoutComplete, setInitialLayoutComplete] = useState(false);
  const [resolvedAspectRatio, setResolvedAspectRatio] = useState(aspectRatio);
  const [resolvedNumColumns, setResolvedNumColumns] = useState(numColumns);

  const { edgeSpacing, interItemSpacing, viewPortWidth } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const {
    coalescedWidth: coalescedImageWidth,
    coalescedHeight: coalescedImageHeight,
  } = useCoalescedSize(imageWidth, imageHeight);

  const { sizeClass } = useSizeClass();

  useEffect(() => {
    setEnableEdgeRendering(edgeRendering && sizeClass === SizeClass.Small);
  }, [edgeRendering, sizeClass]);

  useEffect(() => {
    if (viewPortWidth > 0) {
      _updateLayout();
    }
  }, [viewPortWidth, interItemSpacing, edgeSpacing]);

  const _updateLayout = (): void => {
    const curNumColumns = _updateNumColumns();
    const curAspectRatio = _updateAspectRatio();
    const imageSize = _updateImageSize(curAspectRatio, curNumColumns);

    setGridItemWidth(imageSize.width);
    setGridItemHeight(imageSize.height);

    if (!initialLayoutComplete) {
      setInitialLayoutComplete(true);
    }
  };

  const _updateNumColumns = (): number | undefined => {
    let curNumColumns = numColumns;

    if (horizontal) {
      curNumColumns = undefined; // numColumns is not allowed in react native for horizontal lists
    } else {
      if (curNumColumns === undefined) {
        const isLargeScreen = viewPortWidth >= 768;
        curNumColumns = isLargeScreen ? 3 : 2; // default: grid + square|wide|tall

        switch (listStyle) {
          case ListBlockStyle.Rows:
            if (horizontalSpacing === SpacingType.None) {
              curNumColumns = isLargeScreen ? 2 : 1;
            } else {
              curNumColumns = 1;
            }

            break;
          case ListBlockStyle.Grid:
            switch (itemStyle) {
              case ListItemStyle.StackedWide:
                curNumColumns = isLargeScreen ? 2 : 1;
                break;
              case ListItemStyle.StackedBanner:
                curNumColumns = 1;
                break;
            }
            break;
        }
      }
    }

    setResolvedNumColumns(curNumColumns);

    return curNumColumns;
  };

  const _updateAspectRatio = (): number => {
    let curAspectRatio = resolvedAspectRatio;

    if (curAspectRatio === undefined) {
      curAspectRatio = 1.0; // default: square
      switch (itemStyle) {
        case ListItemStyle.Wide:
          curAspectRatio = 1.78;
          break;
        case ListItemStyle.Tall:
          curAspectRatio = 0.5625;
          break;
        case ListItemStyle.StackedWide:
          curAspectRatio = 1.78;
          break;
        case ListItemStyle.StackedBanner:
          curAspectRatio = 2.76;
          break;
      }
    }

    setResolvedAspectRatio(curAspectRatio);

    return curAspectRatio;
  };

  const _updateImageSize = (
    curAspectRatio: number,
    curNumColumns?: number
  ): { height: number; width: number } => {
    let curImageWidth = 55; // rows + square
    let curImageHeight = 55;

    if (listStyle === ListBlockStyle.Rows) {
      curImageWidth = curImageWidth * curAspectRatio;
    } else if (horizontal) {
      if (pagingEnabled) {
        const availableWidth = viewPortWidth - edgeSpacing * 2;
        curImageWidth = availableWidth;
        curImageHeight = curImageWidth / curAspectRatio;
      } else {
        curImageWidth = Math.min(viewPortWidth / numItemsVisible, 250);
        curImageHeight = curImageWidth / curAspectRatio;
      }
    } else if (curNumColumns !== undefined) {
      // vertical grid
      const availableWidth =
        viewPortWidth -
        edgeSpacing * 2 -
        interItemSpacing * (curNumColumns - 1);
      curImageWidth = availableWidth / curNumColumns;
      curImageHeight = curImageWidth / curAspectRatio;
    }

    setImageWidth(curImageWidth);
    setImageHeight(curImageHeight);

    return { height: curImageHeight, width: curImageWidth };
  };

  const onListItemPressed = (item: IListBlockItem, _index: number): void => {
    dispatchAction(item.action);
  };

  const _renderGridItem = ({
    item,
    index,
  }: {
    item: IListBlockItem;
    index: number;
  }): JSX.Element => {
    let paddingLeft = 0;
    let paddingRight = 0;
    const isLargeScreen = viewPortWidth >= 768;

    if (resolvedNumColumns && resolvedNumColumns > 0) {
      paddingLeft = index % resolvedNumColumns === 0 ? 0 : interItemSpacing / 2;
      paddingRight =
        index % resolvedNumColumns === resolvedNumColumns - 1
          ? 0
          : interItemSpacing / 2;
    } else if (horizontal) {
      paddingLeft = pagingEnabled || index === 0 ? 0 : interItemSpacing / 2;

      if (pagingEnabled || index === data.length - 1) {
        if (!isLargeScreen && edgeRendering) {
          paddingRight = interItemSpacing;
        }
      } else {
        paddingRight = interItemSpacing / 2;
      }
    }

    const paddingBottom =
      horizontal || textPosition === ItemTextPosition.Below
        ? 0
        : interItemSpacing;

    const itemStyle = {
      marginLeft:
        enableEdgeRendering && horizontal && index === 0 ? edgeSpacing : 0,
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      paddingBottom: paddingBottom,
    };

    if (more && index === data.length - 1) {
      return (
        <MoreItem
          style={itemStyle}
          data={item}
          listStyle={listStyle}
          onPress={() => {
            onListItemPressed(item, index);
          }}
          aspectRatio={resolvedAspectRatio}
          fixedWidthEnabled={horizontal}
          width={gridItemWidth}
          height={gridItemHeight}
        />
      );
    }

    return (
      <GridItem
        style={itemStyle}
        data={item}
        onPress={() => {
          onListItemPressed(item, index);
        }}
        aspectRatio={resolvedAspectRatio}
        averageColor={item.averageColor}
        fixedWidthEnabled={horizontal}
        width={gridItemWidth}
        height={gridItemHeight}
        imageWidth={coalescedImageWidth}
        imageHeight={coalescedImageHeight}
        imageRadius={indicatorRadius}
        shadowEnabled={
          shadowsEnabled &&
          (interItemSpacing > 0 || listStyle === ListBlockStyle.Rows)
        }
        titleTextColor={item.titleTextColor}
        subtitleTextColor={item.subtitleTextColor}
        textPosition={textPosition}
        textAlignmentHorizontal={textAlignmentHorizontal}
        textAlignmentVertical={textAlignmentVertical}
      />
    );
  };

  const _renderRowItem = ({
    item,
    index,
  }: {
    item: IListBlockItem;
    index: number;
  }): JSX.Element => {
    if (more && index === data.length - 1) {
      return (
        <MoreItem
          data={item}
          listStyle={listStyle}
          onPress={() => {
            onListItemPressed(item, index);
          }}
        />
      );
    }

    const separatorEnabledOnAllEdges =
      horizontal || (resolvedNumColumns && resolvedNumColumns > 1);

    return (
      <View>
        <View
          style={[
            styles().row,
            separatorEnabledOnAllEdges
              ? styles(colorForScheme).rowSeparatorOnAllEdges
              : styles(colorForScheme).rowSeparatorOnBottom,
            separatorEnabledOnAllEdges
              ? {
                  left:
                    resolvedNumColumns &&
                    resolvedNumColumns > 0 &&
                    index % resolvedNumColumns === 0
                      ? -1
                      : 0,
                }
              : null,
          ]}
        />
        <RowItem
          data={item}
          onPress={() => {
            onListItemPressed(item, index);
          }}
          chevronEnabled={chevronEnabled}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          imageRadius={indicatorRadius}
          marginHorizontal={separatorEnabledOnAllEdges ? Spacing.l : 0}
          marginVertical={Spacing.l}
        />
      </View>
    );
  };

  if (!initialLayoutComplete) return <View style={viewStyle} />;

  return (
    <View style={viewStyle}>
      <KitList
        style={
          !(enableEdgeRendering && horizontal) && {
            marginStart: edgeSpacing,
            marginEnd: edgeSpacing,
          }
        }
        data={data}
        scrollEnabled={horizontal}
        horizontal={horizontal}
        pagingEnabled={horizontal && pagingEnabled}
        pageWidth={horizontal && pagingEnabled ? viewPortWidth : 0}
        numColumns={resolvedNumColumns}
        key={resolvedNumColumns} // 'key' is required in order to change column count on the fly as viewport size changes
        keyExtractor={(_, index) => index.toString()}
        showsHorizontalScrollIndicator={false}
        renderItem={
          listStyle === ListBlockStyle.Grid ? _renderGridItem : _renderRowItem
        }
      />
    </View>
  );
}

//******************************************************************************
//
//******************************************************************************

const styles = (
  colorForScheme?: (
    colorValues: Partial<Record<ColorValueKey, string>>
  ) => string | undefined
) => {
  const borderColor = colorForScheme
    ? colorForScheme({ dark: Colors.N900, light: Colors.N100 })
    : Colors.N100;

  return StyleSheet.create({
    row: {
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    rowSeparatorOnAllEdges: {
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      borderLeftColor: borderColor,
      borderLeftWidth: 1,
      borderRightColor: borderColor,
      borderRightWidth: 1,
      borderTopColor: borderColor,
      borderTopWidth: 1,
      bottom: -1,
      right: -1,
    },
    rowSeparatorOnBottom: {
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
    },
  });
};
