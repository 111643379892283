import { useShellContext } from '@omni/kit/contexts/ShellContext';
import { useMemo, useState } from 'react';

import { PlatformAgnosticFile } from '../../FileTypes';
import { uploadToImageService } from './imageUploadUtilities';
import { ImageUploadProps, ImageUploadStatus } from './types';

/**
 * A hook that wraps the utility function 'uploadToImageService'
 * This is used as a convenience to vend the status of the upload to the functional component.
 * If the status props are not used, the caller may execute the utility function directly.
 *
 * @param endUserId a valid end-user id is required for the upload to succed
 * @returns
 */
export default function useImageUpload(
  appKey: string,
  endUserId?: string
): ImageUploadProps {
  const { tokens } = useShellContext();
  const token = useMemo(() => tokens.user, [tokens.user]);

  const [progress, setProgress] = useState(0); // decimal progress from 0 to 1
  const [status, setStatus] = useState(ImageUploadStatus.NotStarted);

  return {
    uploadImage: (
      imageObject: PlatformAgnosticFile,
      imageType: string
    ): Promise<{ id: string; url: string }> => {
      return uploadToImageService(
        appKey,
        imageObject,
        imageType,
        endUserId,
        token,
        setProgress,
        setStatus
      );
    },
    progress,
    status,
  };
}
