import CreateHousehold from './CreateHousehold';
import CreateHouseholdRelationship from './CreateHouseholdRelationship';
import CreateProfile from './CreateProfile';
import DeleteHouseholdRelationship from './DeleteHouseholdRelationship';
import GetHousehold from './GetHousehold';
import GetHouseholdRelationship from './GetHouseholdRelationship';
import GetHouseholds from './GetHouseholds';
import GetProfile from './GetProfile';
import GetProfiles from './GetProfiles';
import ProfileQuery from './ProfileQuery';
import UpdateProfile from './UpdateProfile';

export default {
  GetHouseholds,
  GetHousehold,
  CreateHousehold,
  CreateHouseholdRelationship,
  DeleteHouseholdRelationship,
  GetHouseholdRelationship,
  GetProfile,
  GetProfiles,
  CreateProfile,
  UpdateProfile,
  ProfileQuery,
};
