import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useMemo } from 'react';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';

import Colors from '@omni/kit/theming/Colors';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitText from '@omni/kit/components/KitText';
import { SendbirdSenderMessage } from '../../Types';
import Spacing from '@omni/kit/theming/Spacing';
import { User } from '@sendbird/chat';
import moment from 'moment';

interface ThreadSummaryProps {
  message: SendbirdSenderMessage;
  onPress: () => void;
  disabled?: boolean;
}

export default function ThreadSummary({
  message,
  onPress,
  disabled,
}: ThreadSummaryProps): JSX.Element | null {
  const { sizeClass } = useSizeClass();

  if (!message || !message.threadInfo) return null;

  const {
    replyCount,
    lastRepliedAt,
    mostRepliedUsers = [],
  } = message.threadInfo;
  const maxDisplayUsers = 4;

  const replyAvatars = useMemo(() => {
    const users = mostRepliedUsers.slice(0, maxDisplayUsers);

    if (mostRepliedUsers.length > maxDisplayUsers) {
      users.push({ profileUrl: '', userId: '', nickname: '+' } as User);
    }

    return users.map((user) => (
      <View style={styles.replyAvatarContainer} key={user.userId}>
        <KitAvatar
          size={24}
          imageUrl={user.profileUrl}
          nickname={user.nickname}
        />
      </View>
    ));
  }, [mostRepliedUsers]);

  return (
    <TouchableOpacity
      style={[styles.wrapper]}
      disabled={disabled}
      onPress={onPress}
    >
      {replyAvatars}
      <KitText brandColor fontSize={12} style={styles.replyCount} semiBold>
        {replyCount === 1 ? `${replyCount} reply` : `${replyCount} replies`}
      </KitText>
      {Platform.OS === 'web' && sizeClass !== SizeClass.Small && (
        <KitText fontSize={12} style={styles.replyCount} semiBold gray>
          {`Last reply ${moment(lastRepliedAt).calendar(null, {
            sameDay: '[today at] h:mm A', // today at 8:40 AM
            lastDay: '[yesterday at] h:mm A', // yesterday at 8:40 AM
            lastWeek: 'dddd [at] h:mm A', // Friday at 8:40 AM
            sameElse: 'MMMM Mo [at] h:mm A', // January 1st at 8:40 AM
          })}`}
        </KitText>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  replyAvatarContainer: {
    backgroundColor: Colors.N0,
    borderRadius: 100,
    marginRight: -Spacing.s,
    padding: Spacing.xxs,
  },
  replyCount: {
    marginLeft: Spacing.m,
  },
  wrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: Spacing.xs,
  },
});
