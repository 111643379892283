import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { CSSProperties } from 'react';
import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import Environment from '../../Environment';
import Colors from '../../theming/Colors';

interface MarkerProps {
  title?: string;
}
interface CircleProps {
  radius?: number;
  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  fillColor?: string;
  fillOpacity?: number;
}
interface WebMapViewProps {
  latitude: number;
  longitude: number;
  zoom?: number;
  marker?: MarkerProps;
  circle?: CircleProps;
  webStyle?: CSSProperties;
}

const WebMapView: React.FC<WebMapViewProps> = ({
  latitude,
  longitude,
  zoom = 11,
  marker,
  circle,
  webStyle,
}) => {
  const mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const circleOptions: google.maps.CircleOptions = {
    strokeColor: circle?.strokeColor || Colors.N1000,
    strokeOpacity: circle?.strokeOpacity,
    strokeWeight: circle?.strokeWeight,
    fillColor: circle?.fillColor || 'rgba(0, 0, 0, 0.2)',
    fillOpacity: circle?.fillOpacity,
  };

  const center: google.maps.LatLngLiteral = {
    lat: latitude,
    lng: longitude,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Environment.googleMapsKey,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={webStyle || styles.mapContainer}
      center={center}
      zoom={zoom}
      options={mapOptions}
    >
      {marker && <Marker position={center} title={marker.title} />}
      {circle && (
        <Circle
          center={center}
          radius={circle.radius || 3218.68}
          options={circleOptions}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  mapContainer: {
    width: '100%',
    height: '40%',
  },
});

export default WebMapView;
