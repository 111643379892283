import React from 'react';
import { View, ViewStyle } from 'react-native';

import { KitIcon, KitText, KitTouchable } from '..';
import { useScreenContext } from '../../contexts/ScreenContext';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { SpacingType } from '../../theming/SpacingType';
import { defaultStyles } from './styles';

export interface KitFrameProps {
  title?: string;
  enableChevron?: boolean;
  onPress?: () => void;
  children?: React.ReactChild;
  insetStyle?: ViewStyle;

  /** When undefined, defaults are applied based on display width. */
  horizontalSpacing?: SpacingType;
}

const KitFrame = ({
  title,
  insetStyle,
  enableChevron,
  onPress,
  children,
  horizontalSpacing,
}: KitFrameProps): JSX.Element => {
  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  return (
    <View
      style={[
        defaultStyles.container,
        insetStyle,
        { marginHorizontal: edgeSpacing },
      ]}
    >
      {(title || enableChevron) && (
        <View
          style={{
            marginHorizontal: Spacing.l,
            marginTop: Spacing.l,
            marginBottom: Spacing.xs,
          }}
        >
          {onPress ? (
            <KitTouchable onPress={onPress}>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {title && (
                  <KitText bold color={Colors.N900} fontSize={14}>
                    {title}
                  </KitText>
                )}
                {enableChevron && (
                  <View>
                    <KitIcon name='arrow-right' size={16} />
                  </View>
                )}
              </View>
            </KitTouchable>
          ) : (
            <View>
              {title && (
                <KitText bold color={Colors.N900} fontSize={14}>
                  {title}
                </KitText>
              )}
            </View>
          )}
        </View>
      )}
      {children}
    </View>
  );
};

export default KitFrame;
