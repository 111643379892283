import { KitText } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitIcon from '@omni/kit/components/KitIcon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { User } from '@sendbird/chat';
import React, { Component } from 'react';
import {
  LayoutAnimation,
  Platform,
  StyleSheet,
  TouchableHighlight,
  View,
} from 'react-native';

import { IInvite, InviteType } from '../../../shared/redux/types';

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  disabled?: boolean;
  invite: IInvite;
  onDeletePress: (invite: IInvite) => void;
  title: string;
}

export interface IState {
  showDelete: boolean;
}

class KitChip extends Component<IProps, IState> {
  //****************************************************************************
  // State
  //****************************************************************************

  state: IState = {
    showDelete: false,
  };

  //****************************************************************************
  // Methods
  //****************************************************************************

  private _onPress = () => {
    LayoutAnimation.configureNext(
      LayoutAnimation.create(
        125,
        LayoutAnimation.Types.linear,
        LayoutAnimation.Properties.opacity
      )
    );

    this.setState({ showDelete: !this.state.showDelete });
  };

  private _renderAvatar = (invite: IInvite): JSX.Element | null => {
    if (this.state.showDelete) return null;

    if (invite.type === InviteType.User) {
      const user: User = invite.data;

      return (
        <KitAvatar
          size={22}
          nickname={user.nickname}
          imageUrl={user.profileUrl}
        />
      );
    }

    return (
      <View style={[styles.image, styles.avatar]}>
        <KitIcon
          name='add-s'
          color={colorForScheme({ default: Colors.N0 })}
          size={10}
        />
      </View>
    );
  };

  private _renderDelete = (): JSX.Element | null => {
    if (!this.state.showDelete) return null;

    return (
      <TouchableHighlight
        style={[
          styles.delete,
          { backgroundColor: colorForScheme({ default: Colors.N500 }) },
        ]}
        onPress={() => this.props.onDeletePress(this.props.invite)}
        underlayColor='#000'
        testID='delete-chip-button'
      >
        <KitIcon
          name='remove-s'
          color={colorForScheme({ default: Colors.N0 })}
          size={10}
        />
      </TouchableHighlight>
    );
  };

  render(): JSX.Element {
    const { disabled, invite } = this.props;
    const title = invite.type === InviteType.User ? invite.title : invite.data;

    return (
      <TouchableHighlight
        disabled={disabled}
        onPress={this._onPress}
        style={styles.chipContainer}
        testID='kit-chip'
        underlayColor='#000'
      >
        <View
          style={[
            styles.button,
            {
              backgroundColor:
                invite.type === InviteType.User
                  ? colorForScheme({ default: Colors.brand })
                  : colorForScheme({ default: Colors.N0 }),
            },
          ]}
        >
          {this._renderAvatar(invite)}
          <View style={styles.name}>
            <KitText
              fontSize={14}
              color={
                invite.type === InviteType.User
                  ? colorForScheme({ default: Colors.N0 })
                  : colorForScheme({ default: Colors.N900 })
              }
            >
              {title}
            </KitText>
          </View>
          {this._renderDelete()}
        </View>
      </TouchableHighlight>
    );
  }
}
export default KitChip;

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  avatar: {
    alignItems: 'center',
    backgroundColor: colorForScheme({ default: Colors.N500 }),
    justifyContent: 'center',
  },
  button: {
    alignItems: 'center',
    backgroundColor: colorForScheme({ default: Colors.brand }),
    borderRadius: 27,
    flexDirection: 'row',
    height: 34,
    justifyContent: 'center',
    paddingHorizontal: Spacing.s,
    ...Platform.select({
      android: {
        elevation: 3,
        overflow: 'hidden',
      },
      ios: {
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.15,
        shadowRadius: 5,
      },
    }),
  },
  chipContainer: {
    borderRadius: 17,
    marginBottom: 4,
    marginRight: 4,
  },
  delete: {
    borderRadius: 11,
    height: 22,
    width: 22,
    marginStart: Spacing.s,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderRadius: 11,
    height: 22,
    width: 22,
  },
  letter: {
    color: colorForScheme({ default: Colors.N0 }),
    fontSize: 13,
    fontWeight: '600',
  },
  name: {
    marginStart: Spacing.s,
  },
});
