import { useShellContext } from '@omni/kit';
import { KitListItem } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import { useSnackbarContext } from '@omni/kit/contexts/Snackbar';
import PeopleService from '@omni/kit/services/PeopleService';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { createImageUrl } from '@omni/kit/utilities/utilities';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { useCheckInContext } from '../contexts/CheckInContext';
import { useKioskContext } from '../contexts/KioskContext';
import KioskModal from './KioskModal';
import KioskModalButton from './KioskModalButton';

type Props = {
  profile: IProfile;
  isVisible: boolean;
  isGuestProfile?: boolean;
  refreshData?: () => void;
  onClose: () => void;
};

export default function RemoveProfileModal({
  profile,
  isVisible,
  isGuestProfile,
  refreshData,
  onClose,
}: Props): JSX.Element {
  const { token } = useKioskContext();
  const { selectedHousehold } = useCheckInContext();
  const { app } = useShellContext();
  const { showSnackbar } = useSnackbarContext();
  const { t } = useTranslation(['check-in', 'common']);

  const [isDeleting, setIsDeleting] = useState(false);
  const [householdRelationshipId, setHouseholdRelationshipId] = useState('');

  const profileFullName = `${profile.first_name} ${profile.last_name}`;

  const removeProfile = async () => {
    setIsDeleting(true);
    try {
      await PeopleService.UpdateProfile({
        id: profile?.id,
        body: {
          org_key: app.orgKey,
          household_role: null,
          _embedded: {
            household: null,
          },
        },
        token,
      });
      showSnackbar(
        t('check-in:textProfileRemoved', {
          name: profile.first_name,
        })
      );
      onClose();
      refreshData?.();
    } catch (error) {
      showSnackbar(t('check-in:textErrorUnableToSave'));
    } finally {
      setIsDeleting(false);
    }
  };

  const getHouseholdRelationship = async () => {
    try {
      const householdRelationship =
        await PeopleService.GetHouseholdRelationship({
          filters: [
            ['type', 'guest'],
            ['profile.id', profile?.id || ''],
            ['household.id', selectedHousehold?.id || ''],
            ['org_key', app.orgKey],
          ],
          token,
        });

      if (householdRelationship?.body?._embedded) {
        setHouseholdRelationshipId(
          householdRelationship?.body?._embedded?.[
            'household-relationships'
          ]?.[0]?.id || ''
        );
      }
    } catch (error) {
      showSnackbar(t('check-in:textErrorOccurred'));
    }
  };

  const removeGuestProfile = async () => {
    try {
      if (householdRelationshipId) {
        await PeopleService.DeleteHouseholdRelationship({
          id: householdRelationshipId,
          token,
        });
        showSnackbar(
          t('check-in:textProfileRemoved', {
            name: profile.first_name,
          })
        );
        onClose();
        refreshData?.();
      }
    } catch (error) {
      showSnackbar(t('check-in:textErrorUnableToSave'));
    }
  };

  useEffect(() => {
    if (isGuestProfile) {
      getHouseholdRelationship();
    }

    return () => {
      setHouseholdRelationshipId('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KioskModal
      isVisible={isVisible}
      onClose={onClose}
      title={t('check-in:titleRemove')}
      subtitle={t('check-in:textAreYouSureRemoveFromHousehold')}
      width={600}
      footer={
        <View style={styles.footerContainer}>
          <View style={{ flexDirection: 'row' }}>
            <KioskModalButton
              secondary
              style={{ marginRight: Spacing.l }}
              title={t('common:buttonTitleNoCancel')}
              onPress={onClose}
            />
            <KioskModalButton
              red
              disabled={isDeleting}
              title={t('common:buttonTitleYesRemove')}
              onPress={isGuestProfile ? removeGuestProfile : removeProfile}
            />
          </View>
        </View>
      }
    >
      <KitListItem
        title={profileFullName}
        titleFontSize={18}
        ImageComponent={
          <KitAvatar
            nickname={profileFullName}
            imageUrl={createImageUrl(profile._embedded?.photo?.id)}
            size={44}
          />
        }
        minHeight={74}
        topBorder
      />
    </KioskModal>
  );
}

const styles = StyleSheet.create({
  labelStyle: {
    color: Colors.N900,
    fontWeight: 'bold',
    fontSize: 16,
  },
  footerContainer: {
    paddingHorizontal: Spacing.xl,
    paddingVertical: Spacing.xl,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: Colors.N0,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
  },
});
