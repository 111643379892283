import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import { useEffect, useState } from 'react';

import { IPrinter } from '../../Types';

const SELECT_PRINTER_KEY = 'selected-printer';

/*
 * Do not use this directly in a component, instead use KioskContext
 * */
export default (): [
  IPrinter | undefined,
  (selectedPrinter?: IPrinter) => Promise<void>
] => {
  const [printer, setPrinter] = useState<IPrinter>();
  useEffect(() => {
    const getPrinter = async () => {
      try {
        const printerString = await getStoredItem(SELECT_PRINTER_KEY);
        const printer = JSON.parse(printerString || '');
        setPrinter(printer);
      } catch (e) {
        // no printer connected
      }
    };
    getPrinter();
  }, []);
  const savePrinterSelection = async (selectedPrinter?: IPrinter) => {
    if (selectedPrinter) {
      await setStoredItem(SELECT_PRINTER_KEY, JSON.stringify(selectedPrinter));
    } else {
      await removeStoredItem(SELECT_PRINTER_KEY);
    }

    setPrinter(selectedPrinter);
  };

  return [printer, savePrinterSelection];
};
