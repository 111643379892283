import { ShellContextData } from '@omni/kit/Types';
import { Branding } from '@omni/kit/services/Types';
import appIconFromBranding, {
  CompositedIconOptions,
} from '@omni/kit/utilities/appIconFromBranding';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';

export default function generateShellContextBranding(
  branding: Branding | undefined,
  options: CompositedIconOptions = {}
): ShellContextData['app']['branding'] {
  if (!branding) return undefined;

  const embedded = branding._embedded ?? {};

  return {
    brandColor: branding.brand_color_hex,
    iconBackgroundColor: branding.icon_background_color_hex,
    brandLogoId: embedded['icon-logo-image']?.id,
    colorScheme: branding.app_color_scheme,
    silhouetteLogo: parseImageUrl(
      embedded['silhouette-logo-image']?._links?.dynamic?.href,
      256,
      256,
      ImageServiceType.ImagePng,
      'image'
    ),
    brandLogo: appIconFromBranding(branding, options),
  };
}
