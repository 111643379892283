import moment from 'moment-timezone';

/**
 * ref: https://subsplash.io/ember/kit/-/blob/master/addon/helpers/date-range.js
 *
 * This helper takes in a start date, end date, a boolean for all day events,
 * and an IANA timezone string and will return an object with a date string and a
 * time string printed in human readable format.
 *
 * @param {[startDate]} - a UTC timestamp
 * @param {[endDate]} - a UTC timestamp
 * @param {[allDay]} - a boolean (optional if no dates returned can be all day dates)
 * @return {Object} - a object
 */
export interface DateRangeProps {
  startDate: string;
  endDate?: string;
  allDay?: boolean;
  timezone?: string;
  timeOnly?: boolean;
  convertToLocal?: boolean;
}

interface DateRangeParts {
  dateString: string;
  timeString?: string;
  allDay?: boolean;
  timezone?: {
    abbreviated: string;
    isLocal: boolean;
  };
}

export function dateFormatRange(
  dateRangeProps: DateRangeProps
): string | undefined {
  const { dateString, timeString, allDay, timezone } =
    getDateRangeParts(dateRangeProps);
  let formattedString = dateRangeProps.timeOnly && timeString ? '' : dateString;

  if (timeString) {
    formattedString += `${
      dateRangeProps.timeOnly ? '' : ' \u2022 '
    }${timeString}`;
  }

  /** Add timezone code if not in same timezone */
  if (
    allDay === false &&
    timezone?.isLocal === false &&
    !dateRangeProps.convertToLocal
  ) {
    formattedString += ` ${timezone?.abbreviated}`;
  }

  return formattedString;
}

export const getAbbreviatedTimezone = (
  date: string,
  timezone: string
): string => moment(date).tz(timezone).format('z');

const isTimezoneLocal = (timezone: string): boolean =>
  moment.tz.guess() === timezone;

export const getDateRangeParts = ({
  startDate,
  endDate,
  allDay = false,
  timezone,
  timeOnly = false,
  convertToLocal = false,
}: DateRangeProps): DateRangeParts => {
  const dayNameMonthDay = 'ddd, MMM D';
  const dayNameMonthDayYear = 'ddd, MMM D, YYYY';
  const hour = 'h';
  const hourAmPm = 'ha';
  const time = 'h:mm';
  const timeAmPm = 'h:mma';

  /**
   * The date function takes in a `dateTimeString` as a UTC timestamp and prevents
   * it from being converted to local time and then formats the returned string
   * to match whatever date format was passed in as the `dateFormat` param.
   *
   * @param {?String} dateTimeString
   * @param {?String} dateFormat
   * @return {String} - formatted date string
   */
  // @ts-ignore
  const date = (dateTimeString, dateFormat) => {
    let momentInstance = timezone
      ? moment(dateTimeString).tz(timezone)
      : moment(dateTimeString);

    if (convertToLocal) {
      momentInstance = momentInstance.tz(moment.tz.guess());
    }

    return momentInstance.format(dateFormat);
  };

  const sameDay = date(startDate, 'YYYYMMDD') === date(endDate, 'YYYYMMDD');
  const sameMoment = moment(startDate).isSame(moment(endDate));
  const sameTimeOfDay = date(startDate, 'a') === date(endDate, 'a');
  const isCurrentYear = (date: string) => moment(date).isSame(moment(), 'year');

  // Date helper
  const getDateFormat = (date: string) =>
    isCurrentYear(date) ? dayNameMonthDay : dayNameMonthDayYear;

  // Time helpers
  const getTimeFormat = (date: string) =>
    moment(date).minutes() === 0 ? hour : time;
  const getTimeAmPmFormat = (date: string) =>
    moment(date).minutes() === 0 ? hourAmPm : timeAmPm;

  let dateString;
  let timeString;

  if (allDay) {
    if (sameDay || !endDate) {
      /**
       * Returns formatted Date of:
       * Tue, Jul 4
       * Tue, Jul 4, 2017
       */
      dateString = date(startDate, getDateFormat(startDate));
    } else {
      /**
       * Returns formatted Date of:
       * Tue, Jul 4 - Thu, Jul 6
       * Sun, Dec 31 - Mon, Jan 1, 2024
       */
      dateString = `${date(startDate, getDateFormat(startDate))} \u2013 ${date(
        endDate,
        getDateFormat(endDate)
      )}`;
    }
  } else {
    if (sameDay || !endDate) {
      /**
       * Returns formatted Date of:
       * Tue, Jul 4
       * Tue, Jul 4, 2017
       */
      dateString = date(startDate, getDateFormat(startDate));

      if (sameMoment || !endDate) {
        /**
         * Returns formatted Time of:
         * 9am
         * 9:30am
         */
        timeString = `${date(startDate, getTimeAmPmFormat(startDate))}`;
      } else if (sameTimeOfDay) {
        /**
         * Returns formatted Time of:
         * 9 - 10am
         * 9:30 - 11:30am
         */
        timeString = `${date(
          startDate,
          getTimeFormat(startDate)
        )} \u2013 ${date(endDate, getTimeAmPmFormat(endDate))}`;
      } else {
        /**
         * Returns formatted Time of:
         * 9am - 1pm
         * 9:30am - 12:30pm
         */
        timeString = `${date(
          startDate,
          getTimeAmPmFormat(startDate)
        )} \u2013 ${date(endDate, getTimeAmPmFormat(endDate))}`;
      }
    } else {
      /**
       * If multi-day, returns fully formatted DateTime of:
       * Tue, Jul 4 • 9am - Thu, Jul 6 • 1pm
       * Tue, Jul 4 • 9:30am - Thu, Jul 6 • 1:30pm
       * Sun, Dec 31 • 9am - Mon, Jan 1, 2024 • 1:30pm
       */
      dateString = `${date(startDate, getDateFormat(startDate))} \u2022 ${date(
        startDate,
        getTimeAmPmFormat(startDate)
      )} \u2013 ${date(endDate, getDateFormat(endDate))} \u2022 ${date(
        endDate,
        getTimeAmPmFormat(endDate)
      )}`;
    }
  }

  return {
    dateString,
    timeString,
    allDay,
    timezone: timezone
      ? {
          abbreviated: getAbbreviatedTimezone(startDate, timezone),
          isLocal: isTimezoneLocal(timezone),
        }
      : undefined,
  };
};
