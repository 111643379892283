import { SizeClassV2, WindowSizeMax } from './types';

export default (width: number): SizeClassV2 => {
  if (width <= WindowSizeMax.XXS) {
    return SizeClassV2.XXS;
  } else if (width <= WindowSizeMax.XS) {
    return SizeClassV2.XS;
  } else if (width <= WindowSizeMax.S) {
    return SizeClassV2.S;
  } else if (width <= WindowSizeMax.M) {
    return SizeClassV2.M;
  } else if (width <= WindowSizeMax.L) {
    return SizeClassV2.L;
  } else if (width <= WindowSizeMax.XL) {
    return SizeClassV2.XL;
  } else {
    return SizeClassV2.XXL;
  }
};
