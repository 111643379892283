import * as Constants from '../../shared/Constants';

import { Image, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { KitButton, KitIcon, KitInput, KitText } from '@omni/kit/components';
import LeftNavButton, { LeftNavType } from '@omni/kit/components/LeftNavButton';
import React, { useEffect, useState } from 'react';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import {
  channelListSelector,
  userSelector,
} from '../../shared/redux/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { ChannelType } from '../../shared/redux/types';
import Colors from '@omni/kit/theming/Colors';
import PlatformKeyboardSpacer from '../components/PlatformKeyboardSpacer';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { createNewChannel } from '../../shared/redux/actions/SystemActions';
import { getShortChannelUrl } from '../../utilities/chatUtilities';
import { setChannel } from '../../shared/redux/actions/ChatActions';

// const debug = require('debug')('tca:chat:screen:CreateChannelModal');

//******************************************************************************
// Component
//******************************************************************************

// @ts-ignore
export default function CreateChannelModal({ navigation }): JSX.Element {
  const [channelName, setChannelName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { sizeClass } = useSizeClass();
  const channelList = useSelector(channelListSelector);
  const user = useSelector(userSelector);

  const dispatch = useDispatch();

  const _onButtonPress = () => {
    const isPublic = false;
    setIsLoading(true);
    dispatch(
      createNewChannel(
        ChannelType.Group,
        false,
        isPublic,
        channelName,
        user ? [user] : []
      )
    );
  };

  useEffect(() => {
    if (channelList && channelList[0]?.name === channelName) {
      setChannelName('');
      setIsLoading(false);
      const channel = channelList[0];

      if (Platform.OS === 'web' && sizeClass !== SizeClass.Small) {
        navigation.goBack();
        dispatch(setChannel(channel));
        navigation.navigate(channel.url);

        return;
      }

      navigation.navigate(Constants.CHAT_SCREEN, {
        channel,
        channelName: channel.name,
        channelId: getShortChannelUrl(channel.url),
      });
    }
  }, [channelList, navigation]);

  useEffect(() => {
    navigation.setOptions({
      title: 'Create group',
      headerLeft: () => (
        <LeftNavButton
          type={LeftNavType.Dismiss}
          title='Create group'
          onPress={navigation.goBack}
        />
      ),
    });
  }, [navigation]);

  return (
    <View style={{ flex: 1 }}>
      {Platform.OS === 'web' && sizeClass !== SizeClass.Small && (
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <LeftNavButton
            type={LeftNavType.Dismiss}
            title='Create group'
            onPress={navigation.goBack}
          />
          <KitText bold black fontSize={20}>
            Create group
          </KitText>
          <View style={{ opacity: 0 }}>
            <LeftNavButton
              disabled
              type={LeftNavType.Dismiss}
              title='Create group'
              onPress={() => null}
            />
          </View>
        </View>
      )}

      <ScrollView style={styles.safeContainer}>
        <View style={styles.viewContainer}>
          <KitInput
            label='Name'
            placeholder='Group name, team name, topic, etc.'
            value={channelName}
            onChangeText={setChannelName}
          />
          <View style={styles.descriptionContainer}>
            <View style={styles.descriptionBlock}>
              {Platform.OS === 'web' ? (
                <KitIcon
                  style={{ flexShrink: 0 }}
                  name='user-search'
                  color={Colors.brand}
                  size={21}
                />
              ) : (
                <Image
                  style={styles.descriptionImage}
                  source={require('@omni/kit/img/icon-createchannel-find.png')}
                />
              )}
              <KitText gray fontSize={16} style={styles.descriptionText}>
                Name your group something that’s easily recognizable.
              </KitText>
            </View>
            <View style={styles.descriptionBlock}>
              {Platform.OS === 'web' ? (
                <KitIcon
                  style={{ flexShrink: 0 }}
                  name='megaphone'
                  color={Colors.brand}
                  size={21}
                />
              ) : (
                <Image
                  style={styles.descriptionImage}
                  source={require('@omni/kit/img/icon-createchannel-safe.png')}
                />
              )}
              <KitText gray fontSize={16} style={styles.descriptionText}>
                As the creator, you are responsible for facilitating and
                moderating conversations to ensure a safe environment for
                everyone.
              </KitText>
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <KitButton
              disabled={!channelName}
              isLoading={isLoading}
              onPress={_onButtonPress}
              title='Create group'
            />
          </View>
        </View>
      </ScrollView>
      <PlatformKeyboardSpacer />
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 48,
  },
  viewContainer: {
    flex: 1,
    marginTop: 28,
    marginBottom: Spacing.xl,
    paddingHorizontal: Spacing.xl,
  },
  safeContainer: {
    flex: 1,
    backgroundColor: colorForScheme({ default: Colors.N0 }),
  },
  descriptionContainer: {
    marginTop: Spacing.xl,
  },
  descriptionBlock: {
    flexDirection: 'row',
    flexWrap: Platform.OS === 'web' ? 'nowrap' : 'wrap',
    paddingTop: Spacing.xl,
  },
  descriptionImage: {
    tintColor: colorForScheme({ default: Colors.brand }),
  },
  descriptionText: {
    flex: 1,
    paddingLeft: Spacing.l,
    lineHeight: 24,
  },
});
