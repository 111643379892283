/**
 * Invokes a callback after a minimum amount of delay in milliseconds
 *
 * @param {number} start - start time in milliseconds
 * @param {number} end - end time in milliseconds
 * @param {number} min - minimum delay
 * @param {number} callback - callback to invoke after minimum delay
 *
 * @returns {NodeJS.Timeout} id  - id of the window.setTimeout
 */
export const minDelay = ({
  start,
  end,
  min = 1000,
  callback,
}: {
  start?: number;
  end?: number;
  min?: number;
  callback: () => void;
}): NodeJS.Timeout => {
  start = start || new Date().getTime();
  end = end || new Date().getTime();

  const diff = end - start;
  const delay = diff < min ? min - diff : 0;

  return setTimeout(() => {
    callback();
  }, delay);
};
