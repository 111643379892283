import { ACCOUNT_SCREEN, CHAT_SETTINGS_MODAL } from '../../shared/Constants';
import { Platform, View } from 'react-native';
import React, { useState } from 'react';

import { KitTouchableIcon } from '@omni/kit/components';
import SnoozeNotificationsActionSheet from './chat/SnoozeNotificationsActionSheet';
import Spacing from '@omni/kit/theming/Spacing';
import { useNavigation } from '@react-navigation/native';
import useSnoozeNotifications from '../../hooks/useSnoozeNotifications';

const debug = require('debug')(
  'tca:chat:components:ChannelListRightNavButtons'
);

const ChannelListRightNavButtons: React.FC = (): JSX.Element => {
  const navigation = useNavigation();
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);
  const { snoozePeriod, _setSnoozePeriod } = useSnoozeNotifications();

  const _openChatSettings = () => {
    debug('_openChatSettings');
    navigation.navigate(CHAT_SETTINGS_MODAL);
  };

  const _openAccountScreen = () => {
    debug('_openAccountScreen');
    navigation.navigate(ACCOUNT_SCREEN);
  };

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ width: Spacing.m }} />
      {Platform.OS !== 'web' && (
        <KitTouchableIcon
          name={snoozePeriod.isSnoozeOn ? 'notifications-off' : 'notifications'}
          onPress={() => setIsActionSheetVisible(true)}
          buttonSize={40}
          size={20}
        />
      )}
      <KitTouchableIcon
        name={'settings'}
        onPress={_openChatSettings}
        buttonSize={40}
        size={20}
      />
      {Platform.OS === 'web' && (
        <KitTouchableIcon
          name={'person'}
          onPress={_openAccountScreen}
          buttonSize={40}
          size={20}
        />
      )}

      <SnoozeNotificationsActionSheet
        isActionSheetVisible={isActionSheetVisible}
        setIsActionSheetVisible={setIsActionSheetVisible}
        isSnoozed={snoozePeriod.isSnoozeOn}
        snoozedUntil={snoozePeriod.endAt}
        setSnoozePeriod={_setSnoozePeriod}
      />
    </View>
  );
};

export default ChannelListRightNavButtons;
