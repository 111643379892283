import GroupDetail from '@omni/groups/routes/GroupDetail';
import { AUTH_STACK, AuthNavigator } from '@omni/kit/auth';
import { KitIcon, KitText } from '@omni/kit/components';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import useDocumentTitle from '@omni/kit/hooks/useDocumentTitle';
import useFavicon from '@omni/kit/hooks/useFavicon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import HeaderTitleLeftComponent from '@omni/messaging/mobile/components/HeaderTitleLeftComponent';
import ChannelAvatar from '@omni/messaging/shared/scenes/channelList/components/ChannelAvatar';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Keyboard,
  Platform,
  Pressable,
  TouchableOpacity,
  View,
} from 'react-native';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { useSelector } from 'react-redux';

import { useChatAuth } from '../../context/ChatAuthContext';
import useAppInfo from '../../hooks/useAppInfo';
import {
  ACCEPT_INVITATION_SCREEN,
  ACCOUNT_SCREEN,
  BIBLE_LOCATION_SCREEN,
  BIBLE_VERSE_SCREEN,
  BLOCKED_USER_LIST_SCREEN,
  CHANNEL_DETAILS_MODAL,
  CHANNEL_LIST_SCREEN,
  CHAT_SCREEN,
  CHAT_SETTINGS_MODAL,
  CHAT_SETTINGS_SCREEN,
  CHAT_THREAD_SCREEN,
  CREATE_CHANNEL_MODAL,
  CREATE_DIRECT_MODAL,
  GIF_SCREEN,
  GROUPS_DETAIL,
  GROUPS_SCREEN,
  INTRO_SCREEN,
  JOIN_REQUESTS_SCREEN,
  MAIN_STACK,
  MEDIA_DETAIL_SCREEN,
  MEDIA_SELECTION_SCREEN,
  NOT_FOUND_SCREEN,
  POLL_RESULTS_MODAL,
  POLL_SCREEN,
  PRAYER_REQUEST_SCREEN,
  PRAYER_TAB,
  THREADS_TAB,
} from '../../shared/Constants';
import {
  appTitleSelector,
  blockedUserListSelector,
  channelSelector,
  userSelector,
} from '../../shared/redux/selectors';
import { ChannelType } from '../../shared/redux/types';
import { ChannelDetailsStack } from '../../shared/scenes/channelDetails/ChannelDetailStack';
import JoinRequestsScreen from '../../shared/scenes/joinRequests/JoinRequestsScreen';
import { formatDirectChannelName } from '../../utilities/chatUtilities';
import GroupsScreen from '../../web/scenes/discover/GroupsScreen';
import ChannelListRightNavButtons from '../components/ChannelListRightNavButtons';
import HeaderRightButtons from '../components/HeaderRightButtons';
import LeftNavButton, { LeftNavType } from '../components/LeftNavButton';
import ChatScreen from '../scenes/channel/ChatScreen';
import PrayerListScreen from '../scenes/channel/PrayerListScreen';
import ThreadListScreen from '../scenes/channel/ThreadListScreen';
import AcceptInvitationScreen from '../screens/AcceptInvitationScreen';
import AccountScreen from '../screens/AccountScreen';
import BibleLocationScreen from '../screens/BibleLocationScreen';
import BibleVerseScreen from '../screens/BibleVerseScreen';
import BlockPageScreen from '../screens/BlockPageScreen';
import BlockedUserListScreen from '../screens/BlockedUserListScreen';
import ChatSettingsScreen from '../screens/ChatSettingsScreen';
import CreateChannelModal from '../screens/CreateChannelModal';
import CreateDirectScreen from '../screens/CreateDirectScreen';
import GifModal from '../screens/GifModal';
import IntroScreen from '../screens/IntroScreen';
import MediaSelectionScreen from '../screens/MediaSelectionScreen';
import PollResultModal from '../screens/PollResultModal';
import PollScreen from '../screens/PollScreen';
import PrayerRequestScreen from '../screens/PrayerRequestScreen';
import MessagingTabs from './TopTabNavigator';
import navStyles from './navStyles';

//******************************************************************************
// Stacks
//******************************************************************************

// @ts-ignore
const MainStack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

// @ts-ignore
const AppModalStack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

// @ts-ignore
const ChatSettingsStack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

// @ts-ignore
const ModalHeaderStack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

const modalStackPresentationOptions = Platform.select({
  ios: {
    stackPresentation: 'modal',
    headerHideShadow: true,
    headerShown: false,
    headerTitleStyle: navStyles.headerTitleStyle,
  },
  android: {
    stackPresentation: 'modal',
    headerHideShadow: true,
    headerShown: false,
    headerTitleStyle: navStyles.headerTitleStyle,
    headerTopInsetEnabled: false, // react-native-screens
  },
  web: {
    headerShown: false,
    cardStyle: { flex: 1 },
    headerTitleStyle: navStyles.headerTitleStyle,
  },
});

//******************************************************************************
// Navigators
//******************************************************************************

const ChatSettingsModalWithHeader = () => {
  return (
    <ChatSettingsStack.Navigator
      screenOptions={{
        // @ts-ignore
        headerHideShadow: true,
        headerTitleStyle: navStyles.headerTitleStyle,
        headerTopInsetEnabled: false, // react-native-screens (Android)
      }}
    >
      <ChatSettingsStack.Screen
        name={CHAT_SETTINGS_SCREEN}
        component={ChatSettingsScreen}
        options={(props) => ({
          title: 'User Settings',
          headerTintColor: Colors.N900,
          ...Platform.select({
            default: {
              headerLeft: () => (
                <LeftNavButton
                  type={LeftNavType.Dismiss}
                  title={''}
                  onPress={props.navigation.goBack}
                />
              ),
            },
            android: null,
          }),
        })}
      />
      <ChatSettingsStack.Screen
        name={BLOCKED_USER_LIST_SCREEN}
        component={BlockedUserListScreen}
        options={(props) => ({
          ...getNavOptions('Blocked', props),
        })}
      />
    </ChatSettingsStack.Navigator>
  );
};

const CreateChannelModalWithHeader = () => (
  <ModalHeaderStack.Navigator
    screenOptions={{
      // @ts-ignore
      headerHideShadow: true,
      headerTitleStyle: navStyles.headerTitleStyle,
      headerTopInsetEnabled: false, // react-native-screens (Android)
    }}
  >
    <ModalHeaderStack.Screen
      name={CREATE_CHANNEL_MODAL}
      component={CreateChannelModal}
    />
  </ModalHeaderStack.Navigator>
);

const CreateDirectModalWithHeader = () => (
  <ModalHeaderStack.Navigator
    screenOptions={{
      // @ts-ignore
      headerHideShadow: true,
      headerTitleStyle: navStyles.headerTitleStyle,
      headerTopInsetEnabled: false, // react-native-screens (Android)
    }}
  >
    <ModalHeaderStack.Screen
      name={CREATE_CHANNEL_MODAL}
      component={CreateDirectScreen}
    />
  </ModalHeaderStack.Navigator>
);

export const GifModalWithHeader = () => (
  <ModalHeaderStack.Navigator
    screenOptions={{
      // @ts-ignore
      headerHideShadow: true,
      headerTitleStyle: navStyles.headerTitleStyle,
      headerTopInsetEnabled: false, // react-native-screens (Android)
    }}
  >
    <ModalHeaderStack.Screen name={GIF_SCREEN} component={GifModal} />
  </ModalHeaderStack.Navigator>
);

export const PollModalWithHeader = () => (
  <ModalHeaderStack.Navigator
    screenOptions={{
      // @ts-ignore
      headerHideShadow: true,
      headerTitleStyle: navStyles.headerTitleStyle,
      headerTopInsetEnabled: false, // react-native-screens (Android)
    }}
  >
    <ModalHeaderStack.Screen name={POLL_SCREEN} component={PollScreen} />
  </ModalHeaderStack.Navigator>
);

// @ts-ignore
export const PollResultModalWithHeader = ({ route }) => (
  <ModalHeaderStack.Navigator
    screenOptions={{
      // @ts-ignore
      headerHideShadow: true,
      headerTitleStyle: navStyles.headerTitleStyle,
      headerTopInsetEnabled: false, // react-native-screens (Android)
    }}
  >
    <ModalHeaderStack.Screen
      name={POLL_RESULTS_MODAL}
      options={(props) =>
        getNavOptions('Poll responses', props, LeftNavType.Dismiss)
      }
    >
      {(props) => <PollResultModal {...props} route={route} />}
    </ModalHeaderStack.Screen>
  </ModalHeaderStack.Navigator>
);

// @ts-ignore
export const PrayerRequestModalWithHeader = (originalProps) => {
  return (
    <ModalHeaderStack.Navigator
      screenOptions={{
        // @ts-ignore
        headerHideShadow: true,
        headerTitleStyle: navStyles.headerTitleStyle,
        headerTopInsetEnabled: false, // react-native-screens (Android)
      }}
    >
      <ModalHeaderStack.Screen
        name={PRAYER_REQUEST_SCREEN}
        options={(props) =>
          getNavOptions('New prayer request', props, LeftNavType.Dismiss)
        }
      >
        {(props) => (
          // @ts-ignore
          <PrayerRequestScreen {...props} route={originalProps.route} />
        )}
      </ModalHeaderStack.Screen>
    </ModalHeaderStack.Navigator>
  );
};

export const MediaSelectionModalWithHeader = () => (
  <ModalHeaderStack.Navigator
    screenOptions={{
      // @ts-ignore
      headerHideShadow: true,
      headerTitleStyle: navStyles.headerTitleStyle,
      headerTopInsetEnabled: false, // react-native-screens (Android)
    }}
  >
    <ModalHeaderStack.Screen
      name={MEDIA_SELECTION_SCREEN}
      component={MediaSelectionScreen}
      options={(props) =>
        getNavOptions('Select a media item', props, LeftNavType.Dismiss)
      }
    />
  </ModalHeaderStack.Navigator>
);

export const BibleSelectionModalWithHeader = ({ togglePopup = () => null }) => {
  const { sizeClass } = useSizeClass();

  return (
    <ChatSettingsStack.Navigator
      screenOptions={{
        // @ts-ignore
        headerHideShadow: true,
        headerShown: sizeClass !== SizeClass.Large,
        headerTitleStyle: navStyles.headerTitleStyle,
        headerTopInsetEnabled: false, // react-native-screens (Android)
      }}
    >
      <ChatSettingsStack.Screen
        name={BIBLE_LOCATION_SCREEN}
        component={BibleLocationScreen}
        options={(props) => ({
          title: 'Choose a passage',
          headerTintColor: Colors.N900,
          headerLeft: () => (
            <LeftNavButton
              type={LeftNavType.Dismiss}
              title={'Choose a passage'}
              onPress={props.navigation.goBack}
            />
          ),
        })}
      />
      <ChatSettingsStack.Screen
        name={BIBLE_VERSE_SCREEN}
        options={(props) => ({
          title: 'Choose verses',
          headerTintColor: Colors.N900,
          headerLeft: () => (
            <LeftNavButton
              type={LeftNavType.Back}
              title={'Choose verses'}
              onPress={props.navigation.goBack}
            />
          ),
        })}
      >
        {/* @ts-ignore */}
        {() => <BibleVerseScreen togglePopup={togglePopup} />}
      </ChatSettingsStack.Screen>
    </ChatSettingsStack.Navigator>
  );
};

// @ts-ignore
const MainScreens = (screenProps) => {
  const channel = useSelector(channelSelector);
  const { t } = useTranslation(['groups']);
  const navigation = useNavigation();
  const user = useSelector(userSelector);
  const blockedUserList = useSelector(blockedUserListSelector);
  const channelType = channel?.customType as ChannelType;
  const channelName =
    channelType === ChannelType.Direct
      ? formatDirectChannelName(user!, channel!.members, blockedUserList)
      : channel?.name;
  const hideDismissButton =
    Platform.OS === 'web' && !window?.ReactNativeWebView;
  const { isAuthenticated } = useChatAuth();

  const { width } = Dimensions.get('window');
  const [activeTab, setActiveTab] = React.useState(CHAT_SCREEN);

  const handleTabPress = (tabName: any) => {
    navigation.navigate(tabName);
  };
  const _onNavDetailsButtonPress = () => {
    Keyboard.dismiss();
    navigation.navigate(CHANNEL_DETAILS_MODAL, { channel });
  };

  return (
    <MainStack.Navigator
      screenOptions={{
        // @ts-ignore
        headerHideShadow: true,
        headerTitleStyle: navStyles.headerTitleStyle,
        headerTopInsetEnabled: false, // react-native-screens (Android)
        ...(Platform.OS === 'web' && { cardStyle: { flex: 1 } }),
      }}
    >
      <MainStack.Screen
        name={CHANNEL_LIST_SCREEN}
        options={{
          title: '',
          headerLeft: () => {
            return (
              <>
                {hideDismissButton ? null : (
                  <LeftNavButton type={LeftNavType.Dismiss} title='' />
                )}
                <KitText
                  black
                  extraBold
                  fontSize={22}
                  style={{ marginLeft: Spacing.m, marginTop: 4 }}
                >
                  Messaging
                </KitText>
              </>
            );
          },
          headerRight: () =>
            isAuthenticated ? <ChannelListRightNavButtons /> : null,
        }}
      >
        {(props) => <MessagingTabs {...props} screenProps={screenProps} />}
      </MainStack.Screen>
      <MainStack.Screen
        name={CHAT_SCREEN}
        component={ChatScreen}
        options={({ navigation, route }) => ({
          title:
            getFocusedRouteNameFromRoute(route) !== THREADS_TAB &&
            getFocusedRouteNameFromRoute(route) !== PRAYER_TAB
              ? ''
              : undefined,
          headerTintColor: Colors.N900,
          ...Platform.select({
            android: {
              headerLeft: () => (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <LeftNavButton
                    type={LeftNavType.Back}
                    title={''}
                    onPress={() => {
                      if (
                        activeTab === THREADS_TAB ||
                        activeTab === PRAYER_TAB
                      ) {
                        setActiveTab(CHAT_SCREEN);
                      } else {
                        navigation.navigate(CHANNEL_LIST_SCREEN);
                      }
                    }}
                  />
                  <HeaderTitleLeftComponent
                    onPress={_onNavDetailsButtonPress}
                    channel={channel}
                    channelName={channelName}
                    width={width}
                  />
                </View>
              ),
            },
            ios: {
              headerLeft: () => (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <LeftNavButton
                    type={LeftNavType.Back}
                    title={''}
                    onPress={() => {
                      if (
                        activeTab === THREADS_TAB ||
                        activeTab === PRAYER_TAB
                      ) {
                        setActiveTab(CHAT_SCREEN);
                      } else {
                        navigation.navigate(CHANNEL_LIST_SCREEN);
                      }
                    }}
                  />
                  <HeaderTitleLeftComponent
                    onPress={_onNavDetailsButtonPress}
                    channel={channel}
                    width={width}
                    channelName={channelName}
                  />
                </View>
              ),
            },
            web: {
              headerLeft: () => (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <LeftNavButton
                    type={LeftNavType.Back}
                    title=''
                    onPress={() => navigation.navigate(CHANNEL_LIST_SCREEN)}
                  />
                  <HeaderTitleLeftComponent
                    onPress={_onNavDetailsButtonPress}
                    channel={channel}
                    width={width}
                    channelName={channelName}
                  />
                </View>
              ),
            },
          }),
          headerRight: () => {
            if (activeTab === CHAT_SCREEN) {
              return <HeaderRightButtons onTabPress={handleTabPress} />;
            }
            return null;
          },
        })}
      />
      <MainStack.Screen
        name={THREADS_TAB}
        component={ThreadListScreen}
        options={({ navigation }) => ({
          [Platform.OS === 'web' ? 'headerTitle' : 'headerCenter']: () => (
            <View
              style={{
                overflow: 'hidden',
                alignItems: Platform.select({
                  ios: 'center',
                  android: 'center',
                  default: 'flex-start',
                }),
              }}
            >
              <KitText
                black
                semiBold
                fontSize={16}
                style={Platform.OS === 'ios' ? { marginTop: -3 } : undefined}
              >
                {t('messaging:threads')}
              </KitText>
              <KitText
                fontSize={14}
                numberOfLines={1}
                style={Platform.OS === 'ios' ? { marginTop: -3 } : undefined}
              >
                {channelName}
              </KitText>
            </View>
          ),
          headerHideShadow: true,
          headerTintColor: Colors.N900,
          ...Platform.select({
            ios: {
              headerLeft: () => (
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navigation.goBack}
                />
              ),
            },
            web: {
              headerLeft: () => (
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navigation.goBack}
                />
              ),
            },
          }),
        })}
      />
      <MainStack.Screen
        name={PRAYER_TAB}
        component={PrayerListScreen}
        options={({ navigation }) => ({
          [Platform.OS === 'web' ? 'headerTitle' : 'headerCenter']: () => (
            <View
              style={{
                alignItems: Platform.select({
                  ios: 'center',
                  android: 'center',
                  default: 'flex-start',
                }),
              }}
            >
              <KitText
                black
                semiBold
                fontSize={16}
                style={Platform.OS === 'ios' ? { marginTop: -3 } : undefined}
              >
                {t('messaging:prayersText')}
              </KitText>
              <KitText
                fontSize={14}
                numberOfLines={1}
                style={Platform.OS === 'ios' ? { marginTop: -3 } : undefined}
              >
                {channelName}
              </KitText>
            </View>
          ),
          headerHideShadow: true,
          headerTintColor: Colors.N900,
          ...Platform.select({
            ios: {
              headerLeft: () => (
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navigation.goBack}
                />
              ),
            },
            web: {
              headerLeft: () => (
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navigation.goBack}
                />
              ),
            },
          }),
        })}
      />
      <MainStack.Screen
        name={CHAT_THREAD_SCREEN}
        component={ChatScreen}
        options={({ navigation }) => ({
          [Platform.OS === 'web' ? 'headerTitle' : 'headerCenter']: () => (
            <TouchableOpacity
              onPress={() =>
                navigation.navigate(CHAT_SCREEN, {
                  channel,
                  channelName: channel?.name,
                })
              }
              style={{ height: '100%' }}
            >
              <View
                style={{
                  width: 300,
                  overflow: 'hidden',
                  alignItems: Platform.select({
                    ios: 'center',
                    default: 'flex-start',
                  }),
                }}
              >
                <KitText
                  black
                  semiBold
                  fontSize={17}
                  style={Platform.OS === 'ios' ? { marginTop: -6 } : undefined}
                >
                  {t('messaging:threadText')}
                </KitText>
                <KitText
                  brandColor
                  fontSize={15}
                  numberOfLines={1}
                  style={Platform.OS === 'ios' ? { marginTop: -3 } : undefined}
                >
                  {channelName}
                </KitText>
              </View>
            </TouchableOpacity>
          ),
          headerHideShadow: true,
          headerTintColor: Colors.N900,
          ...Platform.select({
            ios: {
              headerLeft: () => (
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navigation.goBack}
                />
              ),
            },
            web: {
              headerLeft: () => (
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navigation.goBack}
                />
              ),
            },
          }),
        })}
      />
      <MainStack.Screen
        name={MEDIA_DETAIL_SCREEN}
        component={BlockPageScreen}
        options={({ navigation, route }) => ({
          title: (route?.params as { title: string })?.title || '',
          headerLeft: () => (
            <LeftNavButton
              type={LeftNavType.Back}
              title={(route?.params as { title: string })?.title || ''}
              onPress={navigation.goBack}
            />
          ),
        })}
      />
      <MainStack.Screen
        name={JOIN_REQUESTS_SCREEN}
        component={JoinRequestsScreen}
        options={({ navigation }) => ({
          title: 'Join requests',
          headerTintColor: Colors.N900,
          ...Platform.select({
            default: {
              headerLeft: () => (
                <LeftNavButton
                  type={LeftNavType.Back}
                  title='Join requests'
                  onPress={() => navigation.navigate(CHANNEL_LIST_SCREEN)}
                />
              ),
            },
            android: null,
          }),
        })}
      />
    </MainStack.Navigator>
  );
};

export default function SmallScreenNavigator({
  // @ts-ignore
  screenProps,
  // @ts-ignore
  route,
  // @ts-ignore
  navigation,
}) {
  const appKey: string = route.params?.appKey || screenProps.appKey;
  const appTitle = useSelector(appTitleSelector);

  const { app, orgKey } = useAppInfo(appKey);
  useDocumentTitle(app?.title || '', 'Messaging');
  useFavicon(app?.id || '');

  const { isAuthenticated, isReady } = useChatAuth();

  useEffect(() => {
    if (!appKey) {
      navigation.replace(NOT_FOUND_SCREEN);
    }
  }, [appKey, navigation]);

  /**
   * Closes the current popup after login succeeds
   * and reload the parent window for access token
   */
  if (Platform.OS === 'web' && window.opener) {
    window.opener.location.reload();
    window.close();
  }

  return (
    <AppModalStack.Navigator screenOptions={modalStackPresentationOptions}>
      <AppModalStack.Screen
        name={MAIN_STACK}
        options={{
          headerShown: false,
          // @ts-ignore
          headerHideShadow: true,
          title: appTitle,
        }}
      >
        {() => <MainScreens {...screenProps} appKey={appKey} />}
      </AppModalStack.Screen>
      <AppModalStack.Screen
        name={INTRO_SCREEN}
        component={IntroScreen}
        options={{
          headerShown: false,
          gestureEnabled: false,
          title: appTitle,
        }}
      />
      <AppModalStack.Screen
        name={CHAT_SETTINGS_MODAL}
        component={ChatSettingsModalWithHeader}
        options={{ headerShown: false, title: appTitle }}
      />
      <AppModalStack.Screen
        name={ACCOUNT_SCREEN}
        component={AccountScreen}
        options={{ headerShown: false, title: appTitle }}
      />
      <AppModalStack.Screen
        name={CHANNEL_DETAILS_MODAL}
        component={ChannelDetailsStack}
        options={{
          headerShown: false,
          title: appTitle,
          // @ts-ignore
          stackPresentation: 'push',
        }}
      />
      <AppModalStack.Screen
        name={CREATE_CHANNEL_MODAL}
        component={CreateChannelModalWithHeader}
        options={{ title: appTitle }}
      />
      <AppModalStack.Screen
        name={CREATE_DIRECT_MODAL}
        component={CreateDirectModalWithHeader}
        options={{ title: appTitle }}
      />
      <AppModalStack.Screen
        name={GIF_SCREEN}
        component={GifModalWithHeader}
        options={{ title: appTitle }}
      />
      <AppModalStack.Screen
        name={POLL_SCREEN}
        component={PollModalWithHeader}
        options={{ title: appTitle }}
      />
      <AppModalStack.Screen
        name={POLL_RESULTS_MODAL}
        component={PollResultModalWithHeader}
        options={{ title: appTitle }}
      />
      <AppModalStack.Screen
        name={PRAYER_REQUEST_SCREEN}
        component={PrayerRequestModalWithHeader}
        options={{ title: appTitle }}
      />
      <AppModalStack.Screen
        name={MEDIA_SELECTION_SCREEN}
        component={MediaSelectionModalWithHeader}
        options={{ title: appTitle }}
      />
      <AppModalStack.Screen
        name={BIBLE_LOCATION_SCREEN}
        component={BibleSelectionModalWithHeader}
        options={{ title: appTitle }}
      />
      <AppModalStack.Screen
        name={GROUPS_SCREEN}
        options={{ headerShown: false, title: appTitle }}
      >
        {() => <GroupsScreen appKey={appKey} />}
      </AppModalStack.Screen>
      <AppModalStack.Screen
        name={GROUPS_DETAIL}
        options={{ headerShown: false, title: appTitle }}
      >
        {() => <GroupDetail {...screenProps} />}
      </AppModalStack.Screen>

      <AppModalStack.Screen name={ACCEPT_INVITATION_SCREEN}>
        {/* @ts-ignore */}
        {(props) => <AcceptInvitationScreen {...props} appKey={appKey} />}
      </AppModalStack.Screen>

      {/*
      Handles login scenarios:
        1. Return from login on small screen Web Messaging
           (see LinkingConfig usage of AUTH_STACK)
        2. The AcceptInvitationScreen navigates here in a logged-out state
      */}
      {!isAuthenticated && (
        <AppModalStack.Screen name={AUTH_STACK} options={{ title: appTitle }}>
          {(props) => (
            <AuthNavigator
              {...props}
              appKey={appKey}
              orgKey={orgKey}
              screenProps={screenProps}
              targetAuthProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
            />
          )}
        </AppModalStack.Screen>
      )}
    </AppModalStack.Navigator>
  );
}

//******************************************************************************
// Helpers
//******************************************************************************

// @ts-ignore
const getNavOptions = (title, { navigation }, type = LeftNavType.Back) => ({
  title,
  headerLeft: () => (
    <LeftNavButton type={type} title={title} onPress={navigation.goBack} />
  ),
});
