import GetLocation, { Location } from 'react-native-get-location';
import { PermissionsAndroid, Platform } from 'react-native';

const debug = require('debug')('tca:kit:utilities:UserLocation.ts');

export const getUserLocation = (): Promise<Location> => {
  switch (Platform.OS) {
    case 'android':
    case 'ios':
      return new Promise(async (resolve, reject) => {
        let enableHighAccuracy = true;

        if (Platform.OS === 'android') {
          const permission = await resolveAndroidLocationPermission();

          if (!permission.granted) {
            reject('Location permission error');
          }

          enableHighAccuracy = permission.enableHighAccuracy;
        }

        const timeout = 15000;
        debug(`enableHighAccuracy:${enableHighAccuracy}`);
        GetLocation.getCurrentPosition({ enableHighAccuracy, timeout })
          .then((location) => {
            debug(`Got location ${JSON.stringify(location)}`);
            resolve(location);
          })
          .catch((error) => {
            console.log(`Error: ${JSON.stringify(error)}`);
            debug(`Error: ${JSON.stringify(error)}`);
            reject(error);
          })
          .finally(() => debug('Finished'));
      });
    default:
      // TODO: implement support for getting user location on web platform
      // consider submitting a PR to 'react-native-get-location'
      throw 'Not supported';
  }
};

/**
 * Handle Android runtime permissions
 * ref: https://reactnative.dev/docs/0.63/permissionsandroid
 *
 * Since we target Android 12 and higher, we must request the ACCESS_FINE_LOCATION permission
 * along with the ACCESS_COARSE_LOCATION permission
 * ref: https://developer.android.com/training/location/permissions#accuracy
 *
 * Note that there may be certain device conditions under which course/approxmate
 * location request will 'timeout' unexpectedly. End user can workaround by using fine/precise location.
 */
const resolveAndroidLocationPermission = async (): Promise<{
  granted: boolean;
  enableHighAccuracy: boolean;
}> => {
  let granted = false;
  let enableHighAccuracy = false;
  let enableLowAccuracy = false;

  try {
    const permissions = await PermissionsAndroid.requestMultiple([
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
      PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,
    ]);

    enableHighAccuracy =
      permissions[PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION] ===
      PermissionsAndroid.RESULTS.GRANTED;

    enableLowAccuracy =
      permissions[PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION] ===
      PermissionsAndroid.RESULTS.GRANTED;

    granted = enableHighAccuracy || enableLowAccuracy;
  } catch (error) {
    debug(`Location permission error: ${JSON.stringify(error)}`);
  }

  return { granted, enableHighAccuracy };
};
