import EventService from '../../services/EventService';
import { EVENT_SERVICE_EVENTS } from '../../services/EventService/types';
import PatchMediaPlayResource from './PatchMediaPlayResource';
import ResetPatchTimer from './ResetPatchTimer';
import TrackMediaProgressState from './TrackMediaProgressState';
import { CURRENT_EVENT_SOURCE } from './constants';

export default async ({
  appKey,
  duration,
  mediaItemId,
  mediaPlayId,
  playCount,
  position,
  userId,
}: {
  appKey: string;
  duration?: number;
  mediaItemId: string;
  mediaPlayId: string;
  playCount?: number;
  position: number;
  userId: string;
}): Promise<void> => {
  const response = await PatchMediaPlayResource({
    appKey,
    duration,
    mediaItemId,
    mediaPlayId,
    playCount,
    position,
    userProfileId: userId,
  });

  if (response) {
    EventService.dispatch(EVENT_SERVICE_EVENTS.UPDATED_MEDIA_PLAY_RESOURCE, {
      data: response,
      source: CURRENT_EVENT_SOURCE,
    });

    TrackMediaProgressState.mediaPlay = response;
    ResetPatchTimer();
  }
};
