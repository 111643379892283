import Environment from '@omni/kit/Environment';
import BaseServiceV2, {
  IHttpResponse,
  IInclude,
  IUri,
  buildUrl,
} from '@omni/kit/services/BaseServiceV2';

import { ICheckIn } from '../Types';

const HOST = Environment.checkInService;
const RESOURCE = 'check-ins';

interface IGetCheckIns extends IUri {
  token?: string;
  includes?: IInclude[];
}

interface IGetAllCheckInsResponse extends IHttpResponse {
  body?: {
    _embedded: {
      'check-ins': ICheckIn[];
    };
    count: number;
  };
}

export default async function GetCheckIns({
  token,
  includes,
  sorts,
  filters,
  pages,
}: IGetCheckIns): Promise<IGetAllCheckInsResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    includes,
    sorts,
    filters,
    pages,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
