import { KitTouchableIcon } from '@omni/kit/components';
import KitProgress from '@omni/kit/components/KitProgress';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { FileMessage, UserMessage } from '@sendbird/chat/message';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';

import { MessageType } from '../../../../../shared/redux/types';
import LightboxModal from '../../../../components/native/LightboxModal';
import Portal from '../../../../components/web/Portal';
import ImageLoader from './ImageLoader';

const debug = require('debug')('tca:chat:component:ImageMessageBubble');

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  navigation: any;
  isUser: boolean;
  message: UserMessage;
  progress?: ProgressEvent;
  onLongPress?: () => void;
  disabled?: boolean;
}

export interface IState {
  width: number;
  height: number;
  hasLoaded: boolean;
  isMounted: boolean;
  thumbnailList: IThumbnail[];
}

export interface IPicture {
  senderName: string;
  senderAvatar: string;
  url: string;
}

interface IThumbnail {
  senderName: string;
  senderAvatar: string;
  url: string;
}

//******************************************************************************
// Component
//******************************************************************************

export default function ImageMessageBubble({
  message,
  onLongPress,
  progress,
  disabled,
}: IProps): JSX.Element {
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const [thumbnailList, setThumbnailList] = useState([]);
  const [lightboxVisible, setLightboxVisible] = useState(false);

  //****************************************************************************
  // Methods
  //****************************************************************************

  const _showLightbox = () => {
    const picture = thumbnailList[thumbnailList.length - 1];

    if (!picture) return;

    // if (Platform.OS === 'web') {
    //   const win = window.open(picture.url, '_blank');
    //   win.focus();
    //   return;
    // }

    setLightboxVisible(true);
  };

  const _parseData = (data?: string) => {
    if (data) {
      try {
        return JSON.parse(data);
      } catch {
        return null;
      }
    }
  };

  const _setDimensions = (message: UserMessage) => {
    const maxWidth = 300;
    const maxHeight = 300;
    let ratio = 1;

    // debug('Custom message type', message.customType);
    // debug('Message data', message.data);

    const data = _parseData(message.data);

    if (data !== null && data.ratio !== null) {
      ratio = data.ratio;
    }

    let imageWidth: number;
    let imageHeight: number;

    if (ratio >= 1) {
      imageWidth = maxWidth;
      imageHeight = imageWidth / ratio;
    } else {
      imageHeight = maxHeight;
      imageWidth = imageHeight * ratio;
    }

    setWidth(imageWidth);
    setHeight(imageHeight);
  };

  const _generateThumbnailArray = (message: UserMessage | FileMessage) => {
    const newThumbnailList: IPicture[] = [];
    const data = _parseData(message.data);
    const picture: IPicture = {
      senderName: message.sender.nickname,
      senderAvatar: message.sender.profileUrl,
      url: '',
    };

    if (message.customType === MessageType.Photo) {
      const fileMessage = message as FileMessage; // Cast this if we know that this is a photo

      if (fileMessage.thumbnails.length > 0) {
        try {
          fileMessage.thumbnails.forEach((thumbnail) => {
            newThumbnailList.push({ ...picture, url: thumbnail.url });
          });
        } catch {
          newThumbnailList.push({ ...picture, url: fileMessage.url });
        }
      } else if (fileMessage.url) {
        // Fallback for images that don't have thumbnails
        newThumbnailList.push({ ...picture, url: fileMessage.url });
        // @ts-ignore
      } else if (fileMessage.messageParams?.file?.uri) {
        newThumbnailList.push({
          ...picture,
          // @ts-ignore
          url: fileMessage.messageParams.file.uri,
        });
      }
    } else if (data != null && data.uri != null) {
      newThumbnailList.push({ ...picture, url: data.uri });
    } else if ((message as FileMessage).url !== null) {
      // TODO: Remove this, this is support for legacy messages
      newThumbnailList.push({ ...picture, url: (message as FileMessage).url });
    }

    // @ts-ignore
    setThumbnailList(newThumbnailList);
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  useEffect(() => {
    _parseData(message.data);
    _setDimensions(message);
    _generateThumbnailArray(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <>
      <View style={styles.imageMessageContainer}>
        <TouchableOpacity
          onPress={_showLightbox}
          activeOpacity={0.5}
          onLongPress={Platform.OS === 'web' ? undefined : onLongPress}
          delayLongPress={200}
          disabled={disabled}
        >
          <ImageLoader
            // @ts-ignore
            style={[
              Platform.OS !== 'web' && { flex: 1 },
              { height },
              width ? { width } : {},
            ]}
            thumbnails={thumbnailList}
            showLoadingIndicator={progress ? false : false}
          />
        </TouchableOpacity>
        {(message.sendingStatus === 'pending' || progress) && (
          <View style={{ margin: Spacing.l }}>
            <KitProgress
              percentage={
                progress
                  ? Math.floor((progress.loaded / progress.total) * 100)
                  : message.sendingStatus === 'pending'
                  ? 10
                  : 0
              }
            />
          </View>
        )}
      </View>
      {Platform.OS === 'web'
        ? lightboxVisible && (
            <Portal>
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0,0,0,0.75)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                role='button'
                onClick={() => setLightboxVisible(false)}
              >
                <img
                  style={{ maxWidth: '100%' }}
                  // @ts-ignore
                  src={thumbnailList[thumbnailList.length - 1]?.url}
                />
                <KitTouchableIcon
                  style={{ position: 'absolute', top: 10, right: 10 }}
                  size={20}
                  color='white'
                  onPress={() => setLightboxVisible(false)}
                  name='remove'
                />
              </div>
            </Portal>
          )
        : lightboxVisible && (
            <Modal
              visible={lightboxVisible}
              animationType='slide'
              statusBarTranslucent={true}
              hardwareAccelerated={true}
              onRequestClose={() => {
                setLightboxVisible(false);
              }}
            >
              {Platform.OS === 'android' && (
                <View style={{ height: 24, backgroundColor: 'black' }} />
              )}
              <LightboxModal
                setVisible={setLightboxVisible}
                // @ts-ignore
                message={message}
                picture={thumbnailList[thumbnailList.length - 1]}
                pictureStyle={{ flex: 1, width, height }}
              />
            </Modal>
          )}
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  imageMessageContainer: {
    borderRadius: 12,
    backgroundColor: colorForScheme({ default: Colors.N50 }),
    overflow: 'hidden',
  },
});
