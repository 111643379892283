import { IRootProps } from '@omni/kit/contexts/types';
import React, { Dispatch, createContext, useContext, useState } from 'react';

import { IPrinter, LabelTemplates } from '../../Types';
import { useSetupCode } from '../../contexts/KioskContext/useSetupCode';
import usePrinterStatus, { IPrinterStatus } from '../../hooks/usePrinterStatus';
import CheckInService from '../../services/CheckInService';
import { ITokenStatus } from '../../services/CheckInService/Core/GetTokenData';
import { Kiosk } from './types';
import useDeviceName from './useDeviceName';
import useDirectPrintStation from './useDirectPrintStation';
import useKioskAppKey from './useKioskAppKey';
import useKioskDevice from './useKioskDevice';
import useKioskSelected from './useKioskSelected';
import useKioskToken from './useKioskToken';
import useLabelSizeId, { type ILabelSize } from './useLabelSizeId';
import usePrinter from './usePrinter';
import useSelectedPrintStation from './useSelectedPrintStation';

export const DEFAULT_APP_KEY = 'KIOSKS';

interface IKioskContext {
  loading: boolean;
  appKey: string;
  setAppKey: (appKey: string) => Promise<void>;
  appKeyLoading: boolean;
  token: string;
  tokenStatus: ITokenStatus;
  resetToken: () => void;
  checkRefreshToken: () => Promise<void>;
  handleSetupCode: (props?: {
    app_key?: string;
    org_key?: string;
    short_code?: string;
    long_code?: string;
  }) => Promise<string | undefined>;
  selectedKiosk: Kiosk | null | undefined;
  setSelectedKiosk: (kiosk: Kiosk | null | undefined) => Promise<void>;
  printer: IPrinter | undefined;
  setPrinter: (printer?: IPrinter) => Promise<void>;
  printerStatus: IPrinterStatus | undefined;
  directPrintStationId: string | undefined;
  setDirectPrintStationId: (
    directPrintStationId: string | undefined
  ) => Promise<void>;
  kioskDeviceId: string | undefined;
  setKioskDeviceId: (kioskDeviceId: string | undefined) => Promise<void>;
  selectedPrintStationId: string | undefined;
  setSelectedPrintStationId: (
    selectedPrintStationId: string | undefined
  ) => Promise<void>;
  labelSizeId: ILabelSize | undefined;
  setLabelSizeId: (labelSizeId: ILabelSize | undefined) => Promise<void>;
  deviceName: string | undefined;
  setDeviceName: (deviceName: string | undefined) => Promise<void>;
  resetDeviceName: () => void;
  unlockCode: string | undefined;
  setUnlockCode: Dispatch<React.SetStateAction<string | undefined>>;
  resetKioskState: () => Promise<void>;
  labelTemplates: LabelTemplates | undefined;
  setLabelTemplates: Dispatch<React.SetStateAction<LabelTemplates | undefined>>;
  isTyping: boolean;
  setIsTyping: Dispatch<React.SetStateAction<boolean>>;
  modalInteraction: boolean;
  setModalInteraction: Dispatch<React.SetStateAction<boolean>>;
}

const KioskContext = createContext<IKioskContext>({
  loading: true,
  appKey: DEFAULT_APP_KEY,
  setAppKey: () => Promise.resolve(),
  appKeyLoading: true,
  token: '',
  tokenStatus: ITokenStatus.Missing,
  resetToken: () => undefined,
  checkRefreshToken: () => Promise.resolve(),
  handleSetupCode: () => Promise.resolve(undefined),
  selectedKiosk: undefined,
  setSelectedKiosk: () => Promise.resolve(),
  printer: undefined,
  setPrinter: () => Promise.resolve(),
  printerStatus: undefined,
  directPrintStationId: undefined,
  setDirectPrintStationId: () => Promise.resolve(),
  kioskDeviceId: undefined,
  setKioskDeviceId: () => Promise.resolve(),
  selectedPrintStationId: undefined,
  setSelectedPrintStationId: () => Promise.resolve(),
  labelSizeId: undefined,
  setLabelSizeId: () => Promise.resolve(),
  deviceName: undefined,
  setDeviceName: () => Promise.resolve(),
  resetDeviceName: () => undefined,
  unlockCode: undefined,
  setUnlockCode: () => Promise.resolve(),
  resetKioskState: () => Promise.resolve(),
  labelTemplates: undefined,
  setLabelTemplates: () => Promise.resolve(),
  isTyping: false,
  setIsTyping: () => Promise.resolve(),
  modalInteraction: false,
  setModalInteraction: () => Promise.resolve(),
});

export const KioskContextProvider = ({
  props,
  children,
}: {
  props?: IRootProps;
  children?: React.ReactNode;
}): JSX.Element | null => {
  const { appKey, setAppKey, appKeyLoading } = useKioskAppKey();
  const { selectedKiosk, setSelectedKiosk, selectedKioskLoading } =
    useKioskSelected();
  const [printer, setPrinter] = usePrinter();
  const [selectedPrintStationId, setSelectedPrintStationId] =
    useSelectedPrintStation();
  const {
    directPrintStationId,
    setDirectPrintStationId,
    directPrintStationIdLoading,
  } = useDirectPrintStation();
  const { kioskDeviceId, setKioskDeviceId, kioskDeviceIdLoading } =
    useKioskDevice();
  const [labelSizeId, setLabelSizeId] = useLabelSizeId();
  const { deviceName, setDeviceName, resetDeviceName, deviceNameLoading } =
    useDeviceName();
  const [unlockCode, setUnlockCode] = useState<string | undefined>();
  const [labelTemplates, setLabelTemplates] = useState<
    LabelTemplates | undefined
  >();
  const printerStatus = usePrinterStatus(printer);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [modalInteraction, setModalInteraction] = useState<boolean>(false);

  const {
    token,
    tokenLoading,
    tokenStatus,
    setToken,
    setTokenStatus,
    checkRefreshToken,
    resetToken,
  } = useKioskToken();

  const { handleSetupCode } = useSetupCode(
    setAppKey,
    setToken,
    setTokenStatus,
    setSelectedKiosk,
    setKioskDeviceId
  );

  return (
    <KioskContext.Provider
      value={{
        loading:
          appKeyLoading ||
          tokenLoading ||
          deviceNameLoading ||
          selectedKioskLoading ||
          kioskDeviceIdLoading ||
          directPrintStationIdLoading,
        appKey,
        setAppKey,
        appKeyLoading,
        token,
        tokenStatus,
        resetToken,
        checkRefreshToken,
        handleSetupCode,
        selectedKiosk,
        setSelectedKiosk,
        printer,
        printerStatus,
        setPrinter: async (p) => {
          await setPrinter(p);
        },
        directPrintStationId,
        setDirectPrintStationId,
        kioskDeviceId,
        setKioskDeviceId,
        selectedPrintStationId,
        setSelectedPrintStationId,
        labelSizeId,
        setLabelSizeId,
        deviceName,
        setDeviceName,
        resetDeviceName,
        unlockCode,
        setUnlockCode,
        resetKioskState: async () => {
          /**
           * setAppKey(DEFAULT_APP_KEY) will trigger an effect in the KioskNavigator
           * to re-render the navigator so that the KioskManager will be unmounted
           * as expected to prevent unnecessary requests in the KioskManager as the state is being reset.
           */
          await setAppKey(DEFAULT_APP_KEY);

          setSelectedPrintStationId(undefined);
          setLabelSizeId(undefined);
          setPrinter(undefined);
          setSelectedKiosk(undefined);
          setUnlockCode(undefined);

          /**
           * The 'directPrintStationId' must be cleared last
           * to prevent a auto-create print station side effect KioskManager
           */
          setDirectPrintStationId(undefined);
          setKioskDeviceId(undefined);

          await CheckInService.ClearCache();

          await resetDeviceName();
          await resetToken();
        },
        labelTemplates,
        setLabelTemplates,
        isTyping,
        setIsTyping,
        modalInteraction,
        setModalInteraction,
      }}
    >
      {children}
    </KioskContext.Provider>
  );
};

export const useKioskContext = (): IKioskContext => {
  return useContext(KioskContext);
};
