import KitTouchable from '@omni/kit/components/KitTouchable';
import { animatePressIn, animatePressOut } from '@omni/kit/theming/Animating';
import Colors from '@omni/kit/theming/Colors';
import React from 'react';
import { Animated, ColorValue, ViewStyle } from 'react-native';

export default function ButtonTouchable({
  children,
  onPress = () => null,
  style = {},
  underlayColor = Colors.N900,
}: {
  children?: React.ReactNode;
  onPress?: () => void;
  style?: ViewStyle;
  underlayColor?: ColorValue;
}): JSX.Element {
  const scale = new Animated.Value(1);

  const _onPressIn = (): void => {
    animatePressIn(scale);
  };

  const _onPressOut = (): void => {
    animatePressOut(scale);
  };

  return (
    <Animated.View style={{ transform: [{ scale: scale }] }}>
      <KitTouchable
        onPress={onPress}
        style={style}
        underlayColor={underlayColor}
        onPressIn={_onPressIn}
        onPressOut={_onPressOut}
        ripple={false}
      >
        {children}
      </KitTouchable>
    </Animated.View>
  );
}
