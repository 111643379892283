import { ICategory } from './types';

export const STORAGE_KEY = 'emoji:history';

export const CATEGORIES: ICategory[] = [
  {
    name: 'Recently used',
    slug: 'history',
    symbol: '🕘',
  },
  {
    name: 'Smileys & People',
    slug: 'emotion',
    symbol: '😊',
  },
  // {
  //   name: 'Smileys & Emotion',
  //   slug: 'emotion',
  //   symbol: '😊',
  // },
  // {
  //   name: 'People & Body',
  //   slug: 'people',
  //   symbol: '🧑',
  // },
  {
    name: 'Animals & Nature',
    slug: 'nature',
    symbol: '🌱',
  },
  {
    name: 'Food & Drink',
    slug: 'food',
    symbol: '🍔',
  },
  {
    name: 'Activities',
    slug: 'activities',
    symbol: '⚾️',
  },
  {
    name: 'Travel & Places',
    slug: 'places',
    symbol: '✈️',
  },
  {
    name: 'Objects',
    slug: 'objects',
    symbol: '💡',
  },
  {
    name: 'Symbols',
    slug: 'symbols',
    symbol: '🔣',
  },
  {
    name: 'Flags',
    slug: 'flags',
    symbol: '🚩',
  },
];
