import { AuthProvider } from './Types';

const debug = require('debug')('tca:auth:utilities');

export const isUrlAuthorizedForProviders = ({
  providers,
  url,
}: {
  providers: AuthProvider[];
  url: string;
}): boolean => {
  /**
   * Special case to use hardcoded domain whitelist for the subsplash provider.
   * There are several benefits to this:
   *
   * 1. Optimization: Allows content to load faster that relies on subsplash domains
   * since we can skip the network call that obtains the subsplash provider content_domain.
   *
   * 2. Security: The backend does not yet provide any content_domain for
   * the subsplash provider. Providing a hardcoded set within the project is
   * a big security improvement while we wait on backend to provide content_domain.
   *
   * 3. Convenience: Callers should not have to pass through an appKey and orgKey
   * prop if we are making request using subsplash domains.
   * e.g. see usage of 'downloadUrl' in kit/hooks/useMediaStatus.ts
   */
  if (isUrlAuthorizedForSubsplashDomains(url)) {
    return true;
  }

  return Boolean(getAuthorizedProvider({ providers, url }));
};

export const getAuthorizedProvider = ({
  providers,
  url,
}: {
  providers: AuthProvider[] | undefined;
  url: string;
}): AuthProvider | undefined => {
  const authorizedProvider = providers?.find(
    (provider) =>
      provider.content_domain &&
      isUrlAuthorizedForContentDomain(url, provider.content_domain)
  );

  return authorizedProvider;
};

/**
 * Check to see if the host ends with a valid domain
 * e.g. host = secure.subsplash.com, authorizedDomain = subsplash.com
 */
export const isUrlAuthorizedForContentDomain = (
  url: string,
  content_domain: string[]
): boolean => {
  let authorized = false;

  const host = getHostName(url);

  if (host) {
    const match = content_domain.find((authorizedSuffix) =>
      host.endsWith(authorizedSuffix)
    );

    authorized = Boolean(match);
  }

  return authorized;
};

const getHostName = (url: string): string | undefined => {
  let hostName: string | undefined;
  try {
    /**
     * Note that parsing the url with `new URL` may fail in react-native
     * on certain urls by default. A polyfill is required for this to work
     * as expected on iOS and Android.
     * The native index.js must import 'react-native-url-polyfill/auto'.
     * ref: https://github.com/facebook/react-native/issues/23922#issuecomment-648096619
     * ref: https://www.npmjs.com/package/react-native-url-polyfill
     */
    const { host } = new URL(url);
    hostName = host;
  } catch (error) {
    debug(`Unable to resolve domain: ${error}`);
  }

  return hostName;
};

/*
 * isUrlAuthorizedForSubsplashDomains
 *
 * This function checks the domain suffix of the url to ensure that it ends with
 * subsplash.com or subsplash.net. Normally we would obtain the content_domain
 * from real auth provider data. But this function allows us to bypass using real data
 * in cases where we are certain we are making requests to subsplash domains.
 * This helps maintain security without invasive changes or high regression risk for the 5.17 release.
 *
 * TODO: only validate content domains using real auth provider data.
 * This would be a large refactor to pass props for authProviderId and targetProviders
 * through all callers of BaseService and BaseServiceV2.
 */
export const isUrlAuthorizedForSubsplashDomains = (url: string): boolean => {
  const subsplashContentDomains = ['subsplash.com', 'subsplash.net'];

  return isUrlAuthorizedForContentDomain(url, subsplashContentDomains);
};

export const parseTokenUrlForAuthProviderId = (tokenUrl: string): string => {
  const parsed = tokenUrl.split('auth-providers/')[1];
  const authProviderId = parsed.split('/token')[0];

  return authProviderId;
};
