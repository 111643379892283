import { KitText } from '@omni/kit/components';
import KitCheckmark from '@omni/kit/components/KitCheckmark';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { useEmojiPickerContext } from '@omni/media/liveChat/shared/context/EmojiPickerContext';
import autosize from 'autosize';
import React, { useEffect, useRef, useState } from 'react';
import {
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import ParsedText from 'react-native-parsed-text';
import { useSelector } from 'react-redux';

import {
  memberListSelector,
  sendToChannelSelector,
} from '../../../shared/redux/selectors';
import { MessageType } from '../../../shared/redux/types';
import WebActionBar from '../../../web/scenes/channel/components/WebActionBar';
import {
  ChannelMember,
  CustomMessageData,
  SendbirdFileMessage,
  SendbirdUserMessage,
} from '../../Types';
import ActionBar from './ActionBar';
import MentionList from './MentionList';
import { MessageInputIcon as Icon } from './MessageInputIcon';
import MessageTypeActionSheet from './MessageTypeActionSheet';

interface WebInputProps {
  actionShowNonText: boolean;
  hideActionBar?: boolean;
  onBackspace?: () => void;
  onChangeText?: (text: string) => void;
  onEmojiSelected?: (e: string) => void;
  onEnter?: () => void;
  placeholder: string;
  value: string;
}

const WebInput = React.forwardRef<HTMLTextAreaElement, WebInputProps>(
  (
    {
      actionShowNonText,
      hideActionBar,
      onBackspace,
      onChangeText,
      onEmojiSelected,
      onEnter,
      placeholder,
      value,
    },
    ref
  ) => (
    <div style={webStyles.inputContainer}>
      <textarea
        onChange={({ target }) => {
          onChangeText?.(target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            onEnter?.();
          } else if (event.key === 'Backspace') {
            onBackspace?.();
          }
        }}
        placeholder={placeholder}
        ref={ref}
        rows={1}
        style={webStyles.input}
        value={value}
        data-testid='web-message-input'
      />
      {!hideActionBar && (
        <WebActionBar
          showNonText={actionShowNonText}
          onEmojiSelected={onEmojiSelected}
        />
      )}
    </div>
  )
);

export interface IFile {
  customType: MessageType;
  data?: CustomMessageData;
  name: string;
  type: string;
  uri: string;
}

export interface MessageInputProps {
  autoFocus?: boolean;
  channelName?: string;
  disabled?: boolean;
  hideDrawer?: boolean;
  isEditing: boolean;
  isThread?: boolean;
  onCancelEditingPress: () => void;
  onChangeText: (value: string) => void;
  onConfirmEditingPress: () => void;
  onMediaMessageSend?: () => void;
  onPrayerRequestPress?: () => void;
  onSendPress: (mentions: string[]) => void;
  onToggleSendToChannel?: () => void;
  selectedMessage?: SendbirdUserMessage | SendbirdFileMessage | null;
  textChanged: boolean;
  value: string;
}

export default function MessageInput({
  // Data
  autoFocus,
  channelName,
  disabled,
  hideDrawer,
  isEditing,
  isThread,
  selectedMessage,
  textChanged,
  value,

  // Methods
  onCancelEditingPress,
  onChangeText,
  onConfirmEditingPress,
  onMediaMessageSend,
  onSendPress,
  onToggleSendToChannel,
}: MessageInputProps): JSX.Element {
  /** Filtered list of members in the popup. */
  const [mentionList, setMentionList] = useState<ChannelMember[]>([]);
  /** User IDs that get sent to sendbird. */
  const [mentionedUsers, setMentionedUsers] = useState<string[]>([]);
  /** Mentioned users in this message, for rendering purposes. */
  const [mentionedUserObjects, setMentionedUserObjects] = useState<
    ChannelMember[]
  >([]);
  const [addonShowing, setAddonShowing] = useState(false);

  const { sizeClass } = useSizeClass();
  const members = useSelector(memberListSelector);
  const sendToChannel = useSelector(sendToChannelSelector);
  const inputRef = useRef<TextInput | null>(null);
  const webInput = useRef<HTMLTextAreaElement | null>(null);

  const { selectedEmoji, setIsEmojiPickerOpen } = useEmojiPickerContext();

  const handleMention = (member: ChannelMember) => {
    let newValue = value;
    const lastIndexOfAt = newValue.lastIndexOf('@');
    newValue = `${newValue.slice(0, lastIndexOfAt + 1)}${member.nickname} `;
    onChangeText(newValue);
    setMentionList([]);
    inputRef?.current?.focus();

    if (!mentionedUsers.includes(member.userId)) {
      const newMentionedUsers = [...mentionedUsers, member.userId];
      setMentionedUsers(newMentionedUsers);
      const newMentionedUserObjects = [...mentionedUserObjects, member];
      setMentionedUserObjects(newMentionedUserObjects);
    }
  };

  const handleSend = () => {
    setMentionList([]);
    setMentionedUsers([]);
    setMentionedUserObjects([]);
    onSendPress(mentionedUsers);
  };

  const onBackspace = () => {
    if (value.charAt(value.length - 1) === '@') {
      setMentionList([]);
    }

    mentionedUserObjects.forEach((user) => {
      if (value.includes(user.nickname)) return;

      const newMentionedUsers = mentionedUsers.filter((u) => u !== user.userId);
      const newMentionedUserObjects = mentionedUserObjects.filter(
        (userObject) => userObject.userId !== user.userId
      );
      setMentionedUsers(newMentionedUsers);
      setMentionedUserObjects(newMentionedUserObjects);
    });
  };

  const isWeb = Platform.OS === 'web';
  const isSmall = sizeClass === SizeClass.Small;

  useEffect(() => {
    if (isWeb && webInput.current) {
      autosize(webInput.current);
    }
  }, []);
  useEffect(() => {
    if (isWeb && webInput.current) {
      autosize.update(webInput.current);
    }
  }, [value]);

  useEffect(() => {
    if (!isEditing) {
      inputRef?.current?.blur();

      return;
    }

    if (!selectedMessage) inputRef?.current?.focus();
  }, [isEditing, selectedMessage]);

  useEffect(() => {
    if (
      (value.slice(-2) === ' @' || value === '@') &&
      value.charAt(value.length - 1) === '@'
    ) {
      setMentionList(members);
    }

    if (!value.includes('@') || !canSend) setMentionList([]);

    const lastIndexOfAt = value.lastIndexOf('@');

    if (
      lastIndexOfAt >= 0 &&
      (value.slice(lastIndexOfAt - 1, lastIndexOfAt + 1) === ' @' ||
        lastIndexOfAt === 0)
    ) {
      const query = value.slice(lastIndexOfAt + 1);
      const filteredMembers = members.filter((m) =>
        m.nickname.toLowerCase().includes(query.toLowerCase())
      );
      setMentionList(filteredMembers);
    }
  }, [members, value]);

  useEffect(() => {
    if (selectedEmoji) onChangeText(value + selectedEmoji.emoji);
  }, [selectedEmoji]);

  const inputProps = {
    autoFocus,
    multiline: true,
    onChangeText,
    placeholderTextColor: Colors.N400,
  };

  const placeholder = isThread
    ? 'Reply to thread...'
    : channelName
    ? `Message ${channelName}`
    : 'Message';

  const canSend = !disabled && value.length;
  const canUpdate = canSend && textChanged;

  const onEmojiSelected = (e: string) => {
    onChangeText?.(value + e);
    setIsEmojiPickerOpen(false);
  };

  return (
    <SafeAreaView>
      <View style={{ position: 'relative' }}>
        <MentionList list={mentionList} mentionMember={handleMention} />
      </View>
      <View style={styles.inputContainer}>
        {isWeb ? (
          <WebInput
            hideActionBar={hideDrawer || isSmall}
            onBackspace={onBackspace}
            onChangeText={onChangeText}
            onEmojiSelected={onEmojiSelected}
            actionShowNonText={!isEditing && !canSend}
            onEnter={() => {
              if (isEditing) {
                onConfirmEditingPress();
              } else {
                handleSend();
              }
            }}
            placeholder={placeholder}
            ref={webInput}
            value={value}
          />
        ) : (
          // Mobile Input
          <TextInput
            {...inputProps}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === 'Backspace') onBackspace();
            }}
            placeholder={
              placeholder && placeholder.length > 25
                ? placeholder.slice(0, 25) + '...'
                : placeholder
            }
            ref={inputRef}
            style={styles.textInput}
          >
            <ParsedText
              parse={[
                ...((mentionedUserObjects?.map((user) => ({
                  pattern: new RegExp(`@${user.nickname}`),
                  style: { color: Colors.brand, fontWeight: 'bold' },
                })) || []) as Readonly<
                  Array<{ pattern: RegExp; style: Record<string, any> }>
                >),
                { pattern: /\*.*\*/, style: { fontWeight: 'bold' } },
                { pattern: /_.*_/, style: { fontStyle: 'italic' } },
              ]}
            >
              {value}
            </ParsedText>
          </TextInput>
        )}
        {isSmall || !isWeb ? (
          <Icon
            icon='emoji'
            onPress={() => {
              setIsEmojiPickerOpen(true);
              Keyboard.dismiss();
            }}
          />
        ) : null}

        {isEditing && <Icon icon='remove' onPress={onCancelEditingPress} />}

        {isEditing ? (
          <Icon
            icon='check'
            color={canUpdate ? Colors.brand : undefined}
            onPress={canUpdate ? onConfirmEditingPress : undefined}
          />
        ) : canSend ? (
          <Icon icon='send' color={Colors.brand} onPress={handleSend} />
        ) : !(Platform.OS === 'web' && !isSmall) ? (
          <Icon
            border
            icon={addonShowing ? 'remove-s' : 'add-s'}
            onPress={() => setAddonShowing((prev) => !prev)}
          />
        ) : null}
      </View>

      {/* Custom message action bar, only applicable in small-screen web */}
      {addonShowing && !isEditing && isWeb && isSmall && (
        <ActionBar onMessageSent={onMediaMessageSend} />
      )}

      {isThread && onToggleSendToChannel && channelName && (
        <View style={styles.checkmarkContainer}>
          <KitCheckmark
            checked={sendToChannel}
            onToggle={onToggleSendToChannel}
          />
          <KitText
            black
            fontSize={14}
            numberOfLines={1}
            semiBold
            style={styles.checkmarkLabel}
          >
            Also send to {channelName}
          </KitText>
        </View>
      )}

      {!isWeb && (
        <MessageTypeActionSheet
          isVisible={addonShowing}
          onMessageSent={onMediaMessageSend}
          setVisible={setAddonShowing}
        />
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  checkmarkContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: Spacing.xs,
    marginRight: Spacing.m,
    overflow: 'hidden',
  },
  checkmarkLabel: {
    flex: 1,
  },
  inputContainer: {
    alignItems: 'flex-end',
    backgroundColor: Colors.N100,
    borderRadius: 30,
    flexDirection: 'row',
    marginHorizontal: Spacing.m,
    marginVertical: Spacing.s,
    overflow: 'hidden',
    paddingLeft: Spacing.l,
    paddingRight: Spacing.m,
    paddingVertical: Spacing.m,
    position: 'relative',
  },
  textInput: {
    ...Platform.select({
      android: { marginVertical: -Spacing.xs },
      ios: { marginTop: -Spacing.xs },
      web: { outlineWidth: 0 },
    }),
    color: colorForScheme({ default: Colors.N900 }),
    flex: 1,
    fontSize: 16,
    maxHeight: 128,
    padding: 0,
  },
});

const webStyles = {
  input: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    boxSizing: 'border-box',
    color: Colors.N900,
    flex: 1,
    fontFamily: 'inherit',
    fontSize: '15px',
    lineHeight: '1.2rem',
    maxHeight: 128,
    outlineWidth: 0,
    padding: 0,
    resize: 'none',
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
} as const;
