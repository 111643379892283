/**
 * Transforms an average color and vibrant color combination into a mixed color
 * by applying the vibrant color on top of the average at 70%, then tinting that
 * result with black at 20% opacity.
 *
 * This satisfies design wishes to have useful color to compliment artwork and
 * allow for text on top and still being readable.
 */
export function getVibrantTint(
  averageColor: string,
  vibrantColor: string = averageColor,
  alphaOut = 1
): string {
  let [r, g, b] = hexToRGBA(averageColor);
  const [vr, vg, vb] = hexToRGBA(vibrantColor);

  // Calculate vibrant overlay on top of average color at 70% opacity;
  const va = 0.7;
  r = r * (1 - va) + va * vr;
  g = g * (1 - va) + va * vg;
  b = b * (1 - va) + va * vb;

  // Apply black tint at 20% opacity
  const ta = 0.2;
  r = r * (1 - ta);
  g = g * (1 - ta);
  b = b * (1 - ta);

  return `rgba(${Math.ceil(r)},${Math.ceil(g)},${Math.ceil(b)},${alphaOut})`;
}

export function hexToRGBA(
  hex: string
): [r: number, g: number, b: number, a: number] {
  const [r, g, b, a] =
    hex
      .replace('#', '')
      .match(/\w\w/g)
      ?.map((x) => parseInt(x, 16)) ?? [];
  const alpha = a ? a / 255 : 1;

  return [r ?? 0, g ?? 0, b ?? 0, alpha];
}
