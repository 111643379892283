import { BibleBook, BibleLocation } from '../types';
import { FlatList, Platform, StyleSheet, View } from 'react-native';
import React, { useContext } from 'react';

import BibleNavModalItem from '../screens/BibleNavModalItem';
import Colors from '@omni/kit/theming/Colors';
import { KitTouchableIcon } from '@omni/kit/components';
import LinearGradient from 'react-native-linear-gradient';
import { SCREEN_WIDTH } from '@omni/kit/utilities/utilities';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
  bibleBooks: BibleBook[];
  bibleLocation?: BibleLocation;
  onSelectChapter?: (chapter: number, osis: string) => void;
  toggleModal?: () => void;
};

export default function BibleNavContent({
  bibleBooks = [],
  bibleLocation = { chapter: -1, bookName: '' },
  onSelectChapter = undefined,
  toggleModal = undefined,
}: Props): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);
  const insets = useSafeAreaInsets();

  const bibleBookExpandedState = Array(bibleBooks.length).fill(false);
  const flatListProps: { initialScrollIndex?: number } = {};
  const initialIndex = bibleBooks.findIndex(
    (b) => b.osis === bibleLocation.bookName
  );

  if (initialIndex >= 0) {
    bibleBookExpandedState[initialIndex] = true;
    flatListProps.initialScrollIndex = initialIndex;
  }

  const _renderItem = ({
    index,
    item: book,
  }: {
    index: number;
    item: BibleBook;
  }) => {
    return (
      <BibleNavModalItem
        bibleLocation={bibleLocation}
        book={book}
        index={index}
        initialExpandedState={bibleBookExpandedState[index]}
        onExpanded={_onExpanded}
        selectNewChapter={_selectNewChapter}
      />
    );
  };

  // preserve expanded state for case where item is re-rendered when it is scrolled back on screen
  // otherwise the expanded state will be reset for the item
  const _onExpanded = (index: number, expanded: boolean) => {
    bibleBookExpandedState[index] = expanded;
  };

  const _selectNewChapter = (chapter: number, osis: string) => {
    onSelectChapter && onSelectChapter(chapter, osis);
  };

  return (
    <View
      style={{
        backgroundColor: colorForScheme?.({
          dark: Colors.N1000,
          light: Colors.N0,
        }),
        ...Platform.select({ web: { flex: 1 } }),
        marginBottom: insets.bottom,
      }}
    >
      {toggleModal && (
        <View style={[styles.header, { paddingTop: insets.top - 50 }]}>
          {/* the 50 above is the height of the app top nav, the insets incorrectly include that */}
          <KitTouchableIcon
            color={colorForScheme?.({ light: Colors.N900, dark: Colors.N0 })}
            name='close'
            onPress={toggleModal}
          />
        </View>
      )}

      <View
        style={{
          zIndex: 6,
          position: 'relative',
          ...Platform.select({ web: { flex: 1 } }),
        }}
      >
        <LinearGradient
          colors={[
            colorForScheme?.({
              dark: '#000000ff',
              light: '#ffffffff',
            }) as string,
            colorForScheme?.({
              dark: '#00000000',
              light: '#ffffff00',
            }) as string,
          ]}
          style={{
            backgroundColor: '#ffffff00',
            height: 20,
            left: 0,
            position: 'absolute',
            top: 0,
            width: SCREEN_WIDTH,
            zIndex: 7,
          }}
        />
        <FlatList
          {...flatListProps}
          data={bibleBooks}
          getItemLayout={(_data, index) => ({
            index,
            length: 60,
            offset: 60 * index,
          })}
          initialNumToRender={bibleBooks.length}
          keyExtractor={(item) => item.display}
          ListHeaderComponent={() => <View style={{ height: 20 }} />}
          ListFooterComponent={() => (
            <View
              style={{
                width: 50,
                ...Platform.select({ native: { height: 500 } }),
              }}
            />
          )}
          renderItem={_renderItem}
          style={{ ...Platform.select({ web: { height: 500 } }) }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    paddingLeft: 12,
    zIndex: 1,
  },
});
