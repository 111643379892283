import {
  sbGetSnoozePeriod,
  sbSetSnoozePeriod,
} from '../utilities/sendbird/chatFunctions';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { userSelector } from '../shared/redux/selectors';

const debug = require('debug')('tca:chat:hooks:useSnoozeNotifications');

interface SnoozePeriod {
  isSnoozeOn: boolean;
  endAt: number | null;
}

export default function useSnoozeNotifications(): {
  snoozePeriod: SnoozePeriod;
  _setSnoozePeriod: (
    snoozeOn: boolean,
    start: number,
    end: number
  ) => Promise<void>;
} {
  const user = useSelector(userSelector);
  const [snoozePeriod, setSnoozePeriod] = useState<SnoozePeriod>({
    isSnoozeOn: false,
    endAt: null,
  });

  const _setSnoozePeriod = async (
    snoozeOn: boolean,
    start: number,
    end: number
  ): Promise<void> => {
    const { endTs } = await sbSetSnoozePeriod(snoozeOn, start, end);

    if (endTs && snoozeOn) {
      setSnoozePeriod({ isSnoozeOn: true, endAt: endTs });
    } else {
      setSnoozePeriod({ isSnoozeOn: false, endAt: null });
    }
  };

  useEffect(() => {
    if (!user) return;

    sbGetSnoozePeriod()
      .then(({ isSnoozeOn, endTs }) => {
        const now = Date.now();

        /** isSnoozeOn may be true even if endTs is in the past. */
        setSnoozePeriod(
          isSnoozeOn && endTs && endTs > now
            ? { isSnoozeOn: true, endAt: endTs }
            : { isSnoozeOn: false, endAt: null }
        );
      })
      .catch((error) => {
        debug(error);
      });
  }, [user]);

  return { snoozePeriod, _setSnoozePeriod } as const;
}
