import { KitIcon, KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

interface ActionButtonProps {
  as?: 'button' | 'label';
  children?: React.ReactNode;
  icon: string;
  label: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

const ActionButtonWeb = React.forwardRef(
  (
    {
      as = 'button',
      children,
      icon,
      label,
      onPress,
      style = {},
      ...rest
    }: ActionButtonProps,
    ref: any
  ) => {
    const [isPressed, setIsPressed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const TagName = as;

    return (
      <TagName
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={buttonWebStyles(isPressed, isHovered)}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        onClick={onPress}
        ref={ref}
        {...rest}
      >
        {children}
        <View
          style={[
            // @ts-ignore
            styles.actionButton,
            { backgroundColor: 'transparent' },
            style,
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* @ts-ignore */}
            <KitIcon name={icon} size={22} color={Colors.N900} />
            <KitText black semiBold style={{ marginLeft: Spacing.m }}>
              {label}
            </KitText>
          </View>
        </View>
      </TagName>
    );
  }
);

const styles = {
  actionButton: {
    borderRadius: 80,
    backgroundColor: Colors.N100,
    paddingVertical: Spacing.s,
    paddingHorizontal: Spacing.xl,
    width: 'max-content', // This overrides a minWidth that is automatically set (so that the button doesn't wrap text)
  },
};

function buttonWebStyles(pressed: boolean, hovered: boolean) {
  return {
    borderWidth: 0,
    backgroundColor: pressed
      ? Colors.N300
      : hovered
      ? Colors.N200
      : Colors.N100,
    borderRadius: 80,
    marginLeft: Spacing.s,
    cursor: 'pointer',
  };
}

export default ActionButtonWeb;
