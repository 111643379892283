import Environment from '@omni/kit/Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '@omni/kit/services/BaseServiceV2';

import { CheckInDeviceResponse } from '../Types';

const HOST = Environment.checkInService;
const RESOURCE = 'devices';

interface IGetKioskDevice extends IUri {
  token?: string;
  id?: string;
}

interface IGetKioskDeviceResponse extends IHttpResponse {
  body?: CheckInDeviceResponse;
}

export default async function getKioskDevice({
  token,
  id,
}: IGetKioskDevice): Promise<IGetKioskDeviceResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    id,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
