import CreateGroupMember from './CreateGroupMember';
import CreateJoinRequest from './CreateJoinRequest';
import GetGroup from './GetGroup';
import UpdateGroup from './UpdateGroup';
import GetGroupMembers from './GetGroupMembers';
import GetGroupTypes from './GetGroupTypes';
import GetJoinRequest from './GetJoinRequest';
import GetJoinRequests from './GetJoinRequests';
import UpdateJoinRequest from './UpdateJoinRequest';

export default {
  CreateGroupMember,
  GetGroup,
  UpdateGroup,
  GetGroupMembers,
  GetGroupTypes,
  GetJoinRequests,
  CreateJoinRequest,
  GetJoinRequest,
  UpdateJoinRequest,
};
