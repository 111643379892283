import { ILabelSize } from '@omni/check-in/kiosk/contexts/KioskContext/useLabelSizeId';
import { NativeModules, Platform } from 'react-native';

import Environment from '../Environment';
import {
  IMediaPlaybackStateInfo,
  IPlaylistItemData,
} from '../components/KitPlayer/playbackTypes';
import { ThemeType } from '../theming/ThemeContext';

const debug = require('debug')('tca:kit:NativeHelpers');

//******************************************************************************
// Types
//******************************************************************************

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage(msg: string): void;
    };
  }

  type Uuid = string; // 11111111-2222-3333-4444-555555555555
}

export enum PresentationStyle {
  Default = 'default',
  Modal = 'modal',
  KitModal = 'kitModal', // supported in release 5.21+
}

export enum NativeTopBarStyle {
  Default = 'default',
  Hidden = 'hidden',
  Transparent = 'transparent',
  TransparentOnDark = 'transparentOnDark',
  TransparentOnLight = 'transparentOnLight',
  Light = 'light',
  Dark = 'dark',
}

export const getAppTheme = (): Promise<ThemeType | 'system'> =>
  new Promise<ThemeType>((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.getTheme((theme: ThemeType) => {
        resolve(theme);
      });
    } catch {
      reject(0);
    }
  });

//******************************************************************************
// Helpers
//******************************************************************************

export const showBottomTabBar = (
  isVisible: boolean,
  isAnimated: boolean
): void => {
  try {
    NativeModules.ReactPlatformBridge.setTabBarVisibility(
      isVisible,
      isAnimated
    );
  } catch {}
};

export const dispatchAction = (action: unknown): void => {
  try {
    NativeModules.ReactPlatformBridge.dispatchAction(action);
  } catch {
    debug('Failed to dispatch action');
  }
};

export const setMediaPlayDismissSwipeGestureEnabled = (
  enabled: boolean
): void => {
  try {
    NativeModules.ReactPlatformBridge.setMediaPlayDismissSwipeGestureEnabled(
      enabled
    );
  } catch {}
};

type DeviceType = 'handset' | 'tablet';

export const getDeviceType = (): Promise<DeviceType> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.getDeviceType(
        (deviceType: DeviceType) => {
          resolve(deviceType);
        }
      );
    } catch {
      reject('');
    }
  });

export const getMediaPlayDurationForKey = (key: string): Promise<number> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.getMediaPlayDurationForKey(
        key,
        (durationMsec: number) => {
          resolve(durationMsec);
        }
      );
    } catch {
      reject(0);
    }
  });

export const getMediaPlayPositionForKey = (key: string): Promise<number> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.getMediaPlayPositionForKey(
        key,
        (positionMsec: number) => {
          resolve(positionMsec);
        }
      );
    } catch {
      reject(0);
    }
  });

export const removeMediaPlayStateForKey = (key: string): Promise<void> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.removeMediaPlayStateForKey(key, () =>
        resolve()
      );
    } catch {
      reject(0);
    }
  });

export const setMediaPlayPositionForKey = (
  key: string,
  positionMsec: number
): Promise<void> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.setMediaPlayPositionForKey(
        key,
        positionMsec,
        () => resolve()
      );
    } catch {
      reject(0);
    }
  });

export const getNativeAppHeaders = (): Promise<string> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.getHeaders((headers: string) => {
        resolve(headers);
      });
    } catch {
      reject(0);
    }
  });

export const getNativeDebugInfo = (): Promise<string> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.getDebugInfo((debugText: string) => {
        resolve(debugText);
      });
    } catch {
      reject(0);
    }
  });

export const getNativeDeviceName = (): Promise<string> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.getDeviceName((deviceName: string) => {
        resolve(deviceName);
      });
    } catch {
      reject(0);
    }
  });

export const playPauseMediaPlayer = (): void => {
  try {
    NativeModules.ReactPlatformBridge.togglePlayPause();
  } catch {
    debug('Failed to toggle play / pause in media player');
  }
};

export const playPrevious = (): void => {
  try {
    NativeModules.ReactPlatformBridge.playPrevious();
  } catch {
    debug('Failed to toggle play / pause in media player');
  }
};

export const playNext = (): void => {
  try {
    NativeModules.ReactPlatformBridge.playNext();
  } catch {
    debug('Failed to toggle play / pause in media player');
  }
};

export const seekPlayerWithDelta = (seconds: number): void => {
  try {
    NativeModules.ReactPlatformBridge.seekPlayerWithDelta(seconds);
  } catch {
    debug('Failed to toggle play / pause in media player');
  }
};

export const setReactNativeAppIsAtRoot = (isAtRoot: boolean): void => {
  try {
    NativeModules.ReactPlatformBridge.setReactNativeAppIsAtRoot(isAtRoot);
  } catch {
    debug('Failed to set react native app isAtRoot');
  }
};

type ActionMenuCoordinateFrame = {
  height: number;
  width: number;
  x: number;
  y: number;
};

export const getActionMenuCoordinates = (): Promise<
  Partial<ActionMenuCoordinateFrame>
> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.getActionMenuCoordinates(
        (frame: Partial<ActionMenuCoordinateFrame>) => {
          resolve(frame);
        }
      );
    } catch {
      reject({});
    }
  });

type SafeAreaInsets = {
  bottom: number;
  left: number;
  right: number;
  top: number;
};

export const getSafeAreaInsets = (): Promise<Partial<SafeAreaInsets>> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.getSafeAreaInsets(
        (frame: Partial<SafeAreaInsets>) => {
          resolve(frame);
        }
      );
    } catch {
      reject({});
    }
  });

export const getNativeTopBarHeight = (): Promise<number> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.getTopBarHeight((height: number) => {
        debug('got a nice fancy height', height);
        resolve(height);
      });
    } catch {
      reject(0);
    }
  });

export const getMediaDownloadState = (mediaItemUrl: string): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.getMediaDownloadState(
        mediaItemUrl,
        (state: string) => {
          debug('download state', state);
          resolve(state);
        }
      );
    } catch {
      reject(0);
    }
  });

export const getMediaPlaybackStateInfo = (): Promise<IMediaPlaybackStateInfo> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.getMediaPlaybackStateInfo(
        (info: IMediaPlaybackStateInfo) => {
          resolve(info);
        }
      );
    } catch {
      reject(0);
    }
  });

export const getNowPlayingPlaylistData = (): Promise<IPlaylistItemData[]> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.getNowPlayingPlaylistData(
        (playlistData: IPlaylistItemData[]) => {
          resolve(playlistData);
        }
      );
    } catch {
      reject(0);
    }
  });

export const getGooglePlayServicesAvailable = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    if (Platform.OS !== 'android') {
      resolve(false);

      return;
    }

    try {
      NativeModules.ReactPlatformBridge.getGooglePlayServicesAvailable(
        (available: boolean) => resolve(available)
      );
    } catch {
      reject(0);
    }
  });

export const getRegisteredForPushNotifications = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.isActiveAppRegisteredForPushNotifications(
        (registered: boolean) => {
          resolve(registered);
        }
      );
    } catch {
      reject(0);
    }
  });

export const getPermissionDeterminedForPushNotifications =
  (): Promise<boolean> =>
    new Promise((resolve, reject) => {
      try {
        NativeModules.ReactPlatformBridge.isPermissionDeterminedForPushNotifications(
          (determined: boolean) => {
            resolve(determined);
          }
        );
      } catch {
        reject(0);
      }
    });

export const getPermissionEnabledForPushNotifications = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.isPermissionEnabledForPushNotifications(
        (enabled: boolean) => {
          resolve(enabled);
        }
      );
    } catch {
      reject(0);
    }
  });

export const getPushToken = (): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.getPushToken((token: string) => {
        resolve(token);
      });
    } catch {
      reject(0);
    }
  });

export const registerForPushNotifications = (): void => {
  try {
    NativeModules.ReactPlatformBridge.registerActiveAppForPushNotifications();
  } catch {}
};

export const registerForPushNotificationsWithNativeSoftPrompt = (): void => {
  try {
    NativeModules.ReactPlatformBridge.registerActiveAppForPushNotificationsWithNativeSoftPrompt();
  } catch {}
};

export const openInAppPushNotificationSettings = (
  activeAppKey?: string
): void => {
  if (!activeAppKey) return;

  try {
    NativeModules.ReactPlatformBridge.dispatchAction({
      handler: 'settings',
      url: `${Environment.feedsService}/menu/settings/topics/${activeAppKey}`,
      enableCache: false,
    });
  } catch {}
};

export const openSystemPushNotificationSettings = (): void => {
  try {
    NativeModules.ReactPlatformBridge.openSystemPushNotificationSettings();
  } catch {}
};

export const setTopBarStyle = (
  style: NativeTopBarStyle,
  updateInsets: boolean,
  animated: boolean
): void => {
  try {
    NativeModules.ReactPlatformBridge.setTopBarStyle(
      style,
      updateInsets,
      animated
    );
  } catch {}
};

export const setTopBarTitle = (title: string): void => {
  try {
    NativeModules.ReactPlatformBridge.setTopBarTitle(title);
  } catch {}
};

export const dismissReactNativeModal = (): void => {
  try {
    NativeModules.ReactPlatformBridge.dismiss();
  } catch {}
  try {
    window.ReactNativeWebView?.postMessage('dismiss');
  } catch {}
};

export const updateChatUnreadNumber = (count: number): void => {
  debug('updateChatUnreadNumber():', count);
  try {
    NativeModules.ReactPlatformBridge.updateChatTotalUnreadNumber(count);
  } catch {
    debug('Failed to send chat total unread number over the bridge');
  }
};

export const emitGuestToken = (guestToken?: string): void => {
  try {
    NativeModules.ReactPlatformBridge.emitGuestToken(guestToken);
  } catch {}
};

export const setRefreshToken = (refreshToken?: string): void => {
  try {
    NativeModules.ReactPlatformBridge.setRefreshToken(refreshToken);
  } catch {}
};

export const setTokenData = ({
  authProviderId,
  accessToken,
  refreshToken,
}: {
  authProviderId: string;
  accessToken: string;
  refreshToken: string;
}): void => {
  try {
    NativeModules.ReactPlatformBridge.setTokenData(
      authProviderId,
      accessToken,
      refreshToken
    );
  } catch {}
};

/**
 * Notify Native that the user account settings have changed so it can react
 * accordingly.
 */
export const notifyAppUserSettingsUpdated = (): void => {
  try {
    NativeModules.ReactPlatformBridge.notifyAppUserSettingsUpdated();
  } catch {}
};

/**
 * Notify Native that the module command listener is ready so that it can
 * dispatch a pending module command, if any.
 */
export const notifyModuleCommandListenerReady = (): void => {
  try {
    NativeModules.ReactPlatformBridge.notifyModuleCommandListenerReady();
  } catch {}
};

/**
 * This should only be used in rare situations where
 * the mobile app's root appkey is needed in  react-native
 * and refactoring the app to pass through the root appkey from screen props to
 * child components would introduce significant regression risk.
 *
 * TODO: in the future, refactor the project to avoid relying on this method.
 */
export const getRootAppKey = (): Promise<string | undefined> =>
  new Promise((resolve, _reject): void => {
    getNativeAppHeaders()
      .then((headerString) => {
        const appHeaders = JSON.parse(headerString);
        resolve(appHeaders['sap-root-appkey']);
      })
      .catch((e) => {
        debug('Could not get root appkey');
        resolve(undefined);
      });
  });

type BrotherPrinter = {
  ipaddress?: string;
  model: string;
  location?: string;
  type: 'bluetooth' | 'wifi';
  serialNumber?: string;
};
export const searchForBrotherPrinters = (): Promise<BrotherPrinter[]> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.searchForPrinters(
        (printers: BrotherPrinter[]) => {
          resolve(printers);
        }
      );
    } catch {
      reject();
    }
  });

export const printToBrotherPrinter = (
  filePath: string,
  type: string,
  location: string,
  labelId: ILabelSize
): Promise<string> =>
  new Promise((resolve, reject): void => {
    try {
      NativeModules.ReactPlatformBridge.printToPrinter(
        filePath,
        type,
        location,
        labelId,
        (status: string, error?: string) => {
          if (error) {
            reject(error);
          } else {
            resolve(status);
          }
        }
      );
    } catch {
      reject();
    }
  });

export const isOnMainScreen = (): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    try {
      NativeModules.ReactPlatformBridge.isOnMainScreen(
        (isMainScreen: boolean) => {
          resolve(isMainScreen);
        }
      );
    } catch {
      reject(false);
    }
  });
