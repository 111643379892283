import {
  KitButton,
  KitIcon,
  KitListItem,
  KitText,
  KitTouchableIcon,
} from '@omni/kit/components';
import KitDropdown from '@omni/kit/components/KitDropdown';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import KitMultiSelect from '@omni/kit/components/KitMultiSelect';
import KitPopup from '@omni/kit/components/KitPopup.web';
import { SizeClassV2, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  ScrollView,
  StatusBar,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native';

import { extractLabelsFromArray } from '../../utils/filterOptions';
import { Option } from './Search';

const GroupFilters = ({
  onSave,
  onReset,
  placeHolder,
  groupTypes,
  dayOfWeek,
  frequency,
  setSelectedDay,
  setSelectedFrequency,
  setSelectedGroupType,
  selectedDay,
  selectedFrequency,
  selectedGroupType,
}: {
  onReset: () => void;
  onSave: () => void;
  placeHolder: string;
  groupTypes: Option[];
  dayOfWeek: Option[];
  frequency: Option[];
  setSelectedDay: (sel: Option[]) => void;
  setSelectedFrequency: (sel: Option[]) => void;
  setSelectedGroupType: (sel: Option[]) => void;
  selectedDay: Option[];
  selectedFrequency: Option[];
  selectedGroupType: Option[];
}): JSX.Element => {
  const { sizeClassV2 } = useSizeClass();
  const { t } = useTranslation('search');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [openPopupWeb, setOpenPopupWeb] = useState<boolean>(false);

  const [selectedFilterType, setSelectedFilterType] = useState<string>();
  const [isScrollViewOverflowing, setScrollViewOverflowing] =
    useState<boolean>(false);

  const isLarge = sizeClassV2 >= SizeClassV2.L;

  const dayOfWeekEnabled =
    selectedFrequency.filter((option) => option.value === 'daily').length === 0;

  const getPopupStyle = () => {
    return openPopupWeb
      ? {
          borderColor: Colors.N1000,
          borderWidth: 1,
          outlineColor: Colors.N1000, // Set the outline color
          outlineStyle: 'solid',
          outlineWidth: 1,
        }
      : { borderColor: Colors.N100, borderWidth: 1 };
  };

  const applyFilters = useCallback(() => {
    // Apply filters and search
    setIsModalOpen(false);
    onSave();
    Platform.OS === 'web' && setOpenPopupWeb(false);
  }, [onSave]);

  const openFilterModal = (filterName: string) => {
    setSelectedFilterType(filterName);
  };

  const onApplyFilterModal = () => {
    setSelectedFilterType(undefined);
  };

  const assignArrays = useCallback(
    (selectedFilterType: string) => {
      const array =
        selectedFilterType === 'frequency'
          ? selectedFrequency
          : selectedFilterType === 'dayOfWeek'
          ? selectedDay
          : selectedGroupType;

      selectedFilterType === 'frequency'
        ? setSelectedFrequency(array)
        : selectedFilterType === 'dayOfWeek'
        ? setSelectedDay(array)
        : setSelectedGroupType(array);
    },
    [
      selectedDay,
      selectedFrequency,
      selectedGroupType,
      setSelectedDay,
      setSelectedFrequency,
      setSelectedGroupType,
    ]
  );

  const onBackFilterModal = useCallback(() => {
    selectedFilterType && assignArrays(selectedFilterType);
    setSelectedFilterType(undefined);
  }, [assignArrays, selectedFilterType]);

  const hasItems =
    selectedDay.length > 0 ||
    selectedFrequency.length > 0 ||
    selectedGroupType.length > 0;

  // suppress day of the week if frequency is daily
  useEffect(() => {
    if (!dayOfWeekEnabled) {
      setSelectedDay([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayOfWeekEnabled]);

  // DISP-5266: memoize the content to prevent issue where checkmarks flicker
  const RenderFiltersContent = useMemo(() => {
    const title =
      selectedFilterType === 'frequency'
        ? t('filterOptionFrequency')
        : selectedFilterType === 'dayOfWeek'
        ? t('filterOptionDayOfWeek')
        : t('filterOptionAllGroupTypes');

    const options =
      selectedFilterType === 'frequency'
        ? frequency
        : selectedFilterType === 'dayOfWeek'
        ? dayOfWeek
        : groupTypes;

    const selOption =
      selectedFilterType === 'frequency'
        ? selectedFrequency
        : selectedFilterType === 'dayOfWeek'
        ? selectedDay
        : selectedGroupType;

    const setSelOption =
      selectedFilterType === 'frequency'
        ? setSelectedFrequency
        : selectedFilterType === 'dayOfWeek'
        ? setSelectedDay
        : setSelectedGroupType;

    return (
      <>
        {selectedFilterType === 'frequency' ||
        selectedFilterType === 'dayOfWeek' ||
        selectedFilterType === 'groupType' ? (
          <View
            style={{
              width: '100%',
              height: '100%',
              paddingHorizontal: Spacing.l,
              paddingTop: 0,
              paddingBottom: isLarge
                ? Spacing.l
                : Platform.OS === 'android'
                ? Spacing.m
                : 0,
              justifyContent: 'space-between',
              ...(isLarge && {
                borderRadius: 16,
              }),
            }}
          >
            <View style={{ flex: 1 }}>
              <KitTouchableIcon
                onPress={onBackFilterModal}
                name='back-ios'
                size={20}
                style={{
                  justifyContent: 'center',
                  marginLeft: -14,
                }}
                buttonSize={44}
              />
              <KitText
                fontSize={24}
                lineHeight={24}
                bold
                color={Colors.N1000}
                style={{ marginBottom: Spacing.l, marginTop: 12 }}
              >
                {title}
              </KitText>
              <ScrollView style={{ flex: 1, marginLeft: -10 }}>
                <KitMultiSelect
                  options={options}
                  selOptions={selOption}
                  setSelOptions={setSelOption}
                />
              </ScrollView>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: Spacing.l,
                marginBottom: 0,
                justifyContent: 'space-between',
              }}
            >
              <KitButton
                title={t('common:apply')}
                color={Colors.N900}
                disabled={selOption.length <= 0}
                onPress={onApplyFilterModal}
                style={{ width: '100%' }}
              />
            </View>
          </View>
        ) : (
          <View
            style={{
              width: '100%',
              height: '100%',
              paddingHorizontal: Spacing.l,
              paddingBottom: isLarge
                ? Spacing.l
                : Platform.OS === 'android'
                ? Spacing.m
                : 0,
              paddingTop: 0,
              justifyContent: 'space-between',
              ...(isLarge && {
                borderRadius: 16,
              }),
            }}
          >
            <View style={{}}>
              <KitTouchableIcon
                onPress={() => {
                  setIsModalOpen(false);
                  setSelectedFilterType(undefined);
                  onReset();
                }}
                name='remove'
                size={20}
                style={{
                  justifyContent: 'center',
                  marginLeft: -14,
                }}
                buttonSize={44}
              />
              <KitText
                fontSize={20}
                bold
                color={Colors.N1000}
                style={{ marginTop: 12 }}
              >
                {placeHolder}
              </KitText>
              <KitListItem
                minHeight={80}
                bottomBorder
                title={t('search:filterOptionAllGroupTypes')}
                titleFontSize={16}
                subtitle={
                  selectedGroupType.length > 0
                    ? extractLabelsFromArray(selectedGroupType)
                    : t('search:filterLabelAny')
                }
                subtitleFontSize={14}
                rightIcon='arrow-right'
                onPress={() => openFilterModal('groupType')}
              />
              <KitListItem
                minHeight={80}
                bottomBorder
                title={t('search:filterOptionFrequency')}
                titleFontSize={16}
                subtitle={
                  selectedFrequency.length > 0
                    ? extractLabelsFromArray(selectedFrequency)
                    : t('search:filterLabelAny')
                }
                subtitleFontSize={14}
                rightIcon='arrow-right'
                onPress={() => openFilterModal('frequency')}
              />
              {dayOfWeekEnabled && (
                <KitListItem
                  minHeight={80}
                  bottomBorder
                  title={t('search:filterOptionDayOfWeek')}
                  titleFontSize={16}
                  subtitle={
                    selectedDay.length > 0
                      ? extractLabelsFromArray(selectedDay)
                      : t('search:filterLabelAny')
                  }
                  subtitleFontSize={14}
                  rightIcon='arrow-right'
                  onPress={() => openFilterModal('dayOfWeek')}
                />
              )}
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: Spacing.l,
                marginBottom: 0,
                justifyContent: 'space-between',
              }}
            >
              <KitButton
                title={t('search:filtersResetAll')}
                secondary
                disabled={!hasItems}
                onPress={onReset}
                style={{ marginTop: Spacing.m, width: '48%' }}
              />

              <KitButton
                title={t('common:apply')}
                color={Colors.N900}
                disabled={!hasItems}
                onPress={applyFilters}
                style={{ marginTop: Spacing.m, width: '48%' }}
              />
            </View>
          </View>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFilterType,
    frequency,
    dayOfWeek,
    groupTypes,
    selectedFrequency,
    selectedDay,
    selectedGroupType,
    setSelectedFrequency,
    setSelectedDay,
    setSelectedGroupType,
    isLarge,
    onBackFilterModal,
    placeHolder,
    dayOfWeekEnabled,
    hasItems,
    onReset,
    applyFilters,
  ]);

  return (
    <>
      <View style={isLarge && { flex: 1 }}>
        {Platform.OS === 'web' && isLarge ? (
          <KitPopup
            isOpen={openPopupWeb}
            setIsOpen={setOpenPopupWeb}
            content={
              <View
                style={{
                  width: 308,
                  maxHeight: 554,
                  borderRadius: 27,
                }}
              >
                <KitText
                  fontSize={16}
                  lineHeight={20}
                  bold
                  color={Colors.N900}
                  style={{
                    marginBottom: Spacing.l,
                    paddingHorizontal: Spacing.l,
                  }}
                >
                  {t('search:filtersTitle')}
                </KitText>

                <ScrollView
                  // Check if is overflowing to set the shadow in the bottom docked buttons
                  onContentSizeChange={(e) =>
                    e === 257
                      ? setScrollViewOverflowing(true)
                      : setScrollViewOverflowing(false)
                  }
                  style={{ paddingHorizontal: Spacing.l }}
                >
                  <KitDropdown
                    title={t('search:filterOptionAllGroupTypes')}
                    style={{ marginBottom: Spacing.l }}
                  >
                    <KitMultiSelect
                      options={groupTypes}
                      selOptions={selectedGroupType}
                      setSelOptions={setSelectedGroupType}
                      style={{ marginLeft: -Spacing.s }}
                    />
                  </KitDropdown>
                  <KitDropdown
                    title={t('search:filterOptionFrequency')}
                    style={{ marginBottom: Spacing.l }}
                  >
                    <KitMultiSelect
                      options={frequency}
                      selOptions={selectedFrequency}
                      setSelOptions={setSelectedFrequency}
                      style={{
                        marginLeft: -Spacing.s,
                      }}
                    />
                  </KitDropdown>
                  {dayOfWeekEnabled && (
                    <KitDropdown
                      title={t('search:filterOptionDayOfWeek')}
                      style={{ marginBottom: Spacing.l }}
                    >
                      <KitMultiSelect
                        options={dayOfWeek}
                        selOptions={selectedDay}
                        setSelOptions={setSelectedDay}
                        style={{ marginLeft: -Spacing.s }}
                      />
                    </KitDropdown>
                  )}
                </ScrollView>

                <View
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: Spacing.l,
                    shadowColor: isScrollViewOverflowing
                      ? Colors.N900
                      : Colors.N0,
                    shadowOffset: { width: 0, height: -4 },
                    shadowOpacity: 0.1,
                    shadowRadius: 4,
                  }}
                >
                  <KitButton
                    title={t('search:filtersResetAll')}
                    secondary
                    disabled={!hasItems}
                    onPress={onReset}
                    style={{
                      marginTop: Spacing.m,
                      // @ts-ignore
                      width: 'calc(50% - 6px)',
                      marginRight: 12,
                    }}
                  />

                  <KitButton
                    title={t('common:apply')}
                    color={Colors.N900}
                    disabled={!hasItems}
                    onPress={applyFilters}
                    // @ts-ignore
                    style={{ marginTop: Spacing.m, width: 'calc(50% - 6px)' }}
                  />
                </View>
              </View>
            }
            contentStyle={{
              paddingHorizontal: 0,
              paddingVertical: Spacing.l,
              right: '38%',
            }}
            position='bottom center'
            triggerType='click'
          >
            <TouchableWithoutFeedback onPress={() => setOpenPopupWeb(true)}>
              <View
                style={[
                  getPopupStyle(),
                  {
                    borderRadius: 8,
                    height: 44,
                    width: 180,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: 12,
                    alignItems: 'center',
                  },
                ]}
              >
                <KitText fontSize={16} color={Colors.N900}>
                  {placeHolder}
                </KitText>
                <KitIcon name='arrow-down' size={20} />
              </View>
            </TouchableWithoutFeedback>
          </KitPopup>
        ) : (
          <TouchableWithoutFeedback onPress={() => setIsModalOpen(true)}>
            <View
              style={{
                borderColor: Colors.N100,
                borderRadius: 8,
                height: 44,
                borderWidth: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 12,
              }}
            >
              <KitText
                fontSize={16}
                lineHeight={20}
                color={Colors.N900}
                style={{
                  height: 20,
                }}
              >
                {placeHolder}
              </KitText>
              <KitIcon name='arrow-down' size={20} />
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>

      <KitModalV2
        anchorBottom={!isLarge}
        isVisible={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onModalHide={() => {
          setIsModalOpen(false);
        }}
        coverScreen
        enableScroll={false}
        modalStyle={{
          width: '100%',
          height: '100%',
          borderRadius: 20,
          padding: 0, // override default value
          paddingBottom: 0, // override default value
          ...(isLarge
            ? { maxWidth: 620, maxHeight: 700, paddingTop: 4 }
            : {
                maxWidth: undefined,
                borderRadius: 0, // override default value
              }),
          // SafeAreaView accounts for the notch, but not the status bar
          ...(!isLarge &&
            Platform.OS === 'android' && {
              paddingTop: StatusBar.currentHeight,
            }),
        }}
        noScrollView // necessary so that content fills height of modal
      >
        {/* Using flex and padding bottom in scrollview to fix the alignment of group filter for mobile dimensions in web platform */}
        <SafeAreaView style={{ flex: 1, paddingBottom: Spacing.m }}>
          {RenderFiltersContent}
        </SafeAreaView>
      </KitModalV2>
    </>
  );
};

export default GroupFilters;
