// required to avoid `setImmediate is not defined` in React Native Web
import * as React from 'react';
import {
  Animated,
  Easing,
  Keyboard,
  LayoutAnimation,
  Platform,
  View,
} from 'react-native';
import RootSiblings from 'react-native-root-siblings';
import 'setimmediate';
import styled from 'styled-components/native';

import Spacing from '../theming/Spacing';

//******************************************************************************
// Types
//******************************************************************************
interface IProps {
  duration: number;
  marginBottom?: number;
  message: string;
  onHide?: () => void;
  root?: RootSiblings;
}

const duration = {
  LONG: 3500,
  SHORT: 2000,
  SUPER_LONG: 10000,
};

export const KitSnackDuration = {
  LONG: 3500,
  SHORT: 2000,
  SUPER_LONG: 10000,
};

//******************************************************************************
// Components
//******************************************************************************

interface RenderProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  message: string;
  duration: number;
  marginBottom?: number;
}

export function KitSnackRender({
  visible = false,
  setVisible,
  message,
  duration,
  marginBottom,
}: RenderProps): JSX.Element | null {
  const onHide = () => {
    setVisible(false);
  };

  return visible ? (
    <KitSnackItem
      message={message}
      duration={duration}
      onHide={onHide}
      marginBottom={marginBottom}
    />
  ) : null;
}

export default class KitSnack extends React.Component<IProps, any> {
  //****************************************************************************
  // Static Properties
  //****************************************************************************
  static _snack: null | RootSiblings = null;
  static duration = duration;

  static show = (
    message = '',
    duration = KitSnack.duration.SHORT
  ): RootSiblings => {
    const snack = new RootSiblings(<View />); // This is a hack to get a reference to our root container, in a static context, in order to destroy ourself after animation
    snack.update(
      <KitSnackItem message={message} duration={duration} root={snack} />
    );

    return snack;
  };

  static hide = (snack: RootSiblings): void => {
    if (snack instanceof RootSiblings) {
      snack.destroy();
    } else {
      console.warn(
        `KitSnack.hide expected a \`RootSiblings\` instance.\nBut got \`${typeof snack}\` instead.`
      );
    }
  };
}
class KitSnackItem extends React.Component<IProps> {
  //****************************************************************************
  // Class Properties
  //****************************************************************************
  snackOpacityValue = new Animated.Value(0);
  keyboardWillShowListener: any;
  keyboardWillHideListener: any;

  //****************************************************************************
  // State
  //****************************************************************************
  state = {
    keyboardIsShowing: false,
    snackBottomValue: 48,
  };

  //****************************************************************************
  // Methods
  //****************************************************************************
  private _hideSnack = () => {
    Animated.timing(this.snackOpacityValue, {
      toValue: 0,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      this.props.root?.destroy();
      this.props.onHide?.();
    });
  };

  private _keyboardWillShow = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState({ snackBottomValue: 350 });
  };

  private _keyboardWillHide = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState({ snackBottomValue: 48 });
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************
  componentDidMount = () => {
    this.keyboardWillShowListener = Keyboard.addListener(
      'keyboardWillShow',
      this._keyboardWillShow
    );
    this.keyboardWillHideListener = Keyboard.addListener(
      'keyboardWillHide',
      this._keyboardWillHide
    );

    Animated.timing(this.snackOpacityValue, {
      toValue: 1,
      duration: 225,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();

    setTimeout(this._hideSnack, this.props.duration);
  };

  componentWillUnmount = () => {
    this.keyboardWillShowListener = null;
    this.keyboardWillHideListener = null;
  };

  render() {
    const { message, marginBottom } = this.props;
    const snackOpacity = this.snackOpacityValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
    });
    const bottom = marginBottom ? marginBottom : this.state.snackBottomValue;

    return (
      // TODO: Fix this
      // @ts-ignore
      <SnackWrapper style={{ opacity: snackOpacity }} bottomValue={bottom}>
        <SnackText>{message}</SnackText>
      </SnackWrapper>
    );
  }
}

//******************************************************************************
// Styles
//******************************************************************************
const SnackWrapper = styled(Animated.View)`
  position: absolute;
  left: ${Spacing.l};
  right: ${Spacing.l};
  bottom: ${({ bottomValue }: any) => (bottomValue ? bottomValue : 48)};
  background-color: #222;
  /* height: 54; */
  border-radius: 8;
  padding: 18px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: ${Platform.OS === 'web' ? 360 : 768};

  /* iOS shadow */
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.15);

  /* Android shadow */
  elevation: 3;
`;

const SnackText = styled.Text`
  color: #fff;
  font-size: 16;
  ${() => (Platform.OS === 'web' ? 'font-family: inherit;' : '')}
  width: 100%;
`;
