import { KitText } from '@omni/kit/components';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { PropsWithChildren, useRef } from 'react';
import React from 'react';
import { Animated, StyleSheet } from 'react-native';
import Popup from 'reactjs-popup';

import { TooltipProps } from './index';

export default function Tooltip({
  label = 'Toolip',
  children,
  contentStyle = {},
  clearStyles = false,
  type = 'short',
  ...props
}: PropsWithChildren<TooltipProps>): JSX.Element {
  const anim = { duration: 150, useNativeDriver: true };
  const values = { opacity: { init: 0, end: 1 }, scale: { init: 0.8, end: 1 } };

  const opacity = useRef(new Animated.Value(values.opacity.init)).current;
  const scale = useRef(new Animated.Value(values.scale.init)).current;

  const fadeIn = () => {
    Animated.timing(opacity, { ...anim, toValue: values.opacity.end }).start();
    Animated.timing(scale, { ...anim, toValue: values.scale.end }).start();
  };

  const fadeOut = () => {
    opacity.setValue(values.opacity.init);
    scale.setValue(values.scale.init);
  };

  return (
    <Popup
      on='hover'
      onOpen={fadeIn}
      onClose={fadeOut}
      trigger={
        <div
          className='button'
          role='button'
          style={{
            borderWidth: 0,
            backgroundColor: 'transparent',
            cursor: 'pointer',
            padding: 0,
            display: 'flex',
          }}
        >
          {children}
        </div>
      }
      position='top center'
      arrowStyle={{ display: 'none' }}
      contentStyle={{ zIndex: 2 }}
      {...props}
    >
      <Animated.View
        style={[
          !clearStyles ? styles.tooltip : {},
          type === 'long' && {
            borderRadius: BorderRadius.l,
            maxWidth: 320,
            padding: Spacing.l,
          },
          contentStyle,
          { opacity, transform: [{ scale }] },
        ]}
      >
        <KitText
          white
          fontSize={14}
          fontWeight={600}
          style={type === 'long' && { lineHeight: 20 }}
        >
          {label}
        </KitText>
      </Animated.View>
    </Popup>
  );
}

const styles = StyleSheet.create({
  tooltip: {
    borderRadius: 60,
    paddingVertical: Spacing.xs,
    paddingHorizontal: Spacing.m,
    backgroundColor: Colors.N1000,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
