import { Linking, Platform } from 'react-native';

const debug = require('debug')('tca:playeroverlay:utils:links');

export const openLink = (link: string): void => {
  debug('Clicked: ', link);
  Linking.canOpenURL(link.toLowerCase()).then((supported) => {
    if (supported) {
      Linking.openURL(link);
    } else {
      debug('Unsupported URI: ' + link);
    }
  });
};

interface HrefAttrs {
  target?: string;
  rel?: string;
  download?: boolean;
}

/**
 * Generates the proper attributes to attach to an element for Web or Native.
 * Web should get an `href` (and optionally `hrefAttrs`), and Native should get
 * an `onPress` that uses `Linking` to open the href.
 */
export const generateLinkProps = (
  href: string,
  hrefAttrs?: HrefAttrs
): Partial<{
  href: string;
  hrefAttrs: HrefAttrs;
  onPress: () => void;
}> => {
  return Platform.OS === 'web'
    ? {
        href,
        hrefAttrs,
      }
    : {
        onPress: () => openLink(href),
      };
};
