import React from 'react';
import { TouchableOpacity, ViewStyle } from 'react-native';

export default ({
  children,
  onPress,
  style,
}: {
  children: React.ReactNode;
  onPress?: () => void;
  style?: ViewStyle;
}): JSX.Element => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: 54,
        ...style,
      }}
    >
      {children}
    </TouchableOpacity>
  );
};
