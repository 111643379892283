import { useShellContext } from '@omni/kit';
import { AuthService } from '@omni/kit/auth';
import { IRootProps } from '@omni/kit/contexts/types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetChatState } from '../shared/redux/actions/ChatActions';
import {
  removeStoredIds,
  resetState,
  setAccessToken,
  setAppKey,
} from '../shared/redux/actions/SystemActions';
import { accessTokenSelector, appKeySelector } from '../shared/redux/selectors';

const debug = require('debug')('omni:messaging:context:ChatAuthContext');

interface ChatAuthContextProps {
  isAuthenticated: boolean;
  isReady: boolean;
}

export const ChatAuthContext = createContext<ChatAuthContextProps>({
  isAuthenticated: Boolean(AuthService.accessToken),
  isReady: false,
});

interface ProviderProps {
  children?: React.ReactNode | Array<React.ReactNode>;
  props?: IRootProps;
}

/**
 * This context provider is meant to sync the AuthService's state with the Redux state for Chat
 * The idea being that each module that needs to _hook_ into the auth state,
 * should wrap the app tree in this provider.
 * This is meant to be used once in the app tree.
 *
 * TODO: deprecate accessTokenSelector and setAccessToken redux system action,
 * then remove ChatAuthContext, ChatAuthContextProvider and useChatAuth
 */
export const ChatAuthContextProvider = ({
  children,
  props,
}: ProviderProps): JSX.Element | null => {
  const { app, tokens } = useShellContext();
  const appKey = app.appKey;

  const userToken = useMemo(() => tokens?.user, [tokens.user]);
  const [isReady, setIsReady] = useState(false);

  const chatAppKey = useSelector(appKeySelector);
  const accessToken = useSelector(accessTokenSelector);

  const isAuthenticated = useMemo(() => Boolean(accessToken), [accessToken]);

  const dispatch = useDispatch();

  useEffect(() => {
    const setup = async () => {
      // DISP-3934: attempt to reset chat state when switching apps to prevent
      // issue where user sees an empty conversations tab after switching apps
      // TODO: this does not actually fix the issue, but it's a good start
      // until we can get guidance from Sendbird. See Jira ticket for more info.
      if (userToken && chatAppKey && chatAppKey !== appKey) {
        try {
          await removeStoredIds();
          await dispatch(resetState());
          await dispatch(resetChatState);
          await dispatch(setAppKey(appKey));
        } catch {}
      }

      await dispatch(setAccessToken(userToken || ''));

      setIsReady(true);
    };

    setup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  if (!isReady) {
    return null;
  }

  return (
    <ChatAuthContext.Provider value={{ isAuthenticated, isReady }}>
      {children}
    </ChatAuthContext.Provider>
  );
};

export const useChatAuth = (): ChatAuthContextProps => {
  return useContext<ChatAuthContextProps>(ChatAuthContext);
};
