import { StyleSheet, View } from 'react-native';

import Colors from '@omni/kit/theming/Colors';
import { KitText } from '@omni/kit/components';
import React from 'react';
import Spacing from '@omni/kit/theming/Spacing';

const debug = require('debug')('tca:chat:components:UnsupportedMessageBubble');

export default function UnsupportedMessage() {
  return (
    <View style={styles.messageContainer}>
      <KitText semiBold black fontSize={14} style={{ marginBottom: 2 }}>
        Unsupported message type
      </KitText>
      <KitText fontSize={13} style={{ lineHeight: 15 }}>
        This message type is not supported at this time.
      </KitText>
    </View>
  );
}

const styles = StyleSheet.create({
  messageContainer: {
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.N100,
    paddingHorizontal: Spacing.m,
    paddingVertical: Spacing.s,
  },
  messageStyle: {
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
});
