import { KitIcon, KitText } from '@omni/kit/components';
import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import {
  canCreateChannelsSelector,
  canCreateDirectMessageSelector,
  publicChannelListSelector,
} from '../../../redux/selectors';

import Colors from '@omni/kit/theming/Colors';
import { DISCOVER_TAB } from '../../../Constants';
import Spacing from '@omni/kit/theming/Spacing';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

//******************************************************************************
// Component
//******************************************************************************

interface EmptyConversationListLargeProps {
  navToDiscover: () => void;
}

export default function EmptyConversationListLarge({
  navToDiscover,
}: EmptyConversationListLargeProps) {
  const canCreateChannels = useSelector(canCreateChannelsSelector);
  const canCreateDirectMessage = useSelector(canCreateDirectMessageSelector);
  const publicChannels = useSelector(publicChannelListSelector);

  // const navigation = useNavigation();

  const canCreate = canCreateDirectMessage || canCreateChannels;

  useEffect(() => {
    if (publicChannels && publicChannels?.length > 0) {
      navToDiscover();
    }
  }, [publicChannels]);

  const _renderText = () => {
    if (publicChannels && publicChannels.length > 0) {
      return (
        <View>
          <KitText black fontSize={26} bold style={styles.headerText}>
            Let's get the conversation started!
          </KitText>
          {canCreate ? (
            <KitText gray fontSize={16} style={styles.paragraphText}>
              Join a group or compose a new message to get started.
            </KitText>
          ) : (
            <KitText gray fontSize={16} style={styles.paragraphText}>
              Join a group to get started.
            </KitText>
          )}
        </View>
      );
    } else if (canCreate) {
      return (
        <View>
          <KitText black fontSize={26} bold style={styles.headerText}>
            Let's get the conversation started!
          </KitText>
          <KitText gray fontSize={16} style={styles.paragraphText}>
            Click compose to start a new message.
          </KitText>
        </View>
      );
    } else {
      return (
        <View>
          <KitText black fontSize={26} bold style={styles.headerText}>
            Nothing to see here, yet!
          </KitText>
          <KitText gray fontSize={16} style={styles.paragraphText}>
            You haven't been added to any groups yet, but when you are, this is
            where you'll see them.
          </KitText>
          <KitText
            gray
            fontSize={16}
            style={[styles.paragraphText, { marginTop: Spacing.m }]}
          >
            If you'd like to try out this new way of communicating with people
            in your church, talk to a church leader!
          </KitText>
        </View>
      );
    }
  };

  return (
    <View style={styles.container}>
      <KitIcon name='messaging' color='#999b9e' size={44} />
      {_renderText()}
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.N0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Spacing.xxl,
    paddingBottom: Spacing.xxl,
  },
  headerText: {
    marginTop: Spacing.m,
    marginBottom: Spacing.m,
    textAlign: 'center',
  },
  paragraphText: {
    lineHeight: 24,
    textAlign: 'center',
  },
});
