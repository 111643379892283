import { KitLink, KitText } from '@omni/kit/components';
import KitAppIcon from '@omni/kit/components/KitAppIcon';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitPopup from '@omni/kit/components/KitPopup.web';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { imageTypeFromContentType } from '@omni/kit/utilities/utilities';
import { useNavigation, useRoute } from '@react-navigation/native';
import { BaseMessage, Sender, UserMessage } from '@sendbird/chat/message';
import moment from 'moment';
import React, { Context, useContext, useEffect, useRef, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Pressable } from 'react-native';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import {
  CustomMessageData,
  SendbirdAdminMessage,
  SendbirdMessage,
  SendbirdSenderMessage,
} from '../../../../Types';
import IntroMemberWidget from '../../../../mobile/components/IntroMemberWidget';
import ReactionList from '../../../../mobile/components/chat/ReactionList';
import ThreadSummary from '../../../../mobile/components/chat/ThreadSummary';
import { ChatContext } from '../../../../mobile/scenes/channel/ChatScreen';
import UserActionSheetWeb from '../../../../mobile/scenes/channel/components/UserActionSheetWeb';
import { Admin } from '../../../../mobile/scenes/channel/components/messageTypes/AdminMessage';
import { CHAT_THREAD_SCREEN } from '../../../Constants';
import {
  appBrandingSelector,
  appTitleSelector,
  channelSelector,
  userIdSelector,
} from '../../../redux/selectors';
import { ChannelType, MessageType } from '../../../redux/types';
import { UserContactSheet } from '../../joinRequests/components/UserContactSheet';
import MessageContent from './MessageContent';
import MessageWrapper from './MessageWrapper';
import ReplyView from './ReplyView';

type Props = {
  message: BaseMessage;
  showReactions?: boolean;
  showThreads?: boolean;
  showDate?: boolean;
  disabled?: boolean;
  onReport: any;
  onBlock: any;
};

const ADMIN_USERID_PLACEHOLDER = 'ADMIN';

/**
 * This component renders *Four Potential Message Views*
 * 1. Sender Message
 *     - a message from a user - diverges into the message types found in MessageContent.tsx
 * 2. Intro Widget
 *     - this is the first message in a DM, the thing with the pretty avatar display of those included
 * 3. Admin Message
 *     - a small, plain message from the system, saying "Bob left" or "Alice joined"
 * 4. Nothing
 *     - if none of these fit, we render nothing
 */
export default function Message({
  message,
  onReport,
  onBlock,
  showReactions = true,
  showThreads = true,
  showDate = false,
  disabled = false,
}: Props): JSX.Element | null {
  const channel = useSelector(channelSelector);
  const appTitle = useSelector(appTitleSelector);
  const myUserId = useSelector(userIdSelector);
  const navigation = useNavigation();
  const route = useRoute();
  const { handleSelectingUser } = useContext(ChatContext);

  if (!message) {
    return null;
  }

  const hasReplies = message?.threadInfo && message?.threadInfo?.replyCount > 0;
  // @ts-ignore
  const parentMessageId = route.params?.parentMessageId as number;
  const isThread = parentMessageId !== undefined;

  let data: CustomMessageData | null = null;

  if (message.data) {
    try {
      data = JSON.parse(message.data);
    } catch (e) {}
  }

  const openThreadScreen = () => {
    let messageId = message.messageId;

    if (data?.sentToChannel && data?.inChannel && data?.replyId) {
      messageId = data.replyId;
    }

    navigation.navigate(CHAT_THREAD_SCREEN, {
      parentMessageId: messageId,
      channelId: channel?.url,
    });
  };

  /**
   * Four Potential Message Views
   */
  if (
    message.isUserMessage() ||
    message.isFileMessage() ||
    (message.isAdminMessage() &&
      message.customType &&
      Object.values(MessageType).includes(message.customType as MessageType))
  ) {
    const senderMessage = message as SendbirdSenderMessage; // We do this to avoid TS errors when we use .sender

    if (message.isAdminMessage() && !senderMessage.sender) {
      // @ts-ignore
      senderMessage.sender = {
        userId: ADMIN_USERID_PLACEHOLDER,
        nickname: getAdminMessageNickname(data, appTitle),
      };
    }

    return (
      <View>
        <MessageWrapper
          message={message}
          disabled={disabled}
          openThreadScreen={openThreadScreen}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingTop: Spacing.xs,
            }}
          >
            <Avatar
              isMe={senderMessage.sender.userId === myUserId}
              isAdmin={senderMessage.sender.userId === 'ADMIN'}
              sender={senderMessage.sender}
              handleSelectingUser={handleSelectingUser}
              onReport={onReport}
              disabled={disabled}
              onBlock={onBlock}
            />
            <View>
              <Username
                sender={senderMessage.sender}
                isAdmin={senderMessage.sender.userId === 'ADMIN'}
                time={senderMessage.createdAt}
                showDate={showDate}
                isMe={senderMessage.sender.userId === myUserId}
                onReport={onReport}
                onBlock={onBlock}
                disabled={disabled}
              />
              {/* Replied Message */}
              <AlsoSentToChannel
                // @ts-ignore
                message={message}
                showThreads={!isThread}
                data={data}
                channelType={channel?.customType}
                showDate={showDate}
                onReport={onReport}
                myUserId={myUserId}
              />
              {/* @ts-ignore */}
              <MessageContent message={senderMessage} disabled={disabled} />
            </View>
          </View>
          <View style={{ marginLeft: 62, paddingBottom: Spacing.s }}>
            {showReactions && (
              <ReactionList
                message={senderMessage}
                onLongPress={() => null}
                prayerRequest={senderMessage.customType === MessageType.Prayer}
                disabled={disabled}
              />
            )}
            {hasReplies && !isThread && message && showThreads && (
              // @ts-ignore
              <ThreadSummary
                // @ts-ignore
                message={message}
                onPress={openThreadScreen}
                disabled={disabled}
              />
            )}
          </View>
        </MessageWrapper>
      </View>
    );
  } else if (
    message.isAdminMessage() &&
    channel?.customType === ChannelType.Direct
  ) {
    // For direct messages we should only ever have 1 admin message at the beginning of the conversation
    // we override this message with an intro widget
    return (
      <View style={{ marginTop: Spacing.l, marginHorizontal: Spacing.m }}>
        <IntroMemberWidget beginning members={channel.members} />
      </View>
    );
  } else if (message.isAdminMessage()) {
    return <Admin message={(message as SendbirdAdminMessage).message} />;
  } else {
    return <View />;
  }
}

export const getAdminMessageNickname = (
  data: CustomMessageData | null,
  appTitle: string
): string => {
  if (data?.adminSenderName && appTitle) {
    return `${data.adminSenderName} from ${appTitle}`;
  } else if (data?.adminSenderName) {
    return data?.adminSenderName;
  } else if (appTitle) {
    return appTitle;
  } else {
    return '';
  }
};

function AdminAvatar() {
  const appBranding = useSelector(appBrandingSelector);
  const { branding, logo } = appBranding;
  const color = branding?.brand_color_hex || '#FFF';
  const appLogo = {
    url: logo?._links?.dynamic?.href ?? '',
    size: { width: logo?.width, height: logo?.height },
    imageType: logo ? imageTypeFromContentType(logo?.content_type) : '',
  };

  return (
    <KitAppIcon
      size={40}
      imageUrl={appLogo.url}
      backgroundColor={color}
      style={{ marginHorizontal: 12, marginTop: 3 }}
    />
  );
}

function Avatar({
  isMe,
  isAdmin,
  sender,
  handleSelectingUser,
  onReport,
  onBlock,
  disabled,
}: {
  isMe: boolean;
  isAdmin?: boolean;
  sender: any;
  handleSelectingUser: any;
  onReport: any;
  onBlock: any;
  disabled: boolean;
}) {
  if (sender?.userId === ADMIN_USERID_PLACEHOLDER) {
    return <AdminAvatar />;
  }

  const senderAvatarUrl = sender?.profileUrl.replace('http://', 'https://');
  const senderName = sender?.nickname;

  const avatar = (
    <KitAvatar
      size={40}
      imageUrl={senderAvatarUrl}
      nickname={senderName}
      style={{ marginHorizontal: 12, marginTop: 3 }}
    />
  );

  return sender.showAvatarAndName || true ? (
    Platform.OS === 'web' && !disabled && !isMe && !isAdmin ? (
      <KitPopup
        content={
          <UserActionSheetWeb
            user={sender}
            onReportUser={() => onReport(sender)}
            onBlockUser={() => onBlock(sender)}
          />
        }
        contentStyle={{ paddingHorizontal: 0, paddingVertical: Spacing.l }}
        position='top left'
        triggerType='click'
      >
        {avatar}
      </KitPopup>
    ) : (
      <Pressable
        onPress={!isMe ? () => handleSelectingUser(sender) : undefined}
        disabled={isMe}
      >
        {avatar}
      </Pressable>
    )
  ) : (
    <View style={{ width: 59 }} />
  );
}

function Username({
  isMe,
  isAdmin,
  sender,
  time,
  isReply = false,
  showDate = false,
  onReport,
  onBlock,
  disabled,
}: {
  isMe: boolean;
  isAdmin?: boolean;
  sender: Sender;
  time: number;
  isReply?: boolean;
  showDate?: boolean;
  onReport: any;
  onBlock: any;
  disabled: boolean;
}) {
  let timeDisplay = showDate
    ? moment(time).fromNow()
    : moment(time).format('h:mm A');

  if (isReply) {
    timeDisplay = `[${timeDisplay}]`;
  }

  // @ts-ignore
  return sender.showAvatarAndName || true ? (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {Platform.OS === 'web' && !disabled && !isMe && !isAdmin ? (
        <KitPopup
          content={
            <UserActionSheetWeb
              user={sender}
              onReportUser={() => onReport(sender)}
              onBlockUser={() => onBlock(sender)}
            />
          }
          contentStyle={{ paddingHorizontal: 0, paddingVertical: Spacing.l }}
          position='top left'
          triggerType='click'
        >
          <KitLink
            bold
            fontSize={16}
            underline={disabled ? 'none' : 'hover'}
            style={{ marginRight: 8, color: Colors.N1000 }}
          >
            {sender.nickname}
          </KitLink>
        </KitPopup>
      ) : (
        <KitText
          fontSize={16}
          bold
          style={{ marginRight: 8, color: Colors.N1000 }}
        >
          {sender.nickname}
        </KitText>
      )}
      <KitText fontSize={12} style={{ marginTop: 2 }}>
        {timeDisplay}
      </KitText>
    </View>
  ) : null;
}

function AlsoSentToChannel({
  // @ts-ignore
  showThreads,
  // @ts-ignore
  data,
  // @ts-ignore
  channelType,
  showDate = false,
  // @ts-ignore
  onReport,
  // @ts-ignore
  onBlock,
  // @ts-ignore
  myUserId,
}) {
  const { repliedMessage } = useContext(ChatContext);
  const appTitle = useSelector(appTitleSelector);

  if (
    repliedMessage &&
    repliedMessage.isAdminMessage() &&
    !(repliedMessage as UserMessage).sender
  ) {
    // @ts-ignore
    repliedMessage.sender = {
      userId: ADMIN_USERID_PLACEHOLDER,
      nickname: getAdminMessageNickname(data, appTitle),
    };
  }

  if (!showThreads && data?.sentToChannel) {
    return (
      <KitText fontSize={12} style={{ paddingBottom: 6 }}>
        # Also sent{' '}
        {channelType === ChannelType.Direct
          ? 'as direct message'
          : 'to channel'}
      </KitText>
    );
  } else if (data?.inChannel && repliedMessage) {
    return (
      <View>
        <KitText fontSize={12} style={{ paddingBottom: 6 }}>
          # Replied in thread
        </KitText>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: Spacing.s,
            marginTop: Platform.OS === 'web' ? Spacing.xs : 0,
            position: 'relative',
            paddingLeft: Spacing.m + 3,
            width: '90%',
          }}
        >
          <View style={styles.replyBorder} />
          <View>
            <Username
              // @ts-ignore
              sender={repliedMessage.sender}
              time={repliedMessage.createdAt}
              isReply
              showDate={showDate}
              onReport={onReport}
              onBlock={onBlock}
              // @ts-ignore
              isMe={repliedMessage.sender.userId === myUserId}
              disabled={false}
            />
            {/* @ts-ignore */}
            <ReplyView msg={repliedMessage} />
          </View>
        </View>
      </View>
    );
  } else {
    return null;
  }
}

const styles = StyleSheet.create({
  replyBorder: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: 3,
    backgroundColor: Colors.N100,
    borderRadius: 20,
    marginRight: Spacing.m,
  },
});
