import React, { useContext } from 'react';
import {
  Platform,
  StyleSheet,
  TextStyle,
  TouchableHighlight,
  View,
} from 'react-native';

import BorderRadius from '../theming/BorderRadius';
import Colors from '../theming/Colors';
import Spacing from '../theming/Spacing';
import { ThemeContext } from '../theming/ThemeContext';
import KitIcon, { IconName } from './KitIcon';
import { KitText, KitTouchableIcon } from './index';

export enum LeftNavType {
  Back = 'back',
  Dismiss = 'dismiss',
}

interface IProps {
  color?: string;
  disabled?: boolean;
  iconSize?: number;
  onPress?: () => void;
  title?: string;
  titleStyle?: TextStyle;
  type?: LeftNavType;
  underlayColor?: string;
}

export default function LeftNavButton({
  color = Colors.N900,
  disabled = false,
  iconSize = 20,
  onPress = undefined,
  title = '',
  titleStyle = {},
  type,
  underlayColor = Colors.N100,
}: IProps): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);

  const _leftNavButtonIcon = (): IconName => {
    switch (type) {
      case LeftNavType.Dismiss:
        return Platform.select({
          android: 'bar-back-android',
          ios: 'close',
          web: 'close',
        }) as IconName;
      default:
        return Platform.select({
          android: 'bar-back-android',
          ios: 'back-ios',
          web: 'bar-back-android',
        }) as IconName;
    }
  };
  const truncateTextLength = 23;

  return (
    <View
      style={{
        flexDirection: 'row',
        marginLeft: Platform.OS === 'ios' ? -12 : 0,
      }}
    >
      {Platform.OS === 'android' ? (
        <View style={{ marginRight: Spacing.l, marginLeft: -Spacing.l }}>
          <KitTouchableIcon
            buttonSize={40}
            color={color}
            disabled={disabled}
            name={_leftNavButtonIcon()}
            onPress={onPress}
            size={iconSize}
          />
        </View>
      ) : (
        <TouchableHighlight
          delayPressIn={0}
          disabled={disabled}
          onPress={onPress}
          style={styles.leftIcon}
          underlayColor={
            colorForScheme?.({ dark: Colors.N900, default: Colors.N100 }) ||
            underlayColor
          }
        >
          <View
            style={{
              paddingHorizontal: 10,
            }}
          >
            <KitIcon
              color={color}
              name={_leftNavButtonIcon() as IconName}
              size={iconSize}
            />
          </View>
        </TouchableHighlight>
      )}

      {Platform.OS === 'android' && (
        <KitText
          color={color}
          fontSize={18}
          style={[{ textAlignVertical: 'center' }, titleStyle]}
        >
          {title.length > truncateTextLength
            ? title.slice(0, truncateTextLength) + '...'
            : title}
        </KitText>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  leftIcon: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: BorderRadius.xl,
    paddingVertical: 10,
    marginLeft: Platform.select({ web: 6, native: Spacing.s }),
  },
});
