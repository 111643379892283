import { useEffect, useState } from 'react';
import TokensService from '../services/TokensService/TokensService';

export default function useGuestToken(): string | undefined {
  const [guestToken, setGuestToken] = useState<string>();

  useEffect(() => {
    const fetchToken = async () => {
      const token = await TokensService.getGuestToken();
      setGuestToken(token);
    };

    fetchToken();
  });

  return guestToken;
}
