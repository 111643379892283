import { Platform, StyleSheet } from 'react-native';

import Colors from '../../theming/Colors';

export const defaultStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.N0,
    borderRadius: 16,
    shadowColor: Colors.N1000,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.03,
    shadowRadius: 10,
    overflow: 'hidden',
    ...Platform.select({
      android: {
        elevation: 0,
      },
    }),
  },
});
