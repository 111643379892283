import React, { useState } from 'react';
import { ImageStyle, StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../theming/Colors';
import { ImageServiceType, parseImageUrl } from '../utilities/utilities';
import KitIcon from './KitIcon';
import KitImage from './KitImage';
import { KitText } from './index';

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  size: number;
  initials?: string;
  nickname?: string;
  imageUrl?: string;
  border?: boolean;
  IconComponent?: JSX.Element;
  style?: ViewStyle;
  iconStyle?: ViewStyle;
  imageStyle?: ImageStyle;
  testID?: string;
  color?: string;
  borderColor?: string;
  borderWidth?: number;
}

export default function KitAvatar({
  size,
  imageUrl,
  initials,
  nickname,
  border = false,
  IconComponent,
  style,
  testID = '',
  color = Colors.N0,
  iconStyle,
  imageStyle,
  borderColor = Colors.N0,
  borderWidth = 2,
}: IProps): JSX.Element {
  const [error, setError] = useState(false);

  if (!initials) {
    const splitName = (nickname || '').split(' ');
    const firstInitial = splitName[0]?.charAt(0)?.toUpperCase();
    const lastInitial =
      splitName.length > 1
        ? splitName.slice(-1)[0]?.charAt(0)?.toUpperCase()
        : '';

    initials = `${firstInitial}${lastInitial}`;
  }

  const hasInitialsOrImage = (imageUrl && !error) || initials?.length;

  return (
    <View
      style={[
        styles.container,
        {
          width: size,
          height: size,
          borderRadius: size / 2,
          borderWidth: border ? borderWidth : 0,
          borderColor: borderColor,
        },
        style,
      ]}
      testID={testID}
    >
      {!hasInitialsOrImage ? (
        <View
          style={[
            styles.container,
            {
              backgroundColor: Colors.N200,
              width: '100%',
              height: '100%',
              borderRadius: size / 2,
            },
            iconStyle,
          ]}
        >
          {IconComponent !== undefined ? (
            IconComponent
          ) : (
            <KitIcon name='person' size={size / 2} color={Colors.N0} />
          )}
        </View>
      ) : null}

      {imageUrl && !error ? (
        <KitImage
          style={{
            backgroundColor: Colors.N200,
            width: size - (border ? 2 * borderWidth : 0),
            height: size - (border ? 2 * borderWidth : 0),
            borderRadius: size / 2,
            ...imageStyle,
          }}
          onError={() => setError(true)}
          source={{
            uri: parseImageUrl(
              imageUrl,
              size,
              size,
              ImageServiceType.ImageJpeg
            ),
          }}
        />
      ) : initials?.length ? (
        <View
          style={[
            styles.container,
            {
              backgroundColor: Colors.N200,
              width: '100%',
              height: '100%',
              borderRadius: size / 2,
            },
          ]}
        >
          <KitText
            fontSize={size / 2.5}
            color={color}
            center
            style={{ lineHeight: (size / 2.5) * 1.3 }}
          >
            {initials}
          </KitText>
        </View>
      ) : null}
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
