import {
  KitButton,
  KitSelect,
  KitSnack,
  KitText,
  KitTouchable,
} from '@omni/kit/components';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import GroupsService from '@omni/kit/services/GroupsService';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { accessTokenSelector } from '../../redux/selectors';
import DayPicker from './DayPicker';

interface Props {
  navigation: any;
  route: any;
  isOpenScheduleModal: boolean;
  setIsOpenScheduleModal: (open: boolean) => void;
}
export interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export default function AddMeetingLocationScreen({
  route,
  navigation,
  setIsOpenScheduleModal,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [group, setGroup] = useState(route.params.group);
  const [frequencyValue, setFrequencyValue] = useState(group.frequency);
  const [dayValue, setDayValue] = useState(group.day_of_week);
  const { windowWidth, windowHeight } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  const isWeb = Platform.OS === 'web';
  const isIOS = Platform.OS === 'ios';
  const isAndroid = Platform.OS === 'android';
  const accessToken = useSelector(accessTokenSelector);

  const isEdited = useMemo(() => {
    if (!group.frequency && frequencyValue === 'unset') {
      return false;
    }
    return frequencyValue !== group.frequency || dayValue !== group.day_of_week;
  }, [frequencyValue, dayValue, group]);

  const handleSave = useCallback(() => {
    const updateGroup = async () => {
      const response = await GroupsService.UpdateGroup({
        body: {
          frequency: frequencyValue === 'unset' ? null : frequencyValue,
          day_of_week:
            dayValue === 'unset' ||
            frequencyValue === 'daily' ||
            frequencyValue === 'unset'
              ? null
              : dayValue,

          // TODO: when BE is ready for embedding location on a group
          // _embedded: {
          //   location: { id: 'd3a9e9f8-44da-4f9b-8e6e-75915d5db69a' },
          // },
        },
        id: group.id,
        token: accessToken,
      });
      if (response.status === 200 && response.body) {
        setGroup(response.body);
        navigation.goBack();
        KitSnack.show(
          t('messaging:successMeetingScheduleMessage'),
          KitSnackDuration.SHORT
        );
        setIsOpenScheduleModal(false);
      } else {
        KitSnack.show(t('messaging:errorMessage'), KitSnackDuration.SHORT);
      }
    };

    if (isEdited) {
      updateGroup();
    }
  }, [isEdited, frequencyValue, dayValue, group, accessToken, navigation]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () => (
          <KitTouchable
            onPress={() => handleSave()}
            disabled={!isEdited}
            style={{
              paddingLeft: 12,
              paddingRight: Platform.OS === 'ios' ? 12 : 18,
              paddingVertical: 6,
              marginRight: -6,
              borderRadius: 40,
            }}
          >
            <KitText
              color={isEdited ? Colors.brand : Colors.N400}
              fontSize={16}
            >
              {t('common:buttonTitleSave')}
            </KitText>
          </KitTouchable>
        ),
      });
    }
  }, [isEdited, handleSave, navigation]);

  const containerPadding = {
    padding: !isWeb && isLandscape ? Spacing.xl : 0,
    paddingHorizontal:
      (isIOS && !isLandscape) || isAndroid ? Spacing.l : isWeb ? 0 : Spacing.xl,
    paddingTop:
      (isIOS && !isLandscape) || isAndroid ? Spacing.m : isWeb ? 0 : Spacing.xl,
  };

  return (
    <View style={[styles.container, containerPadding]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <KitText color={Colors.N900} style={styles.title}>
          {t('messaging:meetingSchedule')}
        </KitText>
      </View>
      <View style={styles.selectContainer}>
        <KitSelect
          items={[
            {
              value: 'unset',
              label: t('messaging:unset'),
            },
            {
              value: 'daily',
              label: t('groups:frequency_daily'),
            },
            {
              value: 'weekly',
              label: t('groups:frequency_weekly'),
            },
            {
              value: 'every_other_week',
              label: t('groups:frequency_every_other_week'),
            },
            {
              value: 'monthly',
              label: t('groups:frequency_monthly'),
            },
          ]}
          label={t('messaging:frequencyLabel')}
          labelStyle={{ marginBottom: 8 }}
          onValueChange={(value) => setFrequencyValue(value.value as string)}
          value={frequencyValue || 'unset'}
        />
      </View>
      {!(
        frequencyValue === 'daily' ||
        frequencyValue === 'unset' ||
        !frequencyValue
      ) && (
        <View>
          <DayPicker
            selectedDay={dayValue || 'unset'}
            onDaySelect={(day) => setDayValue(day as string)}
          />
        </View>
      )}
      {(Platform.OS === 'web' || isLandscape) && (
        <View style={styles.buttonContainer}>
          <KitButton
            secondary
            title={t('messaging:buttonTitleCancel')}
            onPress={
              Platform.OS === 'web'
                ? () => setIsOpenScheduleModal(false)
                : navigation.goBack
            }
            style={styles.rightButton}
          />
          <KitButton
            title={t('common:buttonTitleSave')}
            color={Colors.N900}
            disabled={!isEdited}
            onPress={handleSave}
            style={styles.rightButton}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.N0,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: Platform.OS === 'web' ? 24 : 18,
  },
  selectContainer: {
    marginTop: Platform.OS !== 'android' ? -5 : 0,
    marginBottom: Spacing.l,
    // @ts-ignore
    appearance: 'none',
    outlineStyle: 'none',
    width: '100%',
  },

  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 'auto',
  },
  rightButton: {
    marginLeft: Spacing.s,
    width: 100,
  },
});
