import { KitText } from '@omni/kit/components';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import moment from 'moment';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

const EVENT_IMAGE_SIZE_MEDIUM = 36;
const EVENT_IMAGE_SIZE_LARGE = 64;

export const KitCalendarIcon = ({
  date,
  size = 'm',
}: {
  date?: string;
  size?: 'm' | 'l';
}): JSX.Element => {
  const width = size === 'm' ? EVENT_IMAGE_SIZE_MEDIUM : EVENT_IMAGE_SIZE_LARGE;
  const height =
    size === 'm' ? EVENT_IMAGE_SIZE_MEDIUM : EVENT_IMAGE_SIZE_LARGE;

  const headerFontStyles = useMemo(() => {
    switch (size) {
      case 'm':
        return { fontSize: 8, lineHeight: 10 };
      case 'l':
        return { fontSize: 12, lineHeight: 18 };
      default:
        break;
    }
  }, [size]);

  const bodyFontStyles = useMemo(() => {
    switch (size) {
      case 'm':
        return { fontSize: 14, lineHeight: 18 };
      case 'l':
        return { fontSize: 24, lineHeight: 26 };
      default:
        break;
    }
  }, [size]);

  return (
    <View style={[styles.calendarContainer, { width: width, height: height }]}>
      <View style={styles.calendarHeader}>
        <KitText
          bold
          white
          style={{ ...headerFontStyles, textTransform: 'uppercase' }}
        >
          {moment(date).format('MMM')}
        </KitText>
      </View>
      <View style={styles.calendarBody}>
        <KitText
          extraBold={size === 'l'}
          bold={size === 'm'}
          black
          style={bodyFontStyles}
        >
          {moment(date).format('D')}
        </KitText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  calendarContainer: {
    borderRadius: BorderRadius.m,
    borderWidth: 1,
    borderColor: Colors.N100,
    overflow: 'hidden',
  },
  calendarHeader: {
    alignItems: 'center',
    paddingVertical: 1,
    backgroundColor: Colors.N900,
  },
  calendarBody: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.N0,
  },
});

export default KitCalendarIcon;
