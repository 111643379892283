import { Cache } from 'react-native-cache';

import { IDownloaderResponse } from '../Downloader';
import { Optional } from '../Types';
import { WebApp } from './BuilderService';

export interface ICache {
  cachedResponse?: IDownloaderResponse;
  cache?: Cache;
  skipCaching?: boolean;
  skipTimestamp?: boolean;
}

export type IHeader = any;

//******************************************************************************
// Accounts Service resources
//******************************************************************************

export interface App {
  canceled: boolean;
  duns_number: Optional<string>;
  id: string; // aka app_key
  internal: boolean;
  internal_title: Optional<string>;
  is_container: boolean;
  is_trial: boolean;
  org_key: string;
  short_code: string;
  suspended: boolean;
  timezone: { id: number; name: string };
  title: string;
  updated_at: string;
  website_url: Optional<string>;

  _embedded?: {
    branding?: Branding;
    'container-app-info'?: ContainerAppInfo;
    'app-features'?: AppFeature[];
    'app-store-info'?: AppStoreInfo;
    'web-app'?: WebApp;
  };
  type: 'app'; // see usage in Search.ts
}

export interface Branding {
  app_color_scheme: 'light' | 'dark';
  brand_color_hex: string;
  complete: boolean;
  created_at: string;
  icon_background_color_hex: string;
  icon_logo_scale_factor: number;
  id: string;
  launch_background_color_hex: string;
  updated_at: string;

  _embedded?: {
    'icon-logo-image'?: ILogoImage;
    'icon-background-image'?: ILogoImage;
    'silhouette-logo-image'?: ILogoImage;
  };
}

export enum AppFeatureName {
  GATED_CONTENT_V1 = 'gated_content_v1',
  MEDIA_DOWNLADS = 'media-downloads',
  SEARCH_MEDIA = 'search:media',
  NOTES = 'notes',
}

export interface AppFeature {
  available: boolean;
  disabled_at?: string;
  enabled_at: string;
  enable_sidemenu: boolean;
  enabled: boolean;
  id: string;
  name: AppFeatureName;
}

export interface AppStoreInfo {
  id?: string; // same as appkey
  app_key?: string;
  app_title?: string;
  secondary_app_title?: string;
  short_app_title?: string;
  keywords?: string[];
  language?: string;
  app_store_category?: string;
  roku_store_category?: string;
  app_store_features?: string[];
  short_description?: string;
  english_app_description?: string;
  tv_description?: string;
  contact_url?: string;
  last_updated_by_user_id?: number;
  review_status?: string;
  itunes_connect_app_title?: string;
  google_play_app_title?: string;
  windows_phone_store_app_title?: string;
  itunes_id?: string;
  bundle_id?: string;
  android_package_name?: string;
  windows_phone_store_id?: string;
  roku_store_id?: string;
  itunes_connect_live_date?: string;
  google_play_live_date?: string;
  amazon_appstore_live_date?: string;
  windows_phone_store_live_date?: string;
  country?: string;
  created_at?: string;
  updated_at?: string;
  completed?: boolean;
}

export interface ContainerAppInfo {
  id: string;
  container_app_key: string;
  container_type: string;
  member_apps_label: string;
  created_at: string;
  updated_at: string;
}

export interface ContainerAppMembership {
  id: string;
  container_app_key: string;
  app_key: string;
  is_searchable: boolean;
  created_at: string;
  updated_at: string;
  _links?: {
    self?: {
      href?: string;
    };
  };
  _embedded?: {
    app?: App;
  };
  type: 'container-app-membership'; // see usage in kit/feeds/ListItemConverter
}

export interface ImageData {
  aspectRatio?: number;
  color?: string;
  url: string;
}

export interface ILogoImage {
  app_key: string;
  average_color_hex: string;
  content_type: string;
  created_at: string;
  file_size: number;
  height: number;
  id: string;
  md5: string;
  s3_object_key: string;
  title: string;
  type: string;
  updated_at: string;
  vibrant_color_hex: string;
  width: number;
  _links?: {
    self?: {
      href?: string;
    };
    related?: {
      href?: string;
    };
    download?: {
      href?: string;
      templated?: boolean;
    };
    dynamic?: {
      href?: string;
      templated?: boolean;
    };
  };
}

//******************************************************************************
// Links resources
//******************************************************************************

export interface IHref {
  href?: string;
  templated?: boolean;
}

export interface ILinks {
  dashboard?: IHref;
  embed?: IHref;
  self?: IHref;
  share?: IHref;
}

export interface ILinks2 {
  downloaded?: IHref;
  dynamic?: IHref;
  related?: IHref;
}

export interface ILink3 {
  first?: IHref;
  last?: IHref;
  self?: IHref;
}
