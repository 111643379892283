/**
 * https://subsplash.io/go/feeds/-/blob/master/internal/objects/action.go
 */

export enum EActionHandlers {
  App = 'app',
  Audio = 'audio',
  BlockPage = 'blockPage',
  List = 'list',
  NativeShell = 'nativeShell',
  Video = 'video',
}
