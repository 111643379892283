import React, { useCallback, useState } from 'react';
import { Animated } from 'react-native';

import { KitImageProps } from './KitImage';

export default function KitImage(props: KitImageProps): JSX.Element | null {
  const [opacity] = useState(new Animated.Value(0));
  const { onLoadEnd } = props;

  const _onLoadEnd = useCallback(() => {
    /* If we animate in immediately when the onLoadEvent event
       fires, there are two unwanted consequences:
     1. Animation feels janky - not entirely sure why that is
       (handled with minimumWait)
     2. Many images finish loading in the same frame for some reason, and in my
       opinion it looks better when the images fade in separately
       (handled with staggerNonce) */

    const minimumWait = 100;
    const staggerNonce = 200 * Math.random();

    setTimeout(() => {
      Animated.timing(opacity, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }).start();

      onLoadEnd?.();
    }, minimumWait + staggerNonce);
  }, [opacity, onLoadEnd]);

  if (props.source?.uri?.length === 0) return null;

  // Apply animated property to your style
  return (
    <Animated.Image
      source={props.source}
      resizeMode={props.resizeMode}
      onLoadEnd={_onLoadEnd}
      onError={props.onError}
      style={[
        {
          opacity,
        },
        props.style,
      ]}
      onLoad={props.onLoad}
    />
  );
}
