import { Cache, MemoryStore } from 'react-native-cache';

const cache = new Cache({
  backend: MemoryStore,
  namespace: 'bible',
  // @ts-ignore
  policy: { maxEntries: 50 },
});

export default cache;
