import React from 'react';
import { Animated, Easing } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import styled from 'styled-components/native';

import Colors from '../theming/Colors';
import { colorForScheme } from '../theming/Theming';

const knobOffset = 16;

//******************************************************************************
// Types
//******************************************************************************
interface IProps {
  isOn: boolean;
  style?: any;
  onToggle: (isOn: boolean) => void;
}

interface IState {
  animatedValue: Animated.Value;
}

//******************************************************************************
// Component
//******************************************************************************

class KitToggle extends React.Component<IProps, IState> {
  //****************************************************************************
  // Static Properties
  //****************************************************************************
  static defaultProps = {
    isOn: false,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onToggle: (isOn: boolean) => null,
  };

  //****************************************************************************
  // State
  //****************************************************************************
  state = {
    animatedValue: new Animated.Value(this.props.isOn ? knobOffset : 0),
  };

  //****************************************************************************
  // Methods
  //****************************************************************************

  handlePress = (): void => {
    ReactNativeHapticFeedback.trigger('impactMedium', {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
    this.props.onToggle(!this.props.isOn);
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.isOn !== this.props.isOn) {
      Animated.timing(this.state.animatedValue, {
        toValue: this.props.isOn ? knobOffset : 0,
        easing: Easing.elastic(0.7),
        duration: 100,
        useNativeDriver: true,
      }).start();
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const { isOn } = this.props;
    const animatedStyle = {
      transform: [{ translateX: this.state.animatedValue }],
    };

    return (
      <ToggleWrapper
        testID='toggleWrapper'
        activeOpacity={1.0}
        style={this.props.style}
        onPress={this.handlePress}
      >
        <Track testID='toggleTrack' isOn={isOn}>
          <Knob testID='toggleKnob' isOn={isOn} style={animatedStyle} />
        </Track>
      </ToggleWrapper>
    );
  }
}
export default KitToggle;

//******************************************************************************
// Styles
//******************************************************************************
const ToggleWrapper = styled.TouchableOpacity`
  align-items: center;
  border-radius: 28;
  height: 56;
  width: 56;
  justify-content: center;
`;

const Track = styled.View`
  justify-content: center;
  background-color: ${({ isOn }: { isOn: boolean }) =>
    isOn
      ? colorForScheme({ default: Colors.brand })
      : colorForScheme({ default: Colors.N200 })};
  width: 28;
  height: 4;
  border-radius: 14;
`;

const Knob = styled(Animated.View)`
  background-color: ${({ isOn }: { isOn: boolean }) =>
    isOn
      ? colorForScheme({ default: Colors.brand })
      : colorForScheme({ default: Colors.N150 })};
  width: 16;
  height: 16;
  border-radius: 32;
`;
