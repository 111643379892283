import Spacing from '@omni/kit/theming/Spacing';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    width: '100%',
  },
  titleWrapper: {
    marginBottom: Spacing.m,
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
});

export default styles;
