import { Method } from 'axios';

export type IFilter = [string, string];
export type IQuery = [string, any];
export type IPage = [string, number];
export type IInclude = string;
export type IField = [string, string];
export type ISort = string;

export enum EBuildParamTypes {
  QUERIES = 'queries',
  FILTERS = 'filters',
  PAGES = 'pages',
  INCLUDES = 'includes',
  FIELDS = 'fields',
  SORTS = 'sorts',
}

export interface IHttpRequest {
  data?: object;
  headers?: { [key: string]: string };
  method?: Method;
  url: string;
}
