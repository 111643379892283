import { useShellContext } from '@omni/kit';
import Environment from '@omni/kit/Environment';
import { KitButton, KitIcon, KitText } from '@omni/kit/components';
import KitAppIcon from '@omni/kit/components/KitAppIcon';
import Show from '@omni/kit/components/Show';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import WebAppService from '@omni/kit/services/WebAppService';
import { IWebTab } from '@omni/kit/services/WebAppService/Types';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Depth from '@omni/kit/theming/Depth';
import Spacing from '@omni/kit/theming/Spacing';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';
// Messaging imports (to be refactored)
import useAppInfo from '@omni/messaging/hooks/useAppInfo';
import LeftNavButton, {
  LeftNavType,
} from '@omni/messaging/mobile/components/LeftNavButton';
import { ChatSettingsStack } from '@omni/messaging/mobile/navigation/stacks/ChatSettingsStack';
import AccountScreen from '@omni/messaging/mobile/screens/AccountScreen';
import { appKeySelector } from '@omni/messaging/shared/redux/selectors';
import Routes from '@omni/messaging/web/navigation/Routes';
import WebModal from '@omni/messaging/web/scenes/app/components/WebModal';
import { NavigationContainer } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ActionButtonWeb from './ActionButtonWeb';
import UserDropdown from './UserDropdown';

enum ModalContent {
  chatSettings,
  accountSettings,
}

export default function Header(): JSX.Element {
  const { user } = useShellContext();
  const userInfo = user?.profile;

  // Redux data
  const appKey = useSelector(appKeySelector);

  // Local state
  const [modalContent, setModalContent] = useState<ModalContent | undefined>(
    undefined
  );
  const [webTabs, setWebTabs] = useState<IWebTab[]>([]);
  const [customPath, setCustomPath] = useState<string | undefined>();
  const [userDropdownPressed, setUserDropdownPressed] =
    useState<boolean>(false);
  const [userDropdownHover, setUserDropdownHover] = useState<boolean>(false);

  // Hooks
  const { sizeClass } = useSizeClass();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(['common']);
  const isMessaging = location.pathname.includes('/CHURCH/channels');
  const {
    title,
    branding,
    logo: silhouetteLogo,
    iconLogo,
  } = useAppInfo(appKey);

  // Computed values
  const isInWebView = Boolean(window?.ReactNativeWebView);
  const showAppBranding = !isInWebView;
  const logo = iconLogo || silhouetteLogo;
  const styles = getStyles(sizeClass);
  let brandBackgroundImage = '';

  if (branding?._embedded?.['icon-background-image']) {
    brandBackgroundImage =
      branding?._embedded?.['icon-background-image']?._links?.dynamic?.href ??
      '';
  }

  // Handlers
  const openChatSettings = () => {
    setUserDropdownHover(true);
    setModalContent(ModalContent.chatSettings);
  };
  const openAccountSettings = () => {
    setUserDropdownHover(true);
    setModalContent(ModalContent.accountSettings);
  };
  const closeModal = () => {
    setUserDropdownHover(false);
    setModalContent(undefined);
  };
  const navigateToRoot = () =>
    (window.location.href = `${Environment.host}/${appKey}`);

  const navigateToMessaging = () =>
    (window.location.href = `${Environment.host}/${appKey}/channels`);

  const goToMenuItem = (slug: string) => {
    if (slug === 'groups') {
      window.location.href = `${Environment.host}/${appKey}/${slug}`;
    } else {
      window.location.href = `${Environment.legacyWebAppHost}/${customPath}/${slug}`;
    }
  };

  const handleUserDropdown = (val: boolean) => setUserDropdownPressed(val);

  useEffect(() => {
    const getWebApp = async () => {
      if (appKey) {
        const webApps = await WebAppService.getAll({
          filters: [['app_key', appKey]],
          includes: ['web-tabs'],
        });

        if (webApps.body?._embedded['web-apps'][0]._embedded) {
          setWebTabs(
            webApps.body?._embedded['web-apps'][0]._embedded['web-tabs']
          );
          setCustomPath(webApps.body?._embedded['web-apps'][0].custom_path);
        }
      }
    };
    getWebApp();
  }, [appKey]);

  return (
    <View style={[styles.header, Depth.s]}>
      {showAppBranding && (
        <TouchableOpacity style={styles.leftContent} onPress={navigateToRoot}>
          {logo && (
            <ImageBackground
              source={{
                uri: parseImageUrl(
                  brandBackgroundImage,
                  36,
                  36,
                  ImageServiceType.ImagePng
                ),
              }}
              resizeMode='cover'
              style={[styles.imageWrap]}
            >
              <KitAppIcon
                size={36}
                imageUrl={logo._links?.dynamic?.href}
                backgroundColor={
                  brandBackgroundImage
                    ? 'transparent'
                    : branding?.icon_background_color_hex || Colors.N0
                }
                style={styles.logo}
                tintColor={iconLogo ? undefined : Colors.N900}
              />
            </ImageBackground>
          )}
          <KitText style={styles.appTitle} numberOfLines={1}>
            {title}
          </KitText>
        </TouchableOpacity>
      )}
      <Show show={sizeClass !== SizeClass.Small}>
        <View style={styles.centerContent}>
          {webTabs
            .filter((tab: IWebTab) => tab.enabled)
            .sort((a: IWebTab, b: IWebTab) => {
              if (a.position < b.position) return -1;

              if (a.position > b.position) return 1;

              return 0;
            })
            .map((tab: IWebTab, index: number) => {
              return (
                <ActionButtonWeb
                  key={index}
                  onPress={() => goToMenuItem(tab.slug)}
                  borderRadius={100}
                  style={{
                    paddingHorizontal: 18,
                    paddingVertical: 11,
                    marginRight: 0,
                  }}
                >
                  <KitText
                    style={{
                      color: Colors.N900,
                      lineHeight: 20,
                      fontSize: 16,
                      fontWeight: location.pathname.includes(
                        `/${appKey}/${tab.slug}`
                      )
                        ? 'bold'
                        : 'normal',
                    }}
                  >
                    {tab.title}
                  </KitText>
                </ActionButtonWeb>
              );
            })}
        </View>
      </Show>
      <View style={styles.rightContent}>
        <>
          {userInfo ? (
            <>
              <ActionButtonWeb
                onPress={navigateToMessaging}
                width={44}
                height={44}
                padding={Spacing.m}
                borderRadius={44}
                active={isMessaging}
              >
                <KitIcon name='messaging' color='#000' size={20} />
              </ActionButtonWeb>
              <UserDropdown
                user={{ userID: user?.id, ...userInfo }}
                handleUserDropdown={handleUserDropdown}
                onUserSettings={openChatSettings}
                onAccountSelected={openAccountSettings}
                userDropdownPressed={userDropdownPressed}
                userDropdownHover={userDropdownHover}
              />
            </>
          ) : (
            <KitButton
              color={Colors.N1000}
              small
              title={
                sizeClass === SizeClass.Small
                  ? t('buttonTitleLogIn')
                  : t('buttonTitleLogInOrSignUp')
              }
              titleStyle={{ fontSize: 14, fontWeight: '600' }}
              onPress={() => history.push(Routes.login(appKey))}
            />
          )}
        </>
        {isInWebView && <LeftNavButton type={LeftNavType.Dismiss} title='' />}
        <WebModal open={modalContent !== undefined} onClose={closeModal}>
          {modalContent === ModalContent.chatSettings && (
            <NavigationContainer>
              <ChatSettingsStack onBackPress={closeModal} />
            </NavigationContainer>
          )}
          {modalContent === ModalContent.accountSettings && (
            // @ts-ignore
            <AccountScreen onDismiss={closeModal} />
          )}
        </WebModal>
      </View>
    </View>
  );
}

const getStyles = (sizeClass: SizeClass) =>
  StyleSheet.create({
    header: {
      alignItems: 'center',
      backgroundColor: '#fff',
      flexDirection: 'row',
      height: 72,
      paddingHorizontal: sizeClass === SizeClass.Small ? Spacing.l : Spacing.xl,
      paddingVertical: sizeClass === SizeClass.Small ? 0 : Spacing.l,
      zIndex: 1,
    },
    leftContent: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
    },
    centerContent: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'center',
    },
    rightContent: {
      flexDirection: 'row',
      flex: 1,
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    logo: {
      marginRight: Spacing.m,
    },
    appTitle: {
      color: Colors.N900,
      fontSize: 16,
    },
    imageWrap: {
      marginRight: Spacing.m,
      borderRadius: BorderRadius.full,
      height: 36,
      width: 36,
      overflow: 'hidden',
    },
    linkItems: {
      color: Colors.N900,
      paddingHorizontal: Spacing.l,
    },
  });
