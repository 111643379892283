import {
  EBuildParamTypes,
  IField,
  IFilter,
  IInclude,
  IPage,
  IQuery,
  ISort,
} from './Types';

export default function buildParams({
  filters,
  queries,
  pages,
  includes,
  fields,
  sorts,
}: {
  filters?: IFilter[];
  queries?: IQuery[];
  pages?: IPage[];
  includes?: IInclude[];
  fields?: IField[];
  sorts?: ISort[];
}): string {
  const params: any = [];
  buildParamString(EBuildParamTypes.QUERIES, params, queries);
  buildParamString(EBuildParamTypes.FILTERS, params, filters);
  buildParamString(EBuildParamTypes.PAGES, params, pages);
  buildParamString(EBuildParamTypes.INCLUDES, params, includes);
  buildParamString(EBuildParamTypes.FIELDS, params, fields);
  buildParamString(EBuildParamTypes.SORTS, params, sorts);

  return params.length ? `?${params.join('&')}` : '';
}

// Note: operates directly on `params` argument
function buildParamString(
  type: string,
  params: string[],
  modifiers?: (IFilter | IInclude | IQuery | ISort)[]
) {
  if (!modifiers) return;

  let buildFn = (_: any) => '';
  switch (type) {
    case EBuildParamTypes.QUERIES:
      buildFn = buildQuery;
      break;
    case EBuildParamTypes.FILTERS:
      buildFn = buildFilter;
      break;
    case EBuildParamTypes.FIELDS:
      buildFn = buildField;
      break;
    case EBuildParamTypes.PAGES:
      buildFn = buildPage;
      break;
    case EBuildParamTypes.SORTS:
      params.push(buildSorts(modifiers as ISort[]));

      return;
    case EBuildParamTypes.INCLUDES:
      params.push(buildInclude(modifiers as IInclude[]));

      return;
    default:
      return;
  }
  modifiers.forEach((modifier) => params.push(buildFn(modifier)));
}

function buildQuery(param: IQuery) {
  return `${param[0]}=${param[1]}`;
}

function buildFilter(param: IFilter) {
  return `filter[${param[0]}]=${param[1]}`;
}

function buildPage(param: IPage) {
  return `page[${param[0]}]=${param[1]}`;
}

function buildInclude(param: IInclude[]) {
  return `include=${param.join(',')}`;
}

function buildField(param: IField) {
  return `fields[${param[0]}]=${param[1]}`;
}

function buildSorts(param: ISort[]) {
  return `sort=${param.join(',')}`;
}
