import { Platform } from 'react-native';

import { IRootProps } from '../../contexts/types';
import AccountsService from '../../services/AccountsService';
import EventService from '../../services/EventService';
import {
  EVENT_SERVICE_EVENTS,
  IListenerCallbackData,
} from '../../services/EventService/types';
import AddIosMediaPlaybackListener from './AddIosMediaPlaybackListener';
import FetchAccessToken from './FetchAccessToken';
import FetchUserProfile from './FetchUserProfile';
import ResetPatchTimer from './ResetPatchTimer';
import TrackMediaProgressState from './TrackMediaProgressState';
import UpdateServerWithAllSavedProgress from './UpdateServerWithAllSavedProgress';
import { CURRENT_EVENT_SOURCE } from './constants';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:LaunchTrackMediaProgress'
);

/**
 * Initialize media tracking on app launch
 * @param props {IRootProps}
 */
export default async (props: IRootProps): Promise<void> => {
  const { moduleCommand, appKey } = props;
  const isAppLaunch = !moduleCommand;

  if (!isAppLaunch || !appKey) return;

  try {
    // Since we cannot use ApplicationContext imperatively
    // use use the same options as useAppData to increase chance of cache-hit
    const app = await AccountsService.getApp(appKey, {
      includeAppStoreInfo: true,
      includeBranding: true,
      includeFeatures: true,
      includeContainerAppInfo: true,
    });

    /**
     * Do not attempt to track media progress for the container app instance
     * e.g. on first launch of a container app when the Tour/Search UX is visible
     * otherwise the call to FetchAccessToken will cache a sapToken
     * that cannot be used to obtain a fresh accessToken on any child apps
     * otherwise a 400 error will occur on the request to refresh the token for a child app
     */
    if (app?.is_container) {
      return;
    }
  } catch {}

  try {
    await FetchAccessToken(appKey);
    TrackMediaProgressState.userProfile = await FetchUserProfile(appKey);
    await UpdateServerWithAllSavedProgress(
      TrackMediaProgressState.userProfile?.userID
    );
  } catch (e) {
    debug('Failed to update all progress upon app launch', e);
  }

  EventService.addListener(
    EVENT_SERVICE_EVENTS.UPDATED_MEDIA_PLAY_RESOURCE,
    (props?: IListenerCallbackData) => {
      if (props?.source !== CURRENT_EVENT_SOURCE) {
        ResetPatchTimer();
      }
    }
  );

  /**
   * IOS can run react native code in the background
   *
   * Note: Android has to run react native code using a headless task runner.
   * https://reactnative.dev/docs/headless-js-android
   *
   * This is attached in the root index.ts
   */
  if (Platform.OS === 'ios') {
    AddIosMediaPlaybackListener();
  }
};
