import { KitText } from '@omni/kit/components';
import TouchableBase, {
  TouchableBaseProps,
} from '@omni/kit/components/TouchableBase';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import React, { useContext, useEffect, useState } from 'react';
import { LayoutAnimation, Platform, StyleSheet, View } from 'react-native';

import RoundButton from '../components/RoundButton';
import { BibleBook, BibleLocation } from '../types';

const debug = require('debug')('tca:bible:screen:BibleNavModalItem');

interface Props {
  bibleLocation: BibleLocation;
  book: BibleBook;
  index: number;
  initialExpandedState: boolean;
  onExpanded: (index: number, expanded: boolean) => void;
  selectNewChapter: (chapter: number, bookName: string) => void;
}

export default function BibleNavModalItem({
  bibleLocation,
  book,
  index,
  initialExpandedState,
  onExpanded,
  selectNewChapter,
}: Props): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);

  const [expanded, setExpanded] = useState(initialExpandedState);

  const { bookName: selectedBookName, chapter: selectedChapter } =
    bibleLocation;
  const isCurrentBook = selectedBookName === book.osis;

  const chapters = Array(book.chapters).fill(0);

  useEffect(() => {
    onExpanded(index, expanded);
  }, [expanded]);

  return (
    <>
      <TouchableWithRadius
        onPress={() => {
          if (Platform.OS !== 'android') {
            LayoutAnimation.configureNext(
              LayoutAnimation.Presets.easeInEaseOut
            );
          }

          setExpanded(!expanded);
        }}
        underlayColor={colorForScheme?.({
          dark: Colors.N900,
          light: Colors.N200,
        })}
      >
        <KitText
          bold={isCurrentBook}
          color={colorForScheme?.({ dark: Colors.N0, light: Colors.N900 })}
          h1
        >
          {book.display}
        </KitText>
      </TouchableWithRadius>
      {expanded && (
        <View style={styles.chapters}>
          {chapters.map((_chapter, idx) => (
            <RoundButton
              key={idx}
              onPress={() => selectNewChapter(idx + 1, book.osis)}
            >
              <KitText
                color={
                  idx + 1 === selectedChapter && book.osis === selectedBookName
                    ? colorForScheme?.({ dark: Colors.N0, light: Colors.N900 })
                    : colorForScheme?.({
                        dark: Colors.N200,
                        light: Colors.N400,
                      })
                }
                extraBold={
                  idx + 1 === selectedChapter && book.osis === selectedBookName
                }
                subtitle
              >
                {idx + 1}
              </KitText>
            </RoundButton>
          ))}
        </View>
      )}
    </>
  );
}

const TouchableWithRadius = ({ children, ...props }: TouchableBaseProps) => (
  <View
    style={{
      borderRadius: BorderRadius.s,
      marginLeft: 12,
      marginRight: 12,
      overflow: 'hidden',
    }}
  >
    <TouchableBase {...props} style={styles.touchableBookText}>
      {children}
    </TouchableBase>
  </View>
);

const styles = StyleSheet.create({
  chapters: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 30,
    marginLeft: 12,
    marginTop: 15,
  },
  header: {
    paddingLeft: 12,
    zIndex: 1,
  },
  touchableBookText: {
    borderRadius: BorderRadius.s,
    height: 60,
    justifyContent: 'center',
    paddingLeft: 12,
  },
});
