import { Platform } from 'react-native';
import { useEffect } from 'react';

export default function useDocumentTitle(
  appTitle: string,
  pageTitle: string
): void {
  useEffect(() => {
    if (appTitle && pageTitle && Platform.OS === 'web') {
      document.title = `${pageTitle} - ${appTitle}`;
    }
  }, [appTitle, pageTitle]);
}
