import { Moment } from 'moment';

import { IUserProfileInfo } from '../../services/FeedsService/GetUserProfile';
import { IMediaItem } from '../../services/MediaItemService';
import { IMediaPlays } from '../../services/MediaPersonalizationService/MediaPlayService';

export default class TrackMediaProgressState {
  public static currentMediaItem: IMediaItem | null;
  public static isSetupInProgress = false;
  public static mediaPlay: IMediaPlays | null;
  public static pid: NodeJS.Timeout | null = null;
  public static previousStates: Record<string, boolean> = {};
  public static retryAttempts = 0;
  public static retryTimer: Moment | null;
  public static saveProgressToNetworkTimer: Moment | null;
  public static userProfile: IUserProfileInfo | null;
}
