import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit';
import BaseServiceV2, { IHttpResponse } from '@omni/kit/services/BaseServiceV2';

const debug = require('debug')('omni:messaging:service:userService');

export type ChatUserInfoObject = {
  appId: string;
  canCreateChannels: boolean;
  canCreateDirectMessage: boolean;
  endUserId: Uuid;
  userId: Uuid;
  userToken: string;
};

export const userService = {
  getChatUserInfo,
};

function getChatUserInfo(
  appKey: string,
  accessToken: string,
  endpointUrl: string
): Promise<ChatUserInfoObject> {
  if (!appKey) {
    return Promise.reject('No appKey provided');
  }

  return BaseServiceV2.Post({
    url: endpointUrl,
    appKey: appKey,
    authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
    token: accessToken,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(handleResponse);
}

function handleResponse(response: IHttpResponse): ChatUserInfoObject {
  const data = response.body;

  const ok = response.status === 201 || response.status === 200;

  if (!ok) {
    debug(`Auth failed, status:${response.status}`, data);

    throw response;
  }

  debug('Auth success');

  if (!data._embedded['chat-app']) {
    throw 'No chat-app found in chat-sessions';
  }

  if (!data._embedded['chat-user']) {
    throw 'No chat-user found in chat-sessions';
  }

  if (!data._embedded['chat-token']) {
    throw 'No chat-token found in chat-sessions';
  }

  if (data._embedded['chat-token'].expires_at) {
    const expiresAtDate = new Date(data._embedded['chat-token'].expires_at);
    const expiresInMsec = expiresAtDate.getTime() - Date.now();
    const expiresInMin = expiresInMsec / 1000 / 60;
    debug(`chat-token expires in ${expiresInMin} minutes`);
    if (expiresInMin < 0) {
      throw 'chat-token expired';
    }
  }

  return {
    appId: data._embedded['chat-app'].chat_client_app_id,
    canCreateChannels: data._embedded['chat-user'].channel_admin,
    canCreateDirectMessage: data._embedded['chat-app'].direct_messages_enabled,
    endUserId: data._embedded['chat-user']._embedded['end-user'].id,
    userId: data._embedded['chat-user'].id,
    userToken: data._embedded['chat-token'].token,
  };
}
