import { NavigationContainer } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { LogBox, Platform } from 'react-native';
import { useDispatch } from 'react-redux';

import { MessagingProps } from '../../Types';
import AppNavigator from '../../mobile/navigation/AppNavigator';
import LinkingConfig from '../../mobile/navigation/LinkingConfig';
import { initState } from '../../shared/redux/actions/SystemActions';

if (Platform.OS !== 'web') {
  LogBox.ignoreAllLogs(true);
}

export default function SmallWebMessaging(props: MessagingProps): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initState({ appKey: props.appKey }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavigationContainer
      // @ts-ignore
      linking={LinkingConfig}
      documentTitle={{ enabled: false }}
    >
      {/* @ts-ignore */}
      <AppNavigator screenProps={props} />
    </NavigationContainer>
  );
}
