import Environment from '../../../Environment';
import BaseServiceV2, {
  IFilter,
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { ICalendar } from '../Types';

const HOST = Environment.eventsService;
const RESOURCE = 'calendars';

interface IGetCalendar extends IUri {
  token?: string;
  id?: string;
}

interface IGetCalendarResponse extends IHttpResponse {
  body?: ICalendar;
}

export default async function ({
  token,
  id,
  ...buildUrlProps
}: IGetCalendar): Promise<IGetCalendarResponse> {
  const url = buildUrl({
    host: HOST,
    id,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
