import React, { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode;
  delay?: number;
};

const Delayed = ({ children, delay = 0 }: Props): any => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return isShown ? children : null;
};

export default Delayed;
