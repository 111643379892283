import { KitIcon, KitText, KitTouchable } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import React, { useContext } from 'react';
import { Platform, View, ViewStyle } from 'react-native';

import { ListBlockStyle } from '../../blocks/types';

interface IMoreItemProps {
  aspectRatio?: number;
  data?: IMoreItemData;
  fixedWidthEnabled?: boolean;
  height?: number;
  listStyle?: ListBlockStyle;
  onPress?: () => void;
  style?: ViewStyle;
  width?: number;
}

interface IMoreItemData {
  action?: any;
  title?: string;
}

export default function MoreItem(props: IMoreItemProps): JSX.Element {
  const {
    aspectRatio,
    data = {},
    fixedWidthEnabled = false,
    height = 0,
    listStyle = ListBlockStyle.Rows,
    onPress = () => undefined,
    style = {},
    width = 0,
  } = props;

  const { colorForScheme } = useContext(ThemeContext);
  const titleColor = colorForScheme?.({
    dark: Colors.N400,
    light: Colors.N900,
  });

  return (
    <View style={style}>
      <View
        style={[
          fixedWidthEnabled && listStyle === ListBlockStyle.Grid
            ? {
                /**
                 * Occurs in horizontal lists in which numColumns is undefined,
                 * so each item needs a fixed width and height.
                 */
                height: height,
                width: width,
              }
            : aspectRatio
            ? {
                /**
                 * Occurs in vertical lists where each item's width is already
                 * determined by column count + effective margins.
                 * In order to support aspect ratio on the web, we must use
                 * paddingTop percentage + absolute position on children,
                 * ref: https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
                 * react-native-web does not currently support the aspectRatio
                 * prop: https://github.com/necolas/react-native-web/issues/427
                 */
                paddingTop: ((1 / aspectRatio) * 100 + '%') as `${number}%`,
              }
            : {},
        ]}
      >
        <View
          style={[
            listStyle === ListBlockStyle.Rows
              ? { margin: Spacing.l }
              : fixedWidthEnabled
              ? { height, width }
              : aspectRatio
              ? {
                  bottom: 0,
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }
              : {},
            { alignItems: 'center', justifyContent: 'center' },
          ]}
        >
          <KitTouchable
            onPress={onPress}
            style={{
              borderRadius: 40,
              marginRight: -6,
              paddingLeft: 14,
              paddingRight: Platform.OS === 'ios' ? 14 : 18,
              paddingVertical: 6,
            }}
          >
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <View style={{ alignItems: 'center' }}>
                {data.title && (
                  <KitText semiBold fontSize={18} color={titleColor}>
                    {data.title}
                  </KitText>
                )}
              </View>
              {listStyle === ListBlockStyle.Grid && (
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginStart: 4,
                  }}
                >
                  <KitIcon name='arrow-right' size={16} color={titleColor} />
                </View>
              )}
            </View>
          </KitTouchable>
        </View>
      </View>
    </View>
  );
}
