import KitAvatar from '@omni/kit/components/KitAvatar';
import { IProfile } from '@omni/kit/services/PeopleService/Types';
import { ColorString } from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { createImageUrl } from '@omni/kit/utilities/utilities';
import React from 'react';
import { View, ViewStyle } from 'react-native';

export default ({
  users,
  style,
  size,
  avatarMargin = -Spacing.s,
  borderColor,
  borderWidth,
}: {
  users: IProfile[];
  style?: ViewStyle;
  size?: number;
  avatarMargin?: number;
  borderColor?: ColorString;
  borderWidth?: number;
}): JSX.Element => {
  const margin = avatarMargin - (borderWidth ?? 0);

  return (
    <View
      style={{
        flexDirection: 'row',
        marginLeft: -margin,
        ...style,
      }}
    >
      {users?.map((user: IProfile) => {
        return (
          <KitAvatar
            key={user.id}
            border
            style={{ marginLeft: margin }}
            size={size || Spacing.xxl}
            nickname={`${user?.first_name} ${user.last_name}`}
            imageUrl={createImageUrl(user?._embedded?.photo?.id)}
            borderColor={borderColor}
            borderWidth={borderWidth}
          />
        );
      })}
    </View>
  );
};
