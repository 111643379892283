import Colors from '@omni/kit/theming/Colors';
import { URL_REGEX } from '@omni/media/liveChat/shared/components/Message';
import React from 'react';
import { Linking, Platform, StyleSheet, TextStyle } from 'react-native';
import ParsedText, {
  CustomParseShape,
  DefaultParseShape,
} from 'react-native-parsed-text';

import { isEmojiString } from '../../../utilities/sendbird/chatFunctions';

const debug = require('debug')('tca:chat:components:ParsedMessage');

interface IProps {
  children: string;
  color?: string | undefined;
  parsed?: Array<DefaultParseShape | CustomParseShape>;
  style?: TextStyle;
}

export default function ParsedMessage({
  children,
  color = Colors.N900,
  parsed = [],
  style = {},
  ...otherProps
}: IProps): JSX.Element {
  const onUrlPress = (url: string) => {
    const formattedUrl = `${!url.includes('http') ? 'https://' : ''}${url}`;
    openLink(formattedUrl);
  };

  const onEmailPress = (url: string) => {
    const link = `mailto:${url}`;
    openLink(link);
  };

  const onTelPress = (url: string) => {
    const tel = `tel:${url}`;
    openLink(tel);
  };

  const openLink = (link: string) => {
    debug('Clicked: ', link);
    if (Platform.OS === 'web') {
      const win = window.open(link, '_blank');
      win?.focus();

      return;
    }

    /**
     * Not using canOpenUrl because on Android 11 it returns false unexpectedly.
     */
    Linking.openURL(link);
  };

  return (
    <ParsedText
      {...otherProps}
      style={[
        styles.messageStyle,
        {
          color: color,
          fontSize: isEmojiString(children) ? 34 : 16,
          ...style,
        },
      ]}
      parse={[
        { type: 'email', style: styles.clickable, onPress: onEmailPress },
        { pattern: URL_REGEX, style: styles.clickable, onPress: onUrlPress },
        { type: 'phone', style: styles.clickable, onPress: onTelPress },
        {
          pattern: /\*.*\*/,
          style: { fontWeight: 'bold' },
          renderText: (text) => text.slice(1, -1),
        },
        {
          pattern: /_.*_/,
          style: { fontStyle: 'italic' },
          renderText: (text) => text.slice(1, -1),
        },
        ...parsed,
      ]}
    >
      {children}
    </ParsedText>
  );
}

const styles = StyleSheet.create({
  clickable: {
    textDecorationLine: 'underline',
  },
  messageStyle: {
    fontFamily: Platform.OS === 'web' ? 'inherit' : undefined,
    fontWeight: 'normal',
    maxWidth: 600,
  },
});
