import KitEditor from '@omni/kit/components/KitEditor';
import KitText from '@omni/kit/components/KitText';
import KitTouchableIcon from '@omni/kit/components/KitTouchableIcon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import ThemeContextWrapper from '@omni/kit/theming/ThemeContext';
import { NativeTopBarStyle } from '@omni/kit/utilities/NativeHelpers';
import React, { useState } from 'react';
import { FlexStyle, Platform, StyleSheet, View } from 'react-native';

import BlockPageApp from './BlockPageApp';
import { editorTemplate } from './templates/examples/EditorTemplate';

interface IBlockPageEditorProps {
  panelDirection?: FlexStyle['flexDirection'];
}

/**
 * BlockPageEditor
 * Allows you to edit a block page and see the changes in real-time.
 * This is currently only used in the Block Page docs and in the Dev app.
 * In the future, this could be used within standalone apps to allow admins to
 * edit a block page layout template and have it take effect for users
 * similar to how you might edit and publish changes on a Confluence page.
 */
export default function BlockPageEditor({
  panelDirection = 'row',
}: IBlockPageEditorProps): JSX.Element {
  const [valid, setValid] = useState(true);
  const [data, setData] = useState(editorTemplate);

  const [showPreview, setShowPreview] = useState(true);
  const [showEditor, setShowEditor] = useState(true);

  // @ts-ignore
  const onEditorChanged = (newValue): void => {
    try {
      const newData = JSON.parse(newValue);
      setValid(true);
      setData(newData);
    } catch {
      setValid(false);
    }
  };

  const renderOptionBar = (): JSX.Element => {
    return (
      <View style={styles.optionBar}>
        <KitTouchableIcon
          name='edit'
          onPress={() => {
            setShowEditor(!showEditor);
          }}
          color={showEditor ? Colors.brand : Colors.N900}
        />
        <KitTouchableIcon
          name='bar-menu'
          onPress={() => {
            setShowPreview(!showPreview);
          }}
          color={showPreview ? Colors.brand : Colors.N900}
        />
      </View>
    );
  };

  const renderInvalid = () => {
    return (
      <View
        style={{
          alignContent: 'center',
          alignItems: 'center',
          backgroundColor: 'red',
          bottom: 0,
          justifyContent: 'center',
          left: 0,
          opacity: 0.8,
          padding: Spacing.s,
          right: 0,
        }}
      >
        <KitText color={Colors.N0}>Invalid JSON</KitText>
      </View>
    );
  };

  return (
    <ThemeContextWrapper theme='light'>
      <View
        style={{
          backgroundColor: Colors.N200,
          flex: 1,
          flexDirection: panelDirection,
        }}
      >
        {showEditor && (
          <View style={{ flex: 1, flexDirection: 'column', maxWidth: 500 }}>
            <KitEditor
              src={JSON.stringify(data, null, '  ')} // 2 spaces for indents
              onChange={onEditorChanged}
            />
          </View>
        )}
        {showPreview && (
          <View style={{ flex: 1 }}>
            <BlockPageApp
              appKey={'CHURCH'}
              data={data}
              topBarStyle={NativeTopBarStyle.Default}
              guestToken={''}
            />
            {!valid && renderInvalid()}
          </View>
        )}
        {renderOptionBar()}
      </View>
    </ThemeContextWrapper>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  optionBar: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.N0,
    borderRadius: 30,
    bottom: 0,
    flex: 1,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'center',
    margin: Spacing.xl,
    padding: Spacing.l,
    position: 'absolute',
    right: 0,
    ...Platform.select({
      android: { elevation: 10, overflow: 'hidden' },
      default: {
        shadowColor: '#000000',
        shadowOffset: { height: 10, width: 0 },
        shadowOpacity: 0.25,
        shadowRadius: 15,
      },
    }),
  },
});
