import {
  Alert,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { KitIcon, KitModal, KitText } from '@omni/kit/components';
import React, { useState } from 'react';
import {
  appKeySelector,
  channelSelector,
} from '../../../shared/redux/selectors';
import {
  blockUser,
  changeManagerToUser,
  changeUserToManager,
  removeUser,
  unblockUser,
} from '../../../shared/redux/actions/ChatActions';
import { useDispatch, useSelector } from 'react-redux';

import { ChannelMember } from '../../Types';
import Colors from '@omni/kit/theming/Colors';
import ConfirmModal from '../web/ConfirmModal';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import Spacing from '@omni/kit/theming/Spacing';

interface MemberActionSheetProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  selectedUser?: ChannelMember | null;
  setSelectedUser: (user: ChannelMember | null) => void;
  isSelectedUserOwner: boolean;
  isSelectedUserBlocked: boolean;
  enableChannelActions: boolean;
  enableBlockUserActions: boolean;
  showKitSnack: (msg: string, duration: number) => void;
}

export default function MemberActionSheet({
  enableBlockUserActions,
  enableChannelActions,
  isSelectedUserBlocked,
  isSelectedUserOwner,
  selectedUser,
  setSelectedUser,
  setVisible,
  showKitSnack,
  visible,
}: MemberActionSheetProps): JSX.Element | null {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmActionButtonText, setConfirmActionButtonText] = useState('');
  const [confirmActionTitle, setConfirmActionTitle] = useState('');
  const [confirmActionMessage, setConfirmActionMessage] = useState('');
  const [confirmActionKey, setConfirmActionKey] = useState('');

  const appKey = useSelector(appKeySelector);
  const channel = useSelector(channelSelector);
  const dispatch = useDispatch();

  const _askUnblockUser = () => {
    const title = `Unblock ${selectedUser?.nickname}`;
    const msg = `You will be able to receive new messages from ${
      selectedUser?.nickname?.split(' ')[0]
    }, but not anything that may have been sent during the block.`;
    _showConfirmAlert(
      title,
      msg,
      'Unblock',
      () =>
        _confirmUnblockUser(
          channel as GroupChannel,
          selectedUser as ChannelMember
        ),
      'unblock-user',
      'default'
    );
  };

  const _confirmUnblockUser = (channel: GroupChannel, user: ChannelMember) => {
    setVisible(!visible);
    dispatch(unblockUser(channel, user));
    setSelectedUser(null);
  };

  const _askBlockUser = () => {
    const title = `Block ${selectedUser?.nickname}`;
    const msg = 'They will not be able to send or receive messages from you.';
    _showConfirmAlert(
      title,
      msg,
      'Block',
      () =>
        _confirmBlockUser(
          channel as GroupChannel,
          selectedUser as ChannelMember
        ),
      'block-user'
    );
  };

  const _confirmBlockUser = (channel: GroupChannel, user: ChannelMember) => {
    setVisible(!visible);
    dispatch(blockUser(channel, user));
    setSelectedUser(null);
  };

  const _askPromoteUser = () => {
    const title = `Change ${selectedUser?.nickname}'s role to Manager`;
    const message =
      'They will have the ability to edit the group, delete messages, and remove Members.';
    _showConfirmAlert(
      title,
      message,
      'Change',
      _confirmPromoteUser,
      'promote-user',
      'default'
    );
  };

  const _confirmPromoteUser = () => {
    dispatch(
      changeUserToManager(
        channel as GroupChannel,
        selectedUser as ChannelMember
      )
    );
    setVisible(!visible);
    showKitSnack(
      `${selectedUser?.nickname} changed to Manager`,
      KitSnackDuration.SHORT
    );
  };

  const _askDemoteUser = () => {
    const title = `Change ${selectedUser?.nickname}'s role to Member`;
    const message =
      'They will lose the ability to edit the group, delete messages, and remove Members.';
    _showConfirmAlert(
      title,
      message,
      'Change',
      _confirmDemoteUser,
      'demote-user',
      'default'
    );
  };

  const _confirmDemoteUser = () => {
    dispatch(
      changeManagerToUser(
        channel as GroupChannel,
        selectedUser as ChannelMember,
        appKey
      )
    );
    setVisible(!visible);
    showKitSnack(
      `${selectedUser?.nickname} changed to Member`,
      KitSnackDuration.SHORT
    );
  };

  const _askRemoveUser = () => {
    const title = `Remove ${selectedUser?.nickname}`;
    const msg =
      'Are you sure you want to remove this member? They will no longer have access to the group or its messages.';
    _showConfirmAlert(
      title,
      msg,
      'Remove',
      () =>
        _confirmRemoveUser(
          channel as GroupChannel,
          selectedUser as ChannelMember
        ),
      'remove-user'
    );
  };

  const _confirmRemoveUser = (channel: GroupChannel, member: ChannelMember) => {
    setVisible(!visible);
    dispatch(removeUser(channel, member));
    setSelectedUser(null);
  };

  const _showConfirmAlert = (
    title: string,
    message: string,
    confirmButtonText: string,
    confirmAction: (value?: string | undefined) => void,
    actionKey: string,
    confirmStyle:
      | 'cancel'
      | 'default'
      | 'destructive'
      | undefined = 'destructive'
  ) => {
    if (Platform.OS === 'web') {
      setConfirmActionTitle(title);
      setConfirmActionMessage(message);
      setConfirmActionButtonText(confirmButtonText);
      setConfirmActionKey(actionKey);
      setConfirmModalVisible(true);

      return;
    }

    Alert.alert(title, message, [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: confirmButtonText,
        onPress: confirmAction,
        style: confirmStyle,
      },
    ]);
  };

  const _onConfirm = (action: string) => {
    setConfirmModalVisible(false);
    switch (action) {
      case 'unblock-user':
        _confirmUnblockUser(
          channel as GroupChannel,
          selectedUser as ChannelMember
        );
        break;

      case 'block-user':
        _confirmBlockUser(
          channel as GroupChannel,
          selectedUser as ChannelMember
        );
        break;

      case 'promote-user':
        _confirmPromoteUser();
        break;

      case 'demote-user':
        _confirmDemoteUser();
        break;

      case 'remove-user':
        _confirmRemoveUser(
          channel as GroupChannel,
          selectedUser as ChannelMember
        );
        break;
    }
  };

  if (!visible) return null;

  return (
    <KitModal visible={visible} setVisible={setVisible}>
      <View style={styles.actionSheetContainer}>
        <KitText fontSize={14} gray style={styles.actionSheetParagraphText}>
          {selectedUser?.nickname}
        </KitText>

        {enableBlockUserActions &&
          (isSelectedUserBlocked ? (
            <TouchableOpacity
              style={styles.actionSheetButton}
              onPress={_askUnblockUser}
            >
              <KitText
                fontSize={16}
                black
                style={{ ...styles.actionSheetButtonText, marginLeft: 0 }}
              >
                Unblock user
              </KitText>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={styles.actionSheetButton}
              onPress={_askBlockUser}
            >
              <KitIcon name='block' size={22} color={Colors.R500} />
              <KitText fontSize={16} black style={styles.actionSheetButtonText}>
                Block user
              </KitText>
            </TouchableOpacity>
          ))}

        {enableChannelActions && (
          <TouchableOpacity
            style={styles.actionSheetButton}
            onPress={_askRemoveUser}
          >
            <KitIcon name='remove' size={22} color={Colors.brand} />
            <KitText fontSize={16} black style={styles.actionSheetButtonText}>
              Remove from group
            </KitText>
          </TouchableOpacity>
        )}

        {enableChannelActions && (
          <TouchableOpacity
            style={styles.actionSheetButton}
            onPress={isSelectedUserOwner ? _askDemoteUser : _askPromoteUser}
          >
            <KitIcon name='person-circle' size={22} color={Colors.brand} />
            <KitText fontSize={16} black style={styles.actionSheetButtonText}>
              {isSelectedUserOwner
                ? 'Change to Member'
                : 'Change role to Manager'}
            </KitText>
          </TouchableOpacity>
        )}
      </View>

      <KitModal
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
      >
        <ConfirmModal
          title={confirmActionTitle}
          message={confirmActionMessage}
          cancelButtonText='Cancel'
          confirmButtonText={confirmActionButtonText}
          setConfirmModalVisible={setConfirmModalVisible}
          onConfirm={_onConfirm}
          action={confirmActionKey}
        />
      </KitModal>
    </KitModal>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  actionSheetButton: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    marginVertical: Spacing.l,
  },
  actionSheetButtonText: {
    marginLeft: 16,
  },
  actionSheetContainer: {
    marginHorizontal: Spacing.l,
    paddingBottom: Spacing.xl,
  },
  actionSheetParagraphText: {
    marginTop: Spacing.xl,
    marginVertical: 8,
  },
});
