import { Platform, StyleSheet } from 'react-native';

import BorderRadius from '../../theming/BorderRadius';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';

export const defaultStyles = StyleSheet.create({
  viewContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    height: 44,
    paddingHorizontal: 14,
    paddingVertical: Platform.OS === 'android' ? 11 : 0,
    flexDirection: Platform.OS === 'android' ? 'column' : 'row',
    alignItems: Platform.OS === 'android' ? 'stretch' : 'center',
    justifyContent:
      Platform.OS === 'web'
        ? 'flex-end'
        : Platform.OS === 'ios'
        ? 'space-between'
        : 'center',
    borderRadius: BorderRadius.m,
    backgroundColor: Colors.N0,
    borderWidth: 1,
    borderColor: Colors.N100,
  },
  iconContainer: {
    position: 'absolute',
  },
  modalViewTop: {
    flex: 1,
  },
  modalViewMiddle: {
    height: 45,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 10,
    backgroundColor: '#f8f8f8',
    borderTopWidth: 1,
    borderTopColor: '#dedede',
    zIndex: 2,
  },
  done: {
    color: '#007aff',
    fontWeight: '600',
    fontSize: 17,
    paddingTop: 1,
    paddingRight: 11,
  },
  doneDepressed: {
    fontSize: 19,
  },
  modalViewBottom: {
    justifyContent: 'center',
    backgroundColor: '#d0d4da',
  },
  placeholder: {
    color: '#c7c7cd',
  },
  headlessAndroidPicker: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: 'transparent',
    opacity: 0,
  },
  inputAndroid: { color: Colors.N900, fontSize: 16, fontWeight: 'normal' },
  inputIOS: {
    color: Colors.N900,
    fontSize: 16,
    fontWeight: 'normal',
  },
  inputWeb: {
    backgroundColor: 'transparent',
    color: Colors.N900,
    fontFamily: 'inherit',
    fontSize: 16,
    fontWeight: 'normal',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    lineHeight: 18,
    paddingRight: Spacing.xl,
    // @ts-ignore
    appearance: 'none',
    borderRadius: BorderRadius.m,
    paddingLeft: 14,
    outlineColor: Colors.N1000,
    zIndex: 1,
  },
  active: {
    borderWidth: 2,
    borderColor: Colors.N900,
    paddingHorizontal: Spacing.l - 1,
  },
  invalid: { borderColor: Colors.R500, borderWidth: 2 },
  large: {
    height: 54,
    paddingHorizontal: Spacing.l,
  },
  inputLarge: {
    fontSize: 20,
  },
  inputWebLarge: {
    fontSize: 20,
    paddingLeft: Spacing.l,
  },
});
