import { useState } from 'react';
import { Dimensions, LayoutChangeEvent } from 'react-native';

// only use this in cases where Dimensions.get('window') is not sufficient,
// such as in small web embeds.
export default function useViewPortLayout(flex?: number): {
  onViewPortLayout: (event: LayoutChangeEvent) => void;
  smallestViewPortWidth: number;
  viewPortWidth: number;
  viewPortHeight: number;
} {
  const [viewPortWidth, setViewPortWidth] = useState(0);
  const [viewPortHeight, setViewPortHeight] = useState(0);
  const [smallestViewPortWidth, setSmallestViewPortWidth] = useState(0);

  const onViewPortLayout = (event: LayoutChangeEvent) => {
    setViewPortWidth(event.nativeEvent.layout.width);

    let height = event.nativeEvent.layout.height;

    // use Window dimensions for height in cases where the
    // the children determine the height of the layout (e.g. KitModalV2)
    // as opposed to a fixed height determined by an iframe
    if (!flex) {
      height = Dimensions.get('window').height;
    }

    setViewPortHeight(height);

    setSmallestViewPortWidth(Math.min(event.nativeEvent.layout.width, height));
  };

  return {
    onViewPortLayout,
    smallestViewPortWidth,
    viewPortWidth,
    viewPortHeight,
  };
}
