import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';

import Environment from '../../../Environment';
import { IJoinRequest } from '../Types';

const RESOURCE = 'join-requests';

interface ICreateJoinRequest extends Omit<IUri, 'resource'> {
  endUserId: string;
  groupId: string;
  token: string;
}

interface ICreateJoinRequestResponse extends IHttpResponse {
  body?: IJoinRequest;
}

export default ({
  endUserId,
  groupId,
  token,
}: ICreateJoinRequest): Promise<ICreateJoinRequestResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    resource: RESOURCE,
  });

  const data = {
    _embedded: { group: { id: groupId }, 'end-user': { id: endUserId } },
  };

  return BaseServiceV2.Post({ url, token, data });
};
