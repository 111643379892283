import React, { useState } from 'react';
import { Platform, StyleSheet, View, ViewStyle } from 'react-native';
import MaskInput, { Masks } from 'react-native-mask-input';

import Colors from '../theming/Colors';
import { useThemeContext } from '../theming/ThemeContext';

interface IKitInputMaskProps {
  placeholder: string;
  value: string;
  testID?: string;
  isValid?: boolean;
  keyboardType?:
    | 'default'
    | 'number-pad'
    | 'decimal-pad'
    | 'numeric'
    | 'email-address'
    | 'phone-pad';
  onChangeText: (inputText: string) => void;
  onBlur?: any;
  style?: ViewStyle;
}
export default function KitInputMask({
  placeholder = '',
  value = '',
  testID = '',
  isValid = true,
  keyboardType,
  onChangeText,
  onBlur,
  style,
}: IKitInputMaskProps): JSX.Element {
  const [birthdayInputFocus, setBirthdayInputFocus] = useState<boolean>(false);

  const { fontFamily } = useThemeContext();

  return (
    <View style={[styles(birthdayInputFocus, isValid).container, style]}>
      {/*
      // @ts-ignore (library has incorrect type definitions) */}
      <MaskInput
        onFocus={() => setBirthdayInputFocus(true)}
        onBlur={() => {
          onBlur && onBlur();
          setBirthdayInputFocus(false);
        }}
        mask={Masks.DATE_MMDDYYYY}
        placeholder={placeholder}
        keyboardType={keyboardType}
        value={value}
        testID={testID}
        style={{
          fontSize: 17,
          lineHeight: 20,
          ...Platform.select({
            web: {
              outlineWidth: 0,
              fontFamily: 'inherit',
            },
            ios: {
              fontFamily,
            },
            android: {
              fontFamily,
            },
          }),
        }}
        onChangeText={onChangeText}
      />
    </View>
  );
}
export const styles = (
  birthdayInputFocus: boolean,
  isValid: boolean
): Record<string, any> =>
  StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: Colors.N50,
      borderWidth: 2,
      borderRadius: 8,
      borderColor: !isValid
        ? 'red'
        : birthdayInputFocus
        ? Colors.N900
        : 'transparent',
      height: 44,
      paddingLeft: 14,
    },
  });
