import { IJoinRequest } from '@omni/kit/services/GroupsService/Types';
import { Branding, ILogoImage } from '@omni/kit/services/Types';
import { User } from '@sendbird/chat';
import { GroupChannel, Member } from '@sendbird/chat/groupChannel';
import { AdminMessage, FileMessage, UserMessage } from '@sendbird/chat/message';
import { useDispatch } from 'react-redux';

import { BibleBook, BibleLocation } from '../../../../src/bible/types';
import { ChannelMember, CustomMessageData } from '../../Types';
import store from './store';

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export interface IAppState {
  chat: IChatState;
  system: ISystemState;
}

export interface IChatState {
  channel: GroupChannel | null;
  channelName: string;
  messageList: Array<UserMessage | FileMessage | AdminMessage>;
  prayerList: Array<UserMessage>;
  memberList: Array<ChannelMember>;
  blockedUserList: Array<User>;
  invitedList: Array<IInvited>;
  blockedUser: Member | null;
  unblockedUser: Member | null;
  addedUsersList: Array<IInvited> | null;
  removedUser: Member | null;
  userTyping: string | null;
  isLoading: boolean;
  parentMessageId: number | undefined;
  sendToChannel: boolean;
  inviteAccepted: boolean;
  joinRequests: Array<IJoinRequest>;
}

export interface ISystemState {
  user: User | null;
  userId: string | undefined;
  channel: GroupChannel | null;
  canCreateDirectMessage: boolean;
  canCreateChannels: boolean;
  userToken: string;
  accessToken: string;
  environment: string;
  systemError: string;
  userList: User[];
  channelsList: GroupChannel[] | null;
  publicChannels: GroupChannel[] | null;
  deletedChannel: string;
  isLoading: boolean;
  appKey: string;
  orgKey: string;
  appId: string;
  appTitle: string;
  brandColor: string;
  pushPreference: string;
  viewIsReady: boolean;
  bibleBooks: BibleBook[];
  bibleLocation: BibleLocation;
  appBranding: {
    branding: Branding | undefined;
    logo: ILogoImage | undefined;
  };
  profileImageUrl: string;
}

//******************************************************************************
// Other
//******************************************************************************

export interface IInvited {
  id: string;
  timestamp: number;
  title: string;
}

export interface IInvite {
  type: InviteType;
  data: any;
  title: string;
  created_at?: Date;
  message_endpoint?: string;
}

export interface IInviteRecord {
  id: string;
  created_at?: Date;
  message_endpoint?: string;
}

export enum InviteType {
  User = 'user',
  Phone = 'phone',
  Email = 'email',
  None = 'none',
}

export enum ChannelType {
  Direct = 'direct',
  Group = 'group',
  Feed = 'feed',
}

export enum MessageType {
  Text = 'text',
  Photo = 'photo',
  Video = 'video',
  Gif = 'gif',
  File = 'file',
  Poll = 'poll',
  Prayer = 'prayer',
  Media = 'media',
  Bible = 'bible',
  Announcement = 'announcement',
}

export interface IFile {
  uri?: string;
  name: string;
  type: string;
  customType: MessageType;
  data?: CustomMessageData;
  file?: File;
}

export interface MediaMessageDataType extends CustomMessageData {
  id: string;
  image: string;
  title: string;
  description: string;
  speaker: string;
}
