import { useShellContext } from '@omni/kit';
import { BridgeAction } from '@omni/kit/Types';
import { KitButton } from '@omni/kit/components';
import { IconName } from '@omni/kit/components/KitIcon';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { useBlockPageContext } from '../BlockPageContext';
import { IBlockProps } from './types';

const debug = require('debug')('tca:blocks:blocks:ButtonBlock');

// These props are publicly available
export interface IButtonBlockProps extends IBlockProps {
  align?: 'start' | 'end' | 'center';
  title?: string;
  /** An action that will occur when a user taps/clicks on the block. */
  action?: BridgeAction | null;
  secondary?: boolean;
  color?: string;
  textColor?: string;
  width?: 'full' | 'content';
  icon?: IconName;
}

export default function ButtonBlock(props: IButtonBlockProps): JSX.Element {
  const {
    align,
    title = 'New Button',
    insetStyle = {},
    topSpacing = null,
    bottomSpacing = null,
    horizontalSpacing,
    secondary = true,
    color,
    textColor,
    width,
    icon,
  } = props;

  const { dispatchEvent } = useShellContext();

  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const { action } = props;

  const { dispatchAction } = useBlockPageContext();

  const isAuth = action?.handler === 'auth' && action.hasOwnProperty('appKey');
  const isSmall = width === 'content';

  const _onPress = () => {
    if (isAuth) {
      dispatchEvent({
        type: 'AUTHENTICATE_USER',
        openInNewTab: true,
      });
    } else {
      dispatchAction(action);
    }
  };

  const viewStyle = {
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
    ...insetStyle,
  };

  const StyledView = styled(View)`
    ${isSmall && (!align || align === 'center') && 'align-items: center;'}
    ${isSmall && align === 'start' && 'align-items: flex-start;'}
    ${isSmall && align === 'end' && 'align-items: flex-end;'}
  `;

  return (
    <>
      <View style={viewStyle}>
        <StyledView>
          <View
            style={{
              marginHorizontal: edgeSpacing,
            }}
          >
            <KitButton
              title={title}
              secondary={secondary}
              onPress={_onPress}
              color={color}
              textColor={textColor}
              small={isSmall}
              icon={icon}
            />
          </View>
        </StyledView>
      </View>
    </>
  );
}
