import '@omni/kit/localization';

import { AppRegistry } from 'react-native';
import HealthMonitor from '@omni/kit/HealthMonitor';
import Messaging from './Messaging.web';
import { enableScreens } from 'react-native-screens';

enableScreens(false);

/* This is used to instantiate the app for web deployment */
AppRegistry.registerComponent('Messaging', () => Messaging);
AppRegistry.runApplication('Messaging', {
  rootTag: document.getElementById('root'),
});

HealthMonitor.init('web-messaging');
