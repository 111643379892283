import { KitText, KitTouchable } from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import Show from '@omni/kit/components/Show';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { getLinkPreview, getPreviewFromContent } from 'link-preview-js';
import React, { useEffect, useState } from 'react';
import { Linking, Platform, StyleSheet, View } from 'react-native';

import BaseServiceV2 from '../services/BaseServiceV2';

const debug = require('debug')('omni:kit:components:KitLinkPreview');

export default function LinkPreview({
  url,
}: {
  url: string;
}): JSX.Element | null {
  const [linkData, setLinkData] = useState<any | null>(null);

  const onPress = () => {
    if (!linkData) return;

    const formattedUrl = `${!linkData.url.includes('http') ? 'https://' : ''}${
      linkData.url
    }`;
    openLink(formattedUrl);
  };

  const openLink = (link: string) => {
    if (Platform.OS === 'web') {
      const win = window.open(link, '_blank');
      win?.focus();

      return;
    }

    Linking.canOpenURL(link.toLowerCase()).then((supported) => {
      if (supported) {
        Linking.openURL(link);
      } else {
        console.warn('Unsupported URI: ' + link);
      }
    });
  };

  const fetchWithAllOrigins = async (url: string) => {
    /**
     * https://github.com/gnuns/allorigins
     * Use all origins to bypass CORs for link preview
     * */
    const encodedUrl = `https://api.allorigins.win/get?url=${encodeURIComponent(
      url
    )}`;
    const cachedResponse = await BaseServiceV2.Get({
      url: encodedUrl,
      getFromCache: true,
    });

    if (cachedResponse.body) {
      return cachedResponse;
    } else {
      return BaseServiceV2.Get({
        url: encodedUrl,
      });
    }
  };

  const fetchCorsUrl = async (url: string) => {
    if (!url) return;

    try {
      debug('getLinkPreview', url);
      setLinkData(await getLinkPreview(url));

      return;
    } catch (e: any) {
      debug('getLinkPreview error:', url, e?.message);
      // @ts-ignore
      if (e?.message?.includes('did not receive a valid a url or text')) {
        return;
      }
    }

    try {
      debug('fetchWithAllOrigins', url);
      const response = await fetchWithAllOrigins(url);
      const parsed = await getPreviewFromContent({
        data: response.body.contents,
        headers: {
          'content-type': response.body.status.content_type,
        },
        url,
      });
      setLinkData(parsed);
    } catch (e) {
      debug('fetchWithAllOrigins error:', e);
    }
  };

  useEffect(() => {
    if (url) fetchCorsUrl(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return linkData ? (
    <KitTouchable
      noRadius
      onPress={onPress}
      style={[styles.linkPreviewWrapper]}
      touchableStyle={{
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
      }}
    >
      <>
        <View
          style={{
            paddingBottom: 10,
          }}
        >
          <Show show={linkData.title && linkData.title.length > 0}>
            <KitText semiBold black numberOfLines={2}>
              {linkData.title}
            </KitText>
          </Show>
          <Show show={linkData.description && linkData.description.length > 0}>
            <KitText
              fontSize={13}
              numberOfLines={2}
              style={{ marginTop: 6, lineHeight: 15 }}
              color={Colors.N700}
            >
              {linkData.description}
            </KitText>
          </Show>
        </View>
        <Show show={Boolean(linkData.images?.[0])}>
          <KitImage
            source={{ uri: linkData.images?.[0] }}
            style={{ height: 130, borderRadius: 6 }}
            resizeMode='contain'
          />
        </Show>
      </>
    </KitTouchable>
  ) : null;
}

const styles = StyleSheet.create({
  linkPreviewWrapper: {
    borderLeftWidth: 3,
    borderColor: Colors.N200,
    paddingLeft: Spacing.m,
    marginTop: Spacing.s,
  },
  clickable: {
    textDecorationLine: 'underline',
  },
});
