import { shortCommitHash, version } from '@omni/kit';
import { ErrorEvent, Event, EventHint, Options } from '@sentry/types';

import { ENV } from '../Environment';

const debug = require('debug')('tca:kit:sentry');

export const initOptions = (
  product: string
): {
  dsn: string;
  tracesSampleRate: number;
  environment: string;
  release: string;
  dist: string;
  beforeBreadcrumb: Options['beforeBreadcrumb'];
} => {
  return {
    dsn: 'https://d6a3f69d541249b886cc1a9e6aee7b91@o427443.ingest.us.sentry.io/5600940',

    /**
     * Performance Monitoring Sample Rate
     * https://docs.sentry.io/platforms/react-native/performance/
     * value: a number between 0 and 1.0 where 1.0 sends 100% of transactions
     * Please keep at 0 for prod to avoid quickly exhausting transaction limit.
     * The Subsplash organization has a limit of 100k transactions
     * and this can be easily exhausted if we send transactions for millions of users.
     * ref: https://subsplash.atlassian.net/browse/PLAT-1257
     */
    tracesSampleRate: ENV === 'prod' ? 0.0001 : 1.0,

    environment: ENV,

    release: `${product}@${version}+${shortCommitHash}`,

    /**
     * Coalesce a single release for each package, version & environment.
     *
     * Without 'dist', by default, a sentry release will be created unique
     * to each app binary.
     *
     * Since the environment value dev|stage|prod is embedded in each release,
     * we need a 'dist' unique to each environment.
     *
     * When uploading sourcemaps, they will be labeled with this 'dist'
     * within sentry artifacts for the release.
     */
    dist: ENV,

    // scrub sensitive values such as tokens from breadcrumb logs
    beforeBreadcrumb: function (breadcrumb, _hint) {
      let filteredMessage = breadcrumb.message;
      let filteredData = breadcrumb.data;

      if (filteredMessage?.match(/token/i)) {
        filteredMessage = '[Redacted]';
      }

      if (
        Boolean(filteredData) &&
        JSON.stringify(filteredData).match(/token/i)
      ) {
        filteredData = {
          arguments: '[Redacted]',
          logger: filteredData?.logger,
        };
      }

      return { ...breadcrumb, message: filteredMessage, data: filteredData };
    },
  };
};
