import * as React from 'react';

import { StyleSheet, View } from 'react-native';

import Colors from '@omni/kit/theming/Colors';
import { KitText } from '@omni/kit/components';
import Spacing from '@omni/kit/theming/Spacing';
import moment from 'moment';

export default function DaySeparator({
  date,
  newMessage,
}: {
  date: Date | number;
  newMessage: boolean;
}): JSX.Element {
  return (
    <View
      style={{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginHorizontal: newMessage ? Spacing.m : 0,
      }}
    >
      {newMessage && (
        <KitText fontSize={12} semiBold color={Colors.R500}>
          New
        </KitText>
      )}

      <View style={styles(newMessage).line} />
      <View style={styles(newMessage).dateWrapper}>
        <KitText fontSize={14} black>
          {moment(date).format('dddd, MMMM D')}
        </KitText>
      </View>
      <View style={styles(newMessage).line} />
    </View>
  );
}

const styles = (newMessage = false) =>
  StyleSheet.create({
    dateWrapper: {
      alignSelf: 'center',
      backgroundColor: Colors.N100,
      borderRadius: 30,
      marginHorizontal: newMessage ? -Spacing.s : 0,
      marginVertical: Spacing.xl,
      paddingHorizontal: Spacing.xl,
      paddingVertical: Spacing.xxs,
    },
    line: {
      backgroundColor: newMessage ? Colors.R500 : Colors.N100,
      flex: 1,
      height: 1.2,
      marginHorizontal: newMessage ? Spacing.s : Spacing.xl,
    },
  });
