import React, { createContext, useContext, useEffect, useState } from 'react';

import { retrieveBibleSettingsFromDevice } from '../../../../src/bible/api/bible';
import { retrieveGlobalDataFromDevice } from '../../../../src/bible/api/mobileStorage';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { UNLEASH_TOGGLES } from '../../services/UnleashService';
import ScriptureModal from './ScriptureModal';
import {
  IScriptureModalContextProviderProps,
  TScriptureModalContextType,
} from './types';

const initialScriptureModalContext: TScriptureModalContextType = {
  showScriptureModal: (osis: string) => undefined,
};

export const ScriptureModalContext = createContext(
  initialScriptureModalContext
);

export const ScriptureModalContextProvider = ({
  children,
}: IScriptureModalContextProviderProps): null | JSX.Element => {
  const [osis, setOsis] = useState<string>('');
  const [bibleVersion, setBibleVersion] = useState<string>('');
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  const [isScriptureModalVisible, setIsScriptureModalVisible] =
    useState<boolean>(false);

  const { isEnabled, isReady } = useFeatureFlag(
    UNLEASH_TOGGLES.MEDIA_KING_JAMES_TRANSLATION
  );

  const showScriptureModal = async (text: string) => {
    setOsis(text);
    setShouldShowModal(true);
  };

  const hideScriptureModal = () => {
    setShouldShowModal(false);
    setIsScriptureModalVisible(false);
  };

  const getBibleVersion = async () => {
    let version = 'NIV';

    if (isEnabled) {
      try {
        await retrieveGlobalDataFromDevice('@isKJVBibleDefaultTranslation');
      } catch (e) {
        version = 'KJV';
      }
    }

    try {
      const bibleSettings = await retrieveBibleSettingsFromDevice();
      version = bibleSettings.versionDetails.id;
    } catch (e) {
      // no-op
    }

    setBibleVersion(version);
    setIsScriptureModalVisible(true);
  };

  useEffect(() => {
    if (isReady && shouldShowModal) {
      getBibleVersion();
    }
  }, [shouldShowModal, isReady, isEnabled]);

  return (
    <ScriptureModalContext.Provider value={{ showScriptureModal }}>
      <>
        {children}
        <ScriptureModal
          isVisible={isScriptureModalVisible}
          onClose={hideScriptureModal}
          osis={osis}
          bibleVersion={bibleVersion}
        />
      </>
    </ScriptureModalContext.Provider>
  );
};

export const useScriptureModalContext = (): TScriptureModalContextType => {
  return useContext<TScriptureModalContextType>(ScriptureModalContext);
};
