import PropTypes from 'prop-types';
import React from 'react';
import { NativeModules, View, requireNativeComponent } from 'react-native';

let NativeView = null;

if ('OmniActivityIndicator' in NativeModules.UIManager) {
  NativeView = module.exports = requireNativeComponent('OmniActivityIndicator');
}

export default class NativeActivityIndicator extends React.Component {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <View style={{ alignItems: 'center' }}>
        <NativeView {...this.props} />
      </View>
    );
  }
}

NativeActivityIndicator.propTypes = {
  color: PropTypes.string,
};
