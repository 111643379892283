import React from 'react';
import {
  Platform,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import Spacing from '../../theming/Spacing';
import { useThemeContext } from '../../theming/ThemeContext';
import Icon from './Icon';
import { defaultStyles } from './styles';

interface TriggerProps extends ViewProps {
  active?: boolean;
  isValid?: boolean;
  large?: boolean;
  onPress?: () => void;
  placeholder?: string;
  placeholderTextColor?: string;
  style?: ViewStyle;
  testID?: string;
  textInputProps?: TextInputProps;
  rightElement?: JSX.Element;
  value?: string;
}
const Trigger = React.forwardRef<TouchableOpacity, TriggerProps>(
  (props: TriggerProps, ref) => {
    const {
      active,
      isValid = true,
      large = false,
      onPress,
      placeholder,
      placeholderTextColor,
      rightElement,
      style,
      testID,
      textInputProps,
      value,
    } = props;

    const { fontFamily } = useThemeContext();

    return (
      <TouchableOpacity
        activeOpacity={1}
        onPress={onPress}
        testID={testID || 'ios_touchable_wrapper'}
        {...props}
        ref={ref}
        style={[
          defaultStyles.viewContainer,
          style,
          large ? defaultStyles.large : undefined,
          active ? defaultStyles.active : undefined,
          isValid ? undefined : defaultStyles.invalid,
        ]}
      >
        <View pointerEvents='box-only'>
          <TextInput
            editable={false}
            placeholder={placeholder}
            placeholderTextColor={placeholderTextColor}
            style={{
              ...(fontFamily ? { fontFamily } : {}),
              ...(Platform.OS === 'ios'
                ? defaultStyles.inputIOS
                : defaultStyles.inputAndroid),
              ...(large ? defaultStyles.inputLarge : {}),
              ...(!value && placeholderTextColor
                ? { color: placeholderTextColor }
                : {}),
            }}
            testID='text_input'
            value={value}
            {...textInputProps}
          />
        </View>
        {rightElement ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ marginRight: Spacing.l }}>{rightElement}</View>
            <Icon />
          </View>
        ) : (
          <Icon />
        )}
      </TouchableOpacity>
    );
  }
);

export default Trigger;
