import { isUrlAuthorizedForProviders } from '@omni/kit/auth';

import { IDownloaderResponse, RequestOptions, SharedHeaderOverrides } from '.';
import GetAppHeaders from './GetAppHeaders';

const debug = require('debug')('tca:kit:Downloader:GetRequestHeaders');

interface IGetRequestHeadersArgs {
  url: string;
  options: RequestOptions;
  cachedResponse?: IDownloaderResponse;
}

export type IRequestHeaders = Record<string, string | number | boolean>;

export default async ({
  url,
  options,
  cachedResponse,
}: IGetRequestHeadersArgs): Promise<IRequestHeaders> => {
  const etagHeader: IRequestHeaders = {};

  if (cachedResponse) {
    try {
      etagHeader['If-None-Match'] = cachedResponse.headers.etag;
    } catch {
      debug('could not get etag header');
    }
  }

  let appHeaders: IRequestHeaders = {};
  try {
    appHeaders = await GetAppHeaders(options.appKey);
  } catch {}

  const requestHeaders: IRequestHeaders = {
    ...appHeaders,
    ...SharedHeaderOverrides,
    ...etagHeader,
    ...options.extraHeaders,
  };

  const hasAuthorization = Boolean(requestHeaders.Authorization);

  // Do not include Authorization for potentially malicious/third-party domains
  if (hasAuthorization && !options.domainPreValidated) {
    let permitted = false;
    try {
      permitted = isUrlAuthorizedForProviders({
        providers: options.targetProviders || [],
        url,
      });
    } catch {}

    debug(`Authorization permitted:${permitted} for:${url}`);
    if (!permitted) {
      delete requestHeaders.Authorization;
    }
  }

  return requestHeaders;
};
