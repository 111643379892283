const Colors = {
  N0: '#FFFFFF',
  N25: '#FAFBFC',
  N50: '#F5F6F7',
  N75: '#F2F3F5',
  N100: '#EDEEF0',
  N150: '#E1E2E6',
  N200: '#C8CACF',
  N300: '#B8BABF',
  N400: '#999B9E',
  N500: '#747578',
  N600: '#525254',
  N700: '#434445',
  N800: '#323233',
  N900: '#222222',
  G50: '#D4F2E2',
  G300: '#42C97F',
  G3001A: '#42C97F1A',
  G700: '#227748',
  N1000: '#000000',
  N10006: '#00000099',
  brand: '#4f63ff', // TODO: change to B500
  facebook: '#1877f2',
  prayer: '#0197A7',
  R100: '#FEE5EC',
  R500: '#F90143',
  R700: '#C31443',
  S100: '#EDEFFF',
  Y100: '#FFEBC7',
  Y700: '#806000',
} as const;

export type ColorString = `#${string}` | `rgb(${string})` | `rgba(${string})`;

export default Colors;
