import { removeStoredItem } from '@omni/kit/storage';

const ClearCache = async (): Promise<void> => {
  await removeStoredItem('assigned-kiosk');
  await removeStoredItem('check_in_device_id');
  await removeStoredItem('check_in_app_key');
  await removeStoredItem('check_in_org_key');
  await removeStoredItem('check_in_access_token');
  await removeStoredItem('check_in_access_token_expires_at');
  await removeStoredItem('check_in_refresh_token');
  await removeStoredItem('direct-print-station-id');
  await removeStoredItem('fluro-print-station-id');
  // TODO: Delete print station from Fluro & archive device in check-in service
};

export default ClearCache;
