import { IFile, MessageType } from '../shared/redux/types';

import { Image as ImagePickerImage } from 'react-native-image-crop-picker';
import { PlatformAgnosticFile } from '@omni/kit/FileTypes';
import RNPhotoManipulator from 'react-native-photo-manipulator';

export const webImageToAgnosticFile = (image: File) => {
  const uri = URL.createObjectURL(image);

  const file: PlatformAgnosticFile = {
    contentType: image.type,
    fileName: image.name,
    uri: uri,
    size: image.size,
    _original: image,
  };

  return file;
};

export const nativeImageToAgnosticFile = (image: ImagePickerImage) => {
  const file: PlatformAgnosticFile = {
    contentType: image.mime,
    fileName: image.filename || 'unknown',
    uri: image.path,
    size: image.size,
    _original: image,
  };

  return file;
};

export const isLocalFileUrl = (url: string) => {
  return (
    url.startsWith('/') || url.startsWith('file://') || url.startsWith('blob:')
  );
};

export const processImageFile = (file: File) => {
  const image = new Image();
  image.src = URL.createObjectURL(file);

  return new Promise((resolve) => {
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      const ratio = image.naturalWidth / image.naturalHeight;
      // @ts-ignore
      canvas.getContext('2d').drawImage(image, 0, 0);
      canvas.toBlob((blob) => {
        // Use the file rename trick to turn it back into a file
        // @ts-ignore
        const myImage = new File([blob], 'file.jpeg', {
          // @ts-ignore
          type: blob.type,
        });
        const files: IFile = {
          file:
            file.type === 'image/webp'
              ? myImage
              : file.type === 'image/jpeg'
              ? myImage
              : file,
          customType: MessageType.Photo,
          data: { ratio: `${ratio}` },
          name:
            file.type === 'image/webp'
              ? myImage.name
              : file.type === 'image/jpeg'
              ? myImage.name
              : file.name,
          type:
            file.type === 'image/webp'
              ? myImage.type
              : file.type === 'image/jpeg'
              ? myImage.type
              : file.type,
        };
        resolve(files);
      }, 'image/jpeg');
    };
  });
};

export const processImageFileNative = async (
  file: any,
  ratio: number | undefined
) => {
  if (file.type?.startsWith('image')) {
    // Processing image files before sending them to server to remove EXIF metadata.

    // if image type .heic we convert them to .jpeg because most of the browsers doesn't support that type.
    // for the other image types we just compress them to lose metadata and optimize them.
    // https://github.com/guhungry/react-native-photo-manipulator

    const convertedImage =
      file.type === 'image/heic'
        ? await RNPhotoManipulator.printText(
            file.uri,
            [
              {
                position: { x: 0, y: 0 },
                text: '',
                textSize: 0,
                color: '#000000',
              },
            ],
            // @ts-ignore
            'image/jpeg'
          )
        : await RNPhotoManipulator.optimize(file.uri, 70);
    // Add converted image to file constant and return it as IFILE type
    const files: IFile = {
      uri: convertedImage,
      name: file.name.split('.')[1] === 'HEIC' ? 'file.jpeg' : file.name,
      type: file.name.split('.')[1] === 'HEIC' ? 'image/jpeg' : file.type,
      customType: MessageType.Photo,
      data: { ratio: `${ratio}` },
    };

    return files;
  } else {
    return file;
  }
};
