import React, { createContext, useContext } from 'react';

export const SourceContext = createContext<string | undefined>(undefined);

// Dev note, only add to this list if you are sure it is a valid source type
// otherwise the EUA service will return an error on requests
// see: https://subsplash.io/go/end-user-auth/-/blob/main/created_source.go
export enum SOURCE_TYPES {
  APP = 'app',
  DONATIONS = 'donations',
  LIVE_CHAT = 'live_chat',
  MESSAGING = 'messaging',
  PLAYER = 'player',
  SCHEDULE = 'schedule',
}

interface ProviderProps {
  children?: React.ReactNode;
  source?: SOURCE_TYPES;
}

export const SourceContextProvider = ({
  children,
  source,
}: ProviderProps): null | JSX.Element => {
  return (
    <SourceContext.Provider value={source}>{children}</SourceContext.Provider>
  );
};

export const useSourceContext = (): string | undefined => {
  return useContext<string | undefined>(SourceContext);
};
