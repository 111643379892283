import { KitIcon, KitText } from '@omni/kit/components';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '@omni/kit/utilities/utilities';
import moment from 'moment';
import React, { useState } from 'react';
import {
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import ImageZoom from 'react-native-image-pan-zoom';

import { downloadImage } from '../../../utilities/fileUtilities';
import { SendbirdFileMessage } from '../../Types';
import { IPicture } from '../../scenes/channel/components/messageTypes/ImageMessageBubble';
import LeftNavButton, { LeftNavType } from '../LeftNavButton';
import RoundImageButton from '../RoundImageButton';

const debug = require('debug')('tca:chat:screen:LightboxModal');

interface IProps {
  message: SendbirdFileMessage;
  picture: IPicture;
  pictureStyle: ViewStyle;
  setVisible: (value: boolean) => void;
}

export default function LightboxModal({
  message,
  picture,
  pictureStyle,
  setVisible,
}: IProps): JSX.Element {
  const [snackOptions, setSnackOptions] = useState({
    duration: KitSnackDuration.SHORT,
    message: '',
    visible: false,
  });

  const _renderAvatar = () => {
    if (picture.senderAvatar) {
      return (
        <Image source={{ uri: picture.senderAvatar }} style={styles.avatar} />
      );
    }

    return (
      <View style={styles.avatar}>
        <KitIcon name='channel' size={24} color={Colors.N0} />
      </View>
    );
  };

  const _renderHeader = () => (
    <View style={styles.lightboxHeader}>
      <View style={{ alignItems: 'center', flexDirection: 'row' }}>
        <LeftNavButton
          type={LeftNavType.Dismiss}
          onPress={() => setVisible(false)}
          title=''
          color={Colors.N0}
          underlayColor={Colors.N900}
        />
        <View style={{ width: Spacing.l }} />
        {_renderAvatar()}
        <View>
          <KitText fontSize={12} white>
            {picture.senderName}
          </KitText>
          <KitText fontSize={12} color={Colors.N400} style={styles.timeSent}>
            {moment(message.createdAt).format('MMMM Do, h:mm a')}
          </KitText>
        </View>
      </View>
      <RoundImageButton
        color='#fff'
        size={22}
        onPress={_saveToCameraRoll}
        name='download'
        light={true}
      />
    </View>
  );

  const _saveToCameraRoll = () => {
    downloadImage(picture.url)
      .then(() => {
        showKitSnack('Image saved', KitSnackDuration.SHORT);
      })
      .catch((e) => {
        console.warn(e);
        showKitSnack('Image could not be saved.', KitSnackDuration.SHORT);
      });
  };

  const onSwipePerformed = () => {
    setVisible(false);
  };

  const showKitSnack = (message: string, duration: number) => {
    setSnackOptions({ visible: true, message, duration });
  };

  const width = SCREEN_WIDTH;
  const height =
    (width * (pictureStyle.height as number)) / (pictureStyle.width as number);
  const newStyle = { width, height };

  return (
    <>
      <SafeAreaView style={styles.wrapper}>
        {_renderHeader()}
        {/* @ts-ignore */}
        <ImageZoom
          style={{ flex: 1 }}
          cropWidth={SCREEN_WIDTH}
          cropHeight={SCREEN_HEIGHT}
          imageWidth={newStyle.width}
          imageHeight={newStyle.height}
          enableDoubleClickZoom={true}
          enableSwipeDown={true}
          onSwipeDown={onSwipePerformed}
        >
          <Image source={{ uri: picture.url }} style={newStyle} />
        </ImageZoom>
      </SafeAreaView>
      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />
    </>
  );
}

const styles = StyleSheet.create({
  avatar: {
    alignItems: 'center',
    backgroundColor: Colors.N800,
    borderRadius: 40,
    height: 40,
    justifyContent: 'center',
    marginRight: 10,
    width: 40,
  },
  lightboxHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 50,
    justifyContent: 'space-between',
    paddingLeft: Platform.OS === 'ios' ? Spacing.l : Spacing.m,
  },
  senderName: {
    color: '#fff',
    fontSize: 12,
    fontWeight: '500',
  },
  timeSent: {
    color: '#999b9e',
    fontSize: 12,
  },
  wrapper: {
    backgroundColor: '#000',
    flex: 1,
  },
});
