import { KitText } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitIcon from '@omni/kit/components/KitIcon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { User } from '@sendbird/chat';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import TouchableBase from '../../../kit/components/TouchableBase';
import { IInvite, InviteType } from '../../shared/redux/types';

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  invite: IInvite;
  onPress: (...args: unknown[]) => void;
}

//******************************************************************************
// Component
//******************************************************************************

export default function UserListRow({ invite, onPress }: IProps): JSX.Element {
  const _renderAvatar = () => {
    let profileUrl = '';

    if (invite.type === InviteType.User) {
      const user: User = invite.data;
      profileUrl = user.profileUrl;

      return (
        <KitAvatar size={36} nickname={invite.title} imageUrl={profileUrl} />
      );
    }

    return (
      <View style={styles.image}>
        <KitIcon name='add-person' color={Colors.N0} size={20} />
      </View>
    );
  };

  const _renderName = () => {
    const info =
      invite.type === InviteType.Phone || invite.type === InviteType.Email
        ? invite.data
        : null;
    const indexOfNull = invite.title.indexOf('null');

    return (
      <View>
        <KitText black fontSize={16} numberOfLines={1}>
          {indexOfNull >= 0 ? invite.title.slice(0, indexOfNull) : invite.title}
        </KitText>
        {info && (
          <KitText gray fontSize={14} numberOfLines={1}>
            {info}
          </KitText>
        )}
      </View>
    );
  };

  const _renderInviteTag = () => {
    if (invite.type === InviteType.User) return null;

    return (
      <View style={styles.inviteTag}>
        <KitText brandColor fontSize={16}>
          Invite
        </KitText>
      </View>
    );
  };

  return (
    <TouchableBase style={styles.container} onPress={onPress}>
      {_renderAvatar()}
      <View style={styles.containerText}>{_renderName()}</View>
      {_renderInviteTag()}
    </TouchableBase>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.N0,
    paddingHorizontal: Spacing.l,
    paddingVertical: 6,
  },
  containerText: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: Spacing.m,
  },
  image: {
    borderRadius: 18,
    height: 36,
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.N300,
  },
  letter: {
    color: Colors.N0,
    fontSize: 17,
    fontWeight: '600',
  },
  inviteTag: {
    height: '100%',
    justifyContent: 'center',
  },
});
