import * as Sentry from '@sentry/react';
import type { CaptureContext } from '@sentry/types';

import { ENV } from '../Environment';
import { initOptions } from './common';

const debug = require('debug')('tca:kit:sentry:web');

const HealthMonitor = {
  init: (product: string): void => {
    Sentry.init({
      ...initOptions(product),
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: ENV === 'prod' ? 0.01 : 1.0,
      beforeSend: function (event, hint) {
        debug(`beforeSend:exception:${event.exception?.values?.[0]?.value}`);

        return event;
      },
    });
  },
  logError: (error: Error, context?: CaptureContext): void => {
    // @ts-ignore
    Sentry.captureException(error, context);
  },
  setTag: (key: string, value: string): void => {
    Sentry.setTag(key, value);
  },
  testCrash: (): void => {
    // no-op for web
  },
};

export default HealthMonitor;
