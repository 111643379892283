import { useShellContext } from '@omni/kit/contexts/ShellContext';
import React, { useContext, useEffect, useState } from 'react';
import { Appearance } from 'react-native';

import { getAppTheme } from '../utilities/NativeHelpers';

/**
 * Only use Proxima Nova on native in the check-in app.
 * Must not exceed 10k MAU per Proxima Nova license.
 * ref: https://subsplash.atlassian.net/browse/DISP-3937
 */
export type FontFamily = 'Proxima Nova' | string | undefined;

export type ThemeType = 'dark' | 'light' | null;
export type ColorValueKey = Exclude<ThemeType, null> | 'default';
export type ColorValueObject = Partial<Record<ColorValueKey, string>>;

export type ThemeContextType = {
  fontFamily?: FontFamily;
  theme: ThemeType;
  androidWeights?: Record<Weights, string>;
  colorForScheme?: (colorValues: ColorValueObject) => string | undefined;
};

const initialTheme = {
  fontFamily: undefined,
  theme: null,
  colorForScheme: () => undefined,
};

export const ThemeContext = React.createContext<ThemeContextType>(initialTheme);

export const getTheme = async (): Promise<ThemeType> => {
  try {
    const themeFromNativeBridge = await getAppTheme();

    return themeFromNativeBridge === 'system'
      ? (Appearance.getColorScheme() as ThemeType)
      : themeFromNativeBridge;
  } catch (error) {
    return 'light';
  }
};

type Weights = 'regular' | 'bold' | 'semiBold' | 'extraBold';

/**
 * ThemeContextWrapper must be used inside of ApplicationContextProvider.
 */
export default function ThemeContextWrapper({
  children,
  fontFamily = undefined,
  androidWeights,
  theme = null,
}: {
  children?: React.ReactNode;
  fontFamily?: FontFamily;
  androidWeights?: Record<Weights, string>;
  theme?: React.SetStateAction<ThemeType>;
}): JSX.Element {
  // leverage the app_color_scheme when this context is wrapped in an ApplicationContext
  const { app } = useShellContext();
  const activeAppTheme = app?.branding?.colorScheme;

  if (!theme && activeAppTheme) {
    theme = activeAppTheme;
  }

  const [appTheme, setAppTheme] = useState<ThemeType>(null);

  const colorForScheme = (colorValues: ColorValueObject) => {
    switch (appTheme) {
      case 'dark':
        return colorValues.dark || colorValues.default;
      case 'light':
        return colorValues.light || colorValues.default;
      default:
        return colorValues.default; // We should only get here if evaluating the scheme fails.
    }
  };

  useEffect(() => {
    if (theme) {
      setAppTheme(theme);
    } else {
      getTheme().then((theme) => setAppTheme(theme));
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{ fontFamily, theme: appTheme, colorForScheme, androidWeights }}
    >
      {appTheme ? children : null}
    </ThemeContext.Provider>
  );
}

export const useThemeContext = (): ThemeContextType => {
  return useContext(ThemeContext);
};
