import Environment from '@omni/kit/Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '@omni/kit/services/BaseServiceV2';

import { ICheckIn, ICheckOut } from '../Types';

const HOST = Environment.checkInService;
const RESOURCE = 'check-ins-multiple';

export type ICheckOutRequest = Omit<ICheckOut, 'id' | 'created_at'>;
interface ICreateCheckOuts extends IUri {
  token?: string;
  data: ICheckOutRequest[];
}

interface IGetAllCheckOutsResponse extends IHttpResponse {
  body?: ICheckIn[];
}

export default async function CreateCheckOuts({
  token,
  data,
}: ICreateCheckOuts): Promise<IGetAllCheckOutsResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    pluralizeResource: false,
  });

  return BaseServiceV2.Post({
    url,
    token,
    data,
  });
}
