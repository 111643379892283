import { useShellContext } from '@omni/kit';
import { KitTab, KitTabGroup } from '@omni/kit/components/KitTabGroup';
import { useRootAppContext } from '@omni/kit/contexts/RootAppContext';
import {
  DOMAIN_APPS,
  DOMAIN_GROUPS,
  DOMAIN_LIBRARY,
} from '@omni/kit/services/SearchService/Constants';
import memberAppLabelContextKey from '@omni/kit/utilities/memberAppLabelContextKey';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONTEXT_MEDIA } from '../../Constants';
import { ISearchTabsProps } from '../../types';
import Search from '../Search/Search';

const debug = require('debug')('tca:search:Screens:SearchTabs.tsx');

export default ({
  domains = [DOMAIN_LIBRARY, DOMAIN_APPS],
  q,
  typeFilterValue,
}: ISearchTabsProps): JSX.Element => {
  const { t } = useTranslation('search');
  const rootApp = useRootAppContext();
  const { app } = useShellContext();
  const contextKey = memberAppLabelContextKey(rootApp);

  let appSearchEnabled = domains.includes(DOMAIN_APPS);
  let mediaSearchEnabled = domains.includes(DOMAIN_LIBRARY);
  const groupSearchEnabled = domains.includes(DOMAIN_GROUPS);

  if (rootApp?.is_container) {
    if (rootApp?.id === app?.appKey) {
      // do not show media search in container app tour flow where user has not yet selected 'home' app
      mediaSearchEnabled = false;
    }
  }
  // do not show 'app' search in standalone apps
  else {
    appSearchEnabled = false;
  }

  const tabsEnabled = appSearchEnabled && mediaSearchEnabled;

  if (tabsEnabled) {
    return (
      <KitTabGroup initialRouteName={'Media'}>
        <KitTab name={t('search:tabTitle', { context: CONTEXT_MEDIA })}>
          {(_props) => (
            <Search
              domain={DOMAIN_LIBRARY}
              q={q}
              typeFilterValue={typeFilterValue}
            />
          )}
        </KitTab>
        <KitTab name={t('search:tabTitle', { context: contextKey })}>
          {(_props) => (
            <Search
              domain={DOMAIN_APPS}
              q={q}
              typeFilterValue={typeFilterValue}
            />
          )}
        </KitTab>
      </KitTabGroup>
    );
  }

  if (appSearchEnabled) {
    return (
      <Search domain={DOMAIN_APPS} q={q} typeFilterValue={typeFilterValue} />
    );
  }

  if (mediaSearchEnabled) {
    return (
      <Search domain={DOMAIN_LIBRARY} q={q} typeFilterValue={typeFilterValue} />
    );
  }

  if (groupSearchEnabled) {
    return (
      <Search domain={DOMAIN_GROUPS} q={q} typeFilterValue={typeFilterValue} />
    );
  }

  return <></>;
};
