import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';
import { useEffect, useState } from 'react';

import { Kiosk } from './types';

/*
 * Do not use this directly in a component, instead use KioskContext
 * */
export default (): {
  selectedKiosk: Kiosk | null | undefined;
  setSelectedKiosk: (kiosk: Kiosk | null | undefined) => Promise<void>;
  selectedKioskLoading: boolean;
} => {
  const [selectedKiosk, setSelectedKiosk] = useState<
    Kiosk | null | undefined
  >();

  const [selectedKioskLoading, setSelectedKioskLoading] =
    useState<boolean>(true);

  useEffect(() => {
    const getSelectedKiosk = async () => {
      const kiosk = await getStoredItem('assigned-kiosk');

      if (kiosk) {
        setSelectedKiosk(JSON.parse(kiosk));
      } else {
        setSelectedKiosk(null);
      }

      setSelectedKioskLoading(false);
    };
    getSelectedKiosk();
  }, []);

  const saveSelectedKiosk = async (kiosk: Kiosk | null | undefined) => {
    if (kiosk) {
      await setStoredItem('assigned-kiosk', JSON.stringify(kiosk));
    } else {
      await removeStoredItem('assigned-kiosk');
    }

    setSelectedKiosk(kiosk);
  };

  return {
    selectedKiosk,
    setSelectedKiosk: saveSelectedKiosk,
    selectedKioskLoading,
  };
};
