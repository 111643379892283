import { KitIcon, KitText } from '@omni/kit/components';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableHighlight, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';

import LeftNavButton, {
  LeftNavType,
} from '../../../../mobile/components/LeftNavButton';
import {
  CHANNEL_DETAILS_SCREEN,
  CHAT_SCREEN,
  CHAT_THREAD_SCREEN,
  PRAYER_LIST_SCREEN,
} from '../../../../shared/Constants';
import { THREADS_TAB } from '../../../../shared/Constants';
import {
  blockedUserListSelector,
  channelSelector,
  userSelector,
} from '../../../../shared/redux/selectors';
import { ChannelType } from '../../../../shared/redux/types';
import ChannelAvatar from '../../../../shared/scenes/channelList/components/ChannelAvatar';
import { formatDirectChannelName } from '../../../../utilities/chatUtilities';

enum ChannelScreens {
  Messages,
  Prayers,
  Threads,
}

// @ts-ignore
export default function ChannelHeader({ navigation, currentRoute }) {
  const user = useSelector(userSelector);
  const channel = useSelector(channelSelector);
  const blockedUserList = useSelector(blockedUserListSelector);
  const { t } = useTranslation();
  const [activeScreen, setActiveScreen] = useState<ChannelScreens>(
    ChannelScreens.Messages
  );

  const isThreadScreen = currentRoute === CHAT_THREAD_SCREEN;
  const isThreadsTab = currentRoute === THREADS_TAB;
  const isPrayersTab = currentRoute === PRAYER_LIST_SCREEN;

  const isChannelDetailsScreen = currentRoute === 'channel_details_screen';

  const navToMessages = () => {
    setActiveScreen(ChannelScreens.Messages);
    navigation.current?.navigate(CHAT_SCREEN);
  };

  const navToPrayers = () => {
    setActiveScreen(ChannelScreens.Prayers);
    navigation.current?.navigate(PRAYER_LIST_SCREEN);
  };

  const navToThreads = () => {
    setActiveScreen(ChannelScreens.Threads);
    navigation.current?.navigate(THREADS_TAB);
  };

  const navToChannelDetails = () => {
    navigation.current?.navigate(CHANNEL_DETAILS_SCREEN);
  };

  const channelType = channel?.customType as ChannelType;
  const channelName =
    channelType === ChannelType.Direct
      ? // @ts-ignore
        formatDirectChannelName(user!, channel!.members, blockedUserList)
      : channel?.name;

  useEffect(() => {
    // Reset to Messages tab when switching channels
    if (
      navigation &&
      navigation.current &&
      navigation.current.getCurrentRoute()
    ) {
      navToMessages();
    }
  }, [channel?.url]);

  return channel ? (
    <>
      {!isChannelDetailsScreen && (
        <View
          style={{
            height: 77,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {isThreadScreen ? (
            <TouchableOpacity onPress={navToMessages}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navigation.current?.goBack}
                />
                <View style={{ marginLeft: Spacing.m }}>
                  <KitText black fontSize={16}>
                    Thread
                  </KitText>
                  <KitText brandColor fontSize={15}>
                    {channel.name}
                  </KitText>
                </View>
              </View>
            </TouchableOpacity>
          ) : isThreadsTab || isPrayersTab ? (
            <TouchableOpacity onPress={navToMessages}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <LeftNavButton
                  type={LeftNavType.Back}
                  title={''}
                  onPress={navToMessages}
                />
                <View style={{ marginLeft: Spacing.m }}>
                  <KitText black bold fontSize={16}>
                    {isThreadsTab
                      ? t('messaging:threads')
                      : t('messaging:prayersText')}
                  </KitText>
                  <KitText fontSize={16} style={{ marginTop: -2 }}>
                    {channel.name}
                  </KitText>
                </View>
              </View>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={navToChannelDetails} activeOpacity={0.5}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <View
                  style={{ marginHorizontal: Spacing.s, alignItems: 'center' }}
                >
                  <ChannelAvatar channel={channel} />
                </View>
                <View>
                  <KitText
                    black
                    semiBold
                    fontSize={15}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {channelName}
                    <KitIcon
                      name='arrow-right'
                      size={20}
                      style={{ marginLeft: Spacing.xs }}
                    />
                  </KitText>
                  <KitText fontSize={13} style={{ marginTop: -5 }}>
                    {channel.memberCount} members
                  </KitText>
                </View>
              </View>
            </TouchableOpacity>
          )}
          {activeScreen === ChannelScreens.Messages && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: Spacing.l,
                height: '100%',
              }}
            >
              <TouchableHighlight
                onPress={navToThreads}
                underlayColor={Colors.N100}
                delayPressIn={0}
                style={{ padding: Spacing.m, borderRadius: BorderRadius.xl }}
              >
                <KitIcon name='thread' size={20} />
              </TouchableHighlight>
              <TouchableHighlight
                onPress={navToPrayers}
                underlayColor={Colors.N100}
                delayPressIn={0}
                style={{ padding: Spacing.m, borderRadius: BorderRadius.xl }}
              >
                <KitIcon name='prayer' size={20} />
              </TouchableHighlight>
            </View>
          )}
        </View>
      )}
    </>
  ) : null;
}
