import { useShellContext } from '@omni/kit';
import { KitTouchableIcon } from '@omni/kit/components';
import KitModal from '@omni/kit/components/KitModalV2';
import Show from '@omni/kit/components/Show';
import { SizeClassV2, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import AuthProviderList from '../AuthProviderList';
import {
  HEADER_FOOTER_HEIGHT,
  MIN_MODAL_HEIGHT,
  MIN_MODAL_WIDTH,
} from '../Constants';
import { IProps } from './types';

const MAX_MODAL_WIDTH = MIN_MODAL_WIDTH;

export default ({
  appKey = '',
  contentStyle,
  coverScreen = true,
  customHeader,
  isVisible = false,
  modalStyle,
  onClose,
  returnUrl = '',
  targetAuthProviderId,
}: IProps): JSX.Element => {
  const { app, tokens } = useShellContext();
  const orgKey = app.orgKey;

  const userToken = useMemo(() => tokens.user, [tokens.user]);

  const [hasBackdrop, setHasBackdrop] = useState(true);

  const { sizeClassV2 } = useSizeClass();

  const anchorBottom = sizeClassV2 < SizeClassV2.L;

  useEffect(() => {
    if (isVisible && userToken) {
      setHasBackdrop(false); // prevent black flicker on dismiss
      onClose?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, userToken]);

  return (
    <KitModal
      anchorBottom={anchorBottom}
      coverScreen={coverScreen}
      enableScroll={true} // necessary when screen is too small to fit all the login options
      hasBackdrop={hasBackdrop}
      isVisible={isVisible}
      onClose={onClose}
      modalStyle={{
        // use no padding to make content full bleed to edge of modal
        // e.g. for play history modal header rectangle icons https://zpl.io/ew5B6XW
        paddingVertical: 0,
        paddingHorizontal: 0,
        maxWidth: MAX_MODAL_WIDTH,
        maxHeight: anchorBottom ? '95%' : '80%',
        flexDirection: 'row', // necessary to make modal fit height of content
        ...modalStyle,
      }}
    >
      <View
        style={[
          {
            padding: Spacing.m,
            paddingVertical: Spacing.m + 30,
            minHeight: MIN_MODAL_HEIGHT,
          },
          !anchorBottom && {
            borderRadius: BorderRadius.l,
            minWidth: MIN_MODAL_WIDTH,
            paddingVertical: Spacing.m,
          },
          contentStyle,
        ]}
      >
        <Show show={!anchorBottom}>
          <View>
            <KitTouchableIcon
              color={colorForScheme?.({
                light: Colors.N900,
                dark: Colors.N0,
              })}
              name='remove'
              onPress={onClose}
              style={{
                alignSelf: 'flex-end',
              }}
              buttonSize={HEADER_FOOTER_HEIGHT}
            />
          </View>
        </Show>
        <AuthProviderList
          appKey={appKey}
          customHeader={customHeader}
          orgKey={orgKey}
          customReturnUrl={returnUrl}
          targetAuthProviderId={targetAuthProviderId}
          openInNewTab
        />
        <Show show={!anchorBottom}>
          <View style={{ height: HEADER_FOOTER_HEIGHT }} />
        </Show>
      </View>
    </KitModal>
  );
};
