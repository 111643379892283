import TokensService from '@omni/kit/services/TokensService';

import {
  IFacetResults,
  IPrepareFacetsUrl,
  TAG_TYPES,
  prepareFacetsUrl,
} from '.';
import BaseServiceV2, { IHttpResponse } from '../BaseServiceV2';
import { cache } from './Cache';

const debug = require('debug')('tca:service:SearchService');

interface IFacets extends IPrepareFacetsUrl {
  getFromCache?: boolean;
}

export interface IFacetResponse extends IHttpResponse {
  body?: IFacetResults;
}

export const facets = async ({
  getFromCache,
  tagType,
  ...buildUrlProps
}: IFacets): Promise<IFacetResponse> => {
  debug('facets');
  const url = prepareFacetsUrl({
    tagType,
    facet: tagType === TAG_TYPES.SCRIPTURE ? 'books' : 'tags',
    ...buildUrlProps,
  });

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    getFromCache,
    skipTimestamp: true,
    cache,
    token: guestToken,
  });
};
