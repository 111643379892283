import MediaItemService, {
  IMediaItem,
} from '@omni/kit/services/MediaItemService';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:FetchMediaItemByShortCode'
);

interface IFetchMediaItemByShortCode {
  appKey: string;
  mediaItemShortCode: string;
}

export default async ({
  appKey,
  mediaItemShortCode,
}: IFetchMediaItemByShortCode): Promise<IMediaItem | null> => {
  try {
    const mediaItems = await MediaItemService.getAll({
      appKey,
      filters: [['short_code', mediaItemShortCode]],
    });

    const mediaItem = mediaItems.body?._embedded['media-items'][0] || null;
    debug('fetched media item', mediaItem);

    return mediaItem;
  } catch (e) {
    const errorMessage = 'failed to fetch media item';
    debug(errorMessage, e);

    throw new Error(errorMessage);
  }
};
