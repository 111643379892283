import React from 'react';
import { View } from 'react-native';
import { KitButton, KitText } from '@omni/kit/components';
import { SizeClass } from '@omni/kit/contexts/SizeClassContext';
import { IJoinRequest } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { useTranslation } from 'react-i18next';

export function ApproveConfirmation({
  onCancel,
  onConfirm,
  request,
  sizeClass,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  request?: IJoinRequest;
  sizeClass: SizeClass;
}): JSX.Element | null {
  const { t } = useTranslation(['messaging']);

  if (!request) {
    return null;
  }

  const username =
    request._embedded['requesting-user']._embedded?.profiles[0].first_name;
  const groupName = request._embedded.group.name;

  return (
    <View>
      <KitText extraBold fontSize={20} black>
        {t('messaging:textApproveUsersRequest', { name: username })}
      </KitText>
      <KitText
        fontSize={14}
        gray
        style={{ marginTop: Spacing.s, marginBottom: Spacing.l }}
      >
        {t('messaging:textAreYouSureApproveRequest', {
          name: username,
          group: groupName,
        })}
      </KitText>
      <View
        style={{
          ...(sizeClass === SizeClass.Small && {
            flexDirection: 'column',
          }),
          ...(sizeClass !== SizeClass.Small && {
            flexDirection: 'row-reverse',
          }),
        }}
      >
        <KitButton
          title={t('messaging:buttonTitleYesApprove')}
          color={Colors.N900}
          onPress={onConfirm}
          small={sizeClass !== SizeClass.Small}
        />
        <KitButton
          title={t('messaging:buttonTitleNoCancel')}
          color={Colors.N100}
          secondary
          onPress={onCancel}
          style={{
            ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            ...(sizeClass !== SizeClass.Small && { marginRight: Spacing.m }),
          }}
          small={sizeClass !== SizeClass.Small}
        />
      </View>
    </View>
  );
}
