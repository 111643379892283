import { dispatch } from '@omni/kit/ActionHandler';
import KitIcon from '@omni/kit/components/KitIcon';
import KitText from '@omni/kit/components/KitText';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType, getSpacing } from '@omni/kit/theming/SpacingType';
import React from 'react';
import {
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from 'react-native';

import { useBlockPageContext } from '../BlockPageContext';
import { IBlockProps } from './types';

const debug = require('debug')('tca:blocks:IconRowBlock');

export interface IIconRowBlockProps extends IBlockProps {
  /** An action that will occur when a user taps/clicks on the block.*/
  action?: any;

  title: string;
  subtitle: string;
  icon: string;
  iconColor: string;
  iconSize: number;
  isTitleSemiBold: boolean;
  enableChevron: boolean;
  touchableType: 'opacity' | 'highlight';
  hasBottomBorder: boolean;
  topPadding: SpacingType;
  bottomPadding: SpacingType;
}

export default function IconRowBlock(props: IIconRowBlockProps): JSX.Element {
  const { dispatchAction } = useBlockPageContext();

  const {
    action = null,
    insetStyle = {},
    title = '',
    enableChevron = false,
    subtitle = '',
    icon = '',
    iconColor = '#000000',
    iconSize = 26,
    isTitleSemiBold,
    topSpacing = null,
    bottomSpacing = null,
    topPadding = null,
    bottomPadding = null,
    horizontalSpacing,
    touchableType = false,
    hasBottomBorder = true,
  } = props;

  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const viewStyle = {
    insetStyle,
    paddingTop: getSpacing(topSpacing),
    paddingBottom: getSpacing(bottomSpacing),
  };

  const containerStyle = {
    paddingTop: getSpacing(topPadding),
    paddingBottom: getSpacing(bottomPadding),
  };

  const bottomBorderStyle = {
    borderBottomColor: Colors.N100,
    borderBottomWidth: 1,
  };

  const wrapperContents = (
    <View
      style={[
        styles.container,
        {
          marginHorizontal: edgeSpacing,
        },
        containerStyle,
        hasBottomBorder && bottomBorderStyle,
      ]}
    >
      <View
        style={{
          justifyContent: 'center',
          paddingHorizontal: Spacing.s,
        }}
      >
        {/* @ts-ignore */}
        <KitIcon name={icon} size={iconSize} color={iconColor} />
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          paddingLeft: Spacing.m,
        }}
      >
        {title?.length > 0 && (
          <KitText
            style={{ flex: 1, flexWrap: 'wrap' }}
            semiBold={isTitleSemiBold}
            color={Colors.N1000}
          >
            {title}
          </KitText>
        )}
        {subtitle?.length > 0 && (
          <KitText style={{ flex: 1, flexWrap: 'wrap', fontSize: 14 }}>
            {subtitle}
          </KitText>
        )}
      </View>
      <View style={{ justifyContent: 'center' }}>
        {enableChevron && (
          <KitIcon name='arrow-right' size={20} color={Colors.N300} />
        )}
      </View>
    </View>
  );

  if (action) {
    if (touchableType === 'highlight') {
      return (
        <TouchableHighlight
          underlayColor={Colors.N50}
          style={viewStyle}
          onPress={() => {
            dispatchAction(action);
          }}
          testID='iconRowTouchable'
        >
          {wrapperContents}
        </TouchableHighlight>
      );
    } else {
      return (
        <TouchableOpacity
          activeOpacity={0.5}
          style={viewStyle}
          onPress={() => {
            dispatchAction(action);
          }}
          testID='iconRowTouchable'
        >
          {wrapperContents}
        </TouchableOpacity>
      );
    }
  }

  return <View style={viewStyle}>{wrapperContents}</View>;
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    minHeight: 78,
  },
});
