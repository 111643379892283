import KitCopyrightLogo from '@omni/kit/components/KitCopyrightLogo';
import { getSpacing } from '@omni/kit/theming/SpacingType';
import React from 'react';
import { View } from 'react-native';

import { IBlockProps } from './types';

export interface ICopyrightLogoBlockProps extends IBlockProps {
  linkToWebsite?: boolean;
  showDebug?: boolean;
}

export default function CopyrightLogoBlock({
  linkToWebsite,
  showDebug,
  topSpacing,
  bottomSpacing,
  insetStyle,
}: ICopyrightLogoBlockProps): JSX.Element {
  const viewStyle = {
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
    ...insetStyle,
  };

  return (
    <KitCopyrightLogo
      viewStyle={viewStyle}
      linkToWebsite={linkToWebsite}
      showDebug={showDebug}
    />
  );
}
