import KitIcon from '@omni/kit/components/KitIcon';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import React, { FC } from 'react';
import {
  Platform,
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from 'react-native';

import { PRAYER_TAB, THREADS_TAB } from '../../shared/Constants';

interface HeaderRightButtonsProps {
  onTabPress: (tab: string) => void;
}

const HeaderRightButtons: FC<HeaderRightButtonsProps> = ({ onTabPress }) => {
  return (
    <View style={styles.container}>
      <TouchableHighlight
        onPress={() => onTabPress(THREADS_TAB)}
        style={styles.button}
        underlayColor={Colors.N100}
        delayPressIn={0}
      >
        <KitIcon name='thread' color={Colors.N900} size={22} />
      </TouchableHighlight>
      <TouchableHighlight
        onPress={() => onTabPress(PRAYER_TAB)}
        style={styles.button}
        underlayColor={Colors.N100}
        delayPressIn={0}
      >
        <KitIcon name='prayer' color={Colors.N900} size={20} />
      </TouchableHighlight>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  button: {
    paddingHorizontal: 10,
    borderRadius: BorderRadius.xl,
    paddingVertical: 10,
  },
  text: {
    fontSize: 16,
  },
});

export default HeaderRightButtons;
