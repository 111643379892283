import { combineReducers } from 'redux';
import ChatReducer from './ChatReducer';
import SystemReducer from './SystemReducer';

const RootReducer = combineReducers({
  chat: ChatReducer,
  system: SystemReducer,
});

export default RootReducer;
