import { IEvent } from '@omni/kit/services/EventsService/Types';
import { IHousehold, IProfile } from '@omni/kit/services/PeopleService/Types';
import React, { createContext, useContext, useState } from 'react';

interface ICheckInContext {
  matchedAdultProfileIds?: string[]; // used in initial search/sign up
  setMatchedAdultProfileIds: (ids?: string[]) => void;
  adultCheckingIn?: IProfile;
  setAdultCheckingIn: (profile?: IProfile) => void;
  selectedHousehold?: IHousehold | null;
  setSelectedHousehold: (household?: IHousehold | null) => void;
  event?: IEvent;
  setEvent: (event?: IEvent) => void;
  resetCheckInState: () => void;

  // To manage duplicate profiles when adding to household
  duplicateAdultProfileIds?: string[];
  setDuplicateAdultProfileIds: (ids?: string[]) => void;
  duplicateAdultProfile?: IProfile;
  setDuplicateAdultProfile: (profile?: IProfile) => void;
  handlingTrustedPerson: boolean;
  setHandlingTrustedPerson: (arg: boolean) => void;
  isRollCall: boolean;
  setIsRollCall: (b: boolean) => void;
}

const CheckInContext = createContext<ICheckInContext>({
  matchedAdultProfileIds: undefined,
  setMatchedAdultProfileIds: () => undefined,
  adultCheckingIn: undefined,
  setAdultCheckingIn: () => undefined,
  selectedHousehold: undefined,
  setSelectedHousehold: () => undefined,
  event: undefined,
  setEvent: () => undefined,
  resetCheckInState: () => undefined,
  duplicateAdultProfileIds: undefined,
  setDuplicateAdultProfileIds: () => undefined,
  duplicateAdultProfile: undefined,
  setDuplicateAdultProfile: () => undefined,
  handlingTrustedPerson: false,
  setHandlingTrustedPerson: () => undefined,
  isRollCall: false,
  setIsRollCall: () => undefined,
});

export const CheckInContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [matchedAdultProfileIds, setMatchedAdultProfileIds] = useState<
    string[] | undefined
  >();
  const [adultCheckingIn, setAdultCheckingIn] = useState<
    IProfile | undefined
  >();
  const [selectedHousehold, setSelectedHousehold] = useState<
    IHousehold | undefined | null
  >();
  const [event, setEvent] = useState<IEvent | undefined>();
  const [duplicateAdultProfileIds, setDuplicateAdultProfileIds] = useState<
    string[] | undefined
  >();
  const [duplicateAdultProfile, setDuplicateAdultProfile] = useState<
    IProfile | undefined
  >();
  const [handlingTrustedPerson, setHandlingTrustedPerson] =
    useState<boolean>(false);
  const [isRollCall, setIsRollCall] = useState<boolean>(false);

  return (
    <CheckInContext.Provider
      value={{
        matchedAdultProfileIds,
        setMatchedAdultProfileIds,
        adultCheckingIn,
        setAdultCheckingIn,
        selectedHousehold,
        setSelectedHousehold,
        event,
        setEvent,
        resetCheckInState: () => {
          setMatchedAdultProfileIds(undefined);
          setAdultCheckingIn(undefined);
          setSelectedHousehold(undefined);
          setEvent(undefined);
          setDuplicateAdultProfileIds(undefined);
          setDuplicateAdultProfile(undefined);
          setHandlingTrustedPerson(false);
        },
        duplicateAdultProfileIds,
        setDuplicateAdultProfileIds,
        duplicateAdultProfile,
        setDuplicateAdultProfile,
        handlingTrustedPerson,
        setHandlingTrustedPerson,
        isRollCall,
        setIsRollCall,
      }}
    >
      {children}
    </CheckInContext.Provider>
  );
};

export const useCheckInContext = (): ICheckInContext => {
  return useContext(CheckInContext);
};
