import Environment from '../Environment';
import { IAppStates } from '../Types';

// used by the app handler for the loading state in container apps
export default function getAppStates(
  appKey?: string,
  title?: string
): IAppStates | undefined {
  if (!appKey) {
    return undefined;
  }

  return {
    loading: {
      title: title ? `Loading ${title}...` : 'Loading...',
      images: [
        {
          url: `${Environment.imagesHost}/images/cdn/${appKey}/{width}/{height}/ios/circle.png`,
          type: 'icon_circle',
          templated: true,
          aspectRatio: 1,
        },
      ],
    },
  };
}
