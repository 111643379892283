import { Platform, StyleSheet } from 'react-native';
import Colors from './Colors';

const Depth = StyleSheet.create({
  s: Platform.select({
    default: {
      boxShadow:
        'rgb(0 0 0 / 6%) 0px 1px 3px 0px, rgb(0 0 0 / 4%) 0px 1px 1px 0px',
    },
    ios: {
      shadowColor: Colors.N1000,
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.1,
      shadowRadius: 1,
    },
    android: {
      elevation: 1,
    },
  }),
  m: Platform.select({
    default: {
      boxShadow:
        'rgb(0 0 0 / 6%) 0px 3px 10px 0px, rgb(0 0 0 / 4%) 0px 1px 2px 0px',
    },
    ios: {
      shadowColor: Colors.N1000,
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.07,
      shadowRadius: 2,
    },
    android: {
      elevation: 2,
    },
  }),
  l: Platform.select({
    default: {
      boxShadow:
        'rgb(0 0 0 / 6%) 0px 5px 15px 0px, rgb(0 0 0 / 4%) 0px 1px 2px 0px',
    },
    ios: {
      shadowColor: Colors.N1000,
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
    android: {
      elevation: 3,
    },
  }),
  xl: Platform.select({
    default: {
      boxShadow:
        'rgb(0 0 0 / 8%) 0px 10px 25px 0px, rgb(0 0 0 / 4%) 0px 1px 3px 0px',
    },
    ios: {
      shadowColor: Colors.N1000,
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.14,
      shadowRadius: 6,
    },
    android: {
      elevation: 4,
    },
  }),
  xxl: Platform.select({
    default: {
      boxShadow:
        'rgb(0 0 0 / 10%) 0px 15px 35px 0px, rgb(0 0 0 / 4%) 0px 2px 6px 0px',
    },
    ios: {
      shadowColor: Colors.N1000,
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.18,
      shadowRadius: 8,
    },
    android: {
      elevation: 5,
    },
  }),
});

export default Depth;
