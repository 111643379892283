import { BridgeAction } from '@omni/kit/Types';

import BaseServiceV2, { IHttpResponse, IUri } from '../BaseServiceV2';

export interface ICustomProfileHeader {
  title?: string; // aka user full name / nickname
  subtitle?: string; // aka user initials
  image?: string;
  actions?: BridgeAction[]; // aka custom 'edit account' screen
}

interface IGetCustomProfileHeader extends IUri {
  url: string;
  getFromCache?: boolean;
  token: string;
}

interface ICustomProfileHeaderFeedResponse extends IHttpResponse {
  body?: ICustomProfileHeader;
}

export default async function ({
  url,
  getFromCache,
  token,
}: IGetCustomProfileHeader): Promise<ICustomProfileHeaderFeedResponse> {
  return await BaseServiceV2.Get({
    domainPreValidated: true,
    token,
    skipTimestamp: true,
    url,
    getFromCache,
  });
}
