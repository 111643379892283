import { useEffect, useState } from 'react';

import { NativeModules } from 'react-native';

export default function useAppLaunchCount(): number | null {
  const [appLaunchCount, setAppLaunchCount] = useState<number | null>(null);

  useEffect(() => {
    if (appLaunchCount === null) {
      try {
        NativeModules.ReactPlatformBridge.getAppLaunchCount((count: number) => {
          setAppLaunchCount(count);
        });
      } catch (err) {
        setAppLaunchCount(0);
      }
    }
  }, [appLaunchCount]);

  return appLaunchCount;
}
