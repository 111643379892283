import { KitIcon, KitText } from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';
import React from 'react';
import { StyleSheet, View } from 'react-native';

interface Props {
  coverUrl: string;
  name: string;
  description: string;
  groupType?: string | undefined;
}

export default function ChannelDetailHeader({
  coverUrl,
  name,
  description,
  groupType,
}: Props): JSX.Element {
  const hasChannelImage = Boolean(
    coverUrl?.startsWith('https://images.subsplash.com') ||
      coverUrl?.startsWith('https://images.stage.subsplash.net')
  );
  const imageUrl = parseImageUrl(
    coverUrl,
    1024,
    768,
    ImageServiceType.ImageJpeg
  );

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: Spacing.l,
        }}
      >
        <View style={styles.banner}>
          <KitImage
            source={hasChannelImage ? { uri: imageUrl } : {}}
            style={{
              width: 96,
              height: 96,
              borderRadius: 48,
              backgroundColor: Colors.N200,
            }}
          />
          {!hasChannelImage && (
            <View
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              <KitIcon name='channel' size={54} color={Colors.N0} />
            </View>
          )}
        </View>
        <View>
          <KitText h1>{name}</KitText>
          {groupType && (
            <KitText
              fontSize={14}
              style={{ textTransform: 'capitalize' }}
              color={Colors.N500}
            >
              {groupType}
            </KitText>
          )}
        </View>
      </View>
      {Boolean(description) && (
        <View style={{ position: 'relative' }}>
          <KitText expandable numberOfLines={3}>
            {description}
          </KitText>
        </View>
      )}
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  banner: {
    backgroundColor: Colors.N200,
    width: 96,
    height: 96,
    borderRadius: 48,
    marginRight: Spacing.l,
  },
});
