import { AuthProvider, fetchAuthProviders } from '@omni/kit/auth';
import { Dispatch, useEffect, useState } from 'react';

import { excludeSocialAuthProviders } from '../auth/Service/excludeSocialAuthProviders';

interface Props {
  appKey?: string;
  orgKey?: string;
}

interface ReturnType {
  isFetchingAuthProviders: boolean;
  setIsFetchingAuthProviders: Dispatch<React.SetStateAction<boolean>>;
  loginEnabledProviders: AuthProvider[];
  targetProviders: AuthProvider[];
  targetProviderIds: string[];
  providers: AuthProvider[];
}

export default function useAuthProviders({
  appKey,
  orgKey,
}: Props): ReturnType {
  const [isFetchingAuthProviders, setIsFetchingAuthProviders] = useState(true);
  const [providers, setProviders] = useState<AuthProvider[]>([]);
  const [loginEnabledProviders, setLoginEnabledProviders] = useState<
    AuthProvider[]
  >([]);
  const [targetProviders, setTargetProviders] = useState<AuthProvider[]>([]);
  const [targetProviderIds, setTargetProviderIds] = useState<string[]>([]);

  useEffect(() => {
    // When the app_key or org_key changes, we want to re-fetch the providers,
    setIsFetchingAuthProviders(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appKey, orgKey]);

  useEffect(() => {
    if (!isFetchingAuthProviders) {
      return;
    }

    if (!appKey && !orgKey) {
      setIsFetchingAuthProviders(false);

      return;
    }

    const doFetch = async () => {
      try {
        const result = await fetchAuthProviders({ appKey, orgKey });
        setProviders(result);
        setLoginEnabledProviders(
          result.filter((provider) => provider.enable_login)
        );

        const filteredProviders = excludeSocialAuthProviders(result);
        setTargetProviders(filteredProviders);

        const filteredProviderIdSet = filteredProviders.reduce(
          (accumulator, currentValue) => {
            accumulator.add(currentValue.authproviderid);

            return accumulator;
          },
          new Set<string>()
        );
        setTargetProviderIds(Array.from(filteredProviderIdSet));
      } finally {
        setIsFetchingAuthProviders(false);
      }
    };

    doFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingAuthProviders]);

  return {
    isFetchingAuthProviders,
    setIsFetchingAuthProviders,
    loginEnabledProviders,
    targetProviders,
    targetProviderIds,
    providers,
  };
}
