import React from 'react';
import { Pressable, View } from 'react-native';

import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import KitBadge from '../KitBadge';
import KitIcon from '../KitIcon';
import KitText from '../KitText';

export interface KitSectionProps {
  title?: string;
  badge?: string;
  onPress?: () => void;
  children?: React.ReactChild;
}

const KitSection = ({
  title,
  badge,
  onPress,
  children,
}: KitSectionProps): JSX.Element => {
  return (
    <View>
      {onPress ? (
        <Pressable onPress={onPress} testID='kit-section-pressable'>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: Spacing.l,
            }}
          >
            {title && (
              <KitText
                bold
                color={Colors.N900}
                fontSize={20}
                style={{ marginRight: Spacing.s }}
              >
                {title}
              </KitText>
            )}
            {badge && (
              <KitBadge
                color='blue'
                style={{
                  marginRight: Spacing.s,
                }}
                size='l'
                text={badge}
                textStyle={{ textTransform: 'lowercase' }}
              />
            )}
            <KitIcon name='arrow-right' size={20} color={Colors.N200} />
          </View>
        </Pressable>
      ) : (
        <KitText
          bold
          color={Colors.N900}
          fontSize={20}
          style={{ marginLeft: Spacing.l }}
        >
          {title}
        </KitText>
      )}
      {children}
    </View>
  );
};

export default KitSection;
