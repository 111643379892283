import {
  KEY_LOCATION_QUERY_DESCRIPTION,
  VALUE_CURRENT_LOCATION,
} from '../../Constants';
import { KitIcon, KitInput, KitTouchable } from '@omni/kit/components';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from '@omni/kit/storage';

import Colors from '@omni/kit/theming/Colors';
import { Location } from 'react-native-get-location';
import { Place } from '@omni/kit/placeTypes';
import PlacesAutocomplete from './PlacesAutocomplete';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { getUserLocation } from '@omni/kit/utilities/UserLocation';

const debug = require('debug')('tca:search:components:LocationSearchInput.tsx');

interface Props {
  autoFocus: boolean;
  onFocus?: any;
  onRemove?: () => void;
  onSubmit?: any;
  onUserLocationError?: (error: string) => void;
  placeholder?: string;
  selectedPlace: Place | undefined;
  setLocationQuery: (query: string | undefined) => void;
  setLocationSuggestions: (places: Place[]) => void;
  style?: StyleProp<ViewStyle>;
}

export default ({
  autoFocus,
  onFocus,
  onRemove,
  onSubmit,
  onUserLocationError,
  placeholder,
  selectedPlace,
  setLocationQuery,
  setLocationSuggestions,
  style,
}: Props): JSX.Element => {
  const [locationInputValue, setLocationInputValue] = useState<string>('');

  const [locationDescription, setLocationDescription] = useState<
    string | undefined
  >(undefined);

  const [selectedPlaceWithGeometry, setSelectedPlaceWithGeometry] = useState<
    Place | undefined
  >(undefined);

  const [autoCompletePlaces, setAutoCompletePlaces] = useState<
    Place[] | undefined
  >(undefined);

  const onLocationInputChange = (str: string) => {
    setLocationInputValue(str);
    setSelectedPlaceWithGeometry(undefined);
    setLocationQuery(undefined);
    setLocationDescription(undefined);
  };

  const onRightIconPress = useCallback(() => {
    removeStoredItem(KEY_LOCATION_QUERY_DESCRIPTION);
    setAutoCompletePlaces(undefined);
    setLocationQuery(undefined);
    setLocationDescription(undefined);
    setLocationInputValue('');
    onRemove?.();
  }, [onRemove, setLocationDescription, setLocationQuery]);

  const executeCurrentLocationQuery = useCallback(async () => {
    let userLocation: Location | undefined;
    try {
      userLocation = await getUserLocation();
      debug(`Got location: ${JSON.stringify(userLocation)}`);
      const place: Place = {
        place_id: VALUE_CURRENT_LOCATION,
        description: VALUE_CURRENT_LOCATION,
        geometry: {
          lat: userLocation.latitude,
          lng: userLocation.longitude,
        },
      };
      setSelectedPlaceWithGeometry(place);
    } catch (error) {
      onUserLocationError?.(JSON.stringify(error));
    }
  }, [onUserLocationError]);

  /**
   * Lifecycle:
   * If user enabled 'Current Location' in a previous app session,
   * immediately execute a current location query on mount.
   */
  useEffect(() => {
    getStoredItem(KEY_LOCATION_QUERY_DESCRIPTION).then((value) => {
      if (VALUE_CURRENT_LOCATION === value) {
        setLocationDescription(value);
      }
    });
  }, [setLocationDescription]);

  /**
   * Location query:
   * Observe changes to the location query description for 'Current Location'
   * and fire a request to obtain lat/lng
   */
  useEffect(() => {
    if (selectedPlace) {
      if (VALUE_CURRENT_LOCATION === selectedPlace?.place_id) {
        // User either enabled 'Current Location' in the Tour screen and AppSearch was mounted
        // or pressed 'Current Location' in location search results
        executeCurrentLocationQuery();
        setStoredItem(KEY_LOCATION_QUERY_DESCRIPTION, VALUE_CURRENT_LOCATION);
      }
    }
  }, [executeCurrentLocationQuery, selectedPlace]);

  // Propagate location suggestions to parent component
  useEffect(() => {
    setLocationSuggestions([
      ...(VALUE_CURRENT_LOCATION !== selectedPlace?.place_id
        ? [CurrentLocationPlace]
        : []),
      ...(autoCompletePlaces ?? []),
    ]);
  }, [autoCompletePlaces, selectedPlace, setLocationSuggestions]);

  /**
   * Obtained geometry for selected place from Google Places API or Current Location
   * Trigger the effect for 'selectedPlace' for 'setLocationQuery'.
   */
  useEffect(() => {
    if (selectedPlaceWithGeometry?.geometry) {
      const geometry = selectedPlaceWithGeometry?.geometry;
      const query = `${geometry?.lat},${geometry?.lng}`;
      setLocationQuery(query);
    }
  }, [selectedPlaceWithGeometry, setLocationQuery]);

  useEffect(() => {
    if (
      Boolean(selectedPlace?.description) &&
      selectedPlace?.description !== undefined
    ) {
      setLocationDescription(selectedPlace.description);
    }
  }, [selectedPlace]);

  const value = locationDescription || locationInputValue;

  return (
    <>
      <PlacesAutocomplete
        value={locationInputValue}
        setPlaces={setAutoCompletePlaces}
        selectedPlace={selectedPlace}
        setSelectedPlaceWithGeometry={setSelectedPlaceWithGeometry}
      />

      <KitInput
        style={style}
        autoFocus={autoFocus}
        inputBorder={Colors.N50}
        inputStyle={{
          ...{ height: 40 },
          ...(value === VALUE_CURRENT_LOCATION ? { color: Colors.brand } : {}),
        }}
        leftIcon={<LeftIcon />}
        onChangeText={onLocationInputChange}
        onFocus={onFocus}
        onSubmitEditing={onSubmit}
        placeholder={placeholder}
        rightIcon={Boolean(value) && <RightIcon onPress={onRightIconPress} />}
        value={value}
      />
    </>
  );
};

const LeftIcon = () => {
  const { colorForScheme } = useContext(ThemeContext);

  return (
    <KitIcon
      color={colorForScheme?.({ light: Colors.N300, dark: Colors.N500 })}
      name='map-pin'
      size={20}
      style={{ marginTop: 2 }}
    />
  );
};

const RightIcon = ({ onPress = () => undefined }: { onPress?: () => void }) => (
  <KitTouchable onPress={onPress}>
    <View
      style={{
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <KitIcon color={Colors.N300} name='remove' size={12} />
    </View>
  </KitTouchable>
);

export const CurrentLocationPlace: Place = {
  description: VALUE_CURRENT_LOCATION,
  place_id: VALUE_CURRENT_LOCATION,
};
