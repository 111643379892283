import { ViewStyle } from 'react-native';

/**
 * Transform array type style to avoid
 * `Failed to set an indexed property on 'CSSStyleDeclaration': Index property setter is not supported`
 * error in Chrome & Firefox on React 18.
 * This error happens when passing an array of style to a component on the web.
 * ref: https://github.com/alibaba/ice/pull/6455/files
 */
export const normalizeStyleProp = (
  style: ViewStyle | ViewStyle[] | undefined
): ViewStyle | undefined => {
  if (Array.isArray(style)) {
    return style.reduce((acc, val) => {
      return { ...acc, ...val };
    }, {});
  }

  return style;
};
