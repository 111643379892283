import ActionSheetItem from '@omni/kit/components/KitActionSheetItem';
import Colors from '@omni/kit/theming/Colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';

export default function MoreActionSheet({
  isManager,
  canLeave,
  isMuted,
  onLeaveGroup,
  onDeleteGroup,
  onMuteGroup,
}: {
  isManager: boolean;
  canLeave: boolean;
  isMuted: boolean;
  onLeaveGroup: () => void;
  onDeleteGroup: () => void;
  onMuteGroup: () => void;
}): JSX.Element | null {
  const { t } = useTranslation(['groups']);
  return (
    <>
      {Platform.OS !== 'web' && (
        <ActionSheetItem
          icon={isMuted ? 'notifications' : 'notifications-off'}
          iconColor={Colors.N900}
          label={isMuted ? t('unmuteNotifications') : t('muteNotifications')}
          onPress={onMuteGroup}
        />
      )}
      {canLeave && (
        <ActionSheetItem
          icon='logout'
          iconColor={Colors.N900}
          label={t('labelLeaveGroup')}
          onPress={onLeaveGroup}
        />
      )}
      {isManager && (
        <ActionSheetItem
          icon='delete'
          iconColor={Colors.R500}
          label={t('labelDeleteGroup')}
          onPress={onDeleteGroup}
        />
      )}
    </>
  );
}
//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({});
