import HealthMonitor from '@omni/kit/HealthMonitor';
import EventService from '@omni/kit/services/EventService';
import { EVENT_SERVICE_EVENTS } from '@omni/kit/services/EventService/types';
import { setStoredItem } from '@omni/kit/storage';
import { attemptRequest } from '@omni/kit/utilities/attemptRequest';
import { Dispatch } from 'react';

import { Kiosk } from '../../contexts/KioskContext/types';
import { ITokenStatus } from '../../services/CheckInService/Core/GetTokenData';
import VerifySetupCode from '../../services/CheckInService/Core/VerifySetupCode';
import { ITokenResponseBody } from '../../services/CheckInService/Types';

export interface HandleSetupCodeProps {
  app_key?: string;
  org_key?: string;
  short_code?: string;
  long_code?: string;
}

export const useSetupCode = (
  setAppKey: (appKey: string) => Promise<void>,
  setToken: (token: string) => Promise<void>,
  setTokenStatus: Dispatch<React.SetStateAction<ITokenStatus>>,
  setSelectedKiosk: (kiosk: Kiosk | null | undefined) => Promise<void>,
  setKioskDeviceId: (kioskDeviceId: string | undefined) => Promise<void>
): {
  handleSetupCode: (
    props?: HandleSetupCodeProps
  ) => Promise<string | undefined>;
} => {
  const handleSetupCode = async (
    props?: HandleSetupCodeProps
  ): Promise<string | undefined> => {
    const tokenResponse = await attemptRequest<ITokenResponseBody | undefined>(
      async () => await VerifySetupCode(props)
    );

    const accessToken = tokenResponse?.access_token;
    const accessTokenExpiresAt = tokenResponse?.access_token_expires_at ?? '';
    const newDeviceId = tokenResponse?.device_id ?? '';
    const refreshToken = tokenResponse?.refresh_token ?? '';

    if (accessToken) {
      if (props?.app_key) {
        setAppKey(props.app_key);
      }

      if (props?.org_key) {
        await setStoredItem('check_in_org_key', props.org_key);
        HealthMonitor.setTag('sap-active-orgkey', props.org_key);
      }

      setSelectedKiosk(undefined);
      setKioskDeviceId(newDeviceId);

      await setStoredItem(
        'check_in_access_token_expires_at',
        accessTokenExpiresAt
      );

      await setStoredItem('check_in_refresh_token', refreshToken);

      EventService.dispatch(
        EVENT_SERVICE_EVENTS.CHECK_IN_AUTHORIZATION_CHANGED
      );
    }

    setToken(accessToken ?? '');
    setTokenStatus(accessToken ? ITokenStatus.Valid : ITokenStatus.Missing);

    return accessToken;
  };

  return { handleSetupCode };
};
