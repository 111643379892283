import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';

import AnnouncementMessage from './messageTypes/AnnouncementMessage';
import BibleMessage from './messageTypes/BibleMessage';
import FileMessage from './messageTypes/FileMessage';
import MediaMessage from './messageTypes/MediaMessage';
import { MessageType } from '../../../redux/types';
import PhotoMessage from './messageTypes/PhotoMessage';
import PollMessage from './messageTypes/PollMessage';
import PrayerMessage from './messageTypes/PrayerMessage';
import React from 'react';
import { SCREEN_WIDTH } from '@omni/kit/utilities/utilities';
import TextMessage from './messageTypes/TextMessage';
import UnsupportedMessage from './messageTypes/UnsupportedMessage';
import { UserMessage } from '@sendbird/chat/message';
import { View } from 'react-native';

export default function MessageContent({
  message,
  disabled,
}: {
  message: UserMessage;
  disabled?: boolean;
}): JSX.Element {
  const isSmall = useSizeClass().sizeClass === SizeClass.Small;

  const messageComponent = () => {
    const props = { message, disabled };
    switch (message.customType) {
      case MessageType.Text:
        return <TextMessage {...props} />;
      case MessageType.Prayer:
        return <PrayerMessage {...props} />;
      case MessageType.Bible:
        return <BibleMessage {...props} />;
      case MessageType.Media:
        return <MediaMessage {...props} />;
      case MessageType.File:
        return <FileMessage {...props} />;
      case MessageType.Gif:
      case MessageType.Photo:
        return <PhotoMessage {...props} />;
      case MessageType.Poll:
        return <PollMessage {...props} />;
      case MessageType.Announcement:
        return <AnnouncementMessage {...props} />;
      default:
        return <UnsupportedMessage />;
    }
  };

  return (
    <View style={{ maxWidth: isSmall ? SCREEN_WIDTH * 0.8 : 470 }}>
      {messageComponent()}
    </View>
  );
}
